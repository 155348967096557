
import { Card, Icon } from '~/components/atoms'
export default {
  components: {
    Card,
    Icon,
  },
  props: {
    space: {
      type: String,
      default: 'normal',
    },
    testId: {
      type: String,
      default: '',
    },
    headerAlignment: {
      type: String,
      default: 'center',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    extendedClass: {
      type: String,
      default: '',
    },
    margin: {
      type: String,
      default: '',
    },
    isEditIconVisible: {
      type: Boolean,
      default: true,
    },
    isNewDesign: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isActive() {
      return this.isVisible
    },
  },
  methods: {
    handleEdit() {
      this.isEditOptionVisible = true
      this.$emit('card:edit')
    },
  },
}
