
import { Usps, UiTitle } from '~/components/atoms'
export default {
  components: {
    Usps,
    UiTitle,
  },
  props: {
    brandName: {
      type: String,
      default: 'regular',
    },
  },
  data() {
    return {
      brandType: this.brandName === 'Paysafecard' ? 'paysafecard' : 'regular',
    }
  },
  computed: {
    safetyMessage() {
      return this.brandName === 'Paysafecard'
        ? this.$t('organisms.checkout_billing_details.safety')
        : this.$t('organisms.checkout_billing_details.safety_credit_card')
    },
  },
}
