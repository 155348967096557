import { Context, Middleware } from '@nuxt/types'
// import {
//   getConsolidatedCookie,
//   setConsolidatedCookie,
// } from '~/services/ConsolidatedCookie'

const contactsFeatureGuard: Middleware = (context: Context): void => {
  if (!process.server) return
  //
  const { store } = context
  // if (!name) {
  //   return
  // }
  //
  // const isContactsFeaturePreview =
  //   query.contacts_feature_preview === 'true' ||
  //   getConsolidatedCookie(app, 'feature_flags', 'contacts_feature_preview')
  //
  // const { requiresContactsFeatureFlag } = context.route.matched[0].meta
  //
  // if (requiresContactsFeatureFlag && !isContactsFeaturePreview) {
  //   error({
  //     statusCode: 404,
  //     message: 'Page not found',
  //   })
  //
  //   return
  // }
  //
  // if (isContactsFeaturePreview) {
  //   setConsolidatedCookie(
  //     app,
  //     'feature_flags',
  //     'contacts_feature_preview',
  //     isContactsFeaturePreview
  //   )
  // }

  store.dispatch('context/setIsContactsFeaturePreview', true)
}

export default contactsFeatureGuard
