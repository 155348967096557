
import { mapGetters } from 'vuex'
import { getStorageItem } from '~/services/localstorage'

export default {
  computed: {
    ...mapGetters('rtrUser', ['phoneNumber']),
  },
  mounted() {
    const {
      phoneNumberInfo: { phoneNumber = '', dialCode = '' } = {},
    } = getStorageItem({
      storage: 'recharge-store',
    })
    const formattedPhoneNumber = `+${dialCode} ${phoneNumber}`.replace(
      /(\+\d+) \1/,
      '$1 '
    )

    // Update context with phoneNumber found in localStorage
    this.$store.dispatch('rtrUser/setPhoneNumber', formattedPhoneNumber)
  },
}
