
export default {
  props: {
    direction: {
      type: String,
      default: 'left',
      validator: value => ['left', 'right'].includes(value),
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    switchTooltip() {
      this.show = !this.show
    },
    hideTooltip() {
      this.show = false
    },
    showTooltip() {
      this.show = true
    },
  },
}
