import { TYPES } from './mutation-types'
import { IUiNotificationsState } from './state'
import { NotificationsCommit } from './mutations'
import { ActionTree } from '~/store/types'
import { RawNotification } from '~/store/ui/notifications/types'

const { PUSH_NOTIFICATION, POP_NOTIFICATION } = TYPES

interface Actions {
  pushNotification(notification: RawNotification): void
  popNotification(notificationId: string | undefined): void
}

const actions: ActionTree<
  IUiNotificationsState,
  NotificationsCommit,
  Actions
> = {
  pushNotification({ commit }, notification) {
    commit(PUSH_NOTIFICATION, notification)
  },
  popNotification({ commit }, notificationId) {
    commit(POP_NOTIFICATION, notificationId)
  },
}

export default actions
