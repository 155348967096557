
import { mapGetters } from 'vuex'
import Notification from '~/components/molecules/notification/index.vue'
import SmoothWrapper from '~/components/utils/smooth-wrapper.vue'

export default {
  name: 'OverlayNotificationBar',
  components: {
    Notification,
    SmoothWrapper,
  },
  computed: {
    ...mapGetters('ui/notifications', ['notifications']),
  },
  mounted() {
    this.$notifications.restoreDeferredNotifications()
  },
}
