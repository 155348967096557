
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import Vue from 'vue'
import { mapGetters } from 'vuex'
import CountrySelectorDropdown from './components/country-selector-dropdown/index.vue'
import SelectorListItemLabel from './components/country-selector-list-item-label/index.vue'
import MobileDropdownPrefix from './components/country-selector-dropdown-mobile-prefix/index.vue'
import { UiSelector } from '~/components/molecules'
import { getStorageItem, setStorageItem } from '~/services/localstorage'
import isEmpty from '~/utils/isEmpty'

const FILTERED_OUT_COUNTRIES = ['CU']

export default Vue.extend({
  name: 'CountrySelector',
  components: {
    UiSelector,
  },
  props: {
    showDropdownLabel: {
      type: Boolean,
      default: false,
    },
    withMargin: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    customLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedCountry: null,
      customDropdown: CountrySelectorDropdown,
      customListItemLabel: SelectorListItemLabel,
      customDropdownMobilePrefix: MobileDropdownPrefix,
    }
  },
  computed: {
    ...mapGetters('context', ['country']),
    ...mapGetters('context', { countries: 'countriesWithoutGlobal' }),
    options() {
      const filteredCountries: { label: string; value: string }[] = []
      this.countries.forEach(country => {
        if (!FILTERED_OUT_COUNTRIES.includes(country.code)) {
          filteredCountries.push({
            ...country,
            label: this.$t(`countries.${country.code}`),
            value: country.iso,
          })
        }
      })

      filteredCountries.sort(({ label: a }, { label: b }) => a.localeCompare(b))

      return filteredCountries
    },
    inputLabel() {
      if (this.isMobile || this.$mq === 'md') {
        return ''
      }
      if (this.customLabel) {
        return this.customLabel
      }
      return this.$t('organisms.country_selector.input_label')
    },
    isMobile() {
      return this.$mq === 'sm'
    },
  },
  watch: {
    selectedCountry(val): void {
      if (val) {
        this.$emit('input', val.iso)
      }
    },
  },
  mounted() {
    const { storedCountry } = getStorageItem({
      storage: 'recharge-store',
    })
    if (!isEmpty(storedCountry) || this?.country?.iso !== undefined) {
      this.selectedCountry = this.countries.find(
        country => country.iso === (storedCountry?.iso || this.country.iso)
      )
    }
  },
  methods: {
    handleItemSelect(countryIso): void {
      const country = this.options.find(country => country.iso === countryIso)
      setStorageItem({
        storage: 'recharge-store',
        key: 'storedCountry',
        value: country,
      })
      this.selectedCountry = country
      this.$emit('country:select', country)
    },
    filterFn(searchTerm: string) {
      const searchTermLowerCase = searchTerm.toLowerCase()

      const matchesSearchTerm = value =>
        value
          ? value
              .toString()
              .toLowerCase()
              .includes(searchTermLowerCase)
          : false

      return country => {
        return [
          country.name,
          country.label,
          country.iso,
          country.countryCode,
          `+${country.countryCode}`,
        ].some(matchesSearchTerm)
      }
    },
  },
})
