import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import {
  ExternalLinkModel,
  IExternalLinkModel,
  ICMSExternalLinkModel,
} from '../base/ExternalLink'
import { LinkModel, ICMSLink, ILink } from '../base/Link'

export type TIconType =
  | 'giftcards'
  | 'gamecards'
  | 'international'
  | 'entertaiment'
  | 'mobile-recharge'
  | 'prepaid-cards'

export interface ICategoryCard {
  title: string
  isNew?: boolean
  iconType: TIconType
  categoryLink?: ILink
  externalLink?: IExternalLinkModel
}

export interface ICMSCategoryCard extends Entry {
  fields: {
    title: string
    isNew?: boolean
    iconType: TIconType
    categoryLink?: ICMSLink
    externalLink?: ICMSExternalLinkModel
  }
}

export class CategoryCardModel extends EntryModel {
  protected readonly title: string
  protected readonly isNew?: boolean
  protected readonly iconType: TIconType
  protected readonly categoryLink?: ILink
  protected readonly externalLink?: IExternalLinkModel

  constructor(categoryCardModel: ICMSCategoryCard) {
    super(categoryCardModel)
    const {
      fields: { title, isNew, iconType, categoryLink, externalLink },
    } = categoryCardModel
    this.title = title
    this.isNew = isNew
    this.iconType = iconType
    if (categoryLink) {
      this.categoryLink = new LinkModel(categoryLink).toJSON()
    }

    if (externalLink) {
      this.externalLink = new ExternalLinkModel(externalLink).toJSON()
    }
  }

  toJSON(): ICategoryCard {
    return {
      title: this.title,
      isNew: this.isNew,
      iconType: this.iconType,
      categoryLink: this.categoryLink,
      externalLink: this.externalLink,
    }
  }
}
