import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { ICMSData } from './page'
import get from '~/utils/get'
import { TMetaTag, MetaTagsModel } from '~/models/components/MetaTags'
import { MetaLinksModel, IMetaLinks } from '~/models/components/MetaLinks'
import { TCountry } from '~/normalizers/layout/country'
import { Maybe } from '~/apis/clients/graphql/types/contentful'

export type TContentfulSeo = {
  pageTitle: Maybe<string>
  path: string
  lang: string
  country: TCountry
  metaDescription?: Maybe<string>
  metaRobots?: Maybe<string>
  metaCanonicalLink?: Maybe<string>
  metaHreflangLinks?: Maybe<Maybe<string>[]>
  structuredData?: Maybe<unknown>
  parentPath?: string
}

export interface ISEO {
  metaTitle: string
  metaTags: TMetaTag[]
  metaLinks: IMetaLinks
}

function languageMatchesUrl(lang: string, url?: string): boolean {
  if (!url) return false
  const [, locale] = url.split('/')
  const [language] = locale.split('-')
  return language === lang.toLowerCase()
}

export class CMSSeoNormalizer {
  private readonly canonicalPath?: string
  protected readonly metaTitle: string
  protected readonly metaTags: TMetaTag[]
  protected readonly metaLinks: IMetaLinks

  constructor(cmsData: ICMSData) {
    const {
      metaDescription,
      metaRobots,
      pageTitle,
      metaHreflangLinks,
    } = cmsData
    this.metaTitle = pageTitle
    this.canonicalPath = CMSSeoNormalizer.getSEOCanonicalPath(cmsData)
    this.metaTags = new MetaTagsModel(
      metaDescription,
      metaRobots,
      !!this.canonicalPath
    ).toJSON()

    this.metaLinks = new MetaLinksModel(
      this.canonicalPath,
      metaHreflangLinks || []
    ).toJSON()
  }

  private static getSEOCanonicalPath(cmsData: ICMSData): string | undefined {
    const { lang, path, parentPath, country, metaCanonicalLink } = cmsData

    const validPath = languageMatchesUrl(lang, metaCanonicalLink)

    if (validPath) return metaCanonicalLink

    const canonicalLanguages =
      get(country, 'fields.seo.fields.canonicalLanguages') || []
    const shouldUseDefault = canonicalLanguages.includes(lang.toLowerCase())
    const defaultPath = parentPath || path
    return shouldUseDefault ? defaultPath : undefined
  }

  toJSON(): ISEO {
    return {
      metaLinks: this.metaLinks,
      metaTags: this.metaTags,
      metaTitle: this.metaTitle,
    }
  }
}

export function normalizeSeo({
  pageTitle,
  path,
  metaDescription,
  metaRobots,
  metaHreflangLinks,
  metaCanonicalLink,
  structuredData,
  lang,
  country,
}: TContentfulSeo): ISEO {
  return new CMSSeoNormalizer(({
    pageTitle,
    country: ({
      fields: {
        seo: {
          fields: {
            canonicalLanguages: country.seo?.canonicalLanguages,
          },
        },
      },
    } as unknown) as Entry,
    path,
    metaDescription,
    metaRobots,
    metaCanonicalLink,
    metaHreflangLinks,
    structuredData,
    lang,
  } as unknown) as ICMSData).toJSON()
}
