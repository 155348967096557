type TSpace = 'wide' | 'normal' | 'none' | 'tight'

export const highlighted = {
  type: Boolean,
  default: false,
}

export const disable = {
  type: Boolean,
  default: false,
}

export const margin = {
  type: String,
  default: '',
}

export const hasEditOption = {
  type: Boolean,
  default: false,
}

export const overflowHidden = {
  type: Boolean,
  default: true,
}

export const borderRadius = {
  type: String,
  default: 'lg',
}

export const space = {
  type: String as () => TSpace,
  default: 'normal',
}

export const removeBorder = {
  type: Boolean,
  default: false,
}

export const borderColor = {
  type: String,
  default: 'border-gray',
}

export const backgroundColor = {
  type: String,
  default: 'bg-white',
}

export const staticCard = {
  type: Boolean,
  default: false,
}
