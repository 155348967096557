const middleware = {}

middleware['auth/check-auth'] = require('../client/middleware/auth/check-auth.ts')
middleware['auth/check-auth'] = middleware['auth/check-auth'].default || middleware['auth/check-auth']

middleware['auth/redirect-logged-in-user'] = require('../client/middleware/auth/redirect-logged-in-user.ts')
middleware['auth/redirect-logged-in-user'] = middleware['auth/redirect-logged-in-user'].default || middleware['auth/redirect-logged-in-user']

middleware['change-email-feature-guard'] = require('../client/middleware/change-email-feature-guard.ts')
middleware['change-email-feature-guard'] = middleware['change-email-feature-guard'].default || middleware['change-email-feature-guard']

middleware['check-for-federated-account'] = require('../client/middleware/check-for-federated-account.ts')
middleware['check-for-federated-account'] = middleware['check-for-federated-account'].default || middleware['check-for-federated-account']

middleware['check-for-invalid-locales'] = require('../client/middleware/check-for-invalid-locales.ts')
middleware['check-for-invalid-locales'] = middleware['check-for-invalid-locales'].default || middleware['check-for-invalid-locales']

middleware['check-locale/index'] = require('../client/middleware/check-locale/index.ts')
middleware['check-locale/index'] = middleware['check-locale/index'].default || middleware['check-locale/index']

middleware['check-locale/interfaces'] = require('../client/middleware/check-locale/interfaces.ts')
middleware['check-locale/interfaces'] = middleware['check-locale/interfaces'].default || middleware['check-locale/interfaces']

middleware['check-locale/types'] = require('../client/middleware/check-locale/types.ts')
middleware['check-locale/types'] = middleware['check-locale/types'].default || middleware['check-locale/types']

middleware['check-locale/utils'] = require('../client/middleware/check-locale/utils.ts')
middleware['check-locale/utils'] = middleware['check-locale/utils'].default || middleware['check-locale/utils']

middleware['checkout-error-redirection'] = require('../client/middleware/checkout-error-redirection.ts')
middleware['checkout-error-redirection'] = middleware['checkout-error-redirection'].default || middleware['checkout-error-redirection']

middleware['checkout-saga'] = require('../client/middleware/checkout-saga.ts')
middleware['checkout-saga'] = middleware['checkout-saga'].default || middleware['checkout-saga']

middleware['contacts-feature-guard'] = require('../client/middleware/contacts-feature-guard.ts')
middleware['contacts-feature-guard'] = middleware['contacts-feature-guard'].default || middleware['contacts-feature-guard']

middleware['disable-account-analytics'] = require('../client/middleware/disable-account-analytics.ts')
middleware['disable-account-analytics'] = middleware['disable-account-analytics'].default || middleware['disable-account-analytics']

middleware['redirection/blog-url-redirections'] = require('../client/middleware/redirection/blog-url-redirections.ts')
middleware['redirection/blog-url-redirections'] = middleware['redirection/blog-url-redirections'].default || middleware['redirection/blog-url-redirections']

middleware['redirection/constants'] = require('../client/middleware/redirection/constants.ts')
middleware['redirection/constants'] = middleware['redirection/constants'].default || middleware['redirection/constants']

middleware['redirection/enums/ae'] = require('../client/middleware/redirection/enums/ae.ts')
middleware['redirection/enums/ae'] = middleware['redirection/enums/ae'].default || middleware['redirection/enums/ae']

middleware['redirection/enums/at'] = require('../client/middleware/redirection/enums/at.ts')
middleware['redirection/enums/at'] = middleware['redirection/enums/at'].default || middleware['redirection/enums/at']

middleware['redirection/enums/be'] = require('../client/middleware/redirection/enums/be.ts')
middleware['redirection/enums/be'] = middleware['redirection/enums/be'].default || middleware['redirection/enums/be']

middleware['redirection/enums/ca'] = require('../client/middleware/redirection/enums/ca.ts')
middleware['redirection/enums/ca'] = middleware['redirection/enums/ca'].default || middleware['redirection/enums/ca']

middleware['redirection/enums/ch'] = require('../client/middleware/redirection/enums/ch.ts')
middleware['redirection/enums/ch'] = middleware['redirection/enums/ch'].default || middleware['redirection/enums/ch']

middleware['redirection/enums/country-slug-redirect'] = require('../client/middleware/redirection/enums/country-slug-redirect.ts')
middleware['redirection/enums/country-slug-redirect'] = middleware['redirection/enums/country-slug-redirect'].default || middleware['redirection/enums/country-slug-redirect']

middleware['redirection/enums/dk'] = require('../client/middleware/redirection/enums/dk.ts')
middleware['redirection/enums/dk'] = middleware['redirection/enums/dk'].default || middleware['redirection/enums/dk']

middleware['redirection/enums/es'] = require('../client/middleware/redirection/enums/es.ts')
middleware['redirection/enums/es'] = middleware['redirection/enums/es'].default || middleware['redirection/enums/es']

middleware['redirection/enums/fi'] = require('../client/middleware/redirection/enums/fi.ts')
middleware['redirection/enums/fi'] = middleware['redirection/enums/fi'].default || middleware['redirection/enums/fi']

middleware['redirection/enums/gb'] = require('../client/middleware/redirection/enums/gb.ts')
middleware['redirection/enums/gb'] = middleware['redirection/enums/gb'].default || middleware['redirection/enums/gb']

middleware['redirection/enums/gmp'] = require('../client/middleware/redirection/enums/gmp.ts')
middleware['redirection/enums/gmp'] = middleware['redirection/enums/gmp'].default || middleware['redirection/enums/gmp']

middleware['redirection/enums/gr'] = require('../client/middleware/redirection/enums/gr.ts')
middleware['redirection/enums/gr'] = middleware['redirection/enums/gr'].default || middleware['redirection/enums/gr']

middleware['redirection/enums/ie'] = require('../client/middleware/redirection/enums/ie.ts')
middleware['redirection/enums/ie'] = middleware['redirection/enums/ie'].default || middleware['redirection/enums/ie']

middleware['redirection/enums/it'] = require('../client/middleware/redirection/enums/it.ts')
middleware['redirection/enums/it'] = middleware['redirection/enums/it'].default || middleware['redirection/enums/it']

middleware['redirection/enums/mx'] = require('../client/middleware/redirection/enums/mx.ts')
middleware['redirection/enums/mx'] = middleware['redirection/enums/mx'].default || middleware['redirection/enums/mx']

middleware['redirection/enums/no'] = require('../client/middleware/redirection/enums/no.ts')
middleware['redirection/enums/no'] = middleware['redirection/enums/no'].default || middleware['redirection/enums/no']

middleware['redirection/enums/pl'] = require('../client/middleware/redirection/enums/pl.ts')
middleware['redirection/enums/pl'] = middleware['redirection/enums/pl'].default || middleware['redirection/enums/pl']

middleware['redirection/enums/pt'] = require('../client/middleware/redirection/enums/pt.ts')
middleware['redirection/enums/pt'] = middleware['redirection/enums/pt'].default || middleware['redirection/enums/pt']

middleware['redirection/enums/redirection-dictionary'] = require('../client/middleware/redirection/enums/redirection-dictionary.ts')
middleware['redirection/enums/redirection-dictionary'] = middleware['redirection/enums/redirection-dictionary'].default || middleware['redirection/enums/redirection-dictionary']

middleware['redirection/enums/sa'] = require('../client/middleware/redirection/enums/sa.ts')
middleware['redirection/enums/sa'] = middleware['redirection/enums/sa'].default || middleware['redirection/enums/sa']

middleware['redirection/enums/se'] = require('../client/middleware/redirection/enums/se.ts')
middleware['redirection/enums/se'] = middleware['redirection/enums/se'].default || middleware['redirection/enums/se']

middleware['redirection/enums/sk'] = require('../client/middleware/redirection/enums/sk.ts')
middleware['redirection/enums/sk'] = middleware['redirection/enums/sk'].default || middleware['redirection/enums/sk']

middleware['redirection/enums/tr'] = require('../client/middleware/redirection/enums/tr.ts')
middleware['redirection/enums/tr'] = middleware['redirection/enums/tr'].default || middleware['redirection/enums/tr']

middleware['redirection/enums/us'] = require('../client/middleware/redirection/enums/us.ts')
middleware['redirection/enums/us'] = middleware['redirection/enums/us'].default || middleware['redirection/enums/us']

middleware['redirection/index'] = require('../client/middleware/redirection/index.ts')
middleware['redirection/index'] = middleware['redirection/index'].default || middleware['redirection/index']

middleware['refer-a-friend-country-availability-guard'] = require('../client/middleware/refer-a-friend-country-availability-guard.ts')
middleware['refer-a-friend-country-availability-guard'] = middleware['refer-a-friend-country-availability-guard'].default || middleware['refer-a-friend-country-availability-guard']

middleware['route-to-two-point-o'] = require('../client/middleware/route-to-two-point-o.ts')
middleware['route-to-two-point-o'] = middleware['route-to-two-point-o'].default || middleware['route-to-two-point-o']

middleware['set-context-configuration'] = require('../client/middleware/set-context-configuration.ts')
middleware['set-context-configuration'] = middleware['set-context-configuration'].default || middleware['set-context-configuration']

middleware['set-headers'] = require('../client/middleware/set-headers.ts')
middleware['set-headers'] = middleware['set-headers'].default || middleware['set-headers']

middleware['set-marketplace'] = require('../client/middleware/set-marketplace.ts')
middleware['set-marketplace'] = middleware['set-marketplace'].default || middleware['set-marketplace']

middleware['set-shared-data'] = require('../client/middleware/set-shared-data.ts')
middleware['set-shared-data'] = middleware['set-shared-data'].default || middleware['set-shared-data']

middleware['sign-in-with-apple-feature-guard'] = require('../client/middleware/sign-in-with-apple-feature-guard.ts')
middleware['sign-in-with-apple-feature-guard'] = middleware['sign-in-with-apple-feature-guard'].default || middleware['sign-in-with-apple-feature-guard']

export default middleware
