
import { mapGetters } from 'vuex'
import { UiLink } from '~/components/atoms'
export default {
  name: 'CheckoutPaymentDisclaimer',
  components: { UiLink },
  props: {
    isPaysafeCard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('context', ['country']),
    ...mapGetters('ui/checkout', ['termsAndConditionsUrl']),
  },
}
