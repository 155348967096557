const GLOBAL_COUNTRY = require('../../../client/utils/constants/global-country.json')

const URL_LOCALE_TO_RECOMMERCE = {
  ar: 'ar_SA',
  bg: 'bg_BG',
  cs: 'cs_CZ',
  da: 'da_DK',
  de: 'de_DE',
  el: 'el_GR',
  es: 'es_ES',
  fr: 'fr_FR',
  hr: 'hr_HR',
  hu: 'hu_HU',
  it: 'it_IT',
  id: 'id_ID',
  ja: 'ja_JP',
  lt: 'lt_LT',
  lv: 'lv_LV',
  ms: 'ms_MY',
  nb: 'nb_NO',
  nl: 'nl_NL',
  pl: 'pl_PL',
  pt: 'pt_PT',
  ro: 'ro_RO',
  sk: 'sk_SK',
  sl: 'sl_SI',
  sv: 'sv_SE',
  tr: 'tr_TR',
  tl: 'tl_PH',
  th: 'th_TH',
  vi: 'vi_VN',
  zh: 'zh_CN',
  'es-mx': 'es_MX',
  'nl-be': 'nl_BE',
  'en-gb': 'en_GB',
}

const REGIONAL_LOCALES = Object.entries(
  URL_LOCALE_TO_RECOMMERCE
).flatMap(([locale, recommerceLocale]) =>
  locale.length > 2 ? [recommerceLocale] : []
)

const LOCALE_TO_CONTENTFUL = {
  'es-mx': 'es-MX',
  'nl-be': 'nl-BE',
  'fr-be': 'fr-BE',
  'fr-ch': 'fr-CH',
  'de-at': 'de-AT',
  'de-ch': 'de-CH',
  'bg-bg': 'bg-BG',
  'hr-hr': 'hr-HR',
  'lv-lv': 'lv-LV',
  'lt-lt': 'lt-LT',
  'sk-sk': 'sk-SK',
  'sl-sl': 'sl-SL',
}

const LANGUAGE_TO_CONTENTFUL = {
  el: 'el-GR',
}

const toRecommerceLocale = locale => {
  return URL_LOCALE_TO_RECOMMERCE[locale] || GLOBAL_COUNTRY.defaultLocale
}

const toContentfulLocale = locale => {
  const [language] = locale.split('-')
  return (
    LOCALE_TO_CONTENTFUL[locale] || LANGUAGE_TO_CONTENTFUL[language] || language
  )
}

const toFileLocale = (locale, useRecommerce = false) =>
  useRecommerce
    ? toRecommerceLocale(locale)
        .replace('_', '-')
        .toLocaleLowerCase()
    : locale

module.exports = {
  toRecommerceLocale,
  toContentfulLocale,
  toFileLocale,
  REGIONAL_LOCALES,
}
