export enum PREFIX_STRATEGY {
  NO_PREFIX = 'no_prefix',
  PREFIX_AND_DEFAULT = 'prefix_and_default',
}

export enum CONTEXT_STORAGE_KEYS {
  AID = 'aid',
  LAST_PRODUCT_SEEN_BY_COUNTRY = 'last_product_seen_by_country',
  LAST_PRODUCT_VALUE_SEEN_BY_COUNTRY = 'last_product_value_seen_by_country',
  GUEST_TOKEN = 'guest_token',
  SHOW_ERROR_DETAILS = 'show_error_details',
}

export const COOKIE_AGE = {
  path: '/',
  maxAge: 60 * 60 * 24 * 3,
}
