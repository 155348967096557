import { EntryModel } from '../base/Entry'
import { CategoryCardModel, ICategoryCard } from './CategoryCard'
import mapWithFilter from '~/services/map-with-filter'

export interface ICategoryList {
  categoryCards: ICategoryCard[]
}

export interface IAccountCategories {
  title: string
  url: string
  description: string
  type: string
  icon?: string
  name: string
  customBehavior: boolean
}

export const accountCategories: IAccountCategories[] = [
  {
    title: 'account.overview.profile.title',
    url: '/my-account/profile',
    description: 'account.overview.profile.description',
    type: 'link',
    icon: '',
    name: 'profile',
    customBehavior: false,
  },
  {
    title: 'account.overview.orders.title',
    url: '/my-account/orders',
    description: 'account.overview.orders.description',
    type: 'link',
    icon: '',
    name: 'orders',
    customBehavior: false,
  },
  {
    title: 'account.overview.security.title',
    url: '/my-account/security',
    description: 'account.overview.security.description',
    type: 'link',
    icon: '',
    name: 'security',
    customBehavior: false,
  },
  {
    title: 'account.overview.contacts.title',
    url: '/my-account/contacts',
    description: 'account.overview.contacts.description',
    type: 'link',
    icon: '',
    name: 'contacts',
    customBehavior: false,
  },
]

const getFilteredAccountCategories = (
  isContactsFeaturePreview: boolean,
  isNativeAccount: boolean
) => {
  return accountCategories.filter(category => {
    if (category.name === 'security' && !isNativeAccount) {
      return false
    }
    if (category.name !== 'contacts') return true

    return isContactsFeaturePreview
  })
}

export class CategoryListModel extends EntryModel {
  protected readonly categoryCards: ICategoryCard[]

  constructor(categoryListModel) {
    super(categoryListModel)

    const {
      fields: { categoryCards = [] },
    } = categoryListModel

    this.categoryCards = mapWithFilter(
      categoryCards,
      category => new CategoryCardModel(category).toJSON(),
      'fields'
    )
  }

  toJSON(): ICategoryList {
    return {
      categoryCards: this.categoryCards,
    }
  }
}

export { getFilteredAccountCategories }
