<template>
  <transition name="fade">
    <div
      v-if="loading"
      class="w-full h-full z-40 fixed top-0 start-0 flex justify-center items-center"
      :dir="direction"
    >
      <loader />
      <div
        class="loading-page fixed w-full h-full top-0 start-0 bg-white opacity-75"
      />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { Loader } from '~/components/atoms'
export default {
  components: {
    Loader,
  },
  props: {
    initialLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: this.initialLoading,
    }
  },
  computed: {
    ...mapGetters('context', ['direction']),
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      requestAnimationFrame(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>
.loading-page {
  text-align: center;
  backdrop-filter: blur(100px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-active /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
