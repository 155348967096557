import { Entry } from 'contentful-management/dist/typings/entities/entry'
import {
  ICurrencyModel,
  ICMSCurrencyModel,
  CurrencyModel,
} from '../base/Currency'

import { EntryModel } from '../base/Entry'
import { ICMSSeoModel, ISeoModel, SeoModel } from './Seo'
import { ICMSLegalModel, ILegalModel, LegalModel } from './Legal'
export interface ICMSCountryModel extends Entry {
  fields: {
    abv: string
    name: string
    langs: string[]
    currencies: ICMSCurrencyModel[]
    defaultLang: string
    disableLanguages?: string[]
    legal?: ICMSLegalModel
    seo?: ICMSSeoModel
    dialCode: number
  }
}

export interface ICountryModel {
  codes: string[]
  abv: string
  name: string
  langs: string[]
  currencies: ICurrencyModel[]
  defaultLang: string
  defaultCode: string
  legal: ILegalModel
  seo?: ISeoModel
  dialCode: number
}

export class CountryModel extends EntryModel {
  protected readonly abv: string
  protected readonly name: string
  protected readonly langs: string[]
  protected readonly currencies: ICurrencyModel[]
  protected readonly codes: string[]
  protected readonly defaultLang: string
  protected readonly defaultCode: string
  protected readonly seo?: ISeoModel
  protected readonly legal: ILegalModel
  protected readonly dialCode: number

  constructor(countryModel: ICMSCountryModel) {
    super(countryModel)
    const { disableLanguages, dialCode, seo } = this.fields

    this.name = this.requiredField('name')
    this.abv = this.requiredField('abv')
    this.defaultLang = this.requiredField('defaultLang').toLowerCase()
    this.defaultCode = `${this.defaultLang}-${this.abv}`.toLowerCase()
    this.dialCode = dialCode
    this.legal = new LegalModel(this.requiredField('legal')).toJSON()

    this.seo = seo ? new SeoModel(seo).toJSON() : undefined

    this.langs = this.requiredField('langs').filter(lang =>
      disableLanguages ? !disableLanguages.includes(lang) : lang
    )
    this.codes = this.langs.map(lang => `${lang}-${this.abv}`.toLowerCase())
    this.currencies = this.requiredField('currencies').map(currency =>
      new CurrencyModel(currency).toJSON()
    )
  }

  toJSON(): ICountryModel {
    return {
      abv: this.abv,
      name: this.name,
      langs: this.langs,
      currencies: this.currencies,
      defaultLang: this.defaultLang,
      defaultCode: this.defaultCode,
      codes: this.codes,
      legal: this.legal,
      seo: this.seo,
      dialCode: this.dialCode,
    }
  }
}
