
import ServiceCostsExplanation from './service-costs-explanation.vue'
import {
  discountPrice,
  product,
  productPrice,
  promoCode,
  totalPrice,
  transactionCost,
  quantity,
} from './props'

export default {
  components: {
    ServiceCostsExplanation,
  },
  props: {
    discountPrice,
    product,
    productPrice,
    promoCode,
    totalPrice,
    transactionCost,
    quantity,
  },
  computed: {
    localAmount(): number {
      const {
        price: { localAmount = 0 },
      } = this.productPrice

      return localAmount
    },

    serviceFee(): number {
      const { serviceFee = 0 } = this.productPrice
      return serviceFee
    },
    discount(): number {
      const {
        price: { discount = 0 },
      } = this.productPrice
      return discount
    },
  },
}
