import { IImageModel, ImageModel, ICMSImageModel } from './Image'

export interface ICMSIconModel {
  fields: IImageModel
}

export class IconModel {
  protected readonly icon: IImageModel

  constructor(iconModel: ICMSImageModel) {
    this.icon = new ImageModel(iconModel).toJSON()
  }

  toJSON(): IImageModel {
    return this.icon
  }
}
