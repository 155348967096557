import { ActionTree } from 'vuex'
import { isPresent } from 'ts-is-present'
import Ravelin from 'ravelinjs/core+track'
import { ICheckoutState } from './interfaces'
import { formatFormFields } from './services'
import { TYPES } from './mutation-types'
import defaultStateFn from './state'
import get from '~/utils/get'
import isEmpty from '~/utils/isEmpty'
import { CONTEXT_STORAGE_KEYS } from '~/store/context/enums'
import FormatRedirectPaymentUrls from '~/factories/FormatRedirectionUrls'
import { NAMESPACED_TYPES } from '~/store/ui/checkout/mutation-types'
import { getStorageItem } from '~/services/localstorage'
import { COUPON_STATUS } from '~/plugins/gtm/enums'
import { ITopupFetchCalculateOrderRequestParams } from '~/services/topup/interfaces'

const { SET_PAYMENT_METHODS, SET_RESEND_CODE_STATE } = NAMESPACED_TYPES

const {
  SET_ISSUER,
  SET_REDIRECT_URL,
  SET_REDIRECT_URLS,
  SET_GENERAL_INFO,
  SET_PAYMENT_INFORMATION_DATA,
  SET_SELECTED_PAYMENT_METHOD_ID,
  SET_EMAIL,
  SET_PHONE_NUMBER,
  SET_PHONE_CHALLENGE_ID,
  SET_PROMO_CODE_LOADING,
  SET_PROMO_CODE_ERROR,
  SET_CREATE_PAYMENT_PAYLOAD,
  SET_PHONE_CHALLENGE_CODE,
  SET_TERMS_AND_CONDITIONS,
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_REDEEM_PHONE_NUMBER,
  SET_AFFILIATED_ID,
  SET_DISCOUNT_PRICE,
  SET_SERVICE_FEE,
  SET_TOTAL_PRICE,
  SET_VOUCHER,
  SET_IS_RETRY_ORDER,
  SET_CUSTOM_DENOMINATION_ERROR,
  SET_IS_REDIRECT_DEEP_LINK,
  SET_REDIRECT_CLIENT_TOKEN,
  SET_BEACON_ID,
  SET_RAVELIN_DEVICE_ID,
} = TYPES

const COUPON_ERROR_MAPPING = {
  'voucher.not.found': COUPON_STATUS.COUPON_NOT_RECOGNISED,
  'voucher.expired': COUPON_STATUS.COUPON_EXPIRED,
  'voucher.already.used': COUPON_STATUS.COUPON_CONSUMED,
  'voucher.not.valid.for.product': COUPON_STATUS.COUPON_NOT_VALID,
  'voucher.not.valid.for.brand': COUPON_STATUS.COUPON_NOT_VALID,
}

const CUSTOM_DENOMINATION_ERRORS = ['open.range.value.not.supported']

const mapErrorToCouponStatus = ({ message }) => {
  return COUPON_ERROR_MAPPING[message.replace(/\s/g, '')]
}

const actions: ActionTree<ICheckoutState, ICheckoutState> = {
  selectIssuer({ commit }, issuer): void {
    commit(SET_ISSUER, issuer)
  },

  setBeaconId({ commit }, beaconId): void {
    commit(SET_BEACON_ID, beaconId)
  },

  generateUrls(
    { commit, state },
    { appUrl, locale, encodedPhone, productId }
  ): void {
    const { redirectUrls } = state.createPaymentPayload

    const dictionary = {
      query:
        encodedPhone && productId
          ? `?productId=${productId}&p=${encodedPhone}`
          : '',
      urlPlaceholder: `${appUrl}/${locale}`,
    }

    const formattedUrls = FormatRedirectPaymentUrls(redirectUrls, dictionary)

    commit(SET_REDIRECT_URLS, formattedUrls)
  },

  async fetchOrderSummaryTopup(
    { commit },
    params: ITopupFetchCalculateOrderRequestParams
  ): Promise<any> {
    try {
      if (params.voucher) {
        commit(SET_PROMO_CODE_ERROR, '')
        commit(SET_PROMO_CODE_LOADING, true)
        this.$gtmEnhanced.trackApplyCoupon(COUPON_STATUS.COUPON_SUCCESS)
      }

      const { data: calculateOrder } = await this.$topup('calculateOrder')(
        params
      )

      commit(SET_VOUCHER, params.voucher)

      return calculateOrder
    } catch (e) {
      if (e.statusCode === 422) {
        if (CUSTOM_DENOMINATION_ERRORS.includes(e.message.replace(/\s/g, ''))) {
          commit(SET_CUSTOM_DENOMINATION_ERROR, e.message)
        } else {
          const couponStatus = mapErrorToCouponStatus(e)
          this.$gtmEnhanced.trackApplyCoupon(couponStatus)
          commit(SET_PROMO_CODE_ERROR, e.message)
        }
      }
      throw e
    } finally {
      commit(SET_PROMO_CODE_LOADING, false)
    }
  },

  async applyPromoCode(
    { commit, rootGetters, state: { createPaymentPayload } },
    promoCode
  ) {
    const {
      productId,
      merchant,
      currency,
      quantity,
      email,
    } = createPaymentPayload
    try {
      commit(SET_PROMO_CODE_ERROR, '')
      commit(SET_PROMO_CODE_LOADING, true)
      const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']
      const orderDetails = rootGetters['order/orderDetails']
      const openRangeValue = orderDetails?.openRangeValue || null

      const { data } = await this.$topup('paymentMethods')({
        merchant,
        productId: productId.toString(),
        quantity,
        currency,
        voucher: promoCode,
        email,
        country: isGlobalMarketplace
          ? rootGetters['context/country'].code.toUpperCase()
          : null,
        locale: isGlobalMarketplace
          ? rootGetters['context/recommerceLocale']
          : null,
        value: this.$router.app.$route.query.value
          ? Number(this.$router.app.$route.query.value)
          : openRangeValue,
      })
      const locale = rootGetters['context/locale']

      commit(SET_VOUCHER, promoCode)

      const paymentMethods = data.paymentMethods?.filter(isPresent)

      if (!paymentMethods) return

      commit(SET_PAYMENT_METHODS, { paymentMethods, locale }, { root: true })
      commit(SET_DISCOUNT_PRICE, paymentMethods[0].discountPrice?.value)
      commit(SET_SERVICE_FEE, paymentMethods[0].serviceFee?.value)
      commit(SET_TOTAL_PRICE, paymentMethods[0].orderPrice?.value)

      if (promoCode) {
        this.$gtmEnhanced.trackApplyCoupon(COUPON_STATUS.COUPON_SUCCESS)
      }
    } catch (e) {
      if (e.statusCode === 422) {
        const couponStatus = mapErrorToCouponStatus(e)
        this.$gtmEnhanced.trackApplyCoupon(couponStatus)
        commit(SET_PROMO_CODE_ERROR, e.message)
      } else {
        throw e
      }
    } finally {
      commit(SET_PROMO_CODE_LOADING, false)
    }
  },

  async removePromoCode({ dispatch }) {
    await dispatch('applyPromoCode', null)
  },

  async setPromoCodeLoading({ commit }, loading) {
    commit(SET_PROMO_CODE_LOADING, loading)
  },

  async fetchPaymentMethods(
    { commit, rootGetters },
    { productId, currency, merchant, quantity, voucher, email, value }
  ): Promise<void> {
    try {
      commit('errors/resetErrors', undefined, {
        root: true,
      })

      const { $topup } = this

      commit(SET_GENERAL_INFO, {
        productId,
        merchant,
        currency,
        quantity,
      })

      const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']
      const orderDetails = rootGetters['order/orderDetails']
      const openRangeValue = orderDetails?.openRangeValue || null

      const { data } = await $topup('paymentMethods')({
        merchant,
        productId,
        quantity,
        currency,
        email,
        voucher,
        country: isGlobalMarketplace
          ? rootGetters['context/country'].code.toUpperCase()
          : null,
        locale: isGlobalMarketplace
          ? rootGetters['context/recommerceLocale']
          : null,
        value: value || openRangeValue,
      })

      const paymentMethods = data.paymentMethods?.filter(isPresent)

      if (!paymentMethods) return

      commit(SET_PAYMENT_METHODS, { paymentMethods }, { root: true })
      commit(SET_DISCOUNT_PRICE, paymentMethods[0].discountPrice?.value)
      commit(SET_SERVICE_FEE, paymentMethods[0].serviceFee?.value)
      commit(SET_TOTAL_PRICE, paymentMethods[0].orderPrice?.value)
    } catch (error) {
      this.$sentry.captureException(error)
      if (
        CUSTOM_DENOMINATION_ERRORS.includes(error.message.replace(/\s/g, ''))
      ) {
        commit(SET_CUSTOM_DENOMINATION_ERROR, error.message)
      } else {
        commit(
          'errors/setErrors',
          [
            {
              message: error.message,
              code: error.statusCode,
            },
          ],
          {
            root: true,
          }
        )
      }
    }
  },
  setAid({ commit }) {
    const aid = getStorageItem({
      storage: 'recharge-storage',
      key: CONTEXT_STORAGE_KEYS.AID,
    })
    const parsedAid = Number(aid)

    commit(SET_AFFILIATED_ID, parsedAid)
  },
  setTermsAndConditions({ commit }, value) {
    commit(SET_TERMS_AND_CONDITIONS, value)
  },
  setTotalPrice({ commit }, totalPrice) {
    commit(SET_TOTAL_PRICE, totalPrice)
  },
  async saveNormalizedPaymentForm(
    { commit },
    { form = {} } = {}
  ): Promise<void> {
    commit('errors/resetErrors', undefined, {
      root: true,
    })

    if (isEmpty(form)) return

    const formattedFields = formatFormFields(form)

    commit(SET_PAYMENT_INFORMATION_DATA, formattedFields)
  },

  async createPayment(
    { state, commit, rootGetters },
    extraParams = {}
  ): Promise<void> {
    const { $topup } = this
    const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']
    const isAuthenticated = rootGetters['user/isAuthenticated']
    const user = rootGetters['user/user']
    const orderDetails = rootGetters['order/orderDetails']
    const openRangeValue = orderDetails?.openRangeValue || null

    try {
      const { data } = await $topup('createPayment')({
        ...state.createPaymentPayload,
        ...extraParams,
        country: isGlobalMarketplace
          ? rootGetters['context/country'].code.toUpperCase()
          : null,
        locale: isGlobalMarketplace
          ? rootGetters['context/recommerceLocale']
          : null,
        accountId: isAuthenticated ? user.id : null,
        email: isAuthenticated ? user.email : state.createPaymentPayload.email,
        value: this.$router.app.$route.query.value
          ? Number(this.$router.app.$route.query.value)
          : openRangeValue,
      })

      const checkoutUrl = data?.payment?.checkoutUrl

      if (!checkoutUrl) throw new Error('no checkout url')

      commit(SET_REDIRECT_URL, checkoutUrl)
    } catch (error) {
      this.$sentry.captureException(error)

      throw error
    }
  },

  async submitPhoneChallenge(
    { commit, rootGetters },
    { phoneChallengeNumber, locale }
  ): Promise<void> {
    const { dialCode } = rootGetters['contentful/country'] || {}
    const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']
    const phoneNumber = `+${dialCode}${phoneChallengeNumber}`
    commit(SET_PHONE_NUMBER, phoneNumber)

    const merchant = rootGetters['context/merchant']
    const { $topup } = this

    try {
      const { data } = await $topup('createPhoneChallenge')({
        merchant,
        phoneNumber,
        locale: isGlobalMarketplace
          ? rootGetters['context/recommerceLocale']
          : null,
        localeOptions: locale,
      })

      const phoneChallengeId = get(data, 'phoneChallenge.id')

      commit(SET_PHONE_CHALLENGE_ID, phoneChallengeId)
    } catch (error) {
      this.$sentry.captureException(error)

      throw error
    }
  },

  async resendCode({ getters, rootGetters, commit }, { locale }) {
    commit(SET_RESEND_CODE_STATE, { loading: true }, { root: true })
    const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']

    const phoneNumber = get(
      getters,
      'createPaymentPayload.paymentInformationData.phoneChallengeNumber'
    )
    const merchant = rootGetters['context/merchant']
    const { $topup } = this

    try {
      const { data } = await $topup('createPhoneChallenge')({
        merchant,
        phoneNumber,
        locale: isGlobalMarketplace
          ? rootGetters['context/recommerceLocale']
          : null,
        localeOptions: locale,
      })

      const phoneChallengeId = get(data, 'phoneChallenge.id')

      commit(SET_PHONE_CHALLENGE_ID, phoneChallengeId)
      commit(SET_RESEND_CODE_STATE, { succeeded: true }, { root: true })
    } catch (error) {
      this.$sentry.captureException(error)
      commit('errors/setErrors', [error], {
        root: true,
      })
      commit(SET_RESEND_CODE_STATE, { failed: true }, { root: true })
    }
  },
  redirectToPaymentPage(_, { checkoutUrl }): void {
    window.location.href = checkoutUrl
  },
  savePhoneChallengeCode({ commit }, code): void {
    commit(SET_PHONE_CHALLENGE_CODE, code)
  },
  savePaymentMethodId({ commit }, id): void {
    commit(SET_SELECTED_PAYMENT_METHOD_ID, { id })
  },
  saveEmail({ commit }, email): void {
    commit(SET_EMAIL, { email })
  },
  saveProduct({ commit }, product): void {
    commit(SET_PRODUCT, product)
  },
  saveProductId({ commit }, productId): void {
    commit(SET_PRODUCT_ID, productId)
  },
  saveRedeemPhoneNumber({ commit }, redeemPhoneNumber: number): void {
    commit(SET_REDEEM_PHONE_NUMBER, redeemPhoneNumber)
  },
  rehydrate(
    { getters, commit },
    { paymentMethodId: id, email, issuerId, createPaymentPayload = {} }
  ): void {
    if (this?.$router?.app?.$route?.query?.saved) {
      const currentCreatePaymentPayload = getters.createPaymentPayload
      commit(SET_CREATE_PAYMENT_PAYLOAD, {
        ...currentCreatePaymentPayload,
        ...createPaymentPayload,
        email,
        paymentMethodId: id,
        issuer: issuerId,
      })
      return
    }

    commit(SET_SELECTED_PAYMENT_METHOD_ID, { id })
    commit(SET_EMAIL, { email })
    commit(SET_ISSUER, issuerId)
  },
  clearCreatePaymentPayload({ commit }): void {
    commit(SET_CREATE_PAYMENT_PAYLOAD, defaultStateFn().createPaymentPayload)
  },
  setIsRetryOrder({ commit }, isRetryOrder: boolean) {
    commit(SET_IS_RETRY_ORDER, isRetryOrder)
  },
  setRedirectInfo({ commit }, { clientToken }) {
    commit(SET_IS_REDIRECT_DEEP_LINK, true)
    commit(SET_REDIRECT_CLIENT_TOKEN, clientToken)
  },
  async initializeRavelin({ commit }) {
    const ravelin = new Ravelin({
      key: process.env.RAVELIN_KEY,
    })
    const deviceId = await ravelin.core.id()
    commit(SET_RAVELIN_DEVICE_ID, deviceId)
  },
}

export default actions
