import { IPaymentRedirectUrls } from '~/store/checkout/interfaces'
import { objectMap } from '~/utils/objectMap'

export default function FormatRedirectPaymentUrls(
  redirectUrls: Readonly<IPaymentRedirectUrls>,
  dictionary: Record<string, string>
): Readonly<IPaymentRedirectUrls> {
  return objectMap(redirectUrls, (url: string) =>
    url.replace(/\{(\w*)\}/g, (_, key) => dictionary[key])
  )
}
