var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"to":_vm.name}},[_c('transition',{attrs:{"name":"fade"}},[_c('div',_vm._g(_vm._b({staticClass:"fixed z-50 bg-overlay md:p-4 flex justify-center inset-0",class:{
        'p-4': !_vm.fullscreen && !_vm.bottomModal,
        'items-center': !_vm.bottomModal,
        'items-bottom bottom-modal': !_vm.fullscreen && _vm.bottomModal,
      }},'div',_vm.$attrs,false),_vm.$listeners),[_c('section',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeOverlayDirective),expression:"closeOverlayDirective"}],class:{
          'w-screen h-screen md:h-auto md:w-modal': _vm.fullscreen,
          'w-full h-auto md:w-modal': !_vm.fullscreen,
          'max-h-full relative': !_vm.bottomModal,
          'absolute bottom-0 h-fit': !_vm.fullscreen && _vm.bottomModal,
          'slim-modal': _vm.slimWidth,
          'md:rounded-lg': !_vm.isGlobalMarketplace,
          'rounded-lg': _vm.isGlobalMarketplace,
          ..._vm.addedModalStyling,
          'p-5': !_vm.withoutInsets,
          'py-5 xs:p-5': _vm.smallDeviceNoXInsets,
          'bg-gray-light': _vm.grayBackground,
          'bg-white': !_vm.grayBackground,
          'overflow-y-auto': _vm.scrollableModal,
        },attrs:{"data-test":"modal","aria-labelledby":_vm.title,"aria-describedby":_vm.desc,"role":"dialog"}},[(_vm.isLoading)?_c('div',{staticClass:"absolute inset-0 opacity-75 bg-white z-10"}):_vm._e(),_vm._v(" "),(_vm.overlayVisibility !== 'hidden')?_c('overlay-notification-bar',{staticClass:"absolute bottom-0 left-0 z-1"}):_vm._e(),_vm._v(" "),(!_vm.disableCloseButton)?_c('button-icon',{staticClass:"absolute top-4 end-4 z-50",attrs:{"data-test":"close-button","color":"black","icon":"close","font-size":"xs"},on:{"click":_vm.closeClicked}}):_vm._e(),_vm._v(" "),_c('div',{class:{
            'm-3': !_vm.withoutInsets,
            'my-3 mx-2 xs:m-3': _vm.smallDeviceNoXInsets && !_vm.bottomModal,
          }},[_c('transition',{attrs:{"name":"fade"}},[_vm._t("default",null,{"triggerLoader":_vm.changeLoaderStatus})],2)],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }