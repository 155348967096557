
import { PropOptions } from 'vue'
import { Document } from '@contentful/rich-text-types'
import RichTextRenderer from '~/components/renderers/rich-text-renderer/index.vue'
import Icon from '~/components/atoms/icon/index.vue'
import Flag from '~/components/atoms/flag/index.vue'

export default {
  components: {
    Icon,
    Flag,
    RichTextRenderer,
  },
  props: {
    iconSize: {
      type: String,
      default: 'base',
    },
    iconColor: {
      type: String,
      default: 'success',
    },
    text: {
      type: Object,
      default: () => null,
    } as PropOptions<Document | string>,
    country: {
      type: String,
      default: '',
    },
    truncate: Boolean,
    injected: Boolean,
    plainText: {
      type: String,
      default: '',
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: 'check',
    },
  },
}
