import { Middleware, Context } from '@nuxt/types'
import countries from '../../../config/i18n/config/recommerceCountries.json'
import languages from '../../../config/i18n/config/recommerceLanguages.json'
import REDIRECT_DICTIONARY from './enums/redirection-dictionary'
import REDIRECT_GMP_DICTIONARY from './enums/gmp'
import REDIRECT_COUNTRY_SLUGS from './enums/country-slug-redirect'

import {
  TWO_CHARACTER_LMP_PRODUCT_PAGES,
  TEMPORARY_REDIRECT_STATUS_CODE,
  AUTH_ROUTES,
  PERMANENT_REDIRECT_STATUS_CODE,
  BLOG_REDIRECT_LOCALE_COUNTRY,
  CRYPTO_SLUGS,
  CRYPTO_COUNTRIES,
} from './constants'
import { BlogUrlRedirections } from './blog-url-redirections'
import { splitUrl } from '~/services/url'
import {
  endMeasurement,
  startMeasurement,
} from '~~/server/services/performance'
import { REGIONAL_LOCALES } from '~~/server/fetch-i18n-config/format-locale'
import { GMP_LOCALE_COOKIE_NAME } from '~/utils/constants/cookies'
import { REFER_A_FRIEND_INVITATION_PAGE_WITHOUT_LOCALE } from '~/utils/constants/refer-a-friend'

const REGIONAL_LANGUAGE_REDIRECTS = {
  ...Object.assign(
    {},
    ...REGIONAL_LOCALES.map(locale => {
      const [lang, region] = locale.split('_')
      const urlLocale = `${lang}-${region.toLowerCase()}`
      return { [urlLocale]: urlLocale }
    })
  ),
  'es-us': 'es-mx',
  'en-ie': 'en-gb',
}

export const FALLBACK_LOCALE = 'en'

function getCountryRedirect({
  languageRedirects,
  country,
  lowerCaseSlug,
}: {
  languageRedirects: Record<string, string>
  country: string
  lowerCaseSlug: string
}): string | undefined {
  const lowerCaseCountry = country.toLowerCase()
  const countryRedirects = languageRedirects[lowerCaseCountry] || {}
  const languageRedirect = languageRedirects['??']
    ? languageRedirects['??'][lowerCaseSlug]
    : undefined

  return countryRedirects[lowerCaseSlug] || languageRedirect
}

function parseAcceptLanguage(acceptLanguage: string | undefined): string[] {
  if (!acceptLanguage) return [FALLBACK_LOCALE]
  const sortedLocalesWithQuality = acceptLanguage
    .split(',')
    .map(langRegionQuality => {
      const [locale, qualityString = '1'] = langRegionQuality.split(';q=')
      return { locale, quality: parseFloat(qualityString) }
    })
    .sort((a, b) => b.quality - a.quality)

  return sortedLocalesWithQuality.map(({ locale }) =>
    locale.toLowerCase().trim()
  )
}

function getPreferredLanguage(preferredLanguages: string[]) {
  return preferredLanguages.find(preferredLanguage =>
    languages.find(({ code, language }) =>
      REGIONAL_LOCALES.includes(code)
        ? code.replace('_', '-').toLowerCase() === preferredLanguage
        : language === preferredLanguage
    )
  )
}

function getRedirect(currentUrl: string): string | undefined {
  const { lang, country, slug } = splitUrl(currentUrl)

  if (!lang || !country) return undefined

  const lowerCaseLang = lang.toLowerCase()
  const lowerCaseSlug = slug ? slug.toLowerCase() : '/'
  const languageRedirects = REDIRECT_DICTIONARY[lowerCaseLang]

  if (!languageRedirects) return undefined

  return getCountryRedirect({ languageRedirects, country, lowerCaseSlug })
}

function getDefaultMarketplacePrefix(
  cookieLocale?: string,
  acceptLanguage?: string,
  customerCountry?: string
) {
  const redirectCountry =
    countries.find(({ code }) => code === customerCountry) || countries[0] // Global is the first country

  const redirectLocale =
    cookieLocale ||
    getPreferredLanguage(parseAcceptLanguage(acceptLanguage)) ||
    redirectCountry.defaultLocale ||
    FALLBACK_LOCALE
  const redirectCountryAbv = redirectCountry.code.toLocaleLowerCase()
  return `/${redirectLocale}/${redirectCountryAbv}`
}

function redirectWithDefaults(
  cookieLocale?: string,
  acceptLanguage?: string,
  customerCountry?: string,
  slug?: string
): string {
  const marketplacePrefix = getDefaultMarketplacePrefix(
    cookieLocale,
    acceptLanguage,
    customerCountry
  )
  return `${marketplacePrefix}${slug}`
}

function redirectCountrySlug(currentUrl: string): string | undefined {
  const [, lang, country, slug] = currentUrl.split('/')
  const countrySlugs = REDIRECT_COUNTRY_SLUGS[country]
  if (!countrySlugs) return undefined
  return countrySlugs[slug]
    ? `/${lang}/${country}/${countrySlugs[slug]}`
    : undefined
}

function redirectBlogUrls(currentUrl: string) {
  const [, countryLang, ...slug] = currentUrl.split('/')
  const [lang, country] = countryLang.split('-')

  const redirectLocaleCountry = BLOG_REDIRECT_LOCALE_COUNTRY[countryLang]

  // for blog if no explicit redirect is present in dictionary
  // original url lang country is to be used
  // slug[0] will be blog, so move it a before locale/country -> eg. https://recharge.com/en-ie/blog -> https://www.recharge.com/blog/en-gb/ie
  return redirectLocaleCountry
    ? ['', slug[0], redirectLocaleCountry, ...slug.slice(1)]
        .join('/')
        .replace(/\/$/, '')
    : ['', slug[0], lang, country, ...slug.slice(1)]
        .join('/')
        .replace(/\/$/, '')
}

function redirectToGlobalMarketplace(currentUrl: string): string | undefined {
  const [, countryLang, ...slug] = currentUrl.split('/')

  if (!countryLang.includes('-')) return undefined
  if (
    slug[0] &&
    slug[0].length === 2 &&
    !TWO_CHARACTER_LMP_PRODUCT_PAGES.includes(slug[0])
  )
    return undefined // We are on a GMP dialect (e.g. es-mx/mx)

  const [lang, country] = countryLang.split('-')

  const redirects = REDIRECT_GMP_DICTIONARY[country]

  if (!redirects) return undefined

  const redirectSlug = redirects[slug.join('/')]

  if (!redirectSlug && slug[0] === 'blog') return redirectBlogUrls(currentUrl)

  const redirectLang = REGIONAL_LANGUAGE_REDIRECTS[countryLang] || lang

  return redirectSlug?.startsWith('https')
    ? redirectSlug
    : ['', redirectLang, country, redirectSlug].join('/').replace(/\/$/, '')
}

const redirectionMiddleware: Middleware = (context: Context): void => {
  const { store } = context

  const isAuthenticated = store.getters['user/isAuthenticated']

  if (!process.server) {
    // In order to redirect client navigation from LMP (blog)
    if (store.getters['context/isGlobalMarketplace']) return
  }

  if (process.server) {
    // Most redirects are made on server side. Let's keep measuring there.
    startMeasurement(
      `server:middleware:redirection -> ${context.route.fullPath}`
    )
  }

  const {
    route: { path, fullPath },
    route,
    redirect,
    $sentry,
    req,
    app,
  } = context
  if (path === '/')
    redirect(
      TEMPORARY_REDIRECT_STATUS_CODE,
      getDefaultMarketplacePrefix(
        app.$cookies.get(GMP_LOCALE_COOKIE_NAME),
        req.headers['accept-language'],
        req.headers['cloudfront-viewer-country'] as string | undefined
      )
    )

  if (route.name === REFER_A_FRIEND_INVITATION_PAGE_WITHOUT_LOCALE) {
    const { referralCode } = route.params
    let redirectPath = `${getDefaultMarketplacePrefix(
      app.$cookies.get(GMP_LOCALE_COOKIE_NAME),
      req.headers['accept-language'],
      req.headers['cloudfront-viewer-country'] as string | undefined
    )}`

    if (!isAuthenticated) {
      redirectPath = `${redirectPath}/i/${referralCode}?utm_source=web&utm_medium=refer-a-friend&utm_campaign=${referralCode}`
    }
    redirect(TEMPORARY_REDIRECT_STATUS_CODE, redirectPath)
  }

  // Classic exception
  if (path.match(/^\/[a-zA-z]{2}\/o2$/)) {
    const language = req.url ? req.url.split('/')[1] : 'en'
    const o2RedirectCountry = language === 'en' ? 'gb' : 'de'
    redirect(
      PERMANENT_REDIRECT_STATUS_CODE,
      `/${language}/${o2RedirectCountry}/o2-mobile?utm_source=recharge-classic&utm_medium=redirect&utm_campaign=gmp`
    )
  }

  if (req.url) {
    const [, language, country, pathName] = req.url.split('/')
    if (AUTH_ROUTES.includes(pathName)) {
      redirect(
        PERMANENT_REDIRECT_STATUS_CODE,
        `/${language}/${country}?auth=${pathName}`
      )
    }
  }

  const [needsDefaults, slug] = fullPath.match(/^\/global(.*)/) || []
  if (needsDefaults)
    redirect(
      TEMPORARY_REDIRECT_STATUS_CODE,
      redirectWithDefaults(
        app.$cookies.get(GMP_LOCALE_COOKIE_NAME),
        req.headers['accept-language'],
        req.headers['cloudfront-viewer-country'] as string | undefined,
        slug
      )
    )

  const [, language, country, pathName] = fullPath
    .toLocaleLowerCase()
    .split('/')

  // Add redirect from prepaid-credit-cards to payment-cards.
  if (pathName && pathName.includes('prepaid-credit-cards')) {
    redirect(
      PERMANENT_REDIRECT_STATUS_CODE,
      `/${language}/${country}/${pathName.replace(
        'prepaid-credit-cards',
        'payment-cards'
      )}`
    )
  }

  // redirect to home for crypto slugs
  if (CRYPTO_SLUGS.includes(pathName) && CRYPTO_COUNTRIES.includes(country))
    redirect(PERMANENT_REDIRECT_STATUS_CODE, `/${language}/${country}`)

  const lowerCasePath = path.toLowerCase()
  const blogUrlRedirect = BlogUrlRedirections[lowerCasePath]
  const classicRedirect = getRedirect(lowerCasePath)
  const gmpRedirect = redirectToGlobalMarketplace(lowerCasePath)
  const countrySlugRedirect = redirectCountrySlug(lowerCasePath)

  const redirectionUrl =
    blogUrlRedirect || classicRedirect || gmpRedirect || countrySlugRedirect

  if (!redirectionUrl) return

  const encodedRedirectionUrl = encodeURI(redirectionUrl)

  try {
    if (!process.server && store.getters['context/isLocalMarketplace']) {
      // Redirects from blog while it's still on LMP
      const prefix = window.location.host
      const slash = encodedRedirectionUrl.indexOf('/') === 0 ? '' : '/'
      const path = [prefix, slash, encodedRedirectionUrl].join('')
      redirect(PERMANENT_REDIRECT_STATUS_CODE, `//${path}`)
    } else {
      redirect(PERMANENT_REDIRECT_STATUS_CODE, encodedRedirectionUrl)
    }
  } catch (error) {
    $sentry.captureException(error)
  } finally {
    if (process.server) {
      // Most redirects are made on server side. Let's keep measuring there.
      endMeasurement(
        `server:middleware:redirection -> ${context.route.fullPath}`
      )
    }
  }
}

export default redirectionMiddleware
