import { normalizeProduct, ProductData } from './product'
import {
  RecommerceVariant,
  RedeemType,
} from '~/apis/clients/rest/recommerce/types'
import { RecommerceContentError } from '~/apis/error/RecommerceContentError'
import { Maybe } from '~/apis/clients/graphql/types/contentful'

export type VariantData = {
  brandCategory: string
  baseCategory: string
  brandSlug: string
  brandInfo: ProductData
  brandTitle: ProductData['name']
  productListComponent: ProductData['productListComponent']
  sectionAccordion: ProductData['sectionAccordion']
  sectionFreeForm: ProductData['sectionFreeForm']
  maximumQuantity?: Maybe<number>
  sectionProductBanner: {
    product: {
      productId: number
      name: string
      title: string
    }
    productBannerDescription: string
    productImage: ProductData['logo']
  }
  description: string
  parentDenominationType: string
  productId: number
  product: ProductData
  showCountryRestricted: boolean
  title: string
  logo: ProductData['logo']
  isCustomDenomination: boolean
  customDenominationMinValue?: Maybe<number>
  customDenominationMaxValue?: Maybe<number>
  productCardImage: ProductData['productCardImage']
  type: RedeemType
  redirectToCustomerCountry: boolean
  showOnlyInCustomerCountry: boolean
  priceCurrency: string
}

export const normalizeVariant = ({
  id,
  name,
  product,
  redirectToCustomerCountry,
  showOnlyInCustomerCountry,
  isCustomDenomination,
  maxQuantity,
  type,
  priceCurrency,
}: RecommerceVariant): VariantData => {
  if (!name) throw new RecommerceContentError(`Variant name missing for ${id}`)
  const normalizedProduct = normalizeProduct(product)

  return {
    brandCategory: product.mainCategory.name,
    baseCategory: product?.baseCategory?.name ?? '',
    brandSlug: normalizedProduct.slug,
    brandInfo: normalizedProduct,
    brandTitle: normalizedProduct.name,
    productListComponent: normalizedProduct.productListComponent,
    sectionAccordion: normalizedProduct.sectionAccordion,
    sectionFreeForm: normalizedProduct.sectionFreeForm,
    sectionProductBanner: {
      product: {
        productId: id,
        name,
        title: name,
      },
      productBannerDescription: '1 month', // hard-coded until Recommerce is ready
      productImage: normalizedProduct.logo,
    },
    logo: normalizedProduct.logo,
    productCardImage: normalizedProduct.productCardImage,
    maximumQuantity: maxQuantity || undefined,
    description: '1 month', // hard-coded until Recommerce is ready
    parentDenominationType: 'brand', // hard-coded until ...
    priceCurrency,
    productId: id,
    product: normalizedProduct,
    isCustomDenomination,
    customDenominationMinValue: normalizedProduct.customDenominationMinValue,
    customDenominationMaxValue: normalizedProduct.customDenominationMaxValue,
    type,
    showCountryRestricted:
      showOnlyInCustomerCountry || redirectToCustomerCountry,
    title: name,
    redirectToCustomerCountry,
    showOnlyInCustomerCountry,
  }
}
