
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import { ButtonIcon } from '~/components/atoms'

import OverlayNotificationBar from '~/components/organisms/overlay-notification-bar/index.vue'

export default {
  components: { OverlayNotificationBar, ButtonIcon },
  props: {
    title: {
      type: String,
      default: '',
    } as PropOptions<string>,
    desc: {
      type: String,
      default: '',
    } as PropOptions<string>,
    name: {
      type: String,
      default: 'Overlay',
    } as PropOptions<string>,
    fullscreen: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    disableCloseButton: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    withoutInsets: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    grayBackground: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    smallDeviceNoXInsets: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    scrollableModal: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    addedModalStyling: {
      type: Object,
      default: () => ({}),
    },
    slimWidth: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    bottomModal: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('context', ['isGlobalMarketplace']),
    ...mapGetters('ui', ['overlayVisibility']),
  },
  mounted() {
    const bodyHTMLElement = document.querySelector('body') as HTMLBodyElement
    bodyHTMLElement.classList.add('overflow-hidden')
  },
  destroyed() {
    const bodyHTMLElement = document.querySelector('body') as HTMLBodyElement
    bodyHTMLElement.classList.remove('overflow-hidden')
  },
  methods: {
    changeLoaderStatus(status) {
      this.isLoading = status
    },
    onOverlayClose(): void {
      this.$store.dispatch('ui/closeOverlay')
      this.$emit('overlay:close')
    },
    closeOverlayDirective() {
      if (!this.fullscreen) {
        this.onOverlayClose()
      }
    },
    closeClicked(): void {
      this.onOverlayClose()
      this.$emit('overlay:closeClicked')
    },
  },
}
