import { isPresent } from 'ts-is-present'
import { Document } from '../base/document'
import { Maybe } from '~/apis/clients/graphql/types/contentful'
import { ILink, TLink } from '~/models/base/Link'

import { IHeader } from '~/models/components/Header'
import { IHeaderLink } from '~/models/components/HeaderLink'
import { IUsps } from '~/models/components/Usps'
import { transformImage, TContentfulImage } from '~/normalizers/base/image'
import {
  transformLink,
  transformPageLink,
  TContentfulLink,
  TContentfulExternalLink,
  TContentfulPageLink,
} from '~/normalizers/base/link'

export type TContentfulUsps = {
  items: Maybe<{
    text: Maybe<Document>
    icon: Maybe<TContentfulImage>
  }>[]
}

function normalizeUsps(uspsCollection: TContentfulUsps): IUsps[] {
  return (
    uspsCollection?.items?.filter(isPresent).map(({ text, icon }) => {
      return {
        text,
        icon: icon ? transformImage(icon) : undefined,
      }
    }) || []
  )
}

export type TContentfulBrandMenu = {
  items: Maybe<TContentfulPageLink>[]
}

function normalizeBrandMenu(
  brandMenuCollection: TContentfulBrandMenu
): ILink[] {
  return (
    brandMenuCollection?.items.filter(isPresent).map(brandItem => {
      return transformPageLink(brandItem)
    }) || []
  )
}

export type TContentfulMenuItem = {
  title: Maybe<string>
  icon: Maybe<string>
  kindPage: Maybe<{
    slug: Maybe<string>
  }>
  externalLink: Maybe<TContentfulExternalLink>
  brandMenuCollection: Maybe<TContentfulBrandMenu>
}

export type TContentfulMenu = {
  items: Maybe<TContentfulMenuItem>[]
}

function normalizeMenu(menuCollection: TContentfulMenu): IHeaderLink[] {
  return menuCollection?.items?.filter(isPresent).map(menuItem => {
    const {
      brandMenuCollection,
      externalLink,
      kindPage,
      icon,
      title,
    } = menuItem

    const brandMenu = brandMenuCollection
      ? normalizeBrandMenu(brandMenuCollection)
      : []

    const newTab = externalLink ? externalLink.newTab : undefined
    const url = externalLink ? externalLink.url : undefined
    const slug = kindPage ? kindPage.slug : undefined

    return {
      brandMenu,
      icon: icon || '',
      newTab,
      slug: slug || '',
      title: title || '',
      url: url || '',
    }
  })
}

export type TContentfulHeader = {
  menuCollection: TContentfulMenu
  uspsCollection: TContentfulUsps
  logo: TContentfulImage
  legalLink?: TContentfulLink
}

interface IContenfulHeaderParams extends TContentfulHeader {
  groupLinksCollection: TLink[]
}

export function normalizeHeader({
  legalLink,
  menuCollection,
  uspsCollection,
  logo,
  groupLinksCollection,
}: IContenfulHeaderParams): IHeader {
  const menu = normalizeMenu(menuCollection)

  const usps = normalizeUsps(uspsCollection)

  return {
    legalLink: legalLink && transformLink(legalLink),
    menu,
    usps,
    logo: transformImage(logo),
    showAccount: false,
    secondaryLinks: groupLinksCollection,
  }
}
