
import { PropOptions } from 'vue'
import { IContact } from '~/models/components/Contacts'
import { UiTitle } from '~/components/atoms'
import { ContactCard } from '~/components/molecules'

export default {
  name: 'ContactsLetterGroup',
  components: {
    UiTitle,
    ContactCard,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    } as PropOptions<IContact[]>,
    groupingLetter: {
      type: String,
      required: true,
    } as PropOptions<string>,
    direction: {
      type: String,
      default: 'ltr',
    } as PropOptions<'ltr' | 'rtl'>,
  },
  computed: {
    marginForEmptyGroupingLetter() {
      return this.groupingLetter === '' ? 'mt-6 lg:mt-7' : ''
    },
  },
}
