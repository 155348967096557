import { EntryModel } from '../base/Entry'
import { ProductModel, IProduct } from './Product'
import mapWithFilter from '~/services/map-with-filter'

export interface IProductList {
  title: string
  products: IProduct[]
  categories?: IProduct[]
}

export class ProductListModel extends EntryModel {
  protected readonly title: string
  protected readonly products: IProduct[]

  constructor(productListModel) {
    super(productListModel)

    const { fields } = productListModel
    const { title } = fields

    this.cmsIdentifier = 'internalName'
    this.title = title
    this.products = mapWithFilter(
      this.requiredField('products'),
      product => new ProductModel(product).toJSON(),
      'fields'
    )
  }

  toJSON(): IProductList {
    return {
      title: this.title,
      products: this.products,
      categories: this.products,
    }
  }
}
