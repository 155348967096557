import { IImageModel, IRecommerceImageModel } from '../base/Image'
import { ICMSStructure } from '../layout/CMSStructure'
import get from '~/utils/get'
import { CategoryTypes } from '~/enums/categories'
import { transformImage } from '~/normalizers/base/image'
import { Maybe } from '~/apis/clients/graphql/types/topup'

export interface IOrderProductCode {
  code: string
  serialNumber?: Maybe<string>
  sicherheitsCode?: Maybe<string>
  redeemUrl?: Maybe<string>
  hidden?: Maybe<boolean>
}

export interface ITopupOrderProductCode {
  code: string
  /* eslint-disable camelcase */
  serial_number?: Maybe<string>
  sicherheits_code?: Maybe<string>
  redeem_url?: Maybe<string>
  /* eslint-enable camelcase */
  hidden?: Maybe<boolean>
}

export interface ITopupOrderProduct {
  id: string
  name: string
  /* eslint-disable camelcase */
  products_service_id: string
  product_kind?: string
  brand_name?: string
  redeem_type?: string
  scam_notification_type?: string | null
  /* eslint-enable camelcase */
  codes?: ITopupOrderProductCode[]
  available?: boolean
  visible?: boolean
}

export interface IOrderProduct {
  logo?: IImageModel | IRecommerceImageModel
  title: string
  codes: IOrderProductCode[]
  productId: string
  description?: Record<string, unknown>
  brandTitle?: string
  brandCategory?: string
  baseCategory?: string
  nominationType?: string
}

export interface ICMSOrderProduct {
  logo: any
  productFeedImage: any
  title: string
  productId: number
  parentBrand: ICMSStructure
  description?: Record<string, unknown>
}
export class OrderProductModel {
  protected readonly logo?: IImageModel | IRecommerceImageModel
  protected readonly title: string
  protected readonly productId: string
  protected readonly description?: Record<string, unknown>
  protected readonly brandTitle?: string
  protected readonly brandCategory?: CategoryTypes
  protected readonly codes: IOrderProductCode[]
  protected readonly nominationType: string

  constructor(
    { name, codes, products_service_id: productId }: ITopupOrderProduct,
    product?: ICMSOrderProduct
  ) {
    if (product) {
      const {
        logo,
        title,
        description,
        parentBrand,
        productFeedImage,
      } = product

      this.brandCategory = get(parentBrand, 'brandCategory')
      this.brandTitle = get(parentBrand, 'title')
      this.nominationType = get(parentBrand, 'parentPage.pageType')
      this.title = title

      if (logo) {
        this.logo = logo.contentType ? transformImage(logo) : logo
      } else {
        this.logo = transformImage(productFeedImage)
      }
      this.description = description
    }

    this.productId = productId
    this.title = this.title || name
    if (codes) {
      this.codes = codes.map(
        ({
          code,
          serial_number: serialNumber,
          sicherheits_code: sicherheitsCode,
          redeem_url: redeemUrl,
          hidden,
        }) => ({
          code,
          serialNumber,
          sicherheitsCode,
          redeemUrl,
          hidden,
        })
      )
    }
  }

  toJSON(): IOrderProduct {
    return {
      logo: this.logo,
      title: this.title,
      productId: this.productId,
      description: this.description,
      codes: this.codes,
      brandTitle: this.brandTitle,
      brandCategory: this.brandCategory,
      nominationType: this.nominationType,
    }
  }
}
