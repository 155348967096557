<template>
  <skeleton v-if="skeleton" :width="skeletonWidth" height="6" />
  <a v-else-if="fakeUrl" :class="classes" v-on="$listeners">
    <slot />
  </a>
  <a
    v-else-if="isModal"
    :href="url"
    :class="classes"
    @click.prevent.stop="openModal(modalName)"
  >
    <slot />
  </a>
  <a
    v-else-if="isExternal || hasCustomBehavior"
    :href="url"
    :class="classes"
    :rel="rel"
    :target="target"
    v-on="$listeners"
  >
    <slot />
  </a>
  <nuxt-link
    v-else
    :to="$contextPath(url)"
    :class="classes"
    :rel="rel || noFollowRel"
    :target="target"
    @click.native="$emit('click')"
  >
    <slot />
  </nuxt-link>
</template>
<script>
import VueTypes from 'vue-types'
import { mapActions } from 'vuex'
import { isExternalLink, isModalLink } from '~/services/url'
import Skeleton from '~/components/utils/skeleton/index.vue'

export default {
  name: 'Link',
  components: {
    Skeleton,
  },
  props: {
    bold: Boolean,
    color: VueTypes.string.def('link'),
    blank: Boolean,
    disable: Boolean,
    invert: Boolean,
    wrapper: Boolean,
    hasCustomBehavior: Boolean,
    underline: Boolean,
    rel: VueTypes.oneOfType([String, Boolean]),
    noFollow: Boolean,
    url: VueTypes.string.def('#'),
    size: VueTypes.string.def(''),
    noUnderline: VueTypes.bool.def(false),
    enableActiveClass: VueTypes.bool.def(false),
    skeleton: VueTypes.bool.def(false),
    skeletonWidth: {
      type: String,
      default: 'full',
    },
    fakeUrl: VueTypes.bool.def(false),
  },

  computed: {
    noFollowRel() {
      return this.noFollow ? 'noFollow' : ''
    },
    classes() {
      if (this.wrapper) {
        return 'cursor-pointer'
      }

      const underline = this.underline ? 'underline' : 'no-underline'
      const color = this.invert ? 'text-white' : `text-${this.color}`
      const hoverUnderline =
        this.disable || this.noUnderline ? '' : 'hover:underline'
      const disable = this.disable ? 'opacity-20 cursor-not-allowed' : ''
      const font = this.size ? 'text-base' : ''
      const weight = this.bold ? 'font-bold' : ''
      const customActiveClass = this.enableActiveClass
        ? 'enable-active-class'
        : ''
      const cursor = this.fakeUrl ? 'cursor-pointer' : ''

      return [
        'leading-normal',
        underline,
        font,
        weight,
        color,
        hoverUnderline,
        disable,
        customActiveClass,
        cursor,
      ]
    },
    target() {
      return this.blank ? '_blank' : '_self'
    },

    isExternal() {
      return isExternalLink(this.url)
    },
    isModal() {
      return isModalLink(this.url)
    },
    modalName() {
      return this.url.replace('modal://', '')
    },
  },
  methods: {
    ...mapActions('ui/content-modal', ['openModal']),
  },
}
</script>

<style>
.enable-active-class.nuxt-link-exact-active,
.enable-active-class.nuxt-link-active {
  color: rgba(51, 146, 255, 1);
}
</style>
