var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-image',{class:[
    'flag block border border-gray-light',
    _vm.width ? '' : ' w-auto',
    _vm.addedClasses,
  ],style:({
    height: _vm.height + 'px',
    ...(_vm.width ? { width: _vm.width + 'px' } : {}),
  }),attrs:{"src":require(`~/assets/images/flags/${_vm.country.toLowerCase()}.svg`)}})
}
var staticRenderFns = []

export { render, staticRenderFns }