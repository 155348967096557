import uuid from 'uuid/v4'
import { RECHARGE_SESSION_ID } from '~/plugins/request-client'

export default {
  state() {
    return {}
  },

  actions: {
    nuxtServerInit({ dispatch }, { app, query, route }) {
      dispatch('setRechargeSessionCookie', { app })
      dispatch('setRiskifiedCookie', { app })
      dispatch('context/isRedirectedFromRapido', query)
      const countryAbv = route.path.split('/')[1]
      if (countryAbv && countryAbv.length !== 2) {
        dispatch('context/setConfigFromLocales', { route, app })
      }
      return {}
    },
    setRiskifiedCookie(_, { app }) {
      if (!app.$cookies.get('RiskifiedSessionId')) {
        app.$cookies.set('RiskifiedSessionId', uuid(), { path: '/' })
      }
    },
    setRechargeSessionCookie(_, { app }) {
      if (!app.$cookies.get('recharge_session_id')) {
        app.$cookies.set(
          'recharge_session_id',
          app.$cookies.get(RECHARGE_SESSION_ID) || uuid(),
          { path: '/' }
        )
      }
    },
  },
}
