import { Plugin, Context } from '@nuxt/types'
import { GRAPHQL_CLIENT, REST_CLIENT } from '~/enums'
import {
  ContentfulSdk,
  TopupSdk,
  LazyLoaded,
  SdkDefaultOptions,
} from '~/apis/clients/graphql/create-sdk'
import recommerceFetch, {
  RecommerceApiEndpointFunctions,
} from '~/apis/clients/rest/recommerce'
declare module 'vue/types/vue' {
  interface Vue {
    $contentful: LazyLoaded<ContentfulSdk>
    $topup: LazyLoaded<TopupSdk>
    $recommerce: RecommerceApiEndpointFunctions
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $contentful: LazyLoaded<ContentfulSdk>
    $topup: LazyLoaded<TopupSdk>
    $recommerce: RecommerceApiEndpointFunctions
  }
}

export const RECHARGE_SESSION_ID = 'recharge_session_id'

const requestClientPlugin: Plugin = (context: Context, inject) => {
  const {
    req,
    route: {
      query: { preview },
    },
    store,
  } = context

  const defaultOptions: SdkDefaultOptions = {
    ...(preview ? { fetchPolicy: 'no-cache' as const } : {}),
    context: req?.headers['cloudfront-viewer-country']
      ? {
          headers: {
            'x-forwarded-viewer-country':
              req.headers['cloudfront-viewer-country'],
          },
        }
      : {
          headers: {},
        },
  }

  inject(GRAPHQL_CLIENT.TOPUP, method => async (...args) => {
    if (context.$auth.storage.getItem('id_token') && defaultOptions.context) {
      defaultOptions.context.headers = {
        ...defaultOptions.context.headers,
        Authorization: `Bearer ${context.$auth.storage.getItem('id_token')}`,
      }
    }
    const { loadTopup } = await import('./load-topup')
    return loadTopup(context, defaultOptions)[method](...args)
  })
  inject(GRAPHQL_CLIENT.CONTENTFUL, method => async (...args) => {
    const { loadContentful } = await import('./load-contentful')
    return loadContentful(context, defaultOptions)[method](...args)
  })

  inject(REST_CLIENT.RECOMMERCE, recommerceFetch(store))
}

export default requestClientPlugin
