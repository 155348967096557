import { GTMEvents } from '../enums'
import { IECommerceProducts, ECommerceProducts } from './ECommerceProducts'

export interface IAddToCardModel {
  event: GTMEvents
  ecommerce: {
    currencyCode: string
    add: IECommerceProducts
  }
}

export interface reorderAnalyticsData {
  productId: string
  quantity: number
  currency?: string
  available?: boolean
  // eslint-disable-next-line camelcase
  product_kind?: string
  productBrand?: string
  productCategory?: string
  discontinued?: boolean
  invalidProduct?: boolean
  redeemType?: string
  scamNotificationType?: string | null
  title: string
  serviceFee?: string
  brandTitle?: string
  price: {
    currency?: string
    discount: number
    discountPercent: number
    localAmount: number
    subtotal: number
    total: number
  }
  position?: string
}

export class AddToCartModel {
  private readonly add: IECommerceProducts
  private readonly currency: string
  constructor({ product, currency, addList, listValue }) {
    this.currency = currency
    this.add = new ECommerceProducts(product, addList, listValue).toJSON()
  }

  toJSON(): IAddToCardModel {
    return {
      event: GTMEvents.ADD_TO_CART,
      ecommerce: {
        currencyCode: this.currency,
        add: this.add,
      },
    }
  }
}
