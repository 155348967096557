/* eslint-disable camelcase */
import { TStockStatus } from './ECommerceProducts'

export interface IImpression {
  id: string
  stock_status: TStockStatus
  name: string
  category: string
  brand: string
  list: string
  position: string
  price: string
  product_price_stored: string
  quantity: string
  product_brand: string | null
  product_theme: string | null
}

export class Impression {
  private readonly id: string
  private readonly stockStatus: TStockStatus
  private readonly name: string
  private readonly category: string
  private readonly brand: string
  private readonly parentDenomination: {
    product_brand: string | null
    product_theme: string | null
  }

  private readonly list: string
  private position: string
  private readonly price: string
  private readonly priceStored: string
  private readonly quantity: string = '1'

  constructor(
    {
      productId,
      available,
      title,
      brandCategory,
      brand_name: brandName,
      brandTitle: parentDenominationTitle,
      parentDenominationType,
      price: { localAmount = 0 } = {},
      position,
    },
    index: number
  ) {
    const stringifiedLocalAmount = localAmount ? localAmount.toString() : ''
    const hasThemeParentDenomination = parentDenominationType === 'theme'
    const productBrand = hasThemeParentDenomination ? null : brandName
    const productTheme = hasThemeParentDenomination
      ? parentDenominationTitle
      : null
    this.id = productId.toString()
    this.stockStatus = available ? 'on_stock' : 'out_of_stock'
    this.name = title
    this.category = brandCategory
    this.brand = parentDenominationTitle
    this.list = `${brandCategory}+${parentDenominationTitle}`
    this.position = (position || index + 1).toString()
    this.price = stringifiedLocalAmount
    this.priceStored = stringifiedLocalAmount
    this.parentDenomination = {
      product_brand: productBrand,
      product_theme: productTheme,
    }
  }

  toJSON(): IImpression {
    const {
      parentDenomination,
      id,
      name,
      category,
      brand,
      list,
      position,
      price,
      stockStatus: stock_status,
      priceStored: product_price_stored,
      quantity,
    } = this
    const { product_brand, product_theme } = parentDenomination

    return {
      id,
      stock_status,
      name,
      category,
      brand,
      product_brand,
      product_theme,
      list,
      position,
      product_price_stored,
      price,
      quantity,
    }
  }
}
/* eslint-enable camelcase */
