export enum CheckoutSteps {
  STEP_ONE = '1',
  STEP_TWO = '2',
  STEP_THREE = '3',
  STEP_FOUR = '4',
  STEP_FIVE = '5',
  PSC_DETAILS = '6',
  MOBILEVERIFICATION_LOADED = '7',
  MOBILEVERIFICATION_VERIFY = '8',
}

export enum CheckoutPaymentFormSteps {
  EMAIL_INPUT_STEP = 'EMAIL_INPUT_STEP',
  PAYMENT_METHOD_SELECTION_INPUT_STEP = 'PAYMENT_METHOD_SELECTION_INPUT_STEP',
  ISSUER_SELECTION_STEP = 'ISSUER_SELECTION_STEP',
  BILLING_INFO_INPUT_STEP = 'BILLING_INFO_INPUT_STEP',
}
