import { Route } from 'vue-router'

function getElements(): Node[] {
  const iterator: XPathResult = document.evaluate(
    "//*[contains(text(), 'phrase__')]",
    document,
    null,
    XPathResult.ANY_TYPE,
    null
  )
  let currentElement: Node | null = iterator.iterateNext()
  const elements: Node[] = []

  if (!currentElement) return []

  while (currentElement) {
    elements.push(currentElement)
    currentElement = iterator.iterateNext()
  }

  return elements.filter(e => e)
}

function highlightElements(elements: Node[]): void {
  if (!elements.length) return

  elements.forEach((el: HTMLElement) => {
    if (el && el.classList) {
      el.classList.add('border-4')
      // eslint-disable-next-line no-param-reassign
      el.style.border = '4px solid red'
    }
  })
}

function getPhraseKeysFromElements(elements: Node[]): string[] {
  const allKeys = elements.map((el: HTMLElement) =>
    (el.textContent as string).trim().replace('phrase__', '')
  )
  const filterKeysSet = new Set(allKeys)
  return Array.from(filterKeysSet)
}

function buildCounter(elements: Node[]): void {
  if (!elements.length) return

  const body: HTMLBodyElement | null = document.querySelector('body')
  if (!body) return

  const phraseappKeys: string[] = getPhraseKeysFromElements(elements)
  const node: HTMLDivElement = document.createElement('div')
  node.setAttribute('id', 'phraseapp-counter')
  node.style.backgroundColor = 'red'
  // node.style.opacity = '0.1'
  node.classList.add(
    'p-6',
    'm-6',
    'fixed',
    'start-0',
    'top-0',
    'text-white',
    'z-50'
  )

  const counter: HTMLHeadingElement = document.createElement('h1')
  counter.classList.add('text-white')
  counter.textContent = `Phraseapp keys found: ${phraseappKeys.length}`

  node.appendChild(counter)

  phraseappKeys.forEach(key => {
    const keyNode: HTMLParagraphElement = document.createElement('p')
    keyNode.classList.add('mb-4')
    keyNode.textContent = key
    node.appendChild(keyNode)
  })

  const closeLink = document.createElement('p')
  closeLink.textContent = 'CLOSE'
  closeLink.style.textDecoration = 'underline'
  closeLink.onclick = (): void => {
    const elCounter = document.getElementById('phraseapp-counter')
    if (elCounter) elCounter.remove()
  }

  node.appendChild(closeLink)

  body.appendChild(node)
}

export function showPhraseKeys(route: Route): void {
  const showPhraseappKeys = route.query.showKeys

  if (!showPhraseappKeys) return

  setTimeout(() => {
    const elements = getElements()
    highlightElements(elements)
    buildCounter(elements)
  }, 1000)
}
