import { datadogRum } from '@datadog/browser-rum'

const DatadogRumPlugin = (): void => {
  datadogRum.init({
    applicationId: process.env.DATADOG_RUM_APPID || '',
    clientToken: process.env.DATADOG_RUM_TOKEN || '',
    site: process.env.DATADOG_RUM_SITE,
    service: process.env.DATADOG_RUM_SERVICE,
    env: process.env.DATADOG_RUM_ENV,
    sessionSampleRate: parseInt(
      process.env.DATADOG_RUM_SESSION_SAMPLE_RATE || '0',
      10
    ),
    sessionReplaySampleRate: parseInt(
      process.env.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE || '0',
      10
    ),
    trackResources: process.env.DATADOG_RUM_TRACK_RESOURCES === 'true',
    silentMultipleInit: process.env.DATADOG_RUM_SILENT_MULTIPLE_INIT === 'true',
    version: process.env.RELEASE_SHA,
    trackViewsManually: true,
    beforeSend: (event, _) => {
      if (process.env.RELEASE_TIMESTAMP && event.type === 'view') {
        datadogRum.setGlobalContext({
          versionTimestamp: Date.parse(process.env.RELEASE_TIMESTAMP),
        })
      }
    },
  })
}

export default DatadogRumPlugin
