export default {
  allegro: 'allegro',
  'allegro/100-pln': 'allegro',
  'allegro/25-pln': 'allegro/25-pln',
  'allegro/50-pln': 'allegro/50-pln',
  'amazon-de': 'amazon',
  'amazon-de/': 'amazon',
  'amazon-de/10-eur': 'amazon/10-eur-de',
  'amazon-de/100-eur': 'amazon/100-eur-de',
  'amazon-de/15-eur': 'amazon/15-eur-de',
  'amazon-de/25-eur': 'amazon/25-eur-de',
  'amazon-de/50-eur': 'amazon/50-eur-de',
  'amazon-pl': 'amazon',
  'amazon-pl/100-pln': 'amazon/100-pln',
  'amazon-pl/200-pln': 'amazon/200-pln',
  'app-store-itunes': 'app-store-itunes',
  'app-store-itunes/': 'app-store-itunes',
  'app-store-itunes/100-pln': 'app-store-itunes/100-pln',
  'app-store-itunes/150-pln': 'app-store-itunes/150-pln',
  'app-store-itunes/20-pln': 'app-store-itunes/20-pln',
  'app-store-itunes/50-pln': 'app-store-itunes/50-pln',
  battlenet: 'blizzard',
  bigpoint: 'bigpoint',
  'bigpoint/50-pln-dark-orbit': 'bigpoint',
  'bigpoint/50-pln-farmerama': 'bigpoint',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  cashlib: 'cashlib',
  'cashlib/10-pln': 'cashlib',
  cashu: 'prepaid-credit-cards',
  'cda-premium': 'cda-premium',
  'cda-premium/': 'cda-premium',
  'cda-premium/20-pln': 'cda-premium/20-pln',
  'cda-premium/55-pln': 'cda-premium/55-pln',
  'crypto-voucher': 'crypto-voucher',
  'doladuj-telefon': 'mobile-top-up',
  ecovoucher: 'ecovoucher',
  empik: 'empik',
  'empik/': 'empik',
  'empik/100-pln': 'empik/100-pln',
  'empik/25-pln': 'empik/25-pln',
  'eve-online': 'gaming-cards',
  'eve-online/1100-plex': 'gaming-cards',
  'eve-online/500-plex': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  'fifa-points/': 'gaming-cards',
  'fifa-points/1600-xbox-one': 'gaming-cards',
  'fifa-points/4600-xbox-one': 'gaming-cards',
  flexepin: 'flexepin',
  fortnite: 'fortnite',
  'free-fire': 'free-fire',
  'google-play': 'google-play',
  'google-play/': 'google-play',
  'google-play/150-pln': 'google-play/150-pln',
  'google-play/20-pln': 'google-play/20-pln',
  'google-play/50-pln': 'google-play/50-pln',
  'google-play/75-pln': 'google-play/75-pln',
  'guild-wars': 'gaming-cards',
  heyah: 'heyah',
  'informacje-o-nas': 'https://company.recharge.com',
  'jak-to-dziala': 'how-it-works',
  'karma-koin': 'karma-koin',
  'karma-koin/10-eur': 'karma-koin/10-eur',
  'karma-koin/25-eur': 'karma-koin/25-eur',
  'karty-podarunkowe': 'shopping-gift-cards',
  'karty-podarunkowe-streaming': 'entertainment-gift-cards',
  'kody-do-gier': 'gaming-cards',
  kryptowaluta: 'cryptocurrency',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends/10-eur',
  'league-of-legends/20-eur': 'league-of-legends/20-eur',
  lycamobile: 'lycamobile',
  'media-markt': 'mediamarkt',
  'metody-platnosci': 'payment-methods',
  moneyclic: 'prepaid-credit-cards',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin',
  'ncoin/100-eur': 'ncoin/100-eur',
  'ncoin/20-eur': 'ncoin/20-eur',
  'ncoin/5-eur': 'ncoin/5-eur',
  'ncoin/50-eur': 'ncoin/50-eur',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'netflix/': 'netflix',
  'netflix/120-pln': 'netflix/120-pln',
  'netflix/60-pln': 'netflix/60-pln',
  'netflix/80-pln': 'netflix/80-pln',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/': 'nintendo-eshop',
  'nintendo-eshop/120-pln': 'nintendo-eshop/120-pln',
  'nintendo-eshop/70-pln': 'nintendo-eshop/70-pln',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-months': 'nintendo-switch-online',
  'nintendo-switch-online/nintendo-switch-online-individual-membership-6-70-eur':
    'nintendo-switch-online',
  openbucks: 'openbucks',
  orange: 'orange',
  'orange/100-pln': 'orange/100-pln',
  'orange/200-pln': 'orange/200-pln',
  paysafecard: 'paysafecard',
  'paysafecard/100-pln': 'paysafecard/100-pln',
  pcs: 'pcs',
  play: 'play',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/12-miesiecy': 'playstation-plus/12-months-pl',
  'playstation-plus/3-months': 'playstation-plus/3-months-pl',
  'playstation-store': 'playstation-store',
  'playstation-store/100-pln': 'playstation-store/100-pln',
  'playstation-store/200-pln': 'playstation-store/200-pln',
  'playstation-store/50-pln': 'playstation-store/50-pln',
  'polityka-cookies': 'cookie-statement',
  'polkomtel-plus': 'polkomtel-plus',
  'prawo-odstapienia-od-umowy': 'how-it-works',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'prywatnosc-i-bezpieczenstwo': 'privacy-statement',
  'przedplacona-karta-platnicza': 'prepaid-credit-cards',
  'pubg-us': 'pubg',
  'red-dead-online': 'gaming-cards',
  'red-dead-online/150-sztabek-zlota-xbox-one': 'gaming-cards',
  'red-dead-online/55-sztabek-zlota-xbox-one': 'gaming-cards',
  roblox: 'roblox',
  'roblox/': 'roblox',
  'roblox/100-pln': 'roblox/100-pln',
  'roblox/1700-xbox-one': 'roblox',
  'roblox/400-xbox-one': 'roblox',
  'roblox/4500-xbox-one': 'roblox',
  'roblox/50-pln': 'roblox/50-pln',
  'roblox/800-xbox-one': 'roblox',
  runescape: 'runescape',
  'runescape/30-dni': 'runescape/30-days',
  'runescape/90-days': 'runescape/90-days',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/': 'spotify-premium',
  'spotify-premium/120-pln': 'spotify-premium/120-pln',
  'spotify-premium/20-pln': 'spotify-premium/20-pln',
  'spotify-premium/60-pln': 'spotify-premium/60-pln',
  steam: 'steam',
  'steam/': 'steam',
  'steam/100-eur': 'steam/100-eur',
  'steam/20-eur': 'steam/20-eur',
  'steam/5-eur': 'steam/5-eur',
  'steam/50-eur': 'steam/50-eur',
  't-mobile': 't-mobile',
  telegrosik: 'telegrosik',
  telepin: 'telepin',
  'the-settlers-online': 'the-settlers-online',
  'the-settlers-online/40-pln': 'the-settlers-online',
  'the-settlers-online/80-pln': 'the-settlers-online',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  'toneo-first/100-eur': 'toneo-first/100-eur',
  'toneo-first/50-eur': 'toneo-first/50-eur',
  transcash: 'transcash',
  'virgin-mobile': 'virgin-mobile',
  'virgin-mobile/5-pln': 'virgin-mobile',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/20-eur': 'world-of-warcraft',
  'world-of-warcraft/50-eur': 'world-of-warcraft',
  'world-of-warcraft/60-dni': 'world-of-warcraft/60-days',
  'world-of-warcraft/world-of-warcraft-60-days-21-40-eur': 'world-of-warcraft',
  'xbox-game-pass': 'xbox-game-pass',
  'xbox-game-pass/3-miesiace': 'xbox-game-pass',
  'xbox-game-pass/console-3-miesiace': 'xbox-game-pass',
  'xbox-game-pass/console-6-miesiace': 'xbox-game-pass',
  'xbox-game-pass/ultimate-1-mesiac': 'xbox-game-pass',
  'xbox-game-pass/ultimate-3-miesiace': 'xbox-game-pass',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card/': 'xbox-gift-card',
  'xbox-gift-card/100-pln': 'xbox-gift-card/100-pln',
  'xbox-gift-card/20-pln': 'xbox-gift-card/20-pln',
  'xbox-gift-card/200-pln': 'xbox-gift-card/200-pln',
  'xbox-gift-card/50-pln': 'xbox-gift-card/50-pln',
  'xbox-gift-card/70-pln': 'xbox-gift-card/70-pln',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/12-miesiecy': 'xbox-live-gold/12-months',
  'xbox-live-gold/3-miesiace': 'xbox-live-gold/3-months',
  zalando: 'zalando',
  'zalando/': 'zalando',
  'zalando/100-pln': 'zalando/100-pln',
  'zalando/200-pln': 'zalando/200-pln',
  'zalando/50-pln': 'zalando/50-pln',
  'apex-legends-eur': 'gaming-cards',
  'astropay-eur': 'astropay',
  'jeton-cash-eur': 'jeton-cash',
}
