import { ICheckoutState } from './interfaces'
import { Currency, Merchant } from '~/apis/clients/graphql/types/topup'

export default (): ICheckoutState => ({
  createPaymentPayload: {
    merchant: '' as Merchant,
    currency: '' as Currency,
    productId: '',
    discountPrice: 0,
    serviceFee: 0,
    totalPrice: 0,
    quantity: 1,
    paymentMethodId: '',
    email: '',
    voucher: null,
    newsletter: false,
    gift: false,
    acceptedTermsAndConditions: true,
    redirectUrl: '',
    redirectUrls: {
      success: '{urlPlaceholder}/order/paymentprogress',
      pending: '{urlPlaceholder}/order/paymentprogress',
      cancel: '{urlPlaceholder}/checkout/cancel{query}',
      error: '{urlPlaceholder}/order/error{query}',
    },
    paymentInformationData: {},
    issuer: '',
    affiliateId: null,
    phoneChallengeCode: null,
    redeemPhoneNumber: null,
    value: null,
    beaconId: null,
    ravelinDeviceId: null,
  },
  promoCodeLoading: false,
  promoCodeError: '',
  customDenominationError: '',
  isRetryOrder: false,
  primerPhoneChallenge: {
    phoneChallengeId: '',
    error: '',
    reference: '',
  },
  redirectClientToken: '',
  isRedirectDeepLink: false,
})
