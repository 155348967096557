import { Asset } from '~/apis/clients/graphql/types/contentful'
import { ICMSImageModel, IImageModel, ImageModel } from '~/models/base/Image'

export type TContentfulImage = Partial<
  Pick<Asset, 'title' | 'url' | 'contentType' | 'width' | 'height'>
>

// we should replace this with a general solution when we remove the models
export function transformImageToCMSModel({
  title,
  url,
  contentType,
  width,
  height,
}: TContentfulImage): ICMSImageModel {
  return {
    fields: {
      file: {
        url: url?.replace(/^https?:\/\//, ''),
        contentType,
        details: {
          image: {
            width,
            height,
            ratio: (width || 1) / (height || 1),
          },
        },
      },
      title,
    },
  }
}

export function transformImage(image: TContentfulImage): IImageModel {
  return new ImageModel(transformImageToCMSModel(image)).toJSON()
}
