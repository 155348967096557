
import {
  promoCodeError,
  promoCodeLoading,
  promoCodeHasBeenApplied,
} from './props'
import { Icon, UiButton, UiInput } from '~/components/atoms'
import SmoothWrapper from '~/components/utils/smooth-wrapper.vue'
import { COUPON_STATUS } from '~/plugins/gtm/enums'

export default {
  components: {
    Icon,
    SmoothWrapper,
    UiButton,
    UiInput,
  },
  props: {
    promoCodeError,
    promoCodeLoading,
    promoCodeHasBeenApplied,
  },
  data() {
    return {
      clientError: null,
      isFocused: false,
      promoCode: '',
      showOverlay: false,
      submittedPromoCode: '',
    }
  },
  computed: {
    isDirty() {
      return this.submittedPromoCode !== this.promoCode
    },
    error() {
      return this.isFocused || this.isDirty
        ? ''
        : this.$t(this.clientError || this.promoCodeError)
    },
    dataTestError() {
      return this.isFocused || this.isDirty
        ? ''
        : (this.clientError || this.promoCodeError)
            .replace(/\s/g, '')
            .replace(/\./g, '-')
    },
  },
  methods: {
    applyCode() {
      this.submittedPromoCode = this.promoCode

      if (!this.promoCode) {
        this.clientError = 'form.validations.required'
        this.$gtmEnhanced.trackApplyCoupon(COUPON_STATUS.COUPON_NOT_ENTERED)
        return
      }

      this.clientError = null
      this.$emit('promoCode:submit', this.promoCode)
    },
    onInputBlur() {
      this.isFocused = false
    },
    onInputFocus() {
      this.isFocused = true
    },
    toggleOverlay() {
      if (!this.showOverlay) this.$gtmEnhanced.trackClickCoupon()
      this.showOverlay = !this.showOverlay
    },
    removePromoCode() {
      this.$emit('promoCode:remove')
      this.$gtmEnhanced.trackRemoveCoupon()
      this.promoCode = ''
    },
  },
}
