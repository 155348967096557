export const states = {
  US: [
    { iso: 'US-AL', name: 'Alabama' },
    { iso: 'US-AK', name: 'Alaska' },
    { iso: 'US-AZ', name: 'Arizona' },
    { iso: 'US-AR', name: 'Arkansas' },
    { iso: 'US-CA', name: 'California' },
    { iso: 'US-CO', name: 'Colorado' },
    { iso: 'US-CT', name: 'Connecticut' },
    { iso: 'US-DE', name: 'Delaware' },
    { iso: 'US-DC', name: 'District of Columbia' },
    { iso: 'US-FL', name: 'Florida' },
    { iso: 'US-GA', name: 'Georgia' },
    { iso: 'US-HI', name: 'Hawaii' },
    { iso: 'US-ID', name: 'Idaho' },
    { iso: 'US-IL', name: 'Illinois' },
    { iso: 'US-IN', name: 'Indiana' },
    { iso: 'US-IA', name: 'Iowa' },
    { iso: 'US-KS', name: 'Kansas' },
    { iso: 'US-KY', name: 'Kentucky' },
    { iso: 'US-LA', name: 'Louisiana' },
    { iso: 'US-ME', name: 'Maine' },
    { iso: 'US-MD', name: 'Maryland' },
    { iso: 'US-MA', name: 'Massachusetts' },
    { iso: 'US-MI', name: 'Michigan' },
    { iso: 'US-MN', name: 'Minnesota' },
    { iso: 'US-MS', name: 'Mississippi' },
    { iso: 'US-MO', name: 'Missouri' },
    { iso: 'US-MT', name: 'Montana' },
    { iso: 'US-NE', name: 'Nebraska' },
    { iso: 'US-NV', name: 'Nevada' },
    { iso: 'US-NH', name: 'New Hampshire' },
    { iso: 'US-NJ', name: 'New Jersey' },
    { iso: 'US-NM', name: 'New Mexico' },
    { iso: 'US-NY', name: 'New York' },
    { iso: 'US-NC', name: 'North Carolina' },
    { iso: 'US-ND', name: 'North Dakota' },
    { iso: 'US-OH', name: 'Ohio' },
    { iso: 'US-OK', name: 'Oklahoma' },
    { iso: 'US-OR', name: 'Oregon' },
    { iso: 'US-PA', name: 'Pennsylvania' },
    { iso: 'US-RI', name: 'Rhode Island' },
    { iso: 'US-SC', name: 'South Carolina' },
    { iso: 'US-SD', name: 'South Dakota' },
    { iso: 'US-TN', name: 'Tennessee' },
    { iso: 'US-TX', name: 'Texas' },
    { iso: 'US-UT', name: 'Utah' },
    { iso: 'US-VT', name: 'Vermont' },
    { iso: 'US-VA', name: 'Virginia' },
    { iso: 'US-WA', name: 'Washington' },
    { iso: 'US-WV', name: 'West Virginia' },
    { iso: 'US-WI', name: 'Wisconsin' },
    { iso: 'US-WY', name: 'Wyoming' },
  ],
  CA: [
    { iso: 'CA-AB', name: 'Alberta' },
    { iso: 'CA-BC', name: 'British Columbia' },
    { iso: 'CA-MB', name: 'Manitoba' },
    { iso: 'CA-NB', name: 'New Brunswick' },
    { iso: 'CA-NL', name: 'Newfoundland and Labrador' },
    { iso: 'CA-NT', name: 'Northwest Territories' },
    { iso: 'CA-NS', name: 'Nova Scotia' },
    { iso: 'CA-NU', name: 'Nunavut' },
    { iso: 'CA-ON', name: 'Ontario' },
    { iso: 'CA-PE', name: 'Prince Edward Island' },
    { iso: 'CA-QC', name: 'Quebec' },
    { iso: 'CA-SK', name: 'Saskatchewan' },
    { iso: 'CA-YT', name: 'Yukon' },
  ],
  GB: [
    { iso: 'GB-ABE', name: 'Aberdeen City' },
    { iso: 'GB-ABD', name: 'Aberdeenshire' },
    { iso: 'GB-ANS', name: 'Angus' },
    { iso: 'GB-ANN', name: 'Antrim and Newtownabbey' },
    { iso: 'GB-AND', name: 'Ards and North Down' },
    { iso: 'GB-AGB', name: 'Argyll and Bute' },
    { iso: 'GB-ABC', name: 'Armagh City, Banbridge and Craigavon' },
    { iso: 'GB-BDG', name: 'Barking and Dagenham' },
    { iso: 'GB-BNE', name: 'Barnet' },
    { iso: 'GB-BNS', name: 'Barnsley' },
    { iso: 'GB-BAS', name: 'Bath and North East Somerset' },
    { iso: 'GB-BDF', name: 'Bedford' },
    { iso: 'GB-BFS', name: 'Belfast City' },
    { iso: 'GB-BEX', name: 'Bexley' },
    { iso: 'GB-BIR', name: 'Birmingham' },
    { iso: 'GB-BBD', name: 'Blackburn with Darwen' },
    { iso: 'GB-BPL', name: 'Blackpool' },
    { iso: 'GB-BGW', name: 'Blaenau Gwent' },
    { iso: 'GB-BOL', name: 'Bolton' },
    { iso: 'GB-BCP', name: 'Bournemouth, Christchurch and Poole' },
    { iso: 'GB-BRC', name: 'Bracknell Forest' },
    { iso: 'GB-BRD', name: 'Bradford' },
    { iso: 'GB-BEN', name: 'Brent' },
    { iso: 'GB-BGE', name: 'Bridgend' },
    { iso: 'GB-BNH', name: 'Brighton and Hove' },
    { iso: 'GB-BST', name: 'Bristol, City of' },
    { iso: 'GB-BRY', name: 'Bromley' },
    { iso: 'GB-BKM', name: 'Buckinghamshire' },
    { iso: 'GB-BUR', name: 'Bury' },
    { iso: 'GB-CAY', name: 'Caerphilly' },
    { iso: 'GB-CLD', name: 'Calderdale' },
    { iso: 'GB-CAM', name: 'Cambridgeshire' },
    { iso: 'GB-CMD', name: 'Camden' },
    { iso: 'GB-CRF', name: 'Cardiff' },
    { iso: 'GB-CMN', name: 'Carmarthenshire' },
    { iso: 'GB-CCG', name: 'Causeway Coast and Glens' },
    { iso: 'GB-CBF', name: 'Central Bedfordshire' },
    { iso: 'GB-CGN', name: 'Ceredigion' },
    { iso: 'GB-CHE', name: 'Cheshire East' },
    { iso: 'GB-CHW', name: 'Cheshire West and Chester' },
    { iso: 'GB-CLK', name: 'Clackmannanshire' },
    { iso: 'GB-CWY', name: 'Conwy' },
    { iso: 'GB-CON', name: 'Cornwall' },
    { iso: 'GB-COV', name: 'Coventry' },
    { iso: 'GB-CRY', name: 'Croydon' },
    { iso: 'GB-CMA', name: 'Cumbria' },
    { iso: 'GB-DAL', name: 'Darlington' },
    { iso: 'GB-DEN', name: 'Denbighshire' },
    { iso: 'GB-DER', name: 'Derby' },
    { iso: 'GB-DBY', name: 'Derbyshire' },
    { iso: 'GB-DRS', name: 'Derry and Strabane' },
    { iso: 'GB-DEV', name: 'Devon' },
    { iso: 'GB-DNC', name: 'Doncaster' },
    { iso: 'GB-DOR', name: 'Dorset' },
    { iso: 'GB-DUD', name: 'Dudley' },
    { iso: 'GB-DGY', name: 'Dumfries and Galloway' },
    { iso: 'GB-DND', name: 'Dundee City' },
    { iso: 'GB-DUR', name: 'Durham, County' },
    { iso: 'GB-EAL', name: 'Ealing' },
    { iso: 'GB-EAY', name: 'East Ayrshire' },
    { iso: 'GB-EDU', name: 'East Dunbartonshire' },
    { iso: 'GB-ELN', name: 'East Lothian' },
    { iso: 'GB-ERW', name: 'East Renfrewshire' },
    { iso: 'GB-ERY', name: 'East Riding of Yorkshire' },
    { iso: 'GB-ESX', name: 'East Sussex' },
    { iso: 'GB-EDH', name: 'Edinburgh, City of' },
    { iso: 'GB-ELS', name: 'Eilean Siar' },
    { iso: 'GB-ENF', name: 'Enfield' },
    { iso: 'GB-ESS', name: 'Essex' },
    { iso: 'GB-FAL', name: 'Falkirk' },
    { iso: 'GB-FMO', name: 'Fermanagh and Omagh' },
    { iso: 'GB-FIF', name: 'Fife' },
    { iso: 'GB-FLN', name: 'Flintshire' },
    { iso: 'GB-GAT', name: 'Gateshead' },
    { iso: 'GB-GLG', name: 'Glasgow City' },
    { iso: 'GB-GLS', name: 'Gloucestershire' },
    { iso: 'GB-GRE', name: 'Greenwich' },
    { iso: 'GB-GWN', name: 'Gwynedd' },
    { iso: 'GB-HCK', name: 'Hackney' },
    { iso: 'GB-HAL', name: 'Halton' },
    { iso: 'GB-HMF', name: 'Hammersmith and Fulham' },
    { iso: 'GB-HAM', name: 'Hampshire' },
    { iso: 'GB-HRY', name: 'Haringey' },
    { iso: 'GB-HRW', name: 'Harrow' },
    { iso: 'GB-HPL', name: 'Hartlepool' },
    { iso: 'GB-HAV', name: 'Havering' },
    { iso: 'GB-HEF', name: 'Herefordshire' },
    { iso: 'GB-HRT', name: 'Hertfordshire' },
    { iso: 'GB-HLD', name: 'Highland' },
    { iso: 'GB-HIL', name: 'Hillingdon' },
    { iso: 'GB-HNS', name: 'Hounslow' },
    { iso: 'GB-IVC', name: 'Inverclyde' },
    { iso: 'GB-AGY', name: 'Isle of Anglesey' },
    { iso: 'GB-IOW', name: 'Isle of Wight' },
    { iso: 'GB-IOS', name: 'Isles of Scilly' },
    { iso: 'GB-ISL', name: 'Islington' },
    { iso: 'GB-KEC', name: 'Kensington and Chelsea' },
    { iso: 'GB-KEN', name: 'Kent' },
    { iso: 'GB-KHL', name: 'Kingston upon Hull' },
    { iso: 'GB-KTT', name: 'Kingston upon Thames' },
    { iso: 'GB-KIR', name: 'Kirklees' },
    { iso: 'GB-KWL', name: 'Knowsley' },
    { iso: 'GB-LBH', name: 'Lambeth' },
    { iso: 'GB-LAN', name: 'Lancashire' },
    { iso: 'GB-LDS', name: 'Leeds' },
    { iso: 'GB-LCE', name: 'Leicester' },
    { iso: 'GB-LEC', name: 'Leicestershire' },
    { iso: 'GB-LEW', name: 'Lewisham' },
    { iso: 'GB-LIN', name: 'Lincolnshire' },
    { iso: 'GB-LBC', name: 'Lisburn and Castlereagh' },
    { iso: 'GB-LIV', name: 'Liverpool' },
    { iso: 'GB-LND', name: 'London, City of' },
    { iso: 'GB-LUT', name: 'Luton' },
    { iso: 'GB-MAN', name: 'Manchester' },
    { iso: 'GB-MDW', name: 'Medway' },
    { iso: 'GB-MTY', name: 'Merthyr Tydfil' },
    { iso: 'GB-MRT', name: 'Merton' },
    { iso: 'GB-MEA', name: 'Mid and East Antrim' },
    { iso: 'GB-MUL', name: 'Mid-Ulster' },
    { iso: 'GB-MDB', name: 'Middlesbrough' },
    { iso: 'GB-MLN', name: 'Midlothian' },
    { iso: 'GB-MIK', name: 'Milton Keynes' },
    { iso: 'GB-MON', name: 'Monmouthshire' },
    { iso: 'GB-MRY', name: 'Moray' },
    { iso: 'GB-NTL', name: 'Neath Port Talbot' },
    { iso: 'GB-NET', name: 'Newcastle upon Tyne' },
    { iso: 'GB-NWM', name: 'Newham' },
    { iso: 'GB-NWP', name: 'Newport' },
    { iso: 'GB-NMD', name: 'Newry, Mourne and Down' },
    { iso: 'GB-NFK', name: 'Norfolk' },
    { iso: 'GB-NAY', name: 'North Ayrshire' },
    { iso: 'GB-NEL', name: 'North East Lincolnshire' },
    { iso: 'GB-NLK', name: 'North Lanarkshire' },
    { iso: 'GB-NLN', name: 'North Lincolnshire' },
    { iso: 'GB-NSM', name: 'North Somerset' },
    { iso: 'GB-NTY', name: 'North Tyneside' },
    { iso: 'GB-NYK', name: 'North Yorkshire' },
    { iso: 'GB-NTH', name: 'Northamptonshire' },
    { iso: 'GB-NBL', name: 'Northumberland' },
    { iso: 'GB-NGM', name: 'Nottingham' },
    { iso: 'GB-NTT', name: 'Nottinghamshire' },
    { iso: 'GB-OLD', name: 'Oldham' },
    { iso: 'GB-ORK', name: 'Orkney Islands' },
    { iso: 'GB-OXF', name: 'Oxfordshire' },
    { iso: 'GB-PEM', name: 'Pembrokeshire' },
    { iso: 'GB-PKN', name: 'Perth and Kinross' },
    { iso: 'GB-PTE', name: 'Peterborough' },
    { iso: 'GB-PLY', name: 'Plymouth' },
    { iso: 'GB-POR', name: 'Portsmouth' },
    { iso: 'GB-POW', name: 'Powys' },
    { iso: 'GB-RDG', name: 'Reading' },
    { iso: 'GB-RDB', name: 'Redbridge' },
    { iso: 'GB-RCC', name: 'Redcar and Cleveland' },
    { iso: 'GB-RFW', name: 'Renfrewshire' },
    { iso: 'GB-RCT', name: 'Rhondda Cynon Taff' },
    { iso: 'GB-RIC', name: 'Richmond upon Thames' },
    { iso: 'GB-RCH', name: 'Rochdale' },
    { iso: 'GB-ROT', name: 'Rotherham' },
    { iso: 'GB-RUT', name: 'Rutland' },
    { iso: 'GB-SLF', name: 'Salford' },
    { iso: 'GB-SAW', name: 'Sandwell' },
    { iso: 'GB-SCB', name: 'Scottish Borders' },
    { iso: 'GB-SFT', name: 'Sefton' },
    { iso: 'GB-SHF', name: 'Sheffield' },
    { iso: 'GB-ZET', name: 'Shetland Islands' },
    { iso: 'GB-SHR', name: 'Shropshire' },
    { iso: 'GB-SLG', name: 'Slough' },
    { iso: 'GB-SOL', name: 'Solihull' },
    { iso: 'GB-SOM', name: 'Somerset' },
    { iso: 'GB-SAY', name: 'South Ayrshire' },
    { iso: 'GB-SGC', name: 'South Gloucestershire' },
    { iso: 'GB-SLK', name: 'South Lanarkshire' },
    { iso: 'GB-STY', name: 'South Tyneside' },
    { iso: 'GB-STH', name: 'Southampton' },
    { iso: 'GB-SOS', name: 'Southend-on-Sea' },
    { iso: 'GB-SWK', name: 'Southwark' },
    { iso: 'GB-SHN', name: 'St. Helens' },
    { iso: 'GB-STS', name: 'Staffordshire' },
    { iso: 'GB-STG', name: 'Stirling' },
    { iso: 'GB-SKP', name: 'Stockport' },
    { iso: 'GB-STT', name: 'Stockton-on-Tees' },
    { iso: 'GB-STE', name: 'Stoke-on-Trent' },
    { iso: 'GB-SFK', name: 'Suffolk' },
    { iso: 'GB-SND', name: 'Sunderland' },
    { iso: 'GB-SRY', name: 'Surrey' },
    { iso: 'GB-STN', name: 'Sutton' },
    { iso: 'GB-SWA', name: 'Swansea' },
    { iso: 'GB-SWD', name: 'Swindon' },
    { iso: 'GB-TAM', name: 'Tameside' },
    { iso: 'GB-TFW', name: 'Telford and Wrekin' },
    { iso: 'GB-THR', name: 'Thurrock' },
    { iso: 'GB-TOB', name: 'Torbay' },
    { iso: 'GB-TOF', name: 'Torfaen' },
    { iso: 'GB-TWH', name: 'Tower Hamlets' },
    { iso: 'GB-TRF', name: 'Trafford' },
    { iso: 'GB-VGL', name: 'Vale of Glamorgan, The' },
    { iso: 'GB-WKF', name: 'Wakefield' },
    { iso: 'GB-WLL', name: 'Walsall' },
    { iso: 'GB-WFT', name: 'Waltham Forest' },
    { iso: 'GB-WND', name: 'Wandsworth' },
    { iso: 'GB-WRT', name: 'Warrington' },
    { iso: 'GB-WAR', name: 'Warwickshire' },
    { iso: 'GB-WBK', name: 'West Berkshire' },
    { iso: 'GB-WDU', name: 'West Dunbartonshire' },
    { iso: 'GB-WLN', name: 'West Lothian' },
    { iso: 'GB-WSX', name: 'West Sussex' },
    { iso: 'GB-WSM', name: 'Westminster' },
    { iso: 'GB-WGN', name: 'Wigan' },
    { iso: 'GB-WIL', name: 'Wiltshire' },
    { iso: 'GB-WNM', name: 'Windsor and Maidenhead' },
    { iso: 'GB-WRL', name: 'Wirral' },
    { iso: 'GB-WOK', name: 'Wokingham' },
    { iso: 'GB-WLV', name: 'Wolverhampton' },
    { iso: 'GB-WOR', name: 'Worcestershire' },
    { iso: 'GB-WRX', name: 'Wrexham' },
    { iso: 'GB-YOR', name: 'York' },
  ],
}
