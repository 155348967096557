
import { mapGetters } from 'vuex'
import { externalLink, categoryLink, iconType, isNew, title } from './props'
import { UiLink, Icon } from '~/components/atoms'

export default {
  components: {
    Icon,
    UiLink,
  },
  props: {
    categoryLink,
    externalLink,
    iconType,
    isNew,
    title,
  },
  computed: {
    ...mapGetters('context', ['marketplacePrefix', 'isGlobalMarketplace']),
    url(): string {
      return this.categoryLinkUrl || this.externalLink?.url || ''
    },

    categoryLinkUrl() {
      if (!this.categoryLink) return null
      return this.isGlobalMarketplace && this.categoryLink.slug
        ? `/${this.marketplacePrefix}/${this.categoryLink.slug}`
        : this.categoryLink.url
    },

    rel(): string {
      return this.externalLink?.rel
    },
    newTab() {
      return this.externalLink?.newTab
    },
  },
}
