import { TYPES } from './mutation-types'
import { IUserState } from './state'
import { UserCommit } from './mutations'
import { ActionTree } from '~/store/types'

const { SET_ATTRIBUTE, CLEAR_USER, SET_RAF_ELIGIBILITY } = TYPES

const ATTRIBUTES_MAPPING = {
  id: 'id',
  email: 'email',
  username: 'username',
  given_name: 'firstName',
  family_name: 'familyName',
  address: 'address1',
  address2: 'address2',
  house_number: 'houseNumber',
  zip_code: 'zipcode',
  city: 'city',
  country: 'country',
  phone_number: 'phoneNumber',
  birthdate: 'birthdate',
  state_code: 'stateCode',
  state: 'state',
  phone_number_country_code: 'phoneNumberCountryCode',
  referred_by_code_id: 'referredByCodeId',
  identities: 'identities',
  total_referrals: 'totalReferrals',
}

interface Actions {
  setUser(user: Record<string, string>): void
  clearUser(): void
  setRafEligibility(payload: boolean): void
}

const actions: ActionTree<IUserState, UserCommit, Actions> = {
  setUser({ commit }, user: Record<string, string>): void {
    Object.entries(user).forEach(([name, value]) => {
      if (Object.keys(ATTRIBUTES_MAPPING).includes(name)) {
        commit(SET_ATTRIBUTE, {
          name: ATTRIBUTES_MAPPING[name],
          value,
        })
      }
    })
  },
  clearUser({ commit }): void {
    commit(CLEAR_USER, null)
  },
  setRafEligibility({ commit }, payload): void {
    commit(SET_RAF_ELIGIBILITY, payload)
  },
}

export default actions
