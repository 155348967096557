
import CountrySelector from '~/components/organisms/country-selector/index.vue'
import { UiButton } from '~/components/atoms'
import isEmpty from '~/utils/isEmpty'
import { mutatePath } from '~/utils/mutatePath'
import { getStorageItem } from '~/services/localstorage'

export default {
  name: 'HeroCountrySelector',
  components: {
    CountrySelector,
    UiButton,
  },
  props: {
    forceDesktopView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCountry: this.$store.getters['context/country'],
    }
  },
  mounted() {
    const { storedCountry } = getStorageItem({
      storage: 'recharge-store',
    })
    this.selectedCountry = storedCountry || this.selectedCountry
  },
  methods: {
    handleSelect(country): void {
      this.selectedCountry = country
    },
    handleSubmit(): void {
      if (isEmpty(this.selectedCountry)) return

      window.location.href = mutatePath(this.$route.path, {
        selectedCountry: this.selectedCountry.iso,
        selectedSlug: 'mobile-top-up',
      })
    },
  },
}
