import { Context } from '@nuxt/types'
import Vue from 'vue'
import { showPhraseKeys } from './show-phrase-keys'
import isEmpty from '~/utils/isEmpty'

export default ({ app, store, route }: Context): void => {
  const { router, $localStorageHydration, $gtmEnhanced } = app
  router.afterEach(to => {
    if (isEmpty(to)) return

    Vue.nextTick(() => {
      $localStorageHydration({ route: to, store })
      if (store.getters['context/isLocalMarketplace'])
        $gtmEnhanced.trackPageView(to, store)
      showPhraseKeys(route)
    })
  })
}
