import { Document } from '@contentful/rich-text-types'
import { EntryModel } from '../base/Entry'
import { DocumentModel } from '../base/Document'

export interface IAccordionItem {
  title: string
  description: Document
  isOpenByDefault: boolean
}

export class AccordionModel extends EntryModel {
  title: string
  description: Document
  isOpenByDefault: boolean

  constructor(accordionItemModel) {
    super(accordionItemModel)
    const {
      fields: { accordionOpen },
    } = accordionItemModel
    this.title = this.requiredField('title')
    this.description = new DocumentModel(
      this.requiredField('description')
    ).toJSON()
    this.isOpenByDefault = accordionOpen
  }

  toJSON(): IAccordionItem {
    return {
      title: this.title,
      description: this.description,
      isOpenByDefault: this.isOpenByDefault,
    }
  }
}
