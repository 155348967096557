export default {
  angrerett: 'how-it-works',
  'guild-wars': 'gaming-cards',
  'astropay-eur': 'astropay',
  'astropay-eur/10-eur': 'astropay/10-eur',
  'astropay-eur/100-eur': 'astropay/100-eur',
  'astropay-eur/25-eur': 'astropay/25-eur',
  'astropay-eur/50-eur': 'astropay/50-eur',
  battlenet: 'blizzard',
  betalingsmetoder: 'payment-methods',
  bitnovo: 'bitnovo',
  'bitnovo/25-eur': 'bitnovo/25-eur',
  'bitnovo/50-eur': 'bitnovo/50-eur',
  'bitnovo/75-eur': 'bitnovo/75-eur',
  bitsa: 'bitsa',
  'bitsa/100-eur': 'bitsa/100-eur',
  'bitsa/15-eur': 'bitsa/15-eur',
  'bitsa/25-eur': 'bitsa/25-eur',
  blizzard: 'blizzard',
  'cashlib-eur': 'cashlib',
  cashu: 'prepaid-credit-cards',
  cdon: 'shopping-gift-cards',
  'cdon/1000-nok': 'shopping-gift-cards',
  'cdon/200-nok': 'shopping-gift-cards',
  'crypto-voucher': 'crypto-voucher',
  'crypto-voucher/100-eur': 'crypto-voucher/100-eur',
  'crypto-voucher/25-eur': 'crypto-voucher/25-eur',
  dent: 'mobile-top-up',
  ecovoucher: 'ecovoucher',
  'ecovoucher/100-eur': 'ecovoucher/100-eur',
  'ecovoucher/15-eur': 'ecovoucher/15-eur',
  'ecovoucher/25-eur': 'ecovoucher/25-eur',
  'ecovoucher/50-eur': 'ecovoucher/50-eur',
  'erklaering-om-informasjonskapsler': 'cookie-statement',
  'eve-online': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  flexepin: 'flexepin',
  'flexepin/10-eur': 'flexepin/10-eur',
  'forhandsbetalte-kredittkort': 'prepaid-credit-cards',
  fortnite: 'gaming-cards',
  'free-fire': 'free-fire',
  'free-fire/2200-diamonds': 'free-fire',
  'jeton-cash-eur': 'jeton-cash',
  'jeton-cash-eur/10-eur': 'jeton-cash/10-eur',
  'jeton-cash-eur/5-eur': 'jeton-cash/5-eur',
  'jeton-cash-eur/50-eur': 'jeton-cash/50-eur',
  'karma-koin': 'karma-koin',
  kryptovaluta: 'cryptocurrency',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends',
  'league-of-legends/20-eur': 'league-of-legends',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin',
  'ncoin/20-eur': 'ncoin',
  'ncoin/5-eur': 'ncoin',
  'ncoin/50-eur': 'ncoin',
  neosurf: 'neosurf',
  'neosurf/100-eur': 'neosurf/100-eur',
  'neosurf/30-eur': 'neosurf/30-eur',
  'neosurf/50-eur': 'neosurf/50-eur',
  netflix: 'netflix',
  nexon: 'karma-koin',
  'om-oss': 'https://company.recharge.com',
  'openbucks-us': 'openbucks',
  'openbucks-us/10-usd': 'openbucks/10-usd',
  'openbucks-us/100-usd': 'openbucks/100-usd',
  'openbucks-us/25-usd': 'openbucks/25-usd',
  'openbucks-us/5-usd': 'openbucks/5-usd',
  'openbucks-us/50-usd': 'openbucks/50-usd',
  'payll-til-mobil': 'mobile-top-up',
  paysafecard: 'paysafecard',
  'paysafecard/100-nok': 'paysafecard',
  'paysafecard/450-nok': 'paysafecard',
  'paysafecard/500-nok': 'paysafecard',
  'paysafecard/1000-nok': 'paysafecard',
  pcs: 'pcs',
  'pcs/100-eur': 'pcs/100-eur',
  'pcs/150-eur': 'pcs/150-eur',
  'pcs/50-eur': 'pcs/50-eur',
  personvernerklaering: 'privacy-statement',
  'playstation-store': 'playstation-store',
  'playstation-store/100-nok': 'playstation-store/100-nok',
  'prepaid-amex-us': 'prepaid-credit-cards',
  'prepaid-amex-us/50-usd': 'prepaid-credit-cards',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'prepaid-mastercard/10-eur': 'prepaid-credit-cards',
  'prepaid-mastercard/100-eur': 'prepaid-credit-cards',
  'pubg-us': 'pubg',
  runescape: 'runescape',
  'runescape/30-dager': 'runescape/30-days',
  'runescape/90-dager': 'runescape/90-days',
  'shopping-gavekortene': 'shopping-gift-cards',
  'sitemap.xml': 'sitemap.xml',
  'slik-fungerer-det': 'how-it-works',
  soshop: 'soshop',
  'soshop/150-eur': 'soshop/150-eur',
  'spill-gavekortene': 'gaming-cards',
  'spotify-premium': 'spotify-premium',
  steam: 'steam',
  'steam/10-eur': 'steam',
  'steam/100-eur': 'steam',
  'steam/20-eur': 'steam',
  'steam/25-eur': 'steam',
  'steam/5-eur': 'steam',
  'steam/50-eur': 'steam',
  'ticket-premium': 'prepaid-credit-cards',
  'ticket-premium/100-eur': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'transcash/100-eur': 'transcash/100-eur',
  'transcash/50-eur': 'transcash/50-eur',
  underholdningskortene: 'entertainment-gift-cards',
  'visa-prepaid-us': 'prepaid-credit-cards',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-dager': 'world-of-warcraft',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/3-maneder': 'xbox-live-gold/3-months',
  zalando: 'zalando',
  'zalando/100-nok': 'zalando/100-nok',
  'zalando/200-nok': 'zalando/200-nok',
  lycamobile: 'lycamobile',
  'about-us': 'https://company.recharge.com',
  'cookie-statement': 'cookie-statement',
  cryptocurrency: 'cryptocurrency',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  'gaming-gift-cards': 'gaming-cards',
  'how-it-works': 'how-it-works',
  'mobile-top-up': 'mobile-top-up',
  'netflix/250-nok': 'netflix/250-nok',
  'netflix/500-nok': 'netflix/500-nok',
  'payment-methods': 'payment-methods',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'right-of-withdrawal': 'how-it-works',
  'shopping-gift-cards': 'shopping-gift-cards',
  'spotify-premium/100-nok': 'spotify-premium',
  'blog/reasons-prepaid-mobile-plans-are-great':
    'https://www.recharge.com/nb/no',
}
