import { Store } from 'vuex/types'
import { parsePhoneNumber } from 'libphonenumber-js'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { ITopupMyOrderModel, ORDER_STATUS } from '~/models/components/Order'
import { fetchMyOrder, fetchMyOrders } from '~/services/topup'
import { LazyLoaded, TopupSdk } from '~/apis/clients/graphql/create-sdk'
import { ISentry } from '~/types/store'
import { OrderType } from '~/apis/clients/graphql/types/topup'
import { encodeBase64 } from '~/services/encoder'
import { setStorageItem } from '~/services/localstorage'
import { normalizeVariant } from '~/normalizers/recommerce/variant'
import { RecommerceVariant } from '~/apis/clients/rest/recommerce/types'
import { DATE_FORMATS } from '~/enums/date-formats'
import isEmpty from '~/utils/isEmpty'

const ORDER_COMPLETE = 'order-complete'
const ORDER_REFUNDED = 'order-refunded'
const ORDER_PENDING = 'order-pending'
const ORDER_INCOMPLETE = 'order-incomplete'
const ORDER_FAILED = 'order-failed'

/**
 * Function that returns an array of previous orders for an account
 * @param  {TopupSdk}    topupClient  Topup plugin
 * @param  {Store}       store        Vuex store
 * @param  {number}      merchantId   Id of Merchant
 * @param  {OrderType}   orderType    Type of order
 * @param  {number}      limit        Number of results per page
 * @param  {number}      page         Page number for pagination
 * @param  {ISentry}     sentry       Sentry plugin for error logging
 * @return {ITopupMyOrderModel[]}     Array of orders
 */
export async function fetchAccountOrderHistory(
  topupClient: LazyLoaded<TopupSdk>,
  store: Store<unknown>,
  merchantId: number,
  orderType: OrderType,
  limit: number,
  page: number,
  sentry: ISentry
): Promise<ITopupMyOrderModel[]> {
  try {
    const { myOrders } = await fetchMyOrders(
      topupClient,
      { merchantId, orderType, limit, page },
      sentry
    )
    return myOrders
  } catch (e) {
    if (process.env.NODE_ENV === 'development') throw e
    sentry.captureException(e)
    store.commit('errors/setErrors', [e], { root: true })
  }
  return []
}

interface retryOrderParams {
  orderId?: number
  productId: string
  isRtr: boolean
  userPhone: string
  quantity?: string
  hash?: string
  orderLocale?: string
  hasCustomDenomination?: boolean
  productSlug?: string
  customDenominationChangeValue?: boolean
  currency?: string
  customDenominationValue?: number
}

/**
 * Function that sets phoneNumberInfo in localstorage
 * @param  {string} userPhone          User's phone number from order
 * @param  {string} encodedPhoneNumber User's phone number through encodeBase64 function
 * @return void
 */
const setInfoForCheckout = (
  userPhone: string,
  encodedPhoneNumber: string
): void => {
  const parsedPhoneNumber = parsePhoneNumber(userPhone)
  setStorageItem({
    storage: 'recharge-store',
    key: 'phoneNumberInfo',
    value: {
      dialCode: parsedPhoneNumber.countryCallingCode,
      phoneNumber: userPhone,
      encodedPhoneNumber,
    },
  })
}

/**
 * Function that sets proper values to retry order and redirects user to
 * checkout page. If is a custom denomination product, user is redirected to product page.
 * @param   {retryOrderParams}    parameters  Parameters for function (see type retryOrderParams)
 * @return  void
 */
export function retryOrder(parameters: retryOrderParams): void {
  const {
    orderId,
    productId,
    isRtr,
    userPhone,
    hash,
    orderLocale,
    hasCustomDenomination,
    productSlug,
  } = parameters
  let redirectUrl = ''

  if (hasCustomDenomination) {
    redirectUrl = `${orderLocale}/${productSlug}`
  } else {
    redirectUrl = `${orderLocale}/checkout/cancel/${orderId}/${hash}?productId=${productId}`
    let encodedPhoneNumber = ''
    if (isRtr) {
      encodedPhoneNumber = encodeBase64(userPhone)
      redirectUrl = `${redirectUrl}&p=${encodedPhoneNumber}`
      setInfoForCheckout(userPhone, encodedPhoneNumber)
    }
  }

  window.location.href = `${window.location.origin}/${redirectUrl}`
}

/**
 * Function that sets proper values to repeat order and redirects user to
 * checkout page. If is a custom denomination product, user is redirected to product page
 * @param    {retryOrderParams}    parameters  Parameters for function (see type retryOrderParams)
 * @returns  void
 */
export function repeatOrder(parameters: retryOrderParams): void {
  const {
    productId,
    quantity,
    isRtr,
    userPhone,
    orderLocale,
    hasCustomDenomination,
    productSlug,
    customDenominationChangeValue,
    customDenominationValue,
    currency,
  } = parameters
  let redirectUrl = ''

  if (customDenominationChangeValue) {
    redirectUrl = `${orderLocale}/${productSlug}`
  } else {
    redirectUrl = `${orderLocale}/checkout?productId=${productId}&quantity=${quantity}&currency=${currency}`
    if (hasCustomDenomination) {
      redirectUrl += `&value=${customDenominationValue}`
    }
    let encodedPhoneNumber = ''

    if (isRtr) {
      encodedPhoneNumber = encodeBase64(userPhone)
      redirectUrl = `${redirectUrl}&p=${encodeBase64(userPhone)}`
      setInfoForCheckout(userPhone, encodedPhoneNumber)
    }
  }

  window.location.href = `${window.location.origin}/${redirectUrl}`
}

/**
 * Function that returns a specific order object for an account
 * @param  {TopupSdk}    topupClient Topup plugin
 * @param  {Store}       store       Vuex store
 * @param  {number}      orderId     Id of specific order
 * @param  {OrderType}   orderType   Type of order
 * @param  {ISentry}     sentry      Sentry plugin for error logging
 * @return {ITopupMyOrderModel}      Order details object
 */
export async function fetchAccountOrderDetails(
  topupClient: LazyLoaded<TopupSdk>,
  store: Store<unknown>,
  orderId: string,
  orderType: OrderType,
  sentry: ISentry
): Promise<ITopupMyOrderModel | null> {
  try {
    const { myOrder } = await fetchMyOrder(
      topupClient,
      { orderId, orderType },
      sentry
    )

    return myOrder
  } catch (e) {
    if (process.env.NODE_ENV === 'development') throw e
    sentry.captureException(e)
    store.commit('errors/setErrors', [e], { root: true })
  }
  return null
}

/**
 * Function that checks if product is valid
 * @param  {RecommerceVariant} product Recommerce product
 * @return {boolean}
 */
export function isValidProduct(product: RecommerceVariant): boolean {
  try {
    return !!normalizeVariant(product)
  } catch (e) {
    return false
  }
}

/**
 * Function that formats date into desired format dd MMM yyyy
 * @param    {string}      date         Date from order
 * @param    {Record}      locale       date-fns locale lib
 * @return   {string}  date string of format dd MMM yyyy
 */
export function formatOrderDate(
  date: string,
  locale: Record<string, unknown>
): string {
  const parsedDate = parse(date, DATE_FORMATS.TOPUP_ORDER_DATE, new Date())
  return format(parsedDate, 'dd MMM yyyy', {
    locale,
  })
}

/**
 * Function that groups order statuses based on how they are used
 * @param {ORDER_STATUS} orderStatus Order status from order object
 * returns {string}
 */

export const getOrderState = (orderStatus: ORDER_STATUS): string => {
  if ([ORDER_STATUS.SHIPPED, ORDER_STATUS.COMPLETE].includes(orderStatus)) {
    return ORDER_COMPLETE
  }

  if ([ORDER_STATUS.REFUNDED].includes(orderStatus)) {
    return ORDER_REFUNDED
  }

  if (
    [
      ORDER_STATUS.DISTRIBUTION,
      ORDER_STATUS.INVESTIGATION,
      ORDER_STATUS.PAID,
    ].includes(orderStatus)
  ) {
    return ORDER_PENDING
  }

  if ([ORDER_STATUS.UNPAID].includes(orderStatus)) {
    return ORDER_INCOMPLETE
  }

  if (
    [
      ORDER_STATUS.ABORTED,
      ORDER_STATUS.ERROR,
      ORDER_STATUS.CANCELLED,
      ORDER_STATUS.INVESTIGATED,
    ].includes(orderStatus)
  ) {
    return ORDER_FAILED
  }

  return ''
}

/**
 * Function that uses grouped order states from setOrderState to return status text translation key
 * @param {ORDER_STATUS} orderStatus Order status from order object
 * returns {string}
 */
export function getOrderStatusText(orderStatus: ORDER_STATUS): string {
  const orderState = getOrderState(orderStatus)
  if (orderState === ORDER_COMPLETE) {
    return 'account.orders.status.delivered'
  }
  if (orderState === ORDER_REFUNDED) {
    return 'account.orders.status.refunded'
  }
  if (orderState === ORDER_PENDING) {
    return 'account.orders.status.in_progress'
  }
  if (orderState === ORDER_INCOMPLETE) {
    return 'account.orders.status.unpaid'
  }
  if (orderState === ORDER_FAILED) {
    return 'account.orders.status.cancelled'
  }

  if (orderStatus === ORDER_STATUS.INFO_REQUESTED) {
    return 'account.orders.status.info_requested'
  }

  return ''
}

/**
 * Function that returns icon for order state based on setOrderState function
 * @param {ORDER_STATUS} orderStatus Order status from order object
 * returns {string}
 */
export function getOrderStatusIcon(orderStatus: ORDER_STATUS): string {
  const orderState = getOrderState(orderStatus)
  if ([ORDER_COMPLETE, ORDER_REFUNDED].includes(orderState)) {
    return 'success'
  }
  if ([ORDER_PENDING, ORDER_INCOMPLETE].includes(orderState)) {
    return 'pending'
  }
  if (orderState === ORDER_FAILED) {
    return 'error'
  }

  if (orderStatus === ORDER_STATUS.INFO_REQUESTED) {
    return 'danger'
  }
  return ''
}

/**
 * Function that indicates if the product can be re-ordered at the moment
 * @param  {RecommerceVariant} product Recommerce product
 * @param  {ITopupMyOrderModel} order order data
 * @return {boolean}
 */
export function isReorderPossible(
  product: RecommerceVariant | undefined | null,
  order: ITopupMyOrderModel
): boolean {
  if (!product) return false

  let isGMPOrder = false
  if (order?.url) {
    const [, , , urlLocale] = order?.url.split('/')
    isGMPOrder = urlLocale.length <= 2
  }

  let available = false
  let visible = false
  if (order?.products?.length) {
    if (order.products[0]?.available) available = order.products[0]?.available

    if (order.products[0]?.visible) visible = order.products[0]?.visible
  }

  const { isAvailableOnWeb } = product.product

  return !(
    !isValidProduct(product) ||
    isEmpty(product) ||
    !isGMPOrder ||
    !available ||
    !visible ||
    !isAvailableOnWeb ||
    ORDER_STATUS.SHIPPED !== order?.status
  )
}
