import { BLOCKS, TopLevelBlock } from '@contentful/rich-text-types'

export class DocumentModel {
  protected readonly data: Record<string, any>
  protected readonly nodeType: BLOCKS.DOCUMENT
  protected readonly content: TopLevelBlock[]
  protected readonly text: string
  protected readonly marks: string[]
  protected readonly value: string

  constructor({ data, content, nodeType, marks, text, value }: any) {
    this.data = data
    this.marks = marks
    this.text = text
    this.value = value
    this.nodeType = nodeType
    if (content) {
      this.content = content.map(doc => new DocumentModel(doc).toJSON())
    }

    if (nodeType === 'entry-hyperlink') {
      if (!this.data.target || !this.data.target.fields) return
      this.data.target = {
        fields: Object.entries(this.data.target.fields).reduce(
          (accumulator, [key, val]) => {
            if (Array.isArray(val) || typeof val === 'object')
              return accumulator
            accumulator[key] = val
            return accumulator
          },
          {}
        ),
      }
    }
  }

  toJSON(): any {
    return {
      content: this.content,
      nodeType: this.nodeType,
      marks: this.marks,
      value: this.value,
      data: this.data,
    }
  }
}
