
import { sizes } from './types'
import props from './props'
import { COLOR } from './enums'

export default {
  props,
  computed: {
    computedSize(): number {
      return sizes[this.size] || sizes.base
    },
    computedColor(): COLOR {
      if (this.color in COLOR) {
        return COLOR[this.color]
      }
      return COLOR.link
    },
    iconSize(): string {
      return this.fontSize ? `text-${this.fontSize}` : ''
    },
  },
}
