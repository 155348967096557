<template>
  <div>
    <transition name="fade">
      <div
        v-if="visible"
        v-bind="$attrs"
        class="fixed inset-0 z-50 bg-overlay md:p-4 flex justify-center items-center"
        v-on="$listeners"
      >
        <section
          v-click-outside="handleCloseModal"
          data-test="modal"
          :aria-labelledby="title"
          :aria-describedby="description"
          role="dialog"
          class="relative max-h-full overflow-y-auto bg-white p-10 pt-15 pb-15 shadow-md h-auto w-thin-modal max-w-9/10 md:rounded-lg"
        >
          <button-icon
            data-test="modal-close-button"
            color="black"
            icon="close"
            font-size="xs"
            class="absolute top-6 end-6"
            @click="handleCloseModal"
          />
          <error-modal-box
            v-bind="$props"
            @error-modal-box:click-cta="handleErrorCtaClick"
            @error-modal-box:click-link="handleErrorLinkClick"
          />
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { GTMEvents } from '~/plugins/gtm/enums'
import { ErrorModalBox } from '~/components/molecules'
import { ButtonIcon } from '~/components/atoms'

export default {
  components: {
    ButtonIcon,
    ErrorModalBox,
  },
  props: {
    title: {
      type: String,
      default: 'Error',
    },
    description: {
      type: String,
      default: 'An error has occurred',
    },
    linkCtaText: {
      type: String,
      default: 'More info',
    },
    buttonCtaText: {
      type: String,
      default: 'Change payment method',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('ui/content-modal', ['closeModal']),
    handleCloseModal() {
      this.$gtmEnhanced.trackCheckoutEvent(GTMEvents.PAYMENT_ERROR_CLOSE)
      this.closeModal()
    },
    handleErrorCtaClick() {
      this.$emit('error-modal:click-cta')
    },
    handleErrorLinkClick() {
      this.$emit('error-modal:click-link')
    },
  },
}
</script>
