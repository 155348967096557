import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from './Entry'
import { ICMSExternalLinkModel, IExternalLinkModel } from './ExternalLink'
import get from '~/utils/get'

export interface ICMSLink extends Entry {
  fields: {
    slug: string
    pageTitle: string
    path: string
    title?: string
  }
}

export interface ILink {
  slug?: string
  title: string
  internalName?: string
  url?: string
}

export type TCMSLink = ICMSExternalLinkModel | ICMSLink
export type TLink = IExternalLinkModel | ILink

export function isCMSLink(link: TCMSLink): link is ICMSLink {
  if (!get(link, 'fields')) return false
  return (link as ICMSLink).fields.path !== undefined
}

export class LinkModel extends EntryModel {
  protected readonly slug?: string
  protected readonly title: string
  protected readonly url?: string

  constructor(linkModel: ICMSLink) {
    super(linkModel)
    const {
      fields: { slug, title, pageTitle },
    } = linkModel
    const path = this.requiredField('path')
    if (!slug || !path) return

    this.slug = slug
    this.title = title || pageTitle
    const [, ...params] = path && path.split('/')
    this.url = `/${params ? params.join('/') : ''}`
  }

  toJSON(): ILink {
    return {
      slug: this.slug,
      title: this.title,
      url: this.url,
    }
  }
}
