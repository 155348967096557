import { isPresent } from 'ts-is-present'
import { TContentfulCountryLang } from './country-list'
import isEmpty from '~/utils/isEmpty'
import {
  LabelQuery,
  LayoutQuery,
  Maybe,
} from '~/apis/clients/graphql/types/contentful'
import { RequestError } from '~/apis/error/RequestError'
import { ContentError } from '~/apis/error/ContentError'
import { ILink } from '~/models/base/Link'
import { normalizeFooter } from '~/normalizers/layout/footer'
import { normalizeHeader } from '~/normalizers/layout/header'
import { TContentfulImage } from '~/normalizers/base/image'
import { TContentfulCountry, TCountry } from '~/normalizers/layout/country'
import { IHeader } from '~/models/components/Header'
import { IFooter } from '~/models/components/Footer'

export type TPageType =
  | 'home'
  | 'app'
  | 'category'
  | 'brand'
  | 'theme'
  | 'product'
  | 'legal'
  | 'secondary_page'
  | 'blog'
  | 'faq'

export type TContentfulMerchant = {
  logo: TContentfulImage
  countriesCollection: Maybe<{
    items: Maybe<TContentfulCountryLang>[]
  }>
}

export type TContentfulPage = {
  country: TContentfulCountry
  pageType: TPageType
  merchant: TContentfulMerchant
}

export type TLayout = {
  header: IHeader
  footer: IFooter
  country: TCountry
  merchant: TContentfulMerchant
  aboutUsLink: ILink
  pageType?: TPageType
}

function isLabel(data: LabelQuery | LayoutQuery): data is LabelQuery {
  return 'pageCollection' in data
}

// Normalize "old" local market layout structure
function normalizeLabelLayoutBase(data: LabelQuery) {
  const pageItems = data?.pageCollection?.items || []
  if (pageItems === [] || !pageItems[0]) {
    throw new RequestError({ statusCode: 404, message: '' })
  }

  const { country, merchant, pageType } = pageItems[0]
  if (!pageType) throw new ContentError('pageType is not set')
  return { country, merchant, pageType }
}

// Normalize "new" (with recommerce) local market layout structure
function normalizeLayoutLayoutBase(data: LayoutQuery) {
  if (isEmpty(data)) {
    throw new RequestError({ statusCode: 404, message: '' })
  }
  const merchantCollection = data.merchantCollection?.items || []
  const countryCollection = data.countryCollection?.items || []
  const [country] = countryCollection
  const [merchant] = merchantCollection

  return { country, merchant, pageType: undefined }
}

export function normalizeLayout(data: LabelQuery | LayoutQuery): TLayout {
  const { country: countryData, merchant, pageType } = isLabel(data)
    ? normalizeLabelLayoutBase(data)
    : normalizeLayoutLayoutBase(data)

  if (!countryData) throw new ContentError('Layout has no country')
  if (!merchant) throw new ContentError('Layout has no merchant')
  if (!merchant.logo) throw new ContentError('Merchant has no logo')

  const country = {
    ...countryData,
    currencies: countryData?.currencies?.items.filter(isPresent) || [],
  }

  const footer = normalizeFooter({
    ...country.footer,
    logo: merchant.logo,
    paymentMethods:
      country.paymentMethodsCollection?.items.filter(isPresent) || [],
  })

  const {
    footerLinksGroup: [helpLinks],
  } = footer

  if (!country.header) throw new ContentError('Layout has no header')
  if (!country.header.legalLink)
    throw new ContentError('Header has no legal link')
  if (!country.header.menuCollection)
    throw new ContentError('Header has no menus')
  if (!country.header.uspsCollection)
    throw new ContentError('Header has no usps')

  const header = normalizeHeader({
    legalLink: country.header.legalLink,
    menuCollection: country.header.menuCollection,
    uspsCollection: country.header.uspsCollection,
    logo: merchant.logo,
    groupLinksCollection: helpLinks.groupLinks,
  })

  const aboutUsLink = helpLinks.groupLinks.find(({ internalName = '' }) =>
    internalName.includes('About')
  ) as ILink

  return {
    header,
    footer,
    aboutUsLink,
    country,
    merchant: { ...merchant, logo: merchant.logo },
    pageType: pageType as TPageType,
  }
}
