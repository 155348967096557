
import { mapActions, mapGetters } from 'vuex'
import ServiceCostsUsps from './service-costs-usps.vue'
import SmoothWrapper from '~/components/utils/smooth-wrapper.vue'
import ModalBottom from '~/components/molecules/modal-bottom/index.vue'
import { Icon } from '~/components/atoms'

export default {
  components: {
    SmoothWrapper,
    ServiceCostsUsps,
    ModalBottom,
    Icon,
  },
  data() {
    return {
      showOverlay: false,
      icon: 'down',
    }
  },
  computed: {
    ...mapGetters('ui', ['overlayVisibility', 'overlayName']),
  },
  methods: {
    ...mapActions('ui', ['toggleOverlay']),
    openOverlay() {
      this.showOverlay = !this.showOverlay
      this.$gtmEnhanced.trackClickServiceFee()
      this.toggleOverlay({
        name: 'serviceCostsOverlay',
        visibility: 'visible',
      })
    },
  },
}
