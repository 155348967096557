export const isDisabled = {
  type: Boolean,
  default: false,
}

export const orderEmail = {
  type: String,
  default: '',
}

export const privacyPolicy = {
  type: Object,
  default: {},
}

export const emailIsValidated = {
  type: Boolean,
  default: false,
}

export const checkoutEmailState = {
  type: Object,
  default: {},
}

export const checkoutPhoneState = {
  type: Object,
  default: {},
}

export const isCustomFieldError = {
  type: Boolean,
  default: false,
}
