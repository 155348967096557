export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export type Notification = {
  id: string
  closeAfter: number
  text: string
  type: NotificationType
  dataTest?: string
}

export type RawNotification = {
  text: string
  id?: string
  type?: string
  closeAfter?: number
  deferred?: boolean
  dataTest?: string
  isPersistent?: boolean
  isServerNotification?: boolean
}

/* eslint-disable camelcase */
export type NotificationParameters = {
  reward_expires_at?: string
  days_left?: number
  hours_left?: number
  minutes_left?: number
}
/* eslint-enable camelcase */
