import { IProduct } from '~/models/components/Product'
import { IProductValuesModel } from '~/models/topup/ProductPrice'
import { IQuickBuyProductModel } from '~/models/components/QuickBuyProduct'
import { ITopupProduct } from '~/services/topup/interfaces'

export type TProduct = ITopupProduct & IProductValuesModel

export interface IProductsStoreState {
  byId: Record<string, TProduct> | Record<string, never>
  contentfulWithNoId: IProduct[]
  topupWithNoId: ITopupProduct[]
  someProductsHaveRTRRedeemType: boolean
  asyncQuickBuyProduct: {
    loading: boolean
    quickBuyProduct?: IQuickBuyProductModel
    failed: boolean
    available: boolean
  }
  pageViewEventLogged: boolean
}

export default (): IProductsStoreState => {
  return {
    byId: {},
    contentfulWithNoId: [],
    topupWithNoId: [],
    someProductsHaveRTRRedeemType: false,
    asyncQuickBuyProduct: {
      loading: false,
      quickBuyProduct: undefined,
      failed: false,
      available: false,
    },
    pageViewEventLogged: false,
  }
}
