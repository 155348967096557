
import Vue, { PropOptions } from 'vue'
import VueTypes from 'vue-types'
import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
  helpers,
} from 'vuelidate/lib/validators'

import FormDescription from './form-description.vue'
import {
  Card,
  Icon,
  UiInput,
  UiTitle,
  UiButton,
  Usps,
  UiTextarea,
} from '~/components/atoms'

import {
  RightOfWithdrawal,
  PhoneInput,
  TermsAndConditions,
} from '~/components/molecules'
import CountrySelector from '~/components/organisms/country-selector-billing-details/index.vue'

import StateSelector from '~/components/organisms/state-selector/index.vue'
import { Column, Row } from '~/components/grid'
import { IFormField, IFormFieldValidations } from '~/factories/FormField'
import isEmpty from '~/utils/isEmpty'
import { validateCodiceFiscale } from '~/services/codice-fiscale'

// I don't like this code into the component

/** Typescript doesn't allow exec components methods on `data` method definition */
function buildInitialFormValues({
  fields,
}: {
  fields: IFormField[]
}): Record<string, string> {
  return fields.reduce(
    (accum, field) => ({
      ...accum,
      [field.key]: '',
    }),
    {}
  )
}

const minDate = (date: Date) =>
  helpers.withParams(
    { type: 'minDate', min: date.toISOString().substring(0, 10) },
    (value: string) =>
      !helpers.req(value) || value >= date.toISOString().substring(0, 10)
  )

const maxDate = (date: Date) =>
  helpers.withParams(
    { type: 'maxDate', min: date.toISOString().substring(0, 10) },
    (value: string) =>
      !helpers.req(value) || value <= date.toISOString().substring(0, 10)
  )

const validCodiceFiscale = () =>
  helpers.withParams(
    { type: 'validCodiceFiscale' },
    (value: string) => !helpers.req(value) || validateCodiceFiscale(value)
  )

const validations = {
  required: () => required,
  numeric: () => numeric,
  email: () => email,
  minLength,
  maxLength,
  minDate,
  maxDate,
  validCodiceFiscale,
}

function formatValidations(fieldValidations: IFormFieldValidations) {
  return Object.keys(fieldValidations).reduce(
    (accum, key) => ({
      ...accum,
      [key]: validations[key] ? validations[key](fieldValidations[key]) : '',
    }),
    {}
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function buildValidations(fields: IFormField[] = []): Record<string, any> {
  return fields.reduce(
    (accum, { validations: v, key }) => ({
      ...accum,
      [key]: v ? formatValidations(v) : {},
    }),
    {}
  )
}

const StateMandatoryForCountries = ['US', 'GB', 'CA']

export default Vue.extend({
  components: {
    Card,
    UiInput,
    UiTitle,
    UiButton,
    Usps,
    Row,
    Icon,
    Column,
    UiTextarea,
    RightOfWithdrawal,
    PhoneInput,
    CountrySelector,
    StateSelector,
    FormDescription,
    TermsAndConditions,
  },
  props: {
    loading: VueTypes.bool.def(false),
    fields: {
      type: Array,
      default: () => [],
    } as PropOptions<Array<IFormField>>,
    values: VueTypes.object.def({}),
    rightOfWithdrawal: VueTypes.object,
    hasTermsAndConditions: VueTypes.bool.def(false),
    acceptedTermsAndConditions: VueTypes.bool.def(false),
    brandTitle: VueTypes.string,
    hasExtraPaymentInformation: VueTypes.bool.def(false),
    termsAndConditionsUrl: VueTypes.string,
    brandName: VueTypes.string.def('regular'),
  },
  data() {
    return {
      form: buildInitialFormValues({
        fields: this.fields,
      }),
      buttonEnabled: true,
      selectedCountry: undefined,
    }
  },
  mounted() {
    if (isEmpty(this.values)) return

    this.setInitialFormValues()

    this.$v.$touch()
  },
  validations() {
    return {
      form: {
        ...buildValidations(this.fields),
        BILLING_STATE: {
          requiredIfCountryNeedsState: (value, { BILLING_COUNTRY }) => {
            if (!this.fields.find(({ key }) => key === 'BILLING_STATE'))
              return true
            if (!StateMandatoryForCountries.includes(BILLING_COUNTRY))
              return true
            return helpers.req(value)
          },
        },
      },
    }
  },
  methods: {
    onInput(fieldName, event) {
      if (fieldName === 'BILLING_COUNTRY') {
        this.$v.form.BILLING_STATE.$model = ''
        this.$v.form.BILLING_STATE.$reset()
        this.selectedCountry = {
          iso: event.toUpperCase(),
        }
      }
    },
    setInitialFormValues() {
      Object.assign(this.form, this.values)
    },
    savePaymentFormValues() {
      const { $invalid: invalid } = this.$v.form
      if (!invalid) {
        this.$emit('payment-form:valid', this.form)
      }
    },
    handleTermsAndConditionChange(value) {
      this.$emit('checkout-terms-and-conditions:changed', value)
      this.buttonEnabled = value
    },
    submitPayment() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('checkout-payment-form:submit', {
        form: this.form,
      })
    },
    onCountrySelect(country) {
      this.selectedCountry = country
    },
    selectedCountryAttribute(key: string) {
      return key === 'BILLING_STATE' ? 'selectedCountry' : null
    },
  },
})
