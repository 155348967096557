export default {
  'amazon-es': 'amazon',
  'amazon-es/': 'amazon',
  'amazon-es/10-eur': 'amazon',
  'amazon-es/100-eur': 'amazon',
  'amazon-es/15-eur': 'amazon',
  'amazon-es/25-eur': 'amazon',
  'amazon-es/50-eur': 'amazon',
  'app-store-itunes': 'app-store-itunes',
  'app-store-itunes/': 'app-store-itunes',
  'app-store-itunes/100-eur': 'app-store-itunes',
  'app-store-itunes/100-eurold': 'app-store-itunes',
  'app-store-itunes/15-eur': 'app-store-itunes',
  'app-store-itunes/15-eurold': 'app-store-itunes',
  'app-store-itunes/25-eur': 'app-store-itunes',
  'app-store-itunes/25-eurold': 'app-store-itunes',
  'app-store-itunes/50-eur': 'app-store-itunes',
  'app-store-itunes/50-eurold': 'app-store-itunes',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  'battlenet/50-eur': 'blizzard',
  'best-movil': 'mobile-top-up',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  'bitsa/15-eur': 'bitsa',
  'bitsa/25-eur': 'bitsa',
  'bitsa/50-eur': 'bitsa',
  blau: 'blau',
  blizzard: 'blizzard',
  carrefour: 'carrefour',
  'carrefour-movil': 'mobile-top-up',
  'carrefour/50-eur': 'carrefour',
  cashlib: 'cashlib',
  'cashlib/': 'cashlib',
  'cashlib/10-eur': 'cashlib',
  'cashlib/100-eur': 'cashlib',
  'cashlib/20-eur': 'cashlib',
  'cashlib/50-eur': 'cashlib',
  cashu: 'prepaid-credit-cards',
  'codigos-entretenimiento': 'entertainment-gift-cards',
  'codigos-juegos': 'gaming-cards',
  'como-funciona': 'how-it-works',
  criptomonedas: 'cryptocurrency',
  'crypto-voucher': 'crypto-voucher',
  dazn: 'dazn',
  'declaracion-de-privacidad': 'privacy-statement',
  deliveroo: 'deliveroo',
  'derecho-a-retirada': 'how-it-works',
  digimobil: 'digimobil',
  'ea-origin': 'ea-origin',
  ecovoucher: 'ecovoucher',
  'ecovoucher/100-eur': 'ecovoucher',
  'ecovoucher/15-eur': 'ecovoucher',
  'ecovoucher/25-eur': 'ecovoucher',
  'ecovoucher/50-eur': 'ecovoucher',
  'ecovoucher/75-eur': 'ecovoucher',
  'el-corte-ingles': 'el-corte-ingles',
  euskaltel: 'euskaltel',
  'eve-online': 'gaming-cards',
  'eve-online/': 'gaming-cards',
  'eve-online/1100-plex': 'gaming-cards',
  'eve-online/500-plex': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  'fifa-points/1600-xbox-one': 'gaming-cards',
  'fifa-points/2200-xbox-one': 'gaming-cards',
  'fifa-points/4600-xbox-one': 'gaming-cards',
  flexepin: 'flexepin',
  'flexepin/100-eur': 'flexepin',
  'flexepin/20-eur': 'flexepin',
  'flexepin/30-eur': 'flexepin',
  'flexepin/50-eur': 'flexepin',
  'formas-de-pago': 'payment-methods',
  fortnite: 'fortnite',
  'free-fire': 'free-fire',
  'google-play': 'google-play',
  'google-play/': 'google-play',
  'google-play/100-eur': 'google-play',
  'google-play/15-eur': 'google-play',
  'google-play/15-eurold': 'google-play',
  'google-play/25-eur': 'google-play',
  'google-play/50-eur': 'google-play',
  'guild-wars': 'gaming-cards',
  hablafacil: 'mobile-top-up',
  hablapp: 'hablapp',
  'happy-movil': 'happy-movil',
  'hbo-espana': 'hbo-es',
  'hbo-espana/': 'hbo-es',
  'hbo-espana/12-meses': 'hbo-es',
  'hbo-espana/3-meses': 'hbo-es',
  'hbo-espana/6-meses': 'hbo-es',
  'hits-mobile': 'hits-mobile',
  'hong-da-mobile': 'mobile-top-up',
  'karma-koin': 'karma-koin',
  'lcr-movil': 'lcr-movil',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends',
  'league-of-legends/20-eur': 'league-of-legends',
  lebara: 'lebara',
  llamaya: 'llamaya',
  'llamaya/bono-datos-5-eur': 'llamaya',
  lycamobile: 'lycamobile',
  masmovil: 'masmovil',
  moneyclic: 'prepaid-credit-cards',
  movistar: 'movistar',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin',
  'ncoin/100-eur': 'ncoin',
  'ncoin/20-eur': 'ncoin',
  'ncoin/5-eur': 'ncoin',
  'ncoin/50-eur': 'ncoin',
  neosurf: 'neosurf',
  'neosurf/': 'neosurf',
  'neosurf/10-eur': 'neosurf',
  'neosurf/100-eur': 'neosurf',
  'neosurf/15-eur': 'neosurf',
  'neosurf/30-eur': 'neosurf',
  'neosurf/50-eur': 'neosurf',
  netflix: 'netflix',
  'netflix/': 'netflix',
  'netflix/15-eur': 'netflix',
  'netflix/25-eur': 'netflix',
  'netflix/50-eur': 'netflix',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop',
  'nintendo-eshop/25-eur': 'nintendo-eshop',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-meses': 'nintendo-switch-online',
  'nintendo-switch-online/3-meses': 'nintendo-switch-online',
  openbucks: 'openbucks',
  orange: 'orange',
  paysafecard: 'paysafecard',
  'paysafecard/10-eur': 'paysafecard',
  'paysafecard/100-eur': 'paysafecard',
  'paysafecard/25-eur': 'paysafecard',
  'paysafecard/50-eur': 'paysafecard',
  pcs: 'pcs',
  'pcs/100-eur': 'pcs',
  'pcs/150-eur': 'pcs',
  'pcs/50-eur': 'pcs',
  'playstation-now': 'playstation-now',
  'playstation-now/1-mes': 'playstation-now',
  'playstation-now/12-meses': 'playstation-now',
  'playstation-now/3-meses': 'playstation-now',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/': 'playstation-plus',
  'playstation-plus/12-meses': 'playstation-plus',
  'playstation-plus/3-meses': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/10-eur': 'playstation-store',
  'playstation-store/20-eur': 'playstation-store',
  'playstation-store/50-eur': 'playstation-store',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'prepaid-visa-us': 'prepaid-credit-cards',
  'pubg-us': 'pubg',
  'rakuten-kobo': 'kobo-rakuten',
  'rakuten-kobo/10-eur': 'kobo-rakuten',
  'rakuten-kobo/25-eur': 'kobo-rakuten',
  'rakuten-kobo/50-eur': 'kobo-rakuten',
  'rakuten-tv': 'rakuten-tv',
  'recarga-del-movil': 'mobile-top-up',
  'red-dead-online': 'gaming-cards',
  'red-dead-online/150-barras-de-oro': 'gaming-cards',
  'red-dead-online/55-barras-de-oro': 'gaming-cards',
  'republica-movil': 'mobile-top-up',
  roblox: 'roblox',
  'roblox/': 'roblox',
  'roblox/11-eur-xbox-one': 'roblox',
  'roblox/20-eur-xbox-one': 'roblox',
  'roblox/50-eur-xbox-one': 'roblox',
  'roblox/6-eur-xbox-one': 'roblox',
  runescape: 'runescape',
  'runescape/90-dias': 'runescape',
  simyo: 'simyo',
  'sobre-nosotros': 'https://company.recharge.com',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/': 'spotify-premium',
  'spotify-premium/10-eur': 'spotify-premium',
  'spotify-premium/30-eur': 'spotify-premium',
  'spotify-premium/60-eur': 'spotify-premium',
  steam: 'steam',
  'steam/': 'steam',
  talkout: 'mobile-top-up',
  'tarjetas-de-pago': 'prepaid-credit-cards',
  'tarjetas-regalo': 'shopping-gift-cards',
  'ticket-premium': 'ticket-premium',
  'ticket-premium/': 'ticket-premium',
  'ticket-premium/10-eur': 'ticket-premium',
  'ticket-premium/100-eur': 'ticket-premium',
  'ticket-premium/25-eur': 'ticket-premium',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'transcash/100-eur': 'transcash',
  'transcash/20-eur': 'transcash',
  'transcash/50-eur': 'transcash',
  tuenti: 'tuenti',
  'uso-de-cookies': 'cookie-statement',
  viber: 'viber',
  vodafone: 'vodafone',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-dias': 'world-of-warcraft',
  'xbox-game-pass': 'xbox-game-pass',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card/10-eur': 'xbox-gift-card',
  'xbox-gift-card/15-eur': 'xbox-gift-card',
  'xbox-gift-card/20-eur': 'xbox-gift-card',
  'xbox-gift-card/25-eur': 'xbox-gift-card',
  'xbox-gift-card/30-eur': 'xbox-gift-card',
  'xbox-gift-card/5-eur': 'xbox-gift-card',
  'xbox-gift-card/50-eur': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/3-meses': 'xbox-live-gold',
  yoigo: 'yoigo',
  'you-mobile': 'you-mobile',
  'apex-legends': 'gaming-cards',
  'prepaid-amex-us': 'prepaid-credit-cards',
  astropay: 'astropay',
  'jeton-cash': 'jeton-cash',
}
