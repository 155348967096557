import { isPresent } from 'ts-is-present'
import { ActionTree } from '../types'
import { IContenfulState } from './interfaces'
import { TYPES } from './mutation-types'
import { ContentfulCommit } from './mutations'
import cloneDeep from '~/utils/cloneDeep'

import { normalizeCountryList } from '~/normalizers/layout/country-list'
import { normalizeLayout } from '~/normalizers/layout/layout'
import { normalizeSeo, TContentfulSeo } from '~/normalizers/seo'
import { normalizeBlogPage } from '~/normalizers/pages/blog'

import { executeOnServer } from '~/services/nuxt'
import {
  endMeasurement,
  startMeasurement,
} from '~~/server/services/performance'
import { RequestError } from '~/apis/error/RequestError'
import { Maybe } from '~/apis/clients/graphql/types/contentful'
import { collectionHasItems } from '~/utils/collection'
import { ContentError } from '~/apis/error/ContentError'

const getCollection = <K extends string>(key: K) => <T>(
  data?: { [C in K]?: Maybe<{ items?: Maybe<Maybe<T>[]> }> }
) => {
  executeOnServer(() => startMeasurement(`server:contentful:response:clone`))
  const clonedData = cloneDeep(data)

  executeOnServer(() => endMeasurement(`server:contentful:response:clone`))

  const items = clonedData && clonedData[key]?.items
  if (!items)
    throw new RequestError({
      statusCode: 404,
      message: `Contentful collection "${key}" is empty!`,
    })
  const collection = items.filter(isPresent) || []
  if (collection[0] === undefined) {
    throw new RequestError({
      statusCode: 404,
      message: `Contentful collection "${key}" is empty!`,
    })
  }

  return collection
}

const getPage = <T>(data?: {
  pageCollection?: Maybe<{ items?: Maybe<Maybe<T>[]> }>
}): T => {
  const [page] = getCollection('pageCollection')(data)
  return page
}

const {
  SET_COUNTRY_LIST,
  SET_HEADER,
  SET_FOOTER,
  SET_COUNTRY,
  SET_PAYMENT_METHODS,
  SET_META_LINKS,
  SET_META_TAGS_DATA,
  SET_META_TITLE,
  SET_STRUCTURED_DATA,
  SET_PAGE_TITLE,
  SET_ABOUT_US_URL,
  SET_PAYMENT_METHODS_PAGE,
  SET_BLOG_PAGE,
  SET_BLOG_OVERVIEW_PAGE,
} = TYPES

type PathArgs = {
  path: string
  locale: string
  pathLocale: string
  lang: string
  useRecommerce: boolean
}
interface ContentfulActions {
  fetchLayoutData(): Promise<void>
  fetchBlogPage(payload: PathArgs): Promise<void>
  fetchBlogOverviewPage(): Promise<void>
  fetchPageBasicStructure(
    payload: Pick<PathArgs, 'locale' | 'pathLocale'> & {
      pageTitle: Maybe<string>
      isError?: boolean
      seo?: Omit<TContentfulSeo, 'country' | 'pageTitle' | 'parentPath'>
    }
  ): Promise<void>
  fetchSharedData(): Promise<void>
  fetchCountriesList(
    payload: Pick<PathArgs, 'locale' | 'pathLocale'> & {
      isError?: boolean
    }
  )
}

const actions: ActionTree<
  IContenfulState,
  ContentfulCommit,
  ContentfulActions
> = {
  async fetchLayoutData({ commit, rootGetters }): Promise<void> {
    try {
      const cmsLocale = rootGetters['context/cmsLocale']
      const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']
      const countryCode = rootGetters['context/countryCode']
      const locale = rootGetters['context/locale']

      const { data } = isGlobalMarketplace
        ? await this.$contentful('layout')({
            countryCode,
            locale: cmsLocale,
          })
        : await this.$contentful('label')({
            path: `recharge.com/${locale}`,
            locale: cmsLocale,
          })

      const { header, footer, country } = normalizeLayout(data)

      commit(SET_HEADER, header)
      commit(SET_COUNTRY, country)
      commit(SET_FOOTER, footer)
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async fetchBlogOverviewPage(
    { commit, dispatch, rootGetters },
    { path, pathLocale, locale, lang }
  ) {
    let { abv: countryAbv } = rootGetters['contentful/country'] || {}
    const { data, errors } = await this.$contentful('blogOverviewPage')({
      locale,
      countryAbv,
    })
    if (errors && errors.length > 0)
      throw new RequestError({ message: errors[0].message, statusCode: 500 })

    const page = getPage(data)
    const {
      pageTitle,
      metaDescription,
      metaRobots,
      metaHreflangLinks,
      metaCanonicalLink,
      structuredData,
    } = page

    await dispatch('fetchPageBasicStructure', {
      locale: locale || lang.toLowerCase(),
      pathLocale,
      pageTitle,
      seo: {
        path,
        metaDescription,
        metaRobots,
        metaHreflangLinks,
        metaCanonicalLink,
        structuredData,
        lang,
      },
    })

    if (countryAbv === undefined) {
      countryAbv = pathLocale.split('-').pop()
    }

    const { data: blogsData, errors: blogErrors } = await this.$contentful(
      'blogPages'
    )({
      locale,
      countryAbv,
    })

    if (blogErrors && blogErrors.length > 0)
      throw new RequestError({
        message: blogErrors[0].message,
        statusCode: 500,
      })

    const rawBlogs = blogsData?.pageCollection?.items
      ? blogsData.pageCollection.items.filter(isPresent)
      : []

    const allBlogs = rawBlogs.flatMap(blogPage => {
      if (!blogPage || !blogPage.sectionsCollection) return []
      return [
        {
          ...normalizeBlogPage(blogPage.sectionsCollection.items),
          title: blogPage.pageTitle,
          slug: blogPage.slug,
        },
      ]
    })

    const [featured, ...blogs] = allBlogs

    commit(SET_BLOG_OVERVIEW_PAGE, { blogs, featured })
    commit(SET_PAGE_TITLE, pageTitle || '')
  },

  async fetchBlogPage(
    { commit, dispatch },
    { path, pathLocale, locale, lang }
  ) {
    const { data, errors } = await this.$contentful('blogPage')({
      path: `recharge.com${path}`,
      locale,
    })

    if (errors && errors.length > 0)
      throw new RequestError({ message: errors[0].message, statusCode: 500 })

    const page = getPage(data)

    const {
      sectionsCollection,
      pageTitle,
      metaDescription,
      metaRobots,
      metaHreflangLinks,
      metaCanonicalLink,
      structuredData,
    } = page

    await dispatch('fetchPageBasicStructure', {
      locale: locale || lang.toLowerCase(),
      pathLocale,
      pageTitle,
      seo: {
        path,
        metaDescription,
        metaRobots,
        metaHreflangLinks,
        metaCanonicalLink,
        structuredData,
        lang,
      },
    })

    const sections = sectionsCollection?.items
      ? sectionsCollection?.items.filter(isPresent)
      : []
    const pageData = normalizeBlogPage(sections)

    commit(SET_BLOG_PAGE, pageData)
    commit(SET_PAGE_TITLE, pageTitle || '')
  },

  async fetchCountriesList(
    { commit },
    { pathLocale, locale, isError }
  ): Promise<void> {
    const { data } = await this.$contentful('label')({
      path: `recharge.com/${pathLocale}`,
      locale,
    })

    const { merchant } = normalizeLayout(data)

    if (isError) return

    const countryList = normalizeCountryList(
      merchant?.countriesCollection?.items.filter(isPresent) || []
    )

    commit(SET_COUNTRY_LIST, countryList)
  },

  async fetchPageBasicStructure(
    { commit },
    { pathLocale, pageTitle, locale, seo, isError }
  ): Promise<void> {
    const { data } = await this.$contentful('label')({
      path: `recharge.com/${pathLocale}`,
      locale,
    })

    const { country, footer, header, aboutUsLink, merchant } = normalizeLayout(
      data
    )

    commit(SET_HEADER, header)
    commit(SET_FOOTER, footer)
    commit(SET_COUNTRY, country)
    commit(SET_ABOUT_US_URL, aboutUsLink?.slug)
    commit(SET_PAYMENT_METHODS, footer.paymentMethods)
    commit(SET_PAYMENT_METHODS_PAGE, footer.paymentMethodsPage)
    commit(SET_PAGE_TITLE, pageTitle || '')

    if (isError) return

    const countryList = normalizeCountryList(
      merchant?.countriesCollection?.items.filter(isPresent) || []
    )

    commit(SET_COUNTRY_LIST, countryList)

    if (!seo) return

    const {
      path,
      metaDescription,
      metaRobots,
      metaHreflangLinks,
      metaCanonicalLink,
      structuredData,
      lang,
    } = seo
    const { metaTitle, metaTags, metaLinks } = normalizeSeo({
      pageTitle,
      path,
      metaDescription,
      metaRobots,
      metaCanonicalLink,
      metaHreflangLinks,
      structuredData,
      lang,
      country,
    })

    commit(SET_META_LINKS, metaLinks)
    commit(SET_META_TAGS_DATA, metaTags)
    commit(SET_META_TITLE, metaTitle)
    commit(SET_STRUCTURED_DATA, structuredData)
  },
  async fetchSharedData({ commit, getters, rootGetters }) {
    const marketplacePrefix = rootGetters['context/marketplacePrefix']

    const cached = getters.cachedSharedData[marketplacePrefix]
    if (cached)
      return commit(TYPES.SET_SHARED_DATA, { data: cached, marketplacePrefix })

    const cmsLocale = rootGetters['context/cmsLocale']
    const countryCode = rootGetters['context/country'].code.toLowerCase()

    const shared = (
      await this.$contentful('shared')({
        locale: cmsLocale,
        countryCode,
      })
    ).data

    if (!collectionHasItems(shared.countryCollection))
      throw new ContentError(`Country ${countryCode} not found`)
    if (!collectionHasItems(shared.headerCollection))
      throw new ContentError('Header for GMP not found')
    if (!collectionHasItems(shared.merchantCollection))
      throw new ContentError('Merchant for GMP not found')
    if (!collectionHasItems(shared.footerCollection))
      throw new ContentError('Footer for GMP not found')
    if (!collectionHasItems(shared.paymentMethodCollection))
      throw new ContentError('Payment Methods for GMP not found')

    return commit(TYPES.SET_SHARED_DATA, {
      data: shared,
      marketplacePrefix,
    })
  },
}

export default actions
