import { Document } from '@contentful/rich-text-types'
import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { DocumentModel } from '../base/Document'

export interface ILegalModel {
  name: string
  privacyPolicy: Document
  rightOfWithdrawal: Document
  termsAndConditions: Document
}

export interface ICMSLegalModel extends Entry {
  fields: ILegalModel
}

export class LegalModel extends EntryModel {
  protected readonly name: string
  protected readonly privacyPolicy: Document
  protected readonly rightOfWithdrawal: Document
  protected readonly termsAndConditions: Document

  constructor(legalModel: ICMSLegalModel) {
    super(legalModel)
    this.name = this.requiredField('name')
    this.rightOfWithdrawal = new DocumentModel(
      this.requiredField('rightOfWithdrawal')
    ).toJSON()
    this.privacyPolicy = new DocumentModel(
      this.requiredField('privacyPolicy')
    ).toJSON()
    this.termsAndConditions = new DocumentModel(
      this.requiredField('termsAndConditions')
    ).toJSON()
  }

  toJSON(): ILegalModel {
    return {
      name: this.name,
      privacyPolicy: this.privacyPolicy,
      termsAndConditions: this.termsAndConditions,
      rightOfWithdrawal: this.rightOfWithdrawal,
    }
  }
}
