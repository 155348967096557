import { IImageModel, ImageModel } from '../base/Image'
import { EntryModel } from '../base/Entry'

export interface IInfoBox {
  title: string
  image: IImageModel
  info: string
  subInfo?: string
}

export class InfoBoxModel extends EntryModel {
  protected readonly title: string
  protected readonly image: IImageModel
  protected readonly info: string
  protected readonly subInfo: string

  constructor(infoBoxModel) {
    super(infoBoxModel)
    const {
      fields: { subInfo },
    } = infoBoxModel
    this.title = this.requiredField('title')
    this.image = new ImageModel(this.requiredField('image')).toJSON()
    this.info = this.requiredField('info')
    this.subInfo = subInfo
  }

  toJSON(): IInfoBox {
    return {
      title: this.title,
      image: this.image,
      info: this.info,
      subInfo: this.subInfo,
    }
  }
}
