/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ConfirmSignUpDto {
  id: string;
  code: string;
  locale: string;
  client_id?: string;
}

export interface AuthenticationPayload {
  id_token: string;
  refresh_token: string;
  access_token: string;
  expires_in: number;
  token_type: string;
  last_auth_user: string;
}

export interface ConfirmSignUpResponse {
  status: "failed" | "new_code_sent" | "confirmed" | "confirmed_and_signed_in";
  payload?: AuthenticationPayload;
}

export interface ChangeEmailDto {
  id: string;
  token: string;
}

export interface ChangeEmailResponse {
  status: "failed" | "changed" | "changed_and_signed_in";
  payload?: AuthenticationPayload;
}

export interface UserIdentity {
  userId: string;
  providerName: string;
}

export interface ResetPasswordDto {
  id: string;
  password: string;
  token: string;
}

export interface ResetPasswordSignOutSignInResponse {
  status: "failed" | "failed_sign_in" | "success" | "expired_code" | "code_mismatch";
  authenticationPayload?: AuthenticationPayload;
}

export interface UserPreference {
  language?: string | null;
  country?: string | null;
  currency?: string | null;
  id: string;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
}

export interface User {
  identities: UserIdentity[];
  email_verified: boolean;
  referred_by_code?: string;
  is_external: boolean;

  /** @format date-time */
  cognito_created_date?: string;
  locale: string;
  given_name: string;
  family_name: string;
  address: string;
  address2: string;
  house_number: string;
  zip_code: string;
  city: string;
  country: string;
  state: string;
  state_code: string;
  phone_number_country_code: string;
  phone_number: string;

  /** @format date-time */
  birthdate: string;
  referred_by_code_id: string;
  user_preference_id: string;
  confirmation_secret: string;
  new_email: string;
  email_change_confirmation_secret: string;

  /** @format date-time */
  email_change_requested_at: string;
  token_secret: string;
  referral_code_id: string;
  enabled: boolean;

  /** @format date-time */
  disabled_at: string;
  disable_reason: string;
  user_preference?: UserPreference;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  total_referrals: number;
  id: string;
  email: string;
  username: string;
  sign_up_origin: object;
}

export interface UpdateUserPreferenceDto {
  language?: string;
  country?: string;
  currency?: string;
}

export interface UpdateUserDto {
  locale?: string;
  given_name?: string;
  family_name?: string;
  address?: string;
  address2?: string;
  house_number?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  state_code?: string;
  state?: string;
  phone_number_country_code?: string;
  phone_number?: string;

  /** @format date-time */
  birthdate?: string;
  new_email?: string;
  email_change_locale?: string;
  email_change_confirmation_secret?: string;

  /** @format date-time */
  email_change_requested_at?: string;
  user_preference?: UpdateUserPreferenceDto;
}

export interface AttachReferredByCodeDto {
  referred_by_code: string;
}

export interface Contact {
  id: string;
  name: string;
  canonical_name: string;
  phone_number: string;
  phone_number_country_code: string;
  phone_number_country_iso: string;
  email?: string;
  user_id?: string;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
}

export interface CreateContactDto {
  name: string;
  international_phone_number: string;
  phone_number: string;
  phone_number_country_code: string;
  phone_number_country_iso: string;
}

export interface UpdateContactDto {
  name?: string;
  international_phone_number?: string;
  phone_number?: string;
  phone_number_country_code?: string;
  phone_number_country_iso?: string;
}

export interface ReferralCode {
  referral_code: string;
  id: string;
  enabled: boolean;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
}

export interface BalanceResponse {
  currency: string;
  balance: number;
  reserved_balance: number;
}

export interface WalletAvailabilityResponse {
  enabled: boolean;
  countries: string[];
}

export interface Notification {
  id: string;
  visibility_time: number | null;
  translation_key: string;
  is_persistent: boolean;
  parameters: object;
  source_key: string;
  created_by_system: string;
  user_id: string;

  /** @format date-time */
  read_at: string;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
}

export interface LegacyUserResponse {
  email: string;
  given_name: string | null;
  family_name: string | null;
  address: string | null;
  address2: string | null;
  house_number: string | null;
  zip_code: string | null;
  city: string | null;
  country: string | null;
  id: string;
  external_providers: string[];
}

export interface LegacyUserSignInDto {
  email: string;
  password: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Account API
 * @version 1.0
 * @contact
 *
 * Service to Store/Provide UserData.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name ConfirmSignUp
     * @request PATCH:/v1/confirm-sign-up
     */
    confirmSignUp: (data: ConfirmSignUpDto, params: RequestParams = {}) =>
      this.request<ConfirmSignUpResponse, any>({
        path: `/v1/confirm-sign-up`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name ChangeEmail
     * @request PATCH:/v1/change-email
     */
    changeEmail: (data: ChangeEmailDto, params: RequestParams = {}) =>
      this.request<ChangeEmailResponse, any>({
        path: `/v1/change-email`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Signs out the user and disables the account.
     *
     * @tags Auth
     * @name Delete
     * @summary Soft deletes an user
     * @request DELETE:/v1/me
     * @secure
     */
    delete: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/me`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name ResetPassword
     * @request PATCH:/v1/reset-password
     */
    resetPassword: (data: ResetPasswordDto, params: RequestParams = {}) =>
      this.request<ResetPasswordSignOutSignInResponse, any>({
        path: `/v1/reset-password`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name Me
     * @request GET:/v1/me
     * @secure
     */
    me: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/v1/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Update
     * @request PATCH:/v1/me
     * @secure
     */
    update: (data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/v1/me`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ConnectReferredByCode
     * @request PATCH:/v1/me/referred-by-code
     * @secure
     */
    connectReferredByCode: (data: AttachReferredByCodeDto, params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/v1/me/referred-by-code`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  contact = {
    /**
     * No description
     *
     * @tags Contact
     * @name Contacts
     * @request GET:/v1/me/contacts
     * @secure
     */
    contacts: (params: RequestParams = {}) =>
      this.request<Contact, any>({
        path: `/v1/me/contacts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name Create
     * @request POST:/v1/me/contacts
     * @secure
     */
    create: (data: CreateContactDto, params: RequestParams = {}) =>
      this.request<Contact, void>({
        path: `/v1/me/contacts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name Contact
     * @request GET:/v1/me/contacts/{id}
     * @secure
     */
    contact: (id: string, params: RequestParams = {}) =>
      this.request<Contact, void>({
        path: `/v1/me/contacts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name Update
     * @request PATCH:/v1/me/contacts/{id}
     * @secure
     */
    update: (id: string, data: UpdateContactDto, params: RequestParams = {}) =>
      this.request<Contact, any>({
        path: `/v1/me/contacts/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name Delete
     * @request DELETE:/v1/me/contacts/{id}
     * @secure
     */
    delete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/me/contacts/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  referralCode = {
    /**
     * No description
     *
     * @tags ReferralCode
     * @name GetByCode
     * @request GET:/v1/referral-code/{code}
     */
    getByCode: (code: string, params: RequestParams = {}) =>
      this.request<ReferralCode, any>({
        path: `/v1/referral-code/${code}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferralCode
     * @name Get
     * @request GET:/v1/me/referral-code
     * @secure
     */
    get: (params: RequestParams = {}) =>
      this.request<ReferralCode, any>({
        path: `/v1/me/referral-code`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  wallet = {
    /**
     * No description
     *
     * @tags Wallet
     * @name Balance
     * @request GET:/v1/me/balance
     * @secure
     */
    balance: (query?: { currency?: string }, params: RequestParams = {}) =>
      this.request<BalanceResponse, void>({
        path: `/v1/me/balance`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name WalletAvailability
     * @summary proxy wallet availability request to wallet. accepts list of countries and returns enabled if wallet is enabled in all of them
     * @request GET:/v1/wallet/availability
     */
    walletAvailability: (query: { countries: string }, params: RequestParams = {}) =>
      this.request<WalletAvailabilityResponse, any>({
        path: `/v1/wallet/availability`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  notification = {
    /**
     * No description
     *
     * @tags Notification
     * @name ListUnread
     * @request GET:/v1/me/notifications
     * @secure
     */
    listUnread: (params: RequestParams = {}) =>
      this.request<Notification, any>({
        path: `/v1/me/notifications`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name MarkNotificationRead
     * @request PATCH:/v1/me/notifications/{id}/read
     * @secure
     */
    markNotificationRead: (id: string, params: RequestParams = {}) =>
      this.request<Notification, any>({
        path: `/v1/me/notifications/${id}/read`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  legacyUser = {
    /**
     * No description
     *
     * @tags LegacyUser
     * @name FindOne
     * @request GET:/v1/legacy-users
     * @secure
     */
    findOne: (
      query?: { provider_name?: "google_oauth2" | "facebook"; email?: string; user_id?: string },
      params: RequestParams = {},
    ) =>
      this.request<LegacyUserResponse, void>({
        path: `/v1/legacy-users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags LegacyUser
     * @name Login
     * @request POST:/v1/legacy-users/sign-in
     * @secure
     */
    login: (data: LegacyUserSignInDto, params: RequestParams = {}) =>
      this.request<LegacyUserResponse, any>({
        path: `/v1/legacy-users/sign-in`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
