
import { PropOptions } from 'vue'
import get from '~/utils/get'
import { IImageModel } from '~/models/base/Image'

export default {
  props: {
    src: {
      type: Object,
      required: true,
    } as PropOptions<IImageModel>,
    imageDimensions: {
      type: Array,
      default: () => [],
    },
    fit: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    alt: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    isSVG(): boolean {
      const { src } = this
      const format = get(src, 'file.contentType', '')
      return format === 'image/svg+xml'
    },
    imageAlt(): string {
      return this.alt || this.src.title
    },
    regularImage(): string {
      const { src, fit, imageDimensions, isSVG } = this
      const baseUrl = get(src, 'file.url', '')
      if (isSVG) return baseUrl

      const [w, h] = imageDimensions
      const withRedimension = w && h ? `&w=${w}&h=${h}` : ''
      const withFit = fit ? `&fit=${fit}` : ''

      return `${baseUrl}${withFit}${withRedimension}`
    },
  },
}
