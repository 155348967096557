import { IUiMobileMenuState } from './state'
import { MobileMenuCommit } from './mutations'
import { MUTATION_TYPES } from './mutation-types'
import { ActionTree } from '~/store/types'
import { MobileMenuVisibility } from '~/store/ui/mobile-menu/types'

interface Actions {
  setMobileMenuVisibility(visibilityStatus: MobileMenuVisibility)
}

const actions: ActionTree<IUiMobileMenuState, MobileMenuCommit, Actions> = {
  setMobileMenuVisibility({ commit }, visibilityStatus) {
    commit(MUTATION_TYPES.SET_MOBILE_MENU_VISIBILITY, visibilityStatus)
  },
}

export default actions
