import { LinkModel, ICMSLink, ILink, isCMSLink } from '~/models/base/Link'
import {
  ExternalLinkModel,
  ICMSExternalLinkModel,
  IExternalLinkModel,
} from '~/models/base/ExternalLink'
import mapWithFilter from '~/services/map-with-filter'

type TCMSLink = ICMSExternalLinkModel | ICMSLink
type TLink = IExternalLinkModel | ILink

export default function getGroupLinks(groupLinks: TCMSLink[]): TLink[] {
  if (!groupLinks || !groupLinks.length) return []

  return mapWithFilter(
    groupLinks,
    (link: ICMSLink | ICMSExternalLinkModel) => {
      return !isCMSLink(link)
        ? new ExternalLinkModel(link).toJSON()
        : new LinkModel(link).toJSON()
    },
    'fields'
  )
}
