import { MutationTree } from 'vuex'
import { TYPES } from './mutation-types'
import { IStateSelectorModel } from '~/models/components/StateSelector'

const { SET_STATES } = TYPES
const mutations: MutationTree<IStateSelectorModel> = {
  [SET_STATES](state, states): void {
    state.states = states
  },
}

export default mutations
