import { EntryModel } from '../base/Entry'

export interface IPaymentMethodsBanner {
  paymentMethods: string[]
}

export class PaymentMethodsBanner extends EntryModel {
  protected readonly paymentMethods: string[]

  constructor(paymentMethodsBannerModel) {
    super(paymentMethodsBannerModel)

    const {
      fields: { paymentMethods },
    } = paymentMethodsBannerModel

    this.paymentMethods = paymentMethods
  }

  toJSON(): IPaymentMethodsBanner {
    return {
      paymentMethods: this.paymentMethods,
    }
  }
}
