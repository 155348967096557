import { ActionTree } from 'vuex'
import { TYPES } from './mutation-types'
import { UiContentModalState } from './state'

const { SET_MODAL, SET_MODAL_VISIBLE } = TYPES
const actions: ActionTree<UiContentModalState, UiContentModalState> = {
  async loadModal({ commit }, countryAbv) {
    const { data } = await this.$contentful('modal')({
      countryAbv,
    })

    const [modal] = data.modalCollection?.items || []

    if (!modal) return

    commit(SET_MODAL, modal)
    commit(SET_MODAL_VISIBLE, false)
  },

  async openModal({ commit }, name) {
    commit(SET_MODAL_VISIBLE, true)

    const { data } = await this.$contentful('modalByName')({
      name: `Modal - ${name}`,
    })

    const [modal] = data.modalCollection?.items || []

    if (!modal) return

    commit(SET_MODAL, modal)
  },

  showModal({ commit }) {
    commit(SET_MODAL_VISIBLE, true)
  },

  closeModal({ commit }) {
    commit(SET_MODAL, null)
    commit(SET_MODAL_VISIBLE, false)
  },
}

export default actions
