import { Context, Middleware } from '@nuxt/types'
// import {
//   getConsolidatedCookie,
//   setConsolidatedCookie,
// } from '~/services/ConsolidatedCookie'

const signInWithAppleFeatureGuard: Middleware = (context: Context): void => {
  if (!process.server) return

  const {
    // app,
    // route: { query },
    store,
  } = context
  // const isSignInWithAppleFeaturePreview =
  //   query.sign_in_with_apple_feature_preview === 'true' ||
  //   getConsolidatedCookie(
  //     app,
  //     'feature_flags',
  //     'sign_in_with_apple_feature_preview'
  //   )
  //
  // if (isSignInWithAppleFeaturePreview) {
  //   setConsolidatedCookie(
  //     app,
  //     'feature_flags',
  //     'sign_in_with_apple_feature_preview',
  //     isSignInWithAppleFeaturePreview
  //   )
  // }

  store.dispatch('context/setIsSignInWithAppleFeaturePreview', true)
}

export default signInWithAppleFeatureGuard
