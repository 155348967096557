import defaultHeader from './header'
import defaultBlockquote from './blockquote'
import defaultHr from './hr'
import defaultInlineComponents from './inline-components'
import defaultList from './list'
import defaultParagraph from './paragraph'
import defaultTextNode from './text'

export default {
  ...defaultHeader,
  ...defaultBlockquote,
  ...defaultHr,
  ...defaultInlineComponents,
  ...defaultList,
  ...defaultParagraph,
  ...defaultTextNode,
}
