import { ICMSCountryModel } from './Country'
import orderBy from '~/utils/orderBy'

export interface ICountryLangItem {
  lang: string
  displayName: string
  abv: string
  locale: string
  name: string
}

export interface ICountryLangList {
  countries: ICountryLangItem[]
}

export class CountryLangListModel {
  protected readonly countries: ICountryLangItem[]

  constructor(countries: ICMSCountryModel[]) {
    this.countries = CountryLangListModel.getCountryLangList(countries)
  }

  static getCountryLangList(countries: ICMSCountryModel[]): ICountryLangItem[] {
    const formattedCountries = countries.reduce((accumulator, { fields }) => {
      if (!fields) return accumulator

      const countryLangs = CountryLangListModel.getLangListByCountry(fields)
      return [...accumulator, ...countryLangs]
    }, [])

    return orderBy(formattedCountries, ['name', 'lang'], ['desc', 'asc'])
  }

  static getLangListByCountry({
    langs = [],
    disableLanguages = [],
    abv,
    name,
  }: ICMSCountryModel['fields']): ICountryLangItem[] {
    return langs.reduce((accumulator, lang) => {
      if (disableLanguages.includes(lang)) return accumulator
      const newCountryLang = CountryLangListModel.formatCountryLang(
        name,
        abv,
        lang
      )
      return [...accumulator, newCountryLang]
    }, [])
  }

  static formatCountryLang(
    name: string,
    abv: string,
    lang: string
  ): ICountryLangItem {
    return {
      lang,
      name,
      abv,
      displayName: `${name} (${lang.toUpperCase()})`,
      locale: `${lang}-${abv}`.toLowerCase(),
    }
  }

  toJSON(): ICountryLangList {
    return {
      countries: this.countries,
    }
  }
}
