import {
  IImageModel,
  ICMSImageModel,
  IResponsiveImageModel,
  ImageModel,
} from '../base/Image'
import { ICMSLink, ILink } from '../base/Link'
import { EntryModel } from '~/models/base/Entry'

export interface IHeroBanner {
  title: string
  description?: string
  buttonLink?: ILink
  buttonText?: string
  src: IImageModel | IResponsiveImageModel
}

export interface ICMSHeroBannerModel {
  fields: {
    banner: ICMSImageModel | any
    bannerMobile?: ICMSImageModel | any
    title: string
    description?: string
    buttonLink?: ICMSLink
    buttonText?: string
  }
}

export class HeroBannerModel extends EntryModel {
  protected readonly image: IImageModel | any
  protected readonly mobileImage?: IImageModel | any
  protected readonly title: string
  protected readonly description?: string
  protected readonly src: IImageModel | IResponsiveImageModel

  constructor(heroBannerModel: ICMSHeroBannerModel) {
    super(heroBannerModel)
    const {
      fields: { description, bannerMobile },
    } = heroBannerModel

    this.mobileImage = bannerMobile
      ? new ImageModel(bannerMobile).toJSON()
      : undefined

    this.image = new ImageModel(this.requiredField('banner')).toJSON()

    this.title = this.requiredField('title')
    this.description = description
    this.src = this.getSrc()
  }

  getSrc(): IImageModel | IResponsiveImageModel {
    const { image, mobileImage } = this

    if (image && mobileImage) {
      return { mobile: mobileImage, desktop: image }
    }

    return image
  }

  toJSON(): IHeroBanner {
    return {
      title: this.title,
      description: this.description,
      src: this.src,
    }
  }
}
