
import { product } from './props'
import { Alert, Icon } from '~/components/atoms'

export default {
  components: {
    Alert,
    Icon,
  },
  props: {
    product,
  },
}
