import Vue from 'vue'
import VueMq from 'vue-mq'

import { Breakpoints } from '~/enums'

// // Set the global media queries
Vue.use(VueMq, {
  breakpoints: {
    sm: Breakpoints.SM,
    md: Breakpoints.MD,
    lg: Breakpoints.LG,
    xl: Breakpoints.XL,
  },
  // default for SSR
  defaultBreakpoint: Breakpoints.SM,
})
