import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { IImageModel, ICMSImageModel, ImageModel } from '../base/Image'
import { EntryModel } from '../base/Entry'

export interface ICategoryBanner {
  image?: IImageModel
  imageMobile?: IImageModel
  title?: string
  showBreadcrumbs: boolean
}

export interface ICMSCategoryBannerModel extends Entry {
  fields: {
    banner?: ICMSImageModel
    bannerMobile?: ICMSImageModel
    title?: string
    showBreadcrumbs: boolean
  }
}

export class CategoryBannerModel extends EntryModel {
  protected readonly image?: IImageModel
  protected readonly imageMobile?: IImageModel
  protected readonly title?: string
  protected readonly showBreadcrumbs: boolean

  constructor(categoryBannerModel: ICMSCategoryBannerModel) {
    super(categoryBannerModel)
    const {
      fields: { banner, bannerMobile, title: bannerTitle, showBreadcrumbs },
    } = categoryBannerModel
    this.image = banner ? new ImageModel(banner).toJSON() : undefined
    this.title = bannerTitle
    this.showBreadcrumbs = showBreadcrumbs

    this.imageMobile = bannerMobile
      ? new ImageModel(bannerMobile).toJSON()
      : undefined
  }

  toJSON(): ICategoryBanner {
    return {
      image: this.image,
      imageMobile: this.imageMobile,
      title: this.title,
      showBreadcrumbs: this.showBreadcrumbs,
    }
  }
}
