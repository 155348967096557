type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export type RecommerceRequestConfig = {
  url: string
  body?: string
  cache?: boolean
  method?: RequestMethods
}

export const RECOMMERCE_APIS = Object.freeze({
  productBySlug: ({
    productSlug,
  }: {
    productSlug: string
  }): RecommerceRequestConfig => ({
    url: `${process.env.RECOMMERCE_API_URL}/dev-api/product/${productSlug}`,
    cache: true,
  }),
  xBySlug: ({
    slug,
    limit,
  }: {
    slug: string
    limit?: number
  }): RecommerceRequestConfig => {
    const base = `${process.env.RECOMMERCE_API_URL}/dev-api/slug/${slug}`
    const params = [...(limit ? [`limit=${limit}`] : [])]
    const paramsString = params.join('&')
    const url = paramsString ? `${base}?${paramsString}` : base
    return {
      url,
      cache: true,
    }
  },
  taxons: ({
    limit,
    isHomepage,
  }: {
    limit?: number
    isHomepage?: false
  }): RecommerceRequestConfig => {
    const base = `${process.env.RECOMMERCE_API_URL}/dev-api/taxons`
    const params = [
      ...(isHomepage ? ['homepage=1'] : []),
      ...(limit ? [`limit=${limit}`] : []),
    ]
    const paramsString = params.join('&')
    const url = paramsString ? `${base}?${paramsString}` : base
    return {
      url,
      cache: true,
    }
  },
  taxonBySlug: ({
    taxonSlug,
    limit,
    isHomepage,
  }: {
    taxonSlug: string
    limit?: number
    isHomepage?: false
  }): RecommerceRequestConfig => {
    const base = `${process.env.RECOMMERCE_API_URL}/dev-api/taxon/${taxonSlug}`
    const params = [
      ...(isHomepage ? ['homepage=1'] : []),
      ...(limit ? [`limit=${limit}`] : []),
    ]
    const paramsString = params.join('&')
    const url = paramsString ? `${base}?${paramsString}` : base
    return { url, cache: true }
  },
  variantById: ({ id }: { id: number | string }): RecommerceRequestConfig => ({
    url: `${process.env.RECOMMERCE_API_URL}/dev-api/variant/${id}`,
    cache: true,
  }),
  variantBySlug: ({
    productSlug,
    variantSlug,
  }: {
    productSlug: string
    variantSlug: string
  }): RecommerceRequestConfig => ({
    url: `${process.env.RECOMMERCE_API_URL}/dev-api/product/${productSlug}/variant/${variantSlug}`,
    cache: true,
  }),
  hlrLookup: (params: {
    phoneNumber: string
    countryCode: string
  }): RecommerceRequestConfig => ({
    url: `${process.env.RECOMMERCE_API_URL}/dev-api/hlr-lookup`,
    body: JSON.stringify(params),
    method: 'POST',
  }),
})

export type RecommerceApiKeys = keyof typeof RECOMMERCE_APIS

export const UNO_CHANNEL = 'uno'
export const UNO_GMP_CHANNEL = 'uno_global'
