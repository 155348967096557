/* eslint-disable camelcase */
import { IImageModel, IRecommerceImageModel } from '../base/Image'
import {
  OrderProductModel,
  IOrderProduct,
  ITopupOrderProduct,
  ICMSOrderProduct,
} from './OrderProduct'
import isEmpty from '~/utils/isEmpty'
import { Maybe } from '~/apis/clients/graphql/types/topup'
import { RecommerceVariant } from '~/apis/clients/rest/recommerce/types'

export enum ORDER_TYPE_REQUEST {
  TOPUP = 'TOPUP',
}

export enum ORDER_TYPE {
  TOPUP = 'TopupOrder',
}

export enum ORDER_STATUS {
  SHIPPED = 'shipped',
  COMPLETE = 'complete',
  UNPAID = 'unpaid',
  ABORTED = 'aborted',
  DISTRIBUTION = 'distribution',
  ERROR = 'error',
  CANCELLED = 'cancelled',
  INVESTIGATION = 'investigation',
  INVESTIGATED = 'investigated',
  PAID = 'paid',
  REFUNDED = 'refunded',
  INFO_REQUESTED = 'info_requested',
}

export enum ORDER_PAYMENT_STATUS {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

export interface ITransactionCost {
  currency?: string
  value?: number
}

export interface ITopupOrderModel {
  id: Maybe<number>
  status: ORDER_STATUS
  payment_status: ORDER_PAYMENT_STATUS
  order_currency: string
  price: string
  service_fee: string
  total_price: string
  pay_method: string
  products: ITopupOrderProduct[]
  quantity: string
  redeem_url?: string
  user: {
    email: string
  }
  rtr: {
    phone?: Maybe<string>
  }
  voucher: string | null
  open_range_value: number | null
  hash: string | null
}

export interface ITopupMyOrderModel {
  __typename: ORDER_TYPE
  id: Maybe<number>
  email: string
  status: string
  url?: string
  payment_status?: string
  country_name?: string
  country_iso?: string
  currency?: string
  price?: string
  discount_price?: string
  total_price?: string
  pay_method?: string
  isClassic?: boolean
  quantity?: string
  created_at?: string
  voucher?: Maybe<string>
  products: ITopupOrderProduct[]
  wallet_amount?: number
  service_fee?: string
  user?: {
    email?: string
    first_name?: string
    last_name?: string
  }
  rtr?: {
    phone?: Maybe<string>
    status?: string
  } | null
  hash?: string
  transaction_cost?: ITransactionCost
  productVariant?: RecommerceVariant
  open_range_value: number | null
}

export interface IOrderModel {
  id: string
  hash: string
  status: ORDER_STATUS
  paymentStatus: ORDER_PAYMENT_STATUS
  orderCurrency: string
  price: string
  serviceFee: string
  totalPrice: string
  paymentMethod: string
  products: IOrderProduct[]
  email: string
  quantity: string
  isRTR: boolean
  phone?: string
  redeemUrl?: string
  voucher: string | null
  openRangeValue: number | null
}

export interface IOrderCodeModel {
  code: string
  serialNumber?: Maybe<string>
  sicherheitsCode?: Maybe<string>
  logo?: IImageModel | IRecommerceImageModel
  title: string
  productId: string
  description?: Record<string, unknown>
  orderNumber: string
}

export class OrderModel {
  protected readonly id: Maybe<number>
  protected readonly status: ORDER_STATUS
  protected readonly paymentStatus: ORDER_PAYMENT_STATUS
  protected readonly orderCurrency: string
  protected readonly price: string
  protected readonly serviceFee: string
  protected readonly totalPrice: string
  protected readonly paymentMethod: string
  protected readonly products: IOrderProduct[]
  protected readonly email: string
  protected readonly quantity: string
  protected readonly isRTR: boolean
  protected readonly phone?: Maybe<string>
  protected readonly redeemUrl?: string
  protected readonly voucher: string | null
  protected readonly openRangeValue: number | null
  protected readonly hash: string | null

  constructor(order: ITopupOrderModel, cmsProduct?: ICMSOrderProduct) {
    const {
      id,
      status,
      payment_status,
      order_currency,
      price,
      service_fee,
      total_price,
      pay_method,
      products,
      user,
      quantity,
      redeem_url,
      rtr,
      voucher,
      open_range_value,
      hash,
    } = order

    this.id = id
    this.status = status
    this.paymentStatus = payment_status
    this.orderCurrency = order_currency
    this.price = price
    this.serviceFee = service_fee
    this.totalPrice = total_price
    this.paymentMethod = pay_method
    this.redeemUrl = redeem_url
    this.voucher = voucher
    this.openRangeValue = open_range_value

    this.products = products.map(p =>
      new OrderProductModel(p, cmsProduct).toJSON()
    )

    this.email = user ? user.email : ''
    this.quantity = quantity || '1'
    this.isRTR = !isEmpty(rtr)
    this.phone = this.isRTR ? rtr.phone : undefined
    this.hash = hash
  }

  static formattedCodes(order: IOrderModel): IOrderCodeModel[] {
    const { products } = order
    if (isEmpty(products)) {
      return []
    }

    return products.reduce(
      (accum, { codes, title, logo, description, productId }) => {
        const formattedCodes = codes.map(c => ({
          ...c,
          title,
          productId,
          logo,
          description,
          orderNumber: order.id,
        }))
        return [...accum, ...formattedCodes]
      },
      []
    )
  }

  toJSON(): IOrderModel {
    return {
      id: this.id ? this.id.toString() : '',
      hash: this.hash || '',
      status: this.status,
      paymentStatus: this.paymentStatus,
      orderCurrency: this.orderCurrency,
      price: this.price,
      serviceFee: this.serviceFee,
      totalPrice: this.totalPrice,
      paymentMethod: this.paymentMethod,
      products: this.products,
      email: this.email,
      quantity: this.quantity,
      isRTR: this.isRTR,
      phone: this.phone || '',
      redeemUrl: this.redeemUrl,
      voucher: this.voucher,
      openRangeValue: this.openRangeValue,
    }
  }
}
/* eslint-enable camelcase */
