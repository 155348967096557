
import { mapGetters } from 'vuex'
import { UiTitle, Icon, Card, UiLink, Divider } from '~/components/atoms'
import signOut from '~/mixins/sign-out-iframe'

export default {
  components: {
    UiTitle,
    UiLink,
    Icon,
    Card,
    Divider,
  },
  mixins: [signOut],
  props: {
    stepTitle: {
      default: 'molecules.checkout_email_box.title',
      type: String,
    },
    isNewDesign: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
}
