export enum PAYSAFE_TERMS_AND_CONDITION_LINKS {
  // Arabic
  // https://www.paysafecard.com/ar/terms-and-conditions/
  'ar' = 'https://www.paysafecard.com/ar/terms-and-conditions/',

  // Bulgarian
  // https://www.paysafecard.com/bg/agb/

  // Croatian
  // https://www.paysafecard.com/hr/opci-uvjeti-poslovanja/

  // Czech
  // https://www.paysafecard.com/cs/vseobecne-obchodni/

  // Danish
  // https://www.paysafecard.com/da/almindeli-gesalgsbetingelser/
  'da' = 'https://www.paysafecard.com/da/almindeli-gesalgsbetingelser/',

  // Dutch
  // https://www.paysafecard.com/nl/alg-vw/
  'nl' = 'https://www.paysafecard.com/nl/alg-vw/',
  'nl-be' = 'https://www.paysafecard.com/nl/alg-vw/',

  // English
  // https://www.paysafecard.com/en/terms-and-conditions/
  'en-gb' = 'https://www.paysafecard.com/en/terms-and-conditions/',

  // English USA
  // https://www.paysafecard.com/en-us/terms-and-conditions/
  'en' = 'https://www.paysafecard.com/en-us/terms-and-conditions/',

  // Finnish
  // https://www.paysafecard.com/fi/yleiset-kaeyttoeehdot/

  // French
  // https://www.paysafecard.com/fr/cgv/
  'fr' = 'https://www.paysafecard.com/fr/cgv/',

  // Georgian
  // https://www.paysafecard.com/ka/terms-and-conditions/

  // German
  // https://www.paysafecard.com/de/agb/
  'de' = 'https://www.paysafecard.com/de/agb/',

  // Greek
  // https://www.paysafecard.com/el/terms-and-conditions/
  'el' = 'https://www.paysafecard.com/el/terms-and-conditions/',

  // Hungarian
  // https://www.paysafecard.com/hu/auef/

  // Italian
  // https://www.paysafecard.com/it/condizioni-generali/
  'it' = 'https://www.paysafecard.com/it/condizioni-generali/',

  // Latvian
  // https://www.paysafecard.com/lv/visparejie-darijumu/?

  // Montenegrin
  // https://www.paysafecard.com/me/oup/

  // Norwegian
  // https://www.paysafecard.com/no/vilkar/
  'nb' = 'https://www.paysafecard.com/no/vilkar/',

  // Polish
  // https://www.paysafecard.com/pl/owh/
  'pl' = 'https://www.paysafecard.com/pl/owh/',

  // Portuguese
  // https://www.paysafecard.com/pt/termos-e-condicoes/
  'pt' = 'https://www.paysafecard.com/pt/termos-e-condicoes/',

  // Romanian
  // https://www.paysafecard.com/ro/ccg/
  'ro' = 'https://www.paysafecard.com/ro/ccg/',

  // Russian
  // https://www.paysafecard.com/ru/terms-and-conditions/
  'ru' = 'https://www.paysafecard.com/ru/terms-and-conditions/',

  // Serbian
  // https://www.paysafecard.com/sr/opsti-uslovi-poslovanja/

  // Slovakian
  // https://www.paysafecard.com/sk/vop/

  // Slovenian
  // https://www.paysafecard.com/sl/spp/=

  // Spanish
  // https://www.paysafecard.com/es/condiciones-generales/
  'es' = 'https://www.paysafecard.com/es/condiciones-generales/',
  'es-mx' = 'https://www.paysafecard.com/es/condiciones-generales/',

  // Swedish
  // https://www.paysafecard.com/sv/allmaenna-villkor/
  'sv' = 'https://www.paysafecard.com/sv/allmaenna-villkor/',

  // Turkish
  // https://www.paysafecard.com/tr/gkk/
  'tr' = 'https://www.paysafecard.com/tr/gkk/',
}
