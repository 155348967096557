
import { PropOptions } from 'vue'
import { Flag } from '~/components/atoms'
import { ICountryModel } from '~/models/components/CountrySelector'

export default {
  name: 'CountrySelectorDropdown',
  components: {
    Flag,
  },
  props: {
    selectedItem: {
      type: Object,
      default: null,
    } as PropOptions<ICountryModel>,
  },
}
