
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import { UiInput, Flag } from '~/components/atoms'
import InfoTooltip from '~/components/molecules/tooltip/info-tooltip.vue'

const KEYCODE_PLUS = 187

export default {
  name: 'PhoneInput',
  components: {
    UiInput,
    Flag,
    InfoTooltip,
  },
  inheritAttrs: false,
  props: {
    isSucceeded: Boolean,
    hasError: Boolean,
    hideSufix: Boolean,
    isDirty: Boolean,
    isBig: Boolean,
    isLoading: Boolean,
    isDisabled: Boolean,
    suffixIconType: {
      type: String,
      default: '',
    },
    hasSubscript: Boolean,
    subscriptKey: {
      type: String,
      default: '',
    },
    hasCustomSuffixIcon: Boolean,
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    margin: {
      type: String,
      default: 'mb-4',
    },
    informationMessage: {
      type: String,
      default: '',
    },
    extraInfo: {
      type: Array,
      default: () => [],
    },
    isNewDesign: {
      type: Boolean,
      default: false,
    },
    defaultLanguage: {
      type: String,
      default: '',
    } as PropOptions<string>,
    defaultCountryCode: {
      type: String,
      default: '',
    } as PropOptions<string>,
    selectedCountry: {
      type: Object,
      default: null,
    },
    borderCountryCode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFocused: false,
    }
  },

  computed: {
    ...mapGetters('contentful', ['country']),
    ...mapGetters('context', ['direction']),
    abv() {
      if (this.defaultLanguage) {
        return this.defaultLanguage
      }
      if (this.selectedCountry) {
        const { code = '' } = this.selectedCountry
        return code
      }
      const { country: { abv = '' } = {} } = this
      return abv
    },
    focusClass() {
      return this.isFocused ? 'border-secondary' : ''
    },
    dialCode() {
      if (this.defaultCountryCode) {
        return this.defaultCountryCode
      }
      if (this.selectedCountry) {
        const { countryCode = '' } = this.selectedCountry
        return countryCode
      }
      const { country: { dialCode = '' } = {} } = this
      return dialCode
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    handleFocus() {
      this.isFocused = true
      this.$emit('focus')
    },
    handleBlur() {
      this.isFocused = false
      this.$emit('blur')
    },
    onKeyDown($event) {
      const { keyCode } = $event
      if (keyCode === KEYCODE_PLUS) {
        $event.preventDefault()
      }
    },
    onPaste(event) {
      event.preventDefault()
      const text = event.clipboardData.getData('text')
      const numbers = text.match(/\d+/g)

      const cleanNumber = numbers?.reduce((acc, curr) => `${acc}${curr}`, '')

      this.inputVal = cleanNumber || ''
    },
  },
}
