import { REQUEST_ERROR } from '../enums'

export class RequestError extends Error {
  readonly statusCode: REQUEST_ERROR
  readonly message: string
  details?: string
  constructor({
    statusCode,
    message,
    details,
  }: {
    statusCode: REQUEST_ERROR
    message: string
    details?: string
  }) {
    super()
    this.message = message
    this.statusCode = statusCode
    this.details = details
  }
}
