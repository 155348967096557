import debug from 'debug'

export enum DebugKeys {
  'api:graphql' = 'api:graphql',
  'services' = 'services',
}

export default class Debug {
  private instance: (string) => void = (toLog: string) => {
    // eslint-disable-next-line no-console
    console.log(toLog)
  }

  constructor(key: DebugKeys) {
    this.instance = debug(key)
  }

  log(toLog: string | Error): void {
    if (process.env.NODE_ENV !== 'production') {
      this.instance(toLog)
    }
  }
}
