
import { mapGetters } from 'vuex'
import ResponsiveImage from './responsive-image.vue'
import ContentfulImage from './contentful-image.vue'
import UrlImage from './url-image.vue'
import props from './props'
import isEmpty from '~/utils/isEmpty'
import get from '~/utils/get'
import Skeleton from '~/components/utils/skeleton/index.vue'

export default {
  name: 'UiImage',
  components: {
    ResponsiveImage,
    Skeleton,
    ContentfulImage,
    UrlImage,
  },
  props,
  computed: {
    ...mapGetters('context', ['isGlobalMarketplace']),
    classes(): string {
      return `object-${this.contain ? 'contain' : 'cover'} object-${
        this.position
      } h-${this.height} ${
        this.responsiveWidth ? this.responsiveWidth : `w-${this.width}`
      } ${this.rounded ? 'rounded-lg' : ''}`
    },
    isUrlString(): boolean {
      return typeof this.src === 'string'
    },
    isUrlInObject(): boolean {
      return typeof this.src === 'object' && this.src?.url !== undefined
    },
    isContenfulImage(): boolean {
      const { isUrlString, src } = this
      if (isUrlString) return false
      return Boolean(get(src, 'file.url', false))
    },
    isResponsive(): boolean {
      const { src } = this
      return !isEmpty(src?.desktop)
    },
    responsiveAlt(): string {
      // To use both mobile & desktop banners correctly `responsive-image` component has to be modified
      // Use the mobile banner alt if presented.
      // Use the desktop banner alt otherwise.
      // Use alt from props as a fallback
      return (
        (this.src && this.src.mobile && this.src.mobile.alt) ||
        (this.src && this.src.desktop && this.src.desktop.alt) ||
        this.alt
      )
    },
    shouldLazy(): boolean {
      const src = this.isUrlString ? this.src : this.regularImage
      return this.lazy && !this.$Lazyload._imageCache._caches.includes(src)
    },
    urlImgAlt(): string {
      if (this.isGlobalMarketplace) {
        // cannot just put alt as the first priority without investigating all LMP
        return this.alt || this.src.alt || this.src.title
      }
      return this.src.alt || this.src.title || this.alt
    },
  },
}
