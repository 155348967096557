import { MutationTree } from 'vuex'
import {
  ICheckoutState,
  IPaymentRedirectUrls,
  ICreatePaymentPayload,
} from './interfaces'
import { TYPES } from './mutation-types'
import camelCase from '~/utils/camelCase'
import isEmpty from '~/utils/isEmpty'
import { PAYMENT_METHOD_FIELDS } from '~/enums'
import { IProduct } from '~/models/components/Product'
import { Merchant } from '~/apis/clients/graphql/types/topup'

const {
  SET_EMAIL,
  SET_SELECTED_PAYMENT_METHOD_ID,
  SET_ISSUER,
  SET_PAYMENT_INFORMATION_DATA,
  SET_GENERAL_INFO,
  SET_REDIRECT_URLS,
  SET_AFFILIATED_ID,
  SET_PHONE_NUMBER,
  SET_PHONE_CHALLENGE_CODE,
  SET_PHONE_CHALLENGE_ID,
  SET_PRIMER_PHONE_CHALLENGE_ID,
  SET_CREATE_PAYMENT_PAYLOAD,
  SET_TERMS_AND_CONDITIONS,
  SET_REDIRECT_URL,
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_REDEEM_PHONE_NUMBER,
  SET_DISCOUNT_PRICE,
  SET_TOTAL_PRICE,
  SET_VOUCHER,
  SET_PROMO_CODE_ERROR,
  SET_PROMO_CODE_LOADING,
  SET_IS_RETRY_ORDER,
  SET_SERVICE_FEE,
  SET_CUSTOM_DENOMINATION_ERROR,
  SET_REDIRECT_CLIENT_TOKEN,
  SET_IS_REDIRECT_DEEP_LINK,
  SET_BEACON_ID,
  SET_RAVELIN_DEVICE_ID,
} = TYPES

const mutations: MutationTree<ICheckoutState> = {
  [SET_EMAIL](state: ICheckoutState, { email }): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      email,
    }
  },
  [SET_SELECTED_PAYMENT_METHOD_ID](state: ICheckoutState, { id }): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      paymentMethodId: id,
    }
  },
  [SET_ISSUER](state: ICheckoutState, issuer): void {
    if (!issuer) return

    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      issuer,
    }
  },
  [SET_PAYMENT_INFORMATION_DATA](state: ICheckoutState, formattedFields): void {
    if (isEmpty(formattedFields)) return

    const { email } = state.createPaymentPayload
    const billingEmailKey = camelCase(
      PAYMENT_METHOD_FIELDS.BILLING_EMAILADDRESS
    )

    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      paymentInformationData: {
        ...state.createPaymentPayload.paymentInformationData,
        // force the email address from the state as informationData input
        [billingEmailKey]: email,
        ...formattedFields,
      },
      issuer: state.createPaymentPayload.issuer,
    }
  },

  [SET_GENERAL_INFO](
    state: ICheckoutState,
    generalInfo: {
      productId: string
      merchant: Merchant
      country: string
      quantity: number
      voucher: string
    }
  ): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      ...generalInfo,
    }
  },
  [SET_REDIRECT_URL](state: ICheckoutState, redirectUrl: string) {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      redirectUrl,
    }
  },
  [SET_REDIRECT_URLS](
    state: ICheckoutState,
    redirectUrls: IPaymentRedirectUrls
  ): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      redirectUrls,
    }
  },
  [SET_AFFILIATED_ID](state: ICheckoutState, aid: string): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      affiliateId: aid,
    }
  },
  [SET_PHONE_NUMBER](state: ICheckoutState, phoneNumber: string): void {
    const phoneNumberKey = camelCase(
      PAYMENT_METHOD_FIELDS.PHONE_CHALLENGE_NUMBER
    )

    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      paymentInformationData: {
        ...state.createPaymentPayload.paymentInformationData,
        [phoneNumberKey]: phoneNumber,
      },
    }
  },
  [SET_PHONE_CHALLENGE_CODE](state: ICheckoutState, code: string): void {
    const phoneChallengeCode = camelCase(
      PAYMENT_METHOD_FIELDS.PHONE_CHALLENGE_CODE
    )

    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      paymentInformationData: {
        ...state.createPaymentPayload.paymentInformationData,
        [phoneChallengeCode]: code,
      },
    }
  },
  [SET_TERMS_AND_CONDITIONS](state: ICheckoutState, value): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      acceptedTermsAndConditions: value,
    }
  },
  [SET_PHONE_CHALLENGE_ID](state: ICheckoutState, id: string): void {
    const phoneChallengeId = camelCase(PAYMENT_METHOD_FIELDS.PHONE_CHALLENGE_ID)

    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      paymentInformationData: {
        ...state.createPaymentPayload.paymentInformationData,
        [phoneChallengeId]: id,
      },
    }
  },
  [SET_PRIMER_PHONE_CHALLENGE_ID](
    state: ICheckoutState,
    updatedPhoneChallengeState: {
      error: string
      phoneChallengeId: string
      reference: string
    }
  ): void {
    state.primerPhoneChallenge = {
      phoneChallengeId: updatedPhoneChallengeState.phoneChallengeId,
      error: updatedPhoneChallengeState.error,
      reference: updatedPhoneChallengeState.reference,
    }
  },
  [SET_CREATE_PAYMENT_PAYLOAD](
    state: ICheckoutState,
    createPaymentPayload: ICreatePaymentPayload
  ): void {
    state.createPaymentPayload = {
      ...createPaymentPayload,
    }
  },
  [SET_PRODUCT_ID](state: ICheckoutState, productId: string): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      productId,
    }
  },
  [SET_DISCOUNT_PRICE](state, discountPrice: number) {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      discountPrice,
    }
  },
  [SET_SERVICE_FEE](state, serviceFee: number) {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      serviceFee,
    }
  },
  [SET_PRODUCT](state: ICheckoutState, product: IProduct): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      product,
      productId: product.productId?.toString() || '',
    }
  },
  [SET_TOTAL_PRICE](state: ICheckoutState, totalPrice: number): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      totalPrice,
    }
  },
  [SET_REDEEM_PHONE_NUMBER](
    state: ICheckoutState,
    redeemPhoneNumber: string
  ): void {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      redeemPhoneNumber,
    }
  },
  [SET_VOUCHER](state, voucher: string) {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      voucher,
    }
  },
  [SET_PROMO_CODE_ERROR](state, error: string) {
    state.promoCodeError = error
  },
  [SET_PROMO_CODE_LOADING](state, loading: boolean) {
    state.promoCodeLoading = loading
  },
  [SET_CUSTOM_DENOMINATION_ERROR](state, error: string) {
    state.customDenominationError = error
  },
  [SET_IS_RETRY_ORDER](state, isRetryOrder: boolean) {
    state.isRetryOrder = isRetryOrder
  },
  [SET_IS_REDIRECT_DEEP_LINK](state, isRedirectDeepLink: boolean) {
    state.isRedirectDeepLink = isRedirectDeepLink
  },
  [SET_REDIRECT_CLIENT_TOKEN](state, redirectClientToken: string) {
    state.redirectClientToken = redirectClientToken
  },
  [SET_BEACON_ID](state, beaconId: string) {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      beaconId,
    }
  },
  [SET_RAVELIN_DEVICE_ID](state, ravelinDeviceId: string) {
    state.createPaymentPayload = {
      ...state.createPaymentPayload,
      ravelinDeviceId,
    }
  },
}

export default mutations
