
import { mapGetters } from 'vuex'
import {
  required,
  numeric,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import { parsePhoneNumber } from 'libphonenumber-js'
import { NoticeBox, UiButton, UiInput } from '~/components/atoms'
import { setStorageItem, getStorageItem } from '~/services/localstorage'
import { encodeBase64 } from '~/services/encoder'
import { GTMEvents } from '~/plugins/gtm/enums'
import { HLR_STATUS, NO_HLR_COUNTRIES } from '~/utils/constants/hlr'
import PhoneInput from '~/components/molecules/phone-input/index.vue'

export default {
  components: {
    UiInput,
    NoticeBox,
    UiButton,
    PhoneInput,
  },
  props: {
    preFetch: {
      type: Boolean,
      default: false,
    },
    usePhoneInput: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    noContinuation: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: String,
      required: true,
    },
    unknownText: {
      type: String,
      default: null,
    },
    hideButtonWhenInitialValuePresent: {
      type: Boolean,
      default: false,
    },
    gtmTrackEvent: {
      type: String,
      default: GTMEvents.NUMBER_VALIDATION_RESPONSE,
    },
    buttonTag: {
      type: String,
      default: '',
    },
    shouldMatchSlug: {
      type: String,
      default: null,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    extendedBoxClasses: {
      type: String,
      default: '',
    },
    isNewDesign: {
      type: Boolean,
      default: false,
    },
    defaultPhoneNumber: {
      default: null,
      type: String,
    },
    localValidationEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingHLR: false,
      phoneInput: '',
      hlrResponse: null,
      inputGiven: false,
      focus: false,
      isValidLocally: false,
      isZeroRemoved: false,
      isValidated: false,
    }
  },
  computed: {
    ...mapGetters('contentful', { contentfulCountry: 'country' }),
    ...mapGetters('context', ['country']),
    buttonContent() {
      return this.hlrResponse
        ? this.$t('organisms.phone_number_modal.button')
        : this.buttonText
    },
    infoText() {
      if (this.unknownPhoneNumber) return this.unknownText
      if (this.invalidPhoneNumber) return this.invalidText
      return null
    },
    dataTestInfoText() {
      if (this.unknownPhoneNumber) return 'unknown-phone-number'
      if (this.invalidPhoneNumber) return 'invalid-phone-number'
      if (!this.isValidLocally) return 'local-invalid-number'
      return ''
    },
    showProcessButton() {
      return this.hideButtonWhenInitialValuePresent &&
        this.phoneInput.length &&
        !this.inputGiven
        ? false
        : !this.hlrResponse
    },
    invalidPhoneNumber() {
      return this.hlrResponse?.status === HLR_STATUS.INVALID_PHONE_NUMBER
    },
    unknownPhoneNumber() {
      return (
        this.hlrResponse && !this.invalidPhoneNumber && !this.validPhoneNumber
      )
    },
    validPhoneNumber() {
      return this.shouldMatchSlug
        ? this.hlrProduct?.slug === this.shouldMatchSlug
        : this.hlrResponse?.status === HLR_STATUS.HLR_LOOKUP_SUCCESSFUL
    },
    hlrFinished() {
      return this.validPhoneNumber
    },
    hlrProduct() {
      return (
        this.hlrResponse?.data?.products && this.hlrResponse.data.products[0]
      )
    },
    dialCode() {
      return this.contentfulCountry.dialCode
    },
    suffixIcon() {
      if (this.focus) return 'search'
      return this.phoneInput ? 'edit' : 'search'
    },
    phoneNumberInfo() {
      return {
        phoneNumber: this.phoneInput,
        dialCode: this.dialCode,
        encodedPhoneNumber: encodeBase64(`+${this.dialCode}${this.phoneInput}`),
        product: this.hlrProduct,
      }
    },
    gtmStatus() {
      return {
        INVALID_PHONE_NUMBER: 'invalid',
        HLR_LOOKUP_FAILED: 'not_found',
        HLR_LOOKUP_SUCCESSFUL: 'success',
        HLR_CARRIER_PRODUCT_NOT_FOUND: 'not_found',
        HLR_DCGW_CARRIER_NOT_FOUND: 'not_found',
      }[this.hlrResponse?.status]
    },
    skipHLR() {
      return NO_HLR_COUNTRIES.includes(this.country.code)
    },
  },
  watch: {
    '$v.phoneInput.$model': function(newValue, oldValue) {
      if (newValue.length > 1) return
      if (oldValue.length > 0) return
      this.$emit('input:inserted')
      this.$gtmEnhanced.trackNumberValidationInsert()
    },
  },
  mounted() {
    this.emitInterface()

    const phoneNumberValue = getStorageItem({
      storage: 'recharge-store',
      key: 'phoneNumberInfo',
    })
    if (!phoneNumberValue || phoneNumberValue.dialCode !== this.dialCode) {
      if (this.defaultPhoneNumber) {
        const dialCodeFormatted = `+${this.dialCode}`
        if (!this.defaultPhoneNumber.includes(dialCodeFormatted)) return
        const phoneNumber = this.defaultPhoneNumber.replace(
          dialCodeFormatted,
          ''
        )
        this.phoneInput = phoneNumber
      } else {
        return
      }
    } else {
      this.phoneInput = phoneNumberValue.phoneNumber
    }

    if (this.preFetch) {
      this.processPhoneNumber()
    }
    this.isValidLocally = this.checkValidLocally()
  },
  methods: {
    onBlur() {
      this.focus = false
      this.isZeroRemoved = false
    },
    onFocus() {
      this.focus = true
      this.isValidated = false
    },
    onInput() {
      this.inputGiven = true
      this.hlrResponse = null
      this.removeLeadingZero()
      this.$emit('input:update', this.phoneNumberInfo)
    },
    async processPhoneNumber() {
      this.isValidLocally = this.checkValidLocally()
      if (!this.isValidLocally) return
      this.tryCorrectPhoneNumberLocally()

      if (!this.skipHLR) {
        this.$emit('input:loading', true)
        this.loadingHLR = true
        await this.hlrLookup()
        this.loadingHLR = false
        this.$emit('input:loading', false)
      }
      this.gtmTrackValidationEvent()
      const shouldContinue =
        this.validPhoneNumber ||
        this.skipHLR ||
        (this.noContinuation && this.unknownPhoneNumber)

      if (shouldContinue) {
        this.storeAndEmit()
      }
    },
    async hlrLookup() {
      try {
        this.hlrResponse = await this.$recommerce('hlrLookup')({
          countryCode: this.country.code.toUpperCase(),
          phoneNumber: this.phoneInput,
        })
      } catch (e) {
        this.hlrResponse = {
          data: {
            phoneNumber: this.phoneInput,
          },
          status: HLR_STATUS.HLR_LOOKUP_FAILED,
        }
        this.$sentry.configureScope(scope => {
          scope.setTag('HLRLookup', true)
        })
        this.$sentry.captureException(e)
      }
    },
    storeAndEmit() {
      setStorageItem({
        storage: 'recharge-store',
        key: 'phoneNumberInfo',
        value: this.phoneNumberInfo,
      })
      this.$emit('input:result', this.phoneNumberInfo)
    },
    gtmTrackValidationEvent() {
      this.$gtmEnhanced.trackNumberValidationResponse(
        this.gtmTrackEvent,
        this.gtmStatus,
        `+${this.dialCode}`,
        this.phoneInput.length
      )
    },
    emitInterface() {
      this.$emit('interface', {
        processPhoneNumber: () => this.processPhoneNumber(),
        storeAndEmit: () => this.storeAndEmit(),
      })
    },
    tryCorrectPhoneNumberLocally() {
      const input = `+${this.dialCode}${this.phoneInput}`
      try {
        const parsedPhoneNumber = parsePhoneNumber(input)
        // removes any leading '0', '1' in case of US
        if (
          parsedPhoneNumber.nationalNumber &&
          this.phoneInput !== parsedPhoneNumber.nationalNumber
        ) {
          this.phoneInput = parsedPhoneNumber.nationalNumber
        }
      } catch (e) {
        this.$sentry.configureScope(scope => {
          scope.setTag('phoneValidation', true)
        })
        this.$sentry.captureException({ e, input })
      }
    },
    checkValidLocally() {
      this.isValidated = true
      if (!this.localValidationEnabled) return true
      if (!this.phoneInput?.length) return false
      try {
        const parsedPhoneNumber = parsePhoneNumber(
          `+${this.dialCode}${this.phoneInput}`
        )
        return parsedPhoneNumber.isValid()
      } catch (e) {
        return false
      }
    },
    /**
     * Removes the leading zero, once user has typed at least 6 digits
     */
    removeLeadingZero() {
      this.isZeroRemoved = false
      if (this.phoneInput.length > 6) {
        if (this.phoneInput[0] === '0') {
          this.isZeroRemoved = true
          this.phoneInput = this.phoneInput.slice(1, this.phoneInput.length)
        }
      }
    },
  },
  validations: {
    phoneInput: {
      required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(15),
    },
  },
}
