
import { PropOptions } from 'vue'
import { Flag } from '~/components/atoms'
import { ICountrySelectorModel } from '~/models/components/CountrySelector'

export default {
  name: 'CountrySelectorListItemLabel',
  components: {
    Flag,
  },
  props: {
    showCountryCode: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      required: true,
    } as PropOptions<ICountrySelectorModel[]>,
  },
}
