export enum ASYNC_METHODS {
  fetch = 'fetch',
  mutate = 'mutate',
  put = 'put',
}

export enum REQUEST_ERROR {
  SERVER_ERROR = 500,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  UNAUTHORIZED = 401,
}
