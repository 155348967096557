import { IOrderProduct } from './interfaces'
import get from '~/utils/get'
import isEmpty from '~/utils/isEmpty'
import { fetchOrder, fetchOrderStatus } from '~/services/topup'
import { clearStorageItem } from '~/services/localstorage'
import { ITopupOrderModel } from '~/models/components/Order'
import { RequestError } from '~/apis/error/RequestError'

export default {
  addProductToOrder({ commit }, product: IOrderProduct): void {
    commit('setProduct', product)
  },

  trackPurchaseRehydrate(
    { getters, rootGetters, commit },
    localStorageStore
  ): void {
    try {
      const { paymentInProgressTriggered } = localStorageStore
      if (!paymentInProgressTriggered) return
      const { orderDetails } = getters
      const currencies = rootGetters['contentful/currencies'] || []
      const currency = rootGetters['context/isGlobalMarketplace']
        ? rootGetters['context/currency']
        : currencies[0] || {}

      const variant = rootGetters['recommerce/variant']
      const { baseCategory } = variant
      orderDetails.products[0] = { ...orderDetails.products[0], baseCategory }

      this.$gtmEnhanced.trackPurchaseRehydrate(orderDetails, currency)
      clearStorageItem({
        storage: 'recharge-store-order-finished-id-hash',
        key: 'paymentInProgressTriggered',
      })
    } catch (e) {
      if (process.env.NODE_ENV === 'development') throw e
      this.$sentry.captureException(e)
      commit('errors/setErrors', [e], { root: true })
    }
  },

  async fetchRecommerceVariantInfo(
    { commit, rootGetters, dispatch },
    {
      productId,
      order,
    }: {
      order: ITopupOrderModel
      productId: string
      errorPage: () => void
    }
  ): Promise<void> {
    try {
      await dispatch('recommerce/fetchVariantById', productId, { root: true })

      const variant = rootGetters['recommerce/variant']
      const {
        productId: variantId,
        product: { sectionInfoBlocks, sectionAccordion, sectionFreeForm },
      } = variant
      if (!variantId) {
        throw new RequestError({
          message: 'Variant id not found for this order',
          statusCode: 404,
        })
      }
      commit('setProductOrderPageStructure', {
        sectionInfoBlocks,
        sectionAccordion,
        sectionFreeForm,
      })

      commit('setOrderDetails', {
        order,
        product: variant,
        isGlobalMarketplace: true,
      })
    } catch (e) {
      if (process.env.NODE_ENV === 'development') throw e
      this.$sentry.captureException(e)
      commit('errors/setErrors', [e], { root: true })
    }
  },

  async fetchOrderStatus(
    { commit, rootGetters },
    { route: { params }, redirect, merchant }
  ): Promise<void> {
    const { id, hash } = params
    const { $contextPath } = this
    const homePageUrl = $contextPath('/')
    if (!id || !hash) redirect(homePageUrl)

    try {
      const { $topup } = this

      const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']
      const country = isGlobalMarketplace
        ? rootGetters['context/country'].code.toUpperCase()
        : null
      const locale = isGlobalMarketplace
        ? rootGetters['context/recommerceLocale']
        : null

      const response = await fetchOrderStatus(
        $topup,
        {
          merchant,
          id,
          hash,
          country,
          locale,
        },
        this.$sentry
      )

      if (isEmpty(response)) {
        throw new Error('Error getting order status, the response is undefined')
      }

      const { status } = response
      if (status) {
        commit('setOrderStatus', status)
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') throw e
      this.$sentry.captureException(e)
      commit('errors/setErrors', [e], { root: true })
    }
  },

  async fetchOrderInfo(
    { commit, dispatch, rootGetters },
    { options, route: { params }, redirect, merchant }
  ): Promise<void> {
    const { id, hash } = params
    const { $contextPath } = this
    const homePageUrl = $contextPath('/')
    if (!id || !hash) redirect(homePageUrl)

    try {
      const { $topup } = this

      const isGlobalMarketplace = rootGetters['context/isGlobalMarketplace']
      const country = isGlobalMarketplace
        ? rootGetters['context/country'].code.toUpperCase()
        : null
      const locale = isGlobalMarketplace
        ? rootGetters['context/recommerceLocale']
        : null

      const { order } = await fetchOrder(
        $topup,
        {
          merchant,
          id,
          hash,
          country,
          locale,
        },
        this.$sentry
      )

      if (!order) {
        redirect('/')
        return
      }

      commit('setOrderDetails', { order })

      const email = get(order, 'user.email', '')
      commit('checkout/setEmail', { email }, { root: true })

      const { fetchProductInfo: shouldFetchProductInfo, withSections } = options

      if (!shouldFetchProductInfo) return

      const { products_service_id: productId = '0' } = get(
        order,
        'products[0]',
        {}
      )
      const { quantity } = order
      const parsedProductId = parseInt(productId, 10)
      const parsedQuantity = parseInt(quantity, 10)

      commit('setProduct', {
        productId: parsedProductId,
        quantity: parsedQuantity,
      })

      if (productId && withSections) {
        await dispatch('fetchRecommerceVariantInfo', {
          productId,
          order,
        })
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') throw e
      this.$sentry.captureException(e)
      commit('errors/setErrors', [e], { root: true })
    }
  },
}
