/* eslint-disable camelcase */

import { PageViewModel, IPageView } from './PageView'

interface IPageError extends IPageView {
  error_type: string
  error_msg: string
  error_code: string
}

export class PageErrorModel extends PageViewModel {
  protected readonly type: string
  protected readonly msg: string
  protected readonly code: string

  constructor({ url, store, error }) {
    super({
      url,
      store,
      pageType: null,
      pageViewAttrs: {},
    })
    const { type, msg, code } = error

    this.type = type
    this.msg = msg
    this.code = code
  }

  toJSON(): IPageError {
    const page = super.toJSON()
    return {
      ...page,
      page_type: 'error',
      error_code: this.code,
      error_msg: this.msg,
      error_type: this.type,
    }
  }
}

/* eslint-enable camelcase */
