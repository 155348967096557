import { MutationTree } from 'vuex'
import { IProductsStoreState, TProduct } from './state'
import MUTATION_TYPES from './mutation-types'
import { QuickBuyProductModel } from '~/models/components/QuickBuyProduct'

const mutations: MutationTree<IProductsStoreState> = {
  [MUTATION_TYPES.SET_PRODUCTS](
    state: IProductsStoreState,
    products: Record<string, TProduct>
  ): void {
    state.byId = {
      ...state.byId,
      ...products,
    }
  },
  [MUTATION_TYPES.SET_CONTENTFUL_PRODUCTS_WITH_NO_ID](
    state: IProductsStoreState,
    products
  ): void {
    state.contentfulWithNoId.push(...products)
  },

  [MUTATION_TYPES.SET_SOME_PRODUCTS_HAVE_RTR_REDEEM_TYPE](
    state: IProductsStoreState,
    someProductsHaveRTRRedeemType
  ): void {
    state.someProductsHaveRTRRedeemType = someProductsHaveRTRRedeemType
  },
  [MUTATION_TYPES.SET_TOPUP_PRODUCTS_WITH_NO_ID](
    state: IProductsStoreState,
    products
  ): void {
    state.topupWithNoId.push(...products)
  },
  [MUTATION_TYPES.UPDATE_QUICKBUY_PRODUCT](
    state: IProductsStoreState,
    { topupProduct, cmsProduct }
  ): void {
    const quickBuyProduct = new QuickBuyProductModel(
      cmsProduct,
      topupProduct
    ).toJSON()
    state.asyncQuickBuyProduct = {
      quickBuyProduct,
      loading: false,
      failed: false,
      available: topupProduct.available,
    }
  },
  [MUTATION_TYPES.LOADING_QUICKBUY_PRODUCT](state: IProductsStoreState): void {
    state.asyncQuickBuyProduct.loading = true
  },
  [MUTATION_TYPES.FETCH_QUICKBUY_PRODUCT_FAILED](
    state: IProductsStoreState
  ): void {
    state.asyncQuickBuyProduct.loading = false
    state.asyncQuickBuyProduct.failed = true
  },
  [MUTATION_TYPES.UPDATE_QUICKBUY_PRODUCT_AS_NOT_AVAILABLE](
    state: IProductsStoreState
  ): void {
    state.asyncQuickBuyProduct.available = false
  },
  [MUTATION_TYPES.SET_PAGE_VIEW_EVENT_LOGGED](
    state: IProductsStoreState
  ): void {
    state.pageViewEventLogged = true
  },
}

export default mutations
