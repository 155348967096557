export function addTopLevelCategory(
  productKind: string,
  category: string
): string {
  const mapping = {
    call_credit: 'Call Credit & Data',
    entertainment: 'Entertainment',
    shopping_giftcards: 'Shopping',
    game: 'Gaming',
    prepaid_credit_card: 'Prepaid Credit Card',
    crypto: 'Crypto',
    data: 'Call Credit & Data',
    international: 'Call Credit & Data',
    bundle: 'Call Credit & Data',
    chat: 'Call Credit & Data',
  }

  if (mapping[productKind]) {
    return `${mapping[productKind]}/${category}`
  }
  return category
}
