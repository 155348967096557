export interface ContentModal {
  title: string
  description: string
}

export interface UiContentModalState {
  modal: ContentModal | null
  visible: boolean
}

export default (): UiContentModalState => ({
  modal: null,
  visible: false,
})
