import { CMS402Error } from './CMS402Error'

export class DraftEntryError extends CMS402Error {
  constructor({ modelName, sys }) {
    const message = `Entry ${modelName.replace(
      'Model',
      ''
    )} does not have fields. The entry could be draft`
    super({ message, sys })
  }
}
