
import { Checkbox } from '~/components/atoms'
export default {
  components: { Checkbox },
  props: {
    isPreselected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCheckboxChange(value) {
      this.$emit('terms-contidions:change', value)
    },
  },
}
