export const HLR_STATUS = {
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  HLR_LOOKUP_SUCCESSFUL: 'HLR_LOOKUP_SUCCESSFUL',
  HLR_LOOKUP_FAILED: 'HLR_LOOKUP_FAILED',
  HLR_DCGW_CARRIER_NOT_FOUND: 'HLR_DCGW_CARRIER_NOT_FOUND',
  HLR_CARRIER_PRODUCT_NOT_FOUND: 'HLR_CARRIER_PRODUCT_NOT_FOUND',
}

export const PRE_FETCH_PARAM = 'pre-fetch'

export const NO_HLR_COUNTRIES = [
  'US',
  'CV',
  'MX',
  'DO',
  'JM',
  'NP',
  'TT',
  'GH',
  'ET',
  'HN',
  'IT',
]
