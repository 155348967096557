import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1ca55594&scoped=true&functional=true&class=absolute&"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=1ca55594&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1ca55594",
  null
  
)

export default component.exports