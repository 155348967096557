import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { ICMSLink, ILink } from '../base/Link'
import { ICMSExternalLinkModel, IExternalLinkModel } from '../base/ExternalLink'
import getGroupLinks from '~/services/group-links'

export interface IFooterLinks {
  title: string
  groupLinks: Array<ILink | IExternalLinkModel>
}

export interface ICMSFooterLinks extends Entry {
  fields: {
    title: string
    groupLinks: Array<ICMSLink | ICMSExternalLinkModel>
  }
}

export class FooterLinksModel {
  protected readonly title: string
  protected readonly groupLinks: Array<ILink | IExternalLinkModel>
  constructor({ fields: { title, groupLinks } }) {
    this.title = title
    this.groupLinks = getGroupLinks(groupLinks)
  }

  toJSON(): IFooterLinks {
    return {
      title: this.title,
      groupLinks: this.groupLinks,
    }
  }
}
