export enum TYPES {
  SET_EMAIL = 'setEmail',
  SET_SELECTED_PAYMENT_METHOD_ID = 'setSelectedPaymentMethodId',
  SET_ISSUER = 'setIssuer',
  SET_PAYMENT_INFORMATION_DATA = 'setPaymentInformationData',
  SET_GENERAL_INFO = 'setGeneralInfo',
  SET_REDIRECT_URLS = 'setRedirectUrls',
  SET_AFFILIATED_ID = 'setAffiliatedId',
  SET_PHONE_NUMBER = 'setPhoneNumber',
  SET_PHONE_CHALLENGE_ID = 'setPhoneChallengeId',
  SET_PHONE_CHALLENGE_CODE = 'setPhoneChallengeCode',
  SET_CREATE_PAYMENT_PAYLOAD = 'setCreatePaymentPayload',
  SET_TERMS_AND_CONDITIONS = 'setTermsAndConditions',
  SET_REDIRECT_URL = 'setRedirectUrl',
  SET_PRODUCT_ID = 'setProductId',
  SET_PRODUCT = 'setProduct',
  SET_REDEEM_PHONE_NUMBER = 'setRedeemPhoneNumber',
  SET_TOTAL_PRICE = 'setTotalPrice',
  SET_DISCOUNT_PRICE = 'setDiscountPrice',
  SET_VOUCHER = 'setVoucher',
  SET_PROMO_CODE_ERROR = 'setPromoCodeError',
  SET_PROMO_CODE_LOADING = 'setPromoCodeLoading',
  SET_IS_RETRY_ORDER = 'setIsRetryOrder',
  SET_SERVICE_FEE = 'setServiceFee',
  SET_CUSTOM_DENOMINATION_ERROR = 'setCustomDenominationError',
  SET_PRIMER_PHONE_CHALLENGE_ID = 'setPrimerPhoneChallengeId',
  SET_REDIRECT_CLIENT_TOKEN = 'setRedirectClientToken',
  SET_IS_REDIRECT_DEEP_LINK = 'setRedirectDeepLink',
  SET_BEACON_ID = 'setBeaconId',
  SET_RAVELIN_DEVICE_ID = 'setRavelinDeviceId',
}

export default TYPES
