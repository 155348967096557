import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { Document } from '@contentful/rich-text-types'
import { EntryModel } from '../base/Entry'
import { DocumentModel } from '../base/Document'

export interface IFreeFormat {
  title: string
  contentBlock: Document
  type: string
}

export interface ICMSFreeFormatModel extends Entry {
  fields: IFreeFormat
}

export class FreeFormatModel extends EntryModel {
  protected readonly title: string
  protected readonly contentBlock: Document
  protected readonly type: string

  constructor(freeFormatModel: ICMSFreeFormatModel) {
    super(freeFormatModel)
    const {
      fields: { title, contentBlock, type },
    } = freeFormatModel
    this.title = title
    this.contentBlock = new DocumentModel(contentBlock).toJSON()
    this.type = type
  }

  toJSON(): IFreeFormat {
    return {
      title: this.title,
      contentBlock: this.contentBlock,
      type: this.type,
    }
  }
}
