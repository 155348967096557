
import { PropOptions } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import CategoryListDesktop from './desktop.vue'
import BoxList from './box-list.vue'
import { Container } from '~/components/grid'
import { ICategoryList } from '~/models/components/CategoryList'

export default {
  components: {
    BoxList,
    Container,
    CategoryListDesktop,
  },
  props: {
    categoryCards: {
      type: Array,
      default() {
        return []
      },
    } as PropOptions<ICategoryList[] | []>,
    showCategoriesOnDesktop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    ...mapGetters('products', [
      'showQuickBuyProductCard',
      'quickBuyProductIsLoading',
    ]),
    showCategoryListForDesktop() {
      const {
        mounted,
        showQuickBuyProductCard,
        quickBuyProductIsLoading,
        showCategoriesOnDesktop,
      } = this

      return (
        mounted &&
        !showQuickBuyProductCard &&
        !quickBuyProductIsLoading &&
        showCategoriesOnDesktop
      )
    },
  },
  mounted() {
    this.validateLastProductSeen()
    this.mounted = true
  },
  methods: {
    ...mapActions('context', ['validateLastProductSeen']),
  },
}
