export const MOCK_CONTENTFUL_ID_PRODUCTS = {
  '40050': {},
  '40052': {},
}

export const MOCK_MATCHED_PRODUCTS_STORE_CONTENTFUL = {
  '40050': {
    id: '34701',
    products_service_id: '40050',
    available: true,
    brand_name: 'Orange',
    prices: [
      {
        __typename: 'Price',
        local_amount: '10.000',
        local_discount_amount: null,
        local_currency: 'PLN',
      },
    ],
    __typename: 'Product',
    redeem_type: 'rtr',
    product_type: 'Orange',
    product_kind: 'call_credit',
    service_fees: [{ __typename: 'Service_Fee', local_service_fee: '3.990' }],
    price: {
      localAmount: 10,
      discount: 0,
      currency: 'PLN',
      subtotal: 10,
      discountPercent: 0,
      total: '13.99',
    },
    serviceFee: 3.99,
  },
  '40052': {
    id: '34713',
    products_service_id: '40052',
    available: true,
    brand_name: 'Orange',
    prices: [
      {
        __typename: 'Price',
        local_amount: '50.000',
        local_discount_amount: null,
        local_currency: 'PLN',
      },
    ],
    __typename: 'Product',
    redeem_type: 'rtr',
    product_type: 'Orange',
    product_kind: 'call_credit',
    service_fees: [{ __typename: 'Service_Fee', local_service_fee: '5.990' }],
    price: {
      localAmount: 50,
      discount: 0,
      currency: 'PLN',
      subtotal: 50,
      discountPercent: 0,
      total: '55.99',
    },
    serviceFee: 5.99,
  },
}

export const MOCK_STORE_PRODUCTS = {
  '40050': {
    id: '34701',
    products_service_id: '40050',
    available: true,
    brand_name: 'Orange',
    prices: [
      {
        __typename: 'Price',
        local_amount: '10.000',
        local_discount_amount: null,
        local_currency: 'PLN',
      },
    ],
    __typename: 'Product',
    redeem_type: 'rtr',
    product_type: 'Orange',
    product_kind: 'call_credit',
    service_fees: [{ __typename: 'Service_Fee', local_service_fee: '3.990' }],
    price: {
      localAmount: 10,
      discount: 0,
      currency: 'PLN',
      subtotal: 10,
      discountPercent: 0,
      total: '13.99',
    },
    serviceFee: 3.99,
  },
  '40052': {
    id: '34713',
    products_service_id: '40052',
    available: true,
    brand_name: 'Orange',
    prices: [
      {
        __typename: 'Price',
        local_amount: '50.000',
        local_discount_amount: null,
        local_currency: 'PLN',
      },
    ],
    __typename: 'Product',
    redeem_type: 'rtr',
    product_type: 'Orange',
    product_kind: 'call_credit',
    service_fees: [{ __typename: 'Service_Fee', local_service_fee: '5.990' }],
    price: {
      localAmount: 50,
      discount: 0,
      currency: 'PLN',
      subtotal: 50,
      discountPercent: 0,
      total: '55.99',
    },
    serviceFee: 5.99,
  },
  '40053': {
    id: '34721',
    products_service_id: '40053',
    available: true,
    brand_name: 'Orange',
    prices: [
      {
        __typename: 'Price',
        local_amount: '200.000',
        local_discount_amount: null,
        local_currency: 'PLN',
      },
    ],
    __typename: 'Product',
    redeem_type: 'rtr',
    product_type: 'Orange',
    product_kind: 'call_credit',
    service_fees: [{ __typename: 'Service_Fee', local_service_fee: '14.990' }],
    price: {
      localAmount: 200,
      discount: 0,
      currency: 'PLN',
      subtotal: 200,
      discountPercent: 0,
      total: '214.99',
    },
    serviceFee: 14.99,
  },
  '40054': {
    id: '34705',
    products_service_id: '40054',
    available: true,
    brand_name: 'Orange',
    prices: [
      {
        __typename: 'Price',
        local_amount: '30.000',
        local_discount_amount: null,
        local_currency: 'PLN',
      },
    ],
    __typename: 'Product',
    redeem_type: 'rtr',
    product_type: 'Orange',
    product_kind: 'call_credit',
    service_fees: [{ __typename: 'Service_Fee', local_service_fee: '3.990' }],
    price: {
      localAmount: 30,
      discount: 0,
      currency: 'PLN',
      subtotal: 30,
      discountPercent: 0,
      total: '33.99',
    },
    serviceFee: 3.99,
  },
  '40055': {
    id: '34717',
    products_service_id: '40055',
    available: true,
    brand_name: 'Orange',
    prices: [
      {
        __typename: 'Price',
        local_amount: '100.000',
        local_discount_amount: null,
        local_currency: 'PLN',
      },
    ],
    __typename: 'Product',
    redeem_type: 'rtr',
    product_type: 'Orange',
    product_kind: 'call_credit',
    service_fees: [{ __typename: 'Service_Fee', local_service_fee: '9.990' }],
    price: {
      localAmount: 100,
      discount: 0,
      currency: 'PLN',
      subtotal: 100,
      discountPercent: 0,
      total: '109.99',
    },
    serviceFee: 9.99,
  },
}
