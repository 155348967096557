export default {
  errors: [
    {
      message:
        '[GraphQL]: Response not successful: Received status code 401\nToken validation failed, because: The JWT string must have two dots',
      statusCode: 401,
    },
  ],
  data: {},
}
