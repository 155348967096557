import { RecommerceResponse } from './types'
import { RECOMMERCE_APIS } from './enums'

const CACHE: Record<string, RecommerceResponse> = {}

type CacheKeyParams = {
  endpoint: keyof typeof RECOMMERCE_APIS
  params: Parameters<typeof RECOMMERCE_APIS[keyof typeof RECOMMERCE_APIS]>
  recommerceLocale: string
  redemptionCountry: string
  currency: string
}

export const setCache = (key: string, value: RecommerceResponse): void => {
  if (process.server) return
  CACHE[key] = value
}

export const getCache = (key: string): null | RecommerceResponse => {
  return CACHE[key]
}

export const createCacheKey = ({
  endpoint,
  params,
  recommerceLocale,
  redemptionCountry,
  currency,
}: CacheKeyParams): string => {
  const paramsPart = Object.entries(params || {})
    .map(kv => kv.join('.'))
    .sort()
    .join('_')
  return `${endpoint}-${paramsPart}-${recommerceLocale}-${redemptionCountry}-${currency}`
}

export const hydrateCache = (
  keyParams: CacheKeyParams,
  data: RecommerceResponse
): void => {
  const cacheKey = createCacheKey(keyParams)
  setCache(cacheKey, data)
}
