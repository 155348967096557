
import Vue, { PropOptions } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import get from '~/utils/get'
import {
  UiHeader,
  UiFooter,
  ContentModal,
  NotificationBar,
} from '~/components/organisms'

type TLayoutType =
  | 'simple'
  | 'base'
  | 'country-restricted'
  | 'wide'
  | 'business'

export default Vue.extend({
  components: {
    ContentModal,
    UiHeader,
    UiFooter,
    NotificationBar,
  },
  props: {
    type: {
      type: String,
      default: 'base',
    } as PropOptions<TLayoutType>,
    header: {
      type: Object,
      default: null,
    },
    redirectCclToHome: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Object,
      default: null,
    },
    country: {
      type: Object,
      default: () => ({}),
    },
    lang: {
      type: String,
      default: 'en',
    },
    isContentLayoutStretched: {
      type: Boolean,
      default: false,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    hideUsps: {
      type: Boolean,
      default: false,
    },
    showBreadcrumbs: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      shouldShowRebrandingNotification: false,
    }
  },
  computed: {
    ...mapGetters('context', ['direction', 'isGlobalMarketplace']),
    ...mapGetters('ui/content-modal', ['modal', 'modalVisible']),
    ...mapGetters('ui', ['overlayVisibility']),
    displayClass(): string {
      return this.isContentLayoutStretched
        ? `${this.backgroundColor} flex flex-1`
        : `${this.backgroundColor}  block`
    },
    mobileMenuSecondaryLinks() {
      if (this.isGlobalMarketplace) {
        return get(this, 'footer.footerLinksGroup[0].groupLinks', [])
      }
      return this.header.secondaryLinks
    },
  },
  mounted() {
    this.setBcSegments()
  },
  methods: {
    ...mapActions('context', ['setBcSegments']),
  },
})
