import { BLOCKS } from '@contentful/rich-text-types'
const {
  HEADING_1,
  HEADING_2,
  HEADING_3,
  HEADING_4,
  HEADING_5,
  HEADING_6,
} = BLOCKS
export default {
  [HEADING_1]: ({ content }, key, h, next) =>
    h('h1', { key }, next(content, key, h, next)),
  [HEADING_2]: ({ content }, key, h, next) =>
    h('h2', { key }, next(content, key, h, next)),
  [HEADING_3]: ({ content }, key, h, next) =>
    h('h3', { key }, next(content, key, h, next)),
  [HEADING_4]: ({ content }, key, h, next) =>
    h('h4', { key }, next(content, key, h, next)),
  [HEADING_5]: ({ content }, key, h, next) =>
    h('h5', { key }, next(content, key, h, next)),
  [HEADING_6]: ({ content }, key, h, next) =>
    h('h6', { key }, next(content, key, h, next)),
}
