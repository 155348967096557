import { TYPES } from './mutation-types'
import { IRtrUserState } from './state'
import { RtrUserCommit } from './mutations'
import { ActionTree } from '~/store/types'

const { SET_PHONE_NUMBER } = TYPES

interface Actions {
  setPhoneNumber(phoneNumer: string): void
}

const actions: ActionTree<IRtrUserState, RtrUserCommit, Actions> = {
  setPhoneNumber({ commit }, phoneNumber: string): void {
    commit(SET_PHONE_NUMBER, phoneNumber)
  },
}

export default actions
