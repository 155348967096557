import { GetterTree } from 'vuex'
import { IUserState } from './state'

import { APPLE_USER, FACEBOOK_USER, GOOGLE_USER } from '~/utils/constants/users'

const getters: GetterTree<IUserState, IUserState> = {
  user: ({ user }) => user,
  isAuthenticated: ({ user }) => {
    return !!user?.email
  },
  hasFederatedIdentities: ({ user }) => {
    return user?.identities && !!user?.identities.length
  },
  isReferred: ({ user }) => {
    return !!user?.referredByCodeId
  },
  isRafEligible: ({ rafEligible }) => rafEligible,
  isNativeAccount: ({ user }) => {
    if (!user) {
      return false
    }
    return [FACEBOOK_USER, GOOGLE_USER, APPLE_USER].every(
      provider => !user.username.includes(provider)
    )
  },
}

export default getters
