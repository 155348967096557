import { Asset, Maybe } from '~/apis/clients/graphql/types/contentful'
import {
  RecommerceImage,
  RecommerceProduct,
} from '~/apis/clients/rest/recommerce/types'

export type ProductContentImage = {
  title: string
  url: Maybe<string>
  alt: Maybe<string>
}

const getFormattedContentfulUrl = (
  contentType?: Maybe<string>,
  url?: Maybe<string>
): string => {
  if (!url) return ''
  let formattedUrl = url?.replace(/^https?:\/\//, '')
  switch (contentType) {
    case 'image/jpg':
    case 'image/jpeg':
      formattedUrl = `${formattedUrl}?fm=jpg&fl=progressive`
      break
    case 'image/png':
      formattedUrl = `${formattedUrl}?fm=png&fl=png8`
      break
    case 'image/svg+xml':
      formattedUrl = `${formattedUrl}?q=100`
      break
    default:
      formattedUrl = formattedUrl || ''
  }
  const arrayOfPaths = formattedUrl.split('/')
  if (arrayOfPaths.length <= 1) return formattedUrl
  return arrayOfPaths
    .filter(item => item && !item.includes('http'))
    .map((item, index) =>
      index === 0 ? `//${process.env.CMS_ASSETS_DOMAIN}` : item
    )
    .join('/')
}

// Turns a contentful Asset into an image object that can be used by the ui-image component.
// Based on legacy normalization code.

export const contentfulToImage = (
  image: Pick<Asset, 'contentType' | 'height' | 'width' | 'url' | 'title'>
) => {
  return {
    file: {
      contentType: image.contentType,
      details: {
        image: {
          height: image.height,
          width: image.width,
          ratio: (image.width || 1) / (image.height || 1),
        },
      },
      url: getFormattedContentfulUrl(image.contentType, image.url),
    },
    title: image.title,
  }
}

// Turns a recommerce image object into an image object that can be used by the ui-image component.

export const recommerceToImage = (
  title: string,
  image: RecommerceImage
): ProductContentImage => {
  return {
    title,
    url: image.path,
    alt: image.alt,
  }
}

// Finds a recommerce image inside your objects image list, based on given type
// Returns an image object that can be used by the ui-image component.

export const recommerceToImageByType = (
  product: RecommerceProduct,
  type: string
): ProductContentImage | Record<string, never> => {
  if (!product || !product.images) return {}
  const image = product.images.find(image => image.type === type)
  if (!image) return {}
  return recommerceToImage(product.name, image)
}
