import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { IImageModel, ICMSImageModel, ImageModel } from '../base/Image'
import { EntryModel } from '../base/Entry'
import { ICMSCountryModel, ICountryModel, CountryModel } from './Country'
import { CountryLangListModel, ICountryLangList } from './CountryLangList'

export interface ICMSMerchant extends Entry {
  fields: {
    name: string
    logo: ICMSImageModel
    countries: ICMSCountryModel[]
    defaultCountry: ICMSCountryModel
  }
}

export interface IMerchant {
  name: string
  logo: IImageModel
  countries: ICountryLangList
  defaultCountry: ICountryModel
}

export class MerchantModel extends EntryModel {
  protected readonly name: string
  protected readonly defaultCountry: ICountryModel
  protected readonly countries: ICountryLangList
  protected readonly logo: IImageModel

  constructor(merchantModel: ICMSMerchant) {
    super(merchantModel)
    this.name = this.requiredField('name')
    this.countries = new CountryLangListModel(
      this.requiredField('countries')
    ).toJSON()
    this.defaultCountry = new CountryModel(
      this.requiredField('defaultCountry')
    ).toJSON()
    this.logo = new ImageModel(this.requiredField('logo')).toJSON()
  }

  toJSON(): IMerchant {
    return {
      name: this.name,
      countries: this.countries,
      defaultCountry: this.defaultCountry,
      logo: this.logo,
    }
  }
}
