export enum TYPES {
  SET_PRODUCTS = 'setProducts',
  SET_PRODUCT = 'setProduct',
  SET_COUNTRY = 'setCountry',
  SET_LAYOUT = 'setLayout',
  SET_HEADER = 'setHeader',
  SET_META_TITLE = 'setMetaTitle',
  SET_STRUCTURED_DATA = 'setStructuredData',
  SET_FOOTER = 'setFooter',
  SET_COUNTRY_LIST = 'setCountryList',
  SET_BRAND_NAV_LINKS = 'setBrandNavLinks',
  SET_BRAND_INFO = 'setBrandInfo',
  SET_PAYMENT_METHODS = 'setPaymentMethods',
  SET_META_LINKS = 'setMetaLinks',
  SET_META_TAGS_DATA = 'setMetaTagsData',
  SET_PAGE_TITLE = 'setPageTitle',
  SET_PAGE_TYPE = 'setPageType',
  SET_PARENT_PAGE_INFO = 'setParentPageInfo',
  SET_ABOUT_US_URL = 'aboutUsUrl',
  SET_PATH_TRANSLATIONS = 'setPathTranslations',
  SET_PDP = 'setPdp',
  SET_BRAND_PAGE = 'setBrandPage',
  SET_CATEGORY_PAGE = 'setCategoryPage',
  SET_HOME = 'setHome',
  SET_PAYMENT_METHODS_PAGE = 'setPaymentMethodsPage',
  SET_SECONDARY_PAGE = 'setSecondaryPage',
  SET_BLOG_PAGE = 'setBlogPage',
  SET_BLOG_OVERVIEW_PAGE = 'setBlogOverviewPage',
  SET_SHARED_DATA = 'setSharedData',
}

export default TYPES
