
import { mapGetters } from 'vuex'
import { ButtonIcon } from '~/components/atoms'

export default {
  components: { ButtonIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'Overlay',
    },
  },
  computed: {
    ...mapGetters('ui', ['overlayVisibility']),
  },
  methods: {
    onOverlayClose() {
      this.$store.dispatch('ui/closeOverlay')
    },
  },
}
