<template>
  <div class="my-6 w-full">
    <img class="mx-auto" src="@/assets/images/icons/svg/tilde.svg" />
  </div>
</template>

<script>
export default {
  name: 'TildeDivider',
}
</script>
