var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    '-mt-8 w-full h-auto lg:h-hero-banner relative inline-block banner-background',
    _vm.bottomMarginClasses,
  ],attrs:{"role":"banner"}},[(_vm.showBackgroundImage)?_c('ui-image',{attrs:{"src":_vm.src,"height":"full","width":"full"}}):_vm._e(),_vm._v(" "),(!_vm.disableQuickBuyProduct)?_c('div',{staticClass:"flex justify-center w-full start-0"},[_c('container',{class:[
        'pt-8 md:pt-16 flex',
        {
          'justify-center':
            _vm.showReorderCard || _vm.showQuickBuyProductCard || _vm.showCountrySelector,
        },
      ]},[_c('div',{staticClass:"inline-block w-full sm:w-auto"},[_c('row',{staticClass:"mb-8 sm:mb-6"},[_c('column',{staticClass:"w-full"},[_c('h1',{class:[
                {
                  'w-full': _vm.comesFromRechargeClassic,
                  'w-64':
                    _vm.showReorderCard ||
                    _vm.showQuickBuyProductCard ||
                    !_vm.showCountrySelector,
                },
                'leading-none text-tertiary-light font-semibold text-4xl lg:text-6xl',
                _vm.headerWidthClass,
              ]},[_vm._v("\n              "+_vm._s(_vm.propositionTitle)+"\n            ")])])],1),_vm._v(" "),_c('row',[_c('column',{staticClass:"w-full sm:w-auto"},[(_vm.showReorderCard)?[_c('reorder-card',{attrs:{"loading":_vm.orderLoading,"currency":_vm.currency,"order-data":_vm.reorderableOrder,"is-mobile":_vm.isMobile}})]:_vm._e(),_vm._v(" "),(_vm.showQuickBuyProductCard && !_vm.showReorderCard)?[_c('quick-buy-card',_vm._b({attrs:{"loading":_vm.quickBuyProductIsLoading,"currency":_vm.currency}},'quick-buy-card',_vm.quickBuyProduct,false))]:_vm._e()],2)],1),_vm._v(" "),(_vm.showCountrySelector && !_vm.showReorderCard)?_c('row',[_c('hero-country-selector',{attrs:{"force-desktop-view":true}})],1):_vm._e()],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }