import FilteredKeysByType from './types/filteredKeysByType'

export const groupBy = <
  T extends Record<PropertyKey, any>,
  K extends FilteredKeysByType<T, string | number>
>(
  items: T[],
  key: K
): Record<T[K], T[]> => {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {} as Record<T[K], T[]>
  )
}
