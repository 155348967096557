import capitalize from './capitalize'

const camelCase = (str = ''): string =>
  str
    .replace(/[A-Z][a-z]+|[0-9]+|[a-z]+/g, '$& ')
    .split(/\s|-|_/)
    .filter(n => n)
    .map((el, index) => (index === 0 ? el.toLowerCase() : capitalize(el)))
    .join('')

export default camelCase
