
import { createPopper } from '@popperjs/core'
import { PropOptions } from 'vue'
import { Icon } from '@/components/atoms'

export default {
  name: 'IconTooltip',
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      default: 'info',
    } as PropOptions<string>,
    iconWidth: {
      type: String,
      default: '15',
    } as PropOptions<string>,
  },
  data() {
    return {
      tooltipContainer: null,
      tooltipShow: false,
      popperTooltip: null,
    }
  },
  computed: {
    isMobile() {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
  },
  mounted() {
    this.tooltipContainer = document.getElementById(`${this._uid}-tooltip`)
  },
  methods: {
    onMouseLeave(): void {
      if (this.isMobile) return
      this.hideTooltip()
    },
    onMouseEnter(): void {
      if (this.isMobile) return
      this.showTooltip()
    },
    showTooltip(): void {
      this.tooltipShow = true
      this.popperTooltip = createPopper(
        this.tooltipContainer,
        this.$refs.tooltipRef,
        {
          placement: 'right',
          modifiers: [
            {
              name: 'eventListeners',
              options: { scroll: false },
            },
          ],
        }
      )
    },
    hideTooltip() {
      if (!this.tooltipShow) return
      this.popperTooltip.destroy()
      this.popperTooltip = null
      this.tooltipShow = false
    },
    toggleTooltip() {
      if (this.tooltipShow) {
        this.hideTooltip()
      } else {
        this.showTooltip()
      }
    },
  },
}
