type TRel = 'canonical' | 'alternate'

export interface IMetaLink {
  rel: TRel
  href: string
  hreflang?: string
}

export type IMetaLinks = IMetaLink[]

export class MetaLinksModel {
  protected canonicalUrl: IMetaLink | undefined
  protected alternateUrls: IMetaLinks

  constructor(canonicalPath: string | undefined, alternatePaths: string[]) {
    this.canonicalUrl = canonicalPath
      ? MetaLinksModel.toMetaLink('canonical', canonicalPath)
      : undefined

    this.alternateUrls = alternatePaths.map((alternatePath: string) =>
      MetaLinksModel.toMetaLink('alternate', alternatePath)
    )
  }

  public toJSON(): IMetaLinks {
    return this.canonicalUrl
      ? [this.canonicalUrl, ...this.alternateUrls]
      : this.alternateUrls
  }

  private static toMetaLink(type: TRel, path: string): IMetaLink {
    const pathWithHost = path.startsWith('recharge.com')
      ? path
      : `recharge.com${path}`
    const metaLink: IMetaLink = {
      rel: type,
      href: `https://www.${pathWithHost}`,
    }
    if (type === 'alternate') metaLink.hreflang = path.substr(13, 5)

    return metaLink
  }
}
