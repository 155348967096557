export enum PAYMENT_METHODS {
  'buckaroo_instant_ideal' = 'ideal',
  'cg_test' = 'paypal',
  'paypal_expresscheckout' = 'paypal',
  'adyen_visa' = 'visa',
  'telserv_ivr' = 'telephone-payment',
  'adyen_mastercard' = 'mastercard',
}

export enum PAYMENT_METHOD_FIELDS {
  BILLING_SALUTATION = 'BILLING_SALUTATION',
  BILLING_FIRSTNAME = 'BILLING_FIRSTNAME',
  BILLING_LASTNAME = 'BILLING_LASTNAME',
  BILLING_STREET = 'BILLING_STREET',
  BILLING_CITY = 'BILLING_CITY',
  BILLING_POSTALCODE = 'BILLING_POSTALCODE',
  BILLING_HOUSENUMBER = 'BILLING_HOUSENUMBER',
  BILLING_PHONENUMBER = 'BILLING_PHONENUMBER',
  BILLING_PHONENUMBER_PREFIX = 'BILLING_PHONENUMBER_PREFIX',
  BILLING_PHONENUMBER_COUNTRY = 'BILLING_PHONENUMBER_COUNTRY',
  BILLING_EMAILADDRESS = 'BILLING_EMAILADDRESS',
  BILLING_BIRTHDATE = 'BILLING_BIRTHDATE',
  BILLING_COUNTRY = 'BILLING_COUNTRY',
  BILLING_STATE = 'BILLING_STATE',
  BILLING_CODICE_FISCALE = 'BILLING_CODICE_FISCALE',
  REMEMBER = 'REMEMBER',
  PHONE_CHALLENGE_NUMBER = 'PHONE_CHALLENGE_NUMBER',
  PHONE_CHALLENGE_ID = 'PHONE_CHALLENGE_ID',
  PHONE_CHALLENGE_CODE = 'PHONE_CHALLENGE_CODE',
}
