/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from '~/utils/isEmpty'
import { RequiredFieldError, DraftEntryError } from '~/apis'

export interface ICMSEntry {
  fields: Record<string, any>
  sys?: Record<string, any>
}

export abstract class EntryModel {
  protected readonly fields: Record<string, any>
  protected readonly sys?: Record<string, any>
  protected cmsIdentifier = 'internalName'
  protected locale?: string

  constructor({ fields, sys }: ICMSEntry = { fields: {}, sys: {} }) {
    if (isEmpty(fields)) {
      throw new DraftEntryError({
        modelName: this.constructor.name,
        sys,
      })
    }

    this.fields = fields
    this.sys = sys
    this.locale = sys ? sys.locale : undefined
  }

  toJSON(): any | never {
    throw new Error(`Please implement toJSON in: ${this.constructor.name}`)
  }

  requiredField(fieldName: string): any {
    const field = this.fields[fieldName]
    if (field) return field

    const name =
      this.fields[this.cmsIdentifier] || this.fields.name || this.fields.title

    throw new RequiredFieldError({
      fieldName,
      modelName: this.constructor.name,
      fieldIdentifier: this.cmsIdentifier,
      name,
      locale: this.locale,
      sys: this.sys,
    })
  }
}
