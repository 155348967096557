export const encodeBase64 = (str = ''): string => {
  if (process.server) {
    return Buffer.from(str).toString('base64')
  }
  return btoa(str)
}

export const decodeBase64 = (encodedStr = ''): string => {
  if (process.server) {
    return Buffer.from(encodedStr, 'base64').toString('ascii')
  }

  return atob(encodedStr)
}
