import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { PageModel, IPage, ICMSPageModel } from '../base/Page'
import { ICategory, CategoryModel, ICMSCategory } from '../base/Category'
import { EntryModel } from '../base/Entry'
import mapWithFilter from '~/services/map-with-filter'
import { ICountryLangItem } from '~/models/components/CountryLangList'

export interface IBrandLogo {
  title: string
  url: string
  alt: string | null
}

export interface ICMSBrand {
  logo: IBrandLogo
  slug: string
  title: string
  countries: ICountryLangItem
}

export interface IBrandList {
  title?: string
  brands: ICategory[]
  categoryPage?: IPage
}

export interface ICMSBrandList extends Entry {
  fields: {
    title?: string
    brands: ICMSCategory[]
    categoryPage?: ICMSPageModel
  }
}

export class BrandListModel extends EntryModel {
  protected readonly brands: ICategory[]
  protected readonly title?: string
  protected readonly categoryPage?: IPage

  constructor(brandListModel: ICMSBrandList) {
    super(brandListModel)
    const {
      fields: { brands, title, categoryPage },
    } = brandListModel
    this.title = title

    const formattedBrands = mapWithFilter(
      brands,
      brand => new CategoryModel(brand).toJSON(),
      'fields'
    )

    this.brands = formattedBrands as ICategory[]
    this.categoryPage = categoryPage
      ? new PageModel(categoryPage).toJSON()
      : undefined
  }

  toJSON(): IBrandList {
    return {
      title: this.title,
      brands: this.brands,
      categoryPage: this.categoryPage,
    }
  }
}
