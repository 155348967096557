type GrahpqlBase = {
  __typename?: string
}

function noKeysOrOnlyTypename(value: GrahpqlBase) {
  if (Object.keys(value).length === 0) return true
  if (Object.keys(value).length === 1 && value.__typename) return true
  return false
}

export function notEmpty<TValue extends GrahpqlBase>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) return false
  if (noKeysOrOnlyTypename(value)) return false
  return true
}
