
import { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import { MainTitle, BannerImage } from '~/components/molecules'
import { UiButton, Alert } from '~/components/atoms'
import { Container, Row, Column } from '~/components/grid'
import { RequestError } from '~/apis'

export default {
  components: {
    MainTitle,
    UiButton,
    Container,
    BannerImage,
    Alert,
    Row,
    Column,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    } as PropOptions<RequestError | Record<string, unknown>>,
  },
  computed: {
    ...mapGetters('context', ['shouldShowErrorDetails']),
    statusCode() {
      const {
        error: { statusCode = 'non_existent_error' },
      } = this
      return statusCode
    },
    translatedErrorTitle() {
      const hasErrorBoxTranslation = this.$te(
        `organisms.error_box.${this.statusCode}.title`
      )
      if (hasErrorBoxTranslation)
        return this.$t(`organisms.error_box.${this.statusCode}.title`)

      const hasDefaultErrorTranslation = this.$te(`errors.${this.statusCode}`)
      if (hasDefaultErrorTranslation)
        return this.$t(`errors.${this.statusCode}`)
      return this.statusCode
    },
    translatedErrorDescription() {
      const hasErrorBoxTranslation = this.$te(
        `organisms.error_box.${this.statusCode}.description`
      )

      if (hasErrorBoxTranslation)
        return this.$t(`organisms.error_box.${this.statusCode}.description`)

      const hasDefaulErrorTranslation = this.$te(`errors.${this.statusCode}`)
      if (hasDefaulErrorTranslation) return this.$t(`errors.${this.statusCode}`)
      const {
        error: { message },
      } = this
      return message
    },
    errorImage() {
      return this.statusCode === 404 ? '404_Banner.png' : 'error-banner.jpg'
    },
  },
  methods: {
    onClickResetButton() {
      this.$router.go()
    },
  },
}
