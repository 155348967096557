import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d28f331 = () => interopDefault(import('~/pages/raf/raf-invitation.vue' /* webpackChunkName: "" */))
const _5938b8ee = () => interopDefault(import('~/pages/home.vue' /* webpackChunkName: "" */))
const _7544f667 = () => interopDefault(import('~/pages/app.vue' /* webpackChunkName: "" */))
const _0cca7d77 = () => interopDefault(import('~/pages/raf/raf-home.vue' /* webpackChunkName: "" */))
const _42005166 = () => interopDefault(import('~/pages/auth/reset-password.vue' /* webpackChunkName: "" */))
const _79681406 = () => interopDefault(import('~/pages/auth/change-email-confirmation.vue' /* webpackChunkName: "" */))
const _8a135634 = () => interopDefault(import('~/pages/auth/verify-account.vue' /* webpackChunkName: "" */))
const _69aa2050 = () => interopDefault(import('~/pages/account/my-account.vue' /* webpackChunkName: "" */))
const _b1bd8fe6 = () => interopDefault(import('~/pages/account/profile.vue' /* webpackChunkName: "" */))
const _8def51b2 = () => interopDefault(import('~/pages/account/order-details.vue' /* webpackChunkName: "" */))
const _0d622d9e = () => interopDefault(import('~/pages/account/orders.vue' /* webpackChunkName: "" */))
const _43e9c29f = () => interopDefault(import('~/pages/account/contacts.vue' /* webpackChunkName: "" */))
const _391b59d8 = () => interopDefault(import('~/pages/account/add-contact.vue' /* webpackChunkName: "" */))
const _591c813c = () => interopDefault(import('~/pages/account/my-phone-number-details.vue' /* webpackChunkName: "" */))
const _a20eb40e = () => interopDefault(import('~/pages/account/contact-details.vue' /* webpackChunkName: "" */))
const _7b07fc12 = () => interopDefault(import('~/pages/account/edit-my-phone-number.vue' /* webpackChunkName: "" */))
const _1361dea9 = () => interopDefault(import('~/pages/account/edit-contact.vue' /* webpackChunkName: "" */))
const _26df9b0c = () => interopDefault(import('~/pages/account/security.vue' /* webpackChunkName: "" */))
const _e31462fa = () => interopDefault(import('~/pages/account/update-password.vue' /* webpackChunkName: "" */))
const _366036a2 = () => interopDefault(import('~/pages/auth/register-confirmation.vue' /* webpackChunkName: "" */))
const _5fd1b9f2 = () => interopDefault(import('~/pages/blog/gmp.vue' /* webpackChunkName: "" */))
const _4944fb56 = () => interopDefault(import('~/pages/blog/gmp-post.vue' /* webpackChunkName: "" */))
const _617df3cb = () => interopDefault(import('~/pages/variant.vue' /* webpackChunkName: "" */))
const _68882d31 = () => interopDefault(import('~/pages/payment-methods.vue' /* webpackChunkName: "" */))
const _54a72fb6 = () => interopDefault(import('~/pages/carriers.vue' /* webpackChunkName: "" */))
const _0fb1721d = () => interopDefault(import('~/pages/order/error/_orderId/_hash/index.vue' /* webpackChunkName: "" */))
const _36d4fcea = () => interopDefault(import('~/pages/business.vue' /* webpackChunkName: "" */))
const _533b4389 = () => interopDefault(import('~/pages/checkout/payment-blocked/index.vue' /* webpackChunkName: "" */))
const _d953c544 = () => interopDefault(import('~/pages/checkout/old-checkout/index.vue' /* webpackChunkName: "" */))
const _e5f63a64 = () => interopDefault(import('~/pages/category-brand-legal.vue' /* webpackChunkName: "" */))
const _3812e8ac = () => interopDefault(import('~/pages/order/editnumber/_id/_hash/index.vue' /* webpackChunkName: "" */))
const _ec8424ee = () => interopDefault(import('~/pages/order/finished/_id/_hash/index.vue' /* webpackChunkName: "" */))
const _66151df8 = () => interopDefault(import('~/pages/order/paymentprogress/_id/_hash/index.vue' /* webpackChunkName: "" */))
const _c7608362 = () => interopDefault(import('~/pages/order/refund/_id/_hash/index.vue' /* webpackChunkName: "" */))
const _2b35631d = () => interopDefault(import('~/pages/order/finished/_id/_hash/distribution.vue' /* webpackChunkName: "" */))
const _7e9df67e = () => interopDefault(import('~/pages/order/finished/_id/_hash/investigated.vue' /* webpackChunkName: "" */))
const _3ee440b9 = () => interopDefault(import('~/pages/order/finished/_id/_hash/investigation.vue' /* webpackChunkName: "" */))
const _4335180e = () => interopDefault(import('~/pages/order/finished/_id/_hash/pending.vue' /* webpackChunkName: "" */))
const _3abd2dca = () => interopDefault(import('~/pages/order/finished/_id/_hash/shipped.vue' /* webpackChunkName: "" */))
const _5cb1cc82 = () => interopDefault(import('~/pages/auth/oauth-sign-in.vue' /* webpackChunkName: "" */))
const _f2ce677e = () => interopDefault(import('~/pages/auth/oauth-sign-out.vue' /* webpackChunkName: "" */))
const _cb5efb62 = () => interopDefault(import('~/pages/blog/index.vue' /* webpackChunkName: "" */))
const _4c5c8ab3 = () => interopDefault(import('~/pages/blog/post.vue' /* webpackChunkName: "" */))
const _2f093839 = () => interopDefault(import('~/error-pages/404.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/i/:referralCode",
    component: _2d28f331,
    meta: {"redirectIfLoggedIn":true},
    name: "refer-a-friend-invitation-without-locale"
  }, {
    path: "/:language/:country(..)",
    component: _5938b8ee,
    name: "home"
  }, {
    path: "/:language/:country(..)/app",
    component: _7544f667,
    name: "app"
  }, {
    path: "/:language/:country(..)/refer-a-friend",
    component: _0cca7d77,
    name: "refer-a-friend"
  }, {
    path: "/:language/:country(..)/i/:referralCode",
    component: _2d28f331,
    meta: {"redirectIfLoggedIn":true},
    name: "refer-a-friend-invitation"
  }, {
    path: "/:language/:country(..)/reset-password",
    component: _42005166,
    meta: {"redirectIfLoggedIn":true},
    name: "reset-password"
  }, {
    path: "/:language/:country(..)/change-email-confirmation",
    component: _79681406,
    name: "change-email-confirmation"
  }, {
    path: "/:language/:country(..)/verify-account",
    component: _8a135634,
    meta: {"redirectIfLoggedIn":true},
    name: "verify-account"
  }, {
    path: "/:language/:country(..)/my-account",
    component: _69aa2050,
    meta: {"requiresAuth":true},
    name: "my-account"
  }, {
    path: "/:language/:country(..)/my-account/profile",
    component: _b1bd8fe6,
    meta: {"requiresAuth":true},
    name: "profile"
  }, {
    path: "/:language/:country(..)/my-account/orders/:id/details",
    component: _8def51b2,
    meta: {"requiresAuth":true},
    name: "order-details"
  }, {
    path: "/:language/:country(..)/my-account/orders",
    component: _0d622d9e,
    meta: {"requiresAuth":true},
    name: "orders"
  }, {
    path: "/:language/:country(..)/my-account/contacts",
    component: _43e9c29f,
    meta: {"requiresAuth":true,"requiresContactsFeatureFlag":true},
    name: "contacts"
  }, {
    path: "/:language/:country(..)/my-account/contacts/add",
    component: _391b59d8,
    meta: {"requiresAuth":true,"requiresContactsFeatureFlag":true},
    name: "add-contact"
  }, {
    path: "/:language/:country(..)/my-account/contacts/my-phone-number/details",
    component: _591c813c,
    meta: {"requiresAuth":true,"requiresContactsFeatureFlag":true},
    name: "my-phone-number-details"
  }, {
    path: "/:language/:country(..)/my-account/contacts/:id/details",
    component: _a20eb40e,
    meta: {"requiresAuth":true,"requiresContactsFeatureFlag":true},
    name: "contact-details"
  }, {
    path: "/:language/:country(..)/my-account/contacts/my-phone-number/edit",
    component: _7b07fc12,
    meta: {"requiresAuth":true,"requiresContactsFeatureFlag":true},
    name: "edit-my-phone-number"
  }, {
    path: "/:language/:country(..)/my-account/contacts/:id/edit",
    component: _1361dea9,
    meta: {"requiresAuth":true,"requiresContactsFeatureFlag":true},
    name: "edit-contact"
  }, {
    path: "/:language/:country(..)/my-account/security",
    component: _26df9b0c,
    meta: {"requiresAuth":true,"noFederatedAccount":true},
    name: "security"
  }, {
    path: "/:language/:country(..)/my-account/security/update-password",
    component: _e31462fa,
    meta: {"requiresAuth":true,"noFederatedAccount":true},
    name: "update-password"
  }, {
    path: "/:language/:country(..)/register-confirmation",
    component: _366036a2,
    meta: {"redirectIfLoggedIn":true},
    name: "register-confirmation"
  }, {
    path: "/:language/:country(..)/blog",
    component: _5fd1b9f2,
    name: "blog-overview"
  }, {
    path: "/:language/:country(..)/blog/:pageName",
    component: _4944fb56,
    name: "blog-post"
  }, {
    path: "/:language/:country(..)/:brand/:product",
    component: _617df3cb,
    name: "variant"
  }, {
    path: "/:language/:country(..)/payment-methods",
    component: _68882d31,
    name: "payment-methods"
  }, {
    path: "/:language/:country(..)/carriers",
    component: _54a72fb6,
    name: "carriers"
  }, {
    path: "/:language/:country(..)/order/error/:orderId/:hash/:paymentMethod/:errorType",
    component: _0fb1721d,
    name: "order-error"
  }, {
    path: "/:language/:country(..)/business",
    component: _36d4fcea,
    name: "business"
  }, {
    path: "/:language/:country(..)/checkout-blocked",
    component: _533b4389,
    name: "payment-blocked"
  }, {
    path: "/:language/:country(..)/checkout",
    component: _d953c544,
    name: "checkout"
  }, {
    path: "/:language/:country(..)/checkout/error/:orderId/:hash/:paymentMethod?/:errorType?",
    component: _d953c544,
    name: "checkout-error"
  }, {
    path: "/:language/:country(..)/checkout/cancel/:orderId/:hash",
    component: _d953c544,
    name: "checkout-cancel"
  }, {
    path: "/:language/:country(..)/:pageName",
    component: _e5f63a64,
    name: "category-product-legal"
  }, {
    path: "/:language/:country(..)/order/editnumber/:id?/:hash",
    component: _3812e8ac,
    name: "order-editnumber-id-hash"
  }, {
    path: "/:language/:country(..)/order/error/:orderId?/:hash",
    component: _0fb1721d,
    name: "order-error-orderId-hash"
  }, {
    path: "/:language/:country(..)/order/finished/:id?/:hash",
    component: _ec8424ee,
    name: "order-finished-id-hash"
  }, {
    path: "/:language/:country(..)/order/paymentprogress/:id?/:hash/:checkouttype(new-checkout)?",
    component: _66151df8,
    name: "order-paymentprogress-id-hash"
  }, {
    path: "/:language/:country(..)/order/refund/:id?/:hash",
    component: _c7608362,
    name: "order-refund-id-hash"
  }, {
    path: "/:language/:country(..)/order/finished/:id?/:hash?/distribution",
    component: _2b35631d,
    name: "order-finished-id-hash-distribution"
  }, {
    path: "/:language/:country(..)/order/finished/:id?/:hash?/investigated",
    component: _7e9df67e,
    name: "order-finished-id-hash-investigated"
  }, {
    path: "/:language/:country(..)/order/finished/:id?/:hash?/investigation",
    component: _3ee440b9,
    name: "order-finished-id-hash-investigation"
  }, {
    path: "/:language/:country(..)/order/finished/:id?/:hash?/pending",
    component: _4335180e,
    name: "order-finished-id-hash-pending"
  }, {
    path: "/:language/:country(..)/order/finished/:id?/:hash?/shipped",
    component: _3abd2dca,
    name: "order-finished-id-hash-shipped"
  }, {
    path: "/oauth/sign-in",
    component: _5cb1cc82,
    meta: {"withoutLocale":true},
    name: "o-auth-sign-in-callback"
  }, {
    path: "/oauth/sign-out",
    component: _f2ce677e,
    meta: {"withoutLocale":true},
    name: "o-auth-sign-out-callback"
  }, {
    path: "/:locale(..-..)/blog",
    component: _cb5efb62,
    name: "blog-overview-lmp"
  }, {
    path: "/:locale(..-..)/blog/:pageName",
    component: _4c5c8ab3,
    name: "blog-post-lmp"
  }, {
    path: "*",
    component: _2f093839,
    name: "error-404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
