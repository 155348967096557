import { Document } from '@contentful/rich-text-types'
import { DocumentModel } from '../base/Document'

export interface IInfoBlocks {
  title: string
  description: Document
}
export class InfoBlockModel {
  protected readonly title: string
  protected readonly description: Document

  constructor({ fields: { title, description } }) {
    this.title = title
    this.description = new DocumentModel(description).toJSON()
  }

  toJSON(): IInfoBlocks {
    return {
      title: this.title,
      description: this.description,
    }
  }
}
