import { Context, Middleware } from '@nuxt/types'

type Headers = {
  [name: string]: string
}

const setHeadersMiddleware: Middleware = (context: Context): void => {
  if (!process.server) return

  const headers = context.route.matched
    .reduce(
      (acc, { components }) =>
        [...acc, ...Object.values(components)] as {
          options?: { httpHeaders?: (context: Context) => Headers }
        }[],
      []
    )
    .reduce((acc, component) => {
      if (component.options && component.options.httpHeaders) {
        return { ...acc, ...component.options.httpHeaders(context) }
      }

      return acc
    }, {} as Headers)

  Object.entries(headers).forEach(([header, value]) =>
    context.res.setHeader(header, value)
  )
}

export default setHeadersMiddleware
