<template>
  <div class="h-full md:h-auto">
    <div class="items-center text-base">
      <div class="text-start">
        <img
          class="full h-16 mb-4 responsive-icon"
          src="@/assets/images/payment-error.svg"
        />
        <h2 class="mb-4 text-2lg font-bold leading-exceeding">{{ title }}</h2>
        <div class="mb-8">
          {{ description }}
        </div>
        <ui-button type="primary" class="w-full mb-4" @click="handleCtaClick">
          {{ buttonCtaText }}
        </ui-button>
        <div class="text-center">
          <ui-link
            v-if="!!linkCtaText"
            type="primary"
            :has-custom-behavior="true"
            @click="handleLinkClick"
          >
            {{ linkCtaText }}
          </ui-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GTMEvents } from '~/plugins/gtm/enums'
import { UiButton, UiLink } from '~/components/atoms'

export default {
  components: {
    UiButton,
    UiLink,
  },
  props: {
    title: {
      type: String,
      default: 'Error',
    },
    description: {
      type: String,
      default: 'An error has occurred',
    },
    linkCtaText: {
      type: String,
      default: 'More info',
    },
    buttonCtaText: {
      type: String,
      default: 'Change payment method',
    },
  },
  mounted() {
    this.$gtmEnhanced.trackCheckoutEvent(GTMEvents.PAYMENT_ERROR_INFORMATION)
  },
  methods: {
    handleCtaClick() {
      this.$emit('error-modal-box:click-cta')
    },
    handleLinkClick(event) {
      event.preventDefault()
      this.$emit('error-modal-box:click-link')
    },
  },
}
</script>
