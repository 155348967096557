export default {
  'about-us': 'https://company.recharge.com',
  'amazon-uk': 'amazon',
  'amazon-uk/10-gbp': 'amazon',
  'amazon-uk/15-gbp': 'amazon',
  'amazon-uk/25-gbp': 'amazon',
  'amazon-uk/50-gbp': 'amazon/50-gbp',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  'bitsa/100-eur': 'bitsa/100-eur',
  'bitsa/15-eur': 'bitsa/15-eur',
  'bitsa/25-eur': 'bitsa/25-eur',
  'bitsa/50-eur': 'bitsa/50-eur',
  blizzard: 'blizzard',
  'blizzard/50-eur': 'blizzard',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  'cookie-statement': 'cookie-statement',
  'crypto-voucher': 'crypto-voucher',
  'crypto-voucher/25-eur': 'crypto-voucher/25-eur',
  cryptocurrency: 'cryptocurrency',
  'ea-origin': 'ea-origin',
  'ea-origin/15-eur': 'ea-origin/15-eur',
  'ea-origin/30-eur': 'ea-origin/30-eur',
  ecovoucher: 'ecovoucher',
  'eir-mobile': 'eir-mobile',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  'eve-online': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  flexepin: 'flexepin',
  fortnite: 'gaming-cards',
  'free-fire': 'free-fire',
  'gaming-gift-cards': 'gaming-cards',
  'how-it-works': 'how-it-works',
  'karma-koin': 'karma-koin',
  'karma-koin/10-eur': 'karma-koin/10-eur',
  'karma-koin/50-eur': 'karma-koin/50-eur',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends/10-eur',
  'league-of-legends/20-eur': 'league-of-legends/20-eur',
  'mobile-top-up': 'mobile-top-up',
  moneyclic: 'prepaid-credit-cards',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin/10-eur',
  'ncoin/100-eur': 'ncoin/100-eur',
  'ncoin/50-eur': 'ncoin/50-eur',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'netflix/15-eur': 'netflix/15-eur',
  'netflix/25-eur': 'netflix/25-eur',
  'netflix/50-eur': 'netflix/50-eur',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop/15-eur',
  'nintendo-eshop/25-eur': 'nintendo-eshop/25-eur',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-months': 'nintendo-switch-online/12-months',
  'nintendo-switch-online/3-months': 'nintendo-switch-online/3-months',
  openbucks: 'openbucks',
  'payment-methods': 'payment-methods',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/12-months': 'playstation-plus/12-months-ie',
  'playstation-plus/3-months': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/20-eur': 'playstation-store',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'prepaid-money-cards': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'pubg-us': 'pubg',
  'red-dead-online': 'gaming-cards',
  'right-of-withdrawal': 'how-it-works',
  roblox: 'roblox',
  'roblox/50-eur-xbox-one': 'roblox',
  'roblox/6-eur-xbox-one': 'roblox',
  runescape: 'runescape',
  'shopping-gift-cards': 'shopping-gift-cards',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  steam: 'steam',
  'steam/10-eur': 'steam/10-eur',
  'steam/100-eur': 'steam/100-eur',
  'steam/20-eur': 'steam/20-eur',
  'steam/25-eur': 'steam/25-eur',
  'steam/5-eur': 'steam/5-eur',
  'steam/50-eur': 'steam/50-eur',
  three: 'three',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  twitch: 'twitch',
  vodafone: 'vodafone',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/': 'world-of-warcraft',
  'world-of-warcraft/60-days': 'world-of-warcraft/60-days',
  'world-of-warcraft/60-days/': 'world-of-warcraft/60-days',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card/10-eur': 'xbox-gift-card/10-eur',
  'xbox-gift-card/20-eur': 'xbox-gift-card/20-eur',
  'xbox-gift-card/25-eur': 'xbox-gift-card/25-eur',
  'xbox-gift-card/3-months': 'xbox-gift-card',
  'xbox-gift-card/30-eur': 'xbox-gift-card/30-eur',
  'xbox-gift-card/5-eur': 'xbox-gift-card/5-eur',
  'xbox-gift-card/50-eur': 'xbox-gift-card/50-eur',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/12-months': 'xbox-live-gold',
  astropay: 'astropay',
  'jeton-cash': 'jeton-cash',
  'blog/reduce-expenses-with-these-money-saving-tips-and-easy-budgeting':
    'https://www.recharge.com/blog/en-gb/ie/reduce-expenses-with-these-money-saving-tips-and-easy-budgeting',
  'blog/anonymous-prepaid-cards-beginners-guide': 'prepaid-credit-cards',
  'blog/most-widely-accepted-methods-on-sports-betting-websites':
    'prepaid-credit-cards',
  'blog/the-best-prepaid-cards-for-teens': 'prepaid-credit-cards',
  'blog/where-to-buy-prepaid-credit-card-no-extra-cost': 'prepaid-credit-cards',
}
