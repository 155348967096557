import { Context, Middleware } from '@nuxt/types'
import {
  getConsolidatedCookie,
  setConsolidatedCookie,
} from '~/services/ConsolidatedCookie'

const changeEmailFeatureGuard: Middleware = (context: Context): void => {
  if (!process.server) return

  const {
    app,
    route: { query, name },
    store,
  } = context
  if (!name) {
    return
  }

  const isChangeEmailFeaturePreview =
    query.change_email_preview === 'true' ||
    getConsolidatedCookie(app, 'feature_flags', 'change_email_preview')

  if (isChangeEmailFeaturePreview) {
    setConsolidatedCookie(
      app,
      'feature_flags',
      'change_email_preview',
      isChangeEmailFeaturePreview
    )
  }

  store.dispatch(
    'context/setIsChangeEmailFeaturePreview',
    isChangeEmailFeaturePreview
  )
}

export default changeEmailFeatureGuard
