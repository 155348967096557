import camelCase from '~/utils/camelCase'

export function formatFormFields(
  fields: Record<string, string | number>
): Record<string, string | number> {
  return Object.keys(fields).reduce(
    (acc, field) => ({
      ...acc,
      [camelCase(field)]: fields[field],
    }),
    {}
  )
}
