
import { Row, Column, Container } from '~/components/grid'
import { Alert, Icon } from '~/components/atoms'

export default {
  components: {
    Alert,
    Icon,
    Row,
    Container,
    Column,
  },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: 'certified',
    },
  },
}
