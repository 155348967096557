var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('transition',{attrs:{"name":_vm.transitionName}},[_c('div',{staticClass:"fixed inset-0 md:p-4 flex justify-center items-center overflow-hidden",class:[
      {
        'bg-overlay-extra': _vm.level === 1,
        'bg-overlay-extra-half': _vm.level > 1,
      },
      _vm.zIndexClass,
      _vm.paddingTopClass,
    ],on:{"click":_vm.onOverlayClick}},[_c('section',{ref:"section",staticClass:"relative max-h-full overflow-y-auto bg-white p-5 w-screen h-screen md:w-wide-modal md:h-auto rounded-t-lg md:rounded-lg",attrs:{"aria-describedby":_vm.desc,"aria-labelledby":_vm.title,"data-test":"modal","role":"dialog"}},[_c('button-icon',{staticClass:"absolute top-4 end-4",attrs:{"color":"black","data-test":"close-button","font-size":"xs","icon":"close"},on:{"click":_vm.onOverlayClose}}),_vm._v(" "),_c('div',{staticClass:"m-3 h-inner-height-ccl px-2 md:px-0"},[_vm._t("default")],2)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }