export function deleteObjectKey(
  object: Record<string, unknown>,
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> {
  const objectKeys = Object.keys(object)
  return objectKeys.reduce((accumulator, objectKey) => {
    if (objectKey !== key) {
      accumulator[objectKey] = object[objectKey]
    }

    return accumulator
  }, {})
}

export default { deleteObjectKey }
