import { EntryModel } from '../base/Entry'

export interface IH1Title {
  title: string
}

export class H1Title extends EntryModel {
  protected readonly title: string

  constructor(model) {
    super(model)
    const {
      fields: { title },
    } = model
    this.title = title
  }

  toJSON(): IH1Title {
    return {
      title: this.title,
    }
  }
}

export default H1Title
