
import props from './props'
import { UiInput } from '~/components/atoms'
import orderBy from '~/utils/orderBy'
import cloneDeep from '~/utils/cloneDeep'

export default {
  name: 'SearchList',
  components: {
    UiInput,
  },
  props,
  data() {
    return {
      eventTimeout: null,
      searchTerm: '',
      dropdownOpen: false,
    }
  },
  computed: {
    limitedItems() {
      return this.filteredItems.slice(0, this.max)
    },
    filteredItems() {
      if (this.searchTerm === '' || this.deactivateSearch)
        return this.orderedItems
      return this.orderedItems.filter(item =>
        this.searchFields.find(field =>
          item[field]
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              this.searchTerm
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036F]/g, '')
            )
        )
      )
    },
    orderedItems() {
      return orderBy(cloneDeep(this.items), ...this.orderingOptions)
    },
    noResults(): string {
      return this.$t(
        'account.edit_profile.mobile_number.country_code.no_results'
      )
    },
    extendedBoxClasses(): string {
      if (!this.dropdownResults) {
        return `rounded-lg ${this.boxClasses}`
      }
      return this.boxClasses
    },
  },
  mounted() {
    this.dropdownOpen = true
    this.$emit('search-list-opened')
  },
  destroyed() {
    this.$emit('search-list-closed')
  },
  methods: {
    onBlur(event, searchTerm): void {
      // Blur is triggered twice when enter is pressed
      if (!event) return
      this.$emit('blurSearchInput', searchTerm)
    },
    onSearch(): void {
      if (this.eventTimeout) {
        clearTimeout(this.eventTimeout)
      }
      this.eventTimeout = setTimeout(() => {
        this.$emit('search', this.searchTerm)
      }, 1000)
    },
    selectItem(item): void {
      this.$emit('search-list:selected', item)
    },
    closeDropdown(): void {
      this.$emit('close-dropdown')
    },
    clearInput(): void {
      if (!this.clearable) return
      this.searchTerm = ''
    },
  },
}
