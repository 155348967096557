import { UserAttributes } from './types'

export interface IUserState {
  user: UserAttributes | null
  rafEligible: boolean
}

export default (): IUserState => ({
  user: {
    id: '',
    username: '',
    email: '',
    firstName: '',
    familyName: '',
    address1: '',
    address2: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
    phoneNumber: '',
    birthdate: '',
    phoneNumberCountryCode: '',
    referredByCodeId: '',
    stateCode: '',
    state: '',
    totalReferrals: 0,
    identities: [],
  },
  rafEligible: false,
})
