import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { CategoryTypes } from '~/enums/categories'

export interface ICMSBrandInfo extends Entry {
  fields: {
    brandCategory?: CategoryTypes
  }
}

export interface IBrandInfo {
  brandCategory?: CategoryTypes
}

export class BrandInfoModel extends EntryModel {
  protected readonly brandCategory?: CategoryTypes
  constructor(model: ICMSBrandInfo) {
    super(model)
    const {
      fields: { brandCategory },
    } = this
    this.brandCategory = brandCategory
  }

  toJSON(): IBrandInfo {
    return {
      brandCategory: this.brandCategory,
    }
  }
}
