<script>
// based on https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
export default {
  name: `TransitionExpand`,
  functional: true,
  props: {
    multipleChildren: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, context) {
    /* eslint-disable no-param-reassign */
    const data = {
      props: {
        name: `expand`,
      },
      on: {
        afterEnter(element) {
          element.style.height = `auto`
        },
        enter(element) {
          const { width } = getComputedStyle(element)
          element.style.width = width
          element.style.position = `absolute`
          element.style.visibility = `hidden`
          element.style.height = `auto`
          const { height } = getComputedStyle(element)
          element.style.width = null
          element.style.position = null
          element.style.visibility = null
          element.style.height = 0
          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height
          setTimeout(() => {
            element.style.height = height
          })
        },
        leave(element) {
          const { height } = getComputedStyle(element)
          element.style.height = height
          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            element.style.height = 0
          })
        },
      },
    }
    return createElement(
      context.props.multipleChildren ? 'transition-group' : 'transition',
      data,
      context.children
    )
    /* eslint-enable no-param-reassign */
  },
}
</script>

<style scoped>
* {
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translateZ(0);
  will-change: height;
}
</style>

<style>
.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  transition: height 200ms ease-in-out;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
