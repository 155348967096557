<template>
  <div>
    <div class="w-full flex justify-center my-4 relative">
      <div
        v-if="inProgress"
        class="absolute w-full top-0 start-0 h-full flex items-center justify-center"
      >
        <img
          class="w-100 h-auto z-10 rounded-lg -mt-px"
          src="@/assets/images/product-overlay.png"
        />
      </div>
      <ui-image :src="logo" width="100" height="intrinsic" />
    </div>
    <div class="my-4 inline-block">
      <ui-title type="h4"> {{ title }}</ui-title>
      <rich-text-renderer v-if="description" :document="description" />
    </div>
    <div>
      <div class="my-4">
        <p class="text-xs text-gray-dark">{{ $t('general.status') }}</p>
        <p class="text-warning">{{ $t('general.order_status.pending') }}</p>
      </div>
      <div class="my-4">
        <p class="text-xs text-gray-dark">{{ $t('general.order_number') }}</p>
        <p data-test="order-number">{{ orderNumber }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { UiTitle, UiImage } from '~/components/atoms'
import RichTextRenderer from '~/components/renderers/rich-text-renderer'

export default {
  components: {
    RichTextRenderer,
    UiTitle,
    UiImage,
  },
  props: {
    logo: VueTypes.object.def({}),
    title: VueTypes.string.def(''),
    description: VueTypes.object,
    orderNumber: VueTypes.string.def(''),
    status: VueTypes.string.def(''),
    inProgress: VueTypes.bool.def(false),
  },
}
</script>
