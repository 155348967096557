import { NuxtAppOptions } from '@nuxt/types'

export type cookieValue = string | boolean | number | null

export function getConsolidatedCookie(
  context: NuxtAppOptions,
  cookieName: string,
  key: string
): cookieValue {
  const consolidated = context.$cookies.get(cookieName)

  if (!consolidated) {
    return null
  }

  // eslint-disable-next-line no-prototype-builtins
  return consolidated.hasOwnProperty(key) ? consolidated[key] : null
}

export function setConsolidatedCookie(
  context: NuxtAppOptions,
  cookieName: string,
  key: string,
  value: cookieValue
): void {
  let consolidated = context.$cookies.get(cookieName)

  if (consolidated) {
    consolidated = { ...consolidated, [key]: value }
    context.$cookies.set(cookieName, consolidated)
  } else {
    context.$cookies.set(cookieName, { [key]: value })
  }
}
