export enum REDEEM_TYPE {
  RTR = 'rtr',
  PIN = 'pin',
}

export enum PRODUCT_KIND {
  CALL_CREDIT = 'call_credit',
  DATA = 'data',
  BUNDLE = 'bundle',
  CHAT = 'chat',
  SPECIAL = 'special',
  INTERNATIONAL = 'international',
  GSM = 'gsm',
  CDMA = 'cdma',
  UNLIMITED = 'unlimited',
}
