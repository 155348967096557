import { IImageModel } from '../base/Image'
import { TBrandCategory } from '../base/Category'
import { InfoBlockModel, IInfoBlocks } from '../components/InfoBlock'
import { IUsps } from './Usps'
import { ProductModel, IProduct, ICMSProductModel } from './Product'
import { EntryModel } from '~/models/base/Entry'

export interface IProductInfoModel {
  fields: {
    title: string
    logo: IImageModel
    productId: number
  }
}
export interface IProductBanner {
  productImage?: IImageModel
  description?: Record<string, unknown>
  parentBrandInfo?: IInfoBlocks[]
  showTrustpilot?: boolean
  title?: string
  productId?: number
  productInfo?: IProduct
  brandTitle?: string
  // TODO: those are required:
  brandCategory?: TBrandCategory
  maximumQuantity?: number
  isCertifiedReseller?: boolean
}

export class ProductBannerModel extends EntryModel {
  protected readonly title?: string
  protected readonly productId?: number
  protected readonly description?: Record<string, unknown>
  protected productImage?: IImageModel
  protected readonly usps?: IUsps
  protected readonly showBreadcrumbs?: boolean
  protected readonly showTrustpilot?: boolean
  protected readonly productInfo?: IProduct
  protected readonly productPageInfo?: IProduct
  protected readonly brandTitle?: string
  protected readonly brandCategory?: TBrandCategory
  protected readonly maximumQuantity?: number
  protected readonly parentBrandInfo?: IInfoBlocks[]
  protected readonly isCertifiedReseller?: boolean

  constructor(productBannerModel, productInfo: ICMSProductModel) {
    super(productBannerModel)
    const { fields } = productBannerModel
    this.productInfo = new ProductModel(productInfo).toJSON()

    const {
      logo,
      productId,
      brandTitle,
      brandCategory,
      title: productTitle,
      maximumQuantity,
      isCertifiedReseller,
    } = this.productInfo

    const { showTrustpilot, description, parentBrandInfo } = fields
    if (parentBrandInfo) {
      this.parentBrandInfo = parentBrandInfo.map(brandFields => {
        return new InfoBlockModel(brandFields).toJSON()
      })
    }
    this.isCertifiedReseller = isCertifiedReseller
    this.productImage = logo
    this.title = productTitle
    this.productId = productId
    this.description = description
    this.brandTitle = brandTitle
    this.brandCategory = brandCategory
    this.showTrustpilot = showTrustpilot
    this.maximumQuantity = maximumQuantity
  }

  toJSON(): IProductBanner {
    return {
      title: this.title,
      productId: this.productId,
      parentBrandInfo: this.parentBrandInfo,
      maximumQuantity: this.maximumQuantity,
      description: this.description,
      productImage: this.productImage,
      showTrustpilot: this.showTrustpilot,
      productInfo: this.productInfo,
      brandTitle: this.brandTitle,
      brandCategory: this.brandCategory,
      isCertifiedReseller: this.isCertifiedReseller,
    }
  }
}
