import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from './Entry'

export type TPageType =
  | 'home'
  | 'category'
  | 'brand'
  | 'theme'
  | 'product'
  | 'legal'
  | 'secondary_page'
  | 'blog'
  | 'faq'

export interface IPage {
  slug: string
  title: string
  pageType: TPageType
}

export interface ICMSPageModel extends Entry {
  fields: {
    slug: string
    title: string
    pageType: TPageType
  }
}

export interface PageData {
  sectionBrandList: Record<string, unknown>[]
  sectionCategoryBanner: Record<string, unknown>
  sectionFreeForm: Record<string, unknown>[]
  sectionUspsBlock: Record<string, unknown>
}

export class PageModel extends EntryModel {
  protected readonly slug: string
  protected readonly title: string
  protected readonly pageType: TPageType

  constructor(pageModel: ICMSPageModel) {
    super(pageModel)
    const {
      fields: { title },
    } = pageModel

    this.slug = this.requiredField('slug')
    this.title = title
    this.pageType = this.requiredField('pageType')
  }

  toJSON(): IPage {
    return {
      slug: this.slug,
      title: this.title,
      pageType: this.pageType,
    }
  }
}
