<template>
  <picture :class="classes">
    <source
      v-if="webpImageDesktop"
      :media="`(min-width:${breakpoints.LG}px)`"
      :srcset="`${webpImageDesktop}, ${webpImageDesktopRetina} 2x`"
      type="image/webp"
    />
    <source
      v-if="desktopImage"
      :media="`(min-width:${breakpoints.LG}px)`"
      :srcset="`${desktopImage}, ${desktopRetinaImage} 2x`"
    />
    <source :media="`(min-width:${breakpoints.MD}px)`" :srcset="desktopImage" />
    <source
      v-if="webpImageMobile"
      :media="`(max-width:${breakpoints.MD}px)`"
      :srcset="webpImageMobile"
      type="image/webp"
    />
    <img
      :src="mobileImage"
      :alt="alt"
      v-bind="$attrs"
      :class="[classes, blurClasses]"
      @load="onImageLoad"
    />
  </picture>
</template>

<script>
import VueTypes from 'vue-types'
import get from '~/utils/get'
import { Breakpoints } from '~/enums'

export default {
  props: {
    classes: VueTypes.oneOfType([String, Array]).def(''),
    alt: VueTypes.string.def(''),
    mobile: VueTypes.object.def({}),
    desktop: VueTypes.object.def({}),
    fit: VueTypes.string.def(''),
  },
  data() {
    return {
      breakpoints: Breakpoints,
      mobileImage: '',
      desktopImage: '',
      desktopRetinaImage: '',
      webpImageDesktop: '',
      webpImageDesktopRetina: '',
      webpImageMobile: '',
      blurClasses: 'responsive-image-loading',
    }
  },
  mounted() {
    if (!process.client) return

    const viewportWidth = window.innerWidth
    const { mobile, desktop } = this

    this.$nextTick(() => {
      this.mobileImage = this.getFormattedImage(mobile, viewportWidth)
      this.desktopImage = this.getFormattedImage(desktop, viewportWidth)
      this.desktopRetinaImage = this.getFormattedImage(
        desktop,
        Math.round(viewportWidth * 1.4)
      )

      this.webpImageDesktop = this.getFormattedWebpImage(desktop, viewportWidth)
      this.webpImageDesktopRetina = this.getFormattedWebpImage(
        desktop,
        Math.round(viewportWidth * 1.8)
      )
      this.webpImageMobile = this.getFormattedWebpImage(mobile, viewportWidth)
    })
  },
  methods: {
    onImageLoad() {
      this.blurClasses = 'responsive-image-loaded'
    },
    getFormattedImage(src, newWidth = 200) {
      const { fit } = this
      const withFit = fit ? `&fit=${fit}` : ''
      const url = get(src, 'file.url', '')
      const originalWidth = get(src, 'file.details.image.width', 1)
      const originalHeight = get(src, 'file.details.image.height', 1)

      if (newWidth > originalWidth) return `${url}${withFit}`

      const aspectRatio = Math.round(originalWidth / originalHeight)
      const newHeight = Math.round(newWidth / aspectRatio)

      return `${url}&w=${newWidth}&h=${newHeight}${withFit}`
    },
    getFormattedWebpImage(src, newWidth) {
      const url = this.getFormattedImage(src, newWidth)
      if (url.includes('fm=jpg'))
        return url.replace('fm=jpg&fl=progressive', 'fm=webp')
      if (url.includes('fm=png'))
        return url.replace('fm=png&fl=png8', 'fm=webp')

      return undefined
    },
  },
}
</script>

<style scoped>
.responsive-image-loading {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.responsive-image-loaded {
  animation-name: fadein;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  @keyframes fadein {
    0% {
      -webkit-filter: blur(5px);
      filter: blur(5px);
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
    }
  }
}
</style>
