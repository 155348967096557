import { Maybe } from '~/apis/clients/graphql/types/contentful'
export type Collection = {
  items: any[]
}

export const collectionToArray = <T extends Collection>(
  collection: T
): T['items'] => {
  if (!collection) return []
  const { items } = collection
  return items
}

export const firstOfCollection = <T extends Collection>(
  collection: T
): T['items'][number] => collectionToArray(collection)[0]

export const collectionHasItems = <T extends Maybe<Collection>>(
  collection: T
): boolean => collection && collection.items && collection.items.length > 0

export const itemsByNameOfCollection = <T extends Collection>(
  collection: T,
  name: string
): T['items'][number] | undefined => {
  const arr = collectionToArray(collection)
  if (arr.length === 0) return undefined
  return arr.filter(item => {
    return item.__typename === name
  })
}
