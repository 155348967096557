import { Plugin } from '@nuxt/types'

const globalFormatting = (recommerceLocale, number) => {
  const locale = recommerceLocale.replace('_', '-')
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
  }).format(number)
}

const numberFormatterPlugin: Plugin = ({ store }, inject) => {
  const formatNumber = (number: number): string => {
    return globalFormatting(store.getters['context/recommerceLocale'], number)
  }
  inject('numberFormatter', formatNumber)
}

export default numberFormatterPlugin
