import { GTMEvents } from '../enums'
import { Impression, IImpression } from './Impression'
export interface IImpressionsEcommerce {
  currencyCode: string
  impressions: IImpression[]
}
export interface IProductImpressions {
  event: GTMEvents
  ecommerce: IImpressionsEcommerce
}

export class ProductImpressions {
  private readonly impressions: IImpression[]
  private ecommerce: IImpressionsEcommerce

  constructor({ products, currencyCode }) {
    const impressions = products.map((product, index) =>
      new Impression(product, index).toJSON()
    )

    this.impressions = impressions
    this.ecommerce = {
      currencyCode,
      impressions,
    }
  }

  toJSON(): IProductImpressions {
    return {
      event: GTMEvents.PRODUCT_IMRESSIONS,
      ecommerce: this.ecommerce,
    }
  }
}
