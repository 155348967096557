import { INLINES } from '@contentful/rich-text-types'
import get from '~/utils/get'
import UiLink from '~/components/atoms/link'
import { isExternalLink, isModalLink } from '~/services/url'
const { EMBEDDED_ENTRY, ASSET_HYPERLINK, ENTRY_HYPERLINK, HYPERLINK } = INLINES

const defaultInline = (type, _, key, h) => {
  return h(
    'span',
    {
      key,
      style: {
        margin: '0px 5px',
        padding: '0 .25rem 0 .75rem',
        border: '1px solid #d3dce0',
        fontFamily: 'monospace',
      },
    },
    `
       This type: ${type} is not supported currently
      `
  )
}

export default {
  [EMBEDDED_ENTRY]: (node, key, h) =>
    defaultInline(EMBEDDED_ENTRY, node, key, h),
  [ASSET_HYPERLINK]: (node, key, h) => {
    return defaultInline(ASSET_HYPERLINK, node, key, h)
  },

  [ENTRY_HYPERLINK]: ({ content, data }, key, h, next, links) => {
    const hyperlinks = get(links, 'entries.hyperlink')
    const id = get(data, 'target.sys.id')
    const target = (hyperlinks || []).find(link => link.sys.id === id)
    const path = get(target, 'path')
    const pathArray = path ? path.split('/').slice(2) : []
    return h(
      UiLink,
      {
        props: {
          url: `/${pathArray.join('/')}`,
        },
      },
      next(content, key, h, next)
    )
  },
  [HYPERLINK]: ({ content, data: { uri } }, key, h, next) => {
    if (isModalLink(uri)) {
      return h(
        UiLink,
        {
          props: {
            url: uri,
          },
        },
        next(content, key, h, next)
      )
    }
    const target = isExternalLink(uri) ? '_blank' : '_self'
    return h(
      'a',
      {
        key,
        attrs: {
          href: uri,
          target,
        },
      },
      next(content, key, h, next)
    )
  },
}
