import Cookies, { CookieSetOptions } from 'universal-cookie'
import addDays from 'date-fns/addDays'
import { castBoolStringToBool } from '@/utils/castBoolString'
export interface ICognitoStorage {
  setItem(key: string, value: string): void
  getItem(key: string): string | null
  removeItem(key: string): void
  clear(): void
  containsAuthItems(): boolean
  setRememberMe(rememberMe: boolean): void
  removeRememberMeCookie(): void
}

const underscore = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase()

const getCookieName = (key: string) => underscore(key.split('.').reverse()[0])

export const ACCESS_TOKEN_NAME = 'access_token' as const
export const REFRESH_TOKEN_NAME = 'refresh_token' as const
export const ID_TOKEN_NAME = 'id_token' as const
export const LAST_AUTH_USER_NAME = 'last_auth_user' as const
export const USER_DATA_NAME = 'user_data' as const
export const CLOCK_DRIFT_NAME = 'clock_drift' as const

const REMEMBER_ME_COOKIE = 'remember-me'

const AMPLIFY_COOKIES = [
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME,
  ID_TOKEN_NAME,
  LAST_AUTH_USER_NAME,
  USER_DATA_NAME,
  CLOCK_DRIFT_NAME,
] as const

const EXPIRES_IN_DAYS = 30

const PATH = '/'

const getCookieSetOptions = (expires?: Date) => {
  const cookieSetOptions: CookieSetOptions = {
    path: PATH,
    secure: true,
  }

  if (expires) {
    cookieSetOptions.expires = expires
  }

  return cookieSetOptions
}

const createCookieStorage = (cookies: Cookies): ICognitoStorage => {
  const rememberMeCookieValue = cookies.get(REMEMBER_ME_COOKIE)

  const rememberMe =
    rememberMeCookieValue === undefined
      ? false
      : castBoolStringToBool(rememberMeCookieValue)

  if (rememberMeCookieValue !== undefined) {
    cookies.set(
      REMEMBER_ME_COOKIE,
      rememberMe,
      getCookieSetOptions(addDays(new Date(), EXPIRES_IN_DAYS))
    )
  }

  return class CookieStorage {
    static rememberMe = rememberMe

    static setItem(key: string, value: string): void {
      const name = getCookieName(key)

      if (!(AMPLIFY_COOKIES as ReadonlyArray<string>).includes(name)) return

      const expires = CookieStorage.rememberMe
        ? addDays(new Date(), EXPIRES_IN_DAYS)
        : undefined

      const cookieSetOptions = getCookieSetOptions(expires)

      cookies.set(name, value, cookieSetOptions)
    }

    static getItem(key: string): string {
      const name = getCookieName(key)
      return cookies.get(name, {
        doNotParse: true,
      })
    }

    static removeItem(key: string): void {
      const name = getCookieName(key)
      cookies.remove(name, {
        path: PATH,
      })
    }

    static clear(): void {
      AMPLIFY_COOKIES.forEach(name => cookies.remove(name))
    }

    static containsAuthItems(): boolean {
      return [ACCESS_TOKEN_NAME, ID_TOKEN_NAME, REFRESH_TOKEN_NAME].some(
        cookieName => !!this.getItem(cookieName)
      )
    }

    static setRememberMe(rememberMe) {
      CookieStorage.rememberMe = rememberMe
      cookies.set(
        REMEMBER_ME_COOKIE,
        rememberMe,
        getCookieSetOptions(addDays(new Date(), EXPIRES_IN_DAYS))
      )
    }

    static removeRememberMeCookie() {
      cookies.remove(REMEMBER_ME_COOKIE, {
        path: PATH,
      })
    }
  }
}

export default createCookieStorage
