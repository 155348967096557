export default {
  logo: {
    file: {
      url:
        '//images.ctfassets.net/9jtl1uxg7ihi/J4CBb9yfbnPFKFloU13Zy/114477bfdccb49b342d0143440779913/image.png?fm=png',
      details: { size: 5353, image: { width: 252, height: 206 } },
      fileName: 'image.png',
      contentType: 'image/png',
    },
    title: 'image',
  },
  title: 'CDON NO 196.96 NOK',
  banner: {
    file: {
      url:
        '//images.ctfassets.net/9jtl1uxg7ihi/5mg1ASaM0P5pf0SPvqERp0/cfa247624f094b00dbc139d5d1eaff98/image.png?fm=png',
      details: { size: 334259, image: { width: 939, height: 299 } },
      fileName: 'image.png',
      contentType: 'image/png',
    },
    title: 'image',
  },
  productId: 73202,
  brandTitle: 'CDON',
  brandCategory: 'shopping_giftcards',
}
