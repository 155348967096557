<template>
  <container class="mb-8">
    <row v-if="title" wrap>
      <column class="w-full">
        <main-title type="secondary" has-prefix-icon>
          {{ title }}
        </main-title>
      </column>
    </row>
    <row wrap>
      <column
        v-for="card in cards"
        :key="card.title"
        class="w-full md:w-1/2 lg:w-1/3 px-0"
      >
        <featured-card v-bind="card" />
      </column>
    </row>
  </container>
</template>

<script>
import VueTypes from 'vue-types'

import { FeaturedCard, MainTitle } from '~/components/molecules'
import { Column, Row, Container } from '~/components/grid'

export default {
  components: {
    FeaturedCard,
    Column,
    Row,
    Container,
    MainTitle,
  },
  props: {
    title: VueTypes.string,
    cards: VueTypes.arrayOf(
      VueTypes.shape({
        image: VueTypes.shape({
          file: VueTypes.shape({ url: VueTypes.string }).loose,
        }).loose,
        title: VueTypes.string.def(''),
        buttonText: VueTypes.string.def(''),
        brandImg: VueTypes.shape({
          file: VueTypes.shape({ url: VueTypes.string }).loose,
        }).loose,
        brand: VueTypes.shape({
          slug: VueTypes.string,
        }).loose,
      })
    ),
  },
}
</script>
