import { helpers } from '@contentful/rich-text-types'

class NodeRenderer {
  renderNode(node, key, { node: nodeRenderer, createElement, mark, links }) {
    const isTextNode = helpers.isText(node)
    const { nodeType } = node
    if (isTextNode) {
      const markerRender = mark
      return nodeRenderer.text(node, key, createElement, markerRender)
    }
    const nextNode = nodes =>
      this.renderNodeList(nodes, key, {
        node: nodeRenderer,
        createElement,
        mark,
        links,
      })
    if (!nodeRenderer) {
      return createElement('div', `${key} ; lost nodeRenderer`)
    }
    if (!nodeType || !nodeRenderer[nodeType]) {
      return `(Unrecognized node type) ${nodeType || 'empty'}`
    }
    return nodeRenderer[nodeType](node, key, createElement, nextNode, links)
  }

  renderNodeList(nodes, key, renderer) {
    return nodes.map((node, index) =>
      this.renderNode(node, `${key}-${index}`, renderer)
    )
  }
}

const nodeRenderer = new NodeRenderer()
export default nodeRenderer
