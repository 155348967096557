export default Object.freeze({
  en: {
    '??': {
      '/claro-costa-rica': '/en/costa-rica/claro-top-up',
      '/claro-dominican-republic': '/en/dominican-republic/claro-top-up',
      '/claro-guatemala': '/en/guatemala/claro-top-up',
      '/claro-honduras': '/en/honduras/claro-top-up',
      '/cosmote-romania': '/en/romania/cosmote-top-up',
      '/comcel-colombia': '/en/colombia/comcel-top-up',
      '/cyfrowy-polsat-poland': '/en/poland/cyfrowy-polsat-top-up',
      '/digicel-curacao': '/en/curacao/digicel-top-up',
      '/digicel-guyana': '/en/guyana/digicel-top-up',
      '/digicel-grenada': '/en/grenada/digicel-top-up',
      '/digicel-haiti': '/en/haiti/digicel-top-up',
      '/digicel-jamaica': '/en/jamaica/digicel-top-up',
      '/digicel-saint-vincent-and-the-grenadines':
        '/en/saint-vincent-the-grenadines/digicel-top-up',
      '/evn-telecom-vietnam': '/en/vietnam/evn-telecom-top-up',
      '/expresso-telecom-senegal': '/en/senegal/expresso-telecom-top-up',
      '/full-movil-costa-rica': '/en/costa-rica/full-movil-top-up',
      '/gt-mobile-spain': '/en/spain/gt-mobile-top-up',
      '/gtt-guyana': '/en/guyana/gt-t-top-up',
      '/hutchison-three-indonesia': '/en/indonesia/hutchison-three-top-up',
      '/kolbi-costa-rica': '/en/costa-rica/kolbi-top-up',
      '/flow-anguilla': '/en/anguilla/flow-top-up',
      '/flow-barbados': '/en/barbados/flow-top-up',
      '/beeline-uzbekistan': '/en/uzbekistan/beeline-top-up',
      '/movistar-uruguay': '/en/uruguay/movistar-top-up',
      '/glo-benin': '/en/benin/glo-top-up',
      '/digicel-french-guiana': '/en/french-guiana/digicel-top-up',
      '/orange-uganda': '/en/uganda/orange-top-up',
      '/t-mobile-united-kingdom': '/en/united-kingdom/t-mobile-top-up',
      '/sfr-france': '/en/france/sfr-top-up',
      '/bob-austria': '/en/austria/bob-top-up',
      '/b-free-austria': '/en/austria/b-free-top-up',
      '/tele-dot-ring-austria': '/en/austria/telering-top-up',
      '/lycamobile-austria': '/en/austria/lycamobile-top-up',
      '/nimm3-austria': '/en/austria/nimm3-top-up',
      '/3reload-austria': '/en/austria/3reload-top-up',
      '/vectone-austria': '/en/austria/vectone-top-up',
      '/eety-austria': '/en/austria/eety-top-up',
      '/ee-united-kingdom': '/en/united-kingdom/ee-top-up',
      '/cricket-paygo-united-states': '/en/united-states/cricket-paygo-refill',
      '/boost-united-states': '/en/united-states/boost-refill',
      '/du-more-united-arab-emirates':
        '/en/united-arab-emirates/du-more-top-up',
      '/kcell-kazakhstan': '/en/kazakhstan/kcell-top-up',
      '/altel-kazakhstan': '/en/kazakhstan/altel-top-up',
      '/activ-kazakhstan': '/en/kazakhstan/activ-top-up',
      '/beeline-kazakhstan': '/en/kazakhstan/beeline-top-up',
      '/tele2-kazakhstan': '/en/kazakhstan/tele2-top-up',
      '/airtel-zambia': '/en/zambia/airtel-top-up',
      '/airtel-tanzania-united-republic-of': '/en/tanzania/airtel-top-up',
      '/megafon-center-south-russian-federation':
        '/en/russia/megafon-center-south-top-up',
      '/claro-paraguay': '/en/paraguay/claro-top-up',
      '/ooredoo-myanmar': '/en/myanmar/ooredoo-top-up',
      '/mec-myanmar': '/en/myanmar/mec-top-up',
      '/airtel-malawi': '/en/malawi/airtel-top-up',
      '/aircel-uttar-pradesh-west-india':
        '/en/india/aircel-uttar-pradesh-west-top-up',
      '/virgin-mobile-mexico': '/en/mexico/virgin-mobile-top-up',
      '/maz-mexico': '/en/mexico/maz-top-up',
      '/cierto-mexico': '/en/mexico/cierto-top-up',
      '/alo-mexico': '/en/mexico/alo-top-up',
      '/touch-lebanon': '/en/lebanon/touch-top-up',
      '/alfa-lebanon': '/en/lebanon/alfa-top-up',
      '/aircel-kerala-india': '/en/india/aircel-kerala-top-up',
      '/tuenti-ecuador': '/en/ecuador/tuenti-top-up',
      '/smart-cambodia': '/en/cambodia/smart-top-up',
      '/zain-bahrain': '/en/bahrain/zain-top-up',
      '/viva-bahrain': '/en/bahrain/viva-top-up',
      '/freedom-mobile-canada': '/en/canada/freedom-mobile-top-up',
      '/virgin-canada': '/en/canada/virgin-mobile-top-up',
      '/telus-canada': '/en/canada/telus-top-up',
      '/solo-canada': '/en/canada/solo-top-up',
      '/rogers-canada': '/en/canada/rogers-top-up',
      '/publicmobile-canada': '/en/canada/public-mobile-top-up',
      '/mts-canada': '/en/canada/mts-top-up',
      '/koodo-canada': '/en/canada/koodo-top-up',
      '/fido-canada': '/en/canada/fido-top-up',
      '/chatr-canada': '/en/canada/chatr-top-up',
      '/bell-canada': '/en/canada/bell-top-up',
      '/zain-saudi-arabia': '/en/saudi-arabia/zain-mobile-top-up',
      '/virgin-saudi-arabia': '/en/saudi-arabia/virgin-mobile-top-up',
      '/stc-saudi-arabia': '/en/saudi-arabia/stc-mobile-top-up',
      '/mobily-saudi-arabia': '/en/saudi-arabia/mobily-top-up',
      '/lebara-saudi-arabia': '/en/saudi-arabia/lebara-top-up',
      '/friendi-saudi-arabia': '/en/saudi-arabia/friendi-mobile-top-up',
      '/allo-saudi-arabia': '/en/saudi-arabia/allo-top-up',
      '/virgin-mobile-south-africa': '/en/south-africa/virgin-mobile-top-up',
      '/vodafone-qatar': '/en/qatar/vodafone-top-up',
      '/ooredoo-qatar': '/en/qatar/ooredoo-top-up',
      '/at-and-t-puerto-rico': '/en/puerto-rico/at-t-top-up',
      '/renna-mobile-oman': '/en/oman/renna-mobile-top-up',
      '/omantel-oman': '/en/oman/omantel-top-up',
      '/friendi-mobile-oman': '/en/oman/friendi-mobile-top-up',
      '/movitel-mozambique': '/en/zambia/movitel-top-up',
      '/yallo-switzerland': '/en/switzerland/yallo-top-up',
      '/swisscom-switzerland': '/en/moldova/swisscom-top-up',
      '/sunrise-switzerland': '/en/switzerland/sunrise-top-up',
      '/salt-mobile-switzerland': '/en/switzerland/salt-mobile-top-up',
      '/ortel-mobile-switzerland': '/en/switzerland/ortel-mobile-top-up',
      '/mucho-mobile-switzerland': '/en/switzerland/mucho-mobile-top-up',
      '/lycamobile-switzerland': '/en/switzerland/lycamobile-top-up',
      '/lebara-switzerland': '/en/switzerland/lebara-top-up',
      '/tatem-congo': '/en/congo/tatem-top-up',
      '/tracfone-united-states': '/en/united-states/tracfone-refill',
      '/telcel-america-united-states':
        '/en/united-states/telcel-america-refill',
      '/sti-mobile-united-states': '/en/united-states/sti-mobile-refill',
      '/real-mobile-united-states': '/en/united-states/real-mobile-refill',
      '/ready-mobile-united-states': '/en/united-states/ready-mobile-refill',
      '/page-plus-united-states': '/en/united-states/page-plus-refill',
      '/good2go-united-states': '/en/united-states/good2go-refill',
      '/cintex-wireless-united-states':
        '/en/united-states/cintex-wireless-refill',
      '/red-pocket-united-states': '/en/united-states/red-pocket-refill',
      '/urcall-mobile-united-states': '/en/united-states/urcall-mobile-refill',
      '/life-wireless-united-states': '/en/united-states/life-wireless-refill',
      '/airvoice-united-states': '/en/united-states/airvoice-refill',
      '/access-wireless-united-states':
        '/en/united-states/access-wireless-refill',
      '/etisalat-united-arab-emirates':
        '/en/united-arab-emirates/etisalat-top-up',
      '/smart-cell-nepal': '/en/nepal/smart-cell-top-up',
      '/tn-mobile-namibia': '/en/namibia/tn-mobile-top-up',
      '/mtc-namibia': '/en/namibia/mtc-top-up',
      '/orange-botswana': '/en/botswana/orange-top-up',
      '/mascom-botswana': '/en/botswana/mascom-top-up',
      '/bemobile-botswana': '/en/botswana/bemobile-top-up',
      '/batelco-bahrain': '/en/bahrain/batelco-top-up',
      '/cvmovel-cape-verde': '/en/cape-verde/cvmovel-top-up',
      '/three-united-kingdom': '/en/united-kingdom/three-top-up',
      '/asda-mobile-united-kingdom': '/en/united-kingdom/asda-mobile-top-up',
      '/giff-gaff-united-kingdom': '/en/united-kingdom/giff-gaff-top-up',
      '/lycamobile-united-kingdom': '/en/united-kingdom/lycamobile-top-up',
      '/talk-home-mobile-united-kingdom':
        '/en/united-kingdom/talk-home-mobile-top-up',
      '/o2-united-kingdom': '/en/united-kingdom/o2-top-up',
      '/vodafone-united-kingdom': '/en/united-kingdom/vodafone-top-up',
      '/virgin-mobile-united-kingdom':
        '/en/united-kingdom/virgin-mobile-top-up',
      '/you-mobile-spain': '/en/spain/you-mobile-top-up',
      '/republica-movile-spain': '/en/spain/republica-movile-top-up',
      '/lcr-movile-spain': '/en/spain/lcr-movile-top-up',
      '/hablapp-spain': '/en/spain/hablapp-top-up',
      '/wind-italy': '/en/italy/wind-top-up',
      '/vodafone-italy': '/en/italy/vodafone-top-up',
      '/tim-italy': '/en/italy/tim-top-up',
      '/3-tre-italy': '/en/italy/3-tre-top-up',
      '/bluesky-samoa': '/en/american-samoa/bluesky-top-up',
      '/telenor-serbia': '/en/serbia/telenor-top-up',
      '/orange-burkina-faso': '/en/burkina-faso/orange-top-up',
      '/delight-austria': '/en/austria/delight-top-up',
      '/t-mobile-austria': '/en/austria/t-mobile-top-up',
      '/m-tel-austria': '/en/austria/m-tel-aufladen',
      '/optus-australia': '/en/australia/optus-top-up',
      '/orange-liberia': '/en/liberia/orange-liberia',
      '/smile-uganda': '/en/uganda/smile-top-up',
      '/vodafone-india': '/en/india/vodafone-top-up',
      '/talkout-spain': '/en/spain/talkout-top-up',
      '/clm-spain': '/en/spain/clm-top-up',
      '/uganda-telecom-uganda': '/en/uganda/uganda-telecom-top-up',
      '/mundimovil-spain': '/en/spain/mundimovil-top-up',
      '/yu-kenya': '/en/kenya/yu-top-up',
      '/jazzcard-movil-spain': '/en/spain/jazzcard-movil-top-up',
      '/smart-tanzania-united-republic-of': '/en/tanzania/smart-top-up',
      '/moov-gabon': '/en/gabon/moov-top-up',
      '/best-movil-spain': '/en/spain/best-movil-top-up',
      '/movistar-mexico': '/en/mexico/movistar-top-up',
      '/lyca-international-united-states':
        '/en/united-states/lyca-international-refill',
      '/lycamobile-unlimited-international-united-states':
        '/en/united-states/lycamobile-unlimited-international-refill',
      '/symacom-france': '/en/france/symacom-top-up',
      '/la-poste-mobile-france': '/en/france/la-poste-mobile-top-up',
      '/lebara-united-kingdom': '/en/united-kingdom/lebara-top-up',
      '/tesco-mobile-united-kingdom': '/en/united-kingdom/tesco-mobile-top-up',
      '/nrj-france': '/en/france/nrj-top-up',
      '/metropcs-united-states': '/en/united-states/metropcs-refill',
      '/virgin-mobile-united-states': '/en/united-states/virgin-mobile-top-up',
      '/lycamobile-portugal': '/en/portugal/lycamobile-top-up',
      '/vectone-mobile-portugal': '/en/portugal/vectone-mobile-top-up',
      '/vodafone-portugal': '/en/portugal/vodafone-top-up',
      '/uzo-portugal': '/en/portugal/uzo-top-up',
      '/nos-portugal': '/en/portugal/nos-top-up',
      '/moche-portugal': '/en/portugal/moche-top-up',
      '/meo-portugal': '/en/portugal/meo-top-up',
      '/ttcl-tanzania-united-republic-of': '/en/tanzania/ttcl-top-up',
      '/halotel-tanzania-united-republic-of': '/en/tanzania/halotel-top-up',
      '/reliance-jio-india': '/en/india/reliance-jio-top-up',
      '/etl-lao-peoples-democratic-republic': '/en/laos/etl-top-up',
      '/worldmap': '/en/countries',
    },
    us: {
      '/recharge-movistar-mexico': '/en/mexico/movistar-top-up',
      '/airtel-online-recharge': '/en/india/airtel-top-up',
      '/bsnl-online-recharge': '/en/india/bsnl-top-up',
    },
    sn: {
      '/idea-online-recharge': '/en/india/idea-top-up',
      '/recharge-orange-senegal': '/en/senegal/orange-top-up',
    },
    lk: {
      '/recharge-etisalat-sri-lanka': '/en/sri-lanka/etisalat-top-up',
      '/airtel-online-recharge': '/en/india/airtel-top-up',
      '/recharging-hutch-in-sri-lanka': '/en/sri-lanka/hutch-top-up',
      '/recharging-airtel-in-sri-lanka': '/en/sri-lanka/airtel-top-up',
    },
    ni: {
      '/natcom-top-up-haiti': '/en/haiti/natcom-top-up',
    },
    ke: {
      '/top-up-safaricom': '/en/kenya/safaricom-top-up',
      '/topping-up-airtel-kenya': '/en/kenya/airtel-top-up',
    },
    gh: {
      '/mtn-recharge': '/en/ghana/mtn-top-up',
      '/top-up-tigo-ghana': '/en/ghana/airteltigo-top-up',
    },
    np: {
      '/airtel-online-recharge': '/en/bangladesh/airtel-top-up',
      '/bsnl-online-recharge': '/en/india/bsnl-top-up',
    },
    br: {
      '/recharge-claro-brazil': '/en/brazil/claro-top-up',
    },
    my: {
      '/recharge-telkomsel-kartu-as': '/en/indonesia/telkomsel-top-up',
    },
    co: {
      '/claro-recharge-colombia': '/en/colombia/claro-top-up',
    },
    ma: {
      '/recharge-meditel': '/en/morocco/orange-top-up',
    },
    mx: {
      '/recharge-nextel-mobile': '/en/mexico/nextel-top-up',
    },
    de: {
      '/airtel-online-recharge': '/en/india/airtel-top-up',
    },
    in: {
      '/bsnl-online-recharge': '/en/india/bsnl-top-up',
    },
    au: {
      '/bsnl-online-recharge': '/en/india/bsnl-top-up',
    },
    gb: {
      '/bsnl-online-recharge': '/en/india/bsnl-top-up',
    },
    no: {
      '/guild-wars': '/en-no/gaming-gift-cards',
    },
    se: {
      '/guild-wars': '/en-se/gaming-gift-cards',
    },
    pt: {
      '/guild-wars': '/en-pt/gaming-gift-cards',
    },
    pl: {
      '/guild-wars': '/en-pl/gaming-gift-cards',
    },
    be: {
      '/guild-wars': '/en-be/gaming-gift-cards',
    },
    it: {
      '/guild-wars': '/en-it/gaming-gift-cards',
    },
    ie: {
      '/guild-wars': '/en-ie/gaming-gift-cards',
    },
    es: {
      '/guild-wars': '/en-es/gaming-gift-cards',
    },
  },
  nl: {
    '??': {
      '/celcom-malaysia': '/nl/maleisie/celcom-opwaarderen',
      '/orange-madagascar': '/nl/madagascar/orange-opwaarderen',
      '/wataniya-state-of-palestine': '/nl/palestina/wataniya-opwaarderen',
      '/dollarphone-united-states':
        '/nl/verenigde-staten/dollarphone-opwaarderen',
      '/flow-virgin-islands-british':
        '/nl/britse-maagdeneilanden/flow-opwaarderen',
      '/moov-niger': '/nl/niger/moov-opwaarderen',
      '/digicel-nauru': '/nl/nauru/digicel-opwaarderen',
      '/expo-mobile-united-states':
        '/nl/verenigde-staten/expo-mobile-opwaarderen',
      '/aircel-tamil-nadu-india': '/nl/india/aircel-tamil-nadu-opwaarderen',
      '/claro-puerto-rico': '/nl/puerto-rico/claro-opwaarderen',
      '/warid-pakistan': '/nl/pakistan/warid-opwaarderen',
      '/singtel-singapore': '/nl/singapore/singtel-opwaarderen',
      '/vodafone-gujarat-india': '/nl/india/vodafone-gujarat-opwaarderen',
      '/une-colombia': '/nl/colombia/une-opwaarderen',
      '/econet-burundi': '/nl/burundi/econet-opwaarderen',
      '/airtel-india': '/nl/australie/airtel-opwaarderen',
      '/ooredoo-tunisia': '/nl/tunesie/ooredoo-opwaarderen',
      '/vodafone-spain': '/nl/spanje/vodafone-opwaarderen',
      '/banglalink-bangladesh': '/nl/bangladesh/banglalink-opwaarderen',
      '/videocon-india': '/nl/india/videocon-opwaarderen',
      '/movistar-nicaragua': '/nl/nicaragua/movistar-opwaarderen',
      '/tigo-bolivia': '/nl/bolivia/tigo-opwaarderen',
      '/play-poland': '/nl/polen/play-opwaarderen',
      '/entel-bolivia': '/nl/bolivia/entel-opwaarderen',
      '/lebara-spain': '/nl/spanje/lebara-opwaarderen',
      '/smartfren-indonesia': '/nl/indonesie/smartfren-opwaarderen',
      '/xl-indonesia': '/nl/indonesie/xl-opwaarderen',
      '/viettel-vietnam': '/nl/vietnam/viettel-opwaarderen',
      '/tunisie-telecom-tunisia': '/nl/tunesie/tunisie-telecom-opwaarderen',
      '/mtn-nigeria': '/nl/nigeria/mtn-opwaarderen',
      '/mtnl-india': '/nl/india/mtnl-opwaarderen',
      '/tuenti-spain': '/nl/spanje/tuenti-opwaarderen',
      '/movistar-peru': '/nl/peru/movistar-opwaarderen',
      '/cnt-ecuador': '/nl/ecuador/cnt-opwaarderen',
      '/robi-axiata-bangladesh': '/nl/bangladesh/robi-axiata-opwaarderen',
      '/etisalat-afghanistan': '/nl/afghanistan/etisalat-opwaarderen',
      '/turkcell-turkey': '/nl/turkije/turkcell-opwaarderen',
      '/orange-mali': '/nl/mali/orange-opwaarderen',
      '/utel-ukraine': '/nl/oekraïne/utel-opwaarderen',
      '/movistar-el-salvador': '/nl/el salvador/movistar-opwaarderen',
      '/ooredoo-algeria': '/nl/algerije/ooredoo-opwaarderen',
      '/megafone-tajikistan': '/nl/tadzjikistan/megafone-opwaarderen',
      '/aircel-hp-india': '/nl/india/aircel-hp-opwaarderen',
      '/telenor-india': '/nl/india/telenor-opwaarderen',
      '/ais-thailand': '/nl/thailand/ais-opwaarderen',
      '/vodafone-turkey': '/nl/turkije/vodafone-opwaarderen',
      '/aircel-chennai-india': '/nl/india/aircel-chennai-opwaarderen',
      '/beeline-tajikistan': '/nl/tadzjikistan/beeline-opwaarderen',
      '/utl-mobile-nepal': '/nl/nepal/utl-mobile-opwaarderen',
      '/virgin-mobile-colombia': '/nl/colombia/virgin-mobile-opwaarderen',
      '/sinpin-united-states': '/nl/verenigde-staten/sinpin-opwaarderen',
      '/carrefour-spain': '/nl/spanje/carrefour-opwaarderen',
      '/aircel-assam-india': '/nl/india/aircel-assam-opwaarderen',
      '/vodafone-uttar-pradesh-west-india':
        '/nl/india/vodafone-uttar-pradesh-west-opwaarderen',
      '/mtn-yemen': '/nl/jemen/mtn-opwaarderen',
      '/aircel-bihar-india': '/nl/india/aircel-bihar-opwaarderen',
      '/digicel-virgin-islands-british':
        '/nl/britse-maagdeneilanden/digicel-opwaarderen',
      '/mts-india': '/nl/india/mts-opwaarderen',
      '/tigo-colombia': '/nl/colombia/tigo-opwaarderen',
      '/orange-romania': '/nl/roemenie/orange-opwaarderen',
      '/axis-indonesia': '/nl/indonesie/axis-opwaarderen',
      '/nextel-brazil': '/nl/brazilie/nextel-opwaarderen',
      '/nationlink-somalia': '/nl/mali/nationlink-opwaarderen',
      '/lifecell-ukraine': '/nl/oekraïne/lifecell-opwaarderen',
      '/idea-india': '/nl/australie/idea-opwaarderen',
      '/mtn-rwanda': '/nl/rwanda/mtn-opwaarderen',
      '/ultra-mobile-united-states':
        '/nl/verenigde-staten/ultra-mobile-opwaarderen',
      '/cable-and-wireless-panama': '/nl/panama/cable-and-wireless-opwaarderen',
      '/oi-brazil': '/nl/brazilie/oi-opwaarderen',
      '/orange-congo': '/nl/congo/orange-opwaarderen',
      '/digicel-cayman-islands': '/nl/kaaiman-eilanden/digicel-opwaarderen',
      '/happy-movil-spain': '/nl/spanje/happy-movil-opwaarderen',
      '/unicom-china': '/nl/china/unicom-opwaarderen',
      '/telenor-myanmar': '/nl/myanmar/telenor-opwaarderen',
      '/tata-docomo-india': '/nl/india/tata-docomo-opwaarderen',
      '/zong-pakistan': '/nl/pakistan/zong-opwaarderen',
      '/digicel-samoa': '/nl/samoa/digicel-opwaarderen',
      '/vodafone-kerala-india': '/nl/india/vodafone-kerala-opwaarderen',
      '/claro-peru': '/nl/peru/claro-opwaarderen',
      '/cubacel-cuba': '/nl/cuba/cubacel-opwaarderen',
      '/etb-colombia': '/nl/colombia/etb-opwaarderen',
      '/mtn-congo': '/nl/congo/mtn-opwaarderen',
      '/aircel-rajasthan-india': '/nl/india/aircel-rajasthan-opwaarderen',
      '/digicel-antigua-and-barbuda': '/nl/antigua-barbuda/digicel-opwaarderen',
      '/natcom-haiti': '/nl/haïti/natcom-opwaarderen',
      '/smarts-yaroslavl-russian-federation':
        '/nl/rusland/smarts-yaroslavl-opwaarderen',
      '/claro-chile': '/nl/chili/claro-opwaarderen',
      '/aircel-mumbai-india': '/nl/india/aircel-mumbai-opwaarderen',
      '/tata-indicom-india': '/nl/india/tata-indicom-opwaarderen',
      '/vox-paraguay': '/nl/paraguay/vox-opwaarderen',
      '/vodafone-kolkata-india': '/nl/india/vodafone-kolkata-opwaarderen',
      '/geocell-georgia': '/nl/georgie/geocell-opwaarderen',
      '/telekom-mobile-romania': '/nl/roemenie/telekom-mobile-opwaarderen',
      '/inwi-morocco': '/nl/marokko/inwi-opwaarderen',
      '/indosat-mentari-indonesia': '/nl/indonesie/indosat-mentari-opwaarderen',
      '/avea-turkey': '/nl/turkije/avea-opwaarderen',
      '/africell-gambia': '/nl/gambia/africell-opwaarderen',
      '/nextel-chile': '/nl/chili/nextel-opwaarderen',
      '/orange-senegal': '/nl/senegal/orange-opwaarderen',
      '/djezzy-algeria': '/nl/algerije/djezzy-opwaarderen',
      '/aircel-andhra-pradesh-india':
        '/nl/india/aircel-andhra-pradesh-opwaarderen',
      '/claro-uruguay': '/nl/uruguay/claro-opwaarderen',
      '/digicel-tonga': '/nl/tonga/digicel-opwaarderen',
      '/orange-poland': '/nl/polen/orange-opwaarderen',
      '/telenor-pakistan': '/nl/pakistan/telenor-opwaarderen',
      '/lailai-georgia': '/nl/georgie/lailai-opwaarderen',
      '/jazz-pakistan': '/nl/pakistan/jazz-opwaarderen',
      '/vodacom-mozambique': '/nl/mozambique/vodacom-opwaarderen',
      '/llamaya-spain': '/nl/spanje/llamaya-opwaarderen',
      '/mtn-cameroon': '/nl/kameroen/mtn-opwaarderen',
      '/orange-niger': '/nl/nigeria/orange-opwaarderen',
      '/masmovil-spain': '/nl/spanje/masmovil-opwaarderen',
      '/mobilis-algeria': '/nl/algerije/mobilis-opwaarderen',
      '/mtn-swaziland': '/nl/swaziland/mtn-opwaarderen',
      '/intertelecom-ukraine': '/nl/oekraïne/intertelecom-opwaarderen',
      '/bouygues-france': '/nl/frankrijk/bouygues-opwaarderen',
      '/movistar-ecuador': '/nl/ecuador/movistar-opwaarderen',
      '/dialog-sri-lanka': '/nl/sri-lanka/dialog-opwaarderen',
      '/mobifone-vietnam': '/nl/vietnam/mobifone-opwaarderen',
      '/megafon-far-east-russian-federation':
        '/nl/rusland/megafon-far-east-opwaarderen',
      '/telkomsel-kartu-as-indonesia': '/nl/indonesie/telkomsel-opwaarderen',
      '/mts-russian-federation': '/nl/rusland/mts-opwaarderen',
      '/digicel-fiji': '/nl/fiji/digicel-opwaarderen',
      '/ufone-pakistan': '/nl/pakistan/ufone-opwaarderen',
      '/airtel-ghana': '/nl/ghana/airteltigo-opwaarderen',
      '/personal-argentina': '/nl/argentinie/personal-opwaarderen',
      '/hong-da-mobile-spain': '/nl/spanje/hong-da-mobile-opwaarderen',
      '/beeline-georgia': '/nl/georgie/beeline-opwaarderen',
      '/vodafone-rajasthan-india': '/nl/india/vodafone-rajasthan-opwaarderen',
      '/digicel-turks-and-caicos-islands':
        '/nl/turks- en-caicoseilanden/digicel-opwaarderen',
      '/ethiotelecom-ethiopia': '/nl/ethiopie/ethiotelecom-opwaarderen',
      '/asia-cell-telecom-iraq': '/nl/irak/asia-cell-telecom-opwaarderen',
      '/telkomsel-simpati-indonesia': '/nl/indonesie/telkomsel-opwaarderen',
      '/telecel-zimbabwe': '/nl/zimbabwe/telecel-opwaarderen',
      '/hablafacil-spain': '/nl/spanje/hablafacil-opwaarderen',
      '/bmobile-trinidad-and-tobago': '/nl/trinidad-tobago/bmobile-opwaarderen',
      '/ucom-armenia': '/nl/armenie/ucom-opwaarderen',
      '/myphone-georgia': '/nl/georgie/myphone-opwaarderen',
      '/personal-paraguay': '/nl/paraguay/personal-opwaarderen',
      '/vodafone-maharashtra-india':
        '/nl/india/vodafone-maharashtra-opwaarderen',
      '/rostelecom-mrf-ural-russian-federation':
        '/nl/rusland/rostelecom-mrf-ural-opwaarderen',
      '/mtn-guinea': '/nl/guinea/mtn-opwaarderen',
      '/moldcell-moldova-republic-of': '/nl/moldavie/moldcell-opwaarderen',
      '/telecom-china': '/nl/china/telecom-opwaarderen',
      '/lycamobile-france': '/nl/frankrijk/lyca-opwaarderen',
      '/grameenphone-bangladesh': '/nl/bangladesh/grameenphone-opwaarderen',
      '/digicel-el-salvador': '/nl/el salvador/digicel-opwaarderen',
      '/vietnammobile-vietnam': '/nl/vietnam/vietnammobile-opwaarderen',
      '/bani-georgia': '/nl/georgie/bani-opwaarderen',
      '/digimobil-spain': '/nl/spanje/digimobil-opwaarderen',
      '/falabella-chile': '/nl/chili/falabella-opwaarderen',
      '/tuyo-movil-costa-rica': '/nl/costa-rica/tuyo-movil-opwaarderen',
      '/tigo-el-salvador': '/nl/el salvador/tigo-opwaarderen',
      '/heyah-poland': '/nl/polen/heyah-opwaarderen',
      '/hits-mobile-spain': '/nl/spanje/hits-mobile-opwaarderen',
      '/megafon-caucasus-russian-federation':
        '/nl/rusland/megafon-caucasus-opwaarderen',
      '/true-move-thailand': '/nl/thailand/true-move-opwaarderen',
      '/tigo-paraguay': '/nl/tigo-opwaarderen',
      '/orange-egypt': '/nl/egypte/orange-opwaarderen',
      '/digicel-vanuatu': '/nl/vanuatu/digicel-opwaarderen',
      '/magti-gsm-georgia': '/nl/georgie/magti-gsm-opwaarderen',
      '/etisalat-egypt': '/nl/egypte/etisalat-opwaarderen',
      '/mtn-liberia': '/nl/liberia/mtn-opwaarderen',
      '/vodafone-romania': '/nl/roemenie/vodafone-opwaarderen',
      '/safaricom-kenya': '/nl/kenia/safaricom-opwaarderen',
      '/vodafone-fiji': '/nl/fiji/vodafone-opwaarderen',
      '/digicel-barbados': '/nl/barbados/digicel-opwaarderen',
      '/zamtel-zambia': '/nl/zambia/zamtel-opwaarderen',
      '/vodacom-tanzania-united-republic-of':
        '/nl/tanzania/vodacom-opwaarderen',
      '/orange-guinea-bissau': '/nl/guinea-bissau/orange-opwaarderen',
      '/telsur-chile': '/nl/chili/telsur-opwaarderen',
      '/airtel-nigeria': '/nl/nigeria/airtel-opwaarderen',
      '/gmobile-vietnam': '/nl/vietnam/gmobile-opwaarderen',
      '/africell-sierra-leone': '/nl/sierra-leone/africell-opwaarderen',
      '/mtn-south-africa': '/nl/zuid-afrika/mtn-opwaarderen',
      '/lyca-mobile-united-states':
        '/nl/verenigde-staten/lyca-mobile-opwaarderen',
      '/gphone-georgia': '/nl/georgie/gphone-opwaarderen',
      '/ntc-gsm-nepal': '/nl/nepal/ntc-gsm-opwaarderen',
      '/tcell-tajikistan': '/nl/tadzjikistan/tcell-opwaarderen',
      '/claro-argentina': '/nl/argentinie/claro-opwaarderen',
      '/beeline-kyrgyzstan': '/nl/kirgizie/beeline-opwaarderen',
      '/umniah-jordan': '/nl/jordanie/umniah-opwaarderen',
      '/jawwal-state-of-palestine': '/nl/palestina/jawwal-opwaarderen',
      '/smart-burundi': '/nl/burundi/smart-opwaarderen',
      '/africel-uganda': '/nl/oeganda/africel-opwaarderen',
      '/digicel-saint-kitts-and-nevis':
        '/nl/saint-kitts-nevis/digicel-opwaarderen',
      '/hutch-sri-lanka': '/nl/sri-lanka/hutch-opwaarderen',
      '/tele2-russian-federation': '/nl/rusland/tele2-opwaarderen',
      '/mts-ukraine': '/nl/oekraïne/mts-opwaarderen',
      '/digicel-dominica': '/nl/dominica/digicel-opwaarderen',
      '/kb-impuls-beeline-russian-federation':
        '/nl/rusland/kb-impuls-beeline-opwaarderen',
      '/babilonmobile-tajikistan': '/nl/tadzjikistan/babilonmobile-opwaarderen',
      '/btc-bahamas': '/nl/bahamas/btc-opwaarderen',
      '/lebara-france': '/nl/frankrijk/lebara-opwaarderen',
      '/vodacom-south-africa': '/nl/zuid-afrika/vodacom-opwaarderen',
      '/euskaltel-spain': '/nl/spanje/euskaltel-opwaarderen',
      '/vodafone-haryana-india': '/nl/india/vodafone-haryana-opwaarderen',
      '/glo-ghana': '/nl/ghana/glo-opwaarderen',
      '/ncell-nepal': '/nl/nepal/ncell-opwaarderen',
      '/zain-kuwait': '/nl/koeweit/zain-opwaarderen',
      '/mobile-china': '/nl/china/china-mobile-opwaarderen',
      '/megafon-ural-russian-federation':
        '/nl/rusland/megafon-ural-opwaarderen',
      '/roshan-afghanistan': '/nl/afghanistan/roshan-opwaarderen',
      '/blau-spain': '/nl/spanje/blau-opwaarderen',
      '/tigo-ghana': '/nl/ghana/airteltigo-opwaarderen',
      '/aircel-punjab-india': '/nl/india/aircel-punjab-opwaarderen',
      '/fonex-kyrgyzstan': '/nl/kirgizie/fonex-opwaarderen',
      '/unefon-mexico': '/nl/mexico/unefon-opwaarderen',
      '/megafon-center-russian-federation':
        '/nl/rusland/megafon-center-opwaarderen',
      '/orange-moldova-republic-of': '/nl/moldavie/orange-opwaarderen',
      '/aircel-west-bengal-india': '/nl/india/aircel-west-bengal-opwaarderen',
      '/at-and-t-united-states': '/nl/verenigde-staten/at&t-opwaarderen',
      '/mtn-uganda': '/nl/oeganda/mtn-opwaarderen',
      '/airtel-bangladesh': '/nl/bangladesh/airtel-opwaarderen',
      '/maxis-malaysia': '/nl/maleisie/maxis-opwaarderen',
      '/megafon-povolzhye-russian-federation':
        '/nl/rusland/megafon-povolzhye-opwaarderen',
      '/lyca-mobile-spain': '/nl/spanje/lyca-mobile-opwaarderen',
      '/mtn-benin': '/nl/benin/mtn-opwaarderen',
      '/etisalat-nigeria': '/nl/nigeria/etisalat-opwaarderen',
      '/vodafone-delhi-india': '/nl/india/vodafone-delhi-opwaarderen',
      '/digicel-panama': '/nl/panama/digicel-opwaarderen',
      '/vodafone-chennai-india': '/nl/india/vodafone-chennai-opwaarderen',
      '/umobile-malaysia': '/nl/maleisie/umobile-opwaarderen',
      '/pureminutes-united-states':
        '/nl/verenigde-staten/pureminutes-opwaarderen',
      '/motiv-russian-federation': '/nl/rusland/motiv-opwaarderen',
      '/moov-central-african-republic':
        '/nl/centraal-afrikaans-republiek/moov-opwaarderen',
      '/expresso-ghana': '/nl/ghana/expresso-opwaarderen',
      '/vodafone-tamil-nadu-india': '/nl/india/vodafone-tamil-nadu-opwaarderen',
      '/mtn-cyprus': '/nl/cyprus/mtn-opwaarderen',
      '/vodafone-bangalore-india': '/nl/india/vodafone-bangalore-opwaarderen',
      '/tigo-senegal': '/nl/senegal/tigo-opwaarderen',
      '/vodafone-mumbai-india': '/nl/india/vodafone-mumbai-opwaarderen',
      '/digicel-anguilla': '/nl/anguilla/digicel-opwaarderen',
      '/tigo-tanzania-united-republic-of': '/nl/tanzania/tigo-opwaarderen',
      '/warid-uganda': '/nl/oeganda/warid-opwaarderen',
      '/digicel-aruba': '/nl/aruba/digicel-opwaarderen',
      '/claro-brazil': '/nl/brazilie/claro-opwaarderen',
      '/uff-movil-colombia': '/nl/colombia/uff-movil-opwaarderen',
      '/metfone-cambodia': '/nl/cambodja/metfone-opwaarderen',
      '/orange-spain': '/nl/spanje/orange-opwaarderen',
      '/megafon-moscow-russian-federation':
        '/nl/rusland/megafon-moscow-opwaarderen',
      '/aircel-karnataka-india': '/nl/india/aircel-karnataka-opwaarderen',
      '/mobitel-sri-lanka': '/nl/sri-lanka/mobitel-opwaarderen',
      '/ceria-indonesia': '/nl/indonesie/ceria-opwaarderen',
      '/virgin-mobile-chile': '/nl/chili/virgin-mobile-opwaarderen',
      '/vivo-brazil': '/nl/brazilie/vivo-opwaarderen',
      '/starhub-singapore': '/nl/singapore/starhub-opwaarderen',
      '/glo-nigeria': '/nl/nigeria/glo-opwaarderen',
      '/orange-tunisia': '/nl/tunesie/orange-opwaarderen',
      '/suntel-sri-lanka': '/nl/sri-lanka/suntel-opwaarderen',
      '/movistar-chile': '/nl/chili/movistar-opwaarderen',
      '/digicel-papua-new-guinea':
        '/nl/papoea-nieuw-guinea/digicel-opwaarderen',
      '/digicel-trinidad-and-tobago': '/nl/trinidad-tobago/digicel-opwaarderen',
      '/smart-uganda': '/nl/oeganda/smart-opwaarderen',
      '/tricom-dominican-republic':
        '/nl/dominicaanse-republiek/tricom-opwaarderen',
      '/aircel-north-east-india': '/nl/india/aircel-north-east-opwaarderen',
      '/onatel-burkina-faso': '/nl/burkina-faso/onatel-opwaarderen',
      '/globe-philippines': '/nl/filipijnen/globe-opwaarderen',
      '/vodafone-andhra-pradesh-india':
        '/nl/india/vodafone-andhra-pradesh-opwaarderen',
      '/orange-kenya': '/nl/kenia/orange-opwaarderen',
      '/tigo-rwanda': '/nl/rwanda/tigo-opwaarderen',
      '/vinaphone-vietnam': '/nl/vietnam/vinaphone-opwaarderen',
      '/vodafone-bengal-india': '/nl/india/vodafone-bengal-opwaarderen',
      '/moov-cote-divoire': '/nl/ivoorkust/moov-opwaarderen',
      '/mpt-myanmar': '/nl/myanmar/mpt-opwaarderen',
      '/vodafone-ghana': '/nl/ghana/vodafone-opwaarderen',
      '/orange-morocco': '/nl/marokko/meditel-opwaarderen',
      '/citycell-bangladesh': '/nl/bangladesh/citycell-opwaarderen',
      '/flow-turks-and-caicos-islands':
        '/nl/turks- en-caicoseilanden/flow-opwaarderen',
      '/maroc-telecom-morocco': '/nl/marokko/maroc-telecom-opwaarderen',
      '/s-fone-vietnam': '/nl/vietnam/s-fone-opwaarderen',
      '/digi-malaysia': '/nl/maleisie/digi-opwaarderen',
      '/tkmobile-tajikistan': '/nl/tadzjikistan/tkmobile-opwaarderen',
      '/nextel-argentina': '/nl/argentinie/nextel-opwaarderen',
      '/aircel-uttar-pradesh-east-india':
        '/nl/india/aircel-uttar-pradesh-east-opwaarderen',
      '/airtel-sri-lanka': '/nl/sri-lanka/airtel-opwaarderen',
      '/m1-singapore': '/nl/singapore/m1-opwaarderen',
      '/aircel-jk-india': '/nl/india/aircel-jk-opwaarderen',
      '/simple-mobile-united-states':
        '/nl/verenigde-staten/simple-mobile-opwaarderen',
      '/qcell-gambia': '/nl/gambia/qcell-opwaarderen',
      '/beeline-ukraine': '/nl/oekraïne/beeline-opwaarderen',
      '/dtac-thailand': '/nl/thailand/dtac-opwaarderen',
      '/digicel-suriname': '/nl/suriname/digicel-opwaarderen',
      '/cell-c-south-africa': '/nl/zuid-afrika/cell-c-opwaarderen',
      '/tak-tak-poland': '/nl/polen/tak-tak-opwaarderen',
      '/cellcom-guinea': '/nl/guinea/cellcom-opwaarderen',
      '/mobilcat-spain': '/nl/spanje/mobilcat-opwaarderen',
      '/indosat-im3-indonesia': '/nl/indonesie/indosat-im3-opwaarderen',
      '/aircel-kolkata-india': '/nl/india/aircel-kolkata-opwaarderen',
      '/sun-cellular-philippines': '/nl/filipijnen/sun-cellular-opwaarderen',
      '/o-kyrgyzstan': '/nl/kirgizie/o-opwaarderen',
      '/verizon-united-states': '/nl/verenigde-staten/verizon-opwaarderen',
      '/dth-tata-sky-india': '/nl/india/dth-tata-sky-opwaarderen',
      '/claro-nicaragua': '/nl/nicaragua/claro-opwaarderen',
      '/viva-kuwait': '/nl/koeweit/viva-opwaarderen',
      '/aircel-orissa-india': '/nl/india/aircel-orissa-opwaarderen',
      '/smarts-samara-russian-federation':
        '/nl/rusland/smarts-samara-opwaarderen',
      '/afghan-wireless-afghanistan':
        '/nl/afghanistan/afghan-wireless-opwaarderen',
      '/reliance-india': '/nl/india/reliance-opwaarderen',
      '/mtn-afghanistan': '/nl/afghanistan/mtn-opwaarderen',
      '/three-telecom-indonesia': '/nl/indonesie/three-telecom-opwaarderen',
      '/bluesky-american-samoa': '/nl/samoa/bluesky-opwaarderen',
      '/mtn-cote-divoire': '/nl/ivoorkust/mtn-opwaarderen',
      '/entel-chile': '/nl/chili/entel-opwaarderen',
      '/viva-dominican-republic': '/nl/dominicaanse-republiek/viva-opwaarderen',
      '/tunetalk-malaysia': '/nl/maleisie/tunetalk-opwaarderen',
      '/mtn-ghana': '/nl/ghana/mtn-opwaarderen',
      '/claro-panama': '/nl/panama/claro-opwaarderen',
      '/vivacell-mts-armenia': '/nl/armenie/vivacell-mts-opwaarderen',
      '/aircel-maharashtra-india': '/nl/india/aircel-maharashtra-opwaarderen',
      '/vodafone-egypt': '/nl/egypte/vodafone-opwaarderen',
      '/kyivstar-ukraine': '/nl/oekraïne/kyivstar-opwaarderen',
      '/flow-saint-vincent-and-the-grenadines':
        '/nl/saint-vincent-en-de-grenadines/flow-opwaarderen',
      '/vodafone-uttar-pradesh-east-india':
        '/nl/india/vodafone-uttar-pradesh-east-opwaarderen',
      '/digicel-saint-lucia': '/nl/saint-lucia/digicel-opwaarderen',
      '/digicel-martinique': '/nl/martinique/digicel-opwaarderen',
      '/yoigo-spain': '/nl/spanje/yoigo-opwaarderen',
      '/avantel-colombia': '/nl/colombia/avantel-opwaarderen',
      '/smarts-cheboksary-russian-federation':
        '/nl/rusland/smarts-cheboksary-opwaarderen',
      '/orange-france': '/nl/frankrijk/orange-opwaarderen',
      '/digicel-bermuda': '/nl/bermuda/digicel-opwaarderen',
      '/vodafone-albania': '/nl/albanie/vodafone-opwaarderen',
      '/megafon-north-west-russian-federation':
        '/nl/rusland/megafon-north-west-opwaarderen',
      '/claro-el-salvador': '/nl/el salvador/claro-opwaarderen',
      '/tim-brazil': '/nl/brazilie/tim-opwaarderen',
      '/t-mobile-united-states': '/nl/verenigde-staten/t-mobile-opwaarderen',
      '/megafon-siberia-russian-federation':
        '/nl/rusland/megafon-siberia-opwaarderen',
      '/megacom-kyrgyzstan': '/nl/kirgizie/megacom-opwaarderen',
      '/bsnl-india': '/nl/india/bsnl-opwaarderen',
      '/vtr-chile': '/nl/chili/vtr-opwaarderen',
      '/moov-benin': '/nl/benin/moov-opwaarderen',
      '/iusacell-mexico': '/nl/mexico/iusacell-opwaarderen',
      '/airtel-uganda': '/nl/oeganda/airtel-opwaarderen',
      '/polkomtel-plus-poland': '/nl/polen/polkomtel-plus-opwaarderen',
      '/econet-zimbabwe': '/nl/zimbabwe/econet-opwaarderen',
      '/claro-colombia': '/nl/colombia/claro-opwaarderen',
      '/viva-bolivia': '/nl/bolivia/viva-opwaarderen',
      '/etisalat-sri-lanka': '/nl/sri-lanka/etisalat-opwaarderen',
      '/telemedia-belize': '/nl/belize/telemedia-opwaarderen',
      '/camgsm-cambodia': '/nl/cambodja/camgsm-opwaarderen',
      '/aircel-delhi-india': '/nl/india/aircel-delhi-opwaarderen',
      '/orange-central-african-republic':
        '/nl/centraal-afrikaans-republiek/orange-opwaarderen',
      '/vodafone-punjab-india': '/nl/india/vodafone-punjab-opwaarderen',
      '/smarts-astrakhan-russian-federation':
        '/nl/rusland/smarts-astrakhan-opwaarderen',
      '/claro-ecuador': '/nl/ecuador/claro-opwaarderen',
      '/teletalk-bangladesh': '/nl/bangladesh/teletalk-opwaarderen',
      '/bayn-gsm-morocco': '/nl/marokko/bayn-gsm-opwaarderen',
      '/mtn-guinea-bissau': '/nl/guinea-bissau/mtn-opwaarderen',
      '/telcel-mexico': '/nl/mexico/telcel-opwaarderen',
      '/telkom-mobile-south-africa':
        '/nl/zuid-afrika/telkom-mobile-opwaarderen',
      '/aircel-india': '/nl/india/aircel-opwaarderen',
      '/airtel-rwanda': '/nl/rwanda/airtel-opwaarderen',
      '/airtel-kenya': '/nl/kenia/airtel-opwaarderen',
      '/alegro-ecuador': '/nl/ecuador/alegro-opwaarderen',
      '/beeline-lao-peoples-democratic-republic':
        '/nl/laos/beeline-opwaarderen',
      '/cadcomms-cambodia': '/nl/cambodja/cadcomms-opwaarderen',
      '/claro-costa-rica': '/nl/costa-rica/claro-opwaarderen',
      '/claro-dominican-republic':
        '/nl/dominicaanse-republiek/claro-opwaarderen',
      '/claro-guatemala': '/nl/guatemala/claro-opwaarderen',
      '/claro-honduras': '/nl/honduras/claro-opwaarderen',
      '/cosmote-romania': '/nl/roemenie/cosmote-opwaarderen',
      '/comcel-colombia': '/nl/colombia/comcel-opwaarderen',
      '/cyfrowy-polsat-poland': '/nl/polen/cyfrowy-polsat-opwaarderen',
      '/digicel-curacao': '/nl/curacao/digicel-opwaarderen',
      '/digicel-guyana': '/nl/guyana/digicel-opwaarderen',
      '/digicel-grenada': '/nl/grenada/digicel-opwaarderen',
      '/digicel-haiti': '/nl/haïti/digicel-opwaarderen',
      '/digicel-jamaica': '/nl/jamaica/digicel-opwaarderen',
      '/digicel-saint-vincent-and-the-grenadines':
        '/nl/saint-vincent-en-de-grenadines/digicel-opwaarderen',
      '/evn-telecom-vietnam': '/nl/vietnam/evn-telecom-opwaarderen',
      '/expresso-telecom-senegal': '/nl/senegal/expresso-telecom-opwaarderen',
      '/full-movil-costa-rica': '/nl/costa-rica/full-movil-opwaarderen',
      '/gt-mobile-spain': '/nl/spanje/gt-mobile-opwaarderen',
      '/gtt-guyana': '/nl/guyana/gtt-opwaarderen',
      '/hutchison-three-indonesia': '/nl/indonesie/hutchison-three-opwaarderen',
      '/kolbi-costa-rica': '/nl/costa-rica/kolbi-opwaarderen',
      '/flow-anguilla': '/nl/anguilla/flow-opwaarderen',
      '/flow-barbados': '/nl/barbados/flow-opwaarderen',
      '/flow-antigua-and-barbuda': '/nl/antigua-barbuda/flow-opwaarderen',
      '/flow-cayman-islands': '/nl/kaaiman-eilanden/flow-opwaarderen',
      '/flow-dominica': '/nl/dominica/flow-opwaarderen',
      '/flow-grenada': '/nl/grenada/flow-opwaarderen',
      '/flow-saint-kitts-and-nevis': '/nl/saint-kitts-nevis/flow-opwaarderen',
      '/flow-jamaica': '/nl/jamaica/flow-opwaarderen',
      '/flow-montserrat': '/nl/montserrat/flow-opwaarderen',
      '/flow-saint-lucia': '/nl/saint-lucia/flow-opwaarderen',
      '/movicel-angola': '/nl/angola/movicel-opwaarderen',
      '/moun-dominican-republic': '/nl/dominicaanse-republiek/moun-opwaarderen',
      '/movistar-argentina': '/nl/argentinie/movistar-opwaarderen',
      '/movistar-costa-rica': '/nl/costa-rica/movistar-opwaarderen',
      '/movistar-colombia': '/nl/colombia/movistar-opwaarderen',
      '/movistar-guatemala': '/nl/guatemala/movistar-opwaarderen',
      '/movistar-panama': '/nl/panama/movistar-opwaarderen',
      '/mts-armenia': '/nl/armenie/mts-opwaarderen',
      '/netone-zimbabwe': '/nl/zimbabwe/netone-opwaarderen',
      '/orange-dominican-republic':
        '/nl/dominicaanse-republiek/orange-opwaarderen',
      '/orange-cameroon': '/nl/kameroen/orange-opwaarderen',
      '/orange-guinea': '/nl/guinea/orange-opwaarderen',
      '/orange-cote-divoire': '/nl/ivoorkust/orange-opwaarderen',
      '/orange-congo-the-democratic-republic-of-the':
        '/nl/congo/orange-opwaarderen',
      '/telkom-kenya': '/nl/kenia/telkom-opwaarderen',
      '/tigo-guatemala': '/nl/guatemala/tigo-opwaarderen',
      '/tigo-honduras': '/nl/honduras/tigo-opwaarderen',
      '/t-mobile-poland': '/nl/polen/t-mobile-opwaarderen',
      '/togocel-togo': '/nl/togo/togocel-opwaarderen',
      '/uninor-india': '/nl/india/uninor-opwaarderen',
      '/brasil-telecom-brazil': '/nl/brazilie/brasil-telecom-opwaarderen',
      '/claro-jamaica': '/nl/jamaica/claro-opwaarderen',
      '/digicel-honduras': '/nl/honduras/digicel-opwaarderen',
      '/easy-go-united-states': '/nl/verenigde-staten/easy-go-opwaarderen',
      '/h2o-united-states': '/nl/verenigde-staten/h2o-opwaarderen',
      '/voila-haiti': '/nl/haïti/voila-opwaarderen',
      '/idirect-dial-united-states':
        '/nl/verenigde-staten/idirect-dial-opwaarderen',
      '/myphone2go-ild-united-states':
        '/nl/verenigde-staten/myphone2go-ild-opwaarderen',
      '/net-10-united-states': '/nl/verenigde-staten/net-10-opwaarderen',
      '/nextel-mexico': '/nl/mexico/nextel-opwaarderen',
      '/rok-mobile-united-states':
        '/nl/verenigde-staten/rok-mobile-opwaarderen',
      '/smart-philippines': '/nl/filipijnen/smart-opwaarderen',
      '/talk-talk-now-united-states':
        '/nl/verenigde-staten/talk-talk-now-opwaarderen',
      '/terracom-united-states': '/nl/verenigde-staten/terracom-opwaarderen',
      '/ucell-uzbekistan': '/nl/oezbekistan/ucell-opwaarderen',
      '/yourtel-united-states': '/nl/verenigde-staten/yourtel-opwaarderen',
      '/mtn-zambia': '/nl/zambia/mtn-opwaarderen',
      '/zantel-tanzania-united-republic-of': '/nl/tanzania/zantel-opwaarderen',
      '/vodafone-kktc-turkey': '/nl/turkije/vodafone-kktc-opwaarderen',
      '/univision-united-states': '/nl/verenigde-staten/univision-opwaarderen',
      '/gt-mobile-france': '/nl/frankrijk/gt-mobile-opwaarderen',
      '/bali-georgia': '/nl/georgie/bali-opwaarderen',
      '/onevip-macedonia': '/nl/macedonie/onevip-opwaarderen',
      '/tele2-lithuania': '/nl/litouwen/tele2-opwaarderen',
      '/life-belarus': '/nl/wit-rusland/life-opwaarderen',
      '/mts-belarus': '/nl/wit-rusland/mts-opwaarderen',
      '/airtel-madagascar': '/nl/madagascar/airtel-opwaarderen',
      '/smart-gold-philippines': '/nl/filipijnen/smart-opwaarderen',
      '/smart-bro-philippines': '/nl/filipijnen/smart-bro-opwaarderen',
      '/beeline-uzbekistan': '/nl/oezbekistan/beeline-opwaarderen',
      '/movistar-uruguay': '/nl/uruguay/movistar-opwaarderen',
      '/glo-benin': '/nl/benin/glo-opwaarderen',
      '/digicel-french-guiana': '/nl/frans-guyana/digicel-opwaarderen',
      '/orange-uganda': '/nl/oeganda/orange-opwaarderen',
      '/t-mobile-united-kingdom':
        '/nl/verenigd-koninkrijk/t-mobile-opwaarderen',
      '/sfr-france': '/nl/frankrijk/sfr-opwaarderen',
      '/bob-austria': '/nl/oostenrijk/bob-opwaarderen',
      '/b-free-austria': '/nl/oostenrijk/b-free-opwaarderen',
      '/tele-dot-ring-austria': '/nl/oostenrijk/telering-opwaarderen',
      '/lycamobile-austria': '/nl/oostenrijk/lycamobile-opwaarderen',
      '/nimm3-austria': '/nl/oostenrijk/nimm3-opwaarderen',
      '/3reload-austria': '/nl/oostenrijk/3reload-opwaarderen',
      '/vectone-austria': '/nl/oostenrijk/vectone-opwaarderen',
      '/eety-austria': '/nl/oostenrijk/eety-opwaarderen',
      '/ee-united-kingdom': '/nl/verenigd-koninkrijk/ee-opwaarderen',
      '/cricket-paygo-united-states':
        '/nl/verenigde-staten/cricket-paygo-opwaarderen',
      '/boost-united-states': '/nl/verenigde-staten/boost-opwaarderen',
      '/du-more-united-arab-emirates':
        '/nl/verenigde-arabische-emiraten/du-more-opwaarderen',
      '/kcell-kazakhstan': '/nl/kazachstan/kcell-opwaarderen',
      '/altel-kazakhstan': '/nl/kazachstan/altel-opwaarderen',
      '/activ-kazakhstan': '/nl/kazachstan/activ-opwaarderen',
      '/beeline-kazakhstan': '/nl/kazachstan/beeline-opwaarderen',
      '/tele2-kazakhstan': '/nl/kazachstan/tele2-opwaarderen',
      '/airtel-zambia': '/nl/zambia/airtel-opwaarderen',
      '/airtel-tanzania-united-republic-of': '/nl/tanzania/airtel-opwaarderen',
      '/megafon-center-south-russian-federation':
        '/nl/rusland/megafon-center-south-opwaarderen',
      '/claro-paraguay': '/nl/paraguay/claro-opwaarderen',
      '/ooredoo-myanmar': '/nl/myanmar/ooredoo-opwaarderen',
      '/mec-myanmar': '/nl/myanmar/mec-opwaarderen',
      '/airtel-malawi': '/nl/malawi/airtel-opwaarderen',
      '/aircel-uttar-pradesh-west-india':
        '/nl/india/aircel-uttar-pradesh-west-opwaarderen',
      '/virgin-mobile-mexico': '/nl/mexico/virgin-mobile-opwaarderen',
      '/maz-mexico': '/nl/mexico/maz-opwaarderen',
      '/cierto-mexico': '/nl/mexico/cierto-opwaarderen',
      '/alo-mexico': '/nl/mexico/alo-opwaarderen',
      '/touch-lebanon': '/nl/libanon/touch-opwaarderen',
      '/alfa-lebanon': '/nl/libanon/alfa-opwaarderen',
      '/aircel-kerala-india': '/nl/india/aircel-kerala-opwaarderen',
      '/tuenti-ecuador': '/nl/ecuador/tuenti-opwaarderen',
      '/smart-cambodia': '/nl/cambodja/smart-opwaarderen',
      '/zain-bahrain': '/nl/bahrein/zain-opwaarderen',
      '/viva-bahrain': '/nl/bahrein/viva-opwaarderen',
      '/freedom-mobile-canada': '/nl/canada/freedom-mobile-opwaarderen',
      '/virgin-canada': '/nl/canada/virgin-opwaarderen',
      '/telus-canada': '/nl/canada/telus-opwaarderen',
      '/solo-canada': '/nl/canada/solo-opwaarderen',
      '/rogers-canada': '/nl/canada/rogers-opwaarderen',
      '/publicmobile-canada': '/nl/canada/publicmobile-opwaarderen',
      '/mts-canada': '/nl/canada/mts-opwaarderen',
      '/koodo-canada': '/nl/canada/koodo-opwaarderen',
      '/fido-canada': '/nl/canada/fido-opwaarderen',
      '/chatr-canada': '/nl/canada/chatr-opwaarderen',
      '/bell-canada': '/nl/canada/bell-opwaarderen',
      '/zain-saudi-arabia': '/nl/saoedi-arabie/zain-opwaarderen',
      '/virgin-saudi-arabia': '/nl/saoedi-arabie/virgin-opwaarderen',
      '/stc-saudi-arabia': '/nl/saoedi-arabie/stc-opwaarderen',
      '/mobily-saudi-arabia': '/nl/saoedi-arabie/mobily-opwaarderen',
      '/lebara-saudi-arabia': '/nl/saoedi-arabie/lebara-opwaarderen',
      '/friendi-saudi-arabia': '/nl/saoedi-arabie/friendi-opwaarderen',
      '/allo-saudi-arabia': '/nl/saoedi-arabie/allo-opwaarderen',
      '/virgin-mobile-south-africa':
        '/nl/zuid-afrika/virgin-mobile-opwaarderen',
      '/vodafone-qatar': '/nl/qatar/vodafone-opwaarderen',
      '/ooredoo-qatar': '/nl/qatar/ooredoo-opwaarderen',
      '/at-and-t-puerto-rico': '/nl/puerto-rico/at-t-opwaarderen',
      '/renna-mobile-oman': '/nl/oman/renna-mobile-opwaarderen',
      '/omantel-oman': '/nl/oman/omantel-opwaarderen',
      '/friendi-mobile-oman': '/nl/oman/friendi-mobile-opwaarderen',
      '/movitel-mozambique': '/nl/zambia/movitel-opwaarderen',
      '/yallo-switzerland': '/nl/zwitserland/yallo-opwaarderen',
      '/swisscom-switzerland': '/nl/moldavie/swisscom-opwaarderen',
      '/sunrise-switzerland': '/nl/zwitserland/sunrise-opwaarderen',
      '/salt-mobile-switzerland': '/nl/zwitserland/salt-mobile-opwaarderen',
      '/ortel-mobile-switzerland': '/nl/zwitserland/ortel-mobile-opwaarderen',
      '/mucho-mobile-switzerland': '/nl/zwitserland/mucho-mobile-opwaarderen',
      '/lycamobile-switzerland': '/nl/zwitserland/lycamobile-opwaarderen',
      '/lebara-switzerland': '/nl/zwitserland/lebara-opwaarderen',
      '/tatem-congo': '/nl/congo/tatem-opwaarderen',
      '/tracfone-united-states': '/nl/verenigde-staten/tracfone-opwaarderen',
      '/telcel-america-united-states':
        '/nl/verenigde-staten/telcel-america-opwaarderen',
      '/sti-mobile-united-states':
        '/nl/verenigde-staten/sti-mobile-opwaarderen',
      '/real-mobile-united-states':
        '/nl/verenigde-staten/real-mobile-opwaarderen',
      '/ready-mobile-united-states':
        '/nl/verenigde-staten/ready-mobile-opwaarderen',
      '/page-plus-united-states': '/nl/verenigde-staten/page-plus-opwaarderen',
      '/good2go-united-states': '/nl/verenigde-staten/good2go-opwaarderen',
      '/cintex-wireless-united-states':
        '/nl/verenigde-staten/cintex-wireless-opwaarderen',
      '/red-pocket-united-states':
        '/nl/verenigde-staten/red-pocket-opwaarderen',
      '/urcall-mobile-united-states':
        '/nl/verenigde-staten/urcall-mobile-opwaarderen',
      '/life-wireless-united-states':
        '/nl/verenigde-staten/life-wireless-opwaarderen',
      '/airvoice-united-states': '/nl/verenigde-staten/airvoice-opwaarderen',
      '/access-wireless-united-states':
        '/nl/verenigde-staten/access-wireless-opwaarderen',
      '/etisalat-united-arab-emirates':
        '/nl/verenigde-arabische-emiraten/etisalat-opwaarderen',
      '/smart-cell-nepal': '/nl/nepal/smart-cell-opwaarderen',
      '/tn-mobile-namibia': '/nl/namibië/tn-mobile-opwaarderen',
      '/mtc-namibia': '/nl/namibië/mtc-opwaarderen',
      '/orange-botswana': '/nl/botswana/orange-opwaarderen',
      '/mascom-botswana': '/nl/botswana/mascom-opwaarderen',
      '/bemobile-botswana': '/nl/botswana/bemobile-opwaarderen',
      '/batelco-bahrain': '/nl/bahrein/batelco-opwaarderen',
      '/cvmovel-cape-verde': '/nl/kaapverdie/cvmovel-opwaarderen',
      '/three-united-kingdom': '/nl/verenigd-koninkrijk/three-opwaarderen',
      '/asda-mobile-united-kingdom':
        '/nl/verenigd-koninkrijk/asda-mobile-opwaarderen',
      '/giff-gaff-united-kingdom':
        '/nl/verenigd-koninkrijk/giff-gaff-opwaarderen',
      '/lycamobile-united-kingdom':
        '/nl/verenigd-koninkrijk/lycamobile-opwaarderen',
      '/talk-home-mobile-united-kingdom':
        '/nl/verenigd-koninkrijk/talk-home-mobile-opwaarderen',
      '/o2-united-kingdom': '/nl/verenigd-koninkrijk/o2-opwaarderen',
      '/vodafone-united-kingdom':
        '/nl/verenigd-koninkrijk/vodafone-opwaarderen',
      '/virgin-mobile-united-kingdom':
        '/nl/verenigd-koninkrijk/virgin-mobile-opwaarderen',
      '/you-mobile-spain': '/nl/spanje/you-mobile-opwaarderen',
      '/republica-movile-spain': '/nl/spanje/republica-movile-opwaarderen',
      '/lcr-movile-spain': '/nl/spanje/lcr-movile-opwaarderen',
      '/hablapp-spain': '/nl/spanje/hablapp-opwaarderen',
      '/wind-italy': '/nl/canada/wind-opwaarderen',
      '/vodafone-italy': '/nl/italie/vodafone-opwaarderen',
      '/tim-italy': '/nl/italie/tim-opwaarderen',
      '/3-tre-italy': '/nl/italie/3-tre-opwaarderen',
      '/bluesky-samoa': '/nl/canada/bluesky-opwaarderen',
      '/telenor-serbia': '/nl/servie/telenor-opwaarderen',
      '/orange-burkina-faso': '/nl/burkina-faso/orange-opwaarderen',
      '/delight-austria': '/nl/oostenrijk/delight-opwaarderen',
      '/t-mobile-austria': '/nl/oostenrijk/t-mobile-opwaarderen',
      '/m-tel-austria': '/nl/oostenrijk/m-tel-opwaarderen',
      '/optus-australia': '/nl/australie/optus-opwaarderen',
      '/orange-liberia': '/nl/liberia/orange-opwaarderen',
      '/smile-uganda': '/nl/oeganda/smile-opwaarderen',
      '/talkout-spain': '/nl/spanje/talkout-opwaarderen',
      '/clm-spain': '/nl/spanje/clm-opwaarderen',
      '/uganda-telecom-uganda': '/nl/oeganda/uganda-telecom-opwaarderen',
      '/mundimovil-spain': '/nl/spanje/mundimovil-opwaarderen',
      '/yu-kenya': '/nl/kenia/yu-opwaarderen',
      '/jazzcard-movil-spain': '/nl/spanje/jazzcard-movil-opwaarderen',
      '/smart-tanzania-united-republic-of': '/nl/tanzania/smart-opwaarderen',
      '/moov-gabon': '/nl/gabon/moov-opwaarderen',
      '/best-movil-spain': '/nl/spanje/best-movil-opwaarderen',
      '/movistar-mexico': '/nl/mexico/movistar-opwaarderen',
      '/vodafone-india': '/nl/india/vodafone-opwaarderen',
      '/lyca-international-united-states':
        '/nl/verenigde-staten/lyca-international-opwaarderen',
      '/lycamobile-unlimited-international-united-states':
        '/nl/verenigde-staten/lycamobile-unlimited-international-opwaarderen',
      '/symacom-france': '/nl/frankrijk/symacom-opwaarderen',
      '/la-poste-mobile-france': '/nl/frankrijk/la-poste-mobile-opwaarderen',
      '/lebara-united-kingdom': '/nl/verenigd-koninkrijk/lebara-opwaarderen',
      '/tesco-mobile-united-kingdom':
        '/nl/verenigd-koninkrijk/tesco-mobile-opwaarderen',
      '/nrj-france': '/nl/frankrijk/nrj-opwaarderen',
      '/metropcs-united-states': '/nl/verenigde-staten/metropcs-opwaarderen',
      '/virgin-mobile-united-states':
        '/nl/verenigde-staten/virgin-mobile-opwaarderen',
      '/lycamobile-portugal': '/nl/portugal/lycamobile-opwaarderen',
      '/vectone-mobile-portugal': '/nl/portugal/vectone-mobile-opwaarderen',
      '/vodafone-portugal': '/nl/portugal/vodafone-opwaarderen',
      '/uzo-portugal': '/nl/portugal/uzo-opwaarderen',
      '/nos-portugal': '/nl/portugal/nos-opwaarderen',
      '/moche-portugal': '/nl/portugal/moche-opwaarderen',
      '/meo-portugal': '/nl/portugal/meo-opwaarderen',
      '/ttcl-tanzania-united-republic-of': '/nl/tanzania/ttcl-opwaarderen',
      '/halotel-tanzania-united-republic-of':
        '/nl/tanzania/halotel-opwaarderen',
      '/reliance-jio-india': '/nl/india/reliance-jo-opwaarderen',
      '/etl-lao-peoples-democratic-republic': '/nl/laos/etl-opwaarderen',
      '/wereldkaart': '/nl/landen',
    },
    es: {
      '/yoigo-opwaarderen': '/nl/spanje/yoigo-opwaarderen',
      '/happy-movil-opwaarderen': '/nl/spanje/happy-movil-opwaarderen',
      '/masmovil-opwaarderen': '/nl/spanje/masmovil-opwaarderen',
      '/orange-spain-opwaarderen': '/nl/spanje/orange-opwaarderen',
      '/simyo-spanje-opwaarderen': '/nl/spanje/simyo-opwaarderen',
      '/digimobil-opwaarderen': '/nl/spanje/digimobil-opwaarderen',
    },
    za: {
      '/vodacom-zuid-afrika-opwaarderen': '/nl/zuid-afrika/vodacom-opwaarderen',
    },
    be: {
      '/lycamobile-opwaarderen': '/nl/lycamobile',
      '/guild-wars': '/nl-be/gaming-gift-cards',
    },
    ma: {
      '/orange-spain-opwaarderen': '/nl/spanje/orange-opwaarderen',
      '/maroc-telecom-opwaarderen': '/nl/marokko/maroc-telecom-opwaarderen',
    },
    tr: {
      '/turkcell-turkije-opwaarderen': '/nl/turkije/turkcell-opwaarderen',
    },
    nl: {
      '/beltegoed-nieuwe-klanten-promo': '/',
      '/inwi-opwaarderen': '/nl/marokko/inwi-opwaarderen',
    },
  },
  de: {
    '??': {
      '/mtn-nigeria': '/de/nigeria/mtn-aufladen',
      '/orange-romania': '/de/rumaenien/orange-aufladen',
      '/orange-senegal': '/de/senegal/orange-aufladen',
      '/orange-poland': '/de/polen/orange-aufladen',
      '/safaricom-kenya': '/de/kenia/safaricom-aufladen',
      '/roshan-afghanistan': '/de/afghanistan/roshan-aufladen',
      '/mtn-uganda': '/de/uganda/mtn-aufladen',
      '/metfone-cambodia': '/de/kambodscha/metfone-aufladen',
      '/mobitel-sri-lanka': '/de/sri-lanka/mobitel-aufladen',
      '/orange-tunisia': '/de/tunesien/orange-aufladen',
      '/maroc-telecom-morocco': '/de/marokko/maroc-telecom-aufladen',
      '/qcell-gambia': '/de/gambia/qcell-aufladen',
      '/orange-dominican-republic':
        '/de/dominikanische-republik/orange-aufladen',
      '/sunrise-switzerland': '/de/schweiz/sunrise-aufladen',
    },
    es: {
      '/guild-wars': '/de-es/gaming-guthaben',
    },
  },
  it: {
    '??': {
      '/celcom-malaysia': '/it/malaysia/ricarica-celcom',
      '/orange-madagascar': '/it/madagascar/ricarica-orange',
      '/wataniya-state-of-palestine': '/it/palestina/ricarica-wataniya',
      '/dollarphone-united-states': '/it/stati-uniti/ricarica-dollarphone',
      '/flow-virgin-islands-british':
        '/it/isole-vergini-britanniche/ricarica-flow',
      '/moov-niger': '/it/niger/ricarica-moov',
      '/digicel-nauru': '/it/nauru/ricarica-digicel',
      '/expo-mobile-united-states': '/it/stati-uniti/ricarica-expo-mobile',
      '/aircel-tamil-nadu-india': '/it/india/ricarica-aircel-tamil-nadu',
      '/claro-puerto-rico': '/it/puerto-rico/ricarica-claro',
      '/warid-pakistan': '/it/pakistan/ricarica-warid',
      '/singtel-singapore': '/it/singapore/ricarica-singtel',
      '/vodafone-gujarat-india': '/it/india/ricarica-vodafone-gujarat',
      '/une-colombia': '/it/colombia/ricarica-une',
      '/econet-burundi': '/it/burundi/ricarica-econet',
      '/airtel-india': '/it/australia/ricarica-airtel',
      '/ooredoo-tunisia': '/it/tunisia/ricarica-ooredoo',
      '/vodafone-spain': '/it/spagna/ricarica-vodafone',
      '/banglalink-bangladesh': '/it/bangladesh/ricarica-banglalink',
      '/videocon-india': '/it/india/ricarica-videocon',
      '/movistar-nicaragua': '/it/nicaragua/ricarica-movistar',
      '/tigo-bolivia': '/it/bolivia/ricarica-tigo',
      '/play-poland': '/it/polonia/ricarica-play',
      '/entel-bolivia': '/it/bolivia/ricarica-entel',
      '/lebara-spain': '/it/spagna/ricarica-lebara',
      '/smartfren-indonesia': '/it/indonesia/ricarica-smartfren',
      '/xl-indonesia': '/it/indonesia/ricarica-xl',
      '/viettel-vietnam': '/it/vietnam/ricarica-viettel',
      '/tunisie-telecom-tunisia': '/it/tunisia/ricarica-tunisie-telecom',
      '/mtn-nigeria': '/it/nigeria/ricarica-mtn',
      '/mtnl-india': '/it/india/ricarica-mtnl',
      '/tuenti-spain': '/it/spagna/ricarica-tuenti',
      '/movistar-peru': '/it/peru/ricarica-movistar',
      '/cnt-ecuador': '/it/ecuador/ricarica-cnt',
      '/robi-axiata-bangladesh': '/it/bangladesh/ricarica-robi-axiata',
      '/etisalat-afghanistan': '/it/afghanistan/ricarica-etisalat',
      '/turkcell-turkey': '/it/turchia/ricarica-turkcell',
      '/orange-mali': '/it/mali/ricarica-orange',
      '/utel-ukraine': '/it/ucraina/ricarica-utel',
      '/movistar-el-salvador': '/it/el salvador/ricarica-movistar',
      '/ooredoo-algeria': '/it/algeria/ricarica-ooredoo',
      '/megafone-tajikistan': '/it/tagikistan/ricarica-megafone',
      '/aircel-hp-india': '/it/india/ricarica-aircel-hp',
      '/telenor-india': '/it/india/ricarica-telenor',
      '/ais-thailand': '/it/tailandia/ricarica-ais',
      '/vodafone-turkey': '/it/turchia/ricarica-vodafone',
      '/aircel-chennai-india': '/it/india/ricarica-aircel-chennai',
      '/beeline-tajikistan': '/it/tagikistan/ricarica-beeline',
      '/utl-mobile-nepal': '/it/nepal/ricarica-utl-mobile',
      '/virgin-mobile-colombia': '/it/colombia/ricarica-virgin-mobile',
      '/sinpin-united-states': '/it/stati-uniti/ricarica-sinpin',
      '/carrefour-spain': '/it/spagna/ricarica-carrefour',
      '/aircel-assam-india': '/it/india/ricarica-aircel-assam',
      '/vodafone-uttar-pradesh-west-india':
        '/it/india/ricarica-vodafone-uttar-pradesh-west',
      '/mtn-yemen': '/it/yemen/ricarica-mtn',
      '/aircel-bihar-india': '/it/india/ricarica-aircel-bihar',
      '/digicel-virgin-islands-british':
        '/it/isole-vergini-britanniche/ricarica-digicel',
      '/mts-india': '/it/india/ricarica-mts',
      '/tigo-colombia': '/it/colombia/ricarica-tigo',
      '/orange-romania': '/it/romania/ricarica-orange',
      '/axis-indonesia': '/it/indonesia/ricarica-axis',
      '/nextel-brazil': '/it/brasile/ricarica-nextel',
      '/nationlink-somalia': '/it/mali/ricarica-nationlink',
      '/lifecell-ukraine': '/it/ucraina/ricarica-lifecell',
      '/idea-india': '/it/australia/ricarica-idea',
      '/mtn-rwanda': '/it/ruanda/ricarica-mtn',
      '/ultra-mobile-united-states': '/it/stati-uniti/ricarica-ultra-mobile',
      '/cable-and-wireless-panama': '/it/panama/ricarica-cable-and-wireless',
      '/oi-brazil': '/it/brasile/ricarica-oi',
      '/orange-congo': '/it/congo/ricarica-orange',
      '/digicel-cayman-islands': '/it/isole-cayman/ricarica-digicel',
      '/happy-movil-spain': '/it/spagna/ricarica-happy-movil',
      '/unicom-china': '/it/cina/ricarica-china-unicom',
      '/telenor-myanmar': '/it/myanmar/ricarica-telenor',
      '/tata-docomo-india': '/it/india/ricarica-tata-docomo',
      '/zong-pakistan': '/it/pakistan/ricarica-zong',
      '/digicel-samoa': '/it/samoa/ricarica-digicel',
      '/vodafone-kerala-india': '/it/india/ricarica-vodafone-kerala',
      '/claro-peru': '/it/peru/ricarica-claro',
      '/cubacel-cuba': '/it/cuba/ricarica-cubacel',
      '/etb-colombia': '/it/colombia/ricarica-etb',
      '/mtn-congo': '/it/congo/ricarica-mtn',
      '/aircel-rajasthan-india': '/it/india/ricarica-aircel-rajasthan',
      '/digicel-antigua-and-barbuda': '/it/antigua-barbuda/ricarica-digicel',
      '/natcom-haiti': '/it/haiti/ricarica-natcom',
      '/smarts-yaroslavl-russian-federation':
        '/it/russa/ricarica-smarts-yaroslavl',
      '/claro-chile': '/it/chile/ricarica-claro',
      '/aircel-mumbai-india': '/it/india/ricarica-aircel-mumbai',
      '/tata-indicom-india': '/it/india/ricarica-tata-indicom',
      '/vox-paraguay': '/it/paraguay/ricarica-vox',
      '/vodafone-kolkata-india': '/it/india/ricarica-vodafone-kolkata',
      '/geocell-georgia': '/it/georgia/ricarica-geocell',
      '/telekom-mobile-romania': '/it/romania/ricarica-telekom-mobile',
      '/inwi-morocco': '/it/marocco/ricarica-inwi',
      '/indosat-mentari-indonesia': '/it/indonesia/ricarica-indosat-mentari',
      '/avea-turkey': '/it/turchia/ricarica-avea',
      '/africell-gambia': '/it/gambia/ricarica-africell',
      '/nextel-chile': '/it/chile/ricarica-nextel',
      '/orange-senegal': '/it/senegal/ricarica-orange',
      '/djezzy-algeria': '/it/algeria/ricarica-djezzy',
      '/aircel-andhra-pradesh-india':
        '/it/india/ricarica-aircel-andhra-pradesh',
      '/claro-uruguay': '/it/uruguay/ricarica-claro',
      '/digicel-tonga': '/it/tonga/ricarica-digicel',
      '/orange-poland': '/it/polonia/ricarica-orange',
      '/telenor-pakistan': '/it/pakistan/ricarica-telenor',
      '/lailai-georgia': '/it/georgia/ricarica-lailai',
      '/jazz-pakistan': '/it/pakistan/ricarica-jazz',
      '/vodacom-mozambique': '/it/mozambico/ricarica-vodacom',
      '/llamaya-spain': '/it/spagna/ricarica-llamaya',
      '/mtn-cameroon': '/it/camerun/ricarica-mtn',
      '/orange-niger': '/it/nigeria/ricarica-orange',
      '/masmovil-spain': '/it/spagna/ricarica-masmovil',
      '/mobilis-algeria': '/it/algeria/ricarica-mobilis',
      '/mtn-swaziland': '/it/swaziland/ricarica-mtn',
      '/intertelecom-ukraine': '/it/ucraina/ricarica-intertelecom',
      '/bouygues-france': '/it/francia/ricarica-bouygues',
      '/movistar-ecuador': '/it/ecuador/ricarica-movistar',
      '/dialog-sri-lanka': '/it/sri-lanka/ricarica-dialog',
      '/mobifone-vietnam': '/it/vietnam/ricarica-mobifone',
      '/megafon-far-east-russian-federation':
        '/it/russa/ricarica-megafon-far-east',
      '/telkomsel-kartu-as-indonesia': '/it/indonesia/ricarica-telkomsel',
      '/mts-russian-federation': '/it/russa/ricarica-mts',
      '/digicel-fiji': '/it/fiji/ricarica-digicel',
      '/ufone-pakistan': '/it/pakistan/ricarica-ufone',
      '/airtel-ghana': '/it/ghana/ricarica-airteltigo',
      '/personal-argentina': '/it/argentina/ricarica-personal',
      '/hong-da-mobile-spain': '/it/spagna/ricarica-hong-da-mobile',
      '/beeline-georgia': '/it/georgia/ricarica-beeline',
      '/vodafone-rajasthan-india': '/it/india/ricarica-vodafone-rajasthan',
      '/digicel-turks-and-caicos-islands':
        '/it/isole-turks-caicos/ricarica-digicel',
      '/ethiotelecom-ethiopia': '/it/etiopia/ricarica-ethiotelecom',
      '/asia-cell-telecom-iraq': '/it/iraq/ricarica-asia-cell-telecom',
      '/telkomsel-simpati-indonesia': '/it/indonesia/ricarica-telkomsel',
      '/telecel-zimbabwe': '/it/zimbabwe/ricarica-telecel',
      '/hablafacil-spain': '/it/spagna/ricarica-hablafacil',
      '/bmobile-trinidad-and-tobago': '/it/trinidad-tobago/ricarica-bmobile',
      '/ucom-armenia': '/it/armenia/ricarica-ucom',
      '/myphone-georgia': '/it/georgia/ricarica-myphone',
      '/personal-paraguay': '/it/paraguay/ricarica-personal',
      '/vodafone-maharashtra-india': '/it/india/ricarica-vodafone-maharashtra',
      '/rostelecom-mrf-ural-russian-federation':
        '/it/russa/ricarica-rostelecom-mrf-ural',
      '/mtn-guinea': '/it/guinea/ricarica-mtn',
      '/moldcell-moldova-republic-of': '/it/moldovia/ricarica-moldcell',
      '/telecom-china': '/it/cina/ricarica-china-telecom',
      '/lycamobile-france': '/it/francia/ricarica-lyca',
      '/grameenphone-bangladesh': '/it/bangladesh/ricarica-grameenphone',
      '/digicel-el-salvador': '/it/el salvador/ricarica-digicel',
      '/vietnammobile-vietnam': '/it/vietnam/ricarica-vietnammobile',
      '/bani-georgia': '/it/georgia/ricarica-bani',
      '/digimobil-spain': '/it/spagna/ricarica-digimobil',
      '/falabella-chile': '/it/chile/ricarica-falabella',
      '/tuyo-movil-costa-rica': '/it/costa-rica/ricarica-tuyo-movil',
      '/tigo-el-salvador': '/it/el salvador/ricarica-tigo',
      '/heyah-poland': '/it/polonia/ricarica-heyah',
      '/hits-mobile-spain': '/it/spagna/ricarica-hits-mobile',
      '/megafon-caucasus-russian-federation':
        '/it/russa/ricarica-megafon-caucasus',
      '/true-move-thailand': '/it/tailandia/ricarica-true-move',
      '/tigo-paraguay': '/it/paraguay/ricarica-tigo',
      '/orange-egypt': '/it/egitto/ricarica-orange',
      '/digicel-vanuatu': '/it/vanuatu/ricarica-digicel',
      '/magti-gsm-georgia': '/it/georgia/ricarica-magti-gsm',
      '/etisalat-egypt': '/it/egitto/ricarica-etisalat',
      '/mtn-liberia': '/it/liberia/ricarica-mtn',
      '/vodafone-romania': '/it/romania/ricarica-vodafone',
      '/safaricom-kenya': '/it/kenya/ricarica-safaricom',
      '/vodafone-fiji': '/it/fiji/ricarica-vodafone',
      '/digicel-barbados': '/it/barbados/ricarica-digicel',
      '/zamtel-zambia': '/it/zambia/ricarica-zamtel',
      '/vodacom-tanzania-united-republic-of': '/it/tanzania/ricarica-vodacom',
      '/orange-guinea-bissau': '/it/guinea-bissau/ricarica-orange',
      '/telsur-chile': '/it/chile/ricarica-telsur',
      '/airtel-nigeria': '/it/nigeria/ricarica-airtel',
      '/gmobile-vietnam': '/it/vietnam/ricarica-gmobile',
      '/africell-sierra-leone': '/it/sierra-leone/ricarica-africell',
      '/mtn-south-africa': '/it/sud-africa/ricarica-mtn',
      '/lyca-mobile-united-states': '/it/stati-uniti/ricarica-lyca-mobile',
      '/gphone-georgia': '/it/georgia/ricarica-gphone',
      '/ntc-gsm-nepal': '/it/nepal/ricarica-ntc-gsm',
      '/tcell-tajikistan': '/it/tagikistan/ricarica-tcell',
      '/claro-argentina': '/it/argentina/ricarica-claro',
      '/beeline-kyrgyzstan': '/it/kirghizistan/ricarica-beeline',
      '/umniah-jordan': '/it/giordania/ricarica-umniah',
      '/jawwal-state-of-palestine': '/it/palestina/ricarica-jawwal',
      '/smart-burundi': '/it/burundi/ricarica-smart',
      '/africel-uganda': '/it/uganda/ricarica-africel',
      '/digicel-saint-kitts-and-nevis':
        '/it/saint-kitts-nevis/ricarica-digicel',
      '/hutch-sri-lanka': '/it/sri-lanka/ricarica-hutch',
      '/tele2-russian-federation': '/it/russa/ricarica-tele2',
      '/mts-ukraine': '/it/ucraina/ricarica-mts',
      '/digicel-dominica': '/it/dominica/ricarica-digicel',
      '/kb-impuls-beeline-russian-federation':
        '/it/russa/ricarica-kb-impuls-beeline',
      '/babilonmobile-tajikistan': '/it/tagikistan/ricarica-babilonmobile',
      '/btc-bahamas': '/it/bahamas/ricarica-btc',
      '/lebara-france': '/it/francia/ricarica-lebara',
      '/vodacom-south-africa': '/it/sud-africa/ricarica-vodacom',
      '/euskaltel-spain': '/it/spagna/ricarica-euskaltel',
      '/vodafone-haryana-india': '/it/india/ricarica-vodafone-haryana',
      '/glo-ghana': '/it/ghana/ricarica-glo',
      '/ncell-nepal': '/it/nepal/ricarica-ncell',
      '/zain-kuwait': '/it/kuwait/ricarica-zain',
      '/mobile-china': '/it/cina/ricarica-china-mobile',
      '/megafon-ural-russian-federation': '/it/russa/ricarica-megafon-ural',
      '/roshan-afghanistan': '/it/afghanistan/ricarica-roshan',
      '/blau-spain': '/it/spagna/ricarica-blau',
      '/tigo-ghana': '/it/ghana/ricarica-airteltigo',
      '/aircel-punjab-india': '/it/india/ricarica-aircel-punjab',
      '/fonex-kyrgyzstan': '/it/kirghizistan/ricarica-fonex',
      '/unefon-mexico': '/it/messico/ricarica-unefon',
      '/megafon-center-russian-federation': '/it/russa/ricarica-megafon-center',
      '/orange-moldova-republic-of': '/it/moldovia/ricarica-orange',
      '/aircel-west-bengal-india': '/it/india/ricarica-aircel-west-bengal',
      '/at-and-t-united-states': '/it/stati-uniti/ricaricare-att',
      '/mtn-uganda': '/it/uganda/ricarica-mtn',
      '/airtel-bangladesh': '/it/bangladesh/ricarica-airtel',
      '/maxis-malaysia': '/it/malaysia/ricarica-maxis',
      '/megafon-povolzhye-russian-federation':
        '/it/russa/ricarica-megafon-povolzhye',
      '/lyca-mobile-spain': '/it/spagna/ricarica-lycamobile',
      '/mtn-benin': '/it/benin/ricarica-mtn',
      '/etisalat-nigeria': '/it/nigeria/ricarica-etisalat',
      '/vodafone-delhi-india': '/it/india/ricarica-vodafone-delhi',
      '/digicel-panama': '/it/panama/ricarica-digicel',
      '/vodafone-chennai-india': '/it/india/ricarica-vodafone-chennai',
      '/umobile-malaysia': '/it/malaysia/ricarica-umobile',
      '/pureminutes-united-states': '/it/stati-uniti/ricarica-pureminutes',
      '/motiv-russian-federation': '/it/russa/ricarica-motiv',
      '/moov-central-african-republic':
        '/it/repubblica-centrafricana/ricarica-moov',
      '/expresso-ghana': '/it/ghana/ricarica-expresso',
      '/vodafone-tamil-nadu-india': '/it/india/ricarica-vodafone-tamil-nadu',
      '/mtn-cyprus': '/it/cipro/ricarica-mtn',
      '/vodafone-bangalore-india': '/it/india/ricarica-vodafone-bangalore',
      '/tigo-senegal': '/it/senegal/ricarica-tigo',
      '/vodafone-mumbai-india': '/it/india/ricarica-vodafone-mumbai',
      '/digicel-anguilla': '/it/anguilla/ricarica-digicel',
      '/tigo-tanzania-united-republic-of': '/it/tanzania/ricarica-tigo',
      '/warid-uganda': '/it/uganda/ricarica-warid',
      '/digicel-aruba': '/it/aruba/ricarica-digicel',
      '/claro-brazil': '/it/brasile/ricarica-claro',
      '/uff-movil-colombia': '/it/colombia/ricarica-uff-movil',
      '/metfone-cambodia': '/it/cambogia/ricarica-metfone',
      '/orange-spain': '/it/spagna/ricarica-orange',
      '/megafon-moscow-russian-federation': '/it/russa/ricarica-megafon-moscow',
      '/aircel-karnataka-india': '/it/india/ricarica-aircel-karnataka',
      '/mobitel-sri-lanka': '/it/sri-lanka/ricarica-mobitel',
      '/ceria-indonesia': '/it/indonesia/ricarica-ceria',
      '/virgin-mobile-chile': '/it/chile/ricarica-virgin-mobile',
      '/vivo-brazil': '/it/brasile/ricarica-vivo',
      '/starhub-singapore': '/it/singapore/ricarica-starhub',
      '/glo-nigeria': '/it/nigeria/ricarica-glo',
      '/orange-tunisia': '/it/tunisia/ricarica-orange',
      '/suntel-sri-lanka': '/it/sri-lanka/ricarica-suntel',
      '/movistar-chile': '/it/chile/ricarica-movistar',
      '/digicel-papua-new-guinea': '/it/papua-nuova-guinea/ricarica-digicel',
      '/digicel-trinidad-and-tobago': '/it/trinidad-tobago/ricarica-digicel',
      '/smart-uganda': '/it/uganda/ricarica-smart',
      '/tricom-dominican-republic': '/it/repubblica-dominicana/ricarica-tricom',
      '/aircel-north-east-india': '/it/india/ricarica-aircel-north-east',
      '/onatel-burkina-faso': '/it/burkina-faso/ricarica-onatel',
      '/globe-philippines': '/it/filippine/ricarica-globe',
      '/vodafone-andhra-pradesh-india':
        '/it/india/ricarica-vodafone-andhra-pradesh',
      '/orange-kenya': '/it/kenya/ricarica-orange',
      '/tigo-rwanda': '/it/ruanda/ricarica-tigo',
      '/vinaphone-vietnam': '/it/vietnam/ricarica-vinaphone',
      '/vodafone-bengal-india': '/it/india/ricarica-vodafone-bengal',
      '/moov-cote-divoire': '/it/costa-d-avorio/ricarica-moov',
      '/mpt-myanmar': '/it/myanmar/ricarica-mpt',
      '/vodafone-ghana': '/it/ghana/ricarica-vodafone',
      '/orange-morocco': '/it/marocco/ricarica-orange',
      '/citycell-bangladesh': '/it/bangladesh/ricarica-citycell',
      '/flow-turks-and-caicos-islands': '/it/isole-turks-caicos/ricarica-flow',
      '/maroc-telecom-morocco': '/it/marocco/ricarica-maroc-telecom',
      '/s-fone-vietnam': '/it/vietnam/ricarica-s-fone',
      '/digi-malaysia': '/it/malaysia/ricarica-digi',
      '/tkmobile-tajikistan': '/it/tagikistan/ricarica-tkmobile',
      '/nextel-argentina': '/it/argentina/ricarica-nextel',
      '/aircel-uttar-pradesh-east-india':
        '/it/india/ricarica-aircel-uttar-pradesh-east',
      '/airtel-sri-lanka': '/it/sri-lanka/ricarica-airtel',
      '/m1-singapore': '/it/singapore/ricarica-m1',
      '/aircel-jk-india': '/it/india/ricarica-aircel-jk',
      '/simple-mobile-united-states': '/it/stati-uniti/ricarica-simple-mobile',
      '/qcell-gambia': '/it/gambia/ricarica-qcell',
      '/beeline-ukraine': '/it/ucraina/ricarica-beeline',
      '/dtac-thailand': '/it/tailandia/ricarica-dtac',
      '/digicel-suriname': '/it/suriname/ricarica-digicel',
      '/cell-c-south-africa': '/it/sud-africa/ricarica-cell-c',
      '/tak-tak-poland': '/it/polonia/ricarica-tak-tak',
      '/cellcom-guinea': '/it/guinea/ricarica-cellcom',
      '/mobilcat-spain': '/it/spagna/ricarica-mobilcat',
      '/indosat-im3-indonesia': '/it/indonesia/ricarica-indosat-im3',
      '/aircel-kolkata-india': '/it/india/ricarica-aircel-kolkata',
      '/sun-cellular-philippines': '/it/filippine/ricarica-sun-cellular',
      '/o-kyrgyzstan': '/it/kirghizistan/ricarica-o',
      '/verizon-united-states': '/it/stati-uniti/ricarica-verizon',
      '/dth-tata-sky-india': '/it/india/ricarica-dth-tata-sky',
      '/claro-nicaragua': '/it/nicaragua/ricarica-claro',
      '/viva-kuwait': '/it/kuwait/ricarica-viva',
      '/aircel-orissa-india': '/it/india/ricarica-aircel-orissa',
      '/smarts-samara-russian-federation': '/it/russa/ricarica-smarts-samara',
      '/afghan-wireless-afghanistan':
        '/it/afghanistan/ricarica-afghan-wireless',
      '/reliance-india': '/it/india/ricarica-reliance',
      '/mtn-afghanistan': '/it/afghanistan/ricarica-mtn',
      '/three-telecom-indonesia': '/it/indonesia/ricarica-three-telecom',
      '/bluesky-american-samoa': '/it/samoa/ricarica-bluesky',
      '/mtn-cote-divoire': '/it/costa-d-avorio/ricarica-mtn',
      '/entel-chile': '/it/chile/ricarica-entel',
      '/viva-dominican-republic': '/it/repubblica-dominicana/ricarica-viva',
      '/tunetalk-malaysia': '/it/malaysia/ricarica-tunetalk',
      '/mtn-ghana': '/it/ghana/ricarica-mtn',
      '/claro-panama': '/it/panama/ricarica-claro',
      '/vivacell-mts-armenia': '/it/armenia/ricarica-vivacell-mts',
      '/aircel-maharashtra-india': '/it/india/ricarica-aircel-maharashtra',
      '/vodafone-egypt': '/it/egitto/ricarica-vodafone',
      '/kyivstar-ukraine': '/it/ucraina/ricarica-kyivstar',
      '/flow-saint-vincent-and-the-grenadines':
        '/it/saint-vincent-grenadine/ricarica-flow',
      '/vodafone-uttar-pradesh-east-india':
        '/it/india/ricarica-vodafone-uttar-pradesh-east',
      '/digicel-saint-lucia': '/it/santa-lucia/ricarica-digicel',
      '/digicel-martinique': '/it/martinique/ricarica-digicel',
      '/yoigo-spain': '/it/spagna/ricarica-yoigo',
      '/avantel-colombia': '/it/colombia/ricarica-avantel',
      '/smarts-cheboksary-russian-federation':
        '/it/russa/ricarica-smarts-cheboksary',
      '/orange-france': '/it/francia/ricarica-orange',
      '/digicel-bermuda': '/it/bermuda/ricarica-digicel',
      '/vodafone-albania': '/it/albania/ricarica-vodafone',
      '/megafon-north-west-russian-federation':
        '/it/russa/ricarica-megafon-north-west',
      '/claro-el-salvador': '/it/el salvador/ricarica-claro',
      '/tim-brazil': '/it/brasile/ricarica-tim',
      '/t-mobile-united-states': '/it/stati-uniti/ricarica-t-mobile',
      '/megafon-siberia-russian-federation':
        '/it/russa/ricarica-megafon-siberia',
      '/megacom-kyrgyzstan': '/it/kirghizistan/ricarica-megacom',
      '/bsnl-india': '/it/india/ricarica-bsnl',
      '/vtr-chile': '/it/chile/ricarica-vtr',
      '/moov-benin': '/it/benin/ricarica-moov',
      '/iusacell-mexico': '/it/messico/ricarica-iusacell',
      '/airtel-uganda': '/it/uganda/ricarica-airtel',
      '/polkomtel-plus-poland': '/it/polonia/ricarica-polkomtel-plus',
      '/econet-zimbabwe': '/it/zimbabwe/ricarica-econet',
      '/claro-colombia': '/it/colombia/ricarica-claro',
      '/viva-bolivia': '/it/bolivia/ricarica-viva',
      '/etisalat-sri-lanka': '/it/sri-lanka/ricarica-etisalat',
      '/telemedia-belize': '/it/belize/ricarica-telemedia',
      '/camgsm-cambodia': '/it/cambogia/ricarica-camgsm',
      '/aircel-delhi-india': '/it/india/ricarica-aircel-delhi',
      '/orange-central-african-republic':
        '/it/repubblica-centrafricana/ricarica-orange',
      '/vodafone-punjab-india': '/it/india/ricarica-vodafone-punjab',
      '/smarts-astrakhan-russian-federation':
        '/it/russa/ricarica-smarts-astrakhan',
      '/claro-ecuador': '/it/ecuador/ricarica-claro',
      '/teletalk-bangladesh': '/it/bangladesh/ricarica-teletalk',
      '/bayn-gsm-morocco': '/it/marocco/ricarica-bayn-gsm',
      '/mtn-guinea-bissau': '/it/guinea-bissau/ricarica-mtn',
      '/telcel-mexico': '/it/messico/ricarica-telcel',
      '/telkom-mobile-south-africa': '/it/sud-africa/ricarica-telkom-mobile',
      '/aircel-india': '/it/india/ricarica-aircel',
      '/airtel-rwanda': '/it/ruanda/ricarica-airtel',
      '/airtel-kenya': '/it/kenya/ricarica-airtel',
      '/alegro-ecuador': '/it/ecuador/ricarica-alegro',
      '/beeline-lao-peoples-democratic-republic': '/it/laos/ricarica-beeline',
      '/cadcomms-cambodia': '/it/cambogia/ricarica-cadcomms',
      '/claro-costa-rica': '/it/costa-rica/ricarica-claro',
      '/claro-dominican-republic': '/it/repubblica-dominicana/ricarica-claro',
      '/claro-guatemala': '/it/guatemala/ricarica-claro',
      '/claro-honduras': '/it/honduras/ricarica-claro',
      '/cosmote-romania': '/it/romania/ricarica-cosmote',
      '/comcel-colombia': '/it/colombia/ricarica-comcel',
      '/cyfrowy-polsat-poland': '/it/polonia/ricarica-cyfrowy-polsat',
      '/digicel-curacao': '/it/curacao/ricarica-digicel',
      '/digicel-guyana': '/it/guyana/ricarica-digicel',
      '/digicel-grenada': '/it/grenada/ricarica-digicel',
      '/digicel-haiti': '/it/haiti/ricarica-digicel',
      '/digicel-jamaica': '/it/giamaica/ricarica-digicel',
      '/digicel-saint-vincent-and-the-grenadines':
        '/it/saint-vincent-grenadine/ricarica-digicel',
      '/evn-telecom-vietnam': '/it/vietnam/ricarica-evn-telecom',
      '/expresso-telecom-senegal': '/it/senegal/ricarica-expresso-telecom',
      '/full-movil-costa-rica': '/it/costa-rica/ricarica-full-movil',
      '/gt-mobile-spain': '/it/spagna/ricarica-gt-mobile',
      '/gtt-guyana': '/it/guyana/ricarica-gtt',
      '/hutchison-three-indonesia': '/it/indonesia/ricarica-hutchison-three',
      '/kolbi-costa-rica': '/it/costa-rica/ricarica-kolbi',
      '/flow-anguilla': '/it/anguilla/ricarica-flow',
      '/flow-barbados': '/it/barbados/ricarica-flow',
      '/flow-antigua-and-barbuda': '/it/antigua-barbuda/ricarica-flow',
      '/flow-cayman-islands': '/it/isole-cayman/ricarica-flow',
      '/flow-dominica': '/it/dominica/ricarica-flow',
      '/flow-grenada': '/it/grenada/ricarica-flow',
      '/flow-saint-kitts-and-nevis': '/it/saint-kitts-nevis/ricarica-flow',
      '/flow-jamaica': '/it/giamaica/ricarica-flow',
      '/flow-montserrat': '/it/montserrat/ricarica-flow',
      '/flow-saint-lucia': '/it/santa-lucia/ricarica-flow',
      '/movicel-angola': '/it/angola/ricarica-movicel',
      '/moun-dominican-republic': '/it/repubblica-dominicana/ricarica-moun',
      '/movistar-argentina': '/it/argentina/ricarica-movistar',
      '/movistar-costa-rica': '/it/costa-rica/ricarica-movistar',
      '/movistar-colombia': '/it/colombia/ricarica-movistar',
      '/movistar-guatemala': '/it/guatemala/ricarica-movistar',
      '/movistar-panama': '/it/panama/ricarica-movistar',
      '/mts-armenia': '/it/armenia/ricarica-mts',
      '/netone-zimbabwe': '/it/zimbabwe/ricarica-netone',
      '/orange-dominican-republic': '/it/repubblica-dominicana/ricarica-orange',
      '/orange-cameroon': '/it/camerun/ricarica-orange',
      '/orange-guinea': '/it/guinea/ricarica-orange',
      '/orange-cote-divoire': '/it/costa-d-avorio/ricarica-orange',
      '/orange-congo-the-democratic-republic-of-the':
        '/it/congo/ricarica-orange',
      '/telkom-kenya': '/it/kenya/ricarica-telkom',
      '/tigo-guatemala': '/it/guatemala/ricarica-tigo',
      '/tigo-honduras': '/it/honduras/ricarica-tigo',
      '/t-mobile-poland': '/it/polonia/ricarica-t-mobile',
      '/togocel-togo': '/it/togo/ricarica-togocel',
      '/uninor-india': '/it/india/ricarica-uninor',
      '/brasil-telecom-brazil': '/it/brasile/ricarica-brasil-telecom',
      '/claro-jamaica': '/it/giamaica/ricarica-claro',
      '/digicel-honduras': '/it/honduras/ricarica-digicel',
      '/easy-go-united-states': '/it/stati-uniti/ricarica-easy-go',
      '/h2o-united-states': '/it/stati-uniti/ricarica-h2o',
      '/voila-haiti': '/it/haiti/ricarica-voila',
      '/idirect-dial-united-states': '/it/stati-uniti/ricarica-idirect-dial',
      '/myphone2go-ild-united-states':
        '/it/stati-uniti/ricarica-myphone2go-ild',
      '/net-10-united-states': '/it/stati-uniti/ricarica-net-10',
      '/nextel-mexico': '/it/messico/ricarica-nextel',
      '/rok-mobile-united-states': '/it/stati-uniti/ricarica-rok-mobile',
      '/smart-philippines': '/it/filippine/ricarica-smart',
      '/talk-talk-now-united-states': '/it/stati-uniti/ricarica-talk-talk-now',
      '/terracom-united-states': '/it/stati-uniti/ricarica-terracom',
      '/ucell-uzbekistan': '/it/uzbekistan/ricarica-ucell',
      '/yourtel-united-states': '/it/stati-uniti/ricarica-yourtel',
      '/mtn-zambia': '/it/zambia/ricarica-mtn',
      '/zantel-tanzania-united-republic-of': '/it/tanzania/ricarica-zantel',
      '/vodafone-kktc-turkey': '/it/turchia/ricarica-vodafone-kktc',
      '/univision-united-states': '/it/stati-uniti/ricarica-univision',
      '/gt-mobile-france': '/it/francia/ricarica-gt-mobile',
      '/bali-georgia': '/it/georgia/ricarica-bali',
      '/onevip-macedonia': '/it/macedonia/ricarica-onevip',
      '/tele2-lithuania': '/it/lituania/ricarica-tele2',
      '/life-belarus': '/it/bielorussia/ricarica-life',
      '/mts-belarus': '/it/bielorussia/ricarica-mts',
      '/airtel-madagascar': '/it/madagascar/ricarica-airtel',
      '/smart-gold-philippines': '/it/filippine/ricarica-smart-gold',
      '/smart-bro-philippines': '/it/filippine/ricarica-smart-bro',
      '/beeline-uzbekistan': '/it/uzbekistan/ricarica-beeline',
      '/movistar-uruguay': '/it/uruguay/ricarica-movistar',
      '/glo-benin': '/it/benin/ricarica-glo',
      '/digicel-french-guiana': '/it/guiana-francese/ricarica-digicel',
      '/orange-uganda': '/it/uganda/ricarica-orange',
      '/t-mobile-united-kingdom': '/it/regno-unito/ricarica-t-mobile',
      '/sfr-france': '/it/francia/ricarica-sfr',
      '/bob-austria': '/it/austria/ricarica-bob',
      '/b-free-austria': '/it/austria/ricarica-b-free',
      '/tele-dot-ring-austria': '/it/austria/ricarica-telering',
      '/lycamobile-austria': '/it/austria/ricarica-lycamobile',
      '/nimm3-austria': '/it/austria/ricarica-nimm3',
      '/3reload-austria': '/it/austria/ricarica-3reload',
      '/vectone-austria': '/it/austria/ricarica-vectone',
      '/eety-austria': '/it/austria/ricarica-eety',
      '/ee-united-kingdom': '/it/regno-unito/ricarica-ee',
      '/cricket-paygo-united-states': '/it/stati-uniti/ricarica-cricket-paygo',
      '/boost-united-states': '/it/stati-uniti/ricarica-boost',
      '/du-more-united-arab-emirates':
        '/it/emirati-arabi-uniti/ricarica-du-more',
      '/kcell-kazakhstan': '/it/kazakistan/ricarica-kcell',
      '/altel-kazakhstan': '/it/kazakistan/ricarica-altel',
      '/activ-kazakhstan': '/it/kazakistan/ricarica-activ',
      '/beeline-kazakhstan': '/it/kazakistan/ricarica-beeline',
      '/tele2-kazakhstan': '/it/kazakistan/ricarica-tele2',
      '/airtel-zambia': '/it/zambia/ricarica-airtel',
      '/airtel-tanzania-united-republic-of': '/it/tanzania/ricarica-airtel',
      '/megafon-center-south-russian-federation':
        '/it/russa/ricarica-megafon-center-south',
      '/claro-paraguay': '/it/paraguay/ricarica-claro',
      '/ooredoo-myanmar': '/it/myanmar/ricarica-ooredoo',
      '/mec-myanmar': '/it/myanmar/ricarica-mec',
      '/airtel-malawi': '/it/malawi/ricarica-airtel',
      '/aircel-uttar-pradesh-west-india':
        '/it/india/ricarica-aircel-uttar-pradesh-west',
      '/virgin-mobile-mexico': '/it/messico/ricarica-virgin-mobile',
      '/maz-mexico': '/it/messico/ricarica-maz',
      '/cierto-mexico': '/it/messico/ricarica-cierto',
      '/alo-mexico': '/it/messico/ricarica-alo',
      '/touch-lebanon': '/it/libano/ricarica-touch',
      '/alfa-lebanon': '/it/libano/ricarica-alfa',
      '/aircel-kerala-india': '/it/india/ricarica-aircel-kerala',
      '/tuenti-ecuador': '/it/ecuador/ricarica-tuenti',
      '/smart-cambodia': '/it/cambogia/ricarica-smart',
      '/zain-bahrain': '/it/bahrain/ricarica-zain',
      '/viva-bahrain': '/it/bahrain/ricarica-viva',
      '/freedom-mobile-canada': '/it/canada/ricarica-freedom-mobile',
      '/virgin-canada': '/it/canada/ricarica-virgin',
      '/telus-canada': '/it/canada/ricarica-telus',
      '/solo-canada': '/it/canada/ricarica-solo',
      '/rogers-canada': '/it/canada/ricarica-rogers',
      '/publicmobile-canada': '/it/canada/ricarica-publicmobile',
      '/mts-canada': '/it/canada/ricarica-mts',
      '/koodo-canada': '/it/canada/ricarica-koodo',
      '/fido-canada': '/it/canada/ricarica-fido',
      '/chatr-canada': '/it/canada/ricarica-chatr',
      '/bell-canada': '/it/canada/ricarica-bell',
      '/zain-saudi-arabia': '/it/arabia-saudita/ricarica-zain',
      '/virgin-saudi-arabia': '/it/arabia-saudita/ricarica-virgin',
      '/stc-saudi-arabia': '/it/arabia-saudita/ricarica-stc',
      '/mobily-saudi-arabia': '/it/arabia-saudita/ricarica-mobily',
      '/lebara-saudi-arabia': '/it/arabia-saudita/ricarica-lebara',
      '/friendi-saudi-arabia': '/it/arabia-saudita/ricarica-friendi',
      '/allo-saudi-arabia': '/it/arabia-saudita/ricarica-allo',
      '/virgin-mobile-south-africa': '/it/sud-africa/ricarica-virgin-mobile',
      '/vodafone-qatar': '/it/qatar/ricarica-vodafone',
      '/ooredoo-qatar': '/it/qatar/ricarica-ooredoo',
      '/at-and-t-puerto-rico': '/it/puerto-rico/ricarica-at-t',
      '/renna-mobile-oman': '/it/oman/ricarica-renna-mobile',
      '/omantel-oman': '/it/oman/ricarica-omantel',
      '/friendi-mobile-oman': '/it/oman/ricarica-friendi-mobile',
      '/movitel-mozambique': '/it/zambia/ricarica-movitel',
      '/yallo-switzerland': '/it/svizzera/ricarica-yallo',
      '/swisscom-switzerland': '/it/moldovia/ricarica-swisscom',
      '/sunrise-switzerland': '/it/svizzera/ricarica-sunrise',
      '/salt-mobile-switzerland': '/it/svizzera/ricarica-salt-mobile',
      '/ortel-mobile-switzerland': '/it/svizzera/ricarica-ortel-mobile',
      '/mucho-mobile-switzerland': '/it/svizzera/ricarica-mucho-mobile',
      '/lycamobile-switzerland': '/it/svizzera/ricarica-lycamobile',
      '/lebara-switzerland': '/it/svizzera/ricarica-lebara',
      '/tatem-congo': '/it/congo/ricarica-tatem',
      '/tracfone-united-states': '/it/stati-uniti/ricarica-tracfone',
      '/telcel-america-united-states':
        '/it/stati-uniti/ricarica-telcel-america',
      '/sti-mobile-united-states': '/it/stati-uniti/ricarica-sti-mobile',
      '/real-mobile-united-states': '/it/stati-uniti/ricarica-real-mobile',
      '/ready-mobile-united-states': '/it/stati-uniti/ricarica-ready-mobile',
      '/page-plus-united-states': '/it/stati-uniti/ricarica-page-plus',
      '/good2go-united-states': '/it/stati-uniti/ricarica-good2go',
      '/cintex-wireless-united-states':
        '/it/stati-uniti/ricarica-cintex-wireless',
      '/red-pocket-united-states': '/it/stati-uniti/ricarica-red-pocket',
      '/urcall-mobile-united-states': '/it/stati-uniti/ricarica-urcall-mobile',
      '/life-wireless-united-states': '/it/stati-uniti/ricarica-life-wireless',
      '/airvoice-united-states': '/it/stati-uniti/ricarica-airvoice',
      '/access-wireless-united-states':
        '/it/stati-uniti/ricarica-access-wireless',
      '/etisalat-united-arab-emirates':
        '/it/emirati-arabi-uniti/ricarica-etisalat',
      '/smart-cell-nepal': '/it/nepal/ricarica-smart-cell',
      '/tn-mobile-namibia': '/it/namibia/ricarica-tn-mobile',
      '/mtc-namibia': '/it/namibia/ricarica-mtc',
      '/orange-botswana': '/it/botswana/ricarica-orange',
      '/mascom-botswana': '/it/botswana/ricarica-mascom',
      '/bemobile-botswana': '/it/botswana/ricarica-bemobile',
      '/batelco-bahrain': '/it/bahrain/ricarica-batelco',
      '/cvmovel-cape-verde': '/it/capo-verde/ricarica-cvmovel',
      '/three-united-kingdom': '/it/regno-unito/ricarica-three',
      '/asda-mobile-united-kingdom': '/it/regno-unito/ricarica-asda-mobile',
      '/giff-gaff-united-kingdom': '/it/regno-unito/ricarica-giff-gaff',
      '/lycamobile-united-kingdom': '/it/regno-unito/ricarica-lycamobile',
      '/talk-home-mobile-united-kingdom':
        '/it/regno-unito/ricarica-talk-home-mobile',
      '/o2-united-kingdom': '/it/regno-unito/ricarica-o2',
      '/vodafone-united-kingdom': '/it/regno-unito/ricarica-vodafone',
      '/virgin-mobile-united-kingdom': '/it/regno-unito/ricarica-virgin-mobile',
      '/you-mobile-spain': '/it/spagna/ricarica-you-mobile',
      '/republica-movile-spain': '/it/spagna/ricarica-republica-movile',
      '/lcr-movile-spain': '/it/spagna/ricarica-lcr-movile',
      '/hablapp-spain': '/it/spagna/ricarica-hablapp',
      '/wind-italy': '/it/canada/ricarica-wind',
      '/vodafone-italy': '/it/italia/ricarica-vodafone',
      '/tim-italy': '/it/italia/ricarica-tim',
      '/3-tre-italy': '/it/italia/ricarica-3-tre',
      '/bluesky-samoa': '/it/canada/ricarica-bluesky',
      '/telenor-serbia': '/it/serbia/ricarica-telenor',
      '/orange-burkina-faso': '/it/burkina-faso/ricarica-orange',
      '/delight-austria': '/it/austria/ricarica-delight',
      '/t-mobile-austria': '/it/austria/ricarica-t-mobile',
      '/m-tel-austria': '/it/austria/ricarica-m-tel',
      '/optus-australia': '/it/australia/ricarica-optus',
      '/orange-liberia': '/it/liberia/ricarica-orange',
      '/smile-uganda': '/it/uganda/ricarica-smile',
      '/talkout-spain': '/it/spagna/ricarica-talkout',
      '/clm-spain': '/it/spagna/ricarica-clm',
      '/uganda-telecom-uganda': '/it/uganda/ricarica-uganda-telecom',
      '/mundimovil-spain': '/it/spagna/ricarica-mundimovil',
      '/yu-kenya': '/it/kenya/ricarica-yu',
      '/jazzcard-movil-spain': '/it/spagna/ricarica-jazzcard-movil',
      '/smart-tanzania-united-republic-of': '/it/tanzania/ricarica-smart',
      '/moov-gabon': '/it/gabon/ricarica-moov',
      '/best-movil-spain': '/it/spagna/ricarica-best-movil',
      '/movistar-mexico': '/it/messico/ricarica-movistar',
      '/vodafone-india': '/it/india/ricarica-vodafone',
      '/lyca-international-united-states':
        '/it/stati-uniti/ricarica-lyca-international',
      '/lycamobile-unlimited-international-united-states':
        '/it/stati-uniti/ricarica-lycamobile-unlimited-international',
      '/symacom-france': '/it/francia/ricarica-symacom',
      '/la-poste-mobile-france': '/it/francia/ricarica-la-poste-mobile',
      '/lebara-united-kingdom': '/it/regno-unito/ricarica-lebara',
      '/tesco-mobile-united-kingdom': '/it/regno-unito/ricarica-tesco-mobile',
      '/nrj-france': '/it/francia/ricarica-nrj',
      '/metropcs-united-states': '/it/stati-uniti/ricarica-metropcs',
      '/virgin-mobile-united-states': '/it/stati-uniti/ricarica-virgin-mobile',
      '/lycamobile-portugal': '/it/portogallo/ricarica-lycamobile',
      '/vectone-mobile-portugal': '/it/portogallo/ricarica-vectone',
      '/vodafone-portugal': '/it/portogallo/ricarica-vodafone',
      '/uzo-portugal': '/it/portogallo/ricarica-uzo',
      '/nos-portugal': '/it/portogallo/ricarica-nos',
      '/moche-portugal': '/it/portogallo/ricarica-moche',
      '/meo-portugal': '/it/portogallo/ricarica-meo',
      '/ttcl-tanzania-united-republic-of': '/it/tanzania/ricarica-ttcl',
      '/halotel-tanzania-united-republic-of': '/it/tanzania/ricarica-halotel',
      '/reliance-jio-india': '/it/india/ricarica-reliance-jio',
      '/etl-lao-peoples-democratic-republic': '/it/laos/ricarica-reliance-jio',
      '/mappa-del-mondo': '/it/paesi',
      '/domance-frequenti': '/it/faq',
    },
    es: {
      '/ricaricare-yoigo': '/it/spagna/ricarica-yoigo',
      '/ricaricare-simyo-spagna': '/it/spagna/ricarica-simyo',
      '/ricaricare-ortel-mobile': '/it/svizzera/ricarica-ortel-mobile',
    },
    mg: {
      '/madagascar-ricariche-orange': '/it/madagascar/ricarica-orange',
    },
    fr: {
      '/ricaricare-lyca-mobile': '/it/lycamobile',
    },
    it: {
      '/ricaricare-lyca-mobile': '/it/lycamobile',
      '/ricaricare-meditel': '/it/marocco/ricarica-orange',
      '/guild-wars': '/it-it/carte-regalo-giochi',
    },
    tn: {
      '/ricaricare-lyca-mobile': '/it/lycamobile',
    },
    nl: {
      '/ricaricare-inwi': '/it/marocco/ricarica-inwi',
    },
    gh: {
      '/ricaricare-mtn-ghana-online': '/it/ghana/ricarica-mtn',
    },
    pl: {
      '/ricariche-orange-polonia': '/it/polonia/ricarica-orange',
      '/ricariche-play': '/it/polonia/ricarica-play',
    },
    cn: {
      '/ricaricare-china-mobile': '/it/cina/ricarica-china-mobile',
      '/ricaricare-china-unicom': '/it/cina/ricarica-china-unicom',
    },
    co: {
      '/ricaricare-movistar-colombia': '/it/colombia/ricarica-movistar',
      '/ricaricare-tigo-colombia': '/it/colombia/ricarica-tigo',
    },
    cr: {
      '/ricariche-kolbi-costa-rica': '/it/costa-rica/ricarica-kolbi',
    },
    af: {
      '/ricaricare-etisalat-afghanistan': '/it/afghanistan/ricarica-etisalat',
    },
    in: {
      '/ricarica-online-idea': '/it/india/ricarica-idea',
      '/ricaricare-vodafone-india-online': '/it/india/ricarica-vodafone',
    },
    ci: {
      '/ricaricare-mtn-per-la-costa-davorio': '/it/costa-d-avorio/ricarica-mtn',
      '/ricarica-orange-costa-davorio': '/it/costa-d-avorio/ricarica-orange',
    },
  },
  fr: {
    '??': {
      '/celcom-malaysia': '/fr/malaisie/recharge-celcom',
      '/orange-madagascar': '/fr/madagascar/recharge-orange',
      '/wataniya-state-of-palestine': '/fr/palestine/recharge-wataniya',
      '/dollarphone-united-states': '/fr/etats-unis/recharge-dollarphone',
      '/flow-virgin-islands-british':
        '/fr/iles-vierges-britanniques/recharge-flow',
      '/moov-niger': '/fr/niger/recharge-moov',
      '/digicel-nauru': '/fr/nauru/recharge-digicel',
      '/expo-mobile-united-states': '/fr/etats-unis/recharge-expo-mobile',
      '/aircel-tamil-nadu-india': '/fr/inde/recharge-aircel-tamil-nadu',
      '/claro-puerto-rico': '/fr/porto-rico/recharge-claro',
      '/warid-pakistan': '/fr/pakistan/recharge-warid',
      '/singtel-singapore': '/fr/singapour/recharge-singtel',
      '/vodafone-gujarat-india': '/fr/inde/recharge-vodafone-gujarat',
      '/une-colombia': '/fr/colombie/recharge-une',
      '/econet-burundi': '/fr/burundi/recharge-econet',
      '/airtel-india': '/fr/australie/recharge-airtel',
      '/ooredoo-tunisia': '/fr/tunisie/recharge-ooredoo',
      '/vodafone-spain': '/fr/espagne/recharge-vodafone',
      '/banglalink-bangladesh': '/fr/bangladesh/recharge-banglalink',
      '/videocon-india': '/fr/inde/recharge-videocon',
      '/movistar-nicaragua': '/fr/nicaragua/recharge-movistar',
      '/tigo-bolivia': '/fr/bolivie/recharge-tigo',
      '/play-poland': '/fr/pologne/recharge-play',
      '/entel-bolivia': '/fr/bolivie/recharge-entel',
      '/lebara-spain': '/fr/espagne/recharge-lebara',
      '/smartfren-indonesia': '/fr/indonesie/recharge-smartfren',
      '/xl-indonesia': '/fr/indonesie/recharge-xl',
      '/viettel-vietnam': '/fr/viet-nam/recharge-viettel',
      '/tunisie-telecom-tunisia': '/fr/tunisie/recharge-tunisie-telecom',
      '/mtn-nigeria': '/fr/nigeria/recharge-mtn',
      '/mtnl-india': '/fr/inde/recharge-mtnl',
      '/tuenti-spain': '/fr/espagne/recharge-tuenti',
      '/movistar-peru': '/fr/perou/recharge-movistar',
      '/cnt-ecuador': '/fr/equateur/recharge-cnt',
      '/robi-axiata-bangladesh': '/fr/bangladesh/recharge-robi-axiata',
      '/etisalat-afghanistan': '/fr/afghanistan/recharge-etisalat',
      '/turkcell-turkey': '/fr/turquie/recharge-turkcell',
      '/orange-mali': '/fr/mali/recharge-orange',
      '/utel-ukraine': '/fr/ukraine/recharge-utel',
      '/movistar-el-salvador': '/fr/le-salvador/recharge-movistar',
      '/ooredoo-algeria': '/fr/algerie/recharge-ooredoo',
      '/megafone-tajikistan': '/fr/tadjikistan/recharge-megafone',
      '/aircel-hp-india': '/fr/inde/recharge-aircel-hp',
      '/telenor-india': '/fr/inde/recharge-telenor',
      '/ais-thailand': '/fr/thailande/recharge-ais',
      '/vodafone-turkey': '/fr/turquie/recharge-vodafone',
      '/aircel-chennai-india': '/fr/inde/recharge-aircel-chennai',
      '/beeline-tajikistan': '/fr/tadjikistan/recharge-beeline',
      '/utl-mobile-nepal': '/fr/nepal/recharge-utl-mobile',
      '/virgin-mobile-colombia': '/fr/colombie/recharge-virgin-mobile',
      '/sinpin-united-states': '/fr/etats-unis/recharge-sinpin',
      '/carrefour-spain': '/fr/espagne/recharge-carrefour',
      '/aircel-assam-india': '/fr/inde/recharge-aircel-assam',
      '/vodafone-uttar-pradesh-west-india':
        '/fr/inde/recharge-vodafone-uttar-pradesh-west',
      '/mtn-yemen': '/fr/yemen/recharge-mtn',
      '/aircel-bihar-india': '/fr/inde/recharge-aircel-bihar',
      '/digicel-virgin-islands-british':
        '/fr/iles-vierges-britanniques/recharge-digicel',
      '/mts-india': '/fr/inde/recharge-mts',
      '/tigo-colombia': '/fr/colombie/recharge-tigo',
      '/orange-romania': '/fr/roumanie/recharge-orange',
      '/axis-indonesia': '/fr/indonesie/recharge-axis',
      '/nextel-brazil': '/fr/bresil/recharge-nextel',
      '/nationlink-somalia': '/fr/mali/recharge-nationlink',
      '/lifecell-ukraine': '/fr/ukraine/recharge-lifecell',
      '/idea-india': '/fr/australie/recharge-idea',
      '/mtn-rwanda': '/fr/rwanda/recharge-mtn',
      '/ultra-mobile-united-states': '/fr/etats-unis/recharge-ultra-mobile',
      '/cable-and-wireless-panama': '/fr/panama/recharge-cable-and-wireless',
      '/oi-brazil': '/fr/bresil/recharge-oi',
      '/orange-congo': '/fr/congo/recharge-orange',
      '/digicel-cayman-islands': '/fr/iles-caimans/recharge-digicel',
      '/happy-movil-spain': '/fr/espagne/recharge-happy-movil',
      '/unicom-china': '/fr/chine/recharge-china-unicom',
      '/telenor-myanmar': '/fr/myanmar/recharge-telenor',
      '/tata-docomo-india': '/fr/inde/recharge-tata-docomo',
      '/zong-pakistan': '/fr/pakistan/recharge-zong',
      '/digicel-samoa': '/fr/samoa/recharge-digicel',
      '/vodafone-kerala-india': '/fr/inde/recharge-vodafone-kerala',
      '/claro-peru': '/fr/perou/recharge-claro',
      '/cubacel-cuba': '/fr/cuba/recharge-cubacel',
      '/etb-colombia': '/fr/colombie/recharge-etb',
      '/mtn-congo': '/fr/congo/recharge-mtn',
      '/aircel-rajasthan-india': '/fr/inde/recharge-aircel-rajasthan',
      '/digicel-antigua-and-barbuda': '/fr/antigua-barbuda/recharge-digicel',
      '/natcom-haiti': '/fr/haiti/recharge-natcom',
      '/smarts-yaroslavl-russian-federation':
        '/fr/russie/recharge-smarts-yaroslavl',
      '/claro-chile': '/fr/chili/recharge-claro',
      '/aircel-mumbai-india': '/fr/inde/recharge-aircel-mumbai',
      '/tata-indicom-india': '/fr/inde/recharge-tata-indicom',
      '/vox-paraguay': '/fr/paraguay/recharge-vox',
      '/vodafone-kolkata-india': '/fr/inde/recharge-vodafone-kolkata',
      '/geocell-georgia': '/fr/georgie/recharge-geocell',
      '/telekom-mobile-romania': '/fr/roumanie/recharge-telekom-mobile',
      '/inwi-morocco': '/fr/maroc/recharge-inwi',
      '/indosat-mentari-indonesia': '/fr/indonesie/recharge-indosat-mentari',
      '/avea-turkey': '/fr/turquie/recharge-avea',
      '/africell-gambia': '/fr/gambie/recharge-africell',
      '/nextel-chile': '/fr/chili/recharge-nextel',
      '/orange-senegal': '/fr/senegal/recharge-orange',
      '/djezzy-algeria': '/fr/algerie/recharge-djezzy',
      '/aircel-andhra-pradesh-india': '/fr/inde/recharge-aircel-andhra-pradesh',
      '/claro-uruguay': '/fr/uruguay/recharge-claro',
      '/digicel-tonga': '/fr/tonga/recharge-digicel',
      '/orange-poland': '/fr/pologne/recharge-orange',
      '/telenor-pakistan': '/fr/pakistan/recharge-telenor',
      '/lailai-georgia': '/fr/georgie/recharge-lailai',
      '/jazz-pakistan': '/fr/pakistan/recharge-jazz',
      '/vodacom-mozambique': '/fr/mozambique/recharge-vodacom',
      '/llamaya-spain': '/fr/espagne/recharge-llamaya',
      '/mtn-cameroon': '/fr/cameroun/recharge-mtn',
      '/orange-niger': '/fr/nigeria/recharge-orange',
      '/masmovil-spain': '/fr/espagne/recharge-masmovil',
      '/mobilis-algeria': '/fr/algerie/recharge-mobilis',
      '/mtn-swaziland': '/fr/swaziland/recharge-mtn',
      '/intertelecom-ukraine': '/fr/ukraine/recharge-intertelecom',
      '/bouygues-france': '/fr/france/recharge-bouygues',
      '/movistar-ecuador': '/fr/equateur/recharge-movistar',
      '/dialog-sri-lanka': '/fr/sri-lanka/recharge-dialog',
      '/mobifone-vietnam': '/fr/viet-nam/recharge-mobifone',
      '/megafon-far-east-russian-federation':
        '/fr/russie/recharge-megafon-far-east',
      '/telkomsel-kartu-as-indonesia': '/fr/indonesie/recharge-telkomsel',
      '/mts-russian-federation': '/fr/russie/recharge-mts',
      '/digicel-fiji': '/fr/fidji/recharge-digicel',
      '/ufone-pakistan': '/fr/pakistan/recharge-ufone',
      '/airtel-ghana': '/fr/ghana/recharge-airteltigo',
      '/personal-argentina': '/fr/argentine/recharge-personal',
      '/hong-da-mobile-spain': '/fr/espagne/recharge-hong-da-mobile',
      '/beeline-georgia': '/fr/georgie/recharge-beeline',
      '/vodafone-rajasthan-india': '/fr/inde/recharge-vodafone-rajasthan',
      '/digicel-turks-and-caicos-islands':
        '/fr/iles-turques-caiques/recharge-digicel',
      '/ethiotelecom-ethiopia': '/fr/ethiopie/recharge-ethiotelecom',
      '/asia-cell-telecom-iraq': '/fr/irak/recharge-asia-cell-telecom',
      '/telkomsel-simpati-indonesia': '/fr/indonesie/recharge-telkomsel',
      '/telecel-zimbabwe': '/fr/zimbabwe/recharge-telecel',
      '/hablafacil-spain': '/fr/espagne/recharge-hablafacil',
      '/bmobile-trinidad-and-tobago': '/fr/trinite-tobago/recharge-bmobile',
      '/ucom-armenia': '/fr/armenie/recharge-ucom',
      '/myphone-georgia': '/fr/georgie/recharge-myphone',
      '/personal-paraguay': '/fr/paraguay/recharge-personal',
      '/vodafone-maharashtra-india': '/fr/inde/recharge-vodafone-maharashtra',
      '/rostelecom-mrf-ural-russian-federation':
        '/fr/russie/recharge-rostelecom-mrf-ural',
      '/mtn-guinea': '/fr/guinee/recharge-mtn',
      '/moldcell-moldova-republic-of': '/fr/moldavie/recharge-moldcell',
      '/telecom-china': '/fr/chine/recharge-china-telecom',
      '/lycamobile-france': '/fr/france/recharge-lyca',
      '/grameenphone-bangladesh': '/fr/bangladesh/recharge-grameenphone',
      '/digicel-el-salvador': '/fr/le-salvador/recharge-digicel',
      '/vietnammobile-vietnam': '/fr/viet-nam/recharge-vietnammobile',
      '/bani-georgia': '/fr/georgie/recharge-bani',
      '/digimobil-spain': '/fr/espagne/recharge-digimobil',
      '/falabella-chile': '/fr/chili/recharge-falabella',
      '/tuyo-movil-costa-rica': '/fr/costa-rica/recharge-tuyo-movil',
      '/tigo-el-salvador': '/fr/le-salvador/recharge-tigo',
      '/heyah-poland': '/fr/pologne/recharge-heyah',
      '/hits-mobile-spain': '/fr/espagne/recharge-hits-mobile',
      '/megafon-caucasus-russian-federation':
        '/fr/russie/recharge-megafon-caucasus',
      '/true-move-thailand': '/fr/thailande/recharge-true-move',
      '/tigo-paraguay': '/fr/paraguay/recharge-tigo',
      '/orange-egypt': '/fr/egypte/recharge-orange',
      '/digicel-vanuatu': '/fr/vanuatu/recharge-digicel',
      '/magti-gsm-georgia': '/fr/georgie/recharge-magti-gsm',
      '/etisalat-egypt': '/fr/egypte/recharge-etisalat',
      '/mtn-liberia': '/fr/liberia/recharge-mtn',
      '/vodafone-romania': '/fr/roumanie/recharge-vodafone',
      '/safaricom-kenya': '/fr/kenia/recharge-safaricom',
      '/vodafone-fiji': '/fr/fidji/recharge-vodafone',
      '/digicel-barbados': '/fr/barbades/recharge-digicel',
      '/zamtel-zambia': '/fr/zambie/recharge-zamtel',
      '/vodacom-tanzania-united-republic-of': '/fr/tanzanie/recharge-vodacom',
      '/orange-guinea-bissau': '/fr/guinee-bissau/recharge-orange',
      '/telsur-chile': '/fr/chili/recharge-telsur',
      '/airtel-nigeria': '/fr/nigeria/recharge-airtel',
      '/gmobile-vietnam': '/fr/viet-nam/recharge-gmobile',
      '/africell-sierra-leone': '/fr/sierra-leone/recharge-africell',
      '/mtn-south-africa': '/fr/ afrique-du-sud/recharge-mtn',
      '/lyca-mobile-united-states': '/fr/etats-unis/recharge-lyca-mobile',
      '/gphone-georgia': '/fr/georgie/recharge-gphone',
      '/ntc-gsm-nepal': '/fr/nepal/recharge-ntc-gsm',
      '/tcell-tajikistan': '/fr/tadjikistan/recharge-tcell',
      '/claro-argentina': '/fr/argentine/recharge-claro',
      '/beeline-kyrgyzstan': '/fr/kirghizistan/recharge-beeline',
      '/umniah-jordan': '/fr/jordan/recharge-umniah',
      '/jawwal-state-of-palestine': '/fr/palestine/recharge-jawwal',
      '/smart-burundi': '/fr/burundi/recharge-smart',
      '/africel-uganda': '/fr/ouganda/recharge-africel',
      '/digicel-saint-kitts-and-nevis':
        '/fr/saint-christophe-nieves/recharge-digicel',
      '/hutch-sri-lanka': '/fr/sri-lanka/recharge-hutch',
      '/tele2-russian-federation': '/fr/russie/recharge-tele2',
      '/mts-ukraine': '/fr/ukraine/recharge-mts',
      '/digicel-dominica': '/fr/dominique/recharge-digicel',
      '/kb-impuls-beeline-russian-federation':
        '/fr/russie/recharge-kb-impuls-beeline',
      '/babilonmobile-tajikistan': '/fr/tadjikistan/recharge-babilonmobile',
      '/btc-bahamas': '/fr/bahamas/recharge-btc',
      '/lebara-france': '/fr/france/recharge-lebara',
      '/vodacom-south-africa': '/fr/ afrique-du-sud/recharge-vodacom',
      '/euskaltel-spain': '/fr/espagne/recharge-euskaltel',
      '/vodafone-haryana-india': '/fr/inde/recharge-vodafone-haryana',
      '/glo-ghana': '/fr/ghana/recharge-glo',
      '/ncell-nepal': '/fr/nepal/recharge-ncell',
      '/zain-kuwait': '/fr/koweit/recharge-zain',
      '/mobile-china': '/fr/chine/recharge-china-mobile',
      '/megafon-ural-russian-federation': '/fr/russie/recharge-megafon-ural',
      '/roshan-afghanistan': '/fr/afghanistan/recharge-roshan',
      '/blau-spain': '/fr/espagne/recharge-blau',
      '/tigo-ghana': '/fr/ghana/recharge-airteltigo',
      '/aircel-punjab-india': '/fr/inde/recharge-aircel-punjab',
      '/fonex-kyrgyzstan': '/fr/kirghizistan/recharge-fonex',
      '/unefon-mexico': '/fr/mexique/recharge-unefon',
      '/megafon-center-russian-federation':
        '/fr/russie/recharge-megafon-center',
      '/orange-moldova-republic-of': '/fr/moldavie/recharge-orange',
      '/aircel-west-bengal-india': '/fr/inde/recharge-aircel-west-bengal',
      '/at-and-t-united-states': '/fr/etats-unis/recharge-at-t',
      '/mtn-uganda': '/fr/ouganda/recharge-mtn',
      '/airtel-bangladesh': '/fr/bangladesh/recharge-airtel',
      '/maxis-malaysia': '/fr/malaisie/recharge-maxis',
      '/megafon-povolzhye-russian-federation':
        '/fr/russie/recharge-megafon-povolzhye',
      '/lyca-mobile-spain': '/fr/espagne/recharge-lycamobile',
      '/mtn-benin': '/fr/benin/recharge-mtn',
      '/etisalat-nigeria': '/fr/nigeria/recharge-etisalat',
      '/vodafone-delhi-india': '/fr/inde/recharge-vodafone-delhi',
      '/digicel-panama': '/fr/panama/recharge-digicel',
      '/vodafone-chennai-india': '/fr/inde/recharge-vodafone-chennai',
      '/umobile-malaysia': '/fr/malaisie/recharge-umobile',
      '/pureminutes-united-states': '/fr/etats-unis/recharge-pureminutes',
      '/motiv-russian-federation': '/fr/russie/recharge-motiv',
      '/moov-central-african-republic':
        '/fr/republique-centrafricaine/recharge-moov',
      '/expresso-ghana': '/fr/ghana/recharge-expresso',
      '/vodafone-tamil-nadu-india': '/fr/inde/recharge-vodafone-tamil-nadu',
      '/mtn-cyprus': '/fr/chypre/recharge-mtn',
      '/vodafone-bangalore-india': '/fr/inde/recharge-vodafone-bangalore',
      '/tigo-senegal': '/fr/senegal/recharge-tigo',
      '/vodafone-mumbai-india': '/fr/inde/recharge-vodafone-mumbai',
      '/digicel-anguilla': '/fr/anguilla/recharge-digicel',
      '/tigo-tanzania-united-republic-of': '/fr/tanzanie/recharge-tigo',
      '/warid-uganda': '/fr/ouganda/recharge-warid',
      '/digicel-aruba': '/fr/aruba/recharge-digicel',
      '/claro-brazil': '/fr/bresil/recharge-claro',
      '/uff-movil-colombia': '/fr/colombie/recharge-uff-movil',
      '/metfone-cambodia': '/fr/cambodge/recharge-metfone',
      '/orange-spain': '/fr/espagne/recharge-orange',
      '/megafon-moscow-russian-federation':
        '/fr/russie/recharge-megafon-moscow',
      '/aircel-karnataka-india': '/fr/inde/recharge-aircel-karnataka',
      '/mobitel-sri-lanka': '/fr/sri-lanka/recharge-mobitel',
      '/ceria-indonesia': '/fr/indonesie/recharge-ceria',
      '/virgin-mobile-chile': '/fr/chili/recharge-virgin-mobile',
      '/vivo-brazil': '/fr/bresil/recharge-vivo',
      '/starhub-singapore': '/fr/singapour/recharge-starhub',
      '/glo-nigeria': '/fr/nigeria/recharge-glo',
      '/orange-tunisia': '/fr/tunisie/recharge-orange',
      '/suntel-sri-lanka': '/fr/sri-lanka/recharge-suntel',
      '/movistar-chile': '/fr/chili/recharge-movistar',
      '/digicel-papua-new-guinea':
        '/fr/papouasie-nouvelle-guinee/recharge-digicel',
      '/digicel-trinidad-and-tobago': '/fr/trinite-tobago/recharge-digicel',
      '/smart-uganda': '/fr/ouganda/recharge-smart',
      '/tricom-dominican-republic':
        '/fr/republique-dominicaine/recharge-tricom',
      '/aircel-north-east-india': '/fr/inde/recharge-aircel-north-east',
      '/onatel-burkina-faso': '/fr/burkina-faso/recharge-onatel',
      '/globe-philippines': '/fr/philipines/recharge-globe',
      '/vodafone-andhra-pradesh-india':
        '/fr/inde/recharge-vodafone-andhra-pradesh',
      '/orange-kenya': '/fr/kenia/recharge-orange',
      '/tigo-rwanda': '/fr/rwanda/recharge-tigo',
      '/vinaphone-vietnam': '/fr/viet-nam/recharge-vinaphone',
      '/vodafone-bengal-india': '/fr/inde/recharge-vodafone-bengal',
      '/moov-cote-divoire': '/fr/cote-divoire/recharge-moov',
      '/mpt-myanmar': '/fr/myanmar/recharge-mpt',
      '/vodafone-ghana': '/fr/ghana/recharge-vodafone',
      '/orange-morocco': '/fr/maroc/recharge-meditel',
      '/citycell-bangladesh': '/fr/bangladesh/recharge-citycell',
      '/flow-turks-and-caicos-islands':
        '/fr/iles-turques-caiques/recharge-flow',
      '/maroc-telecom-morocco': '/fr/maroc/recharge-maroc-telecom',
      '/s-fone-vietnam': '/fr/viet-nam/recharge-s-fone',
      '/digi-malaysia': '/fr/malaisie/recharge-digi',
      '/tkmobile-tajikistan': '/fr/tadjikistan/recharge-tkmobile',
      '/nextel-argentina': '/fr/argentine/recharge-nextel',
      '/aircel-uttar-pradesh-east-india':
        '/fr/inde/recharge-aircel-uttar-pradesh-east',
      '/airtel-sri-lanka': '/fr/sri-lanka/recharge-airtel',
      '/m1-singapore': '/fr/singapour/recharge-m1',
      '/aircel-jk-india': '/fr/inde/recharge-aircel-jk',
      '/simple-mobile-united-states': '/fr/etats-unis/recharge-simple-mobile',
      '/qcell-gambia': '/fr/gambie/recharge-qcell',
      '/beeline-ukraine': '/fr/ukraine/recharge-beeline',
      '/dtac-thailand': '/fr/thailande/recharge-dtac',
      '/digicel-suriname': '/fr/suriname/recharge-digicel',
      '/cell-c-south-africa': '/fr/ afrique-du-sud/recharge-cell-c',
      '/tak-tak-poland': '/fr/pologne/recharge-tak-tak',
      '/cellcom-guinea': '/fr/guinee/recharge-cellcom',
      '/mobilcat-spain': '/fr/espagne/recharge-mobilcat',
      '/indosat-im3-indonesia': '/fr/indonesie/recharge-indosat-im3',
      '/aircel-kolkata-india': '/fr/inde/recharge-aircel-kolkata',
      '/sun-cellular-philippines': '/fr/philipines/recharge-sun-cellular',
      '/o-kyrgyzstan': '/fr/kirghizistan/recharge-o',
      '/verizon-united-states': '/fr/etats-unis/recharge-verizon',
      '/dth-tata-sky-india': '/fr/inde/recharge-dth-tata-sky',
      '/claro-nicaragua': '/fr/nicaragua/recharge-claro',
      '/viva-kuwait': '/fr/koweit/recharge-viva',
      '/aircel-orissa-india': '/fr/inde/recharge-aircel-orissa',
      '/smarts-samara-russian-federation': '/fr/russie/recharge-smarts-samara',
      '/afghan-wireless-afghanistan':
        '/fr/afghanistan/recharge-afghan-wireless',
      '/reliance-india': '/fr/inde/recharge-reliance',
      '/mtn-afghanistan': '/fr/afghanistan/recharge-mtn',
      '/three-telecom-indonesia': '/fr/indonesie/recharge-three-telecom',
      '/bluesky-american-samoa': '/fr/samoa/recharge-bluesky',
      '/mtn-cote-divoire': '/fr/cote-divoire/recharge-mtn',
      '/entel-chile': '/fr/chili/recharge-entel',
      '/viva-dominican-republic': '/fr/republique-dominicaine/recharge-viva',
      '/tunetalk-malaysia': '/fr/malaisie/recharge-tunetalk',
      '/mtn-ghana': '/fr/ghana/recharge-mtn',
      '/claro-panama': '/fr/panama/recharge-claro',
      '/vivacell-mts-armenia': '/fr/armenie/recharge-vivacell-mts',
      '/aircel-maharashtra-india': '/fr/inde/recharge-aircel-maharashtra',
      '/vodafone-egypt': '/fr/egypte/recharge-vodafone',
      '/kyivstar-ukraine': '/fr/ukraine/recharge-kyivstar',
      '/flow-saint-vincent-and-the-grenadines':
        '/fr/saint-vincent-les-grenadines/recharge-flow',
      '/vodafone-uttar-pradesh-east-india':
        '/fr/inde/recharge-vodafone-uttar-pradesh-east',
      '/digicel-saint-lucia': '/fr/sainte-lucie/recharge-digicel',
      '/digicel-martinique': '/fr/martinique/recharge-digicel',
      '/yoigo-spain': '/fr/espagne/recharge-yoigo',
      '/avantel-colombia': '/fr/colombie/recharge-avantel',
      '/smarts-cheboksary-russian-federation':
        '/fr/russie/recharge-smarts-cheboksary',
      '/orange-france': '/fr/france/recharge-orange',
      '/digicel-bermuda': '/fr/bermudes/recharge-digicel',
      '/vodafone-albania': '/fr/albanie/recharge-vodafone',
      '/megafon-north-west-russian-federation':
        '/fr/russie/recharge-megafon-north-west',
      '/claro-el-salvador': '/fr/le-salvador/recharge-claro',
      '/tim-brazil': '/fr/bresil/recharge-tim',
      '/t-mobile-united-states': '/fr/etats-unis/recharge-t-mobile',
      '/megafon-siberia-russian-federation':
        '/fr/russie/recharge-megafon-siberia',
      '/megacom-kyrgyzstan': '/fr/kirghizistan/recharge-megacom',
      '/bsnl-india': '/fr/inde/recharge-bsnl',
      '/vtr-chile': '/fr/chili/recharge-vtr',
      '/moov-benin': '/fr/benin/recharge-moov',
      '/iusacell-mexico': '/fr/mexique/recharge-iusacell',
      '/airtel-uganda': '/fr/ouganda/recharge-airtel',
      '/polkomtel-plus-poland': '/fr/pologne/recharge-polkomtel-plus',
      '/econet-zimbabwe': '/fr/zimbabwe/recharge-econet',
      '/claro-colombia': '/fr/colombie/recharge-claro',
      '/viva-bolivia': '/fr/bolivie/recharge-viva',
      '/etisalat-sri-lanka': '/fr/sri-lanka/recharge-etisalat',
      '/telemedia-belize': '/fr/belize/recharge-telemedia',
      '/camgsm-cambodia': '/fr/cambodge/recharge-camgsm',
      '/aircel-delhi-india': '/fr/inde/recharge-aircel-delhi',
      '/orange-central-african-republic':
        '/fr/republique-centrafricaine/recharge-orange',
      '/vodafone-punjab-india': '/fr/inde/recharge-vodafone-punjab',
      '/smarts-astrakhan-russian-federation':
        '/fr/russie/recharge-smarts-astrakhan',
      '/claro-ecuador': '/fr/equateur/recharge-claro',
      '/teletalk-bangladesh': '/fr/bangladesh/recharge-teletalk',
      '/bayn-gsm-morocco': '/fr/maroc/recharge-bayn-gsm',
      '/mtn-guinea-bissau': '/fr/guinee-bissau/recharge-mtn',
      '/telcel-mexico': '/fr/mexique/recharge-telcel',
      '/telkom-mobile-south-africa':
        '/fr/ afrique-du-sud/recharge-telkom-mobile',
      '/aircel-india': '/fr/inde/recharge-aircel',
      '/airtel-rwanda': '/fr/rwanda/recharge-airtel',
      '/airtel-kenya': '/fr/kenia/recharge-airtel',
      '/alegro-ecuador': '/fr/equateur/recharge-alegro',
      '/beeline-lao-peoples-democratic-republic': '/fr/laos/recharge-beeline',
      '/cadcomms-cambodia': '/fr/cambodge/recharge-cadcomms',
      '/claro-costa-rica': '/fr/costa-rica/recharge-claro',
      '/claro-dominican-republic': '/fr/republique-dominicaine/recharge-claro',
      '/claro-guatemala': '/fr/guatemala/recharge-claro',
      '/claro-honduras': '/fr/honduras/recharge-claro',
      '/cosmote-romania': '/fr/roumanie/recharge-cosmote',
      '/comcel-colombia': '/fr/colombie/recharge-comcel',
      '/cyfrowy-polsat-poland': '/fr/pologne/recharge-cyfrowy-polsat',
      '/digicel-curacao': '/fr/curacao/recharge-digicel',
      '/digicel-guyana': '/fr/guyane/recharge-digicel',
      '/digicel-grenada': '/fr/grenade/recharge-digicel',
      '/digicel-haiti': '/fr/haiti/recharge-digicel',
      '/digicel-jamaica': '/fr/jamaique/recharge-digicel',
      '/digicel-saint-vincent-and-the-grenadines':
        '/fr/saint-vincent-les-grenadines/recharge-digicel',
      '/evn-telecom-vietnam': '/fr/viet-nam/recharge-evn-telecom',
      '/expresso-telecom-senegal': '/fr/senegal/recharge-expresso-telecom',
      '/full-movil-costa-rica': '/fr/costa-rica/recharge-full-movil',
      '/gt-mobile-spain': '/fr/espagne/recharge-gt-mobile',
      '/gtt-guyana': '/fr/guyane/recharge-gtt',
      '/hutchison-three-indonesia': '/fr/indonesie/recharge-hutchison-three',
      '/kolbi-costa-rica': '/fr/costa-rica/recharge-kolbi',
      '/flow-anguilla': '/fr/anguilla/recharge-flow',
      '/flow-barbados': '/fr/barbades/recharge-flow',
      '/flow-antigua-and-barbuda': '/fr/antigua-barbuda/recharge-flow',
      '/flow-cayman-islands': '/fr/iles-caimans/recharge-flow',
      '/flow-dominica': '/fr/dominique/recharge-flow',
      '/flow-grenada': '/fr/grenade/recharge-flow',
      '/flow-saint-kitts-and-nevis':
        '/fr/saint-christophe-nieves/recharge-flow',
      '/flow-jamaica': '/fr/jamaique/recharge-flow',
      '/flow-montserrat': '/fr/montserrat/recharge-flow',
      '/flow-saint-lucia': '/fr/sainte-lucie/recharge-flow',
      '/movicel-angola': '/fr/angola/recharge-movicel',
      '/moun-dominican-republic': '/fr/republique-dominicaine/recharge-moun',
      '/movistar-argentina': '/fr/argentine/recharge-movistar',
      '/movistar-costa-rica': '/fr/costa-rica/recharge-movistar',
      '/movistar-colombia': '/fr/colombie/recharge-movistar',
      '/movistar-guatemala': '/fr/guatemala/recharge-movistar',
      '/movistar-panama': '/fr/panama/recharge-movistar',
      '/mts-armenia': '/fr/armenie/recharge-mts',
      '/netone-zimbabwe': '/fr/zimbabwe/recharge-netone',
      '/orange-dominican-republic':
        '/fr/republique-dominicaine/recharge-orange',
      '/orange-cameroon': '/fr/cameroun/recharge-orange',
      '/orange-guinea': '/fr/guinee/recharge-orange',
      '/orange-cote-divoire': '/fr/cote-divoire/recharge-orange',
      '/orange-congo-the-democratic-republic-of-the':
        '/fr/congo/recharge-orange',
      '/telkom-kenya': '/fr/kenia/recharge-telkom',
      '/tigo-guatemala': '/fr/guatemala/recharge-tigo',
      '/tigo-honduras': '/fr/honduras/recharge-tigo',
      '/t-mobile-poland': '/fr/pologne/recharge-t-mobile',
      '/togocel-togo': '/fr/togo/recharge-togocel',
      '/uninor-india': '/fr/inde/recharge-uninor',
      '/brasil-telecom-brazil': '/fr/bresil/recharge-brasil-telecom',
      '/claro-jamaica': '/fr/jamaique/recharge-claro',
      '/digicel-honduras': '/fr/honduras/recharge-digicel',
      '/easy-go-united-states': '/fr/etats-unis/recharge-easy-go',
      '/h2o-united-states': '/fr/etats-unis/recharge-h2o',
      '/voila-haiti': '/fr/haiti/recharge-voila',
      '/idirect-dial-united-states': '/fr/etats-unis/recharge-idirect-dial',
      '/myphone2go-ild-united-states': '/fr/etats-unis/recharge-myphone2go-ild',
      '/net-10-united-states': '/fr/etats-unis/recharge-net-10',
      '/nextel-mexico': '/fr/mexique/recharge-nextel',
      '/rok-mobile-united-states': '/fr/etats-unis/recharge-rok-mobile',
      '/smart-philippines': '/fr/philipines/recharge-smart',
      '/talk-talk-now-united-states': '/fr/etats-unis/recharge-talk-talk-now',
      '/terracom-united-states': '/fr/etats-unis/recharge-terracom',
      '/ucell-uzbekistan': '/fr/ouzbekistan/recharge-ucell',
      '/yourtel-united-states': '/fr/etats-unis/recharge-yourtel',
      '/mtn-zambia': '/fr/zambie/recharge-mtn',
      '/zantel-tanzania-united-republic-of': '/fr/tanzanie/recharge-zantel',
      '/vodafone-kktc-turkey': '/fr/turquie/recharge-vodafone-kktc',
      '/univision-united-states': '/fr/etats-unis/recharge-univision',
      '/gt-mobile-france': '/fr/france/recharge-gt-mobile',
      '/bali-georgia': '/fr/georgie/recharge-bali',
      '/onevip-macedonia': '/fr/macedoine/recharge-onevip',
      '/tele2-lithuania': '/fr/lithuanie/recharge-tele2',
      '/life-belarus': '/fr/belarus/recharge-life',
      '/mts-belarus': '/fr/belarus/recharge-mts',
      '/airtel-madagascar': '/fr/madagascar/recharge-airtel',
      '/smart-gold-philippines': '/fr/philipines/recharge-smart-gold',
      '/smart-bro-philippines': '/fr/philipines/recharge-smart-bro',
      '/beeline-uzbekistan': '/fr/ouzbekistan/recharge-beeline',
      '/movistar-uruguay': '/fr/uruguay/recharge-movistar',
      '/glo-benin': '/fr/benin/recharge-glo',
      '/digicel-french-guiana': '/fr/guinee-francaise/recharge-digicel',
      '/orange-uganda': '/fr/ouganda/recharge-orange',
      '/t-mobile-united-kingdom': '/fr/royaume-uni/recharge-t-mobile',
      '/sfr-france': '/fr/france/recharge-sfr',
      '/bob-austria': '/fr/autriche/recharge-bob',
      '/b-free-austria': '/fr/autriche/recharge-b-free',
      '/tele-dot-ring-austria': '/fr/autriche/recharge-telering',
      '/lycamobile-austria': '/fr/autriche/recharge-lycamobile',
      '/nimm3-austria': '/fr/autriche/recharge-nimm3',
      '/3reload-austria': '/fr/autriche/recharge-3reload',
      '/vectone-austria': '/fr/autriche/recharge-vectone',
      '/eety-austria': '/fr/autriche/recharge-eety',
      '/ee-united-kingdom': '/fr/royaume-uni/recharge-ee',
      '/cricket-paygo-united-states': '/fr/etats-unis/recharge-cricket-paygo',
      '/boost-united-states': '/fr/etats-unis/recharge-boost',
      '/du-more-united-arab-emirates':
        '/fr/emirats-arabes-unis/recharge-du-more',
      '/kcell-kazakhstan': '/fr/kazakhstan/recharge-kcell',
      '/altel-kazakhstan': '/fr/kazakhstan/recharge-altel',
      '/activ-kazakhstan': '/fr/kazakhstan/recharge-activ',
      '/beeline-kazakhstan': '/fr/kazakhstan/recharge-beeline',
      '/tele2-kazakhstan': '/fr/kazakhstan/recharge-tele2',
      '/airtel-zambia': '/fr/zambie/recharge-airtel',
      '/airtel-tanzania-united-republic-of': '/fr/tanzanie/recharge-airtel',
      '/megafon-center-south-russian-federation':
        '/fr/russie/recharge-megafon-center-south',
      '/claro-paraguay': '/fr/paraguay/recharge-claro',
      '/ooredoo-myanmar': '/fr/myanmar/recharge-ooredoo',
      '/mec-myanmar': '/fr/myanmar/recharge-mec',
      '/mcel-mozambique': '/fr/zambie/recharge-mcel',
      '/airtel-malawi': '/fr/malawi/recharge-airtel',
      '/aircel-uttar-pradesh-west-india':
        '/fr/inde/recharge-aircel-uttar-pradesh-west',
      '/virgin-mobile-mexico': '/fr/mexique/recharge-virgin-mobile',
      '/maz-mexico': '/fr/mexique/recharge-maz',
      '/cierto-mexico': '/fr/mexique/recharge-cierto',
      '/alo-mexico': '/fr/mexique/recharge-alo',
      '/touch-lebanon': '/fr/liban/recharge-touch',
      '/alfa-lebanon': '/fr/liban/recharge-alfa',
      '/aircel-kerala-india': '/fr/inde/recharge-aircel-kerala',
      '/tuenti-ecuador': '/fr/equateur/recharge-tuenti',
      '/smart-cambodia': '/fr/cambodge/recharge-smart',
      '/zain-bahrain': '/fr/bahrein/recharge-zain',
      '/viva-bahrain': '/fr/bahrein/recharge-viva',
      '/freedom-mobile-canada': '/fr/canada/recharge-freedom-mobile',
      '/virgin-canada': '/fr/canada/recharge-virgin-mobile',
      '/telus-canada': '/fr/canada/recharge-telus-mobilite',
      '/solo-canada': '/fr/canada/recharge-solo',
      '/rogers-canada': '/fr/canada/recharge-rogers-mobile',
      '/publicmobile-canada': '/fr/canada/recharge-public-mobile',
      '/mts-canada': '/fr/canada/recharge-mts',
      '/koodo-canada': '/fr/canada/recharge-koodo',
      '/fido-canada': '/fr/canada/recharge-fido',
      '/chatr-canada': '/fr/canada/recharge-chatr',
      '/bell-canada': '/fr/canada/recharge-bell',
      '/zain-saudi-arabia': '/fr/arabie-saoudite/recharge-zain',
      '/virgin-saudi-arabia': '/fr/arabie-saoudite/recharge-virgin',
      '/stc-saudi-arabia': '/fr/arabie-saoudite/recharge-stc',
      '/mobily-saudi-arabia': '/fr/arabie-saoudite/recharge-mobily',
      '/lebara-saudi-arabia': '/fr/arabie-saoudite/recharge-lebara',
      '/friendi-saudi-arabia': '/fr/arabie-saoudite/recharge-friendi',
      '/allo-saudi-arabia': '/fr/arabie-saoudite/recharge-allo',
      '/virgin-mobile-south-africa':
        '/fr/afrique-du-sud/recharge-virgin-mobile',
      '/vodafone-qatar': '/fr/qatar/recharge-vodafone',
      '/ooredoo-qatar': '/fr/qatar/recharge-ooredoo',
      '/at-and-t-puerto-rico': '/fr/porto-rico/recharge-at-t',
      '/renna-mobile-oman': '/fr/oman/recharge-renna-mobile',
      '/omantel-oman': '/fr/oman/recharge-omantel',
      '/friendi-mobile-oman': '/fr/oman/recharge-friendi-mobile',
      '/movitel-mozambique': '/fr/zambie/recharge-movitel',
      '/yallo-switzerland': '/fr/suisse/recharge-yallo',
      '/swisscom-switzerland': '/fr/moldavie/recharge-swisscom',
      '/sunrise-switzerland': '/fr/suisse/recharge-sunrise',
      '/salt-mobile-switzerland': '/fr/suisse/recharge-salt-mobile',
      '/ortel-mobile-switzerland': '/fr/suisse/recharge-ortel-mobile',
      '/mucho-mobile-switzerland': '/fr/suisse/recharge-mucho-mobile',
      '/lycamobile-switzerland': '/fr/suisse/recharge-lycamobile',
      '/lebara-switzerland': '/fr/suisse/recharge-lebara',
      '/tatem-congo': '/fr/congo/recharge-tatem',
      '/tracfone-united-states': '/fr/etats-unis/recharge-tracfone',
      '/telcel-america-united-states': '/fr/etats-unis/recharge-telcel-america',
      '/sti-mobile-united-states': '/fr/etats-unis/recharge-sti-mobile',
      '/real-mobile-united-states': '/fr/etats-unis/recharge-real-mobile',
      '/ready-mobile-united-states': '/fr/etats-unis/recharge-ready-mobile',
      '/page-plus-united-states': '/fr/etats-unis/recharge-page-plus',
      '/good2go-united-states': '/fr/etats-unis/recharge-good2go',
      '/cintex-wireless-united-states':
        '/fr/etats-unis/recharge-cintex-wireless',
      '/red-pocket-united-states': '/fr/etats-unis/recharge-red-pocket',
      '/urcall-mobile-united-states': '/fr/etats-unis/recharge-urcall-mobile',
      '/life-wireless-united-states': '/fr/etats-unis/recharge-life-wireless',
      '/airvoice-united-states': '/fr/etats-unis/recharge-airvoice',
      '/access-wireless-united-states':
        '/fr/etats-unis/recharge-access-wireless',
      '/etisalat-united-arab-emirates':
        '/fr/emirats-arabes-unis/recharge-etisalat',
      '/smart-cell-nepal': '/fr/nepal/recharge-smart-cell',
      '/tn-mobile-namibia': '/fr/namibie/recharge-tn-mobile',
      '/mtc-namibia': '/fr/namibie/recharge-mtc',
      '/orange-botswana': '/fr/botswana/recharge-orange',
      '/mascom-botswana': '/fr/botswana/recharge-mascom',
      '/bemobile-botswana': '/fr/botswana/recharge-bemobile',
      '/batelco-bahrain': '/fr/bahrein/recharge-batelco',
      '/cvmovel-cape-verde': '/fr/cap-vert/recharge-cvmovel',
      '/three-united-kingdom': '/fr/royaume-uni/recharge-three',
      '/asda-mobile-united-kingdom': '/fr/royaume-uni/recharge-asda-mobile',
      '/giff-gaff-united-kingdom': '/fr/royaume-uni/recharge-giff-gaff',
      '/lycamobile-united-kingdom': '/fr/royaume-uni/recharge-lycamobile',
      '/talk-home-mobile-united-kingdom':
        '/fr/royaume-uni/recharge-talk-home-mobile',
      '/o2-united-kingdom': '/fr/royaume-uni/recharge-o2',
      '/vodafone-united-kingdom': '/fr/royaume-uni/recharge-vodafone',
      '/virgin-mobile-united-kingdom': '/fr/royaume-uni/recharge-virgin-mobile',
      '/you-mobile-spain': '/fr/espagne/recharge-you-mobile',
      '/republica-movile-spain': '/fr/espagne/recharge-republica-movile',
      '/lcr-movile-spain': '/fr/espagne/recharge-lcr-movile',
      '/hablapp-spain': '/fr/espagne/recharge-hablapp',
      '/wind-italy': '/fr/canada/recharge-wind',
      '/vodafone-italy': '/fr/italie/recharge-vodafone',
      '/tim-italy': '/fr/italie/recharge-tim',
      '/3-tre-italy': '/fr/italie/recharge-3-tre',
      '/bluesky-samoa': '/fr/canada/recharge-bluesky',
      '/telenor-serbia': '/fr/serbie/recharge-telenor',
      '/orange-burkina-faso': '/fr/burkina-faso/recharge-orange',
      '/delight-austria': '/fr/autriche/recharge-delight',
      '/t-mobile-austria': '/fr/autriche/recharge-t-mobile',
      '/m-tel-austria': '/fr/autriche/recharge-mtel',
      '/optus-australia': '/fr/australie/recharge-optus',
      '/orange-liberia': '/fr/liberia/recharge-orange',
      '/smile-uganda': '/fr/ouganda/recharge-smile',
      '/talkout-spain': '/fr/espagne/recharge-talkout',
      '/clm-spain': '/fr/espagne/recharge-clm',
      '/uganda-telecom-uganda': '/fr/ouganda/recharge-uganda-telecom',
      '/mundimovil-spain': '/fr/espagne/recharge-mundimovil',
      '/yu-kenya': '/fr/kenia/recharge-yu',
      '/jazzcard-movil-spain': '/fr/espagne/recharge-jazzcard-movil',
      '/smart-tanzania-united-republic-of': '/fr/tanzanie/recharge-smart',
      '/moov-gabon': '/fr/gabon/recharge-moov',
      '/best-movil-spain': '/fr/espagne/recharge-best-movil',
      '/movistar-mexico': '/fr/mexique/recharge-movistar',
      '/vodafone-india': '/fr/inde/recharge-vodafone',
      '/lyca-international-united-states':
        '/fr/etats-unis/recharge-lyca-international',
      '/lycamobile-unlimited-international-united-states':
        '/fr/etats-unis/recharge-lycamobile-unlimited-international',
      '/symacom-france': '/fr/france/recharge-symacom',
      '/la-poste-mobile-france': '/fr/france/recharge-la-poste-mobile',
      '/lebara-united-kingdom': '/fr/royaume-uni/recharge-lebara',
      '/tesco-mobile-united-kingdom': '/fr/royaume-uni/recharge-tesco-mobile',
      '/nrj-france': '/fr/france/recharge-nrj',
      '/metropcs-united-states': '/fr/etats-unis/recharge-metropcs',
      '/virgin-mobile-united-states': '/fr/etats-unis/recharge-virgin-mobile',
      '/lycamobile-portugal': '/fr/portugal/recharge-lycamobile',
      '/vectone-mobile-portugal': '/fr/portugal/recharge-vectone',
      '/vodafone-portugal': '/fr/portugal/recharge-vodafone',
      '/uzo-portugal': '/fr/portugal/recharge-uzo',
      '/nos-portugal': '/fr/portugal/recharge-nos',
      '/moche-portugal': '/fr/portugal/recharge-moche',
      '/meo-portugal': '/fr/portugal/recharge-meo',
      '/ttcl-tanzania-united-republic-of': '/fr/tanzanie/recharge-ttcl',
      '/halotel-tanzania-united-republic-of': '/fr/tanzanie/recharge-halotel',
      '/reliance-jio-india': '/fr/inde/recharge-reliance-jio',
      '/etl-lao-peoples-democratic-republic': '/fr/laos/recharge-etl',
      '/carte-du-monde': '/fr/pays',
    },
    cm: {
      '/rechargez-mtn-cameroun': '/fr/cameroun/recharge-mtn',
    },
    br: {
      '/recharger-tim-bresil': '/fr/bresil/recharge-tim',
    },
    be: {
      '/recharge-inwi': '/fr/maroc/recharge-inwi',
      '/recharge-meditel': '/fr/maroc/recharge-meditel',
      '/recharge-maroc-telecom': '/fr/maroc/recharge-maroc-telecom',
      '/recharger-lebara': '/fr/lebara',
      '/recharger-lycamobile': '/fr/lycamobile',
      '/guild-wars': '/fr-be/cartes-prepayees-jeux-video',
    },
    tg: {
      '/recharger-moov-togo': '/fr/togo/recharge-moov',
    },
    cr: {
      '/recharger-kolbi-costa-rica': '/fr/costa-rica/recharge-kolbi',
    },
    cn: {
      '/recharger-china-mobile': '/fr/chine/recharge-china-mobile',
    },
    mg: {
      '/recharger-mtn-congo': '/fr/congo/recharge-mtn',
    },
    vn: {
      '/recharger-vinaphone-viet-nam': '/fr/viet-nam/recharge-vinaphone',
      '/recharger-mobifone': '/fr/viet-nam/recharge-mobifone',
    },
    us: {
      '/easy-go': '/fr/etats-unis/recharge-easy-go',
      '/methodes-de-paiement': '/fr/moyens-de-paiement',
      '/recharger-t-mobile-usa': '/fr/etats-unis/recharge-t-mobile',
      '/go-smart-united-states': '/fr/etats-unis/recharge-go-smart',
    },
    pl: {
      '/recharger-play': '/fr/pologne/recharge-play',
    },
    nl: {
      '/recharger-bayn-gsm': '/fr/maroc/recharge-bayn-gsm',
      '/recharge-lyca-mobile': '/fr/lycamobile',
    },
    eg: {
      '/recharger-vodafone-egypte': '/fr/egypte/recharge-vodafone',
    },
    pe: {
      '/recharger-claro-perou': '/fr/perou/recharge-claro',
    },
    es: {
      '/recharger-happy-movil': '/fr/espagne/recharge-happy-movil',
      '/recharger-digimobil': '/fr/espagne/recharge-digimobil',
      '/recharger-llamaya-movil': '/fr/espagne/recharge-llamaya',
    },
    tr: {
      '/rechargez-turkcell-turquie': '/fr/turquie/recharge-vodafone',
    },
    sn: {
      '/recharge-lyca-mobile': '/fr/lycamobile',
    },
    tn: {
      '/recharge-lyca-mobile': '/fr/lycamobile',
    },
    de: {
      '/recharge-lyca-mobile': '/fr/lycamobile',
    },
    ci: {
      '/recharge-mtn': '/fr/mtn',
    },
    fr: {
      '/recharge-inwi': '/fr/maroc/recharge-inwi',
    },
  },
  es: {
    '??': {
      '/celcom-malaysia': '/es/malasia/recarga-celcom',
      '/orange-madagascar': '/es/madagascar/recarga-orange',
      '/wataniya-state-of-palestine': '/es/palestina/recarga-wataniya',
      '/dollarphone-united-states': '/es/estados-unidos/recarga-dollarphone',
      '/flow-virgin-islands-british':
        '/es/islas-virgenes-britanicas/recarga-flow',
      '/moov-niger': '/es/niger/recarga-moov',
      '/digicel-nauru': '/es/nauru/recarga-digicel',
      '/expo-mobile-united-states': '/es/estados-unidos/recarga-expo-mobile',
      '/aircel-tamil-nadu-india': '/es/india/recarga-aircel-tamil-nadu',
      '/claro-puerto-rico': '/es/puerto-rico/recarga-claro',
      '/warid-pakistan': '/es/pakistan/recarga-warid',
      '/singtel-singapore': '/es/signapur/recarga-singtel',
      '/vodafone-gujarat-india': '/es/india/recarga-vodafone-gujarat',
      '/une-colombia': '/es/colombia/recarga-une',
      '/econet-burundi': '/es/burundi/recarga-econet',
      '/airtel-india': '/es/australia/recarga-airtel',
      '/ooredoo-tunisia': '/es/tunez/recarga-ooredoo',
      '/vodafone-spain': '/es/espana/recarga-vodafone',
      '/banglalink-bangladesh': '/es/banglasdesh/recarga-banglalink',
      '/videocon-india': '/es/india/recarga-videocon',
      '/movistar-nicaragua': '/es/nicaragua/recarga-movistar',
      '/tigo-bolivia': '/es/bolivia/recarga-tigo',
      '/play-poland': '/es/polonia/recarga-play',
      '/entel-bolivia': '/es/bolivia/recarga-entel',
      '/lebara-spain': '/es/espana/recarga-lebara',
      '/smartfren-indonesia': '/es/indonesia/recarga-smartfren',
      '/xl-indonesia': '/es/indonesia/recarga-xl',
      '/viettel-vietnam': '/es/vietnam/recarga-viettel',
      '/tunisie-telecom-tunisia': '/es/tunez/recarga-tunisie-telecom',
      '/mtn-nigeria': '/es/nigeria/recarga-mtn',
      '/mtnl-india': '/es/india/recarga-mtnl',
      '/tuenti-spain': '/es/espana/recarga-tuenti',
      '/movistar-peru': '/es/peru/recarga-movistar',
      '/cnt-ecuador': '/es/ecuador/recarga-cnt',
      '/robi-axiata-bangladesh': '/es/banglasdesh/recarga-robi-axiata',
      '/etisalat-afghanistan': '/es/afghanistan/recarga-etisalat',
      '/turkcell-turkey': '/es/turquia/recarga-turkcell',
      '/orange-mali': '/es/mali/recarga-orange',
      '/utel-ukraine': '/es/ucrania/recarga-utel',
      '/movistar-el-salvador': '/es/el-salvador/recarga-movistar',
      '/ooredoo-algeria': '/es/algeria/recarga-ooredoo',
      '/megafone-tajikistan': '/es/tayikistan/recarga-megafone',
      '/aircel-hp-india': '/es/india/recarga-aircel-hp',
      '/telenor-india': '/es/india/recarga-telenor',
      '/ais-thailand': '/es/thailandia/recarga-ais',
      '/vodafone-turkey': '/es/turquia/recarga-vodafone',
      '/aircel-chennai-india': '/es/india/recarga-aircel-chennai',
      '/beeline-tajikistan': '/es/tayikistan/recarga-beeline',
      '/utl-mobile-nepal': '/es/nepal/recarga-utl-mobile',
      '/virgin-mobile-colombia': '/es/colombia/recarga-virgin-mobile',
      '/sinpin-united-states': '/es/estados-unidos/recarga-sinpin',
      '/carrefour-spain': '/es/espana/recarga-carrefour',
      '/aircel-assam-india': '/es/india/recarga-aircel-assam',
      '/vodafone-uttar-pradesh-west-india':
        '/es/india/recarga-vodafone-uttar-pradesh-west',
      '/mtn-yemen': '/es/yemen/recarga-mtn',
      '/aircel-bihar-india': '/es/india/recarga-aircel-bihar',
      '/digicel-virgin-islands-british':
        '/es/islas-virgenes-britanicas/recarga-digicel',
      '/mts-india': '/es/india/recarga-mts',
      '/tigo-colombia': '/es/colombia/recarga-tigo',
      '/orange-romania': '/es/rumania/recarga-orange',
      '/axis-indonesia': '/es/indonesia/recarga-axis',
      '/nextel-brazil': '/es/brasil/recarga-nextel',
      '/nationlink-somalia': '/es/mali/recarga-nationlink',
      '/lifecell-ukraine': '/es/ucrania/recarga-lifecell',
      '/idea-india': '/es/australia/recarga-idea',
      '/mtn-rwanda': '/es/ruanda/recarga-mtn',
      '/ultra-mobile-united-states': '/es/estados-unidos/recarga-ultra-mobile',
      '/cable-and-wireless-panama': '/es/panama/recarga-cable-and-wireless',
      '/oi-brazil': '/es/brasil/recarga-oi',
      '/orange-congo': '/es/congo/recarga-orange',
      '/digicel-cayman-islands': '/es/isalas-caiman/recarga-digicel',
      '/happy-movil-spain': '/es/espana/recarga-happy-movil',
      '/unicom-china': '/es/china/recarga-china-unicom',
      '/telenor-myanmar': '/es/myanmar/recarga-telenor',
      '/tata-docomo-india': '/es/india/recarga-tata-docomo',
      '/zong-pakistan': '/es/pakistan/recarga-zong',
      '/digicel-samoa': '/es/samoa/recarga-digicel',
      '/vodafone-kerala-india': '/es/india/recarga-vodafone-kerala',
      '/claro-peru': '/es/peru/recarga-claro',
      '/cubacel-cuba': '/es/cuba/recarga-cuba',
      '/etb-colombia': '/es/colombia/recarga-etb',
      '/mtn-congo': '/es/congo/recarga-mtn',
      '/aircel-rajasthan-india': '/es/india/recarga-aircel-rajasthan',
      '/digicel-antigua-and-barbuda': '/es/antigua-barbuda/recarga-digicel',
      '/natcom-haiti': '/es/haiti/recarga-natcom',
      '/smarts-yaroslavl-russian-federation':
        '/es/rusia/recarga-smarts-yaroslavl',
      '/claro-chile': '/es/chile/recarga-claro',
      '/aircel-mumbai-india': '/es/india/recarga-aircel-mumbai',
      '/tata-indicom-india': '/es/india/recarga-tata-indicom',
      '/vox-paraguay': '/es/paraguay/recarga-vox',
      '/vodafone-kolkata-india': '/es/india/recarga-vodafone-kolkata',
      '/geocell-georgia': '/es/georgia/recarga-geocell',
      '/telekom-mobile-romania': '/es/rumania/recarga-telekom-mobile',
      '/inwi-morocco': '/es/marruecos/recarga-inwi',
      '/indosat-mentari-indonesia': '/es/indonesia/recarga-indosat-mentari',
      '/avea-turkey': '/es/turquia/recarga-avea',
      '/africell-gambia': '/es/gambia/recarga-africell',
      '/nextel-chile': '/es/chile/recarga-nextel',
      '/orange-senegal': '/es/senegal/recarga-orange',
      '/djezzy-algeria': '/es/algeria/recarga-djezzy',
      '/aircel-andhra-pradesh-india': '/es/india/recarga-aircel-andhra-pradesh',
      '/claro-uruguay': '/es/uruguay/recarga-claro',
      '/digicel-tonga': '/es/tonga/recarga-digicel',
      '/orange-poland': '/es/polonia/recarga-orange',
      '/telenor-pakistan': '/es/pakistan/recarga-telenor',
      '/lailai-georgia': '/es/georgia/recarga-lailai',
      '/jazz-pakistan': '/es/pakistan/recarga-jazz',
      '/vodacom-mozambique': '/es/mozambique/recarga-vodacom',
      '/llamaya-spain': '/es/espana/recarga-llamaya',
      '/mtn-cameroon': '/es/camerun/recarga-mtn',
      '/orange-niger': '/es/nigeria/recarga-orange',
      '/masmovil-spain': '/es/espana/recarga-masmovil',
      '/mobilis-algeria': '/es/algeria/recarga-mobilis',
      '/mtn-swaziland': '/es/swazilandia/recarga-mtn',
      '/intertelecom-ukraine': '/es/ucrania/recarga-intertelecom',
      '/bouygues-france': '/es/francia/recarga-bouygues',
      '/movistar-ecuador': '/es/ecuador/recarga-movistar',
      '/dialog-sri-lanka': '/es/sri-lanka/recarga-dialog',
      '/mobifone-vietnam': '/es/vietnam/recarga-mobifone',
      '/megafon-far-east-russian-federation':
        '/es/rusia/recarga-megafon-far-east',
      '/telkomsel-kartu-as-indonesia': '/es/indonesia/recarga-telkomsel',
      '/mts-russian-federation': '/es/rusia/recarga-mts',
      '/digicel-fiji': '/es/fiji/recarga-digicel',
      '/ufone-pakistan': '/es/pakistan/recarga-ufone',
      '/airtel-ghana': '/es/ghana/recarga-airteltigo',
      '/personal-argentina': '/es/argentina/recarga-personal',
      '/hong-da-mobile-spain': '/es/espana/recarga-hong-da-mobile',
      '/beeline-georgia': '/es/georgia/recarga-beeline',
      '/vodafone-rajasthan-india': '/es/india/recarga-vodafone-rajasthan',
      '/digicel-turks-and-caicos-islands':
        '/es/islas-turcas-caicos/recarga-digicel',
      '/ethiotelecom-ethiopia': '/es/etiopia/recarga-ethio-telecom',
      '/asia-cell-telecom-iraq': '/es/irak/recarga-asia-cell-telecom',
      '/telkomsel-simpati-indonesia': '/es/indonesia/recarga-telkomsel',
      '/telecel-zimbabwe': '/es/zimbabue/recarga-telecel',
      '/hablafacil-spain': '/es/espana/recarga-hablafacil',
      '/bmobile-trinidad-and-tobago': '/es/trinidad-tobago/recarga-bmobile',
      '/ucom-armenia': '/es/armenia/recarga-ucom',
      '/myphone-georgia': '/es/georgia/recarga-myphone',
      '/personal-paraguay': '/es/paraguay/recarga-personal',
      '/vodafone-maharashtra-india': '/es/india/recarga-vodafone-maharashtra',
      '/rostelecom-mrf-ural-russian-federation':
        '/es/rusia/recarga-rostelecom-mrf-ural',
      '/mtn-guinea': '/es/guinea/recarga-mtn',
      '/moldcell-moldova-republic-of': '/es/moldova/recarga-moldcell',
      '/telecom-china': '/es/china/recarga-china-telecom',
      '/lycamobile-france': '/es/francia/recarga-lyca',
      '/grameenphone-bangladesh': '/es/banglasdesh/recarga-grameenphone',
      '/digicel-el-salvador': '/es/el-salvador/recarga-digicel',
      '/vietnammobile-vietnam': '/es/vietnam/recarga-vietnammobile',
      '/bani-georgia': '/es/georgia/recarga-bani',
      '/digimobil-spain': '/es/espana/recarga-digimobil',
      '/falabella-chile': '/es/chile/recarga-falabella',
      '/tuyo-movil-costa-rica': '/es/costa-rica/recarga-tuyo-movil',
      '/tigo-el-salvador': '/es/el-salvador/recarga-tigo',
      '/heyah-poland': '/es/polonia/recarga-heyah',
      '/hits-mobile-spain': '/es/espana/recarga-hits-mobile',
      '/megafon-caucasus-russian-federation':
        '/es/rusia/recarga-megafon-caucasus',
      '/true-move-thailand': '/es/thailandia/recarga-true-move',
      '/tigo-paraguay': '/es/paraguay/recarga-tigo',
      '/orange-egypt': '/es/egipto/recarga-orange',
      '/digicel-vanuatu': '/es/vanuatu/recarga-digicel',
      '/magti-gsm-georgia': '/es/georgia/recarga-magti-gsm',
      '/etisalat-egypt': '/es/egipto/recarga-etisalat',
      '/mtn-liberia': '/es/liberia/recarga-mtn',
      '/vodafone-romania': '/es/rumania/recarga-vodafone',
      '/safaricom-kenya': '/es/kenia/recarga-safaricom',
      '/vodafone-fiji': '/es/fiji/recarga-vodafone',
      '/digicel-barbados': '/es/barbados/recarga-digicel',
      '/zamtel-zambia': '/es/zambia/recarga-zamtel',
      '/vodacom-tanzania-united-republic-of': '/es/tanzania/recarga-vodacom',
      '/orange-guinea-bissau': '/es/guinea-bissau/recarga-orange',
      '/telsur-chile': '/es/chile/recarga-telsur',
      '/airtel-nigeria': '/es/nigeria/recarga-airtel',
      '/gmobile-vietnam': '/es/vietnam/recarga-gmobile',
      '/africell-sierra-leone': '/es/sierra-leona/recarga-africell',
      '/mtn-south-africa': '/es/sudafrica/recarga-mtn',
      '/lyca-mobile-united-states': '/es/estados-unidos/recarga-lyca-mobile',
      '/gphone-georgia': '/es/georgia/recarga-gphone',
      '/ntc-gsm-nepal': '/es/nepal/recarga-ntc-gsm',
      '/tcell-tajikistan': '/es/tayikistan/recarga-tcell',
      '/claro-argentina': '/es/argentina/recarga-claro',
      '/beeline-kyrgyzstan': '/es/kirguistan/recarga-beeline',
      '/umniah-jordan': '/es/jordan/recarga-umniah',
      '/jawwal-state-of-palestine': '/es/palestina/recarga-jawwal',
      '/smart-burundi': '/es/burundi/recarga-smart',
      '/africel-uganda': '/es/uganda/recarga-africel',
      '/digicel-saint-kitts-and-nevis':
        '/es/san-cristobal-nieves/recarga-digicel',
      '/hutch-sri-lanka': '/es/sri-lanka/recarga-hutch',
      '/tele2-russian-federation': '/es/rusia/recarga-tele2',
      '/mts-ukraine': '/es/ucrania/recarga-mts',
      '/digicel-dominica': '/es/dominica/recarga-digicel',
      '/kb-impuls-beeline-russian-federation':
        '/es/rusia/recarga-kb-impuls-beeline',
      '/babilonmobile-tajikistan': '/es/tayikistan/recarga-babilonmobile',
      '/btc-bahamas': '/es/bahamas/recarga-btc',
      '/lebara-france': '/es/francia/recarga-lebara',
      '/vodacom-south-africa': '/es/sudafrica/recarga-vodacom',
      '/euskaltel-spain': '/es/espana/recarga-euskaltel',
      '/vodafone-haryana-india': '/es/india/recarga-vodafone-haryana',
      '/glo-ghana': '/es/ghana/recarga-glo',
      '/ncell-nepal': '/es/nepal/recarga-ncell',
      '/zain-kuwait': '/es/kuwait/recarga-zain',
      '/mobile-china': '/es/china/recarga-china-mobile',
      '/megafon-ural-russian-federation': '/es/rusia/recarga-megafon-ural',
      '/roshan-afghanistan': '/es/afghanistan/recarga-roshan',
      '/blau-spain': '/es/espana/recarga-blau',
      '/tigo-ghana': '/es/ghana/recarga-airteltigo',
      '/aircel-punjab-india': '/es/india/recarga-aircel-punjab',
      '/fonex-kyrgyzstan': '/es/kirguistan/recarga-fonex',
      '/unefon-mexico': '/es/mexico/recarga-unefon',
      '/megafon-center-russian-federation': '/es/rusia/recarga-megafon-center',
      '/orange-moldova-republic-of': '/es/moldova/recarga-orange',
      '/aircel-west-bengal-india': '/es/india/recarga-aircel-west-bengal',
      '/at-and-t-united-states': '/es/estados-unidos/recarga-at-t',
      '/mtn-uganda': '/es/uganda/recarga-mtn',
      '/airtel-bangladesh': '/es/banglasdesh/recarga-airtel',
      '/maxis-malaysia': '/es/malasia/recarga-maxis',
      '/megafon-povolzhye-russian-federation':
        '/es/rusia/recarga-megafon-povolzhye',
      '/lyca-mobile-spain': '/es/espana/recarga-lycamobile',
      '/mtn-benin': '/es/benin/recarga-mtn',
      '/etisalat-nigeria': '/es/nigeria/recarga-etisalat',
      '/vodafone-delhi-india': '/es/india/recarga-vodafone-delhi',
      '/digicel-panama': '/es/panama/recarga-digicel',
      '/vodafone-chennai-india': '/es/india/recarga-vodafone-chennai',
      '/umobile-malaysia': '/es/malasia/recarga-umobile',
      '/pureminutes-united-states': '/es/estados-unidos/recarga-pure-minutes',
      '/motiv-russian-federation': '/es/rusia/recarga-motiv',
      '/moov-central-african-republic':
        '/es/republica-centroafricana/recarga-moov',
      '/expresso-ghana': '/es/ghana/recarga-expresso',
      '/vodafone-tamil-nadu-india': '/es/india/recarga-vodafone-tamil-nadu',
      '/mtn-cyprus': '/es/chipre/recarga-mtn',
      '/vodafone-bangalore-india': '/es/india/recarga-vodafone-bangalore',
      '/tigo-senegal': '/es/senegal/recarga-tigo',
      '/vodafone-mumbai-india': '/es/india/recarga-vodafone-mumbai',
      '/digicel-anguilla': '/es/anguilla/recarga-digicel',
      '/tigo-tanzania-united-republic-of': '/es/tanzania/recarga-tigo',
      '/warid-uganda': '/es/uganda/recarga-warid',
      '/digicel-aruba': '/es/aruba/recarga-digicel',
      '/claro-brazil': '/es/brasil/recarga-claro',
      '/uff-movil-colombia': '/es/colombia/recarga-uff-movil',
      '/metfone-cambodia': '/es/camboya/recarga-metfone',
      '/orange-spain': '/es/espana/recarga-orange',
      '/megafon-moscow-russian-federation': '/es/rusia/recarga-megafon-moscow',
      '/aircel-karnataka-india': '/es/india/recarga-aircel-karnataka',
      '/mobitel-sri-lanka': '/es/sri-lanka/recarga-mobitel',
      '/ceria-indonesia': '/es/indonesia/recarga-ceria',
      '/virgin-mobile-chile': '/es/chile/recarga-virgin-mobile',
      '/vivo-brazil': '/es/brasil/recarga-vivo',
      '/starhub-singapore': '/es/signapur/recarga-starhub',
      '/glo-nigeria': '/es/nigeria/recarga-glo',
      '/orange-tunisia': '/es/tunez/recarga-orange',
      '/suntel-sri-lanka': '/es/sri-lanka/recarga-suntel',
      '/movistar-chile': '/es/chile/recarga-movistar',
      '/digicel-papua-new-guinea': '/es/papua-nueva-guinea/recarga-digicel',
      '/digicel-trinidad-and-tobago': '/es/trinidad-tobago/recarga-digicel',
      '/smart-uganda': '/es/uganda/recarga-smart',
      '/tricom-dominican-republic': '/es/republica-dominicana/recarga-tricom',
      '/aircel-north-east-india': '/es/india/recarga-aircel-north-east',
      '/onatel-burkina-faso': '/es/burkina-faso/recarga-onatel',
      '/globe-philippines': '/es/filipinas/recarga-globe',
      '/vodafone-andhra-pradesh-india':
        '/es/india/recarga-vodafone-andhra-pradesh',
      '/orange-kenya': '/es/kenia/recarga-orange',
      '/tigo-rwanda': '/es/ruanda/recarga-tigo',
      '/vinaphone-vietnam': '/es/vietnam/recarga-vinaphone',
      '/vodafone-bengal-india': '/es/india/recarga-vodafone-bengal',
      '/moov-cote-divoire': '/es/costa-de-marfil/recarga-moov',
      '/mpt-myanmar': '/es/myanmar/recarga-mpt',
      '/vodafone-ghana': '/es/ghana/recarga-vodafone',
      '/orange-morocco': '/es/marruecos/recarga-orange',
      '/citycell-bangladesh': '/es/banglasdesh/recarga-citycell',
      '/flow-turks-and-caicos-islands': '/es/islas-turcas-caicos/recarga-flow',
      '/maroc-telecom-morocco': '/es/marruecos/recarga-maroc-telecom',
      '/s-fone-vietnam': '/es/vietnam/recarga-s-fone',
      '/digi-malaysia': '/es/malasia/recarga-digi',
      '/tkmobile-tajikistan': '/es/tayikistan/recarga-tkmobile',
      '/nextel-argentina': '/es/argentina/recarga-nextel',
      '/aircel-uttar-pradesh-east-india':
        '/es/india/recarga-aircel-uttar-pradesh-east',
      '/airtel-sri-lanka': '/es/sri-lanka/recarga-airtel',
      '/m1-singapore': '/es/signapur/recarga-m1',
      '/aircel-jk-india': '/es/india/recarga-aircel-jk',
      '/simple-mobile-united-states':
        '/es/estados-unidos/recarga-simple-mobile',
      '/qcell-gambia': '/es/gambia/recarga-qcell',
      '/beeline-ukraine': '/es/ucrania/recarga-beeline',
      '/dtac-thailand': '/es/thailandia/recarga-dtac',
      '/digicel-suriname': '/es/suriname/recarga-digicel',
      '/cell-c-south-africa': '/es/sudafrica/recarga-cell-c',
      '/tak-tak-poland': '/es/polonia/recarga-tak-tak',
      '/cellcom-guinea': '/es/guinea/recarga-cellcom',
      '/mobilcat-spain': '/es/espana/recarga-mobilcat',
      '/indosat-im3-indonesia': '/es/indonesia/recarga-indosat-im3',
      '/aircel-kolkata-india': '/es/india/recarga-aircel-kolkata',
      '/sun-cellular-philippines': '/es/filipinas/recarga-sun-cellular',
      '/o-kyrgyzstan': '/es/kirguistan/recarga-o',
      '/verizon-united-states': '/es/estados-unidos/recarga-verizon',
      '/dth-tata-sky-india': '/es/india/recarga-dth tata-sky',
      '/claro-nicaragua': '/es/nicaragua/recarga-claro',
      '/viva-kuwait': '/es/kuwait/recarga-viva',
      '/aircel-orissa-india': '/es/india/recarga-aircel-orissa',
      '/smarts-samara-russian-federation': '/es/rusia/recarga-smarts-samara',
      '/afghan-wireless-afghanistan': '/es/afghanistan/recarga-afghan-wireless',
      '/reliance-india': '/es/india/recarga-reliance',
      '/mtn-afghanistan': '/es/afghanistan/recarga-mtn',
      '/three-telecom-indonesia': '/es/indonesia/recarga-three-telecom',
      '/bluesky-american-samoa': '/es/samoa/recarga-bluesky',
      '/mtn-cote-divoire': '/es/costa-de-marfil/recarga-mtn',
      '/entel-chile': '/es/chile/recarga-entel',
      '/viva-dominican-republic': '/es/republica-dominicana/recarga-viva',
      '/tunetalk-malaysia': '/es/malasia/recarga-tunetalk',
      '/mtn-ghana': '/es/ghana/recarga-mtn',
      '/claro-panama': '/es/panama/recarga-claro',
      '/vivacell-mts-armenia': '/es/armenia/recarga-vivacell-mts',
      '/aircel-maharashtra-india': '/es/india/recarga-aircel-maharashtra',
      '/vodafone-egypt': '/es/egipto/recarga-vodafone',
      '/kyivstar-ukraine': '/es/ucrania/recarga-kyivstar',
      '/flow-saint-vincent-and-the-grenadines':
        '/es/saint-vincent-las-granadinas/recarga-flow',
      '/vodafone-uttar-pradesh-east-india':
        '/es/india/recarga-vodafone-uttar-pradesh-east',
      '/digicel-saint-lucia': '/es/santa-lucia/recarga-digicel',
      '/digicel-martinique': '/es/martinica/recarga-digicel',
      '/yoigo-spain': '/es/espana/recarga-yoigo',
      '/avantel-colombia': '/es/colombia/recarga-avantel',
      '/smarts-cheboksary-russian-federation':
        '/es/rusia/recarga-smarts-cheboksary',
      '/orange-france': '/es/francia/recarga-orange',
      '/digicel-bermuda': '/es/bermuda/recarga-digicel',
      '/vodafone-albania': '/es/albania/recarga-vodafone',
      '/megafon-north-west-russian-federation':
        '/es/rusia/recarga-megafon-north-west',
      '/claro-el-salvador': '/es/el-salvador/recarga-claro',
      '/tim-brazil': '/es/brasil/recarga-tim',
      '/t-mobile-united-states': '/es/estados-unidos/recarga-t-mobile',
      '/megafon-siberia-russian-federation':
        '/es/rusia/recarga-megafon-siberia',
      '/megacom-kyrgyzstan': '/es/kirguistan/recarga-megacom',
      '/bsnl-india': '/es/india/recarga-bsnl',
      '/vtr-chile': '/es/chile/recarga-vtr',
      '/moov-benin': '/es/benin/recarga-moov',
      '/iusacell-mexico': '/es/mexico/recarga-iusacell',
      '/airtel-uganda': '/es/uganda/recarga-airtel',
      '/polkomtel-plus-poland': '/es/polonia/recarga-polkomtel-plus',
      '/econet-zimbabwe': '/es/zimbabue/recarga-econet',
      '/claro-colombia': '/es/colombia/recarga-claro',
      '/viva-bolivia': '/es/bolivia/recarga-viva',
      '/etisalat-sri-lanka': '/es/sri-lanka/recarga-etisalat',
      '/telemedia-belize': '/es/belice/recarga-telemedia',
      '/camgsm-cambodia': '/es/camboya/recarga-camgsm',
      '/aircel-delhi-india': '/es/india/recarga-aircel-delhi',
      '/orange-central-african-republic':
        '/es/republica-centroafricana/recarga-orange',
      '/vodafone-punjab-india': '/es/india/recarga-vodafone-punjab',
      '/smarts-astrakhan-russian-federation':
        '/es/rusia/recarga-smarts-astrakhan',
      '/claro-ecuador': '/es/ecuador/recarga-claro',
      '/teletalk-bangladesh': '/es/banglasdesh/recarga-teletalk',
      '/bayn-gsm-morocco': '/es/marruecos/recarga-bayn-gsm',
      '/mtn-guinea-bissau': '/es/guinea-bissau/recarga-mtn',
      '/telcel-mexico': '/es/mexico/recarga-telcel',
      '/telkom-mobile-south-africa': '/es/sudafrica/recarga-telkom-mobile',
      '/aircel-india': '/es/india/recarga-aircel',
      '/airtel-rwanda': '/es/ruanda/recarga-airtel',
      '/airtel-kenya': '/es/kenia/recarga-airtel',
      '/alegro-ecuador': '/es/ecuador/recarga-alegro',
      '/beeline-lao-peoples-democratic-republic': '/es/laos/recarga-beeline',
      '/cadcomms-cambodia': '/es/camboya/recarga-cadcomms',
      '/claro-costa-rica': '/es/costa-rica/recarga-claro',
      '/claro-dominican-republic': '/es/republica-dominicana/recarga-claro',
      '/claro-guatemala': '/es/guatemala/recarga-claro',
      '/claro-honduras': '/es/honduras/recarga-claro',
      '/cosmote-romania': '/es/rumania/recarga-cosmote',
      '/comcel-colombia': '/es/colombia/recarga-comcel',
      '/cyfrowy-polsat-poland': '/es/polonia/recarga-cyfrowy-polsat',
      '/digicel-curacao': '/es/curacao/recarga-digicel',
      '/digicel-guyana': '/es/guyana/recarga-digicel',
      '/digicel-grenada': '/es/granada/recarga-digicel',
      '/digicel-haiti': '/es/haiti/recarga-digicel',
      '/digicel-jamaica': '/es/jamaica/recarga-digicel',
      '/digicel-saint-vincent-and-the-grenadines':
        '/es/saint-vincent-las-granadinas/recarga-digicel',
      '/evn-telecom-vietnam': '/es/vietnam/recarga-evn-telecom',
      '/expresso-telecom-senegal': '/es/senegal/recarga-expresso-telecom',
      '/full-movil-costa-rica': '/es/costa-rica/recarga-full-movil',
      '/gt-mobile-spain': '/es/espana/recarga-gt-mobile',
      '/gtt-guyana': '/es/guyana/recarga-gtt',
      '/hutchison-three-indonesia': '/es/indonesia/recarga-hutchison-three',
      '/kolbi-costa-rica': '/es/costa-rica/recarga-kolbi',
      '/flow-anguilla': '/es/anguilla/recarga-flow',
      '/flow-barbados': '/es/barbados/recarga-flow',
      '/flow-antigua-and-barbuda': '/es/antigua-barbuda/recarga-flow',
      '/flow-cayman-islands': '/es/isalas-caiman/recarga-flow',
      '/flow-dominica': '/es/dominica/recarga-flow',
      '/flow-grenada': '/es/granada/recarga-flow',
      '/flow-saint-kitts-and-nevis': '/es/san-cristobal-nieves/recarga-flow',
      '/flow-jamaica': '/es/jamaica/recarga-flow',
      '/flow-montserrat': '/es/montserrat/recarga-flow',
      '/flow-saint-lucia': '/es/santa-lucia/recarga-flow',
      '/movicel-angola': '/es/angola/recarga-movicel',
      '/moun-dominican-republic': '/es/republica-dominicana/recarga-moun',
      '/movistar-argentina': '/es/argentina/recarga-movistar',
      '/movistar-costa-rica': '/es/costa-rica/recarga-movistar',
      '/movistar-colombia': '/es/colombia/recarga-movistar',
      '/movistar-guatemala': '/es/guatemala/recarga-movistar',
      '/movistar-panama': '/es/panama/recarga-movistar',
      '/mts-armenia': '/es/armenia/recarga-mts',
      '/netone-zimbabwe': '/es/zimbabue/recarga-netone',
      '/orange-dominican-republic': '/es/republica-dominicana/recarga-orange',
      '/orange-cameroon': '/es/camerun/recarga-orange',
      '/orange-guinea': '/es/guinea/recarga-orange',
      '/orange-cote-divoire': '/es/costa-de-marfil/recarga-orange',
      '/orange-congo-the-democratic-republic-of-the':
        '/es/congo/recarga-orange',
      '/telkom-kenya': '/es/kenia/recarga-telkom',
      '/tigo-guatemala': '/es/guatemala/recarga-tigo',
      '/tigo-honduras': '/es/honduras/recarga-tigo',
      '/t-mobile-poland': '/es/polonia/recarga-t-mobile',
      '/togocel-togo': '/es/togo/recarga-togocel',
      '/uninor-india': '/es/india/recarga-uninor',
      '/brasil-telecom-brazil': '/es/brasil/recarga-brasil-telecom',
      '/claro-jamaica': '/es/jamaica/recarga-claro',
      '/digicel-honduras': '/es/honduras/recarga-digicel',
      '/easy-go-united-states': '/es/estados-unidos/recarga-easy-go',
      '/h2o-united-states': '/es/estados-unidos/recarga-h2o',
      '/voila-haiti': '/es/haiti/recarga-voila',
      '/idirect-dial-united-states': '/es/estados-unidos/recarga-idirect-dial',
      '/myphone2go-ild-united-states':
        '/es/estados-unidos/recarga-myphone2go-ild',
      '/net-10-united-states': '/es/estados-unidos/recarga-net 10',
      '/nextel-mexico': '/es/mexico/recarga-nextel',
      '/rok-mobile-united-states': '/es/estados-unidos/recarga-rok-mobile',
      '/smart-philippines': '/es/filipinas/recarga-smart',
      '/talk-talk-now-united-states':
        '/es/estados-unidos/recarga-talk-talk-now',
      '/terracom-united-states': '/es/estados-unidos/recarga-terracom',
      '/ucell-uzbekistan': '/es/uzbekistan/recarga-ucell',
      '/yourtel-united-states': '/es/estados-unidos/recarga-yourtel',
      '/mtn-zambia': '/es/zambia/recarga-mtn',
      '/zantel-tanzania-united-republic-of': '/es/tanzania/recarga-zantel',
      '/vodafone-kktc-turkey': '/es/turquia/recarga-vodafone-kktc',
      '/univision-united-states': '/es/estados-unidos/recarga-univision',
      '/gt-mobile-france': '/es/francia/recarga-gt-mobile',
      '/bali-georgia': '/es/georgia/recarga-bali',
      '/onevip-macedonia': '/es/macedonia/recarga-onevip',
      '/tele2-lithuania': '/es/lituania/recarga-tele2',
      '/life-belarus': '/es/bielorrusia/recarga-life',
      '/mts-belarus': '/es/bielorrusia/recarga-mts',
      '/airtel-madagascar': '/es/madagascar/recarga-airtel',
      '/smart-gold-philippines': '/es/filipinas/recarga-smart-gold',
      '/smart-bro-philippines': '/es/filipinas/recarga-smart-bro',
      '/beeline-uzbekistan': '/es/uzbekistan/recarga-beeline',
      '/movistar-uruguay': '/es/uruguay/recarga-movistar',
      '/glo-benin': '/es/benin/recarga-glo',
      '/digicel-french-guiana': '/es/guayana-francesa/recarga-digicel',
      '/orange-uganda': '/es/uganda/recarga-orange',
      '/t-mobile-united-kingdom': '/es/reino-unido/recarga-t-mobile',
      '/sfr-france': '/es/francia/recarga-sfr',
      '/bob-austria': '/es/austria/recarga-bob',
      '/b-free-austria': '/es/austria/recarga-b-free',
      '/tele-dot-ring-austria': '/es/austria/recarga-telering',
      '/lycamobile-austria': '/es/austria/recarga-lycamobile',
      '/nimm3-austria': '/es/austria/recarga-nimm3',
      '/3reload-austria': '/es/austria/recarga-3reload',
      '/vectone-austria': '/es/austria/recarga-vectone',
      '/eety-austria': '/es/austria/recarga-eety',
      '/ee-united-kingdom': '/es/reino-unido/recarga-ee',
      '/cricket-paygo-united-states':
        '/es/estados-unidos/recarga-cricket-paygo',
      '/boost-united-states': '/es/estados-unidos/recarga-boost',
      '/du-more-united-arab-emirates':
        '/es/emiratos-arabes-unidos/recarga-du-more',
      '/kcell-kazakhstan': '/es/kazajstan/recarga-kcell',
      '/altel-kazakhstan': '/es/kazajstan/recarga-altel',
      '/activ-kazakhstan': '/es/kazajstan/recarga-activ',
      '/beeline-kazakhstan': '/es/kazajstan/recarga-beeline',
      '/tele2-kazakhstan': '/es/kazajstan/recarga-tele2',
      '/airtel-zambia': '/es/zambia/recarga-airtel',
      '/airtel-tanzania-united-republic-of': '/es/tanzania/recarga-airtel',
      '/megafon-center-south-russian-federation':
        '/es/rusia/recarga-megafon-center-south',
      '/claro-paraguay': '/es/paraguay/recarga-claro',
      '/ooredoo-myanmar': '/es/myanmar/recarga-ooredoo',
      '/mec-myanmar': '/es/myanmar/recarga-mec',
      '/airtel-malawi': '/es/malawi/recarga-airtel',
      '/aircel-uttar-pradesh-west-india':
        '/es/india/recarga-aircel-uttar-pradesh-west',
      '/virgin-mobile-mexico': '/es/mexico/recarga-virgin-mobile',
      '/maz-mexico': '/es/mexico/recarga-maz',
      '/cierto-mexico': '/es/mexico/recarga-cierto',
      '/alo-mexico': '/es/mexico/recarga-alo',
      '/touch-lebanon': '/es/libano/recarga-touch',
      '/alfa-lebanon': '/es/libano/recarga-alfa',
      '/aircel-kerala-india': '/es/india/recarga-aircel-kerala',
      '/tuenti-ecuador': '/es/ecuador/recarga-tuenti',
      '/smart-cambodia': '/es/camboya/recarga-smart',
      '/zain-bahrain': '/es/bahrein/recarga-zain',
      '/viva-bahrain': '/es/bahrein/recarga-viva',
      '/freedom-mobile-canada': '/es/canada/recarga-freedom-mobile',
      '/virgin-canada': '/es/canada/recarga-virgin',
      '/telus-canada': '/es/canada/recarga-telus',
      '/solo-canada': '/es/canada/recarga-solo',
      '/rogers-canada': '/es/canada/recarga-rogers',
      '/publicmobile-canada': '/es/canada/recarga-public-mobile',
      '/mts-canada': '/es/canada/recarga-mts',
      '/koodo-canada': '/es/canada/recarga-koodo',
      '/fido-canada': '/es/canada/recarga-fido',
      '/chatr-canada': '/es/canada/recarga-chatr',
      '/bell-canada': '/es/canada/recarga-bell',
      '/zain-saudi-arabia': '/es/saudita-arabia/recarga-zain',
      '/virgin-saudi-arabia': '/es/saudita-arabia/recarga-virgin',
      '/stc-saudi-arabia': '/es/saudita-arabia/recarga-stc',
      '/mobily-saudi-arabia': '/es/saudita-arabia/recarga-mobily',
      '/lebara-saudi-arabia': '/es/saudita-arabia/recarga-lebara',
      '/friendi-saudi-arabia': '/es/saudita-arabia/recarga-friendi',
      '/allo-saudi-arabia': '/es/saudita-arabia/recarga-allo',
      '/virgin-mobile-south-africa': '/es/sudafrica/recarga-virgin-mobile',
      '/vodafone-qatar': '/es/katar/recarga-vodafone',
      '/ooredoo-qatar': '/es/katar/recarga-ooredoo',
      '/at-and-t-puerto-rico': '/es/puerto-rico/recarga-at-t',
      '/renna-mobile-oman': '/es/oman/recarga-renna-mobile',
      '/omantel-oman': '/es/oman/recarga-omantel',
      '/friendi-mobile-oman': '/es/oman/recarga-friendi-mobile',
      '/movitel-mozambique': '/es/zambia/recarga-movitel',
      '/yallo-switzerland': '/es/switzerland/recarga-yallo',
      '/swisscom-switzerland': '/es/moldova/recarga-swisscom',
      '/sunrise-switzerland': '/es/switzerland/recarga-sunrise',
      '/salt-mobile-switzerland': '/es/switzerland/recarga-salt-mobile',
      '/ortel-mobile-switzerland': '/es/switzerland/recarga-ortel-mobile',
      '/mucho-mobile-switzerland': '/es/switzerland/recarga-mucho-mobile',
      '/lycamobile-switzerland': '/es/switzerland/recarga-lycamobile',
      '/lebara-switzerland': '/es/switzerland/recarga-lebara',
      '/tatem-congo': '/es/congo/recarga-tatem',
      '/tracfone-united-states': '/es/estados-unidos/recarga-tracfone',
      '/telcel-america-united-states':
        '/es/estados-unidos/recarga-telcel-america',
      '/sti-mobile-united-states': '/es/estados-unidos/recarga-sti-mobile',
      '/real-mobile-united-states': '/es/estados-unidos/recarga-real-mobile',
      '/ready-mobile-united-states': '/es/estados-unidos/recarga-ready-mobile',
      '/page-plus-united-states': '/es/estados-unidos/recarga-page-plus',
      '/good2go-united-states': '/es/estados-unidos/recarga-good2go',
      '/cintex-wireless-united-states':
        '/es/estados-unidos/recarga-cintex-wireless',
      '/red-pocket-united-states': '/es/estados-unidos/recarga-red-pocket',
      '/urcall-mobile-united-states':
        '/es/estados-unidos/recarga-urcall-mobile',
      '/life-wireless-united-states':
        '/es/estados-unidos/recarga-life-wireless',
      '/airvoice-united-states': '/es/estados-unidos/recarga-airvoice',
      '/access-wireless-united-states':
        '/es/estados-unidos/recarga-access-wireless',
      '/etisalat-united-arab-emirates':
        '/es/emiratos-arabes-unidos/recarga-etisalat',
      '/smart-cell-nepal': '/es/nepal/recarga-smart-cell',
      '/tn-mobile-namibia': '/es/namibia/recarga-tn-mobile',
      '/mtc-namibia': '/es/namibia/recarga-mtc',
      '/orange-botswana': '/es/botswana/recarga-orange',
      '/mascom-botswana': '/es/botswana/recarga-mascom',
      '/bemobile-botswana': '/es/botswana/recarga-bemobile',
      '/batelco-bahrain': '/es/bahrein/recarga-batelco',
      '/cvmovel-cape-verde': '/es/cabo-verde/recarga-cvmovel',
      '/three-united-kingdom': '/es/reino-unido/recarga-three',
      '/asda-mobile-united-kingdom': '/es/reino-unido/recarga-asda-mobile',
      '/giff-gaff-united-kingdom': '/es/reino-unido/recarga-giff-gaff',
      '/lycamobile-united-kingdom': '/es/reino-unido/recarga-lycamobile',
      '/talk-home-mobile-united-kingdom':
        '/es/reino-unido/recarga-talk-home-mobile',
      '/o2-united-kingdom': '/es/reino-unido/recarga-o2',
      '/vodafone-united-kingdom': '/es/reino-unido/recarga-vodafone',
      '/virgin-mobile-united-kingdom': '/es/reino-unido/recarga-virgin-mobile',
      '/you-mobile-spain': '/es/espana/recarga-you-mobile',
      '/republica-movile-spain': '/es/espana/recarga-republica-movile',
      '/lcr-movile-spain': '/es/espana/recarga-lcr-movile',
      '/hablapp-spain': '/es/espana/recarga-hablapp',
      '/wind-italy': '/es/canada/recarga-wind',
      '/vodafone-italy': '/es/italia/recarga-vodafone',
      '/tim-italy': '/es/italia/recarga-tim',
      '/3-tre-italy': '/es/italia/recarga-3-tre',
      '/bluesky-samoa': '/es/canada/recarga-bluesky',
      '/telenor-serbia': '/es/serbia/recarga-telenor',
      '/orange-burkina-faso': '/es/burkina-faso/recarga-orange',
      '/delight-austria': '/es/recarga-delight',
      '/t-mobile-austria': '/es/austria/recarga-t-mobile',
      '/m-tel-austria': '/es/austria/recarga-m-tel',
      '/optus-australia': '/es/australia/recarga-optus',
      '/orange-liberia': '/es/liberia/recarga-orange',
      '/smile-uganda': '/es/uganda/recarga-smile',
      '/talkout-spain': '/es/espana/recarga-talkout',
      '/clm-spain': '/es/espana/recarga-clm',
      '/uganda-telecom-uganda': '/es/uganda/recarga-uganda-telecom',
      '/mundimovil-spain': '/es/espana/recarga-mundi-movil',
      '/yu-kenya': '/es/kenia/recarga-yu',
      '/jazzcard-movil-spain': '/es/espana/recarga-jazzcard-movil',
      '/smart-tanzania-united-republic-of': '/es/tanzania/recarga-smart',
      '/moov-gabon': '/es/gabon/recarga-moov',
      '/best-movil-spain': '/es/espana/recarga-best-movil',
      '/movistar-mexico': '/es/mexico/recarga-movistar',
      '/vodafone-india': '/es/india/recarga-vodafone',
      '/lyca-international-united-states':
        '/es/estados-unidos/recarga-lyca-international',
      '/lycamobile-unlimited-international-united-states':
        '/es/estados-unidos/recarga-lycamobile-unlimited-international',
      '/symacom-france': '/es/francia/recarga-symacom',
      '/la-poste-mobile-france': '/es/francia/recarga-la-poste-mobile',
      '/lebara-united-kingdom': '/es/reino-unido/recarga-lebara',
      '/tesco-mobile-united-kingdom': '/es/reino-unido/recarga-tesco-mobile',
      '/nrj-france': '/es/francia/recarga-nrj',
      '/metropcs-united-states': '/es/estados-unidos/recarga-metropcs',
      '/virgin-mobile-united-states':
        '/es/estados-unidos/recarga-virgin-mobile',
      '/lycamobile-portugal': '/es/portugal/recarga-lycamobile',
      '/vectone-mobile-portugal': '/es/portugal/recarga-vectone-mobile',
      '/vodafone-portugal': '/es/portugal/recarga-vodafone',
      '/uzo-portugal': '/es/portugal/recarga-uzo',
      '/nos-portugal': '/es/portugal/recarga-nos',
      '/moche-portugal': '/es/portugal/recarga-moche',
      '/meo-portugal': '/es/portugal/recarga/meo',
      '/ttcl-tanzania-united-republic-of': '/es/tanzania/recarga-ttcl',
      '/halotel-tanzania-united-republic-of': '/es/tanzania/recarga-halotel',
      '/reliance-jio-india': '/es/india/recarga-reliance',
      '/etl-lao-peoples-democratic-republic': '/es/laos/recarga-etl',
      '/mapamundi': '/es/paises',
      '/preguntas-frecuentas': '/es/preguntas-frecuentes',
    },
    ec: {
      '/recargas-cnt-mobile-ecuador': '/es/ecuador/recarga-cnt',
    },
    mx: {
      '/recargas-movistar-guatemala': '/es/guatemala/recarga-movistar',
    },
    es: {
      '/recarga-llamaya-movil': '/es/espana/recarga-llamaya',
      '/guild-wars': '/es-es/codigos-juegos',
    },
    us: {
      '/recarga-ultra-mobile': '/es/estados-unidos/recarga-ultra-mobile',
    },
    pl: {
      '/recargas-play': '/es/polonia/recarga-play',
    },
    cr: {
      '/recargas-claro-nicaragua': '/es/nicaragua/recarga-claro',
      '/recargas-claro-costa-rica': '/es/costa-rica/recarga-claro',
    },
  },
  pt: {
    '??': {
      '/celcom-malaysia': '/pt/malasia/recarrega-celcom',
      '/orange-madagascar': '/pt/madagascar/recarrega-orange',
      '/wataniya-state-of-palestine': '/pt/palestina/recarrega-wataniya',
      '/dollarphone-united-states': '/pt/estados-unidos/recarrega-dollarphone',
      '/flow-virgin-islands-british':
        '/pt/ilhas-virgens-britanicas/recarrega-flow',
      '/moov-niger': '/pt/níger/recarrega-moov',
      '/digicel-nauru': '/pt/nauru/recarrega-digicel',
      '/expo-mobile-united-states': '/pt/estados-unidos/recarrega-expo-mobile',
      '/aircel-tamil-nadu-india': '/pt/india/recarrega-aircel-tamil-nadu',
      '/claro-puerto-rico': '/pt/porto-rico/recarrega-claro',
      '/warid-pakistan': '/pt/paquistao/recarrega-warid',
      '/singtel-singapore': '/pt/cingapura/recarrega-singtel',
      '/vodafone-gujarat-india': '/pt/india/recarrega-vodafone-gujarat',
      '/une-colombia': '/pt/colombia/recarrega-une',
      '/econet-burundi': '/pt/burundi/recarrega-econet',
      '/airtel-india': '/pt/australia/recarrega-airtel',
      '/ooredoo-tunisia': '/pt/tunisia/recarrega-ooredoo',
      '/vodafone-spain': '/pt/espanha/recarrega-vodafone',
      '/banglalink-bangladesh': '/pt/bangladesh/recarrega-banglalink',
      '/videocon-india': '/pt/india/recarrega-videocon',
      '/movistar-nicaragua': '/pt/nicaragua/recarrega-movistar',
      '/tigo-bolivia': '/pt/bolivia/recarrega-tigo',
      '/play-poland': '/pt/polonia/recarrega-play',
      '/entel-bolivia': '/pt/bolivia/recarrega-entel',
      '/lebara-spain': '/pt/espanha/recarrega-lebara',
      '/smartfren-indonesia': '/pt/indonesia/recarrega-smartfren',
      '/xl-indonesia': '/pt/indonesia/recarrega-xl',
      '/viettel-vietnam': '/pt/vietna/recarrega-viettel',
      '/tunisie-telecom-tunisia': '/pt/tunisia/recarrega-tunisie-telecom',
      '/mtn-nigeria': '/pt/nigeria/recarrega-mtn',
      '/mtnl-india': '/pt/india/recarrega-mtnl',
      '/tuenti-spain': '/pt/espanha/recarrega-tuenti',
      '/movistar-peru': '/pt/peru/recarrega-movistar',
      '/cnt-ecuador': '/pt/equador/recarrega-cnt',
      '/robi-axiata-bangladesh': '/pt/bangladesh/recarrega-robi-axiata',
      '/etisalat-afghanistan': '/pt/afeganistao/recarrega-etisalat',
      '/turkcell-turkey': '/pt/turquia/recarrega-turkcell',
      '/orange-mali': '/pt/mali/recarrega-orange',
      '/utel-ukraine': '/pt/ucrania/recarrega-utel',
      '/movistar-el-salvador': '/pt/el-salvador/recarrega-movistar',
      '/ooredoo-algeria': '/pt/argelia/recarrega-ooredoo',
      '/megafone-tajikistan': '/pt/tajiquistao/recarrega-megafone',
      '/aircel-hp-india': '/pt/india/recarrega-aircel-hp',
      '/telenor-india': '/pt/india/recarrega-telenor',
      '/ais-thailand': '/pt/tailandia/recarrega-ais',
      '/vodafone-turkey': '/pt/turquia/recarrega-vodafone',
      '/aircel-chennai-india': '/pt/india/recarrega-aircel-chennai',
      '/beeline-tajikistan': '/pt/tajiquistao/recarrega-beeline',
      '/utl-mobile-nepal': '/pt/nepal/recarrega-utl-mobile',
      '/virgin-mobile-colombia': '/pt/colombia/recarrega-virgin-mobile',
      '/sinpin-united-states': '/pt/estados-unidos/recarrega-sinpin',
      '/carrefour-spain': '/pt/espanha/recarrega-carrefour',
      '/aircel-assam-india': '/pt/india/recarrega-aircel-assam',
      '/vodafone-uttar-pradesh-west-india':
        '/pt/india/recarrega-vodafone-uttar-pradesh-west',
      '/mtn-yemen': '/pt/iemen/recarrega-mtn',
      '/aircel-bihar-india': '/pt/india/recarrega-aircel-bihar',
      '/digicel-virgin-islands-british':
        '/pt/ilhas-virgens-britanicas/recarrega-digicel',
      '/mts-india': '/pt/india/recarrega-mts',
      '/tigo-colombia': '/pt/colombia/recarrega-tigo',
      '/orange-romania': '/pt/romenia/recarrega-orange',
      '/axis-indonesia': '/pt/indonesia/recarrega-axis',
      '/nextel-brazil': '/pt/brasil/recarrega-nextel',
      '/nationlink-somalia': '/pt/mali/recarrega-nationlink',
      '/lifecell-ukraine': '/pt/ucrania/recarrega-lifecell',
      '/idea-india': '/pt/australia/recarrega-idea',
      '/mtn-rwanda': '/pt/ruanda/recarrega-mtn',
      '/ultra-mobile-united-states':
        '/pt/estados-unidos/recarrega-ultra-mobile',
      '/cable-and-wireless-panama': '/pt/panama/recarrega-cable-and-wireless',
      '/oi-brazil': '/pt/brasil/recarrega-oi',
      '/orange-congo': '/pt/congo/recarrega-orange',
      '/digicel-cayman-islands': '/pt/ilhas-cayman/recarrega-digicel',
      '/happy-movil-spain': '/pt/espanha/recarrega-happy-movil',
      '/unicom-china': '/pt/china/recarrega-china-unicom',
      '/telenor-myanmar': '/pt/mianmar/recarrega-telenor',
      '/tata-docomo-india': '/pt/india/recarrega-tata-docomo',
      '/zong-pakistan': '/pt/paquistao/recarrega-zong',
      '/digicel-samoa': '/pt/samoa/recarrega-digicel',
      '/vodafone-kerala-india': '/pt/india/recarrega-vodafone-kerala',
      '/claro-peru': '/pt/peru/recarrega-claro',
      '/cubacel-cuba': '/pt/cuba/recarrega-cubacel',
      '/etb-colombia': '/pt/colombia/recarrega-etb',
      '/mtn-congo': '/pt/congo/recarrega-mtn',
      '/aircel-rajasthan-india': '/pt/india/recarrega-aircel-rajasthan',
      '/digicel-antigua-and-barbuda': '/pt/antigua-barbuda/recarrega-digicel',
      '/natcom-haiti': '/pt/haiti/recarrega-natcom',
      '/smarts-yaroslavl-russian-federation':
        '/pt/russia/recarrega-smarts-yaroslavl',
      '/claro-chile': '/pt/chile/recarrega-claro',
      '/aircel-mumbai-india': '/pt/india/recarrega-aircel-mumbai',
      '/tata-indicom-india': '/pt/india/recarrega-tata-indicom',
      '/vox-paraguay': '/pt/paraguai/recarrega-vox',
      '/vodafone-kolkata-india': '/pt/india/recarrega-vodafone-kolkata',
      '/geocell-georgia': '/pt/georgia/recarrega-geocell',
      '/telekom-mobile-romania': '/pt/romenia/recarrega-telekom-mobile',
      '/inwi-morocco': '/pt/marrocos/recarrega-inwi',
      '/indosat-mentari-indonesia': '/pt/indonesia/recarrega-indosat-mentari',
      '/avea-turkey': '/pt/turquia/recarrega-avea',
      '/africell-gambia': '/pt/gambia/recarrega-africell',
      '/nextel-chile': '/pt/chile/recarrega-nextel',
      '/orange-senegal': '/pt/senegal/recarrega-orange',
      '/djezzy-algeria': '/pt/argelia/recarrega-djezzy',
      '/aircel-andhra-pradesh-india':
        '/pt/india/recarrega-aircel-andhra-pradesh',
      '/claro-uruguay': '/pt/uruguai/recarrega-claro',
      '/digicel-tonga': '/pt/tonga/recarrega-digicel',
      '/orange-poland': '/pt/polonia/recarrega-orange',
      '/telenor-pakistan': '/pt/paquistao/recarrega-telenor',
      '/lailai-georgia': '/pt/georgia/recarrega-lailai',
      '/jazz-pakistan': '/pt/paquistao/recarrega-jazz',
      '/vodacom-mozambique': '/pt/mocambique/recarrega-vodacom',
      '/llamaya-spain': '/pt/espanha/recarrega-llamaya',
      '/mtn-cameroon': '/pt/camaroes/recarrega-mtn',
      '/orange-niger': '/pt/nigeria/recarrega-orange',
      '/masmovil-spain': '/pt/espanha/recarrega-masmovil',
      '/mobilis-algeria': '/pt/argelia/recarrega-mobilis',
      '/mtn-swaziland': '/pt/suazilandia/recarrega-mtn',
      '/intertelecom-ukraine': '/pt/ucrania/recarrega-intertelecom',
      '/bouygues-france': '/pt/franca/recarrega-bouygues',
      '/movistar-ecuador': '/pt/equador/recarrega-movistar',
      '/dialog-sri-lanka': '/pt/sri lanka/recarrega-dialog',
      '/mobifone-vietnam': '/pt/vietna/recarrega-mobifone',
      '/megafon-far-east-russian-federation':
        '/pt/russia/recarrega-megafon-far-east',
      '/telkomsel-kartu-as-indonesia': '/pt/indonesia/recarrega-telkomsel',
      '/mts-russian-federation': '/pt/russia/recarrega-mts',
      '/digicel-fiji': '/pt/fiji/recarrega-digicel',
      '/ufone-pakistan': '/pt/paquistao/recarrega-ufone',
      '/airtel-ghana': '/pt/gana/recarrega-airteltigo',
      '/personal-argentina': '/pt/argentina/recarrega-personal',
      '/hong-da-mobile-spain': '/pt/espanha/recarrega-hong-da-mobile',
      '/beeline-georgia': '/pt/georgia/recarrega-beeline',
      '/vodafone-rajasthan-india': '/pt/india/recarrega-vodafone-rajasthan',
      '/digicel-turks-and-caicos-islands':
        '/pt/ilhas-turks-caicos/recarrega-digicel',
      '/ethiotelecom-ethiopia': '/pt/etiopia/recarrega-ethiotelecom',
      '/asia-cell-telecom-iraq': '/pt/iraque/recarrega-asia-cell-telecom',
      '/telkomsel-simpati-indonesia': '/pt/indonesia/recarrega-telkomsel',
      '/telecel-zimbabwe': '/pt/zimbabue/recarrega-telecel',
      '/hablafacil-spain': '/pt/espanha/recarrega-hablafacil',
      '/bmobile-trinidad-and-tobago': '/pt/trinidad-tobago/recarrega-bmobile',
      '/ucom-armenia': '/pt/armenia/recarrega-ucom',
      '/myphone-georgia': '/pt/georgia/recarrega-myphone',
      '/personal-paraguay': '/pt/paraguai/recarrega-personal',
      '/vodafone-maharashtra-india': '/pt/india/recarrega-vodafone-maharashtra',
      '/rostelecom-mrf-ural-russian-federation':
        '/pt/russia/recarrega-rostelecom-mrf-ural',
      '/mtn-guinea': '/pt/guine/recarrega-mtn',
      '/moldcell-moldova-republic-of': '/pt/moldavia/recarrega-moldcell',
      '/telecom-china': '/pt/china/recarrega-china-telecom',
      '/lycamobile-france': '/pt/franca/recarrega-lyca',
      '/grameenphone-bangladesh': '/pt/bangladesh/recarrega-grameenphone',
      '/digicel-el-salvador': '/pt/el-salvador/recarrega-digicel',
      '/vietnammobile-vietnam': '/pt/vietna/recarrega-vietnammobile',
      '/bani-georgia': '/pt/georgia/recarrega-bani',
      '/digimobil-spain': '/pt/espanha/recarrega-digimobil',
      '/falabella-chile': '/pt/chile/recarrega-falabella',
      '/tuyo-movil-costa-rica': '/pt/costa-rica/recarrega-tuyo-movil',
      '/tigo-el-salvador': '/pt/el-salvador/recarrega-tigo',
      '/heyah-poland': '/pt/polonia/recarrega-heyah',
      '/hits-mobile-spain': '/pt/espanha/recarrega-hits-mobile',
      '/megafon-caucasus-russian-federation':
        '/pt/russia/recarrega-megafon-caucasus',
      '/true-move-thailand': '/pt/tailandia/recarrega-true-move',
      '/tigo-paraguay': '/pt/paraguai/recarrega-tigo',
      '/orange-egypt': '/pt/egito/recarrega-orange',
      '/digicel-vanuatu': '/pt/vanuatu/recarrega-digicel',
      '/magti-gsm-georgia': '/pt/georgia/recarrega-magti-gsm',
      '/etisalat-egypt': '/pt/egito/recarrega-etisalat',
      '/mtn-liberia': '/pt/liberia/recarrega-mtn',
      '/vodafone-romania': '/pt/romenia/recarrega-vodafone',
      '/safaricom-kenya': '/pt/quenia/recarrega-safaricom',
      '/vodafone-fiji': '/pt/fiji/recarrega-vodafone',
      '/digicel-barbados': '/pt/barbados/recarrega-digicel',
      '/zamtel-zambia': '/pt/zambia/recarrega-zamtel',
      '/vodacom-tanzania-united-republic-of': '/pt/tanzania/recarrega-vodacom',
      '/orange-guinea-bissau': '/pt/guine-bissau/recarrega-orange',
      '/telsur-chile': '/pt/chile/recarrega-telsur',
      '/airtel-nigeria': '/pt/nigeria/recarrega-airtel',
      '/gmobile-vietnam': '/pt/vietna/recarrega-gmobile',
      '/africell-sierra-leone': '/pt/serra-leoa/recarrega-africell',
      '/mtn-south-africa': '/pt/africa-do-sul/recarrega-mtn',
      '/lyca-mobile-united-states': '/pt/estados-unidos/recarrega-lyca-mobile',
      '/gphone-georgia': '/pt/georgia/recarrega-gphone',
      '/ntc-gsm-nepal': '/pt/nepal/recarrega-ntc-gsm',
      '/tcell-tajikistan': '/pt/tajiquistao/recarrega-tcell',
      '/claro-argentina': '/pt/argentina/recarrega-claro',
      '/beeline-kyrgyzstan': '/pt/quirguistao/recarrega-beeline',
      '/umniah-jordan': '/pt/jordania/recarrega-umniah',
      '/jawwal-state-of-palestine': '/pt/palestina/recarrega-jawwal',
      '/smart-burundi': '/pt/burundi/recarrega-smart',
      '/africel-uganda': '/pt/uganda/recarrega-africel',
      '/digicel-saint-kitts-and-nevis':
        '/pt/sao-cristovao-nevis/recarrega-digicel',
      '/hutch-sri-lanka': '/pt/sri lanka/recarrega-hutch',
      '/tele2-russian-federation': '/pt/russia/recarrega-tele2',
      '/mts-ukraine': '/pt/ucrania/recarrega-mts',
      '/digicel-dominica': '/pt/dominica/recarrega-digicel',
      '/kb-impuls-beeline-russian-federation':
        '/pt/russia/recarrega-kb-impuls-beeline',
      '/babilonmobile-tajikistan': '/pt/tajiquistao/recarrega-babilonmobile',
      '/btc-bahamas': '/pt/bahamas/recarrega-btc',
      '/lebara-france': '/pt/franca/recarrega-lebara',
      '/vodacom-south-africa': '/pt/africa-do-sul/recarrega-vodacom',
      '/euskaltel-spain': '/pt/espanha/recarrega-euskaltel',
      '/vodafone-haryana-india': '/pt/india/recarrega-vodafone-haryana',
      '/glo-ghana': '/pt/gana/recarrega-glo',
      '/ncell-nepal': '/pt/nepal/recarrega-ncell',
      '/zain-kuwait': '/pt/kuweit/recarrega-zain',
      '/mobile-china': '/pt/china/recarrega-china-mobile',
      '/megafon-ural-russian-federation': '/pt/russia/recarrega-megafon-ural',
      '/roshan-afghanistan': '/pt/afeganistao/recarrega-roshan',
      '/blau-spain': '/pt/espanha/recarrega-blau',
      '/tigo-ghana': '/pt/gana/recarrega-airteltigo',
      '/aircel-punjab-india': '/pt/india/recarrega-aircel-punjab',
      '/fonex-kyrgyzstan': '/pt/quirguistao/recarrega-fonex',
      '/unefon-mexico': '/pt/mexico/recarrega-unefon',
      '/megafon-center-russian-federation':
        '/pt/russia/recarrega-megafon-center',
      '/orange-moldova-republic-of': '/pt/moldavia/recarrega-orange',
      '/aircel-west-bengal-india': '/pt/india/recarrega-aircel-west-bengal',
      '/at-and-t-united-states': '/pt/estados-unidos/recarrega-at-t',
      '/mtn-uganda': '/pt/uganda/recarrega-mtn',
      '/airtel-bangladesh': '/pt/bangladesh/recarrega-airtel',
      '/maxis-malaysia': '/pt/malasia/recarrega-maxis',
      '/megafon-povolzhye-russian-federation':
        '/pt/russia/recarrega-megafon-povolzhye',
      '/lyca-mobile-spain': '/pt/espanha/recarrega-lyca-mobile',
      '/mtn-benin': '/pt/benin/recarrega-mtn',
      '/etisalat-nigeria': '/pt/nigeria/recarrega-etisalat',
      '/vodafone-delhi-india': '/pt/india/recarrega-vodafone-delhi',
      '/digicel-panama': '/pt/panama/recarrega-digicel',
      '/vodafone-chennai-india': '/pt/india/recarrega-vodafone-chennai',
      '/umobile-malaysia': '/pt/malasia/recarrega-umobile',
      '/pureminutes-united-states': '/pt/estados-unidos/recarrega-pureminutes',
      '/motiv-russian-federation': '/pt/russia/recarrega-motiv',
      '/moov-central-african-republic':
        '/pt/republica-centro-africana/recarrega-moov',
      '/expresso-ghana': '/pt/gana/recarrega-expresso',
      '/vodafone-tamil-nadu-india': '/pt/india/recarrega-vodafone-tamil-nadu',
      '/mtn-cyprus': '/pt/chipre/recarrega-mtn',
      '/vodafone-bangalore-india': '/pt/india/recarrega-vodafone-bangalore',
      '/tigo-senegal': '/pt/senegal/recarrega-tigo',
      '/vodafone-mumbai-india': '/pt/india/recarrega-vodafone-mumbai',
      '/digicel-anguilla': '/pt/anguilla/recarrega-digicel',
      '/tigo-tanzania-united-republic-of': '/pt/tanzania/recarrega-tigo',
      '/warid-uganda': '/pt/uganda/recarrega-warid',
      '/digicel-aruba': '/pt/aruba/recarrega-digicel',
      '/claro-brazil': '/pt/brasil/recarrega-claro',
      '/uff-movil-colombia': '/pt/colombia/recarrega-uff-movil',
      '/metfone-cambodia': '/pt/camboja/recarrega-metfone',
      '/orange-spain': '/pt/espanha/recarrega-orange',
      '/megafon-moscow-russian-federation':
        '/pt/russia/recarrega-megafon-moscow',
      '/aircel-karnataka-india': '/pt/india/recarrega-aircel-karnataka',
      '/mobitel-sri-lanka': '/pt/sri lanka/recarrega-mobitel',
      '/ceria-indonesia': '/pt/indonesia/recarrega-ceria',
      '/virgin-mobile-chile': '/pt/chile/recarrega-virgin-mobile',
      '/vivo-brazil': '/pt/brasil/recarrega-vivo',
      '/starhub-singapore': '/pt/cingapura/recarrega-starhub',
      '/glo-nigeria': '/pt/nigeria/recarrega-glo',
      '/orange-tunisia': '/pt/tunisia/recarrega-orange',
      '/suntel-sri-lanka': '/pt/sri lanka/recarrega-suntel',
      '/movistar-chile': '/pt/chile/recarrega-movistar',
      '/digicel-papua-new-guinea': '/pt/papua-nova-guine/recarrega-digicel',
      '/digicel-trinidad-and-tobago': '/pt/trinidad-tobago/recarrega-digicel',
      '/smart-uganda': '/pt/uganda/recarrega-smart',
      '/tricom-dominican-republic': '/pt/republica-dominicana/recarrega-tricom',
      '/aircel-north-east-india': '/pt/india/recarrega-aircel-north-east',
      '/onatel-burkina-faso': '/pt/burkina-faso/recarrega-onatel',
      '/globe-philippines': '/pt/filipinas/recarrega-globe',
      '/vodafone-andhra-pradesh-india':
        '/pt/india/recarrega-vodafone-andhra-pradesh',
      '/orange-kenya': '/pt/quenia/recarrega-orange',
      '/tigo-rwanda': '/pt/ruanda/recarrega-tigo',
      '/vinaphone-vietnam': '/pt/vietna/recarrega-vinaphone',
      '/vodafone-bengal-india': '/pt/india/recarrega-vodafone-bengal',
      '/moov-cote-divoire': '/pt/costa-do-marfim/recarrega-moov',
      '/mpt-myanmar': '/pt/mianmar/recarrega-mpt',
      '/vodafone-ghana': '/pt/gana/recarrega-vodafone',
      '/orange-morocco': '/pt/marrocos/recarrega-orange',
      '/citycell-bangladesh': '/pt/bangladesh/recarrega-citycell',
      '/flow-turks-and-caicos-islands': '/pt/ilhas-turks-caicos/recarrega-flow',
      '/maroc-telecom-morocco': '/pt/marrocos/recarrega-maroc-telecom',
      '/s-fone-vietnam': '/pt/vietna/recarrega-s-fone',
      '/digi-malaysia': '/pt/malasia/recarrega-digi',
      '/tkmobile-tajikistan': '/pt/tajiquistao/recarrega-tkmobile',
      '/nextel-argentina': '/pt/argentina/recarrega-nextel',
      '/aircel-uttar-pradesh-east-india':
        '/pt/india/recarrega-aircel-uttar-pradesh-east',
      '/airtel-sri-lanka': '/pt/sri lanka/recarrega-airtel',
      '/m1-singapore': '/pt/cingapura/recarrega-m1',
      '/aircel-jk-india': '/pt/india/recarrega-aircel-jk',
      '/simple-mobile-united-states':
        '/pt/estados-unidos/recarrega-simple-mobile',
      '/qcell-gambia': '/pt/gambia/recarrega-qcell',
      '/beeline-ukraine': '/pt/ucrania/recarrega-beeline',
      '/dtac-thailand': '/pt/tailandia/recarrega-dtac',
      '/digicel-suriname': '/pt/suriname/recarrega-digicel',
      '/cell-c-south-africa': '/pt/africa-do-sul/recarrega-cell-c',
      '/tak-tak-poland': '/pt/polonia/recarrega-tak-tak',
      '/cellcom-guinea': '/pt/guine/recarrega-cellcom',
      '/mobilcat-spain': '/pt/espanha/recarrega-mobilcat',
      '/indosat-im3-indonesia': '/pt/indonesia/recarrega-indosat-im3',
      '/aircel-kolkata-india': '/pt/india/recarrega-aircel-kolkata',
      '/sun-cellular-philippines': '/pt/filipinas/recarrega-sun-cellular',
      '/o-kyrgyzstan': '/pt/quirguistao/recarrega-o',
      '/verizon-united-states': '/pt/estados-unidos/recarrega-verizon',
      '/dth-tata-sky-india': '/pt/india/recarrega-dth-tata-sky',
      '/claro-nicaragua': '/pt/nicaragua/recarrega-claro',
      '/viva-kuwait': '/pt/kuweit/recarrega-viva',
      '/aircel-orissa-india': '/pt/india/recarrega-aircel-orissa',
      '/smarts-samara-russian-federation': '/pt/russia/recarrega-smarts-samara',
      '/afghan-wireless-afghanistan':
        '/pt/afeganistao/recarrega-afghan-wireless',
      '/reliance-india': '/pt/india/recarrega-reliance',
      '/mtn-afghanistan': '/pt/afeganistao/recarrega-mtn',
      '/three-telecom-indonesia': '/pt/indonesia/recarrega-three-telecom',
      '/bluesky-american-samoa': '/pt/samoa/recarrega-bluesky',
      '/mtn-cote-divoire': '/pt/costa-do-marfim/recarrega-mtn',
      '/entel-chile': '/pt/chile/recarrega-entel',
      '/viva-dominican-republic': '/pt/republica-dominicana/recarrega-viva',
      '/tunetalk-malaysia': '/pt/malasia/recarrega-tunetalk',
      '/mtn-ghana': '/pt/gana/recarrega-mtn',
      '/claro-panama': '/pt/panama/recarrega-claro',
      '/vivacell-mts-armenia': '/pt/armenia/recarrega-vivacell-mts',
      '/aircel-maharashtra-india': '/pt/india/recarrega-aircel-maharashtra',
      '/vodafone-egypt': '/pt/egito/recarrega-vodafone',
      '/kyivstar-ukraine': '/pt/ucrania/recarrega-kyivstar',
      '/flow-saint-vincent-and-the-grenadines':
        '/pt/sao-vicente-granadinas/recarrega-flow',
      '/vodafone-uttar-pradesh-east-india':
        '/pt/india/recarrega-vodafone-uttar-pradesh-east',
      '/digicel-saint-lucia': '/pt/santa- lucia/recarrega-digicel',
      '/digicel-martinique': '/pt/martinica/recarrega-digicel',
      '/yoigo-spain': '/pt/espanha/recarrega-yoigo',
      '/avantel-colombia': '/pt/colombia/recarrega-avantel',
      '/smarts-cheboksary-russian-federation':
        '/pt/russia/recarrega-smarts-cheboksary',
      '/orange-france': '/pt/franca/recarrega-orange',
      '/digicel-bermuda': '/pt/bermuda/recarrega-digicel',
      '/vodafone-albania': '/pt/albania/recarrega-vodafone',
      '/megafon-north-west-russian-federation':
        '/pt/russia/recarrega-megafon-north-west',
      '/claro-el-salvador': '/pt/el-salvador/recarrega-claro',
      '/tim-brazil': '/pt/brasil/recarrega-tim',
      '/t-mobile-united-states': '/pt/estados-unidos/recarrega-t-mobile',
      '/megafon-siberia-russian-federation':
        '/pt/russia/recarrega-megafon-siberia',
      '/megacom-kyrgyzstan': '/pt/quirguistao/recarrega-megacom',
      '/bsnl-india': '/pt/india/recarrega-bsnl',
      '/vtr-chile': '/pt/chile/recarrega-vtr',
      '/moov-benin': '/pt/benin/recarrega-moov',
      '/iusacell-mexico': '/pt/mexico/recarrega-iusacell',
      '/airtel-uganda': '/pt/uganda/recarrega-airtel',
      '/polkomtel-plus-poland': '/pt/polonia/recarrega-polkomtel-plus',
      '/econet-zimbabwe': '/pt/zimbabue/recarrega-econet',
      '/claro-colombia': '/pt/colombia/recarrega-claro',
      '/viva-bolivia': '/pt/bolivia/recarrega-viva',
      '/etisalat-sri-lanka': '/pt/sri lanka/recarrega-etisalat',
      '/telemedia-belize': '/pt/belize/recarrega-telemedia',
      '/camgsm-cambodia': '/pt/camboja/recarrega-camgsm',
      '/aircel-delhi-india': '/pt/india/recarrega-aircel-delhi',
      '/orange-central-african-republic':
        '/pt/republica-centro-africana/recarrega-orange',
      '/vodafone-punjab-india': '/pt/india/recarrega-vodafone-punjab',
      '/smarts-astrakhan-russian-federation':
        '/pt/russia/recarrega-smarts-astrakhan',
      '/claro-ecuador': '/pt/equador/recarrega-claro',
      '/teletalk-bangladesh': '/pt/bangladesh/recarrega-teletalk',
      '/bayn-gsm-morocco': '/pt/marrocos/recarrega-bayn-gsm',
      '/mtn-guinea-bissau': '/pt/guine-bissau/recarrega-mtn',
      '/telcel-mexico': '/pt/mexico/recarrega-telcel',
      '/telkom-mobile-south-africa':
        '/pt/africa-do-sul/recarrega-telkom-mobile',
      '/aircel-india': '/pt/india/recarrega-aircel',
      '/airtel-rwanda': '/pt/ruanda/recarrega-airtel',
      '/airtel-kenya': '/pt/quenia/recarrega-airtel',
      '/alegro-ecuador': '/pt/equador/recarrega-alegro',
      '/beeline-lao-peoples-democratic-republic': '/pt/laos/recarrega-beeline',
      '/cadcomms-cambodia': '/pt/camboja/recarrega-cadcomms',
      '/claro-costa-rica': '/pt/costa-rica/recarrega-claro',
      '/claro-dominican-republic': '/pt/republica-dominicana/recarrega-claro',
      '/claro-guatemala': '/pt/guatemala/recarrega-claro',
      '/claro-honduras': '/pt/honduras/recarrega-claro',
      '/cosmote-romania': '/pt/romenia/recarrega-cosmote',
      '/comcel-colombia': '/pt/colombia/recarrega-comcel',
      '/cyfrowy-polsat-poland': '/pt/polonia/recarrega-cyfrowy-polsat',
      '/digicel-curacao': '/pt/curacao/recarrega-digicel',
      '/digicel-guyana': '/pt/guiana/recarrega-digicel',
      '/digicel-grenada': '/pt/grenada/recarrega-digicel',
      '/digicel-haiti': '/pt/haiti/recarrega-digicel',
      '/digicel-jamaica': '/pt/jamaica/recarrega-digicel',
      '/digicel-saint-vincent-and-the-grenadines':
        '/pt/sao-vicente-granadinas/recarrega-digicel',
      '/evn-telecom-vietnam': '/pt/vietna/recarrega-evn-telecom',
      '/expresso-telecom-senegal': '/pt/senegal/recarrega-expresso-telecom',
      '/full-movil-costa-rica': '/pt/costa-rica/recarrega-full-movil',
      '/gt-mobile-spain': '/pt/espanha/recarrega-gt-mobile',
      '/gtt-guyana': '/pt/guiana/recarrega-gtt',
      '/hutchison-three-indonesia': '/pt/indonesia/recarrega-hutchison-three',
      '/kolbi-costa-rica': '/pt/costa-rica/recarrega-kolbi',
      '/flow-anguilla': '/pt/anguilla/recarrega-flow',
      '/flow-barbados': '/pt/barbados/recarrega-flow',
      '/flow-antigua-and-barbuda': '/pt/antigua -barbuda/recarrega-flow',
      '/flow-cayman-islands': '/pt/ilhas-cayman/recarrega-flow',
      '/flow-dominica': '/pt/dominica/recarrega-flow',
      '/flow-grenada': '/pt/grenada/recarrega-flow',
      '/flow-saint-kitts-and-nevis': '/pt/sao-cristovao-nevis/recarrega-flow',
      '/flow-jamaica': '/pt/jamaica/recarrega-flow',
      '/flow-montserrat': '/pt/montserrat/recarrega-flow',
      '/flow-saint-lucia': '/pt/santa- lucia/recarrega-flow',
      '/movicel-angola': '/pt/angola/recarrega-movicel',
      '/moun-dominican-republic': '/pt/republica-dominicana/recarrega-moun',
      '/movistar-argentina': '/pt/argentina/recarrega-movistar',
      '/movistar-costa-rica': '/pt/costa-rica/recarrega-movistar',
      '/movistar-colombia': '/pt/colombia/recarrega-movistar',
      '/movistar-guatemala': '/pt/guatemala/recarrega-movistar',
      '/movistar-panama': '/pt/panama/recarrega-movistar',
      '/mts-armenia': '/pt/armenia/recarrega-mts',
      '/netone-zimbabwe': '/pt/zimbabue/recarrega-netone',
      '/orange-dominican-republic': '/pt/republica-dominicana/recarrega-orange',
      '/orange-cameroon': '/pt/camaroes/recarrega-orange',
      '/orange-guinea': '/pt/guine/recarrega-orange',
      '/orange-cote-divoire': '/pt/costa-do-marfim/recarrega-orange',
      '/orange-congo-the-democratic-republic-of-the':
        '/pt/congo/recarrega-orange',
      '/telkom-kenya': '/pt/quenia/recarrega-telkom',
      '/tigo-guatemala': '/pt/guatemala/recarrega-tigo',
      '/tigo-honduras': '/pt/honduras/recarrega-tigo',
      '/t-mobile-poland': '/pt/polonia/recarrega-t-mobile',
      '/togocel-togo': '/pt/togo/recarrega-togocel',
      '/uninor-india': '/pt/india/recarrega-uninor',
      '/brasil-telecom-brazil': '/pt/brasil/recarrega-brasil-telecom',
      '/claro-jamaica': '/pt/jamaica/recarrega-claro',
      '/digicel-honduras': '/pt/honduras/recarrega-digicel',
      '/easy-go-united-states': '/pt/estados-unidos/recarrega-easy-go',
      '/h2o-united-states': '/pt/estados-unidos/recarrega-h2o',
      '/voila-haiti': '/pt/haiti/recarrega-voila',
      '/idirect-dial-united-states':
        '/pt/estados-unidos/recarrega-idirect-dial',
      '/myphone2go-ild-united-states':
        '/pt/estados-unidos/recarrega-myphone2go-ild',
      '/net-10-united-states': '/pt/estados-unidos/recarrega-net-10',
      '/nextel-mexico': '/pt/mexico/recarrega-nextel',
      '/rok-mobile-united-states': '/pt/estados-unidos/recarrega-rok-mobile',
      '/smart-philippines': '/pt/filipinas/recarrega-smart',
      '/talk-talk-now-united-states':
        '/pt/estados-unidos/recarrega-talk-talk-now',
      '/terracom-united-states': '/pt/estados-unidos/recarrega-terracom',
      '/ucell-uzbekistan': '/pt/uzbequistao/recarrega-ucell',
      '/yourtel-united-states': '/pt/estados-unidos/recarrega-yourtel',
      '/mtn-zambia': '/pt/zambia/recarrega-mtn',
      '/zantel-tanzania-united-republic-of': '/pt/tanzania/recarrega-zantel',
      '/vodafone-kktc-turkey': '/pt/turquia/recarrega-vodafone-kktc',
      '/univision-united-states': '/pt/estados-unidos/recarrega-univision',
      '/gt-mobile-france': '/pt/franca/recarrega-gt-mobile',
      '/bali-georgia': '/pt/georgia/recarrega-bali',
      '/onevip-macedonia': '/pt/macedonia/recarrega-onevip',
      '/tele2-lithuania': '/pt/lituania/recarrega-tele2',
      '/life-belarus': '/pt/bielorrussia/recarrega-life',
      '/mts-belarus': '/pt/bielorrussia/recarrega-mts',
      '/airtel-madagascar': '/pt/madagascar/recarrega-airtel',
      '/smart-gold-philippines': '/pt/filipinas/recarrega-smart-gold',
      '/smart-bro-philippines': '/pt/filipinas/recarrega-smart-bro',
      '/beeline-uzbekistan': '/pt/uzbequistao/recarrega-beeline',
      '/movistar-uruguay': '/pt/uruguai/recarrega-movistar',
      '/glo-benin': '/pt/benin/recarrega-glo',
      '/digicel-french-guiana': '/pt/guiana-francesa/recarrega-digicel',
      '/orange-uganda': '/pt/uganda/recarrega-orange',
      '/t-mobile-united-kingdom': '/pt/reino-unido/recarrega-t-mobile',
      '/sfr-france': '/pt/franca/recarrega-sfr',
      '/bob-austria': '/pt/austria/recarrega-bob',
      '/b-free-austria': '/pt/austria/recarrega-b-free',
      '/tele-dot-ring-austria': '/pt/austria/recarrega-telering',
      '/lycamobile-austria': '/pt/austria/recarrega-lycamobile',
      '/nimm3-austria': '/pt/austria/recarrega-nimm3',
      '/3reload-austria': '/pt/austria/recarrega-3reload',
      '/vectone-austria': '/pt/austria/recarrega-vectone',
      '/eety-austria': '/pt/austria/recarrega-eety',
      '/ee-united-kingdom': '/pt/reino-unido/recarrega-ee',
      '/cricket-paygo-united-states':
        '/pt/estados-unidos/recarrega-cricket-paygo',
      '/boost-united-states': '/pt/estados-unidos/recarrega-boost',
      '/du-more-united-arab-emirates':
        '/pt/emirados-arabes-unidos/recarrega-du-more',
      '/kcell-kazakhstan': '/pt/cazaquistao/recarrega-kcell',
      '/altel-kazakhstan': '/pt/cazaquistao/recarrega-altel',
      '/activ-kazakhstan': '/pt/cazaquistao/recarrega-activ',
      '/beeline-kazakhstan': '/pt/cazaquistao/recarrega-beeline',
      '/tele2-kazakhstan': '/pt/cazaquistao/recarrega-tele2',
      '/airtel-zambia': '/pt/zambia/recarrega-airtel',
      '/airtel-tanzania-united-republic-of': '/pt/tanzania/recarrega-airtel',
      '/megafon-center-south-russian-federation':
        '/pt/russia/recarrega-megafon-center-south',
      '/claro-paraguay': '/pt/paraguai/recarrega-claro',
      '/ooredoo-myanmar': '/pt/mianmar/recarrega-ooredoo',
      '/mec-myanmar': '/pt/mianmar/recarrega-mec',
      '/airtel-malawi': '/pt/malawi/recarrega-airtel',
      '/aircel-uttar-pradesh-west-india':
        '/pt/india/recarrega-aircel-uttar-pradesh-west',
      '/virgin-mobile-mexico': '/pt/mexico/recarrega-virgin-mobile',
      '/maz-mexico': '/pt/mexico/recarrega-maz',
      '/cierto-mexico': '/pt/mexico/recarrega-cierto',
      '/alo-mexico': '/pt/mexico/recarrega-alo',
      '/touch-lebanon': '/pt/líbano/recarrega-touch',
      '/alfa-lebanon': '/pt/líbano/recarrega-alfa',
      '/aircel-kerala-india': '/pt/india/recarrega-aircel-kerala',
      '/tuenti-ecuador': '/pt/equador/recarrega-tuenti',
      '/smart-cambodia': '/pt/camboja/recarrega-smart',
      '/zain-bahrain': '/pt/bahrein/recarrega-zain',
      '/viva-bahrain': '/pt/bahrein/recarrega-viva',
      '/freedom-mobile-canada': '/pt/canada/recarrega-freedom-mobile',
      '/virgin-canada': '/pt/canada/recarrega-virgin',
      '/telus-canada': '/pt/canada/recarrega-telus',
      '/solo-canada': '/pt/canada/recarrega-solo',
      '/rogers-canada': '/pt/canada/recarrega-rogers',
      '/publicmobile-canada': '/pt/canada/recarrega-publicmobile',
      '/mts-canada': '/pt/canada/recarrega-mts',
      '/koodo-canada': '/pt/canada/recarrega-koodo',
      '/fido-canada': '/pt/canada/recarrega-fido',
      '/chatr-canada': '/pt/canada/recarrega-chatr',
      '/bell-canada': '/pt/canada/recarrega-bell',
      '/zain-saudi-arabia': '/pt/arabia-saudita/recarrega-zain',
      '/virgin-saudi-arabia': '/pt/arabia-saudita/recarrega-virgin',
      '/stc-saudi-arabia': '/pt/arabia-saudita/recarrega-stc',
      '/mobily-saudi-arabia': '/pt/arabia-saudita/recarrega-mobily',
      '/lebara-saudi-arabia': '/pt/arabia-saudita/recarrega-lebara',
      '/friendi-saudi-arabia': '/pt/arabia-saudita/recarrega-friendi',
      '/allo-saudi-arabia': '/pt/arabia-saudita/recarrega-allo',
      '/virgin-mobile-south-africa':
        '/pt/africa-do-sul/recarrega-virgin-mobile',
      '/vodafone-qatar': '/pt/catar/recarrega-vodafone',
      '/ooredoo-qatar': '/pt/catar/recarrega-ooredoo',
      '/at-and-t-puerto-rico': '/pt/porto-rico/recarrega-at-t',
      '/renna-mobile-oman': '/pt/oman/recarrega-renna-mobile',
      '/omantel-oman': '/pt/oman/recarrega-omantel',
      '/friendi-mobile-oman': '/pt/oman/recarrega-friendi-mobile',
      '/movitel-mozambique': '/pt/zambia/recarrega-movitel',
      '/yallo-switzerland': '/pt/suica/recarrega-yallo',
      '/swisscom-switzerland': '/pt/moldavia/recarrega-swisscom',
      '/sunrise-switzerland': '/pt/suica/recarrega-sunrise',
      '/salt-mobile-switzerland': '/pt/suica/recarrega-salt-mobile',
      '/ortel-mobile-switzerland': '/pt/suica/recarrega-ortel-mobile',
      '/mucho-mobile-switzerland': '/pt/suica/recarrega-mucho-mobile',
      '/lycamobile-switzerland': '/pt/suica/recarrega-lycamobile',
      '/lebara-switzerland': '/pt/suica/recarrega-lebara',
      '/tatem-congo': '/pt/congo/recarrega-tatem',
      '/tracfone-united-states': '/pt/estados-unidos/recarrega-tracfone',
      '/telcel-america-united-states':
        '/pt/estados-unidos/recarrega-telcel-america',
      '/sti-mobile-united-states': '/pt/estados-unidos/recarrega-sti-mobile',
      '/real-mobile-united-states': '/pt/estados-unidos/recarrega-real-mobile',
      '/ready-mobile-united-states':
        '/pt/estados-unidos/recarrega-ready-mobile',
      '/page-plus-united-states': '/pt/estados-unidos/recarrega-page-plus',
      '/good2go-united-states': '/pt/estados-unidos/recarrega-good2go',
      '/cintex-wireless-united-states':
        '/pt/estados-unidos/recarrega-cintex-wireless',
      '/red-pocket-united-states': '/pt/estados-unidos/recarrega-red-pocket',
      '/urcall-mobile-united-states':
        '/pt/estados-unidos/recarrega-urcall-mobile',
      '/life-wireless-united-states':
        '/pt/estados-unidos/recarrega-life-wireless',
      '/airvoice-united-states': '/pt/estados-unidos/recarrega-airvoice',
      '/access-wireless-united-states':
        '/pt/estados-unidos/recarrega-access-wireless',
      '/etisalat-united-arab-emirates':
        '/pt/emirados-arabes-unidos/recarrega-etisalat',
      '/smart-cell-nepal': '/pt/nepal/recarrega-smart-cell',
      '/tn-mobile-namibia': '/pt/namibia/recarrega-tn-mobile',
      '/mtc-namibia': '/pt/namibia/recarrega-mtc',
      '/orange-botswana': '/pt/botsuana/recarrega-orange',
      '/mascom-botswana': '/pt/botsuana/recarrega-mascom',
      '/bemobile-botswana': '/pt/botsuana/recarrega-bemobile',
      '/batelco-bahrain': '/pt/bahrein/recarrega-batelco',
      '/cvmovel-cape-verde': '/pt/cabo-verde/recarrega-cvmovel',
      '/three-united-kingdom': '/pt/reino-unido/recarrega-three',
      '/asda-mobile-united-kingdom': '/pt/reino-unido/recarrega-asda-mobile',
      '/giff-gaff-united-kingdom': '/pt/reino-unido/recarrega-giff-gaff',
      '/lycamobile-united-kingdom': '/pt/reino-unido/recarrega-lycamobile',
      '/talk-home-mobile-united-kingdom':
        '/pt/reino-unido/recarrega-talk-home-mobile',
      '/o2-united-kingdom': '/pt/reino-unido/recarrega-o2',
      '/vodafone-united-kingdom': '/pt/reino-unido/recarrega-vodafone',
      '/virgin-mobile-united-kingdom':
        '/pt/reino-unido/recarrega-virgin-mobile',
      '/you-mobile-spain': '/pt/espanha/recarrega-you-mobile',
      '/republica-movile-spain': '/pt/espanha/recarrega-republica-movile',
      '/lcr-movile-spain': '/pt/espanha/recarrega-lcr-movile',
      '/hablapp-spain': '/pt/espanha/recarrega-hablapp',
      '/wind-italy': '/pt/canada/recarrega-wind',
      '/vodafone-italy': '/pt/italia/recarrega-vodafone',
      '/tim-italy': '/pt/italia/recarrega-tim',
      '/3-tre-italy': '/pt/italia/recarrega-3-tre',
      '/bluesky-samoa': '/pt/canada/recarrega-bluesky',
      '/telenor-serbia': '/pt/servia/recarrega-telenor',
      '/orange-burkina-faso': '/pt/burkina-faso/recarrega-orange',
      '/delight-austria': '/pt/austria/recarrega-delight',
      '/t-mobile-austria': '/pt/austria/recarrega-t-mobile',
      '/m-tel-austria': '/pt/austria/recarrega-m-tel',
      '/optus-australia': '/pt/australia/recarrega-optus',
      '/orange-liberia': '/pt/liberia/recarrega-orange',
      '/smile-uganda': '/pt/uganda/recarrega-smile',
      '/talkout-spain': '/pt/espanha/recarrega-talkout',
      '/clm-spain': '/pt/espanha/recarrega-clm',
      '/uganda-telecom-uganda': '/pt/uganda/recarrega-uganda-telecom',
      '/mundimovil-spain': '/pt/espanha/recarrega-mundimovil',
      '/yu-kenya': '/pt/quenia/recarrega-yu',
      '/jazzcard-movil-spain': '/pt/espanha/recarrega-jazzcard-movil',
      '/smart-tanzania-united-republic-of': '/pt/tanzania/recarrega-smart',
      '/moov-gabon': '/pt/gabao/recarrega-moov',
      '/best-movil-spain': '/pt/espanha/recarrega-best-movil',
      '/movistar-mexico': '/pt/mexico/recarrega-movistar',
      '/vodafone-india': '/pt/india/recarrega-vodafone',
      '/lyca-international-united-states':
        '/pt/estados-unidos/recarrega-lyca-international',
      '/lycamobile-unlimited-international-united-states':
        '/pt/estados-unidos/recarrega-lycamobile-unlimited-international',
      '/symacom-france': '/pt/franca/recarrega-symacom',
      '/la-poste-mobile-france': '/pt/franca/recarrega-la-poste-mobile',
      '/lebara-united-kingdom': '/pt/reino-unido/recarrega-lebara',
      '/tesco-mobile-united-kingdom': '/pt/reino-unido/recarrega-tesco-mobile',
      '/nrj-france': '/pt/franca/recarrega-nrj',
      '/metropcs-united-states': '/pt/estados-unidos/recarrega-metropcs',
      '/virgin-mobile-united-states':
        '/pt/estados-unidos/recarrega-virgin-mobile',
      '/lycamobile-portugal': '/pt/portugal/recarrega-lycamobile',
      '/vectone-mobile-portugal': '/pt/portugal/recarrega-vectone',
      '/vodafone-portugal': '/pt/portugal/vodafone',
      '/uzo-portugal': '/pt/portugal/recarrega-uzo',
      '/nos-portugal': '/pt/portugal/recarrega-nos',
      '/moche-portugal': '/pt/portugal/recarrega-moche',
      '/meo-portugal': '/pt/portugal/recarrega-meo',
      '/ttcl-tanzania-united-republic-of': '/pt/tanzania/recarrega-ttcl',
      '/halotel-tanzania-united-republic-of': '/pt/tanzania/recarrega-halotel',
      '/reliance-jio-india': '/pt/india/recarrega-reliance-jio',
      '/etl-lao-peoples-democratic-republic': '/pt/laos/recarrega-etl',
      '/paises': '/pt/paises',
    },
    pt: {
      '/guild-wars': '/pt-pt/codigos-de-jogo',
    },
  },
  pl: {
    '??': {
      '/celcom-malaysia': '/pl/malezja/doladowanie-celcom',
      '/orange-madagascar': '/pl/madagaskar/doladowanie-orange',
      '/wataniya-state-of-palestine': '/pl/palestyna/doladowanie-wataniya',
      '/dollarphone-united-states':
        '/pl/stany-zjednoczone/doladowanie-dollarphone',
      '/flow-virgin-islands-british':
        '/pl/brytyjskie-wyspy-dziewicze/doladowanie-flow',
      '/moov-niger': '/pl/niger/doladowanie-moov',
      '/digicel-nauru': '/pl/nauru/doladowanie-digicel',
      '/expo-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-expo-mobile',
      '/aircel-tamil-nadu-india': '/pl/indie/doladowanie-aircel-tamil-nadu',
      '/claro-puerto-rico': '/pl/portoryko/doladowanie-claro',
      '/warid-pakistan': '/pl/pakistan/doladowanie-warid',
      '/singtel-singapore': '/pl/singapur/doladowanie-singtel',
      '/vodafone-gujarat-india': '/pl/indie/doladowanie-vodafone-gujarat',
      '/une-colombia': '/pl/kolumbia/doladowanie-une',
      '/econet-burundi': '/pl/burundi/doladowanie-econet',
      '/airtel-india': '/pl/australia/doladowanie-airtel',
      '/ooredoo-tunisia': '/pl/tunezja/doladowanie-ooredoo',
      '/vodafone-spain': '/pl/hiszpania/doladowanie-vodafone',
      '/banglalink-bangladesh': '/pl/bangladesz/doladowanie-banglalink',
      '/videocon-india': '/pl/indie/doladowanie-videocon',
      '/movistar-nicaragua': '/pl/nikaragua/doladowanie-movistar',
      '/tigo-bolivia': '/pl/boliwia/doladowanie-tigo',
      '/play-poland': '/pl/polska/doladowanie-play',
      '/entel-bolivia': '/pl/boliwia/doladowanie-entel',
      '/lebara-spain': '/pl/hiszpania/doladowanie-lebara',
      '/smartfren-indonesia': '/pl/indonezja/doladowanie-smartfren',
      '/xl-indonesia': '/pl/indonezja/doladowanie-xl',
      '/viettel-vietnam': '/pl/wietnam/doladowanie-viettel',
      '/tunisie-telecom-tunisia': '/pl/tunezja/doladowanie-tunisie-telecom',
      '/mtn-nigeria': '/pl/nigeria/doladowanie-mtn',
      '/mtnl-india': '/pl/indie/doladowanie-mtnl',
      '/tuenti-spain': '/pl/hiszpania/doladowanie-tuenti',
      '/movistar-peru': '/pl/peru/doladowanie-movistar',
      '/cnt-ecuador': '/pl/ekwador/doladowanie-cnt',
      '/robi-axiata-bangladesh': '/pl/bangladesz/doladowanie-robi-axiata',
      '/etisalat-afghanistan': '/pl/afganistan/doladowanie-etisalat',
      '/turkcell-turkey': '/pl/turcja/doladowanie-turkcell',
      '/orange-mali': '/pl/mali/doladowanie-orange',
      '/utel-ukraine': '/pl/ukraina/doladowanie-utel',
      '/movistar-el-salvador': '/pl/salwador/doladowanie-movistar',
      '/ooredoo-algeria': '/pl/algieria/doladowanie-ooredoo',
      '/megafone-tajikistan': '/pl/tadzykistan/doladowanie-megafone',
      '/aircel-hp-india': '/pl/indie/doladowanie-aircel-hp',
      '/telenor-india': '/pl/indie/doladowanie-telenor',
      '/ais-thailand': '/pl/taijlandia/doladowanie-ais',
      '/vodafone-turkey': '/pl/turcja/doladowanie-vodafone',
      '/aircel-chennai-india': '/pl/indie/doladowanie-aircel-chennai',
      '/beeline-tajikistan': '/pl/tadzykistan/doladowanie-beeline',
      '/utl-mobile-nepal': '/pl/nepal/doladowanie-utl-mobile',
      '/virgin-mobile-colombia': '/pl/kolumbia/doladowanie-virgin-mobile',
      '/sinpin-united-states': '/pl/stany-zjednoczone/doladowanie-sinpin',
      '/carrefour-spain': '/pl/hiszpania/doladowanie-carrefour',
      '/aircel-assam-india': '/pl/indie/doladowanie-aircel-assam',
      '/vodafone-uttar-pradesh-west-india':
        '/pl/indie/doladowanie-vodafone-uttar-pradesh-west',
      '/mtn-yemen': '/pl/jemen/doladowanie-mtn',
      '/aircel-bihar-india': '/pl/indie/doladowanie-aircel-bihar',
      '/digicel-virgin-islands-british':
        '/pl/brytyjskie-wyspy-dziewicze/doladowanie-digicel',
      '/mts-india': '/pl/indie/doladowanie-mts',
      '/tigo-colombia': '/pl/kolumbia/doladowanie-tigo',
      '/orange-romania': '/pl/rumunia/doladowanie-orange',
      '/axis-indonesia': '/pl/indonezja/doladowanie-axis',
      '/nextel-brazil': '/pl/brazylia/doladowanie-nextel',
      '/nationlink-somalia': '/pl/mali/doladowanie-nationlink',
      '/lifecell-ukraine': '/pl/ukraina/doladowanie-lifecell',
      '/idea-india': '/pl/australia/doladowanie-idea',
      '/mtn-rwanda': '/pl/rwanda/doladowanie-mtn',
      '/ultra-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-ultra-mobile',
      '/cable-and-wireless-panama': '/pl/panama/doladowanie-cable-and-wireless',
      '/oi-brazil': '/pl/brazylia/doladowanie-oi',
      '/orange-congo': '/pl/kongo/doladowanie-orange',
      '/digicel-cayman-islands': '/pl/kajmany/doladowanie-digicel',
      '/happy-movil-spain': '/pl/hiszpania/doladowanie-happy-movil',
      '/unicom-china': '/pl/chiny/doladowanie-unicom',
      '/telenor-myanmar': '/pl/myanmar/doladowanie-telenor',
      '/tata-docomo-india': '/pl/indie/doladowanie-tata-docomo',
      '/zong-pakistan': '/pl/pakistan/doladowanie-zong',
      '/digicel-samoa': '/pl/samoa/doladowanie-digicel',
      '/vodafone-kerala-india': '/pl/indie/doladowanie-vodafone-kerala',
      '/claro-peru': '/pl/peru/doladowanie-claro',
      '/cubacel-cuba': '/pl/kuba/doladowanie-cubacel',
      '/etb-colombia': '/pl/kolumbia/doladowanie-etb',
      '/mtn-congo': '/pl/kongo/doladowanie-mtn',
      '/aircel-rajasthan-india': '/pl/indie/doladowanie-aircel-rajasthan',
      '/digicel-antigua-and-barbuda':
        '/pl/antigua-i-barbuda/doladowanie-digicel',
      '/natcom-haiti': '/pl/haiti/doladowanie-natcom',
      '/smarts-yaroslavl-russian-federation':
        '/pl/rosja/doladowanie-smarts-yaroslavl',
      '/claro-chile': '/pl/chile/doladowanie-claro',
      '/aircel-mumbai-india': '/pl/indie/doladowanie-aircel-mumbai',
      '/tata-indicom-india': '/pl/indie/doladowanie-tata-indicom',
      '/vox-paraguay': '/pl/paragwaj/doladowanie-vox',
      '/vodafone-kolkata-india': '/pl/indie/doladowanie-vodafone-kolkata',
      '/geocell-georgia': '/pl/gruzja/doladowanie-geocell',
      '/telekom-mobile-romania': '/pl/rumunia/doladowanie-telekom-mobile',
      '/inwi-morocco': '/pl/maroko/doladowanie-inwi',
      '/indosat-mentari-indonesia': '/pl/indonezja/doladowanie-indosat-mentari',
      '/avea-turkey': '/pl/turcja/doladowanie-avea',
      '/africell-gambia': '/pl/gambia/doladowanie-africell',
      '/nextel-chile': '/pl/chile/doladowanie-nextel',
      '/orange-senegal': '/pl/senegal/doladowanie-orange',
      '/djezzy-algeria': '/pl/algieria/doladowanie-djezzy',
      '/aircel-andhra-pradesh-india':
        '/pl/indie/doladowanie-aircel-andhra-pradesh',
      '/claro-uruguay': '/pl/urugwaj/doladowanie-claro',
      '/digicel-tonga': '/pl/tonga/doladowanie-digicel',
      '/orange-poland': '/pl/polska/doladowanie-orange',
      '/telenor-pakistan': '/pl/pakistan/doladowanie-telenor',
      '/lailai-georgia': '/pl/gruzja/doladowanie-lailai',
      '/jazz-pakistan': '/pl/pakistan/doladowanie-jazz',
      '/vodacom-mozambique': '/pl/mozambik/doladowanie-vodacom',
      '/llamaya-spain': '/pl/hiszpania/doladowanie-llamaya',
      '/mtn-cameroon': '/pl/kamerun/doladowanie-mtn',
      '/orange-niger': '/pl/nigeria/doladowanie-orange',
      '/masmovil-spain': '/pl/hiszpania/doladowanie-masmovil',
      '/mobilis-algeria': '/pl/algieria/doladowanie-mobilis',
      '/mtn-swaziland': '/pl/suazi/doladowanie-mtn',
      '/intertelecom-ukraine': '/pl/ukraina/doladowanie-intertelecom',
      '/bouygues-france': '/pl/francja/doladowanie-bouygues',
      '/movistar-ecuador': '/pl/ekwador/doladowanie-movistar',
      '/dialog-sri-lanka': '/pl/sri-lanka/doladowanie-dialog',
      '/mobifone-vietnam': '/pl/wietnam/doladowanie-mobifone',
      '/megafon-far-east-russian-federation':
        '/pl/rosja/doladowanie-megafon-far-east',
      '/telkomsel-kartu-as-indonesia': '/pl/indonezja/doladowanie-telkomsel',
      '/mts-russian-federation': '/pl/rosja/doladowanie-mts',
      '/digicel-fiji': '/pl/fidzi/doladowanie-digicel',
      '/ufone-pakistan': '/pl/pakistan/doladowanie-ufone',
      '/airtel-ghana': '/pl/ghana/doladowanie-airteltigo',
      '/personal-argentina': '/pl/argentyna/doladowanie-personal',
      '/hong-da-mobile-spain': '/pl/hiszpania/doladowanie-hong-da-mobile',
      '/beeline-georgia': '/pl/gruzja/doladowanie-beeline',
      '/vodafone-rajasthan-india': '/pl/indie/doladowanie-vodafone-rajasthan',
      '/digicel-turks-and-caicos-islands':
        '/pl/wypsy-turks-i-caicos/doladowanie-digicel',
      '/ethiotelecom-ethiopia': '/pl/etiopia/doladowanie-ethiotelecom',
      '/asia-cell-telecom-iraq': '/pl/irak/doladowanie-asia-cell-telecom',
      '/telkomsel-simpati-indonesia': '/pl/indonezja/doladowanie-telkomsel',
      '/telecel-zimbabwe': '/pl/zimbabwe/doladowanie-telecel',
      '/hablafacil-spain': '/pl/hiszpania/doladowanie-hablafacil',
      '/bmobile-trinidad-and-tobago':
        '/pl/trynidad-i-tobago/doladowanie-bmobile',
      '/ucom-armenia': '/pl/armenia/doladowanie-ucom',
      '/myphone-georgia': '/pl/gruzja/doladowanie-myphone',
      '/personal-paraguay': '/pl/paragwaj/doladowanie-personal',
      '/vodafone-maharashtra-india':
        '/pl/indie/doladowanie-vodafone-maharashtra',
      '/rostelecom-mrf-ural-russian-federation':
        '/pl/rosja/doladowanie-rostelecom-mrf-ural',
      '/mtn-guinea': '/pl/gwinea/doladowanie-mtn',
      '/moldcell-moldova-republic-of': '/pl/mołdawia/doladowanie-moldcell',
      '/telecom-china': '/pl/chiny/doladowanie-telecom',
      '/lycamobile-france': '/pl/francja/doladowanie-lyca',
      '/grameenphone-bangladesh': '/pl/bangladesz/doladowanie-grameenphone',
      '/digicel-el-salvador': '/pl/salwador/doladowanie-digicel',
      '/vietnammobile-vietnam': '/pl/wietnam/doladowanie-vietnammobile',
      '/bani-georgia': '/pl/gruzja/doladowanie-bani',
      '/digimobil-spain': '/pl/hiszpania/doladowanie-digimobil',
      '/falabella-chile': '/pl/chile/doladowanie-falabella',
      '/tuyo-movil-costa-rica': '/pl/kostaryka/doladowanie-tuyo-movil',
      '/tigo-el-salvador': '/pl/salwador/doladowanie-tigo',
      '/heyah-poland': '/pl/polska/doladowanie-heyah',
      '/hits-mobile-spain': '/pl/hiszpania/doladowanie-hits-mobile',
      '/megafon-caucasus-russian-federation':
        '/pl/rosja/doladowanie-megafon-caucasus',
      '/true-move-thailand': '/pl/taijlandia/doladowanie-true-move',
      '/tigo-paraguay': '/pl/paragwaj/doladowanie-tigo',
      '/orange-egypt': '/pl/egipt/doladowanie-orange',
      '/digicel-vanuatu': '/pl/vanuatu/doladowanie-digicel',
      '/magti-gsm-georgia': '/pl/gruzja/doladowanie-magti-gsm',
      '/etisalat-egypt': '/pl/egipt/doladowanie-etisalat',
      '/mtn-liberia': '/pl/liberia/doladowanie-mtn',
      '/vodafone-romania': '/pl/rumunia/doladowanie-vodafone',
      '/safaricom-kenya': '/pl/kenia/doladowanie-safaricom',
      '/vodafone-fiji': '/pl/fidzi/doladowanie-vodafone',
      '/digicel-barbados': '/pl/barbados/doladowanie-digicel',
      '/zamtel-zambia': '/pl/zambia/doladowanie-zamtel',
      '/vodacom-tanzania-united-republic-of':
        '/pl/tanzania/doladowanie-vodacom',
      '/orange-guinea-bissau': '/pl/gwinea-bissau/doladowanie-orange',
      '/telsur-chile': '/pl/chile/doladowanie-telsur',
      '/airtel-nigeria': '/pl/nigeria/doladowanie-airtel',
      '/gmobile-vietnam': '/pl/wietnam/doladowanie-gmobile',
      '/africell-sierra-leone': '/pl/sierra-leone/doladowanie-africell',
      '/mtn-south-africa': '/pl/afryka-poludniowa/doladowanie-mtn',
      '/lyca-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-lyca-mobile',
      '/gphone-georgia': '/pl/gruzja/doladowanie-gphone',
      '/ntc-gsm-nepal': '/pl/nepal/doladowanie-ntc-gsm',
      '/tcell-tajikistan': '/pl/tadzykistan/doladowanie-tcell',
      '/claro-argentina': '/pl/argentyna/doladowanie-claro',
      '/beeline-kyrgyzstan': '/pl/kirgistan/doladowanie-beeline',
      '/umniah-jordan': '/pl/jordania/doladowanie-umniah',
      '/jawwal-state-of-palestine': '/pl/palestyna/doladowanie-jawwal',
      '/smart-burundi': '/pl/burundi/doladowanie-smart',
      '/africel-uganda': '/pl/uganda/doladowanie-africel',
      '/digicel-saint-kitts-and-nevis':
        '/pl/saint-kitts-i-nevis/doladowanie-digicel',
      '/hutch-sri-lanka': '/pl/sri-lanka/doladowanie-hutch',
      '/tele2-russian-federation': '/pl/rosja/doladowanie-tele2',
      '/mts-ukraine': '/pl/ukraina/doladowanie-mts',
      '/digicel-dominica': '/pl/dominika/doladowanie-digicel',
      '/kb-impuls-beeline-russian-federation':
        '/pl/rosja/doladowanie-kb-impuls-beeline',
      '/babilonmobile-tajikistan': '/pl/tadzykistan/doladowanie-babilonmobile',
      '/btc-bahamas': '/pl/bahamas/doladowanie-btc',
      '/lebara-france': '/pl/francja/doladowanie-lebara',
      '/vodacom-south-africa': '/pl/afryka-poludniowa/doladowanie-vodacom',
      '/euskaltel-spain': '/pl/hiszpania/doladowanie-euskaltel',
      '/vodafone-haryana-india': '/pl/indie/doladowanie-vodafone-haryana',
      '/glo-ghana': '/pl/ghana/doladowanie-glo',
      '/ncell-nepal': '/pl/nepal/doladowanie-ncell',
      '/zain-kuwait': '/pl/kuwejt/doladowanie-zain',
      '/mobile-china': '/pl/chiny/doladowanie-mobile',
      '/megafon-ural-russian-federation': '/pl/rosja/doladowanie-megafon-ural',
      '/roshan-afghanistan': '/pl/afganistan/doladowanie-roshan',
      '/blau-spain': '/pl/hiszpania/doladowanie-blau',
      '/tigo-ghana': '/pl/ghana/doladowanie-airteltigo',
      '/aircel-punjab-india': '/pl/indie/doladowanie-aircel-punjab',
      '/fonex-kyrgyzstan': '/pl/kirgistan/doladowanie-fonex',
      '/unefon-mexico': '/pl/meksyk/doladowanie-unefon',
      '/megafon-center-russian-federation':
        '/pl/rosja/doladowanie-megafon-center',
      '/orange-moldova-republic-of': '/pl/mołdawia/doladowanie-orange',
      '/aircel-west-bengal-india': '/pl/indie/doladowanie-aircel-west-bengal',
      '/at-and-t-united-states': '/pl/stany-zjednoczone/doladowanie-at-t',
      '/mtn-uganda': '/pl/uganda/doladowanie-mtn',
      '/airtel-bangladesh': '/pl/bangladesz/doladowanie-airtel',
      '/maxis-malaysia': '/pl/malezja/doladowanie-maxis',
      '/megafon-povolzhye-russian-federation':
        '/pl/rosja/doladowanie-megafon-povolzhye',
      '/lyca-mobile-spain': '/pl/hiszpania/doladowanie-lyca-mobile',
      '/mtn-benin': '/pl/benin/doladowanie-mtn',
      '/etisalat-nigeria': '/pl/nigeria/doladowanie-etisalat',
      '/vodafone-delhi-india': '/pl/indie/doladowanie-vodafone-delhi',
      '/digicel-panama': '/pl/panama/doladowanie-digicel',
      '/vodafone-chennai-india': '/pl/indie/doladowanie-vodafone-chennai',
      '/umobile-malaysia': '/pl/malezja/doladowanie-umobile',
      '/pureminutes-united-states':
        '/pl/stany-zjednoczone/doladowanie-pureminutes',
      '/motiv-russian-federation': '/pl/rosja/doladowanie-motiv',
      '/moov-central-african-republic':
        '/pl/republika-srodkowoafrykanska/doladowanie-moov',
      '/expresso-ghana': '/pl/ghana/doladowanie-expresso',
      '/vodafone-tamil-nadu-india': '/pl/indie/doladowanie-vodafone-tamil-nadu',
      '/mtn-cyprus': '/pl/cypr/doladowanie-mtn',
      '/vodafone-bangalore-india': '/pl/indie/doladowanie-vodafone-bangalore',
      '/tigo-senegal': '/pl/senegal/doladowanie-tigo',
      '/vodafone-mumbai-india': '/pl/indie/doladowanie-vodafone-mumbai',
      '/digicel-anguilla': '/pl/anguilla/doladowanie-digicel',
      '/tigo-tanzania-united-republic-of': '/pl/tanzania/doladowanie-tigo',
      '/warid-uganda': '/pl/uganda/doladowanie-warid',
      '/digicel-aruba': '/pl/aruba/doladowanie-digicel',
      '/claro-brazil': '/pl/brazylia/doladowanie-claro',
      '/uff-movil-colombia': '/pl/kolumbia/doladowanie-uff-movil',
      '/metfone-cambodia': '/pl/kambodza/doladowanie-metfone',
      '/orange-spain': '/pl/hiszpania/doladowanie-orange',
      '/megafon-moscow-russian-federation':
        '/pl/rosja/doladowanie-megafon-moscow',
      '/aircel-karnataka-india': '/pl/indie/doladowanie-aircel-karnataka',
      '/mobitel-sri-lanka': '/pl/sri-lanka/doladowanie-mobitel',
      '/ceria-indonesia': '/pl/indonezja/doladowanie-ceria',
      '/virgin-mobile-chile': '/pl/chile/doladowanie-virgin-mobile',
      '/vivo-brazil': '/pl/brazylia/doladowanie-vivo',
      '/starhub-singapore': '/pl/singapur/doladowanie-starhub',
      '/glo-nigeria': '/pl/nigeria/doladowanie-glo',
      '/orange-tunisia': '/pl/tunezja/doladowanie-orange',
      '/suntel-sri-lanka': '/pl/sri-lanka/doladowanie-suntel',
      '/movistar-chile': '/pl/chile/doladowanie-movistar',
      '/digicel-papua-new-guinea': '/pl/papua-nowa-gwinea/doladowanie-digicel',
      '/digicel-trinidad-and-tobago':
        '/pl/trynidad-i-tobago/doladowanie-digicel',
      '/smart-uganda': '/pl/uganda/doladowanie-smart',
      '/tricom-dominican-republic': '/pl/dominikana/doladowanie-tricom',
      '/aircel-north-east-india': '/pl/indie/doladowanie-aircel-north-east',
      '/onatel-burkina-faso': '/pl/burkina-faso/doladowanie-onatel',
      '/globe-philippines': '/pl/filipiny/doladowanie-globe',
      '/vodafone-andhra-pradesh-india':
        '/pl/indie/doladowanie-vodafone-andhra-pradesh',
      '/orange-kenya': '/pl/kenia/doladowanie-orange',
      '/tigo-rwanda': '/pl/rwanda/doladowanie-tigo',
      '/vinaphone-vietnam': '/pl/wietnam/doladowanie-vinaphone',
      '/vodafone-bengal-india': '/pl/indie/doladowanie-vodafone-bengal',
      '/moov-cote-divoire': '/pl/wybreze-kosci-sloniowej/doladowanie-moov',
      '/mpt-myanmar': '/pl/myanmar/doladowanie-mpt',
      '/vodafone-ghana': '/pl/ghana/doladowanie-vodafone',
      '/orange-morocco': '/pl/maroko/doladowanie-orange',
      '/citycell-bangladesh': '/pl/bangladesz/doladowanie-citycell',
      '/flow-turks-and-caicos-islands':
        '/pl/wypsy-turks-i-caicos/doladowanie-flow',
      '/maroc-telecom-morocco': '/pl/maroko/doladowanie-maroc-telecom',
      '/s-fone-vietnam': '/pl/wietnam/doladowanie-s-fone',
      '/digi-malaysia': '/pl/malezja/doladowanie-digi',
      '/tkmobile-tajikistan': '/pl/tadzykistan/doladowanie-tkmobile',
      '/nextel-argentina': '/pl/argentyna/doladowanie-nextel',
      '/aircel-uttar-pradesh-east-india':
        '/pl/indie/doladowanie-aircel-uttar-pradesh-east',
      '/airtel-sri-lanka': '/pl/sri-lanka/doladowanie-airtel',
      '/m1-singapore': '/pl/singapur/doladowanie-m1',
      '/aircel-jk-india': '/pl/indie/doladowanie-aircel-jk',
      '/simple-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-simple-mobile',
      '/qcell-gambia': '/pl/gambia/doladowanie-qcell',
      '/beeline-ukraine': '/pl/ukraina/doladowanie-beeline',
      '/dtac-thailand': '/pl/taijlandia/doladowanie-dtac',
      '/digicel-suriname': '/pl/surinam/doladowanie-digicel',
      '/cell-c-south-africa': '/pl/afryka-poludniowa/doladowanie-cell-c',
      '/tak-tak-poland': '/pl/polska/doladowanie-tak-tak',
      '/cellcom-guinea': '/pl/gwinea/doladowanie-cellcom',
      '/mobilcat-spain': '/pl/hiszpania/doladowanie-mobilcat',
      '/indosat-im3-indonesia': '/pl/indonezja/doladowanie-indosat-im3',
      '/aircel-kolkata-india': '/pl/indie/doladowanie-aircel-kolkata',
      '/sun-cellular-philippines': '/pl/filipiny/doladowanie-sun-cellular',
      '/o-kyrgyzstan': '/pl/kirgistan/doladowanie-o',
      '/verizon-united-states': '/pl/stany-zjednoczone/doladowanie-verizon',
      '/dth-tata-sky-india': '/pl/indie/doladowanie-dth-tata-sky',
      '/claro-nicaragua': '/pl/nikaragua/doladowanie-claro',
      '/viva-kuwait': '/pl/kuwejt/doladowanie-viva',
      '/aircel-orissa-india': '/pl/indie/doladowanie-aircel-orissa',
      '/smarts-samara-russian-federation':
        '/pl/rosja/doladowanie-smarts-samara',
      '/afghan-wireless-afghanistan':
        '/pl/afganistan/doladowanie-afghan-wireless',
      '/reliance-india': '/pl/indie/doladowanie-reliance',
      '/mtn-afghanistan': '/pl/afganistan/doladowanie-mtn',
      '/three-telecom-indonesia': '/pl/indonezja/doladowanie-three-telecom',
      '/bluesky-american-samoa': '/pl/samoa/doladowanie-bluesky',
      '/mtn-cote-divoire': '/pl/wybreze-kosci-sloniowej/doladowanie-mtn',
      '/entel-chile': '/pl/chile/doladowanie-entel',
      '/viva-dominican-republic': '/pl/dominikana/doladowanie-viva',
      '/tunetalk-malaysia': '/pl/malezja/doladowanie-tunetalk',
      '/mtn-ghana': '/pl/ghana/doladowanie-mtn',
      '/claro-panama': '/pl/panama/doladowanie-claro',
      '/vivacell-mts-armenia': '/pl/armenia/doladowanie-vivacell-mts',
      '/aircel-maharashtra-india': '/pl/indie/doladowanie-aircel-maharashtra',
      '/vodafone-egypt': '/pl/egipt/doladowanie-vodafone',
      '/kyivstar-ukraine': '/pl/ukraina/doladowanie-kyivstar',
      '/flow-saint-vincent-and-the-grenadines':
        '/pl/swiety-wincenty-i-grenadyny/doladowanie-flow',
      '/vodafone-uttar-pradesh-east-india':
        '/pl/indie/doladowanie-vodafone-uttar-pradesh-east',
      '/digicel-saint-lucia': '/pl/swieta-lucia/doladowanie-digicel',
      '/digicel-martinique': '/pl/martynika/doladowanie-digicel',
      '/yoigo-spain': '/pl/hiszpania/doladowanie-yoigo',
      '/avantel-colombia': '/pl/kolumbia/doladowanie-avantel',
      '/smarts-cheboksary-russian-federation':
        '/pl/rosja/doladowanie-smarts-cheboksary',
      '/orange-france': '/pl/francja/doladowanie-orange',
      '/digicel-bermuda': '/pl/bermudy/doladowanie-digicel',
      '/vodafone-albania': '/pl/albania/doladowanie-vodafone',
      '/megafon-north-west-russian-federation':
        '/pl/rosja/doladowanie-megafon-north-west',
      '/claro-el-salvador': '/pl/salwador/doladowanie-claro',
      '/tim-brazil': '/pl/brazylia/doladowanie-tim',
      '/t-mobile-united-states': '/pl/stany-zjednoczone/doladowanie-t-mobile',
      '/megafon-siberia-russian-federation':
        '/pl/rosja/doladowanie-megafon-siberia',
      '/megacom-kyrgyzstan': '/pl/kirgistan/doladowanie-megacom',
      '/bsnl-india': '/pl/indie/doladowanie-bsnl',
      '/vtr-chile': '/pl/chile/doladowanie-vtr',
      '/moov-benin': '/pl/benin/doladowanie-moov',
      '/iusacell-mexico': '/pl/meksyk/doladowanie-iusacell',
      '/airtel-uganda': '/pl/uganda/doladowanie-airtel',
      '/polkomtel-plus-poland': '/pl/polska/doladowanie-polkomtel-plus',
      '/econet-zimbabwe': '/pl/zimbabwe/doladowanie-econet',
      '/claro-colombia': '/pl/kolumbia/doladowanie-claro',
      '/viva-bolivia': '/pl/boliwia/doladowanie-viva',
      '/etisalat-sri-lanka': '/pl/sri-lanka/doladowanie-etisalat',
      '/telemedia-belize': '/pl/belize/doladowanie-telemedia',
      '/camgsm-cambodia': '/pl/kambodza/doladowanie-camgsm',
      '/aircel-delhi-india': '/pl/indie/doladowanie-aircel-delhi',
      '/orange-central-african-republic':
        '/pl/republika-srodkowoafrykanska/doladowanie-orange',
      '/vodafone-punjab-india': '/pl/indie/doladowanie-vodafone-punjab',
      '/smarts-astrakhan-russian-federation':
        '/pl/rosja/doladowanie-smarts-astrakhan',
      '/claro-ecuador': '/pl/ekwador/doladowanie-claro',
      '/teletalk-bangladesh': '/pl/bangladesz/doladowanie-teletalk',
      '/bayn-gsm-morocco': '/pl/maroko/doladowanie-bayn-gsm',
      '/mtn-guinea-bissau': '/pl/gwinea-bissau/doladowanie-mtn',
      '/telcel-mexico': '/pl/meksyk/doladowanie-telcel',
      '/telkom-mobile-south-africa':
        '/pl/afryka-poludniowa/doladowanie-telkom-mobile',
      '/aircel-india': '/pl/indie/doladowanie-aircel',
      '/airtel-rwanda': '/pl/rwanda/doladowanie-airtel',
      '/airtel-kenya': '/pl/kenia/doladowanie-airtel',
      '/alegro-ecuador': '/pl/ekwador/doladowanie-alegro',
      '/beeline-lao-peoples-democratic-republic':
        '/pl/laos/doladowanie-beeline',
      '/cadcomms-cambodia': '/pl/kambodza/doladowanie-cadcomms',
      '/claro-costa-rica': '/pl/kostaryka/doladowanie-claro',
      '/claro-dominican-republic': '/pl/dominikana/doladowanie-claro',
      '/claro-guatemala': '/pl/gwatemala/doladowanie-claro',
      '/claro-honduras': '/pl/honduras/doladowanie-claro',
      '/cosmote-romania': '/pl/rumunia/doladowanie-cosmote',
      '/comcel-colombia': '/pl/kolumbia/doladowanie-comcel',
      '/cyfrowy-polsat-poland': '/pl/polska/doladowanie-cyfrowy-polsat',
      '/digicel-curacao': '/pl/curacao/doladowanie-digicel',
      '/digicel-guyana': '/pl/gujana/doladowanie-digicel',
      '/digicel-grenada': '/pl/grenada/doladowanie-digicel',
      '/digicel-haiti': '/pl/haiti/doladowanie-digicel',
      '/digicel-jamaica': '/pl/jamajka/doladowanie-digicel',
      '/digicel-saint-vincent-and-the-grenadines':
        '/pl/swiety-wincenty-i-grenadyny/doladowanie-digicel',
      '/evn-telecom-vietnam': '/pl/wietnam/doladowanie-evn-telecom',
      '/expresso-telecom-senegal': '/pl/senegal/doladowanie-expresso-telecom',
      '/full-movil-costa-rica': '/pl/kostaryka/doladowanie-full-movil',
      '/gt-mobile-spain': '/pl/hiszpania/doladowanie-gt-mobile',
      '/gtt-guyana': '/pl/gujana/doladowanie-gtt',
      '/hutchison-three-indonesia': '/pl/indonezja/doladowanie-hutchison-three',
      '/kolbi-costa-rica': '/pl/kostaryka/doladowanie-kolbi',
      '/flow-anguilla': '/pl/anguilla/doladowanie-flow',
      '/flow-barbados': '/pl/barbados/doladowanie-flow',
      '/flow-antigua-and-barbuda': '/pl/antigua-i-barbuda/doladowanie-flow',
      '/flow-cayman-islands': '/pl/kajmany/doladowanie-flow',
      '/flow-dominica': '/pl/dominika/doladowanie-flow',
      '/flow-grenada': '/pl/grenada/doladowanie-flow',
      '/flow-saint-kitts-and-nevis': '/pl/saint-kitts-i-nevis/doladowanie-flow',
      '/flow-jamaica': '/pl/jamajka/doladowanie-flow',
      '/flow-montserrat': '/pl/montserrat/doladowanie-flow',
      '/flow-saint-lucia': '/pl/swieta-lucia/doladowanie-flow',
      '/movicel-angola': '/pl/angola/doladowanie-movicel',
      '/moun-dominican-republic': '/pl/dominikana/doladowanie-moun',
      '/movistar-argentina': '/pl/argentyna/doladowanie-movistar',
      '/movistar-costa-rica': '/pl/kostaryka/doladowanie-movistar',
      '/movistar-colombia': '/pl/kolumbia/doladowanie-movistar',
      '/movistar-guatemala': '/pl/gwatemala/doladowanie-movistar',
      '/movistar-panama': '/pl/panama/doladowanie-movistar',
      '/mts-armenia': '/pl/armenia/doladowanie-mts',
      '/netone-zimbabwe': '/pl/zimbabwe/doladowanie-netone',
      '/orange-dominican-republic': '/pl/dominikana/doladowanie-orange',
      '/orange-cameroon': '/pl/kamerun/doladowanie-orange',
      '/orange-guinea': '/pl/gwinea/doladowanie-orange',
      '/orange-cote-divoire': '/pl/wybreze-kosci-sloniowej/doladowanie-orange',
      '/orange-congo-the-democratic-republic-of-the':
        '/pl/kongo/doladowanie-orange',
      '/telkom-kenya': '/pl/kenia/doladowanie-telkom',
      '/tigo-guatemala': '/pl/gwatemala/doladowanie-tigo',
      '/tigo-honduras': '/pl/honduras/doladowanie-tigo',
      '/t-mobile-poland': '/pl/polska/doladowanie-t-mobile',
      '/togocel-togo': '/pl/togo/doladowanie-togocel',
      '/uninor-india': '/pl/indie/doladowanie-uninor',
      '/brasil-telecom-brazil': '/pl/brazylia/doladowanie-brasil-telecom',
      '/claro-jamaica': '/pl/jamajka/doladowanie-claro',
      '/digicel-honduras': '/pl/honduras/doladowanie-digicel',
      '/easy-go-united-states': '/pl/stany-zjednoczone/doladowanie-easy-go',
      '/h2o-united-states': '/pl/stany-zjednoczone/doladowanie-h2o',
      '/voila-haiti': '/pl/haiti/doladowanie-voila',
      '/idirect-dial-united-states':
        '/pl/stany-zjednoczone/doladowanie-idirect-dial',
      '/myphone2go-ild-united-states':
        '/pl/stany-zjednoczone/doladowanie-myphone2go-ild',
      '/net-10-united-states': '/pl/stany-zjednoczone/doladowanie-net-10',
      '/nextel-mexico': '/pl/meksyk/doladowanie-nextel',
      '/rok-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-rok-mobile',
      '/smart-philippines': '/pl/filipiny/doladowanie-smart',
      '/talk-talk-now-united-states':
        '/pl/stany-zjednoczone/doladowanie-talk-talk-now',
      '/terracom-united-states': '/pl/stany-zjednoczone/doladowanie-terracom',
      '/ucell-uzbekistan': '/pl/uzbekistan/doladowanie-ucell',
      '/yourtel-united-states': '/pl/stany-zjednoczone/doladowanie-yourtel',
      '/mtn-zambia': '/pl/zambia/doladowanie-mtn',
      '/zantel-tanzania-united-republic-of': '/pl/tanzania/doladowanie-zantel',
      '/vodafone-kktc-turkey': '/pl/turcja/doladowanie-vodafone-kktc',
      '/univision-united-states': '/pl/stany-zjednoczone/doladowanie-univision',
      '/gt-mobile-france': '/pl/francja/doladowanie-gt-mobile',
      '/bali-georgia': '/pl/gruzja/doladowanie-bali',
      '/onevip-macedonia': '/pl/macedonia/doladowanie-onevip',
      '/tele2-lithuania': '/pl/litwa/doladowanie-tele2',
      '/life-belarus': '/pl/białoruś/doladowanie-life',
      '/mts-belarus': '/pl/białoruś/doladowanie-mts',
      '/airtel-madagascar': '/pl/madagaskar/doladowanie-airtel',
      '/smart-gold-philippines': '/pl/filipiny/doladowanie-smart-gold',
      '/smart-bro-philippines': '/pl/filipiny/doladowanie-smart-bro',
      '/beeline-uzbekistan': '/pl/uzbekistan/doladowanie-beeline',
      '/movistar-uruguay': '/pl/urugwaj/doladowanie-movistar',
      '/glo-benin': '/pl/benin/doladowanie-glo',
      '/digicel-french-guiana': '/pl/gujana-francuska/doladowanie-digicel',
      '/orange-uganda': '/pl/uganda/doladowanie-orange',
      '/t-mobile-united-kingdom': '/pl/wielka-brytania/doladowanie-t-mobile',
      '/sfr-france': '/pl/francja/doladowanie-sfr',
      '/bob-austria': '/pl/austria/doladowanie-bob',
      '/b-free-austria': '/pl/austria/doladowanie-b-free',
      '/tele-dot-ring-austria': '/pl/austria/doladowanie-telering',
      '/lycamobile-austria': '/pl/austria/doladowanie-lycamobile',
      '/nimm3-austria': '/pl/austria/doladowanie-nimm3',
      '/3reload-austria': '/pl/austria/doladowanie-3reload',
      '/vectone-austria': '/pl/austria/doladowanie-vectone',
      '/eety-austria': '/pl/austria/doladowanie-eety',
      '/ee-united-kingdom': '/pl/wielka-brytania/doladowanie-ee',
      '/cricket-paygo-united-states':
        '/pl/stany-zjednoczone/doladowanie-cricket-paygo',
      '/boost-united-states': '/pl/stany-zjednoczone/doladowanie-boost',
      '/du-more-united-arab-emirates':
        '/pl/zjednoczone-emiraty-arabskie/doladowanie-du-more',
      '/kcell-kazakhstan': '/pl/kazachstan/doladowanie-kcell',
      '/altel-kazakhstan': '/pl/kazachstan/doladowanie-altel',
      '/activ-kazakhstan': '/pl/kazachstan/doladowanie-activ',
      '/beeline-kazakhstan': '/pl/kazachstan/doladowanie-beeline',
      '/tele2-kazakhstan': '/pl/kazachstan/doladowanie-tele2',
      '/airtel-zambia': '/pl/zambia/doladowanie-airtel',
      '/airtel-tanzania-united-republic-of': '/pl/tanzania/doladowanie-airtel',
      '/megafon-center-south-russian-federation':
        '/pl/rosja/doladowanie-megafon-center-south',
      '/claro-paraguay': '/pl/paragwaj/doladowanie-claro',
      '/ooredoo-myanmar': '/pl/myanmar/doladowanie-ooredoo',
      '/mec-myanmar': '/pl/myanmar/doladowanie-mec',
      '/airtel-malawi': '/pl/malawi/doladowanie-airtel',
      '/aircel-uttar-pradesh-west-india':
        '/pl/indie/doladowanie-aircel-uttar-pradesh-west',
      '/virgin-mobile-mexico': '/pl/meksyk/doladowanie-virgin-mobile',
      '/maz-mexico': '/pl/meksyk/doladowanie-maz',
      '/cierto-mexico': '/pl/meksyk/doladowanie-cierto',
      '/alo-mexico': '/pl/meksyk/doladowanie-alo',
      '/touch-lebanon': '/pl/liban/doladowanie-touch',
      '/alfa-lebanon': '/pl/liban/doladowanie-alfa',
      '/aircel-kerala-india': '/pl/indie/doladowanie-aircel-kerala',
      '/tuenti-ecuador': '/pl/ekwador/doladowanie-tuenti',
      '/smart-cambodia': '/pl/kambodza/doladowanie-smart',
      '/zain-bahrain': '/pl/bahrajn/doladowanie-zain',
      '/viva-bahrain': '/pl/bahrajn/doladowanie-viva',
      '/freedom-mobile-canada': '/pl/kanada/doladowanie-freedom-mobile',
      '/virgin-canada': '/pl/kanada/doladowanie-virgin',
      '/telus-canada': '/pl/kanada/doladowanie-telus',
      '/solo-canada': '/pl/kanada/doladowanie-solo',
      '/rogers-canada': '/pl/kanada/doladowanie-rogers',
      '/publicmobile-canada': '/pl/kanada/doladowanie-publicmobile',
      '/mts-canada': '/pl/kanada/doladowanie-mts',
      '/koodo-canada': '/pl/kanada/doladowanie-koodo',
      '/fido-canada': '/pl/kanada/doladowanie-fido',
      '/chatr-canada': '/pl/kanada/doladowanie-chatr',
      '/bell-canada': '/pl/kanada/doladowanie-bell',
      '/zain-saudi-arabia': '/pl/arabia-saudyjska/doladowanie-zain',
      '/virgin-saudi-arabia': '/pl/arabia-saudyjska/doladowanie-virgin',
      '/stc-saudi-arabia': '/pl/arabia-saudyjska/doladowanie-stc',
      '/mobily-saudi-arabia': '/pl/arabia-saudyjska/doladowanie-mobily',
      '/lebara-saudi-arabia': '/pl/arabia-saudyjska/doladowanie-lebara',
      '/friendi-saudi-arabia': '/pl/arabia-saudyjska/doladowanie-friendi',
      '/allo-saudi-arabia': '/pl/arabia-saudyjska/doladowanie-allo',
      '/virgin-mobile-south-africa':
        '/pl/afryka-poludniowa/doladowanie-virgin-mobile',
      '/vodafone-qatar': '/pl/qatar/doladowanie-vodafone',
      '/ooredoo-qatar': '/pl/qatar/doladowanie-ooredoo',
      '/at-and-t-puerto-rico': '/pl/portoryko/doladowanie-at-t',
      '/renna-mobile-oman': '/pl/oman/doladowanie-renna-mobile',
      '/omantel-oman': '/pl/oman/doladowanie-omantel',
      '/friendi-mobile-oman': '/pl/oman/doladowanie-friendi-mobile',
      '/movitel-mozambique': '/pl/zambia/doladowanie-movitel',
      '/yallo-switzerland': '/pl/szwajcaria/doladowanie-yallo',
      '/swisscom-switzerland': '/pl/mołdawia/doladowanie-swisscom',
      '/sunrise-switzerland': '/pl/szwajcaria/doladowanie-sunrise',
      '/salt-mobile-switzerland': '/pl/szwajcaria/doladowanie-salt-mobile',
      '/ortel-mobile-switzerland': '/pl/szwajcaria/doladowanie-ortel-mobile',
      '/mucho-mobile-switzerland': '/pl/szwajcaria/doladowanie-mucho-mobile',
      '/lycamobile-switzerland': '/pl/szwajcaria/doladowanie-lycamobile',
      '/lebara-switzerland': '/pl/szwajcaria/doladowanie-lebara',
      '/tatem-congo': '/pl/kongo/doladowanie-tatem',
      '/tracfone-united-states': '/pl/stany-zjednoczone/doladowanie-tracfone',
      '/telcel-america-united-states':
        '/pl/stany-zjednoczone/doladowanie-telcel-america',
      '/sti-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-sti-mobile',
      '/real-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-real-mobile',
      '/ready-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-ready-mobile',
      '/page-plus-united-states': '/pl/stany-zjednoczone/doladowanie-page-plus',
      '/good2go-united-states': '/pl/stany-zjednoczone/doladowanie-good2go',
      '/cintex-wireless-united-states':
        '/pl/stany-zjednoczone/doladowanie-cintex-wireless',
      '/red-pocket-united-states':
        '/pl/stany-zjednoczone/doladowanie-red-pocket',
      '/urcall-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-urcall-mobile',
      '/life-wireless-united-states':
        '/pl/stany-zjednoczone/doladowanie-life-wireless',
      '/airvoice-united-states': '/pl/stany-zjednoczone/doladowanie-airvoice',
      '/access-wireless-united-states':
        '/pl/stany-zjednoczone/doladowanie-access-wireless',
      '/etisalat-united-arab-emirates':
        '/pl/zjednoczone-emiraty-arabskie/doladowanie-etisalat',
      '/smart-cell-nepal': '/pl/nepal/doladowanie-smart-cell',
      '/tn-mobile-namibia': '/pl/namibia/doladowanie-tn-mobile',
      '/mtc-namibia': '/pl/namibia/doladowanie-mtc',
      '/orange-botswana': '/pl/botswany/doladowanie-orange',
      '/mascom-botswana': '/pl/botswany/doladowanie-mascom',
      '/bemobile-botswana': '/pl/botswany/doladowanie-bemobile',
      '/batelco-bahrain': '/pl/bahrajn/doladowanie-batelco',
      '/cvmovel-cape-verde':
        '/pl/wyspy-zielonego-przyladka/doladowanie-cvmovel',
      '/three-united-kingdom': '/pl/wielka-brytania/doladowanie-three',
      '/asda-mobile-united-kingdom':
        '/pl/wielka-brytania/doladowanie-asda-mobile',
      '/giff-gaff-united-kingdom': '/pl/wielka-brytania/doladowanie-giff-gaff',
      '/lycamobile-united-kingdom':
        '/pl/wielka-brytania/doladowanie-lycamobile',
      '/talk-home-mobile-united-kingdom':
        '/pl/wielka-brytania/doladowanie-talk-home-mobile',
      '/o2-united-kingdom': '/pl/wielka-brytania/doladowanie-o2',
      '/vodafone-united-kingdom': '/pl/wielka-brytania/doladowanie-vodafone',
      '/virgin-mobile-united-kingdom':
        '/pl/wielka-brytania/doladowanie-virgin-mobile',
      '/you-mobile-spain': '/pl/hiszpania/doladowanie-you-mobile',
      '/republica-movile-spain': '/pl/hiszpania/doladowanie-republica-movile',
      '/lcr-movile-spain': '/pl/hiszpania/doladowanie-lcr-movile',
      '/hablapp-spain': '/pl/hiszpania/doladowanie-hablapp',
      '/wind-italy': '/pl/kanada/doladowanie-wind',
      '/vodafone-italy': '/pl/wlochy/doladowanie-vodafone',
      '/tim-italy': '/pl/wlochy/doladowanie-tim',
      '/3-tre-italy': '/pl/wlochy/doladowanie-3 tre',
      '/bluesky-samoa': '/pl/kanada/doladowanie-bluesky',
      '/telenor-serbia': '/pl/serbia/doladowanie-telenor',
      '/orange-burkina-faso': '/pl/burkina-faso/doladowanie-orange',
      '/delight-austria': '/pl/austria/doladowanie-delight',
      '/t-mobile-austria': '/pl/austria/doladowanie-t-mobile',
      '/m-tel-austria': '/pl/austria/doladowanie-m-tel',
      '/optus-australia': '/pl/australia/doladowanie-optus',
      '/orange-liberia': '/pl/liberia/doladowanie-orange',
      '/smile-uganda': '/pl/uganda/doladowanie-smile',
      '/talkout-spain': '/pl/hiszpania/doladowanie-talkout',
      '/clm-spain': '/pl/hiszpanzia/doladowanie-clm',
      '/uganda-telecom-uganda': '/pl/uganda/doladowanie-uganda-telecom',
      '/mundimovil-spain': '/pl/hiszpania/doladowanie-mundimovil',
      '/yu-kenya': '/pl/kenia/doladowanie-yu',
      '/jazzcard-movil-spain': '/pl/hiszpania/doladowanie-jazzcard-movil',
      '/smart-tanzania-united-republic-of': '/pl/tanzania/doladowanie-smart',
      '/moov-gabon': '/pl/gabon/doladowanie-moov',
      '/best-movil-spain': '/pl/hiszpania/doladowanie-best-movil',
      '/movistar-mexico': '/pl/meksyk/doladowanie-movistar',
      '/vodafone-india': '/pl/india/doladowanie-vodafone',
      '/lyca-international-united-states':
        '/pl/stany-zjednoczone/doladowanie-lyca-international',
      '/lycamobile-unlimited-international-united-states':
        '/pl/stany-zjednoczone/doladowanie-lycamobile-unlimited-international',
      '/symacom-france': '/pl/francja/doladowanie-symacom',
      '/la-poste-mobile-france': '/pl/francja/doladowanie-la-poste-mobile',
      '/lebara-united-kingdom': '/pl/wielka-brytania/doladowanie-lebara',
      '/tesco-mobile-united-kingdom':
        '/pl/wielka-brytania/doladowanie-tesco-mobile',
      '/nrj-france': '/pl/francja/doladowanie-nrj',
      '/metropcs-united-states': '/pl/stany-zjednoczone/doladowanie-metropcs',
      '/virgin-mobile-united-states':
        '/pl/stany-zjednoczone/doladowanie-virgin-mobile',
      '/lycamobile-portugal': '/pl/portugalia/doladowanie-lycamobile',
      '/vectone-mobile-portugal': '/pl/portugalia/doladowanie-vectone-mobile',
      '/vodafone-portugal': '/pl/portugalia/doladowanie-vodafone',
      '/uzo-portugal': '/pl/portugalia/doladowanie-uzo',
      '/nos-portugal': '/pl/portugalia/doladowanie-nos',
      '/moche-portugal': '/pl/portugalia/doladowanie-moche',
      '/meo-portugal': '/p/portugalia/doladowanie-meo',
      '/ttcl-tanzania-united-republic-of': '/pl/portugalia/doladowanie-ttcl',
      '/halotel-tanzania-united-republic-of':
        '/pl/tanzania/doladowanie-halotel',
      '/reliance-jio-india': '/pl/india/doladowanie-reliance',
      '/etl-lao-peoples-democratic-republic': '/pl/laos/doladowanie-etl',
      '/kraje': '/pl/kraje',
    },
    pl: {
      '/guild-wars': '/pl-pl/kody-do-gier',
    },
  },
  nb: {
    no: {
      '/guild-wars': '/nb-no/spill-gavekortene',
    },
  },
  sv: {
    se: {
      '/guild-wars': '/sv-se/spelpresentkoder',
    },
  },
})
