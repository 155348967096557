import { STEPS } from './enums/steps'
import { IFormField } from '~/factories/FormField'
import { ICheckoutPaymentMethod } from '~/models/components/CheckoutPaymentMethod'
import {
  IAsyncStoreState,
  AsyncStoreState,
} from '~/factories/async-store/State'
import { IBreadcrumb } from '~/components/molecules/breadcrumbs/interfaces'
export interface ICheckoutSection {
  isActive: boolean
  isFilled: boolean
  isLoading?: boolean
}
export interface ICheckoutSections {
  email?: ICheckoutSection
  paymentMethods?: ICheckoutSection
  billingInfoForm?: ICheckoutSection
}
export interface IUiCheckoutState {
  paymentMethods: ICheckoutPaymentMethod[]
  submit: IAsyncStoreState
  resendCode: IAsyncStoreState
  submitVerificationCode: IAsyncStoreState
  validateRTRPhoneNumber: IAsyncStoreState
  hasCertifiedBadge: boolean
  hasExtraPaymentInformation: boolean
  hasTermsAndConditions: boolean
  checkoutSections: ICheckoutSections
  billingInfoForm: {
    visible: boolean
    fields: IFormField[]
    values: string
  }
  paymentMethodForm: {
    enabled: boolean
  }
  step: STEPS
  breadcrumbs: IBreadcrumb[]
  termsAndConditionsUrl: string
}

export const defaultState: IUiCheckoutState = {
  paymentMethods: [],
  submit: AsyncStoreState(),
  submitVerificationCode: AsyncStoreState(),
  validateRTRPhoneNumber: AsyncStoreState(),
  resendCode: AsyncStoreState(),
  hasCertifiedBadge: false,
  hasExtraPaymentInformation: false,
  hasTermsAndConditions: false,
  checkoutSections: {
    email: {
      isActive: true,
      isFilled: false,
      isLoading: false,
    },
    paymentMethods: {
      isActive: false,
      isFilled: false,
      isLoading: false,
    },
    billingInfoForm: {
      isActive: false,
      isFilled: false,
      isLoading: false,
    },
  },
  billingInfoForm: {
    fields: [],
    visible: false,
    values: '',
  },
  paymentMethodForm: {
    enabled: false,
  },
  step: STEPS.initial,
  breadcrumbs: [
    {
      label: 'Payment Form',
      title: 'Payment Form',
      goBack: false,
      id: STEPS.initial,
    },
  ],
  termsAndConditionsUrl: '',
}

export function state(): IUiCheckoutState {
  return defaultState
}

export default state
