const cloneDeep = <T>(obj: T): T => {
  if (typeof obj !== 'object' || obj === null) return obj

  if (Array.isArray(obj)) {
    return obj.reduce((result, prop) => [...result, cloneDeep(prop)], [])
  }

  const shallowCopy = { ...obj }

  return Object.keys(obj).reduce((result, prop) => {
    return { ...result, [prop]: cloneDeep(obj[prop]) }
  }, shallowCopy)
}

export default cloneDeep
