import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { IImageModel } from '../base/Image'
import { ExternalLinkModel, ICMSExternalLinkModel } from '../base/ExternalLink'
import { TLink, TCMSLink } from '../base/Link'
import {
  IHeaderLink,
  ICMSHeaderLink,
  HeaderLinkModel,
} from '../components/HeaderLink'
import { UspsModel, IUsps, ICMSUsps } from './Usps'
import { IFooter } from './Footer'
import get from '~/utils/get'
import { ICMSStructure } from '~/models/layout/CMSStructure'
import mapWithFilter from '~/services/map-with-filter'

export interface IHeader {
  logo: IImageModel
  menu?: IHeaderLink[]
  usps: IUsps[]
  showAccount: boolean
  legalLink?: TLink
  secondaryLinks: TLink[]
}

export interface ICMSHeader extends Entry {
  fields: {
    menu?: ICMSHeaderLink[]
    usps: ICMSUsps[]
    showAccount: boolean
    legalLink?: ICMSExternalLinkModel | ICMSStructure
    serviceLinks: TCMSLink[]
  }
}

export interface IHeaderParams {
  logo: IImageModel
  footer: IFooter
  header: ICMSHeader
}

export class HeaderModel extends EntryModel {
  protected readonly logo: IImageModel
  protected readonly menu?: IHeaderLink[]
  protected readonly usps: IUsps[]
  protected readonly showAccount: boolean
  protected readonly legalLink: TLink
  protected readonly secondaryLinks: TLink[]

  constructor({ header, footer, logo }: IHeaderParams) {
    super(header)
    const { fields } = header
    const { menu, showAccount, legalLink } = fields
    const {
      footerLinksGroup: [{ groupLinks }],
    } = footer

    this.logo = logo

    if (menu) {
      this.menu = mapWithFilter(
        menu,
        headerLink => new HeaderLinkModel(headerLink).toJSON(),
        'fields'
      )
    }

    this.usps = this.requiredField('usps').map(uspsItem =>
      new UspsModel(uspsItem).toJSON()
    )
    this.showAccount = showAccount

    if (legalLink) {
      this.legalLink = HeaderModel.formatLegalLink(legalLink)
    }

    this.secondaryLinks = groupLinks
  }

  static formatLegalLink(
    legalLink: ICMSExternalLinkModel | ICMSStructure
  ): TLink {
    const type = get(legalLink, 'sys.contentType.sys.id')
    if (type === 'page') {
      const { slug, title } = get(legalLink, 'fields')
      return { title, url: slug }
    }

    return new ExternalLinkModel(legalLink as ICMSExternalLinkModel).toJSON()
  }

  toJSON(): IHeader {
    return {
      logo: this.logo,
      menu: this.menu,
      usps: this.usps,
      showAccount: this.showAccount,
      legalLink: this.legalLink,
      secondaryLinks: this.secondaryLinks,
    }
  }
}
