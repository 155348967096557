import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { ISEO, CMSSeoNormalizer } from './seo'
import get from '~/utils/get'
import camelCase from '~/utils/camelCase'

import { LayoutModel, ILayout } from '~/models/layout/Layout'
import { TPageType } from '~/models/base/Page'
import { PageStructureModel } from '~/models/layout/PageStructure'
import { ISection } from '~/factories/Section'
import { IProduct, ICMSProductModel } from '~/models/components/Product'
import { IProductList } from '~/models/components/ProductList'
import {
  ParentPageInfoModel,
  IParentPageInfo,
} from '~/models/components/ParentPageInfo'
import { ICMSBrandFallbackInfoModel } from '~/models/components/BrandInfoFallbackModel'
import {
  IBrandInfo,
  BrandInfoModel,
  ICMSBrandInfo,
} from '~/models/components/BrandInfo'

export interface ICMSData {
  merchant: Entry
  country: Entry
  title: string
  pageTitle: string
  sections?: Entry[]
  brandInfo?: ICMSBrandFallbackInfoModel | ICMSBrandInfo
  productInfo?: ICMSProductModel
  pageType: TPageType
  path: string
  metaDescription?: string
  metaRobots?: string
  metaCanonicalLink?: string
  metaHreflangLinks?: string[]
  structuredData?: unknown
  parentPath: string
  lang: string
  pathTranslations: Record<string, string>
}

export interface IBrandNavigationLink {
  title: string
  scrollPath: string
  productQuantity: number
}

export interface ICMSNormalizedData {
  pageType: TPageType
  title: string
  brandInfo?: IBrandInfo
  seo: ISEO
  layout: ILayout
  parentPageInfo?: IParentPageInfo
  structure: ISection[]
  products?: IProduct[]
  brandNavigationLinks: IBrandNavigationLink[]
  pathTranslations: Record<string, string>
}

export class CMSPageNormalizer {
  protected readonly pageType: TPageType
  protected readonly title: string
  protected readonly brandInfo?: IBrandInfo
  protected readonly seo: ISEO
  protected readonly layout: ILayout
  protected readonly parentPageInfo?: IParentPageInfo
  protected readonly structure: ISection[]
  protected readonly products?: IProduct[]
  protected readonly brandNavigationLinks: IBrandNavigationLink[]
  protected readonly pathTranslations: Record<string, string>

  constructor(cmsData: ICMSData) {
    this.layout = CMSPageNormalizer.normalizeLayout(cmsData)
    this.seo = new CMSSeoNormalizer(cmsData).toJSON()

    const { sections, brandInfo, productInfo, pageType, title } = cmsData
    this.pageType = pageType
    this.brandInfo = brandInfo
      ? new BrandInfoModel(brandInfo as ICMSBrandInfo).toJSON()
      : undefined
    this.title = title
    this.parentPageInfo = CMSPageNormalizer.normalizeParentPageInfo(productInfo)

    this.pathTranslations = CMSPageNormalizer.normalizePathTranslations(cmsData)

    const { structure, products } = new PageStructureModel(sections, {
      brandInfo: brandInfo as ICMSBrandFallbackInfoModel,
      productInfo,
    }).toJSON()

    this.structure = structure
    this.products = products
    this.brandNavigationLinks = CMSPageNormalizer.normalizeBrandNavigationLinks(
      structure
    )
  }

  public static normalizeLayout(cmsData: ICMSData): ILayout {
    return new LayoutModel(cmsData).toJSON()
  }

  private static normalizeParentPageInfo(
    productInfo: ICMSProductModel | undefined
  ): IParentPageInfo | undefined {
    if (!productInfo) return undefined

    const parentPage = get(
      productInfo,
      'fields.parentBrand.fields.parentPage',
      {}
    )

    return parentPage.fields
      ? new ParentPageInfoModel(parentPage).toJSON()
      : undefined
  }

  private static normalizeBrandNavigationLinks(
    structure: ISection[]
  ): IBrandNavigationLink[] {
    const productLists = structure.filter(
      ({ sectionName }) => sectionName === 'product-list'
    )

    const brandNavLinks = productLists.map(({ props }) => {
      const { title = '', products = [] } = props as IProductList
      const productListDOMId = `#${title.toLowerCase().replace(/[\s&]+/g, '_')}` // example: #popular_products
      return {
        title,
        scrollPath: productListDOMId,
        productQuantity: products.length,
      }
    })

    return brandNavLinks
  }

  private static normalizePathTranslations({
    pathTranslations,
    country,
  }): Record<string, string> {
    const langs = get(country, 'fields.langs', [])

    const translatesByAvailableLangs = langs.reduce((acc, currentLang) => {
      const pathTranslationForLang = pathTranslations[currentLang]
      if (!pathTranslationForLang) return acc

      return {
        ...acc,
        [currentLang]: pathTranslationForLang.replace('recharge.com', ''),
      }
    }, {})

    return translatesByAvailableLangs
  }

  toJSON(): ICMSNormalizedData {
    return {
      seo: this.seo,
      layout: this.layout,
      parentPageInfo: this.parentPageInfo,
      products: this.products,
      structure: this.structure,
      brandNavigationLinks: this.brandNavigationLinks,
      pageType: this.pageType,
      title: this.title,
      brandInfo: this.brandInfo,
      pathTranslations: this.pathTranslations,
    }
  }
}

export function normalizePageComponents<T>({
  components,
  productListComponent,
  brandInfo,
  componentsToAggregate,
}: {
  components: any[]
  componentsToAggregate?: string[]
  productListComponent?
  brandInfo?
}): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, T | any> {
  const initialState = {} as Record<string, T>

  if (productListComponent)
    initialState.productListComponent = productListComponent
  if (brandInfo) initialState.brandInfo = brandInfo
  if (!components) {
    return initialState
  }

  return components.reduce((accumulator, component, index) => {
    const { sectionName } = component
    const camelCasedSectionName = camelCase(sectionName)

    const shouldAggregate = componentsToAggregate?.includes(
      camelCasedSectionName
    )

    if (shouldAggregate) {
      accumulator[camelCasedSectionName] =
        accumulator[camelCasedSectionName] || []
      accumulator[camelCasedSectionName].push({ ...component, index })
    } else {
      accumulator[camelCasedSectionName] = { ...component, index }
    }

    return accumulator
  }, initialState)
}
