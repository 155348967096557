export default {
  'amazon-de': 'amazon',
  'amazon-de/10-eur': 'amazon',
  'amazon-de/100-eur': 'amazon',
  'amazon-de/15-eur': 'amazon',
  'amazon-de/25-eur': 'amazon',
  'amazon-de/50-eur': 'amazon',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  'battlenet/50-eur': 'blizzard',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  cosmote: 'cosmote',
  'crypto-voucher': 'crypto-voucher',
  diadikasia: 'how-it-works',
  'dikaioma-upanaxorisis': 'how-it-works',
  'dilosi-aporritou': 'privacy-statement',
  'dilosi-cookies': 'cookie-statement',
  'dorokartes-agoron': 'shopping-gift-cards',
  'dorokartes-paixnidion': 'gaming-cards',
  'dorokartes-psychagogias': 'entertainment-gift-cards',
  'ea-origin': 'ea-origin',
  'ea-origin/15-eur': 'ea-origin',
  'ea-origin/30-eur': 'ea-origin',
  ecovoucher: 'ecovoucher',
  'eve-online': 'gaming-cards',
  flexepin: 'flexepin',
  'free-fire': 'free-fire',
  'karma-koin': 'karma-koin',
  'karma-koin/10-eur': 'karma-koin',
  'karma-koin/50-eur': 'karma-koin',
  'kinito-top-up': 'mobile-top-up',
  kryptonomisma: 'cryptocurrency',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends',
  'league-of-legends/20-eur': 'league-of-legends',
  ncoin: 'ncoin',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'netflix/15-eur': 'netflix',
  'netflix/25-eur': 'netflix',
  'netflix/50-eur': 'netflix',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop',
  'nintendo-eshop/25-eur': 'nintendo-eshop',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-mines': 'nintendo-switch-online',
  'nintendo-switch-online/3-mines': 'nintendo-switch-online',
  'openbucks-us': 'openbucks',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/3-months': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/12-mines': 'playstation-store',
  'playstation-store/20-eur': 'playstation-store',
  'playstation-store/50-eur': 'playstation-store',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'propliromenes-kartes': 'prepaid-credit-cards',
  'pubg-us': 'pubg',
  'q-telecom': 'q-telecom',
  roblox: 'roblox',
  'roblox/50-eur-xbox-one': 'roblox',
  'roblox/7-eur-xbox-one': 'roblox',
  runescape: 'runescape',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/21-eur': 'spotify-premium',
  'spotify-premium/42-eur': 'spotify-premium',
  'spotify-premium/7-eur': 'spotify-premium',
  steam: 'steam',
  'steam/10-eur': 'steam',
  'steam/100-eur': 'steam',
  'steam/20-eur': 'steam',
  'steam/25-eur': 'steam',
  'steam/5-eur': 'steam',
  'steam/50-eur': 'steam',
  'sxetika-me-emas': 'https://company.recharge.com',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'tropoi-pliromis': 'payment-methods',
  vodafone: 'vodafone',
  wind: 'wind',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-imeres': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/12-months': 'xbox-live-gold',
  'xbox-live-gold/3-months': 'xbox-live-gold',
  'apex-legends': 'gaming-cards',
  'prepaid-visa-us': 'prepaid-credit-cards',
  'jeton-cash-eur': 'jeton-cash',
  astropay: 'astropay',
  'prepaid-amex-us': 'prepaid-credit-cards',
}
