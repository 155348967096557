import Vue from 'vue'
import { Context, Plugin } from '@nuxt/types'

const PhraseappPlugin: Plugin = ({ app, route }: Context): void => {
  const { i18n } = app
  const showPhraseappKeys = route.query.showKeys

  if (!showPhraseappKeys) return

  i18n.t = (key: string): string => `phrase__${key}`
  Vue.prototype.$t = (key: string): string => {
    return `phrase__${key}`
  }
}

export default PhraseappPlugin
