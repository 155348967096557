import { Middleware, Context } from '@nuxt/types'
import currencies from '../../config/i18n/config/recommerceCurrencies.json'
import { RequestError } from '~/apis/error/RequestError'
import {
  DEFAULT_COOKIE_SETTINGS,
  GMP_LOCALE_COOKIE_NAME,
} from '~/utils/constants/cookies'

const setContextConfiguration: Middleware = async ({
  store,
  i18n,
  req,
  route,
  app: { $cookies, $sentry },
}: Context): Promise<void> => {
  if (store.getters['context/isLocalMarketplace']) return
  const { language, country } = route.params
  const locale = `${language}/${country}`
  const allLocales = i18n.locales.map(locale => locale.code)

  if (!allLocales.includes(locale))
    throw new RequestError({
      statusCode: 404,
      message: 'Language/country combination is not supported',
    })

  await i18n.setLocale(locale)

  const unsafeCurrency =
    route.query.currency || $cookies.get('currency', { parseJSON: false })

  const validUnsafeCurrency = currencies
    .map(({ code }) => code)
    .includes(
      Array.isArray(unsafeCurrency) ? unsafeCurrency[0] || '' : unsafeCurrency
    )
  const currency = validUnsafeCurrency
    ? unsafeCurrency
    : i18n.localeProperties.country.defaultCurrency?.code || 'EUR'

  store.dispatch('context/setLocaleProperties', { ...i18n.localeProperties })
  store.commit('context/setCurrency', currency)

  store.dispatch('context/validateOriginRechargeClassicMerchant', { $cookies })
  store.dispatch('context/translateCountriesAndCurrencies')

  $sentry.configureScope(scope => {
    scope.setTag('redemptionCountry', country)
    scope.setTag('language', language)
    scope.setTag('currency', currency)
    scope.setTag('direction', i18n.localeProperties.direction)
  })

  if (process.server) {
    $cookies.set(GMP_LOCALE_COOKIE_NAME, language, DEFAULT_COOKIE_SETTINGS)
    $cookies.set('currency', currency, DEFAULT_COOKIE_SETTINGS)
    $sentry.configureScope(scope => {
      scope.setTag('customerCountry', req.headers['cloudfront-viewer-country'])
    })

    store.dispatch(
      'context/setCustomerCountry',
      process.env.NODE_ENV === 'development'
        ? 'pl'
        : req.headers['cloudfront-viewer-country']
    )
  } else {
    $sentry.configureScope(scope => {
      scope.setTag(
        'customerCountry',
        store.getters['context/customerCountry'].code
      )
    })
  }
}
export default setContextConfiguration
