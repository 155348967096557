import { Auth } from '@aws-amplify/auth'

export default {
  methods: {
    async signOut(): Promise<void> {
      this.$nuxt.$loading.start()
      try {
        const iframe = document.createElement('iframe')
        iframe.style.width = '0'
        iframe.style.height = '0'
        iframe.setAttribute(
          'src',
          `https://${process.env.AWS_OAUTH_DOMAIN}/logout?client_id=${process.env.AWS_USER_POOL_WEB_CLIENT_ID}&logout_uri=${window.location.origin}/oauth/sign-out`
        )
        document.body.appendChild(iframe)

        window.addEventListener(
          'message',
          message =>
            (process.env.APP_ENV === 'production'
              ? message.origin === window.location.origin
              : true) &&
            message.data === 'sign-out' &&
            Auth.signOut() &&
            document.body.removeChild(iframe) &&
            this.$nuxt.$loading.finish(),
          { once: true }
        )
      } catch (error) {
        this.$sentry.captureException(error)

        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(error)
        this.$notifications.pushError({
          text: this.$t('authentication.sign_out.error') as string,
          closeAfter: 4000,
        })
        this.$nuxt.$loading.finish()
      }
    },
  },
}
