import {
  RecommerceProduct,
  RecommerceVariant,
} from '~/apis/clients/rest/recommerce/types'
import { reorderAnalyticsData } from '~/plugins/gtm/models/AddToCart'
import { IReorderModel } from '~/models/components/MyAccount'
import { isValidProduct } from '~/services/orders'

export default {
  methods: {
    checkGMPOrder(orderData): boolean {
      const [, , , urlLocale] = orderData?.url.split('/')
      return urlLocale.length <= 2
    },
    getAnalyticsData(
      orderData: IReorderModel,
      productData: RecommerceProduct
    ): reorderAnalyticsData | null {
      if (!orderData) return null
      const discount = Number(orderData.discount_price)
      const localAmount = Number(orderData.price)
      const subtotal = Number(orderData.price)
      const total = Number(orderData.total_price)

      const discountPercent = subtotal !== 0 ? (discount * 100) / subtotal : 0
      let formattedProduct = {
        productId: orderData.products[0].id,
        quantity: Number(orderData.quantity),
        currency: orderData.currency,
        available: orderData.products[0].available,
        invalidProduct: isValidProduct({
          product: productData,
        } as RecommerceVariant),
        product_kind: orderData.products[0].product_kind,
        productBrand: orderData.products[0].brand_name,
        brandTitle: orderData.products[0].brand_name,
        productCategory: productData ? productData.mainCategory.name : '',
        redeemType: orderData.products[0].redeem_type,
        scamNotificationType: orderData.products[0].scam_notification_type,
        title: productData ? productData.name : orderData.products[0].name,
        serviceFee: orderData.service_fee,
        price: {
          currency: orderData.currency,
          discount,
          discountPercent,
          localAmount,
          subtotal,
          total,
        },
      }

      if (!productData) {
        formattedProduct = {
          ...formattedProduct,
          ...{ discontinued: true },
        }
      }

      if (orderData.position) {
        formattedProduct = {
          ...formattedProduct,
          ...{ position: orderData.position },
        }
      }
      return formattedProduct
    },
  },
}
