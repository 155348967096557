import { Context, Middleware } from '@nuxt/types'

// Middleware checks for federated account and revokes access to specific routes
const checkForFederatedAccountMiddleware: Middleware = (
  context: Context
): void => {
  const {
    route: { name },
    redirect,
    store,
  } = context
  if (!name) {
    return
  }

  const isNativeAccount: boolean = store.getters['user/isNativeAccount']

  const { noFederatedAccount } = context.route.matched[0].meta

  if (noFederatedAccount && !isNativeAccount) {
    redirect(302, context.$contextPath(`my-account`))
  }
}

export default checkForFederatedAccountMiddleware
