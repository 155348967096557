
import { mapGetters, mapState } from 'vuex'
import ProductField from './product-field.vue'
import PhoneNumberBox from './phone-number-box.vue'
import {
  brandSlug,
  country,
  discountPrice,
  hasCertifiedBadge,
  locale,
  product,
  productPrice,
  promoCode,
  promoCodeError,
  promoCodeHasBeenApplied,
  promoCodeLoading,
  quantity,
  totalPrice,
  transactionCost,
  marketplacePrefix,
  openRangeValue,
} from './props'
import PromoCodeField from './promo-code-field.vue'
import get from '~/utils/get'
import { getUrlWithLocale } from '~/services/url'
import { REDEEM_TYPE } from '~/services/topup/enums'
import {
  UiTitle,
  Divider,
  Flag,
  Alert,
  UiImage,
  Icon,
} from '~/components/atoms'
import { EditableCard } from '~/components/molecules'
import customDenominations from '~/mixins/custom-denominations'

export default {
  components: {
    EditableCard,
    Divider,
    UiTitle,
    ProductField,
    Flag,
    Alert,
    UiImage,
    Icon,
    PhoneNumberBox,
    PromoCodeField,
  },
  mixins: [customDenominations],
  props: {
    brandSlug,
    country,
    discountPrice,
    hasCertifiedBadge,
    locale,
    product,
    productPrice,
    promoCode,
    promoCodeError,
    promoCodeHasBeenApplied,
    promoCodeLoading,
    quantity,
    totalPrice,
    transactionCost,
    marketplacePrefix,
    openRangeValue,
  },
  computed: {
    ...mapState('checkout', ['customDenominationError']),
    ...mapGetters('context', ['direction', 'useRecommerce']),
    isRTR() {
      return this.productPrice.redeem_type === REDEEM_TYPE.RTR
    },
    brandUrl() {
      const { brandSlug: slug } = this
      const origin = get(window, 'location.origin', '')
      const baseUrl = getUrlWithLocale(origin, this.marketplacePrefix)
      return `${baseUrl}/${slug}`
    },
    productLogoImg() {
      return this.useRecommerce
        ? this.product.product.productCardImage
        : this.product.logo
    },
    shouldPrependToServiceFee() {
      return this.product.brandSlug.toLowerCase() === 'paysafecard'
    },
  },
  methods: {
    onPhoneNumberChange() {
      window.location.href = this.brandUrl
    },
    propagatePromoCodeRemove() {
      this.$emit('promoCode:remove')
    },
    propagatePromoCodeSubmit(promoCode) {
      this.$emit('promoCode:submit', promoCode)
    },
  },
}
