
import FontIcon from './font-icon.vue'
import SvgIcon from './svg-icon.vue'
import { FONT_ICON } from './enums'
import props from './props'

export default {
  name: 'Icon',
  components: {
    FontIcon,
    SvgIcon,
  },
  props,
  computed: {
    isFontType(): boolean {
      return Object.values(FONT_ICON).includes(this.icon)
    },
  },
}
