import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'

export interface ISeoModel {
  name: string
  canonicalLanguages?: string[]
}

export interface ICMSSeoModel extends Entry {
  fields: ISeoModel
}

export class SeoModel extends EntryModel {
  protected readonly name: string
  protected readonly canonicalLanguages: string[]

  constructor(model: ICMSSeoModel) {
    super(model)
    const {
      fields: { canonicalLanguages },
    } = model
    this.name = this.requiredField('name')
    this.canonicalLanguages = canonicalLanguages || []
  }

  toJSON(): ISeoModel {
    return {
      name: this.name,
      canonicalLanguages: this.canonicalLanguages,
    }
  }
}
