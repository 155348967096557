import { hasPresentKey, isPresent } from 'ts-is-present'
import {
  FooterLinksGroup,
  Maybe,
  PaymentMethod,
} from '~/apis/clients/graphql/types/contentful'
import { IFooter } from '~/models/components/Footer'
import { IFooterLinks } from '~/models/components/FooterLinks'
import { transformImage, TContentfulImage } from '~/normalizers/base/image'
import { transformLink, TContentfulLink } from '~/normalizers/base/link'

export type TContentfulPaymentMethod = {
  link?: TContentfulLink
  name: string
  icon: TContentfulImage
  topupId: string
}

export type TContentfulFooterLinks = {
  title: string
  groupLinks: {
    items: TContentfulLink[]
  }
}

export type TContentfulFooter = {
  footerLinksGroup: {
    items: TContentfulFooterLinks[]
  }
  paymentMethodsPage?: TContentfulLink
  legalLinks: {
    items: TContentfulLink[]
  }
}

type NormalizeFooterLinksParams = Maybe<
  Pick<FooterLinksGroup, 'title'> & {
    groupLinks?: Maybe<{ items?: Maybe<TContentfulLink>[] }>
  }
>[]

function normalizeFooterLinks(
  footerLinks: NormalizeFooterLinksParams
): IFooterLinks[] {
  return (
    footerLinks.filter(isPresent).map(({ title, groupLinks }) => ({
      title: title || '',
      groupLinks:
        groupLinks?.items?.filter(isPresent).map(link => transformLink(link)) ||
        [],
    })) || []
  )
}

type NormalizeFooterParams = { paymentMethodsPage?: Maybe<TContentfulLink> } & {
  logo: TContentfulImage
} & {
  paymentMethods: Array<
    Pick<PaymentMethod, 'name' | 'topupId'> & { icon?: Maybe<TContentfulImage> }
  >
} & {
  footerLinksGroup?: Maybe<{ items: NormalizeFooterLinksParams }>
} & { legalLinks?: Maybe<{ items?: Maybe<TContentfulLink>[] }> }

export function normalizeFooter({
  logo,
  paymentMethods,
  footerLinksGroup,
  legalLinks,
  paymentMethodsPage,
}: NormalizeFooterParams): IFooter {
  const footerLinks = footerLinksGroup
    ? normalizeFooterLinks(footerLinksGroup.items)
    : []

  const formatedLegalLinks = legalLinks?.items
    ? legalLinks.items.filter(isPresent).map(link => {
        return transformLink(link)
      }) || []
    : []

  const transformedPaymentMethods = paymentMethods
    .filter(hasPresentKey('icon'))
    .map(item => ({
      name: item.name || '',
      topupId: item.topupId || '',
      icon: transformImage(item.icon),
    }))

  const transformedLogo = transformImage(logo)

  const paymentMethodsPageLink =
    paymentMethodsPage && transformLink(paymentMethodsPage)

  return {
    logo: transformedLogo,
    paymentMethods: transformedPaymentMethods,
    footerLinksGroup: footerLinks,
    legalLinks: formatedLegalLinks,
    ...(paymentMethodsPageLink
      ? { paymentMethodsPage: paymentMethodsPageLink }
      : {}),
  }
}
