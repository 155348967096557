import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { IImageModel, ImageModel, ICMSImageModel } from '../base/Image'
import { LinkModel, ILink, ICMSLink } from '../base/Link'
import { EntryModel } from '~/models/base/Entry'

export interface ICMSPromotionBanner extends Entry {
  fields: {
    bannerTitle?: string
    description?: string
    banner: ICMSImageModel
    buttonName?: string
    pageReference?: ICMSLink
  }
}

export interface IPromotionBanner {
  title: string
  description?: string
  banner: IImageModel
  buttonName?: string
  link?: ILink
}

export class PromotionBannerModel extends EntryModel {
  protected readonly title: string
  protected readonly description?: string
  protected readonly banner: IImageModel
  protected readonly buttonName?: string
  protected readonly link?: ILink

  constructor(promotionBannerModel) {
    super(promotionBannerModel)
    const {
      fields: { description, buttonName, pageReference, bannerTitle },
    } = promotionBannerModel
    this.title = bannerTitle
    this.banner = new ImageModel(this.requiredField('banner')).toJSON()
    this.description = description
    this.buttonName = buttonName
    this.link = pageReference
      ? new LinkModel(pageReference).toJSON()
      : undefined
  }

  toJSON(): IPromotionBanner {
    return {
      title: this.title,
      description: this.description,
      banner: this.banner,
      buttonName: this.buttonName,
      link: this.link,
    }
  }
}

export default PromotionBannerModel
