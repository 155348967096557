var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-wrap",attrs:{"id":"search-list"}},[_c('div',{class:[
      `${_vm.widthClasses} w-full`,
      {
        hidden: _vm.hideSearch,
      },
    ]},[(!_vm.hideSearch)?_c('ui-input',_vm._g({attrs:{"has-autofocus":_vm.enableAutoFocus,"with-floating-label":false,"placeholder":_vm.searchPlaceholder,"extended-box-classes":_vm.extendedBoxClasses,"extended-input-classes":!_vm.dropdownResults ? 'rounded-lg' : '',"focus-again":_vm.focusSearchAgain,"is-big":"","clearable":_vm.clearable,"has-custom-suffix-icon":"","suffix-icon-type":"search","show-prefix":_vm.inputPrefix,"back-button":_vm.inputBackButton,"back-button-icon":_vm.inputPrefixIcon,"prefix-classes":_vm.inputPrefixClasses,"data-test":_vm.dataTest,"direction":_vm.direction,"autocomplete":"off"},on:{"focus":function($event){return _vm.$emit('focusSearchInput')},"input":_vm.onSearch,"blur":function($event){return _vm.$emit('blurSearchInput', _vm.searchTerm)},"clicked-prefix":function($event){return _vm.$emit('prefix-clicked')},"clear-input":_vm.clearInput},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}},_vm.$listeners)):_vm._e()],1),_vm._v(" "),(_vm.dropdownResults)?[_c('div',{staticClass:"w-full absolute flex flex-wrap justify-between top-12 max-h-60 -right-px-17 bg-white mt-1 focus:outline-none border-gray overflow-y-auto hide-scrollbar border z-10",attrs:{"id":"results"}},[_vm._l((_vm.limitedItems),function(item){return _c('div',{key:item.name,class:[
          `flex items-center justify-between hover:cursor-pointer ${_vm.widthClasses} w-full`,
          {
            'border border-gray border-l-0 border-r-0 border-t-0 ': _vm.dropdownResults,
            'py-2': _vm.dropdownResults,
            'bg-info-light': _vm.selectedItem.name === item.name,
          },
        ],attrs:{"data-test":"search-results-item"},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},[_c('div',{staticClass:"w-full px-4"},[_vm._t("default",null,{"item":item,"selected":item[_vm.selectPropName] === _vm.selectedItem[_vm.selectPropName]})],2)])}),_vm._v(" "),(_vm.limitedItems.length === 0)?_c('div',{staticClass:"flex items-center justify-between w-full py-2 py-4 px-4",class:{ 'py-4 px-4': _vm.dropdownResults }},[_vm._v("\n        "+_vm._s(_vm.noResults)+"\n      ")]):_vm._e()],2)]:[_c('div',{staticClass:"w-full flex flex-wrap",class:[
        _vm.justifyContent,
        { 'overflow-y-auto results-container-height': _vm.scrollableResults },
      ],attrs:{"id":"results"}},[_vm._l((_vm.limitedItems),function(item,itemKey){return _c('div',{key:`${itemKey}-${item.name}`,class:[
          `flex items-center justify-between hover:cursor-pointer ${_vm.widthClasses} w-full`,
          {
            'border border-gray border-l-0 border-r-0 border-t-0 my-0 py-2': _vm.enableDividers,
            'my-2': !_vm.enableDividers,
            'bg-info-light':
              _vm.highlightSelected && _vm.selectedItem.name === item.name,
          },
        ],attrs:{"data-test":"search-results-item"},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},[_c('div',{staticClass:"w-full"},[_vm._t("default",null,{"item":item,"selected":item[_vm.selectPropName] === _vm.selectedItem[_vm.selectPropName]})],2)])}),_vm._v(" "),(_vm.limitedItems.length === 0)?_c('div',{staticClass:"flex items-center justify-between w-full py-2 py-4 px-4"},[_vm._v("\n        "+_vm._s(_vm.noResults)+"\n      ")]):_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }