export enum portals {
  PORTAL_NAME_LANGUAGE_SELECTOR = 'languageSelector',
  PORTAL_NAME_RTR_PHONE_NUMBER_INPUT = 'phoneNumberInput',
  PORTAL_NAME_COUNTRY_CURRENCY_LANGUAGE_SELECTOR = 'countryCurrencyLanguageSelector',
  PORTAL_NAME_PAYMENT_METHODS_MODAL = 'countryCurrencyLanguageSelector',
  PORTAL_NAME_SWITCH_COUNTRY = 'switchCountry',
  PORTAL_SUGGESTION_COUNTRY = 'gmpHome',
  PORTAL_PROFILE_EDIT_PHONE_NUMBER = 'editPhoneNumber',
  PORTAL_ORDER_DETAILS_CHANGE_RTR_PHONE_NUMBER = 'changePhoneNumber',
  PORTAL_CHANGE_PHONE_NUMBER_COUNTRY = 'changePhoneNumberCountry',
  PORTAL_CONFIRMATION_MODAL = 'confirmationModal',
  PORTAL_SEARCH_CONTACTS = 'searchContacts',
  PORTAL_NAME_PRIMER_SWITCH_COUNTRY = 'primerSwitchCountry',
  PORTAL_AUTHENTICATION_MODAL = 'authenticationModal',
  PORTAL_WEB_TO_APP_BANNER = 'webToAppBanner',
}
