import Amplify, { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { Context, Plugin } from '@nuxt/types'
import Cookies from 'universal-cookie'
import { NuxtI18nInstance } from 'nuxt-i18n/types'
import { Route } from 'vue-router'
import createCookieStorage, { ICognitoStorage } from './cookie-storage'
import { ROUTE_NAME } from '~~/config/router'
import {
  clearStorageItem,
  getStorageItem,
  setStorageItem,
} from '~/services/localstorage'
import {
  FEDERATED_SIGN_IN_LOCALE,
  SIGN_IN_REF,
  REFERRAL_STORAGE,
  REFERRED_BY_CODE_KEY,
} from '~/services/constants'
import isEmpty from '~/utils/isEmpty'
import {
  Api,
  UpdateUserPreferenceDto,
  UserPreference,
} from '~/apis/clients/rest/account/api'
import { ISentry } from '~/types/store'
import GLOBAL_COUNTRY from '~/utils/constants/global-country.json'
import { notificationHandler } from '~/utils/notificationHandler'
import { NotificationsPlugin } from '~/plugins/notifications'
import { MobileMenuVisibility } from '~/store/ui/mobile-menu/types'

export type AuthPlugin = {
  storage: ICognitoStorage
  accountClient: Api<unknown>
  refreshUser(): void
}

export const SIGN_IN_SUCCESS_MESSAGE_KEY =
  'authentication.sign_in.success_notification'

const SIGN_OUT_SESSION_EXPIRED_MESSAGE_KEY =
  'authentication.sign_out.session_expired_notification'

const NOT_ELIGIBLE_FOR_REWARD_MESSAGE_KEY =
  'refer_a_friend.notification.referred_not_eligible'

const TOKEN_REFRESH_INTERVAL = 30 * 60 * 1000

const NOTIFICATION_FETCHER_DELAY = 2.5 * 1000

const RESET_PASSWORD_ROUTE_NAME = 'reset-password'

const CUSTOM_USER_AGENT = 'Shop web SSR'

const lmpLocalePattern = /^[a-z]{2}-[a-z]{2}$/

const lmpLocaleRegex = new RegExp(lmpLocalePattern, 'i')

let tokenRefreshInterval

const resetUserState = async store => {
  store.dispatch('user/clearUser')
}

const getSignInRef = () => {
  const signInRef = getStorageItem({
    storage: SIGN_IN_REF,
  })

  clearStorageItem({ storage: SIGN_IN_REF })
  return signInRef
}

const handleRedirect = (ctx: Context, path: string) => {
  return ctx.app.router.push(path)
}

export const handleHomeRedirect = (ctx: Context, route: Route): void => {
  let requiresAuth = false
  if (route.matched[0].meta) {
    requiresAuth = route.matched[0].meta.requiresAuth
  }

  const locale = ctx.store.getters['context/marketplacePrefix']
  const signInRef = route.path.replace(`/${locale}`, '')

  const redirectPath =
    isEmpty(signInRef) && !requiresAuth
      ? ctx.$contextPath('/')
      : ctx.$contextPath(`${signInRef}?auth=login`)

  if (requiresAuth) {
    return handleRedirect(ctx, redirectPath)
  }
  return handleRedirect(ctx, '?auth=login')
}

const expiredSessionHandler = (ctx: Context, storage) => {
  const { store, route, $notifications, i18n } = ctx

  clearInterval(tokenRefreshInterval)
  storage.clear()
  storage.removeRememberMeCookie()
  resetUserState(store)

  $notifications.pushInfo({
    text: i18n.t(SIGN_OUT_SESSION_EXPIRED_MESSAGE_KEY) as string,
    deferred: true,
  })

  handleHomeRedirect(ctx, route)
}

const fetchAndDisplayNotifications = async (
  accountClient,
  i18n,
  $notifications
): Promise<void> => {
  const notificationsResponse = await accountClient.notification.listUnread()
  if (notificationsResponse.data) {
    notificationsResponse.data.forEach(notification => {
      notificationHandler(i18n, $notifications, notification)
    })
  }
}

const checkAndUpdateUserPreferences = async (
  savedPreferences: UserPreference,
  accountClient: Api<unknown>,
  $sentry: ISentry,
  storage
) => {
  const preferencesToBeSaved = {} as UpdateUserPreferenceDto

  const { currency, language, country } = savedPreferences
  const currencyCookie = storage.getItem('currency') as string
  const localeCookie = storage.getItem('locale') as string
  let localeCookieLanguage = ''
  let localeCookieCountry = ''

  if (localeCookie && !lmpLocaleRegex.test(localeCookie)) {
    ;[localeCookieLanguage, localeCookieCountry] = localeCookie?.split('/')
  }

  if (
    currencyCookie &&
    currency?.toLowerCase() !== currencyCookie?.toLowerCase()
  ) {
    preferencesToBeSaved.currency = currencyCookie
  }

  if (
    localeCookieCountry &&
    country?.toLowerCase() !== localeCookieCountry?.toLowerCase() &&
    localeCookieCountry !== GLOBAL_COUNTRY.code.toLowerCase()
  ) {
    preferencesToBeSaved.country = localeCookieCountry
  }

  if (
    localeCookieLanguage &&
    language?.toLowerCase() !== localeCookieLanguage?.toLowerCase()
  ) {
    preferencesToBeSaved.language = localeCookieLanguage
  }

  if (Object.keys(preferencesToBeSaved).length) {
    try {
      await accountClient.user.update({ user_preference: preferencesToBeSaved })
    } catch (e) {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'acceptance'
      ) {
        console.error(e)
      }
      $sentry.captureException(
        `${e}, savedPreferences: ${JSON.stringify(
          savedPreferences
        )}, currencyCookie: ${currencyCookie}, localeCookie: ${localeCookie}`
      )
    }
  }
}

const checkReferralProgramEligibility = async (
  accountClient: Api<unknown>,
  store,
  $sentry: ISentry
): Promise<void> => {
  try {
    const { data } = await accountClient.referralCode.get()
    const rafEligibleUser = !!data.referral_code
    store.dispatch('user/setRafEligibility', rafEligibleUser)
  } catch (e) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'acceptance'
    )
      console.error(e)
    $sentry.captureException(e)
  }
}

const createRefreshUser = (
  store,
  accountClient: Api<unknown>,
  $gtmEnhanced,
  $notifications,
  i18n,
  storage,
  $sentry: ISentry
) => async () => {
  try {
    // refreshes the access and id token if needed
    const cognitoUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })

    const { data: user } = await accountClient.user.me()
    store.dispatch('user/setUser', user)

    if (process.client) {
      $gtmEnhanced.userAuthSuccess(user.id)
    }

    const { refreshToken } = cognitoUser.signInUserSession

    if (refreshToken.token) {
      tokenRefreshInterval = setInterval(async () => {
        await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
      }, TOKEN_REFRESH_INTERVAL)
    }

    if (process.client) {
      setTimeout(async () => {
        fetchAndDisplayNotifications(accountClient, i18n, $notifications)
      }, NOTIFICATION_FETCHER_DELAY)
    }

    checkReferralProgramEligibility(accountClient, store, $sentry)

    if (user.user_preference) {
      checkAndUpdateUserPreferences(
        user.user_preference,
        accountClient,
        $sentry,
        storage
      )
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(error)
  }
}

const setLocaleInFederatedSignIn = async (store, i18n) => {
  const locale = getStorageItem({
    storage: FEDERATED_SIGN_IN_LOCALE,
  })

  clearStorageItem({ storage: FEDERATED_SIGN_IN_LOCALE })

  if (!isEmpty(locale)) {
    await i18n.setLocale(locale)
  }

  store.dispatch('context/setLocaleProperties', {
    ...i18n.localeProperties,
  })
}

const createAddReferralCode = (
  accountClient: Api<unknown>,
  $notificationsPlugin: NotificationsPlugin,
  i18n: NuxtI18nInstance,
  route: Route,
  $sentry: ISentry
) => async (): Promise<void> => {
  const referredByCode = getStorageItem({
    storage: REFERRAL_STORAGE,
    key: REFERRED_BY_CODE_KEY,
  })

  if (referredByCode) {
    try {
      $sentry.captureMessage(`createAddReferral`)
      await accountClient.user.connectReferredByCode({
        referred_by_code: referredByCode,
      })
    } catch (e) {
      $sentry.captureException(e)
      $notificationsPlugin.pushInfo({
        text: i18n.t(NOT_ELIGIBLE_FOR_REWARD_MESSAGE_KEY) as string,
        deferred: true,
      })
    } finally {
      clearStorageItem({ storage: REFERRAL_STORAGE, key: REFERRED_BY_CODE_KEY })
    }
  }
}

const handleOAuthCallback = async (data, ctx, refreshUser, addReferralCode) => {
  const { i18n, store } = ctx

  if (data.payload.event === 'signIn') {
    await refreshUser()

    const signInRef = getSignInRef()

    await setLocaleInFederatedSignIn(store, i18n)

    await addReferralCode()

    ctx.$notifications.pushSuccess({
      text: i18n.t(SIGN_IN_SUCCESS_MESSAGE_KEY),
      deferred: true,
      dataTest: 'sign-in-success',
    })

    window.location = ctx.$contextPath(signInRef)
  }

  if (data.payload.event === 'signIn_failure') {
    const signInRef = getSignInRef()

    await setLocaleInFederatedSignIn(store, i18n)

    if (
      data.payload?.data?.message?.includes(
        'external_provider_without_email_field'
      )
    ) {
      ctx.$notifications.pushError({
        text: i18n.t(
          'authentication.register.external_provider_without_email_error'
        ),
        deferred: true,
        closeAfter: 16000,
      })
    } else {
      ctx.$notifications.pushError({
        text: i18n.t('authentication.sign_in.generic_error'),
        deferred: true,
      })
    }

    window.location = ctx.$contextPath(`${signInRef}?auth=login`)
  }
}

const isOAuthCallbackRoute = routeName => {
  return [
    ROUTE_NAME.OAUTH_SIGN_IN_REDIRECT,
    ROUTE_NAME.OAUTH_SIGN_OUT_REDIRECT,
  ].includes(routeName)
}

const closeMobileMenu = store => {
  const mobileMenuVisibility: MobileMenuVisibility =
    store.getters['ui/mobile-menu/mobileMenuVisibility']
  if (mobileMenuVisibility === 'visible') {
    store.dispatch('ui/mobile-menu/setMobileMenuVisibility', 'hidden')
  }
}

const auth: Plugin = async (ctx, inject) => {
  const {
    req,
    store,
    app,
    i18n,
    $notifications,
    route,
    redirect,
    $sentry,
  } = ctx

  const { $gtmEnhanced } = app

  const cookiesFromServer = process.server && req ? req.headers.cookie : null
  const cookies = new Cookies(cookiesFromServer)
  const storage = createCookieStorage(cookies)

  const defaultHeader = process.server
    ? { 'user-agent': CUSTOM_USER_AGENT }
    : {}

  const accountClient = new Api({
    baseURL: process.env.ACCOUNT_URL,
    headers: defaultHeader,
    securityWorker: () => {
      const headers = {
        authorization: `Bearer ${storage.getItem('id_token')}`,
      }
      if (process.server) {
        headers['user-agent'] = CUSTOM_USER_AGENT
      }
      return {
        headers,
      }
    },
  })

  accountClient.instance.interceptors.response.use(
    res => res,
    err => {
      if (err?.response?.status === 401) {
        expiredSessionHandler(ctx, storage)
      }

      throw err
    }
  )

  const refreshUser = createRefreshUser(
    store,
    accountClient,
    $gtmEnhanced,
    $notifications,
    i18n,
    storage,
    $sentry
  )

  const addReferralCode = createAddReferralCode(
    accountClient,
    $notifications,
    i18n,
    route,
    $sentry
  )

  Amplify.configure({
    Auth: {
      region: process.env.AWS_COGNITO_REGION,
      userPoolId: process.env.AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.AWS_USER_POOL_WEB_CLIENT_ID,
      storage,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: process.env.AWS_OAUTH_DOMAIN,
        redirectSignIn: process.env.AWS_SIGN_IN_REDIRECT_URL,
        redirectSignOut: process.env.AWS_SIGN_OUT_REDIRECT_URL,
        responseType: 'code',
      },
    },
  })

  Hub.listen('auth', async data => {
    const routeName = app.getRouteBaseName()

    if (isOAuthCallbackRoute(routeName)) {
      await handleOAuthCallback(data, ctx, refreshUser, addReferralCode)
      return
    }

    if (data.payload.event === 'signIn') {
      await refreshUser()
      $notifications.pushSuccess({
        text: i18n.t(SIGN_IN_SUCCESS_MESSAGE_KEY) as string,
        dataTest: 'sign-in-success',
      })

      closeMobileMenu(store)

      if (route.query.ref) {
        redirect(302, ctx.$contextPath(route.query.ref as string))
      }

      if (route.name === RESET_PASSWORD_ROUTE_NAME) {
        redirect(302, ctx.$contextPath('/') as string)
      }
    }

    if (data.payload.event === 'signOut') {
      closeMobileMenu(store)
      clearInterval(tokenRefreshInterval)
      storage.removeRememberMeCookie()
      await resetUserState(store)
      // set localstorage that users first login has been completed
      if (process.client) {
        if (!getStorageItem({ storage: 'recharge-store', key: 'firstLogin' })) {
          setStorageItem({
            storage: 'recharge-store',
            key: 'firstLogin',
            value: true,
          })
        }
        $gtmEnhanced.accountSignOut()
        $notifications.pushInfo({
          text: i18n.t('authentication.sign_out.success') as string,
          closeAfter: 4000,
          dataTest: 'sign-out-success',
          deferred: false,
        })
      }

      if (ctx.route.name === 'home' || !ctx.route.name) return
      handleRedirect(
        ctx,
        `/${ctx.route.params.language}/${ctx.route.params.country}`
      )
    }

    if (data.payload.event === 'tokenRefresh_failure') {
      expiredSessionHandler(ctx, storage)
      storage.removeRememberMeCookie()
    }
  })

  if (
    storage.containsAuthItems() &&
    !isOAuthCallbackRoute(app.getRouteBaseName())
  ) {
    await refreshUser()
  }

  inject('auth', {
    storage,
    refreshUser,
    accountClient,
  })
}

export default auth
