
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import { ICategory } from '~/models/base/Category'
import {
  MainTitle,
  PopularBrandCard,
  SwitchCountry,
} from '~/components/molecules'
import { Row, Column, Container } from '~/components/grid'
import { CdpContainer } from '~/components/renderers'
import { portals } from '~/enums'
import { POPULAR_PRODUCTS_SLUG } from '~/utils/constants/pages'
export default {
  components: {
    MainTitle,
    PopularBrandCard,
    Row,
    Column,
    Container,
    SwitchCountry,
    CdpContainer,
  },
  props: {
    brands: {
      type: Array,
      required: true,
    } as PropOptions<ICategory[]>,
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCountrySelector: false,
      countriesForProduct: [],
      newSlug: undefined,
    }
  },
  computed: {
    ...mapGetters('context', ['isGlobalCountry', 'isGlobalMarketplace']),
    portals() {
      return portals
    },
    isMobileApplyClassToPopularProduct() {
      if (this.$mq === 'sm') {
        return 'mb-2 flex flex-col relative w-full'
      }
      return 'min-w-popular-card min-h-popular-card max-w-popular-card md:max-w-initial md:max-h-initial md:min-w-0 md:min-h-0 md:w-1/3 lg:w-1/4 mb-4 pe-4'
    },
  },
  methods: {
    onClickPopularProduct(brand) {
      if (this.isGlobalMarketplace)
        this.$gtmEnhanced.trackBrandClick(brand, POPULAR_PRODUCTS_SLUG)
      if (!this.isGlobalCountry) return
      this.newSlug = brand.slug
      this.countriesForProduct = brand.countries
      this.showCountrySelector = true
    },
  },
}
