
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import { UiImage, UiLink } from '~/components/atoms'
import { IPaymentMethod } from '~/models/components/PaymentMethod'
import { ILink } from '~/models/base/Link'

export default {
  components: {
    UiImage,
    UiLink,
  },
  props: {
    align: {
      type: String,
      default: 'center',
    },
    imageHeight: {
      type: String,
      default: '8',
    },
    imageDimensions: {
      type: Array,
      default: () => [60, 60],
    },
    paymentMethods: {
      type: Array,
      default: () => [],
    } as PropOptions<IPaymentMethod[]>,
    paymentMethodsPage: {
      type: Object,
      default: () => ({ url: '' }),
    } as PropOptions<ILink | Record<string, unknown>>,
    hasLinks: {
      type: Boolean,
      default: true,
    },
    singleRow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('context', ['trustpilotLocale', 'direction']),
    url() {
      const {
        paymentMethodsPage: { url },
      } = this
      return url
    },
    trustpilotIdentifier() {
      return `footer-${this.direction}`
    },
  },
}
