import errorTypesEnum from './enums'
import { ICMSCGPayErrorRedirectParams } from './types'
import { REQUEST_ERROR } from '~/apis/enums'

export class CgPayError extends Error {
  readonly details: string
  readonly statusCode: REQUEST_ERROR
  readonly message: string
  constructor({
    params: { errorType = 'errorNotProvided' },
  }: ICMSCGPayErrorRedirectParams) {
    super()
    const formattedErrorType = errorType.toLowerCase()
    this.statusCode = 500
    this.message = errorTypesEnum[formattedErrorType] || 'unknown'
    this.details = this.getDetails()
  }

  getDetails(): string {
    return `
      <br />
      <b> this information is only available in development environment</b><br />
      CMS Error: <br />
      * message: ${this.message} <br />
      * status code: ${this.statusCode}  <br />
      * environment: ${process.env.CONTENTFUL_ENVIRONMENT}`
  }
}
