import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { LinkModel, ICMSLink, ILink, TLink, TCMSLink } from '../base/Link'
import { IImageModel } from '../base/Image'

import {
  PaymentMethodModel,
  IPaymentMethod,
  ICMSPaymentMethod,
} from './PaymentMethod'
import { FooterLinksModel, IFooterLinks, ICMSFooterLinks } from './FooterLinks'
import getGroupLinks from '~/services/group-links'

export interface IFooter {
  logo: IImageModel
  paymentMethods: IPaymentMethod[]
  footerLinksGroup: IFooterLinks[]
  paymentMethodsPage?: ILink
  legalLinks: TLink[]
}

export interface ICMSFooter extends Entry {
  logo: IImageModel
  paymentMethods: ICMSPaymentMethod[]
  fields: {
    footerLinksGroup: ICMSFooterLinks[]
    paymentMethodsPage?: ICMSLink
    legalLinks: TCMSLink[]
  }
}

export class FooterModel extends EntryModel {
  protected readonly logo: IImageModel
  protected readonly paymentMethods: IPaymentMethod[]
  protected readonly footerLinksGroup: IFooterLinks[]
  protected readonly paymentMethodsPage?: ILink
  protected readonly legalLinks: TLink[]

  constructor(footerModel: ICMSFooter) {
    super(footerModel)
    const { logo, paymentMethods = [], fields } = footerModel
    const { paymentMethodsPage } = fields
    this.logo = logo
    this.paymentMethods = paymentMethods.map(paymentMethod =>
      new PaymentMethodModel(paymentMethod).toJSON()
    )
    this.footerLinksGroup = this.requiredField(
      'footerLinksGroup'
    ).map(groupLinks => new FooterLinksModel(groupLinks).toJSON())

    this.legalLinks = getGroupLinks(this.requiredField('legalLinks'))

    if (paymentMethodsPage) {
      this.paymentMethodsPage = new LinkModel(paymentMethodsPage).toJSON()
    }
  }

  toJSON(): IFooter {
    return {
      logo: this.logo,
      paymentMethods: this.paymentMethods,
      footerLinksGroup: this.footerLinksGroup,
      legalLinks: this.legalLinks,
      paymentMethodsPage: this.paymentMethodsPage,
    }
  }
}
