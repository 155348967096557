import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { ICategory, CategoryModel, ICMSCategory } from '../base/Category'
import { EntryModel } from '../base/Entry'
import mapWithFilter from '~/services/map-with-filter'

export interface IPopularBrandList {
  title?: string
  brands: ICategory[]
}

export interface ICMSPopularBrandList extends Entry {
  fields: {
    title?: string
    brands: ICMSCategory[]
  }
}

export class PopularBrandListModel extends EntryModel {
  protected readonly brands: ICategory[]
  protected readonly title?: string

  constructor(popularBrandListModel: ICMSPopularBrandList) {
    super(popularBrandListModel)
    const {
      fields: { brands = [], title },
    } = popularBrandListModel
    this.title = title
    const formattedBrands = mapWithFilter(
      brands,
      brand => new CategoryModel(brand).toJSON(),
      'fields'
    )

    this.brands = formattedBrands as ICategory[]
  }

  toJSON(): IPopularBrandList {
    return {
      title: this.title,
      brands: this.brands,
    }
  }
}
