
import { PropOptions } from 'vue'
import { SVG_ICON } from './enums'

const iconsLoader =
  process.env.NODE_ENV !== 'test'
    ? require.context('~/assets/images/icons/svg/', false)
    : () => ''

export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
    } as PropOptions<SVG_ICON>,
  },
  methods: {
    getIcon() {
      try {
        const icon = iconsLoader(`./${this.icon}.svg`)
        return icon
      } catch (e) {
        return ''
      }
    },
  },
}
