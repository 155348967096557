import { ILink } from '~/models/base/Link'
import { IExternalLinkModel } from '~/models/base/ExternalLink'
import { Maybe } from '~/apis/clients/graphql/types/contentful'

export type TContentfulPageLink = {
  title?: Maybe<string>
  path?: Maybe<string>
  slug?: Maybe<string>
  pageTitle?: Maybe<string>
}

export type TContentfulExternalLink = {
  title?: Maybe<string>
  url?: Maybe<string>
  newTab?: Maybe<boolean>
  noFollow?: Maybe<boolean>
  internalName?: Maybe<string>
}

export type TContentfulLink = TContentfulPageLink | TContentfulExternalLink

function isExternalLink(
  link: TContentfulLink
): link is TContentfulExternalLink {
  return !!(link as TContentfulExternalLink)?.url
}

export function transformExternalLink({
  title = '',
  url,
  newTab = false,
  noFollow = false,
  internalName = '',
}: TContentfulExternalLink): IExternalLinkModel {
  return {
    newTab: !!newTab,
    title: title || '',
    url: url || '',
    internalName: internalName || '',
    rel: noFollow ? 'noFollow' : '',
  }
}

export function transformPageLink({
  path,
  pageTitle,
  slug,
  title,
}: TContentfulPageLink): ILink {
  const [, ...params] = path ? path.split('/') : []
  const url = `/${params.join('/')}`
  return {
    title: title || pageTitle || '',
    url,
    slug: slug === null ? undefined : slug,
  }
}

export function transformLink(
  link: TContentfulLink
): ILink | IExternalLinkModel {
  if (!link) throw new Error('missing Link entry')
  if (isExternalLink(link)) return transformExternalLink(link)

  return transformPageLink(link)
}
