import { ICMSImageModel } from '../base/Image'

export interface IBrandFallbackInfoModel {
  banner: ICMSImageModel
  title: string
  logo: ICMSImageModel
}

export interface ICMSBrandFallbackInfoModel {
  fields: {
    banner: ICMSImageModel
    title: string
    logo: ICMSImageModel
  }
}

export class BrandFallbackInfoModel {
  readonly banner: ICMSImageModel
  readonly title: string
  readonly logo: ICMSImageModel

  constructor(brandInfoFallback: ICMSBrandFallbackInfoModel) {
    const {
      fields: { banner, title, logo },
    } = brandInfoFallback

    this.title = title
    this.banner = banner
    this.logo = logo
  }

  toJSON(): IBrandFallbackInfoModel {
    return {
      banner: this.banner,
      title: this.title,
      logo: this.logo,
    }
  }
}
