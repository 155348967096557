import { Middleware, Context } from '@nuxt/types'
import { checkoutSagaLoading } from '~/sagas/checkout/loading'
import { getUrl } from '~/services/url'
import { ContextPath } from '~/plugins/context-path/types'
import { ISentry } from '~/types/store'
import {
  endMeasurement,
  startMeasurement,
} from '~~/server/services/performance'
import { executeOnServer } from '~/services/nuxt'

export interface IExtendedContext extends Context {
  $sentry: ISentry
  $contextPath: ContextPath
}

const checkoutSagaLoadingState: Middleware = async ({
  app,
  error: errorPage,
  route,
  redirect,
  $sentry,
  $contextPath,
  store,
}: IExtendedContext): Promise<void> => {
  try {
    executeOnServer(() => startMeasurement(`server:fetch -> ${route.path}`))
    const { url: appUrl } = getUrl(app.isDev)

    const {
      params: { orderId, hash },
      query: {
        productId,
        quantity,
        p: phone,
        value: openRangeValue,
        clientToken,
        rdrOrderId: redirectOrderId,
        rdrHash: redirectHash,
      },
      name: routeName,
    } = route

    const { dispatch, getters } = store

    await checkoutSagaLoading(
      {
        dispatch,
        getters,
        errorPage,
        redirect,
        $sentry,
        $contextPath,
      },
      {
        urlParams: {
          orderId,
          hash,
          productId,
          routeName,
          quantity,
          phone,
          openRangeValue,
          clientToken,
          redirectOrderId,
          redirectHash,
        },
        appUrl,
      }
    )
  } catch (error) {
    $sentry.captureException(error)
    if (process.env.NODE_ENV === 'development') throw error
    errorPage(error)
  } finally {
    executeOnServer(() => endMeasurement(`server:fetch -> ${route.path}`))
  }
}

export default checkoutSagaLoadingState
