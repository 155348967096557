
import { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import { Overlay, SearchInput } from '@/components/molecules'
import { NoSearchResults } from '~/components/atoms'
import { IContact, IContactsByLetter } from '~/models/components/Contacts'
import isEmpty from '~/utils/isEmpty'
import { SEARCH_FIELDS } from '~/utils/constants/contacts'
import { groupContactsByLetter } from '~/services/contacts'
import ContactsLetterGroup from '~/components/organisms/contacts-letter-group/index.vue'
import { filterArrayBySearchTerm } from '~/utils/filterArrayBySearchTerm'

export default {
  name: 'SearchContactOverlay',
  components: {
    Overlay,
    SearchInput,
    NoSearchResults,
    ContactsLetterGroup,
  },
  props: {
    contactsFlat: {
      type: Array,
      required: true,
    } as PropOptions<IContact[]>,
    direction: {
      type: String,
      default: 'ltr',
    } as PropOptions<'ltr' | 'rtl'>,
  },
  data() {
    return {
      searchTerm: null,
    }
  },
  computed: {
    ...mapGetters('context', ['language']),
    prefixClasses(): string {
      return 'absolute h-full top-0 -left-2 transform rotate-180 cursor-pointer flex items-center z-10'
    },
    inputPadding(): string {
      return this.direction === 'ltr' ? 'pl-12' : 'pr-12'
    },
    contactsGroupedByName(): IContactsByLetter {
      let contacts = [...this.contactsFlat]
      if (this.searchTerm) {
        contacts = filterArrayBySearchTerm(
          contacts,
          SEARCH_FIELDS,
          this.searchTerm
        )
      }
      return groupContactsByLetter(contacts, this.language.language)
    },
    noSearchResults(): boolean {
      return isEmpty(this.contactsGroupedByName)
    },
  },
  methods: {
    closeList(): void {
      this.$emit('close-dropdown')
    },
    setTerm(term): void {
      this.searchTerm = term
    },
    clearTerm(): void {
      this.searchTerm = null
    },
  },
}
