import { ActionTree } from 'vuex'
import { IUiCheckoutState } from './state'
import { TYPES } from './mutation-types'
import { STEPS } from './enums/steps'
import { PAYSAFE_TERMS_AND_CONDITION_LINKS } from './enums/paysafe-terms-and-conditions-links'
import { BRAND_CONFIG } from './enums/brandConfig'
import isEmpty from '~/utils/isEmpty'
import get from '~/utils/get'
import { IAsyncStoreState } from '~/factories/async-store/State'

const {
  SET_PAYMENT_METHOD_FORM_STATE,
  SET_BILLING_INFO_FORM_VALUES,
  SET_BILLING_INFO_FORM_FIELDS,
  SET_STEP,
  SET_HAS_CERTIFIED_BADGE,
  SET_HAS_TERMS_AND_CONDITIONS,
  SET_TERMS_AND_CONDITIONS_URL,
  SET_HAS_EXTRA_PAYMENT_INFORMATON,
  SET_SUBMIT_STATE,
  SET_CHECKOUT_SECTIONS_STATE,
} = TYPES

const actions: ActionTree<IUiCheckoutState, IUiCheckoutState> = {
  showPaymentMethodFormState({ commit }): void {
    commit(SET_PAYMENT_METHOD_FORM_STATE, true)
  },
  hidePaymentMethodFormState({ commit }): void {
    commit(SET_PAYMENT_METHOD_FORM_STATE, false)
  },
  saveBillingInfoFormValues({ commit }, { values }): void {
    commit(SET_BILLING_INFO_FORM_VALUES, { values })
  },
  clearBillingInfoFormValues({ commit }): void {
    commit(SET_BILLING_INFO_FORM_VALUES, { values: {} })
  },
  showBrandSettings({ commit, rootGetters }, brand): void {
    const brandConfig = Object.values(BRAND_CONFIG).find(
      markedBrand => markedBrand.name === brand
    )
    const currentLanguage = rootGetters['context/language'].language
    const termsAndConditionsUrl = PAYSAFE_TERMS_AND_CONDITION_LINKS[
      currentLanguage
    ]
      ? PAYSAFE_TERMS_AND_CONDITION_LINKS[currentLanguage]
      : PAYSAFE_TERMS_AND_CONDITION_LINKS['en-gb']
    const hasCertifiedBadge = get(brandConfig, 'hasCertifiedBadge')
    const hasTermsAndConditions = get(brandConfig, 'hasTermsAndConditions')
    const hasExtraPaymentInformation = get(
      brandConfig,
      'hasExtraPaymentInformation'
    )

    commit(SET_HAS_CERTIFIED_BADGE, hasCertifiedBadge)
    commit(SET_HAS_TERMS_AND_CONDITIONS, hasTermsAndConditions)
    commit(SET_TERMS_AND_CONDITIONS_URL, termsAndConditionsUrl)
    commit(SET_HAS_EXTRA_PAYMENT_INFORMATON, hasExtraPaymentInformation)
  },
  toggleBillingInfoForm({ commit }, { fields = [] } = {}): void {
    commit(SET_BILLING_INFO_FORM_FIELDS, {
      fields,
      visible: !isEmpty(fields),
    })
  },
  setCheckoutSectionsState({ commit }, sectionState) {
    commit(SET_CHECKOUT_SECTIONS_STATE, sectionState)
  },
  hidePaymentForm({ commit }): void {
    commit(SET_BILLING_INFO_FORM_FIELDS, { fields: [], show: false })
  },
  setValidationCodeStep({ commit }) {
    commit(SET_STEP, STEPS.validationCode)
  },
  setSubmitState(
    { commit },
    { failed = false, succeeded = false, loading = false }: IAsyncStoreState
  ): void {
    commit(SET_SUBMIT_STATE, { failed, succeeded, loading })
  },
  rehydrate({ commit }, { formValues: values, step }): void {
    if (this.$router.app.$route.query.saved) {
      commit(SET_STEP, step)
      return
    }

    commit(SET_BILLING_INFO_FORM_VALUES, { values })
  },
  resetStep({ commit }): void {
    commit(SET_STEP, STEPS.initial)
  },
}

export default actions
