export const PERMANENT_REDIRECT_STATUS_CODE = 301
export const TEMPORARY_REDIRECT_STATUS_CODE = 302

export const TWO_CHARACTER_LMP_PRODUCT_PAGES = ['o2', 'ee', 'du']

export const AUTH_ROUTES = ['login', 'register', 'forgot-password']

export const BLOG_REDIRECT_LOCALE_COUNTRY = {
  'en-ie': 'en-gb/ie',
  'en-es': 'en-gb/ie',
  'en-pl': 'en-gb/ie',
  'en-it': 'en-gb/ie',
  'en-ch': 'en-gb/ie',
  'de-ch': 'de/at',
  'en-no': 'en-gb/ie',
  'nb-no': 'en-gb/ie',
  'en-se': 'en-gb/ie',
  'sv-se': 'en-gb/ie',
  'en-dk': 'en-gb/ie',
  'da-dk': 'en-gb/ie',
  'en-be': 'en-gb/ie',
  'fr-be': 'en-gb/ie',
  'nl-be': 'en-gb/ie',
  'en-tr': 'en-gb/ie',
  'tr-tr': 'en-gb/ie',
  'en-pt': 'en-gb/ie',
  'en-ae': 'en-gb/ie',
  'ar-ae': 'en-gb/ie',
  'en-sa': 'en-gb/ie',
  'ar-sa': 'en-gb/ie',
  'en-at': 'en-gb/ie',
  'en-gr': 'en-gb/ie',
  'gr-gr': 'en-gb/ie',
  'el-gr': 'en-gb/ie',
  'es-us': 'es-mx/mx',
}
export const CRYPTO_SLUGS = [
  'bitnovo',
  'crypto-voucher',
  'cryptocurrency',
  'crypto-currency',
]
export const CRYPTO_COUNTRIES = [
  'it',
  'es',
  'pl',
  'at',
  'be',
  'no',
  'se',
  'dk',
  'gb',
  'ch',
  'ie',
  'pt',
  'fi',
  'sk',
  'gr',
  'sa',
  'ae',
  'au',
  'mx',
  'us',
  'ca',
  'in',
  'fr',
]
