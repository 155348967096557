export default {
  amazon: 'amazon',
  'amazon-mx': 'amazon',
  'astropay-eur': 'astropay',
  blim: 'shopping-gift-cards',
  blizzard: 'blizzard',
  cashu: 'prepaid-credit-cards',
  cinepolis: 'entertainment-gift-cards',
  'codigos-entretenimiento': 'entertainment-gift-cards',
  'codigos-juegos': 'gaming-cards',
  coppel: 'shopping-gift-cards',
  dent: 'mobile-top-up',
  'derecho-a-retirada': 'how-it-works',
  elektra: 'elektra',
  experimenta: 'shopping-gift-cards',
  fortnite: 'fortnite',
  gandhi: 'gandhi',
  'innova-sport': 'innova-sport',
  italiannis: 'shopping-gift-cards',
  'jeton-cash-eur': 'jeton-cash',
  'karma-koin': 'karma-koin',
  'league-of-legends': 'league-of-legends',
  'nintendo-eshop': 'nintendo-eshop',
  'payment-methods': 'payment-methods',
  'prepaid-amex-us': 'prepaid-credit-cards',
  'prepaid-mastercard-us': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'recarga-movil': 'mobile-top-up',
  'sobre-nosotros': 'https://company.recharge.com',
  'spotify-premium': 'spotify-premium',
  'tarjetas-de-pago': 'prepaid-credit-cards',
  'tarjetas-regalo': 'shopping-gift-cards',
  'uso-de-cookies': 'cookie-statement',
  'visa-prepaid-us': 'prepaid-credit-cards',
  'xbox-gift-card': 'xbox-gift-card',
  'at-t-iusacell': 'at-t-prepaid',
  movistar: 'movistar',
  telcel: 'telcel',
  unefon: 'unefon',
  'virgin-mobile-mx': 'virgin-mobile',
  mixup: 'mix-up',
  'minecraft-minecoins': 'minecraft',
  'virgin-mobile-us': 'virgin-mobile',
}
