import { MutationTree } from 'vuex'
import { TYPES } from './mutation-types'
import { IUiCheckoutState, ICheckoutSections } from './state'
import { STEPS } from './enums/steps'
import isEmpty from '~/utils/isEmpty'
import {
  CheckoutPaymentMethodModel,
  ITopupPaymentMethod,
} from '~/models/components/CheckoutPaymentMethod'
import { IAsyncStoreState } from '~/factories/async-store/State'

export interface ISetPaymentMethodsArgs {
  paymentMethods: ITopupPaymentMethod[]
  shouldSeparateStreetHouseInputs: boolean
}

const {
  SET_BILLING_INFO_FORM_FIELDS,
  SET_BILLING_INFO_FORM_VALUES,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_METHOD_FORM_STATE,
  SET_BILLING_INFO_FORM_VISIBILITY,
  SET_SUBMIT_STATE,
  SET_SUBMIT_VERIFICATION_CODE_STATE,
  SET_RESEND_CODE_STATE,
  SET_HAS_CERTIFIED_BADGE,
  SET_STEP,
  SET_BREADCRUMBS,
  SET_HAS_TERMS_AND_CONDITIONS,
  SET_TERMS_AND_CONDITIONS_URL,
  SET_HAS_EXTRA_PAYMENT_INFORMATON,
  SET_CHECKOUT_SECTIONS_STATE,
} = TYPES

const mutations: MutationTree<IUiCheckoutState> = {
  [SET_BILLING_INFO_FORM_VISIBILITY](state, value): void {
    state.billingInfoForm.visible = value
  },
  [SET_HAS_CERTIFIED_BADGE](state, value): void {
    state.hasCertifiedBadge = value
  },
  [SET_TERMS_AND_CONDITIONS_URL](state, value): void {
    state.termsAndConditionsUrl = value
  },
  [SET_HAS_EXTRA_PAYMENT_INFORMATON](state, value): void {
    state.hasExtraPaymentInformation = value
  },
  [SET_HAS_TERMS_AND_CONDITIONS](state, value): void {
    state.hasTermsAndConditions = value
  },
  [SET_PAYMENT_METHOD_FORM_STATE](state, value): void {
    state.paymentMethodForm.enabled = value
  },
  [SET_BILLING_INFO_FORM_FIELDS](
    state: IUiCheckoutState,
    { fields, visible }
  ): void {
    state.billingInfoForm = {
      ...state.billingInfoForm,
      fields,
      visible,
    }
  },
  [SET_SUBMIT_STATE](
    state: IUiCheckoutState,
    submitState: IAsyncStoreState
  ): void {
    state.submit = { ...submitState }
  },
  [SET_BILLING_INFO_FORM_VALUES](state: IUiCheckoutState, { values }): void {
    state.billingInfoForm.values = isEmpty(values) ? '' : JSON.stringify(values)
  },
  [SET_PAYMENT_METHODS](
    state: IUiCheckoutState,
    { paymentMethods }: ISetPaymentMethodsArgs
  ): void {
    state.paymentMethods = paymentMethods.map(method =>
      new CheckoutPaymentMethodModel(method).toJSON()
    )
  },
  [SET_STEP](state: IUiCheckoutState, step: STEPS): void {
    state.step = step
  },
  [SET_SUBMIT_VERIFICATION_CODE_STATE](
    state: IUiCheckoutState,
    { failed = false, succeeded = false, loading = false }
  ): void {
    state.submitVerificationCode = {
      failed,
      succeeded,
      loading,
    }
  },
  [SET_CHECKOUT_SECTIONS_STATE](
    state: IUiCheckoutState,
    checkoutSections: ICheckoutSections
  ) {
    state.checkoutSections = {
      ...state.checkoutSections,
      ...checkoutSections,
    }
  },
  [SET_RESEND_CODE_STATE](
    state: IUiCheckoutState,
    { failed = false, succeeded = false, loading = false }
  ): void {
    state.resendCode = {
      failed,
      succeeded,
      loading,
    }
  },
  [SET_BREADCRUMBS](state: IUiCheckoutState, breadcrumbs): void {
    state.breadcrumbs = breadcrumbs
  },
}

export default mutations
