
import { mapGetters, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import get from '~/utils/get'
import {
  Card,
  UiButton,
  UiInput,
  UiLink,
  UiTitle,
  Icon,
} from '~/components/atoms'
import { CheckoutSteps } from '~/enums/checkout'

export default {
  components: {
    Card,
    UiButton,
    UiInput,
    UiLink,
    UiTitle,
    Icon,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    localeOptions: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      code: '',
      isFocused: false,
    }
  },
  computed: {
    ...mapGetters('checkout', ['createPaymentPayload']),
    ...mapGetters('ui/checkout', [
      'isResendCodeLoading',
      'hasResendCodeSucceeded',
      'hasResendCodeFailed',
    ]),
    message(): string {
      const isInvalid = !this.$v.code.$dirty || this.isFocused
      if (!this.$v.code.required && !isInvalid)
        return this.$t('form.validations.required')

      return ''
    },
    showIcon(): boolean {
      const {
        isResendCodeLoading,
        hasResendCodeFailed,
        hasResendCodeSucceeded,
      } = this
      return (
        isResendCodeLoading || hasResendCodeFailed || hasResendCodeSucceeded
      )
    },
    iconName(): undefined | 'loader' | 'close' | 'check' {
      const { isResendCodeLoading, hasResendCodeSucceeded, showIcon } = this

      if (!showIcon) return undefined
      if (isResendCodeLoading) return 'loader'
      if (hasResendCodeSucceeded) return 'check'

      return 'close'
    },
    iconColor(): 'error' | 'success' {
      const { hasResendCodeFailed } = this
      if (hasResendCodeFailed) return 'error'
      return 'success'
    },
    phoneNumber() {
      return get(
        this.createPaymentPayload,
        'paymentInformationData.phoneChallengeNumber'
      )
    },
  },
  async mounted() {
    this.$gtm.init()
    this.trackCheckoutProgress(CheckoutSteps.MOBILEVERIFICATION_LOADED)
  },
  methods: {
    ...mapActions('checkout', ['resendCode', 'savePhoneChallengeCode']),
    handleSubmit() {
      this.trackCheckoutProgress(CheckoutSteps.MOBILEVERIFICATION_VERIFY)
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$emit('checkout-verification-code:submit')
    },
    handleInputChange() {
      if (this.$v.$invalid) return
      this.savePhoneChallengeCode(this.code)
    },
    handleCodeResend() {
      if (this.isLoading) return
      this.$gtmEnhanced.trackCodeResend()
      this.resendCode({ locale: this.localeOptions })
    },
    trackCheckoutProgress(step) {
      if (process.server) return
      this.$gtmEnhanced.trackCheckoutProgress(step)
    },
  },
  validations: {
    code: {
      required,
      minLength: minLength(6),
    },
  },
}
