
import { mapGetters } from 'vuex'
import ProductField from './product-field.vue'
import {
  brandSlug,
  country,
  discountPrice,
  hasCertifiedBadge,
  locale,
  product,
  productPrice,
  promoCode,
  promoCodeError,
  promoCodeHasBeenApplied,
  promoCodeLoading,
  quantity,
  maximumQuantity,
  totalPrice,
  transactionCost,
} from './props'
import PromoCodeField from './promo-code-field.vue'
import {
  UiTitle,
  Divider,
  Flag,
  Alert,
  UiImage,
  Icon,
  Card,
  UiSelect,
} from '~/components/atoms'
export default {
  components: {
    Card,
    Divider,
    UiTitle,
    ProductField,
    Flag,
    Alert,
    UiImage,
    Icon,
    UiSelect,
    PromoCodeField,
  },
  props: {
    brandSlug,
    country,
    discountPrice,
    hasCertifiedBadge,
    locale,
    product,
    productPrice,
    promoCode,
    promoCodeError,
    promoCodeHasBeenApplied,
    promoCodeLoading,
    quantity,
    totalPrice,
    transactionCost,
    maximumQuantity,
  },
  computed: {
    ...mapGetters('context', ['useRecommerce']),
    productLogoImg() {
      return this.useRecommerce
        ? this.product.product.productCardImage
        : this.product.logo
    },
    amountSelectorOptions(): { id: number; value: number; label: string }[] {
      return [...Array(this.maximumQuantity).keys()].map((_item, index) => {
        const value = index + 1

        return { id: index, value, label: value.toString() }
      })
    },
  },
  methods: {
    propagatePromoCodeRemove() {
      this.$emit('promoCode:remove')
    },
    propagatePromoCodeSubmit(promoCode) {
      this.$emit('promoCode:submit', promoCode)
    },
    handleAmountSelectorSelect({ value }): void {
      console.log(`Amount selected: ${value}`)
    },
  },
}
