export default Object.freeze({
  payeruseddifferentcard: 'payer_used_different_card',
  notenoughbalance: 'not_enough_balance',
  issuerunavailable: 'issuer_unavailable',
  invalidpayloadprovided: 'invalid_payload_provided',
  unknownreferer: 'unknown_referer',
  invalidcardnumber: 'invalid_card_number',
  acquirererror: 'acquirer_error',
  requestalreadyprocessedorinprogress:
    'request_already_processed_or_in_progress',
  '102': 'unknown_variant',
  '103': 'cvc_code_length',
  '105': '3d_secure_error',
  '122': 'phone_number_missing',
  '167': 'psp_reference_required',
  '172': 'encrypted_data_timeout',
  '905': 'acquier_config_missing',
  '907': 'uspayment_details_not_supported',
  '': 'unknown',
  errornotprovided: 'error_not_provided',
})
