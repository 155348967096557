import uuid from 'uuid/v4'
import { TYPES } from './mutation-types'
import { IUiNotificationsState } from './state'
import { Notification, RawNotification } from './types'
import { Commit, mutationTree } from '~/store/types'

const CLOSE_AFTER_DEFAULT = 5000

const { PUSH_NOTIFICATION, POP_NOTIFICATION } = TYPES
const mutations = mutationTree<IUiNotificationsState>()({
  [PUSH_NOTIFICATION](state, notification: RawNotification): void {
    const copy = { ...notification }

    if (!copy.id) {
      copy.id = uuid()
    }

    if (!copy.closeAfter) {
      copy.closeAfter = CLOSE_AFTER_DEFAULT
    }

    state.notifications.push(copy as Notification)
  },
  [POP_NOTIFICATION](state, notificationId: string | undefined): void {
    if (notificationId) {
      state.notifications = state.notifications.filter(
        ({ id }) => id !== notificationId
      )
    } else {
      state.notifications.pop()
    }
  },
})

export type NotificationsCommit = Commit<typeof mutations>

export default mutations
