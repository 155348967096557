
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    lazy: Boolean,
  },
}
