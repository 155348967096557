import { deleteObjectKey } from './object'

export function getStorageItem({
  storage,
  key,
}: {
  storage: string
  key?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
  const storageData = window.localStorage.getItem(storage) || '{}'
  const parsedStorage = JSON.parse(storageData)

  return key ? parsedStorage[key] : parsedStorage
}

export function setStorageItem({
  storage,
  key,
  value,
}: {
  storage: string
  key?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}): void {
  let storageData = value

  if (key) {
    storageData = getStorageItem({ storage })
    storageData[key] = value
  }

  const stringifiedStorageData = JSON.stringify(storageData)

  window.localStorage.setItem(storage, stringifiedStorageData)
}

export function clearStorageItem({
  storage,
  key,
}: {
  storage: string
  key?: string
}): void {
  let newStorageData = {}

  if (key) {
    const storageData = getStorageItem({ storage })
    newStorageData = deleteObjectKey(storageData, key)
  }

  const stringifiedStorageData = JSON.stringify(newStorageData)

  window.localStorage.setItem(storage, stringifiedStorageData)
}

export default { getStorageItem, setStorageItem, clearStorageItem }
