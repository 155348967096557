export const UNO_MERCHANT = {
  ID: 13,
  NAME: 'UNO_GLOBAL',
}
export const NUMBER_OF_RESULTS = 10
export const DEFAULT_REQUEST_PAGE_NUMBER = 0

export const CLASSIC_URL_PREFIXES = [
  '-top-up',
  '-opwaarderen',
  '-aufladen',
  'ricarica-',
  'recharge-',
  'recarga-',
  'recarrega-',
  'doladowanie-',
  '-afghanistan',
  '-albania',
  '-algeria',
  '-american-samoa',
  '-anguilla',
  '-antigua-and-barbuda',
  '-argentina',
  '-armenia',
  '-aruba',
  '-austria',
  '-azerbaijan',
  '-bahamas',
  '-bahrain',
  '-bangladesh',
  '-barbados',
  '-belarus',
  '-belgium',
  '-belize',
  '-benin',
  '-bermuda',
  '-bhutan',
  '-bolivia',
  '-bonaire',
  '-botswana',
  '-brazil',
  '-british-virgin-islands',
  '-burkina-faso',
  '-burundi',
  '-cambodia',
  '-cameroon',
  '-canada',
  '-cape-verde',
  '-cayman-islands',
  '-central-african-republic',
  '-chile',
  '-china',
  '-colombia',
  '-comoros',
  '-congo-brazzaville',
  '-congo-kinshasa',
  '-costa-rica',
  '-cuba',
  '-curacao',
  '-cyprus',
  '-dominica',
  '-dominican-republic',
  '-ecuador',
  '-egypt',
  '-el-salvador',
  '-ethiopia',
  '-fiji',
  '-france',
  '-french-guiana',
  '-gambia',
  '-georgia',
  '-germany',
  '-ghana',
  '-greece',
  '-greenland',
  '-grenada',
  '-guatemala',
  '-guinea',
  '-guinea-bissau',
  '-guyana',
  '-guam',
  '-haiti',
  '-honduras',
  '-hong-kong',
  '-india',
  '-indonesia',
  '-iraq',
  '-ireland',
  '-italy',
  '-ivory-coast',
  '-jamaica',
  '-jordan',
  '-kazakhstan',
  '-kenya',
  '-kosovo',
  '-kuwait',
  '-kyrgyzstan',
  '-laos',
  '-lebanon',
  '-liberia',
  '-lithuania',
  '-madagascar',
  '-malawi',
  '-malaysia',
  '-mali',
  '-martinique',
  '-mexico',
  '-moldova',
  '-mongolia',
  '-montserrat',
  '-morocco',
  '-mozambique',
  '-myanmar',
  '-namibia',
  '-nauru',
  '-nepal',
  '-netherlands',
  '-nicaragua',
  '-niger',
  '-nigeria',
  '-oman',
  '-pakistan',
  '-palestine',
  '-panama',
  '-papua-new-guinea',
  '-paraguay',
  '-peru',
  '-philippines',
  '-poland',
  '-portugal',
  '-puerto-rico',
  '-qatar',
  '-romania',
  '-russia',
  '-rwanda',
  '-saint-kitts-and-nevis',
  '-saint-lucia',
  '-saint-vincent-the-grenadines',
  '-samoa',
  '-saudi-arabia',
  '-senegal',
  '-sierra-leone',
  '-singapore',
  '-south-africa',
  '-south-korea',
  '-spain',
  '-sri-lanka',
  '-suriname',
  '-swaziland',
  '-switzerland',
  '-syria',
  '-tajikistan',
  '-tanzania',
  '-thailand',
  '-togo',
  '-tonga',
  '-trinidad-and-tobago',
  '-tunisia',
  '-turkey',
  '-turks-and-caicos-islands',
  '-uganda',
  '-ukraine',
  '-united-arab-emirates',
  '-united-kingdom',
  '-united-states',
  '-uruguay',
  '-uzbekistan',
  '-vanuatu',
  '-venezuela',
  '-vietnam',
  '-yemen',
  '-zambia',
]
