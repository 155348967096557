import { mutationTree, Commit } from '../types'
import { IRecommerceState } from './state'
import { TYPES } from './mutation-types'
import { ProductData } from '~/normalizers/recommerce/product'
import { VariantData } from '~/normalizers/recommerce/variant'

const { SET_PRODUCT, SET_VARIANT } = TYPES
const mutations = mutationTree<IRecommerceState>()({
  // TODO: add proper typing
  [SET_PRODUCT](state, product: ProductData): void {
    state.product = product
  },
  [SET_VARIANT](state, variant: VariantData): void {
    state.variant = variant
  },
})

export type RecommerceCommit = Commit<typeof mutations>

export default mutations
