export default {
  'amazon-it': 'amazon',
  'amazon-it/': 'amazon',
  'amazon-it/10-eur': 'amazon/10-eur-it',
  'amazon-it/100-eur': 'amazon/100-eur-it',
  'amazon-it/15-eur': 'amazon/15-eur-it',
  'amazon-it/25-eur': 'amazon/25-eur-it',
  'amazon-it/50-eur': 'amazon/50-eur-it',
  'app-store-itunes': 'app-store-itunes',
  'app-store-itunes/': 'app-store-itunes',
  'app-store-itunes/100-eur': 'app-store-itunes/100-eur-it',
  'app-store-itunes/15-eur': 'app-store-itunes/15-eur-it',
  'app-store-itunes/25-eur': 'app-store-itunes/25-eur-it',
  'app-store-itunes/50-eur': 'app-store-itunes/50-eur-it',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  'blizzard/50-eur': 'blizzard',
  carrefour: 'carrefour',
  'carrefour/25-eur': 'carrefour/25-eur-it',
  'carrefour/50-eur': 'carrefour/50-eur-it',
  'carte-credito-prepagate': 'prepaid-credit-cards',
  'carte-regalo-giochi': 'gaming-cards',
  'carte-regalo-intrattenimento': 'entertainment-gift-cards',
  'carte-regalo-shopping': 'shopping-gift-cards',
  cashlib: 'cashlib',
  'cashlib/': 'cashlib',
  'cashlib/100-eur': 'cashlib/100-eur',
  cashu: 'prepaid-credit-cards',
  'come-funziona': 'how-it-works',
  criptovaluta: 'cryptocurrency',
  'crypto-voucher': 'crypto-voucher',
  dazn: 'dazn',
  'dazn/1-mese': 'dazn',
  'dichiarazione-dei-cookie': 'cookie-statement',
  'digi-mobil': 'digi-mobil',
  'digi-mobil/10-eur': 'digi-mobil',
  'digi-mobil/15-eur': 'digi-mobil',
  'digi-mobil/20-eur': 'digi-mobil',
  'digi-mobil/5-eur': 'digi-mobil',
  'diritto-di-recesso': 'how-it-works',
  'ea-origin': 'ea-origin',
  'ea-origin/15-eur': 'ea-origin/15-eur',
  'ea-origin/30-eur': 'ea-origin/30-eur',
  ecovoucher: 'ecovoucher',
  esselunga: 'esselunga',
  'esselunga/10-eur': 'esselunga/10-eur-it',
  'eve-online': 'gaming-cards',
  'eve-online/1100-plex': 'gaming-cards',
  'eve-online/500-plex': 'gaming-cards',
  fastweb: 'fastweb',
  'fastweb/10-eur': 'fastweb/10-eur',
  'fastweb/100-eur': 'fastweb/100-eur',
  'fastweb/15-eur': 'fastweb/15-eur',
  'fastweb/25-eur': 'fastweb/25-eur',
  'fastweb/5-eur': 'fastweb/5-eur',
  'fastweb/50-eur': 'fastweb/50-eur',
  'fifa-points': 'gaming-cards',
  'fifa-points/1050-xbox-one': 'gaming-cards',
  'fifa-points/12000-xbox-one': 'gaming-cards',
  'fifa-points/1600-xbox-one': 'gaming-cards',
  'fifa-points/500-xbox-one': 'gaming-cards',
  'fifa-points/750-xbox-one': 'gaming-cards',
  flexepin: 'flexepin',
  fortnite: 'gaming-cards',
  'free-fire': 'free-fire',
  'free-fire/1080-diamonds': 'free-fire',
  'google-play': 'google-play',
  'google-play/': 'google-play',
  'google-play/100-eur': 'google-play/100-eur-it',
  'google-play/15-eur': 'google-play/15-eur-it',
  'google-play/25-eur': 'google-play/25-eur-it',
  'google-play/5-eur': 'google-play/5-eur-it',
  'google-play/50-eur': 'google-play/50-eur-it',
  'guild-wars': 'gaming-cards',
  'ho-mobile': 'ho-mobile',
  iliad: 'iliad',
  'iliad/10-eur': 'iliad/10-eur',
  'iliad/15-eur': 'iliad/15-eur',
  'iliad/20-eur': 'iliad/20-eur',
  'iliad/30-eur': 'iliad/30-eur',
  'iliad/5-eur': 'iliad/5-eur',
  'infinity-pass': 'entertainment-gift-cards',
  'infinity-pass/': 'entertainment-gift-cards',
  'infinity-pass/12-mesi': 'entertainment-gift-cards',
  'infinity-pass/3-mesi': 'entertainment-gift-cards',
  'informativa-sulla-privacy': 'privacy-statement',
  'karma-koin': 'karma-koin',
  'karma-koin/10-eur': 'karma-koin/10-eur',
  'karma-koin/25-eur': 'karma-koin/25-eur',
  'karma-koin/50-eur': 'karma-koin/50-eur',
  'kena-mobile': 'kena-mobile',
  'kena-mobile/10-eur': 'kena-mobile/10-eur',
  'kena-mobile/15-eur': 'kena-mobile/15-eur',
  'kena-mobile/25-eur': 'kena-mobile/25-eur',
  'kena-mobile/5-eur': 'kena-mobile/5-eur',
  'kena-mobile/50-eur': 'kena-mobile/50-eur',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends/10-eur',
  'league-of-legends/20-eur': 'league-of-legends/20-eur',
  linkem: 'linkem',
  lycamobile: 'lycamobile',
  'lycamobile/10-eur': 'lycamobile/10-eur-it',
  'lycamobile/5-eur': 'lycamobile/5-eur-it',
  'lycamobile/globe-plus-bundle-11-99': 'lycamobile',
  'lycamobile/gold-bundle-9-99': 'lycamobile',
  mediaworld: 'mediaworld',
  'mediaworld/100-eur': 'mediaworld/100-eur-it',
  'mediaworld/25-eur': 'mediaworld/25-eur-it',
  'mediaworld/50-eur': 'mediaworld/50-eur-it',
  'modalita-di-pagamento': 'payment-methods',
  moneyclic: 'prepaid-credit-cards',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin/10-eur',
  'ncoin/100-eur': 'ncoin/100-eur',
  'ncoin/20-eur': 'ncoin/20-eur',
  'ncoin/5-eur': 'ncoin/5-eur',
  'ncoin/50-eur': 'ncoin/50-eur',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'netflix/': 'netflix',
  'netflix/15-eur': 'netflix/15-eur',
  'netflix/25-eur': 'netflix/25-eur',
  'netflix/50-eur': 'netflix/50-eur',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop/15-eur',
  'nintendo-eshop/25-eur': 'nintendo-eshop/25-eur',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/': 'nintendo-switch-online',
  'nintendo-switch-online/12-mesi': 'nintendo-switch-online/12-months',
  'nintendo-switch-online/3-mesi': 'nintendo-switch-online/3-months',
  openbucks: 'openbucks',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-now': 'playstation-now',
  'playstation-now/': 'playstation-now',
  'playstation-now/1-mese': 'playstation-now/1-month-it',
  'playstation-now/12-mesi': 'playstation-now/12-months-it',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/': 'playstation-plus',
  'playstation-plus/12-mesi': 'playstation-plus/12-months-it',
  'playstation-plus/3-mesi': 'playstation-plus/3-months-it',
  'playstation-store': 'playstation-store',
  'playstation-store/': 'playstation-store',
  'playstation-store/10-eur': 'playstation-store/10-eur-it',
  'playstation-store/20-eur': 'playstation-store/20-eur-it',
  'playstation-store/20-eur/': 'playstation-store',
  'playstation-store/3-mesi': 'playstation-store',
  'playstation-store/35-eur': 'playstation-store',
  postemobile: 'postemobile',
  'postemobile/10-eur': 'postemobile/10-eur',
  'postemobile/5-eur': 'postemobile/5-eur',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'pubg-us': 'pubg',
  'rakuten-tv': 'rakuten-tv',
  'red-dead-online': 'gaming-cards',
  'red-dead-online/150-gold-bars-xbox-one': 'gaming-cards',
  'red-dead-online/55-gold-bars': 'gaming-cards',
  'ricarica-cellulare': 'mobile-top-up',
  'riguardo-a-noi': 'https://company.recharge.com',
  roblox: 'roblox',
  'roblox/1700-xbox-one': 'roblox',
  'roblox/400-xbox-one': 'roblox',
  'roblox/4500-xbox-one': 'roblox',
  'roblox/800-xbox-one': 'roblox',
  runescape: 'runescape',
  'runescape/30-giorni': 'runescape/30-days',
  'runescape/90-giorni': 'runescape/90-days',
  simfonia: 'mobile-top-up',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/': 'spotify-premium',
  'spotify-premium/10-eur': 'spotify-premium/10-eur-it',
  'spotify-premium/30-eur': 'spotify-premium/30-eur-it',
  'spotify-premium/60-eur': 'spotify-premium/60-eur-it',
  steam: 'steam',
  'steam/': 'steam',
  'steam/100-eur': 'steam/100-eur',
  'steam/20-eur': 'steam/20-eur',
  'steam/25-eur': 'steam/25-eur',
  'steam/5-eur': 'steam/5-eur',
  'steam/50-eur': 'steam/50-eur',
  'ticket-premium': 'prepaid-credit-cards',
  tim: 'tim',
  'tim/10-eur': 'tim/10-eur',
  'tim/100-eur': 'tim/100-eur',
  'tim/15-eur': 'tim/15-eur',
  'tim/20-eur': 'tim/20-eur',
  'tim/30-eur': 'tim/30-eur',
  'tim/5-eur': 'tim/5-eur',
  'tim/50-eur': 'tim/50-eur',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'vectone-mobile': 'vectone-mobile',
  'very-mobile': 'very-mobile',
  'very-mobile/10-eur': 'very-mobile/10-eur',
  'very-mobile/15-eur': 'very-mobile/15-eur',
  'very-mobile/25-eur': 'very-mobile/25-eur',
  'very-mobile/5-eur': 'very-mobile/5-eur',
  'very-mobile/50-eur': 'very-mobile/50-eur',
  viber: 'viber',
  vodafone: 'vodafone',
  'vodafone/30-eur': 'vodafone',
  'vodafone/50-eur': 'vodafone/50-eur-it',
  'wind-tre': 'wind-tre',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-giorni': 'world-of-warcraft/60-days',
  'xbox-game-pass': 'xbox-game-pass',
  'xbox-game-pass/console-3-mesi': 'xbox-game-pass',
  'xbox-game-pass/console-6-mesi': 'xbox-game-pass',
  'xbox-game-pass/pc-3-mesi': 'xbox-game-pass',
  'xbox-game-pass/ultimate-1-mese': 'xbox-game-pass',
  'xbox-game-pass/ultimate-3-mesi': 'xbox-game-pass',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card/': 'xbox-gift-card',
  'xbox-gift-card/10-eur': 'xbox-gift-card/10-eur',
  'xbox-gift-card/15-eur': 'xbox-gift-card/15-eur',
  'xbox-gift-card/20-eur': 'xbox-gift-card/20-eur',
  'xbox-gift-card/25-eur': 'xbox-gift-card/25-eur',
  'xbox-gift-card/30-eur': 'xbox-gift-card/30-eur',
  'xbox-gift-card/5-eur': 'xbox-gift-card/5-eur',
  'xbox-gift-card/50-eur': 'xbox-gift-card/50-eur',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/': 'xbox-live-gold',
  'xbox-live-gold/12-mesi': 'xbox-live-gold/12-months',
  'xbox-live-gold/3-mesi': 'xbox-live-gold/3-months',
  zalando: 'zalando',
  'zalando/': 'zalando',
  'zalando/20-eur': 'zalando/20-eur-it',
  'zalando/50-eur': 'zalando/50-eur-it',
  astropay: 'astropay',
  'jeton-cash': 'jeton-cash',
}
