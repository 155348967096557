enum TYPES {
  SET_PRODUCTS = 'setProducts',
  SET_CONTENTFUL_PRODUCTS_WITH_NO_ID = 'setContentfulProductsWithNoId',
  SET_TOPUP_PRODUCTS_WITH_NO_ID = 'setTopupProductsWithNoId',
  UPDATE_QUICKBUY_PRODUCT = 'updateQuickBuyProduct',
  LOADING_QUICKBUY_PRODUCT = 'loadingFetchQuickBuyProduct',
  FETCH_QUICKBUY_PRODUCT_FAILED = 'fetchQuickBuyProductFailed',
  SET_SOME_PRODUCTS_HAVE_RTR_REDEEM_TYPE = 'setSomeProductsHaveRtrRedeemType',
  UPDATE_QUICKBUY_PRODUCT_AS_NOT_AVAILABLE = 'updateQuickBuyProductAsNotAvailable',
  SET_PAGE_VIEW_EVENT_LOGGED = 'setPageViewEventLogged',
}

export default TYPES
