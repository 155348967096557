/* eslint-disable @typescript-eslint/no-explicit-any */
const get = <TObject extends Record<string | number, unknown>, TDefault>(
  obj?: TObject | unknown,
  path?: string | number | [string | number],
  defaultValue?: TDefault
): any => {
  if (typeof obj !== 'object' || obj === null || path === undefined) {
    return defaultValue || undefined
  }

  if (Array.isArray(path)) {
    const result = path.reduce((result, key) => result && result[key], obj)
    return result || defaultValue || undefined
  }

  const result = path
    .toString()
    .match(/([^[.\]])+/g)
    ?.reduce((result, key) => result && result[key], obj)

  return result !== undefined ? result : defaultValue
}

export default get
