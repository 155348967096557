import Vue from 'vue'
import { Plugin } from '@nuxt/types'

const CURRENCIES_WITH_NO_DECIMALS_EXCEPTION = ['CVE', 'IDR']

interface INumberFormatOptionsWIthFallback extends Intl.NumberFormatOptions {
  fallbackLocale: string
}

const globalFormatting = (currentCurrency, recommerceLocale, number) => {
  const locale = recommerceLocale.replace('_', '-')
  if (
    CURRENCIES_WITH_NO_DECIMALS_EXCEPTION.includes(currentCurrency.currency)
  ) {
    return new Intl.NumberFormat(locale, {
      ...currentCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number)
  }
  return new Intl.NumberFormat(locale, currentCurrency).format(number)
}

const localFormatting = (i18n, number) => {
  const { locale, numberFormats } = i18n
  const localeNumberFormat = numberFormats[locale] || {}

  const { currency = {} } = localeNumberFormat
  const { fallbackLocale = '' } = currency as INumberFormatOptionsWIthFallback
  const localeToFormat = fallbackLocale || locale
  const formattedNumber = new Intl.NumberFormat(
    localeToFormat,
    currency
  ).format(number)
  return formattedNumber
}

const currencyFormatterPlugin: Plugin = ({ app, store }) => {
  const formatNumber = (number: number): string =>
    store.getters['context/isGlobalMarketplace']
      ? globalFormatting(
          store.getters['context/currency'],
          store.getters['context/recommerceLocale'],
          number
        )
      : localFormatting(app.i18n, number)

  Vue.prototype.$n = formatNumber
}

export default currencyFormatterPlugin
