import { ActionContext, MutationTree, Store } from 'vuex/types/index'
import UnionToIntersection from '~/utils/types/unionToIntersection'

// Function that can be used to create a mutationTree that extends
// the MutationTree type form vuex-types, while a more specific type can
// still be inferred.
export const mutationTree = <S>() => <T extends MutationTree<S>>(tree: T): T =>
  tree

// Utility type to create a type for the commit function based on a MutationTree.
//
// Usage:
// const tree = mutationTree<MyState>()({
//   myMutation(state, example: boolean) {
//     state.example = example
//   }
// })
// type MyCommit = Commit(typeof tree)
export type Commit<M> = UnionToIntersection<
  {
    [K in keyof M]: M[K] extends (state: unknown, payload: infer P) => void
      ? (name: K, payload: P) => void
      : never
  }[keyof M]
>

export type Dispatch<M> = UnionToIntersection<
  {
    [K in keyof M]: M[K] extends (payload: infer P) => infer R
      ? (name: K, payload: P) => R
      : never
  }[keyof M]
>

// A patched versions of the ActionTree type of vuex-types that provides
// a types version of the commit and dispatch function.
//
// Usage:
// interface MyActions = {
//   myAction(payload: MyPayload): Promise<void>
// }
// const actions: ActionTree<MyState, MyCommit, MyActions> = {
//   myAction({ commit }, payload) {
//     commit('myPayload', payload)
//   }
// }
export type ActionTree<S, C, A> = {
  [K in keyof A]: A[K] extends (payload: infer P) => infer R
    ? (
        this: Omit<Store<S>, 'commit' | 'dispatch'> & { commit: C } & {
          dispatch: Dispatch<A>
        },
        injectee: Omit<ActionContext<S, S>, 'commit' | 'dispatch'> & {
          commit: C
        } & { dispatch: Dispatch<A> },
        payload: P
      ) => R
    : never
}
