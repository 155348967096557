import { notEmpty } from '~/utils/notEmpty'
import {
  Maybe,
  PageSectionsItem,
  SectionImage,
} from '~/apis/clients/graphql/types/contentful'
import { normalizePageComponents } from '~/normalizers/page'
import { transformImage } from '~/normalizers/base/image'

type NormalizedPageSectionsItem =
  | Pick<PageSectionsItem, '__typename'>
  | Pick<SectionImage, 'image' | '__typename'>

function isImageSection(
  section: NormalizedPageSectionsItem
): section is SectionImage {
  return section.__typename === 'SectionImage'
}

export function normalizeBlogPage(
  sections: Maybe<NormalizedPageSectionsItem>[]
): Record<string, NormalizedPageSectionsItem | NormalizedPageSectionsItem[]> {
  const list = sections.filter(notEmpty)

  const components = normalizePageComponents({
    components: list,
  })

  const transformedList = list.flatMap((item, i) => {
    if (i === list.indexOf(components.sectionBlogLead)) return []
    if (!isImageSection(item) || !item.image) return [item]
    return [
      {
        ...item,
        image: transformImage(item.image),
      },
    ]
  })

  return {
    ...components,
    list: transformedList,
    sectionBlogLead: {
      ...(components.sectionBlogLead && {
        ...components.sectionBlogLead,
        image: transformImage(components.sectionBlogLead.image),
      }),
    },
  }
}
