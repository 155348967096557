
import { PropOptions } from 'vue'
import { CheckoutSteps } from '~/enums/checkout'
import { Row, Column } from '~/components/grid'
import { UiTitle, Icon } from '~/components/atoms'
import { PaymentMethodsChecklist, EditableCard } from '~/components/molecules'

import {
  ICheckoutPaymentMethod,
  IIssuer,
} from '~/models/components/CheckoutPaymentMethod'

export default {
  components: {
    UiTitle,
    PaymentMethodsChecklist,
    Row,
    Column,
    EditableCard,
    Icon,
  },

  props: {
    checkoutPaymentMethodsState: {
      type: Object,
      default: () => ({}),
    },
    paymentMethods: {
      type: Array,
      default: () => [],
    } as PropOptions<ICheckoutPaymentMethod[]>,
    disable: Boolean,
    loading: Boolean,
    savedPaymentMethodId: {
      type: String,
      default: '',
    },
    savedIssuerId: {
      type: String,
      default: '',
    },
    rightOfWithdrawal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      marginClasses: '',
      isActive: false,
    }
  },
  computed: {
    isInEditMode() {
      return this.savedPaymentMethodId && !this.isActive
    },
    isDisabled() {
      const { checkoutPaymentMethodsState, loading, disable } = this
      return (
        (disable || loading) &&
        (checkoutPaymentMethodsState.isFilled ||
          checkoutPaymentMethodsState.isActive)
      )
    },
  },
  methods: {
    onPaymentMethodSelect(
      method: ICheckoutPaymentMethod,
      isPreselected: boolean
    ): void {
      this.$emit('payment-method:select', method, isPreselected)
      this.toggleMarginBottom(method.paymentInformationFields)
    },
    onIssuerSelect(issuer: IIssuer, paymentMethodId: string): void {
      this.$emit('issuer:select', issuer, paymentMethodId)
    },
    onEdit() {
      this.$emit('payment:method:edit')
    },
    onPaymentSubmit(): void {
      this.$gtmEnhanced.trackCheckoutProgress(
        CheckoutSteps.STEP_THREE,
        this.savedPaymentMethodId
      )
      this.$emit('payment-method:submit')
    },
    onPaymentMethodContinue(id): void {
      this.isActive = false
      this.$emit('payment-method:continue', id)
    },
    onPaymentResubmit(): void {
      this.$emit('payment-method:submit')
    },
    toggleMarginBottom(paymentInformationFields = []): void {
      if (paymentInformationFields.length > 0) {
        this.marginClasses = 'mb-4 lg:mb-6'
      } else {
        this.marginClasses = ''
      }
    },
  },
}
