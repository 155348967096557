export default {
  'about-us': 'https://company.recharge.com',
  'amazon-ae': 'amazon',
  'amazon-ae/100-aed': 'amazon',
  'amazon-ae/50-aed': 'amazon',
  'amazon-ae/500-aed': 'amazon',
  'amazon-us': 'amazon',
  'apex-legends': 'gaming-cards',
  bitsa: 'bitsa',
  'call-of-duty': 'gaming-cards',
  careem: 'careem',
  carrefour: 'carrefour',
  cashu: 'prepaid-credit-cards',
  checkout: 'https://www.recharge.com/en/ae',
  'checkout/cancel/': 'https://www.recharge.com/en/ae',
  'checkout/error/': 'https://www.recharge.com/en/ae',
  'cookie-statement': 'cookie-statement',
  'du-ae': 'du-mobile',
  du: 'du-mobile',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  etisalat: 'etisalat',
  'fifa-points': 'gaming-cards',
  'five-mobile': 'five-mobile',
  'five-mobile/15-aed': 'five-mobile',
  'fortnite-us': 'fortnite',
  'free-fire': 'free-fire',
  'gaming-gift-cards': 'gaming-cards',
  'how-it-works': 'how-it-works',
  'karma-koin-us': 'karma-koin',
  'landmark-shukran': 'shukran',
  'landmark-shukran/100-aed': 'shukran',
  'landmark-shukran/250-aed': 'shukran',
  'landmark-shukran/50-aed': 'shukran',
  'league-of-legends-us': 'league-of-legends',
  'mobile-top-up': 'mobile-top-up',
  netflix: 'netflix',
  'netflix/100-aed': 'netflix',
  'netflix/500-aed': 'netflix',
  'nintendo-eshop-us': 'nintendo-eshop',
  noon: 'noon',
  'openbucks-us': 'openbucks',
  'order/error/': 'https://www.recharge.com/en/ae',
  'order/finished/': 'https://www.recharge.com/en/ae',
  'order/paymentprogress/': 'https://www.recharge.com/en/ae',
  'payment-methods': 'payment-methods',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/1-month': 'playstation-plus',
  'playstation-plus/12-months': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store-us': 'playstation-store',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'prepaid-mastercard-usd': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  pubg: 'pubg',
  qanz: 'qanz',
  'roblox-us': 'roblox',
  sharafdg: 'sharaf',
  'shopping-gift-cards': 'shopping-gift-cards',
  'spotify-premium': 'spotify-premium',
  starzplay: 'entertainment-gift-cards',
  steam: 'steam',
  'tamimi-markets': 'tamimi-market',
  'test-brand': 'https://www.recharge.com/en/ae',
  'twitch-us': 'twitch',
  'virgin-mobile': 'virgin-mobile',
  'virgin-mobile/200-aed': 'virgin-mobile',
  'virgin-mobile/50-aed': 'virgin-mobile',
  'xbox-game-pass': 'xbox-game-pass',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card-us': 'xbox-gift-card',
  'xbox-gift-card/15-usd': 'xbox-gift-card',
  'xbox-gift-card/25-usd': 'xbox-gift-card',
  'xbox-gift-card/50-usd': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold-us': 'xbox-live-gold',
  astropay: 'astropay',
  'jeton-cash-eur': 'jeton-cash',
  'visa-prepaid': 'prepaid-credit-cards',
  'prepaid-amex-us': 'prepaid-credit-cards',
  'right-of-withdrawal': 'how-it-works',
  'visa-prepaid-us': 'prepaid-credit-cards',
}
