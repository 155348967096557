
import { mapGetters, mapActions } from 'vuex'
import { Overlay, MainTitle, HlrInput } from '~/components/molecules'
import { GTMEvents } from '~/plugins/gtm/enums'
import { portals } from '~/enums'

export default {
  name: 'PhoneNumberModal',
  components: { Overlay, MainTitle, HlrInput },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentGtmStep: 0,
    }
  },
  computed: {
    ...mapGetters('ui', ['overlayName']),
    gtmValidationTrackEvent() {
      return this.type === 'change'
        ? GTMEvents.CHANGE_NUMBER_RESPONSE
        : GTMEvents.NUMBER_VALIDATION_RESPONSE
    },
    productSlug() {
      return this.$route.path.split('/')[3]
    },
    gtmExitTrackEvent() {
      return this.type === 'change'
        ? GTMEvents.EXIT_CHANGE_NUMBER
        : GTMEvents.NUMBER_VALIDATION_EXIT
    },
  },
  watch: {
    show(val) {
      this.toggleOverlay({
        name: portals.PORTAL_NAME_RTR_PHONE_NUMBER_INPUT,
        visibility: val ? 'visible' : 'hidden',
        type: this.type,
      })
    },
    overlayName(val) {
      if (!val) this.$emit('close')
    },
  },
  methods: {
    ...mapActions('ui', ['toggleOverlay']),
    changeCurrentGTMStep(step) {
      this.currentGtmStep = Math.max(step, this.currentGtmStep)
    },
    onHlrInput(phoneNumberInfo) {
      this.$emit('phone-number-info-input', phoneNumberInfo.encodedPhoneNumber)
    },
    handleOverlayClose() {
      this.$gtmEnhanced.trackNumberValidationExit(
        this.gtmExitTrackEvent,
        this.currentGtmStep
      )
    },
  },
}
