<template>
  <div>
    <ui-button
      data-test="btn-complete-payment"
      :loading="loading"
      class="mt-0"
      @click="onClick"
    >
      {{ $t('general.complete_payment') }}
    </ui-button>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { UiButton } from '~/components/atoms'

export default {
  components: {
    UiButton,
  },
  props: {
    loading: VueTypes.bool.def(false),
  },
  methods: {
    onClick() {
      this.$emit('continue:click')
    },
  },
}
</script>
