import { PropOptions } from 'vue'
import { IExternalLinkModel } from '~/models/base/ExternalLink'
import { TIconType } from '~/models/components/CategoryCard'

export const categoryLink = {
  type: Object,
  default() {
    return {}
  },
} as PropOptions<IExternalLinkModel | Record<string, unknown>>

export const externalLink = {
  type: Object,
  default() {
    return {}
  },
} as PropOptions<IExternalLinkModel | Record<string, unknown>>

export const iconType = {
  type: String,
  default: 'international',
} as PropOptions<TIconType>

export const isNew = {
  type: Boolean,
  default: false,
}

export const title = {
  type: String,
  required: true,
}
