import { IContact, IContactsByLetter } from '~/models/components/Contacts'

export function groupContactsByLetter(
  contacts: IContact[],
  language: string
): IContactsByLetter {
  /* eslint-disable no-param-reassign */
  const groupedContacts = contacts.reduce((groupedContacts, contact) => {
    const firstLetter = contact.name[0]?.toUpperCase() || ''
    if (!groupedContacts[firstLetter]) {
      groupedContacts[firstLetter] = { firstLetter, contacts: [contact] }
    } else {
      groupedContacts[firstLetter].contacts.push(contact)
    }
    return groupedContacts
  }, {})

  return Object.keys(groupedContacts)
    .sort()
    .reduce((group, key) => {
      groupedContacts[key].contacts.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase(), language)
      )
      group[key] = groupedContacts[key]
      return group
    }, {})
  /* eslint-enable no-param-reassign */
}
