
import {
  HydratedSearchList as SearchList,
  Overlay,
  MainTitle,
} from '@/components/molecules'
import { ButtonBox } from '@/components/atoms'
import scrollToSelected from '~/components/organisms/country-code-selector/mixins/scroll-to-selected'

export default {
  name: 'Mobile',
  components: {
    SearchList,
    ButtonBox,
    Overlay,
    MainTitle,
  },
  mixins: [scrollToSelected(true)],
  props: {
    selectedCountry: {
      type: Object,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: 'ltr',
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: null,
      dropdown: false,
      focused: false,
    }
  },
  computed: {
    overlayTitle(): string {
      return this.$t('account.edit_profile.mobile_number.country_code.title')
    },
    searchPlaceholder(): string {
      return this.$t(
        'account.edit_profile.mobile_number.country_code.search_country'
      )
    },
    prefixClasses(): string {
      let classes = 'h-full top-0 transform cursor-pointer flex items-center '
      if (this.direction === 'ltr') {
        classes += 'ml-4 rotate-180'
      } else {
        classes += 'mr-4'
      }
      return classes
    },
  },
  methods: {
    closeList(): void {
      this.$emit('close-dropdown')
    },
    changeItem(item): void {
      this[`${this.selectorShown}Selected`] = item
      this.$emit('change-item', item)
      this.$emit('close-dropdown')
    },
    unfocusInput(inputRef): void {
      inputRef.blur()
    },
  },
}
