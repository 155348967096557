import {
  REFER_A_FRIEND_INVITATION_PAGE,
  REFER_A_FRIEND_INVITATION_PAGE_WITHOUT_LOCALE,
} from '../client/utils/constants/refer-a-friend.ts'

const ROUTE_NAME = {
  OAUTH_SIGN_IN_REDIRECT: 'o-auth-sign-in-callback',
  OAUTH_SIGN_OUT_REDIRECT: 'o-auth-sign-out-callback',
}

export { ROUTE_NAME }

export default {
  middleware: [
    'route-to-two-point-o',
    'change-email-feature-guard',
    'check-for-federated-account',
    'sign-in-with-apple-feature-guard',
    'redirection/index',
    'refer-a-friend-country-availability-guard',
    'set-marketplace',
    'set-context-configuration',
    'check-locale/index',
    'auth/check-auth',
    'auth/redirect-logged-in-user',
    'disable-account-analytics',
    'set-headers',
    'set-shared-data',
    'contacts-feature-guard',
    'checkout-error-redirection',
  ],
  extendRoutes(routes) {
    if (process.generatingErrorPages) return
    routes.splice(0, routes.length)
    routes.push({
      name: REFER_A_FRIEND_INVITATION_PAGE_WITHOUT_LOCALE,
      path: '/i/:referralCode',
      component: '~/pages/raf/raf-invitation.vue',
      meta: { redirectIfLoggedIn: true },
    })
    routes.push({
      name: 'home',
      path: '/:language/:country(..)',
      component: '~/pages/home.vue',
    })
    routes.push({
      name: 'app',
      path: '/:language/:country(..)/app',
      component: '~/pages/app.vue',
    })
    routes.push({
      name: 'refer-a-friend',
      path: '/:language/:country(..)/refer-a-friend',
      component: '~/pages/raf/raf-home.vue',
    })
    routes.push({
      name: REFER_A_FRIEND_INVITATION_PAGE,
      path: '/:language/:country(..)/i/:referralCode',
      component: '~/pages/raf/raf-invitation.vue',
      meta: { redirectIfLoggedIn: true },
    })
    routes.push({
      name: 'reset-password',
      path: '/:language/:country(..)/reset-password',
      component: '~/pages/auth/reset-password.vue',
      meta: { redirectIfLoggedIn: true },
    })
    routes.push({
      name: 'change-email-confirmation',
      path: '/:language/:country(..)/change-email-confirmation',
      component: '~/pages/auth/change-email-confirmation.vue',
    })
    routes.push({
      name: 'verify-account',
      path: '/:language/:country(..)/verify-account',
      component: '~/pages/auth/verify-account.vue',
      meta: { redirectIfLoggedIn: true },
    })
    routes.push({
      name: 'my-account',
      path: '/:language/:country(..)/my-account',
      component: '~/pages/account/my-account.vue',
      meta: { requiresAuth: true },
    })
    routes.push({
      name: 'profile',
      path: '/:language/:country(..)/my-account/profile',
      component: '~/pages/account/profile.vue',
      meta: { requiresAuth: true },
    })
    routes.push({
      name: 'order-details',
      path: '/:language/:country(..)/my-account/orders/:id/details',
      component: '~/pages/account/order-details.vue',
      meta: { requiresAuth: true },
    })
    routes.push({
      name: 'orders',
      path: '/:language/:country(..)/my-account/orders',
      component: '~/pages/account/orders.vue',
      meta: { requiresAuth: true },
    })
    routes.push({
      name: 'contacts',
      path: '/:language/:country(..)/my-account/contacts',
      component: '~/pages/account/contacts.vue',
      meta: {
        requiresAuth: true,
        requiresContactsFeatureFlag: true,
      },
    })
    routes.push({
      name: 'add-contact',
      path: '/:language/:country(..)/my-account/contacts/add',
      component: '~/pages/account/add-contact.vue',
      meta: {
        requiresAuth: true,
        requiresContactsFeatureFlag: true,
      },
    })
    routes.push({
      name: 'my-phone-number-details',
      path:
        '/:language/:country(..)/my-account/contacts/my-phone-number/details',
      component: '~/pages/account/my-phone-number-details.vue',
      meta: {
        requiresAuth: true,
        requiresContactsFeatureFlag: true,
      },
    })
    routes.push({
      name: 'contact-details',
      path: '/:language/:country(..)/my-account/contacts/:id/details',
      component: '~/pages/account/contact-details.vue',
      meta: {
        requiresAuth: true,
        requiresContactsFeatureFlag: true,
      },
    })
    routes.push({
      name: 'edit-my-phone-number',
      path: '/:language/:country(..)/my-account/contacts/my-phone-number/edit',
      component: '~/pages/account/edit-my-phone-number.vue',
      meta: {
        requiresAuth: true,
        requiresContactsFeatureFlag: true,
      },
    })
    routes.push({
      name: 'edit-contact',
      path: '/:language/:country(..)/my-account/contacts/:id/edit',
      component: '~/pages/account/edit-contact.vue',
      meta: {
        requiresAuth: true,
        requiresContactsFeatureFlag: true,
      },
    })
    routes.push({
      name: 'security',
      path: '/:language/:country(..)/my-account/security',
      component: '~/pages/account/security.vue',
      meta: {
        requiresAuth: true,
        noFederatedAccount: true,
      },
    })
    routes.push({
      name: 'update-password',
      path: '/:language/:country(..)/my-account/security/update-password',
      component: '~/pages/account/update-password.vue',
      meta: {
        requiresAuth: true,
        noFederatedAccount: true,
      },
    })
    routes.push({
      name: 'register-confirmation',
      path: '/:language/:country(..)/register-confirmation',
      component: '~/pages/auth/register-confirmation.vue',
      meta: { redirectIfLoggedIn: true },
    })
    routes.push({
      name: 'blog-overview',
      path: '/:language/:country(..)/blog',
      component: '~/pages/blog/gmp.vue',
    })
    routes.push({
      name: 'blog-post',
      path: '/:language/:country(..)/blog/:pageName',
      component: '~/pages/blog/gmp-post.vue',
    })
    routes.push({
      name: 'variant',
      path: '/:language/:country(..)/:brand/:product',
      component: '~/pages/variant.vue',
    })
    routes.push({
      name: 'payment-methods',
      path: '/:language/:country(..)/payment-methods',
      component: '~/pages/payment-methods.vue',
    })
    routes.push({
      name: 'carriers',
      path: '/:language/:country(..)/carriers',
      component: '~/pages/carriers.vue',
    })
    routes.push({
      name: 'order-error',
      path:
        '/:language/:country(..)/order/error/:orderId/:hash/:paymentMethod/:errorType',
      component: '~/pages/order/error/_orderId/_hash/index.vue',
    })
    routes.push({
      name: 'business',
      path: '/:language/:country(..)/business',
      component: '~/pages/business.vue',
    })
    /*
      Checkout routes
    */
    routes.push({
      name: 'payment-blocked',
      path: '/:language/:country(..)/checkout-blocked',
      component: '~/pages/checkout/payment-blocked/index.vue',
    })
    routes.push({
      name: 'checkout',
      path: '/:language/:country(..)/checkout',
      component: '~/pages/checkout/old-checkout/index.vue',
    })
    routes.push({
      name: 'checkout-error',
      path:
        '/:language/:country(..)/checkout/error/:orderId/:hash/:paymentMethod?/:errorType?',
      component: '~/pages/checkout/old-checkout/index.vue',
    })

    routes.push({
      name: 'checkout-cancel',
      path: '/:language/:country(..)/checkout/cancel/:orderId/:hash',
      component: '~/pages/checkout/old-checkout/index.vue',
    })
    /**/

    routes.push({
      name: 'category-product-legal',
      path: '/:language/:country(..)/:pageName',
      component: '~/pages/category-brand-legal.vue',
    })
    routes.push({
      name: 'order-editnumber-id-hash',
      path: '/:language/:country(..)/order/editnumber/:id?/:hash',
      component: '~/pages/order/editnumber/_id/_hash/index.vue',
    })
    routes.push({
      name: 'order-error-orderId-hash',
      path: '/:language/:country(..)/order/error/:orderId?/:hash',
      component: '~/pages/order/error/_orderId/_hash/index.vue',
    })
    routes.push({
      name: 'order-finished-id-hash',
      path: '/:language/:country(..)/order/finished/:id?/:hash',
      component: '~/pages/order/finished/_id/_hash/index.vue',
    })
    routes.push({
      name: 'order-paymentprogress-id-hash',
      path:
        '/:language/:country(..)/order/paymentprogress/:id?/:hash/:checkouttype(new-checkout)?',
      component: '~/pages/order/paymentprogress/_id/_hash/index.vue',
    })
    routes.push({
      name: 'order-refund-id-hash',
      path: '/:language/:country(..)/order/refund/:id?/:hash',
      component: '~/pages/order/refund/_id/_hash/index.vue',
    })
    routes.push({
      name: 'order-finished-id-hash-distribution',
      path: '/:language/:country(..)/order/finished/:id?/:hash?/distribution',
      component: '~/pages/order/finished/_id/_hash/distribution.vue',
    })
    routes.push({
      name: 'order-finished-id-hash-investigated',
      path: '/:language/:country(..)/order/finished/:id?/:hash?/investigated',
      component: '~/pages/order/finished/_id/_hash/investigated.vue',
    })
    routes.push({
      name: 'order-finished-id-hash-investigation',
      path: '/:language/:country(..)/order/finished/:id?/:hash?/investigation',
      component: '~/pages/order/finished/_id/_hash/investigation.vue',
    })
    routes.push({
      name: 'order-finished-id-hash-pending',
      path: '/:language/:country(..)/order/finished/:id?/:hash?/pending',
      component: '~/pages/order/finished/_id/_hash/pending.vue',
    })
    routes.push({
      name: 'order-finished-id-hash-shipped',
      path: '/:language/:country(..)/order/finished/:id?/:hash?/shipped',
      component: '~/pages/order/finished/_id/_hash/shipped.vue',
    })
    routes.push({
      name: ROUTE_NAME.OAUTH_SIGN_IN_REDIRECT,
      path: '/oauth/sign-in',
      component: '~/pages/auth/oauth-sign-in.vue',
      meta: { withoutLocale: true },
    })
    routes.push({
      name: ROUTE_NAME.OAUTH_SIGN_OUT_REDIRECT,
      path: '/oauth/sign-out',
      component: '~/pages/auth/oauth-sign-out.vue',
      meta: { withoutLocale: true },
    })
    routes.push({
      name: 'blog-overview-lmp',
      path: '/:locale(..-..)/blog',
      component: '~/pages/blog/index.vue',
    })
    routes.push({
      name: 'blog-post-lmp',
      path: '/:locale(..-..)/blog/:pageName',
      component: '~/pages/blog/post.vue',
    })
    routes.push({
      name: 'error-404',
      path: '*',
      component: '~/error-pages/404.vue',
    })
  },
}
