import { GetterTree } from 'vuex'
import { IRecommerceState } from './state'

const getters: GetterTree<IRecommerceState, IRecommerceState> = {
  variant: ({ variant }) => variant,
  product: ({ product }) => product,
  variantSiblings: ({ variant }) =>
    variant ? variant.product.productListComponent.productList : [],
}
export default getters
