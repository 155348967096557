
import { UiButton, CopyToClipboard } from '~/components/atoms'
import { isDesktop } from '~/services/screen'

export default {
  components: {
    UiButton,
    CopyToClipboard,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    orderNumber: {
      type: String,
      default: '',
    },
    serialNumber: {
      type: String,
      default: '',
    },
    sicherheitsCode: {
      type: String,
      default: '',
    },
    redeemUrl: {
      type: String,
      default: '',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDesktop: false,
    }
  },
  mounted() {
    this.isDesktop = isDesktop()
  },
}
