import { RtrUserAttributes } from './types'

export interface IRtrUserState {
  rtrUser: RtrUserAttributes
}

export default (): IRtrUserState => ({
  rtrUser: {
    phoneNumber: '',
  },
})
