import { GTMEvents, GTMDefaults } from '../enums'

export interface ICheckoutActionField {
  checkout: {
    actionField: {
      step: number
      option?: string
    }
  }
}
export interface ICheckoutProgress {
  event: GTMEvents
  // eslint-disable-next-line camelcase
  checkout_stage_total_steps: string
  ecommerce: ICheckoutActionField
}
export class CheckoutProgressModel {
  private ecommerce: ICheckoutActionField
  private event: GTMEvents

  constructor(step, option) {
    const events = {
      '2': GTMEvents.CHECKOUT_EMAIL,
      '3': GTMEvents.CHECKOUT_SELECTPAYMENT,
      '4': GTMEvents.CHECKOUT_STARTPAYMENT,
      '5': GTMEvents.CHECKOUT_STARTPAYMENT_TWO,
      '6': GTMEvents.CHECKOUT_PSC_DETAILS_LOADED,
      '7': GTMEvents.CHECKOUT_MOBILEVERIFICATION_LOADED,
      '8': GTMEvents.CHECKOUT_MOBILEVERIFICATION_VERIFY,
    }
    this.event = events[step]
    this.ecommerce = {
      checkout: {
        actionField: {
          step,
          ...(option && { option }),
        },
      },
    }
  }

  toJSON(): ICheckoutProgress {
    return {
      event: this.event,
      checkout_stage_total_steps: GTMDefaults.CHECKOUT_STEPS,
      ecommerce: this.ecommerce,
    }
  }
}
