import { ProductData } from '~/normalizers/recommerce/product'
import { VariantData } from '~/normalizers/recommerce/variant'

export interface IRecommerceState {
  product: ProductData | Record<string, never>
  variant: VariantData | Record<string, never>
}

export default function state(): IRecommerceState {
  return {
    product: {},
    variant: {},
  }
}
