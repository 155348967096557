import { hydrateSsrOnly, hydrateWhenVisible } from '~/services/lazy-hydration'

export const MainTitle = hydrateSsrOnly(() => import('./main-title'))
export const BannerImage = () => import('./banner-image')
export const FeaturedBlog = () => import('./featured-blog')
export const BlogList = () => import('./blog-list')
export const AppBanner = hydrateWhenVisible(() => import('./app-banner'))
export const AppBannerLanding = hydrateWhenVisible(() =>
  import('./app-banner/index-landing')
)
export const BannerCard = hydrateSsrOnly(() => import('./banner-card'))
export const Logo = hydrateSsrOnly(() => import('./logo'))
export { default as CategoryListItem } from './category-list-item'
export { default as CheckoutDeliveryDetailsBox } from './checkout-delivery-details-box'
export { default as CheckoutOutOfStock } from './checkout-out-of-stock'
export { default as CheckoutPaymentDisclaimer } from './checkout-payment-disclaimer'
export { default as CheckoutUserEmailBox } from './checkout-user-email-box'
export { default as ConfirmationCard } from './confirmation-card'
export { default as EditableCard } from './editable-card'
export { default as ErrorModalBox } from './error-modal-box'
export { default as OverlayWithNesting } from './gmp-overlay-with-nesting'
export { default as IconTooltip } from './icon-tooltip'
export { default as InfoBadge } from './info-badge'
export { default as Overlay } from './overlay'
export { default as PaymentMethods } from './payment-methods'
export { default as PaymentMethodsChecklist } from './payment-methods-checklist'
export { default as PopularBrandCard } from './popular-brand-card'
export { default as ProductOrderStatusInfo } from './product-order-status-info'
export { default as RightOfWithdrawal } from './right-of-withdrawal'
export { default as TermsAndConditions } from './terms-and-conditions'
export { default as UspsList } from './usps-list'
export { default as NativeShare } from './native-share'
export const FooterLinks = hydrateWhenVisible(() => import('./footer-links'))
export const SearchList = () => import('./search-list')
export const HydratedSearchList = hydrateWhenVisible(() =>
  import('./search-list')
)
export const SearchInput = hydrateWhenVisible(() => import('./search-input'))
export const Copyright = hydrateSsrOnly(() => import('./copyright'))
export const LegalLinks = hydrateWhenVisible(() => import('./legal-links'))
export const Locale = hydrateSsrOnly(() => import('./locale'))
export const Notification = hydrateSsrOnly(() => import('./notification'))
export const BlogLead = hydrateWhenVisible(() => import('./blog-lead'))
export const Breadcrumbs = hydrateWhenVisible(() => import('./breadcrumbs'))
export const ProductCardCta = hydrateWhenVisible(() =>
  import('./product-card-cta')
)
export const UiSelector = hydrateWhenVisible(() => import('./selector'))

export const ProductCard = hydrateWhenVisible(() => import('./product-card'))
export const MenuAccordion = hydrateWhenVisible(() =>
  import('./menu-accordion')
)
export const StandardAccordion = hydrateWhenVisible(() =>
  import('./standard-accordion')
)
export const BrandCard = hydrateWhenVisible(() => import('./brand-card'))
export const BrandQuickLinks = hydrateWhenVisible(() =>
  import('./brand-quick-links')
)
export const FeaturedCard = hydrateWhenVisible(() => import('./featured-card'))
export const CountryList = hydrateWhenVisible(() => import('./country-list'))
export const BrandBox = hydrateWhenVisible(() => import('./brand-box'))
export const FeaturedBanner = hydrateWhenVisible(() =>
  import('./featured-banner')
)
export const FixedNotification = hydrateWhenVisible(() =>
  import('./fixed-notification')
)
export const EmailInputBox = () => import('./email-input-box')

export const TabList = hydrateWhenVisible(() => import('./tab-list'))
export const ErrorAlert = () => import('./error-alert')
export const AccountOptionList = hydrateWhenVisible(() =>
  import('./account-option-list')
)
export const AccountOrderCard = hydrateWhenVisible(() =>
  import('./account-order-card')
)
export const InfoBox = hydrateWhenVisible(() => import('./info-box'))

export const ConfirmationHeader = () => import('./confirmation-header')
export const EditnumberHeader = () => import('./editnumber-header')
export const EditnumberContent = () => import('./editnumber-content')

export const CategoryCard = hydrateWhenVisible(() => import('./category-card'))

export const QuickBuyCard = hydrateWhenVisible(() => import('./quick-buy-card'))
export const InfoCard = hydrateWhenVisible(() => import('./info-card'))
export const PasswordInput = hydrateWhenVisible(() =>
  import('./password-input')
)
export const PasswordRules = hydrateWhenVisible(() =>
  import('./password-rules')
)

export const PhoneInput = () => import('./phone-input')
export const HlrInput = () => import('./hlr-input')
export const PhoneInputCountrySelector = hydrateWhenVisible(() =>
  import('./phone-input-country-selector')
)
export const PhoneNumberCdInput = hydrateWhenVisible(() =>
  import('./phone-number-cd-input')
)
export const PhoneNumberBox = () => import('./phone-number-box')
export const OrderProductInfo = () => import('./order-product-info')
export const SwitchCountry = () => import('./switch-country')
export const SwitchCountryButton = () => import('./switch-country-button')
export const CurrencyDropdown = () => import('./currency-dropdown')
export const SuggestCountry = () => import('./suggest-country')
export const AlertBlock = () => import('./alert-block')
export const ProductInfo = hydrateWhenVisible(() => import('./product-info'))

export const RechargeCarouselTile = hydrateWhenVisible(() =>
  import('./recharge-carousel-tile/index')
)

export const AccountCard = hydrateWhenVisible(() =>
  import('./account-category-card/index')
)

export const OrderHistoryCard = hydrateWhenVisible(() =>
  import('./order-history-card/index')
)
export const OrderHistoryCardWrapper = hydrateWhenVisible(() =>
  import('./order-history-card/order-history-card-wrapper')
)

export const ColorBanner = hydrateWhenVisible(() =>
  import('./color-banner/index')
)

export const SignOutButton = hydrateWhenVisible(() =>
  import('./sign-out-button/index')
)

export const ProfileCardPreview = hydrateWhenVisible(() =>
  import('./profile-card-preview/index')
)

export const FederatedAuth = hydrateWhenVisible(() =>
  import('./federated-auth/index')
)

export const SecurityCard = hydrateWhenVisible(() =>
  import('./account-security-card')
)
export const ScamModalBox = hydrateWhenVisible(() => import('./scam-modal-box'))

export const ContactCard = hydrateWhenVisible(() => import('./contact-card'))

export const OrderDetailsTable = hydrateWhenVisible(() =>
  import('./order-details-table')
)

export const OrderDetailsPaymentOverview = hydrateWhenVisible(() =>
  import('./order-details-payment-overview')
)

export const OrderDetailsStatusIcon = hydrateWhenVisible(() =>
  import('./order-details-status-icon')
)

export const OrderDetailsStatusBlock = hydrateWhenVisible(() =>
  import('./order-details-status-block')
)

export const OrderDetailsProductBlock = hydrateWhenVisible(() =>
  import('./order-details-product-block')
)

export const ExpandableCard = hydrateWhenVisible(() =>
  import('./expandable-card')
)

export const OrderDetailsExpandableCardContent = hydrateWhenVisible(() =>
  import('./order-details-expandable-card-content')
)

export const OrderDetailsSupportSection = hydrateWhenVisible(() =>
  import('./order-details-support-section')
)

export const OrderDetailsRefundSection = hydrateWhenVisible(() =>
  import('./order-details-refund-section')
)

export const MessageWithLink = hydrateWhenVisible(() =>
  import('./message-with-link')
)

export const CustomerSupportLink = hydrateWhenVisible(() =>
  import('./customer-support-link')
)

export const RafBannerContent = hydrateWhenVisible(() =>
  import('./raf-banner-content')
)

export const RafTermsLink = hydrateWhenVisible(() => import('./raf-terms-link'))

export const FieldWithCopyButton = hydrateWhenVisible(() =>
  import('./field-with-copy-button')
)

export const RafFaqCollapsableRow = hydrateWhenVisible(() =>
  import('./raf-faq-collapsable-row')
)

export const UspsCard = hydrateWhenVisible(() => import('./usps-card'))

export const TitleDescription = hydrateWhenVisible(() =>
  import('./title-description')
)

export const Datepicker = hydrateWhenVisible(() => import('./datepicker'))

export const AuthenticationModalToggleLink = hydrateWhenVisible(() =>
  import('./authentication-modal-toggle-link')
)

export const AuthenticationModalAuthOptions = hydrateWhenVisible(() =>
  import('./authentication-modal-auth-options')
)

export const AuthenticationModalDisclaimer = hydrateWhenVisible(() =>
  import('./authentication-modal-disclaimer')
)

export const AuthenticationModalRegistrationSuccessful = hydrateWhenVisible(
  () => import('./authentication-modal-registration-successful')
)

export const LinkWithIcon = hydrateWhenVisible(() => import('./link-with-icon'))

export const OrderDetailsTableRow = hydrateWhenVisible(() =>
  import('./order-details-table-row')
)

export const CustomDenomination = hydrateWhenVisible(() =>
  import('./custom-denomination')
)

export const AccountHeaderMenuButton = hydrateWhenVisible(() =>
  import('./account-header-menu-button')
)

export const MyAccountMenuContent = hydrateWhenVisible(() =>
  import('./my-account-menu-content')
)

export const RafAccountBannerContent = hydrateWhenVisible(() =>
  import('./raf-account-banner-content')
)

export const ReorderCard = hydrateWhenVisible(() => import('./reorder-card'))

export const RechargeFasterBannerImage = hydrateWhenVisible(() =>
  import('./recharge-faster-banner-image')
)

export const WebToAppMobileBannerImage = hydrateWhenVisible(() =>
  import('./web-to-app-mobile-banner-image')
)
