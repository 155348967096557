import { NuxtI18nInstance } from 'nuxt-i18n/types'
import { Notification } from '~/apis/clients/rest/account/api'
import { NotificationParameters } from '~/store/ui/notifications/types'
import { NotificationsPlugin } from '~/plugins/notifications'

const REWARD_EXPIRATION_NOTIFICATION_KEY =
  'refer_a_friend.notification.referred_reward_reminder'

const handleRewardExpirationNotification = (
  i18n,
  notificationParameters: NotificationParameters
): string => {
  let translationText = ''
  if (
    notificationParameters.days_left &&
    notificationParameters.days_left > 0
  ) {
    translationText = i18n.tc(
      `${REWARD_EXPIRATION_NOTIFICATION_KEY}.days`,
      notificationParameters.days_left,
      {
        expiration: notificationParameters.days_left,
      }
    )
  } else if (
    notificationParameters.hours_left &&
    notificationParameters.hours_left > 0
  ) {
    translationText = i18n.tc(
      `${REWARD_EXPIRATION_NOTIFICATION_KEY}.hours`,
      notificationParameters.hours_left,
      {
        expiration: notificationParameters.hours_left,
      }
    )
  } else if (
    notificationParameters.minutes_left &&
    notificationParameters.minutes_left > 0
  ) {
    translationText = i18n.tc(
      `${REWARD_EXPIRATION_NOTIFICATION_KEY}.minutes`,
      notificationParameters.minutes_left,
      {
        expiration: notificationParameters.minutes_left,
      }
    )
  }
  return translationText
}

export const notificationHandler = (
  i18n: NuxtI18nInstance,
  $notifications: NotificationsPlugin,
  notificationObject: Notification
): void => {
  let translationText = ''
  if (
    notificationObject.translation_key === REWARD_EXPIRATION_NOTIFICATION_KEY
  ) {
    translationText = handleRewardExpirationNotification(
      i18n,
      notificationObject.parameters
    )
  } else {
    translationText = i18n.tc(notificationObject.translation_key)
  }
  $notifications.pushInfo({
    id: notificationObject.id,
    text: translationText,
    closeAfter: notificationObject.visibility_time
      ? notificationObject.visibility_time
      : 0,
    isServerNotification: true,
  })
}
