var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['flex flex-wrap']},[(_vm.paymentMethods.length)?_c('ul',{class:[
      `flex items-center justify-${_vm.align} lg:justify-start flex-wrap flex-grow w-full lg:w-auto`,
      _vm.singleRow ? 'my-4 h-6 overflow-hidden' : 'py-4',
    ]},_vm._l((_vm.paymentMethods),function({
        name,
        icon,
        link: { rel, title, newTab, url: paymentUrl } = {},
      }){return _c('li',{key:name,staticClass:"pe-3 pb-1 last:pe-0"},[(_vm.hasLinks)?_c('ui-link',{attrs:{"url":_vm.url || paymentUrl,"title":title,"rel":rel,"blank":newTab}},[_c('ui-image',{attrs:{"src":icon,"image-dimensions":_vm.imageDimensions,"height":_vm.imageHeight,"width":"auto"}})],1):_c('ui-image',{attrs:{"src":icon,"alt":title,"image-dimensions":_vm.imageDimensions,"height":_vm.imageHeight,"width":"auto"}})],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }