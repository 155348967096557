import { mutationTree, Commit } from '../types'
import { IContenfulState } from './interfaces'
import { TYPES } from './mutation-types'
import { ILayout } from '~/models/layout/Layout'
import { IFooter } from '~/models/components/Footer'
import { IHeader } from '~/models/components/Header'
import { IPaymentMethod } from '~/models/components/PaymentMethod'
import { IProduct } from '~/models/components/Product'
import { TMetaTag } from '~/models/components/MetaTags'
import { CategoryTypes } from '~/enums/categories'
import { TCountry } from '~/normalizers/layout/country'
import { Maybe } from '~/apis/clients/graphql/types/contentful'

const {
  SET_PRODUCT,
  SET_PRODUCTS,
  SET_COUNTRY,
  SET_LAYOUT,
  SET_HEADER,
  SET_FOOTER,
  SET_META_TITLE,
  SET_STRUCTURED_DATA,
  SET_COUNTRY_LIST,
  SET_BRAND_NAV_LINKS,
  SET_BRAND_INFO,
  SET_PAYMENT_METHODS,
  SET_META_LINKS,
  SET_META_TAGS_DATA,
  SET_PAGE_TITLE,
  SET_PAGE_TYPE,
  SET_PARENT_PAGE_INFO,
  SET_ABOUT_US_URL,
  SET_PATH_TRANSLATIONS,
  SET_PDP,
  SET_BRAND_PAGE,
  SET_HOME,
  SET_PAYMENT_METHODS_PAGE,
  SET_CATEGORY_PAGE,
  SET_SECONDARY_PAGE,
  SET_BLOG_PAGE,
  SET_BLOG_OVERVIEW_PAGE,
  SET_SHARED_DATA,
} = TYPES

const mutations = mutationTree<IContenfulState>()({
  [SET_PRODUCTS](state, products): void {
    state.products = products
  },
  [SET_PAYMENT_METHODS_PAGE](state, paymentMethodsPage) {
    state.paymentMethodsPage = paymentMethodsPage
  },
  [SET_PRODUCT](state, product: IProduct): void {
    state.product = product
  },
  [SET_COUNTRY](state, country: TCountry): void {
    state.country = country
  },
  [SET_LAYOUT](state, layout: ILayout): void {
    state.layout = layout
  },
  [SET_HEADER](state, header: IHeader): void {
    state.header = header
  },
  [SET_META_TITLE](state, metaTitle: string): void {
    state.metaTitle = metaTitle
  },
  [SET_STRUCTURED_DATA](state, structuredData: unknown): void {
    state.structuredData = structuredData
  },
  [SET_FOOTER](state, footer: IFooter): void {
    state.footer = footer
  },
  [SET_COUNTRY_LIST](state, countries): void {
    state.countryList = countries
  },
  [SET_BRAND_NAV_LINKS](state, brandNavLinks): void {
    state.brandNavLinks = brandNavLinks
  },
  [SET_ABOUT_US_URL](state, aboutUsUrl): void {
    state.aboutUsUrl = aboutUsUrl
  },
  [SET_PDP](state, pdp): void {
    state.pdp = pdp
  },
  [SET_BRAND_PAGE](state, brandPage): void {
    state.brandPage = brandPage
  },
  [SET_HOME](state, home): void {
    state.home = home
  },
  [SET_SECONDARY_PAGE](state, secondaryPage): void {
    state.secondaryPage = secondaryPage
  },
  [SET_BLOG_PAGE](state, blogPage): void {
    state.blogPage = blogPage
  },
  [SET_BLOG_OVERVIEW_PAGE](state, blogOverviewPage): void {
    state.blogOverviewPage = blogOverviewPage
  },
  [SET_CATEGORY_PAGE](state, categoryPage): void {
    state.categoryPage = categoryPage
  },
  [SET_PAYMENT_METHODS](state, paymentMethods = []): void {
    if (!paymentMethods) return
    const paymentMethodsMap = paymentMethods.reduce(
      (
        methods: Record<string, IPaymentMethod>,
        currentMethod: IPaymentMethod
      ) => ({
        ...methods,
        [currentMethod.topupId.toLowerCase()]: currentMethod,
      }),
      {}
    )
    state.paymentMethods = paymentMethodsMap
  },
  [SET_META_LINKS](state, metaLinks): void {
    state.metaLinks = metaLinks
  },
  [SET_META_TAGS_DATA](state, metaTags: TMetaTag[]): void {
    state.metaTags = metaTags
  },
  [SET_PAGE_TITLE](state, title: string): void {
    state.pageTitle = title
  },
  [SET_PAGE_TYPE](state, pageType: string): void {
    state.pageType = pageType
  },
  [SET_PARENT_PAGE_INFO](
    state,
    parentPageInfo: {
      title: Maybe<string>
      pageType: Maybe<string>
      brandCategory?: Maybe<CategoryTypes>
    }
  ): void {
    state.parentPageInfo = parentPageInfo
  },
  [SET_PATH_TRANSLATIONS](
    state,
    pathTranslations: Record<string, string>
  ): void {
    state.pathTranslations = pathTranslations
  },
  [SET_SHARED_DATA](state, { data, marketplacePrefix }) {
    state.cachedSharedData = {
      ...state.cachedSharedData,
      [marketplacePrefix]: data,
    }

    state.sharedData = { ...data }
  },
})

export type ContentfulCommit = Commit<typeof mutations>

export default mutations
