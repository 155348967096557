import { Plugin, Context } from '@nuxt/types'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    router: NuxtAppOptions
  }
}
const checkPreviewModePlugin: Plugin = ({ app }: Context): void => {
  const { router } = app

  router.beforeEach((to, from, next) => {
    if (from.query.preview && !to.query.preview) {
      if (to.path === from.path) {
        return
      }

      const queryString = Object.keys(from.query)
        .map(key => `${key}=${from.query[key]}`)
        .join('&')

      window.location.href = `${to.fullPath}?${queryString}`
      return
    }

    next()
  })
}

export default checkPreviewModePlugin
