import { GetterTree } from 'vuex'
import { GLOBAL_MERCHANT } from '../../utils/constants/api'
import {
  IContextStore,
  Country,
  Currency,
  Language,
  CustomerCountry,
} from './interfaces'
import { PREFIX_STRATEGY } from './enums'
import GLOBAL_COUNTRY from '~/utils/constants/global-country.json'
import isEmpty from '~/utils/isEmpty'
import { ICurrencyModel } from '~/models/base/Currency'

function getLangFromLocale(locale: string): string {
  return locale.length > 4 ? locale.substr(0, 2).toUpperCase() : ''
}

const getters: GetterTree<IContextStore, IContextStore> = {
  label: ({ labelName }): string => labelName,
  domain: ({ domain }): string => domain,
  marketplacePrefix: (
    { locale, localeProperties },
    { isGlobalMarketplace }
  ): string => (isGlobalMarketplace ? localeProperties.code : locale),
  languages: ({ languages }): Language[] => languages,
  language: ({ languages }, { recommerceLocale }): Language | undefined =>
    languages.find(language => language.code === recommerceLocale),
  locale: ({ locale, localeProperties }, { isGlobalMarketplace }): string =>
    isGlobalMarketplace ? localeProperties.code || locale : locale,
  locales: ({ locales }): string[] => locales,
  lang: (_, { locale }): string => getLangFromLocale(locale),
  country: ({ localeProperties, countries }) =>
    countries.find(
      country => country?.code.toLowerCase() === localeProperties?.country?.code
    ),
  isGlobalCountry: (_, { country }) => country?.code === GLOBAL_COUNTRY.code,
  countryCode: (
    { countryCode, localeProperties },
    { isGlobalMarketplace }
  ): string =>
    isGlobalMarketplace ? localeProperties.country.code : countryCode,
  cmsLocale: (
    { cmsLocale, localeProperties },
    { isGlobalMarketplace }
  ): string => (isGlobalMarketplace ? localeProperties.cmsLocale : cmsLocale),
  merchant: ({ merchant }, { isGlobalMarketplace }): string =>
    isGlobalMarketplace ? GLOBAL_MERCHANT : merchant,
  currency: (
    { currency },
    { isLocalMarketplace, currencies }
  ): ICurrencyModel | string | Record<string, never> => {
    if (isLocalMarketplace) return currency
    const currencyString =
      typeof currency === 'string' ? currency : currency.abv
    const gmpCurrency = currencies.find(
      currencyConfigObj => currencyConfigObj.currency === currencyString
    )

    // We use abv here as well because of legacy LMP reasons
    return { ...gmpCurrency, abv: gmpCurrency.currency }
  },
  direction: (
    { direction, localeProperties },
    { isGlobalMarketplace }
  ): string => (isGlobalMarketplace ? localeProperties.direction : direction),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trustpilot: ({ trustpilot }): any => trustpilot,
  prefixStrategy: ({ prefixStrategy }): string => prefixStrategy,
  pathIncludeLocale: ({ prefixStrategy }): boolean =>
    prefixStrategy !== PREFIX_STRATEGY.NO_PREFIX,
  rechargeClassicDomain: ({ rechargeClassicDomain }): string =>
    rechargeClassicDomain,
  trustpilotLocale: ({ trustpilot }, { lang }): string => {
    const { locales = {}, defaultLocale } = trustpilot

    if (isEmpty(locales)) return defaultLocale
    return locales[lang] || defaultLocale
  },
  comesFromRechargeClassic: ({ comesFromRechargeClassic }): boolean =>
    comesFromRechargeClassic,
  isRedirectedFromRapido: ({ isRedirectedFromRapido }): boolean =>
    isRedirectedFromRapido,
  lastProductSeenByCountryMap: ({ lastProductSeenByCountry }) =>
    lastProductSeenByCountry,
  lastProductSeenId: (
    { lastProductSeenByCountry },
    getters,
    _rootState,
    rootGetters
  ): number | undefined => {
    const countryAbv = getters.isGlobalMarketplace
      ? getters.countryCode
      : rootGetters['contentful/countryAbv']
    return lastProductSeenByCountry[countryAbv]
  },
  hasRapidoSegment: ({ hasRapidoSegment }): boolean => hasRapidoSegment,
  shouldShowErrorDetails: ({ showErrorDetails }): boolean => showErrorDetails,
  isGlobalMarketplace: ({ marketplace }): boolean => marketplace === 'global',
  isLocalMarketplace: ({ marketplace }): boolean => marketplace === 'local',
  useRecommerce: (_state, getters): boolean => getters.isGlobalMarketplace,
  recommerceLocale: ({ localeProperties }): string =>
    localeProperties.recommerceLocale,
  countries: ({ countries }): Country[] => countries,
  countriesWithoutGlobal: ({ countries }): Country[] =>
    countries.filter(({ code }) => code !== GLOBAL_COUNTRY.code),
  currencies: ({ currencies }): Currency[] => currencies,
  customerCountry: ({ customerCountry }): CustomerCountry | null =>
    customerCountry,
  isContactsFeaturePreview: ({ isContactsFeaturePreview }): boolean =>
    isContactsFeaturePreview,
  isSignInWithAppleFeaturePreview: ({
    isSignInWithAppleFeaturePreview,
  }): boolean => isSignInWithAppleFeaturePreview,
  isChangeEmailFeaturePreview: ({ isChangeEmailFeaturePreview }): boolean =>
    isChangeEmailFeaturePreview,
  accountAnalyticsDisabled: ({ accountAnalyticsDisabled }): boolean =>
    accountAnalyticsDisabled,
  redeemCountryEqualsCustomerCountry: (
    _,
    { country, customerCountry }
  ): boolean => {
    if (!country) return false
    return country.code === customerCountry.code
  },
  isRafAvailableCountry: ({ isRafAvailableCountry }): boolean =>
    isRafAvailableCountry,
}

export default getters
