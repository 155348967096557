import { IOrderState } from './interfaces'
import { ORDER_STATUS } from '~/models/components/Order'

export default function state(): IOrderState {
  return {
    orderStatus: ORDER_STATUS.UNPAID,
    details: {},
    product: {
      productId: '-1',
      quantity: 1,
    },
    productOrderPageStructure: {},
  }
}
