export function getUrl(isDev: boolean): { host: string; url: string } {
  const protocol = isDev ? 'http' : 'https'
  const host = process.env.DOMAIN || window.location.host
  const url = `${protocol}://${host}`

  return {
    host,
    url,
  }
}
export function isExternalLink(page: string): boolean {
  if (typeof page !== 'string') {
    return false
  }
  const expression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm
  return Boolean(page && page.match(expression))
}

export function isModalLink(page: string): boolean {
  if (typeof page !== 'string') {
    return false
  }
  const expression = /^(modal?):\/\//gm
  return Boolean(page && page.match(expression))
}

export function getUrlWithLocale(origin: string, locale: string): string {
  return `${origin}/${locale}`
}

interface ISplittedUrl {
  lang?: string
  country?: string
  slug?: string
}
export function splitUrl(url: string): ISplittedUrl {
  if (!url) return {}

  // used only for redirection middleware to use in REDIRECT_DICTIONARY
  // Adjusted to redirect some LMP pages to GMP
  // groups urls chunk into language, country and slug (without query strings)
  const urlChunkGroupingRegex = /\/(\w{2})(?:-(\w{2}))?(\/.+?)?(?:\?|$)/
  const [, lang, country, slug] = urlChunkGroupingRegex.exec(url) || []

  return { lang, country, slug }
}
