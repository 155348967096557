import { TProduct } from './state'

import isEmpty from '~/utils/isEmpty'
import { IProduct } from '~/models/components/Product'
import { ProductValuesModel } from '~/models/topup/ProductPrice'
import { ITopupProduct } from '~/services/topup/interfaces'

import { defaultTopupProductFields } from '~/services/topup'
import { ContentError } from '~/apis/error/ContentError'

export function normalizeContentfulProducts(
  products: IProduct[]
): {
  productsById: Record<string, IProduct>
  productsWithNoId: IProduct[]
} {
  if (!products) throw new ContentError('Products not set!')

  const productsWithNoId: IProduct[] = []
  const productsById = products.reduce((accumulator, currentValue) => {
    const { productId } = currentValue

    if (!productId) {
      productsWithNoId.push(currentValue)
    } else {
      accumulator[productId] = {
        ...currentValue,
      }
    }

    return accumulator
  }, {} as Record<string, IProduct>)

  return { productsById, productsWithNoId }
}

export function normalizeTopupProducts(
  products: ITopupProduct[]
): {
  productsById: Record<string, TProduct>
  productsWithNoId: ITopupProduct[]
} {
  const productsWithNoId: ITopupProduct[] = []
  const productsById = products.reduce((accumulator, currentValue) => {
    const { products_service_id: productServiceId } = currentValue

    if (!productServiceId) {
      productsWithNoId.push(currentValue)
    }

    const currentValueWithDefault = {
      ...defaultTopupProductFields,
      ...currentValue,
    }

    accumulator[productServiceId] = {
      ...currentValueWithDefault,
      ...new ProductValuesModel(currentValueWithDefault).toJSON(),
    }

    return accumulator
  }, {} as Record<string, TProduct>)

  return { productsById, productsWithNoId }
}

export function getProductsIdWithoutPricesInStore(
  productsById: Record<string, IProduct>,
  productsByIdInStore: Record<string, TProduct>
): number[] {
  const ids = Object.keys(productsById)

  const productsWithoutPricesInStore = ids.reduce(
    (accumulator: number[], id: string): number[] => {
      const { price, failed } = productsByIdInStore[id] || {}
      const hasValidPrice = !isEmpty(price) && !failed

      if (hasValidPrice) return accumulator

      const parsedId = parseInt(id, 10)
      accumulator.push(parsedId)

      return accumulator
    },
    []
  )

  return productsWithoutPricesInStore
}
