import Vue from 'vue'

import TrustpilotPlugin from '../trustpilot/index'

/** eslint-disable */
const config = {
  "defaultLocale": "en-GB",
  "locales": {
    "EN": "en-GB",
    "NB": "nb-no",
    "SV": "sv-se",
    "FR": "fr-be",
    "NL": "nl-be",
    "PL": "pl-pl",
    "IT": "it-it",
    "ES": "es-es",
    "DE": "de-es",
    "PT": "pt-pt",
    "DA": "da-dk",
    "EL": "el-gr",
    "FI": "fi-fi",
    "TR": "tr-tr",
    "AR": "ar-ae",
    "CS": "cs-cz",
    "HU": "hu-hu",
    "IS": "is-is",
    "LV": "lv-lv",
    "MT": "mt-mt",
    "RO": "ro-ro",
    "SI": "si-si",
    "HR": "hr-hr",
    "ZH": "zh-tw"
  },
  "widgets": {
    "footer-ltr": {
      "templateId": "5419b6ffb0d04a076446a9af",
      "styleHeight": "45px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "dark",
      "schemaType": "Organization"
    },
    "footer-rtl": {
      "templateId": "5419b6ffb0d04a076446a9af",
      "styleHeight": "45px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "dark",
      "schemaType": "Organization"
    },
    "header-desktop-ltr": {
      "templateId": "5419b6ffb0d04a076446a9af",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "header-mobile-ltr": {
      "templateId": "5419b732fbfb950b10de65e5",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "header-desktop-rtl": {
      "templateId": "5419b6ffb0d04a076446a9af",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "header-mobile-rtl": {
      "templateId": "5419b732fbfb950b10de65e5",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "categoryHeader": {
      "templateId": "5419b732fbfb950b10de65e5",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "reviewList": {
      "templateId": "5717796816f630043868e2e8",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "business-desktop-rtl": {
      "templateId": "5419b637fa0340045cd0c936",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "business-desktop-ltr": {
      "templateId": "5419b637fa0340045cd0c936",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "business-mobile-rtl": {
      "templateId": "5419b637fa0340045cd0c936",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    },
    "business-mobile-ltr": {
      "templateId": "5419b637fa0340045cd0c936",
      "styleHeight": "24px",
      "styleWidth": "100%",
      "businessunitId": "5060842700006400051bf54f",
      "reviewUrl": "https://nl.trustpilot.com/review/beltegoed.nl",
      "locale": "en-GB",
      "reviewnumber": "5",
      "theme": "light",
      "schemaType": "Organization"
    }
  }
}
Vue.use(TrustpilotPlugin, config)
