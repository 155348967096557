export default {
  'a-propos-de-nous': 'https://company.recharge.com',
  'amazon-de': 'amazon',
  'amazon-fr': 'amazon',
  'amazon-fr/100-eur': 'amazon',
  'amazon-fr/25-eur': 'amazon',
  'amazon-fr/50-eur': 'amazon',
  battlenet: 'blizzard',
  bitnovo: 'bitnovo',
  'bitnovo/100-eur': 'bitnovo/100-eur',
  'bitnovo/25-eur': 'bitnovo/25-eur',
  'bitnovo/50-eur': 'bitnovo/50-eur',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  'blizzard/20-eur': 'blizzard/20-eur',
  'blizzard/50-eur': 'blizzard/50-eur',
  'blizzard/60-jours': 'blizzard',
  'cartes-cadeaux-de-divertissement': 'entertainment-gift-cards',
  'cartes-cadeaux-shopping': 'shopping-gift-cards',
  'cartes-de-paiement-prepayees': 'prepaid-credit-cards',
  'cartes-prepayees-jeux-video': 'gaming-cards',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  'comment-ca-functionne': 'how-it-works',
  'credit-d-appel': 'mobile-top-up',
  'crypto-monnaie': 'cryptocurrency',
  'crypto-voucher': 'crypto-voucher',
  'declaration-cookie': 'cookie-statement',
  dent: 'mobile-top-up',
  'droit-de-retractation': 'how-it-works',
  ecovoucher: 'ecovoucher',
  'ecovoucher/15-eur': 'ecovoucher/15-eur',
  'ecovoucher/25-eur': 'ecovoucher/25-eur',
  'ecovoucher/50-eur': 'ecovoucher/50-eur',
  'eve-online': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  'fifa-points/': 'gaming-cards',
  'fifa-points/1050-ps4': 'gaming-cards',
  'fifa-points/1600-ps4': 'gaming-cards',
  'fifa-points/2200-ps4': 'gaming-cards',
  'fifa-points/4600-ps4': 'gaming-cards',
  'fifa-points/4600-xbox-one': 'gaming-cards',
  flexepin: 'flexepin',
  'flexepin/50-eur': 'flexepin/50-eur',
  'google-play': 'google-play',
  'google-play/10-chf': 'google-play/10-chf',
  'google-play/100-chf': 'google-play/100-chf',
  'google-play/30-chf': 'google-play/30-chf',
  'google-play/50-chf': 'google-play/50-chf',
  'h-m-gift-card': 'shopping-gift-cards',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends/10-eur',
  'league-of-legends/20-eur': 'league-of-legends/20-eur',
  lebara: 'lebara',
  lycamobile: 'lycamobile',
  'modes-de-paiement': 'payment-methods',
  moneyclic: 'prepaid-credit-cards',
  'mucho-mobile': 'mucho-mobile',
  ncoin: 'ncoin',
  'ncoin/100-eur': 'ncoin/100-eur',
  'ncoin/20-eur': 'ncoin/20-eur',
  'ncoin/5-eur': 'ncoin/5-eur',
  'ncoin/50-eur': 'ncoin/50-eur',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-switch-online': 'nintendo-switch-online',
  openbucks: 'openbucks',
  paysafecard: 'paysafecard',
  'paysafecard/150-chf': 'paysafecard/150-chf',
  'paysafecard/25-chf': 'paysafecard/25-chf',
  'paysafecard/75-chf': 'paysafecard/75-chf',
  pcs: 'pcs',
  'playstation-now': 'playstation-now',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/12-mois': 'playstation-plus',
  'playstation-plus/3-mois': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/': 'playstation-store',
  'playstation-store/20-chf': 'playstation-store/20-chf',
  'playstation-store/50-chf': 'playstation-store/50-chf',
  'politique-de-confide': 'privacy-statement',
  'prepaid-mastercard-eur': 'prepaid-credit-cards',
  'prepaid-visa-us': 'prepaid-credit-cards',
  'red-dead-online': 'gaming-cards',
  roblox: 'roblox',
  runescape: 'runescape',
  'runescape-game-time': 'runescape',
  'runescape/90-jours': 'runescape/90-days',
  'salt-mobile': 'salt-mobile',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/': 'spotify-premium',
  'spotify-premium/13-chf': 'spotify-premium/13-chf',
  'spotify-premium/39-chf': 'spotify-premium/39-chf',
  'spotify-premium/78-chf': 'spotify-premium/78-chf',
  steam: 'steam',
  'steam/10-eur': 'steam/10-eur',
  'steam/100-eur': 'steam/100-eur',
  'steam/20-eur': 'steam/20-eur',
  'steam/25-eur': 'steam/25-eur',
  'steam/5-eur': 'steam/5-eur',
  'steam/50-eur': 'steam/50-eur',
  sunrise: 'sunrise',
  swisscom: 'swisscom',
  'swisscom/10-chf': 'swisscom/10-chf',
  'swisscom/30-chf': 'swisscom/30-chf',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'world-of-warcraft': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/12-mois': 'xbox-live-gold/12-months',
  'xbox-live-gold/150-lingots-d-or': 'xbox-live-gold',
  'xbox-live-gold/3-mois': 'xbox-live-gold/3-months',
  yallo: 'yallo',
  zalando: 'zalando',
  'zalando/20-chf': 'zalando/20-chf',
  'amazon-de/10-eur': 'amazon/10-eur-de',
  'cookie-hinweis': 'cookie-statement',
  datenschutzerklaerung: 'privacy-statement',
  'ecovoucher/100-eur': 'ecovoucher/100-eur',
  'entertainment-guthaben': 'entertainment-gift-cards',
  'fifa-points/1600-xbox-one': 'gaming-cards',
  'flexepin/10-eur': 'flexepin/10-eur',
  'flexepin/30-eur': 'flexepin/30-eur',
  'gaming-guthaben': 'gaming-cards',
  'h-m-geschenkcode': 'shopping-gift-cards',
  'handy-aufladen': 'mobile-top-up',
  kryptowaehrung: 'cryptocurrency',
  'ncoin/10-eur': 'ncoin/10-eur',
  'paysafecard/': 'paysafecard',
  'playstation-plus/12-monate': 'playstation-plus',
  'prepaid-kreditkarten': 'prepaid-credit-cards',
  'runescape/90-tage': 'runescape/90-days',
  'shopping-guthaben': 'shopping-gift-cards',
  'ueber-uns': 'https://company.recharge.com',
  widerrufsrecht: 'how-it-works',
  'wie-es-funktioniert': 'how-it-works',
  'xbox-live-gold/12-monate': 'xbox-live-gold/12-months',
  zahlungsmethoden: 'payment-methods',
  'zalando/50-chf': 'zalando/50-chf',
  'blog/anonyme-zahlungsmethoden': 'prepaid-credit-cards',
  'blog/die-besten-kreditkarten-fuer-jugendliche': 'prepaid-credit-cards',
  'blog/was-ist-eine-anonyme-prepaid-kreditkarte': 'prepaid-credit-cards',
  'blog/wo-du-kostenlos-eine-prepaid-kreditkarte-kaufen-kannst':
    'prepaid-credit-cards',
}
