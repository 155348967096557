const nameSpace = 'ui/checkout'
export enum TYPES {
  SET_BILLING_INFO_FORM_VISIBILITY = 'setBillingInfoFormVisibility',
  SET_CHECKOUT_SECTIONS_STATE = 'setCheckoutSectionsState',
  SET_PAYMENT_METHOD_FORM_STATE = 'setPaymentMethodFormState',
  SET_BILLING_INFO_FORM_FIELDS = 'setBillingInfoFormFields',
  SET_SUBMIT_STATE = 'setSubmitState',
  SET_BILLING_INFO_FORM_VALUES = 'setBillingInfoFormValues',
  SET_PAYMENT_METHODS = 'setPaymentMethods',
  SET_STEP = 'setStep',
  SET_SUBMIT_VERIFICATION_CODE_STATE = 'setSubmitVerificationCodeState',
  SET_RESEND_CODE_STATE = 'setResendCodeState',
  SET_BREADCRUMBS = 'setBreadcrumbs',
  SET_HAS_CERTIFIED_BADGE = 'setHasCertifiedBadge',
  SET_HAS_TERMS_AND_CONDITIONS = 'setHasTermsAndConditions',
  SET_TERMS_AND_CONDITIONS_URL = 'setTermsAndConditionsUrl',
  SET_HAS_EXTRA_PAYMENT_INFORMATON = 'setHasExtraPaymentInformaton',
}

export const NAMESPACED_TYPES = Object.freeze({
  SET_STEP: `${nameSpace}/${TYPES.SET_STEP}`,
  SET_PAYMENT_METHODS: `${nameSpace}/${TYPES.SET_PAYMENT_METHODS}`,
  SET_SUBMIT_STATE: `${nameSpace}/${TYPES.SET_SUBMIT_STATE}`,
  SET_BILLING_INFO_FORM_VALUES: `${nameSpace}/${TYPES.SET_BILLING_INFO_FORM_VALUES}`,
  SET_SUBMIT_VERIFICATION_CODE_STATE: `${nameSpace}/${TYPES.SET_SUBMIT_VERIFICATION_CODE_STATE}`,
  SET_RESEND_CODE_STATE: `${nameSpace}/${TYPES.SET_RESEND_CODE_STATE}`,
  SET_BILLING_INFO_FORM_VISIBILITY: `${nameSpace}/${TYPES.SET_BILLING_INFO_FORM_VISIBILITY}`,
  SET_PAYMENT_METHOD_FORM_STATE: `${nameSpace}/${TYPES.SET_PAYMENT_METHOD_FORM_STATE}`,
  SET_BILLING_INFO_FORM_FIELDS: `${nameSpace}/${TYPES.SET_BILLING_INFO_FORM_FIELDS}`,
  SET_BREADCRUMBS: `${nameSpace}/${TYPES.SET_BREADCRUMBS}`,
  SET_HAS_CERTIFIED_BADGE: `${nameSpace}/${TYPES.SET_HAS_CERTIFIED_BADGE}`,
  SET_HAS_TERMS_AND_CONDITIONS: `${nameSpace}/${TYPES.SET_HAS_TERMS_AND_CONDITIONS}`,
})

export default TYPES
