import { MutationTree } from 'vuex'
import { TYPES } from './mutation-types'
import { UiContentModalState } from './state'

const { SET_MODAL, SET_MODAL_VISIBLE } = TYPES
const mutations: MutationTree<UiContentModalState> = {
  [SET_MODAL](state, modal) {
    state.modal = modal
  },
  [SET_MODAL_VISIBLE](state, visible) {
    state.visible = visible
  },
}

export default mutations
