export default {
  'amazon-de': 'amazon',
  'amazon-de/10-eur': 'amazon/10-eur-de',
  'amazon-de/100-eur': 'amazon/100-eur-de',
  'amazon-de/15-eur': 'amazon/15-eur-de',
  'amazon-de/25-eur': 'amazon/25-eur-de',
  'amazon-de/50-eur': 'amazon/50-eur-de',
  'app-store-itunes': 'app-store-itunes',
  'app-store-itunes/150-dkk': 'app-store-itunes/150-dkk',
  'app-store-itunes/250-dkk': 'app-store-itunes/250-dkk',
  'app-store-itunes/500-dkk': 'app-store-itunes/500-dkk',
  'astropay-eur': 'astropay',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  'battlenet/50-eur': 'blizzard',
  betalingsmetoder: 'payment-methods',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  'cookie-politik': 'cookie-statement',
  'crypto-voucher': 'crypto-voucher',
  dent: 'mobile-top-up',
  ecovoucher: 'ecovoucher',
  'eve-online': 'gaming-cards',
  'eve-online/1100-plex': 'gaming-cards',
  'eve-online/500-plex': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  'fifa-points/1050-xbox-one': 'gaming-cards',
  'fifa-points/1600-xbox-one': 'gaming-cards',
  'fifa-points/2200-xbox-one': 'gaming-cards',
  'fifa-points/300-dkk': 'gaming-cards',
  'fifa-points/4600-xbox-one': 'gaming-cards',
  flexepin: 'flexepin',
  flipp: 'flipp',
  'flipp/250-dkk': 'flipp/250-dkk',
  'flipp/400-dkk': 'flipp/400-dkk',
  'flipp/750-dkk': 'flipp/750-dkk',
  fortrydelsesret: 'how-it-works',
  'forudbetalte-kreditkort': 'prepaid-credit-cards',
  'free-fire': 'free-fire',
  'gaming-gavekort': 'gaming-cards',
  'guild-wars': 'gaming-cards',
  'jeton-cash-eur': 'jeton-cash',
  'karma-koin': 'karma-koin',
  kryptovaluta: 'cryptocurrency',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends',
  'league-of-legends/20-eur': 'league-of-legends',
  'mobile-top-up': 'mobile-top-up',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin',
  'ncoin/100-eur': 'ncoin',
  'ncoin/20-eur': 'ncoin',
  'ncoin/50-eur': 'ncoin',
  neosurf: 'neosurf',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/150-dkk': 'nintendo-eshop/150-dkk',
  'nintendo-eshop/250-dkk': 'nintendo-eshop/250-dkk',
  'nintendo-eshop/500-dkk': 'nintendo-eshop/500-dkk',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-maneder': 'nintendo-switch-online/12-months',
  'nintendo-switch-online/3-maneder': 'nintendo-switch-online/3-months',
  'om-os': 'https://company.recharge.com',
  openbucks: 'openbucks',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/3-maneder': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/200-dkk': 'playstation-store/200-dkk',
  'playstation-store/400-dkk': 'playstation-store/400-dkk',
  pling: 'pling',
  'pling/1089-dkk': 'pling/1089-dkk',
  'pling/198-dkk': 'pling/198-dkk',
  'pling/495-dkk': 'pling/495-dkk',
  'prepaid-amex-us': 'prepaid-credit-cards',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'prepaid-visa-us': 'prepaid-credit-cards',
  privatlivserklaering: 'privacy-statement',
  'pubg-us': 'pubg',
  'red-dead-online': 'gaming-cards',
  'red-dead-online/150-gold-bars-xbox-one': 'gaming-cards',
  roblox: 'roblox',
  runescape: 'runescape',
  'sadan-virker-det': 'how-it-works',
  'shopping-gavekort': 'shopping-gift-cards',
  'sitemap.xml': 'sitemap.xml',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/100-dkk': 'spotify-premium/100-dkk',
  'spotify-premium/300-dkk': 'spotify-premium/300-dkk',
  'spotify-premium/600-dkk': 'spotify-premium/600-dkk',
  steam: 'steam',
  'steam/10-eur': 'steam',
  'steam/100-eur': 'steam',
  'steam/20-eur': 'steam',
  'steam/25-eur': 'steam',
  'steam/5-eur': 'steam',
  'steam/50-eur': 'steam',
  supergavekortet: 'supergavekortet',
  'supergavekortet/100-dkk': 'supergavekortet/100-dkk',
  'supergavekortet/1000-dkk': 'supergavekortet/1000-dkk',
  'supergavekortet/1500-dkk': 'supergavekortet/1500-dkk',
  'supergavekortet/250-dkk': 'supergavekortet/250-dkk',
  'supergavekortet/500-dk': 'supergavekortet/500-dkk',
  'supergavekortet/750-dkk': 'supergavekortet/750-dkk',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'underholdning-gavekort': 'entertainment-gift-cards',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-dage': 'world-of-warcraft',
  'xbox-gavekort': 'xbox-gift-card',
  'xbox-gavekort/100-dkk': 'xbox-gift-card',
  'xbox-gavekort/150-dkk': 'xbox-gift-card/150-dkk',
  'xbox-gavekort/250-dkk': 'xbox-gift-card',
  'xbox-gavekort/50-dkk': 'xbox-gift-card/50-dkk',
  'xbox-gavekort/500-dkk': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/12-maneder': 'xbox-live-gold',
  'xbox-live-gold/3-maneder': 'xbox-live-gold',
  zalando: 'zalando',
  'zalando/10-eur': 'zalando/100-dkk',
  'zalando/20-eur': 'zalando/200-dkk',
  'zalando/30-eur': 'zalando/300-dkk',
  'zalando/40-eur': 'zalando/400-dkk',
  'about-us': 'https://company.recharge.com',
  cryptocurrency: 'cryptocurrency',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  'gaming-gift-cards': 'gaming-cards',
  'how-it-works': 'how-it-works',
  'payment-methods': 'payment-methods',
  'pcs/20-eur': 'pcs/20-eur',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'prepaid-mastercard/100-eur': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'right-of-withdrawal': 'how-it-works',
  'shopping-gift-cards': 'shopping-gift-cards',
  'xbox-gift-card': 'xbox-gift-card',
  'blog/hvordan-vil-aendringerne-i-paysafecard-paavirke-dig': 'paysafecard',
  'blog/recharge-appen-er-her': '/',
}
