import { ActionTree } from 'vuex'
import { CONTEXT_STORAGE_KEYS } from '../context/enums'
import { IProductsStoreState } from './state'
import {
  normalizeContentfulProducts,
  normalizeTopupProducts,
  getProductsIdWithoutPricesInStore,
} from './services'
import MUTATION_TYPES from './mutation-types'
import isEmpty from '~/utils/isEmpty'
import { REDEEM_TYPE } from '~/services/topup/enums'

import { IProduct } from '~/models/components/Product'
import { ITopupProduct } from '~/services/topup/interfaces'

import { fetchProducts as fetchTopupProducts } from '~/services/topup'
import { ICurrencyModel } from '~/models/base/Currency'
import { normalizeVariant } from '~/normalizers/recommerce/variant'
import { Country } from '~/apis/clients/graphql/types/topup'
import { getStorageItem } from '~/services/localstorage'

export const defaultTopupProductFields = {
  id: '',
  products_service_id: '',
  available: false,

  brand_name: '',
  prices: [],
}

const actions: ActionTree<IProductsStoreState, IProductsStoreState> = {
  async fetchProductPrice(
    { commit, dispatch },
    {
      productId,
      merchant,
      currency,
      quantity,
      country,
    }: {
      productId: string
      currency: string
      merchant: string
      quantity: number
      country: Country
    }
  ): Promise<void> {
    const parsedId = parseInt(productId, 10)
    try {
      const { $topup } = this

      const topupProducts = await fetchTopupProducts({
        $topup,
        productsServiceIds: [parsedId],
        currency,
        merchant,
        quantity,
        country,
      })

      const [product] = topupProducts

      if (!product) {
        return
      }

      const { productsById: topupProductsById } = normalizeTopupProducts(
        topupProducts
      )

      const { brand_name: brandName } = topupProductsById[parsedId]
      dispatch('ui/checkout/showBrandSettings', brandName, { root: true })

      commit(MUTATION_TYPES.SET_PRODUCTS, topupProductsById)
    } catch (error) {
      dispatch('setProductsWithDefaultValues', {
        productIds: [parsedId],
      })
      this.$sentry.captureException(error)
    }
  },
  async fetchProducts(
    { commit, getters, dispatch },
    {
      products,
      currency: { abv: currency },
      merchant,
      country,
    }: {
      products: IProduct[]
      currency: ICurrencyModel
      merchant: string
      country?: Country
    }
  ): Promise<void> {
    if (isEmpty(products)) {
      return
    }

    const {
      productsById: contentfulProductsById,
      productsWithNoId: contentfulProductsWithNoId,
    } = normalizeContentfulProducts(products)

    commit(
      MUTATION_TYPES.SET_CONTENTFUL_PRODUCTS_WITH_NO_ID,
      contentfulProductsWithNoId
    )

    const productsByIdInStore = getters.all

    const contentfulProductsIdWithoutPricesInStore = getProductsIdWithoutPricesInStore(
      contentfulProductsById,
      productsByIdInStore
    )

    const needsToFetchNewProducts =
      contentfulProductsIdWithoutPricesInStore.length
    if (!needsToFetchNewProducts) {
      dispatch('setExistingProducts', {
        contentfulProductsById,
        productsByIdInStore,
      })
      return
    }

    const { $topup } = this

    try {
      commit('errors/resetErrors', undefined, { root: true })

      const topupProducts = await fetchTopupProducts({
        $topup,
        productsServiceIds: contentfulProductsIdWithoutPricesInStore,
        currency,
        merchant,
        country,
      })

      if (!topupProducts) return
      const {
        productsById: topupProductsById,
        productsWithNoId: topupProductsWithNoId,
      } = normalizeTopupProducts(topupProducts)

      commit(
        MUTATION_TYPES.SET_TOPUP_PRODUCTS_WITH_NO_ID,
        topupProductsWithNoId
      )
      commit(MUTATION_TYPES.SET_PRODUCTS, topupProductsById)
      dispatch('checkForRTR', topupProductsById)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') throw error
      commit('errors/setErrors', [error], {
        root: true,
      })
      dispatch('setProductsWithDefaultValues', {
        productIds: contentfulProductsIdWithoutPricesInStore,
      })
      this.$sentry.captureException(error)
    }
  },
  setProductsWithDefaultValues(
    { commit, dispatch, rootGetters },
    { productIds }: { productIds: number[] }
  ): void {
    if (rootGetters['errors/isGeolocationError']) return

    const topupProducts = productIds.map(id => {
      const parsedServiceId = id.toString()

      return {
        ...defaultTopupProductFields,
        products_service_id: parsedServiceId,
        id: parsedServiceId,
        brand_name: '',
        failed: true,
        available: false,
      }
    })

    const {
      productsById: topupProductsById,
      productsWithNoId: topupProductsWithNoId,
    } = normalizeTopupProducts(topupProducts)

    commit(MUTATION_TYPES.SET_TOPUP_PRODUCTS_WITH_NO_ID, topupProductsWithNoId)
    commit(MUTATION_TYPES.SET_PRODUCTS, topupProductsById)
    dispatch('checkForRTR', topupProductsById)
  },

  async fetchQuickBuyProductPriceIfNeeded(
    { dispatch, commit, getters, rootGetters },
    { recommerceProduct }
  ): Promise<void> {
    const productId = rootGetters['context/lastProductSeenId']
    const merchant = rootGetters['context/merchant']
    const currency = rootGetters['context/currency']?.abv
    const country = rootGetters['context/country']?.code.toLowerCase()
    const getProductById = getters.byId
    let topupProduct: ITopupProduct | undefined = getProductById(productId)

    if (isEmpty(topupProduct)) {
      await dispatch('fetchProductPrice', {
        productId,
        merchant,
        currency,
        country,
      })

      topupProduct = getters.all[productId]
    }

    if (isEmpty(topupProduct) || isEmpty(recommerceProduct)) {
      commit(MUTATION_TYPES.FETCH_QUICKBUY_PRODUCT_FAILED)
    }

    const valuesByCountry =
      getStorageItem({
        storage: 'recharge-storage',
        key: CONTEXT_STORAGE_KEYS.LAST_PRODUCT_VALUE_SEEN_BY_COUNTRY,
      }) || {}

    // Use productCard for QuickBuy product without changing all the components
    const recommerceProductForQuickBuy = {
      ...recommerceProduct,
      logo: recommerceProduct.productCardImage,
      value: valuesByCountry[country]?.value,
    }
    commit(MUTATION_TYPES.UPDATE_QUICKBUY_PRODUCT, {
      topupProduct,
      cmsProduct: recommerceProductForQuickBuy,
    })
  },
  async fetchQuickBuyProduct({ dispatch, commit, rootGetters }): Promise<void> {
    commit(MUTATION_TYPES.LOADING_QUICKBUY_PRODUCT)
    const lastProductSeenId = rootGetters['context/lastProductSeenId']

    try {
      const recommerceProduct = await this.$recommerce('variantById')({
        id: lastProductSeenId,
      })

      const product = recommerceProduct
        ? normalizeVariant(recommerceProduct)
        : undefined

      if (isEmpty(product)) {
        commit(MUTATION_TYPES.UPDATE_QUICKBUY_PRODUCT_AS_NOT_AVAILABLE)
        return
      }

      await dispatch('fetchQuickBuyProductPriceIfNeeded', {
        recommerceProduct: product,
      })
    } catch (error) {
      commit('errors/setErrors', [error], { root: true })
      commit(MUTATION_TYPES.FETCH_QUICKBUY_PRODUCT_FAILED)
      this.$sentry.captureException(error)
    }
  },

  checkForRTR({ commit }, products) {
    const someProductsHaveRTRRedeemType = Object.values(products).some(
      ({ redeem_type: redeemType }) => redeemType === REDEEM_TYPE.RTR
    )
    commit(
      MUTATION_TYPES.SET_SOME_PRODUCTS_HAVE_RTR_REDEEM_TYPE,
      someProductsHaveRTRRedeemType
    )
  },
  setExistingProducts(
    { commit, dispatch },
    {
      contentfulProductsById,
      productsByIdInStore,
    }: {
      contentfulProductsById: Record<string, IProduct>
      productsByIdInStore: Record<string, ITopupProduct>
    }
  ): void {
    const productsInStore = Object.keys(contentfulProductsById).reduce(
      (acc, currentId) => {
        if (!productsByIdInStore[currentId]) return acc

        return {
          ...acc,
          [`${currentId}`]: productsByIdInStore[currentId],
        }
      },
      {}
    )
    commit(MUTATION_TYPES.SET_PRODUCTS, productsInStore)
    dispatch('checkForRTR', productsInStore)
  },
  fetchQuickBuyProductIfIdExistsInLocalstorage({
    dispatch,
    rootGetters,
  }): void {
    dispatch('context/validateLastProductSeen', {}, { root: true })
    const productId = rootGetters['context/lastProductSeenId']
    if (!productId) return

    dispatch('fetchQuickBuyProduct')
  },
  setPageViewEventLogged(
    { commit },
    {
      isPageViewed,
    }: {
      isPageViewed: boolean
    }
  ): void {
    commit(MUTATION_TYPES.SET_PAGE_VIEW_EVENT_LOGGED, isPageViewed)
  },
}

export default actions
