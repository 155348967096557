<template>
  <div v-if="!snoozed">
    <div
      v-if="modal && modal.showNotification && !visible"
      class="w-full bg-tertiary-light flex relative"
    >
      <button-icon
        data-test="close-button"
        color="black"
        icon="close"
        font-size="xs"
        class="absolute top-4 end-4"
        @click="closeModalAndSnooze"
      />
      <div class="flex items-center m-auto py-4">
        <icon icon="bell" class="m-4" />
        <div class="flex flex-wrap md:flex-nowrap items-center me-10">
          <span class="m-4">{{ modal.notificationText }}</span>
          <ui-button class="m-4" type="dark" @click.stop="showModal"
            >Read more</ui-button
          >
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="visible && modal"
        v-bind="$attrs"
        class="fixed inset-0 z-50 bg-overlay md:p-4 flex justify-center items-center"
        v-on="$listeners"
      >
        <section
          v-click-outside="closeModalAndSnooze"
          data-test="modal"
          :aria-labelledby="modal.title"
          :aria-describedby="modal.description"
          role="dialog"
          class="relative max-h-full overflow-y-auto bg-white p-10 pt-15 pb-15 shadow-md w-screen h-screen md:h-auto md:w-wide-modal md:rounded-lg"
        >
          <button-icon
            data-test="close-button"
            color="black"
            icon="close"
            font-size="xs"
            class="absolute top-4 end-4"
            @click="closeModalAndSnooze"
          />
          <div class="flex h-full md:h-auto">
            <div class="flex items-center md:pe-5 text-lg">
              <div class="text-center md:text-start">
                <ui-image
                  :src="modal.imageMobile.url"
                  width="4/5"
                  height="auto"
                  class="inline md:hidden mb-4"
                  :image-dimension="[20, 20]"
                />
                <ui-title class="mb-4">{{ modal.title }}</ui-title>
                <div class="mb-8">
                  <rich-text-renderer
                    v-if="modal.description"
                    :document="modal.description"
                  />
                </div>
                <ui-button
                  v-if="modal.callToActionUrl"
                  type="primary"
                  :url="modal.callToActionUrl"
                >
                  {{ modal.callToActionText }}
                </ui-button>
                <ui-button v-else type="primary" @click="closeModalAndSnooze">
                  {{ modal.callToActionText }}
                </ui-button>
              </div>
            </div>
            <ui-image
              :src="modal.imageDesktop.url"
              width="2/4"
              height="auto"
              class="hidden md:block"
              :image-dimension="[20, 20]"
            />
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  ButtonIcon,
  UiButton,
  UiImage,
  UiTitle,
  Icon,
} from '~/components/atoms'
import RichTextRenderer from '~/components/renderers/rich-text-renderer'

export default {
  components: {
    ButtonIcon,
    Icon,
    UiButton,
    UiImage,
    UiTitle,
    RichTextRenderer,
  },
  props: {
    modal: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    snoozed() {
      return (
        this.modal &&
        this.$cookies.get('content_modal_snoozed') === this.modal.name
      )
    },
  },
  methods: {
    ...mapActions('ui/content-modal', ['showModal', 'closeModal']),
    closeModalAndSnooze() {
      if (this.modal.showNotification) {
        this.$cookies.set('content_modal_snoozed', this.modal.name)
      }
      this.closeModal()
    },
  },
}
</script>
