
import { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import { IImageModel } from '~/models/base/Image'
import { UiImage } from '~/components/atoms'

export default {
  components: {
    UiImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      required: true,
    },
    brandFeedImage: {
      type: Object,
      default() {
        return {}
      },
    } as PropOptions<IImageModel | Record<string, unknown>>,
    enableLinks: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('context', ['marketplacePrefix', 'country']),
  },
  methods: {
    handleClick() {
      this.$navigate(this.slug)
    },
  },
}
