import { GetterTree } from 'vuex'
import { IUiCheckoutState } from './state'
import { STEPS } from './enums/steps'
import isEmpty from '~/utils/isEmpty'
import { IFormField } from '~/factories/FormField'
import { ICheckoutPaymentMethod } from '~/models/components/CheckoutPaymentMethod'
import { IBreadcrumb } from '~/components/molecules/breadcrumbs/interfaces'

const getters: GetterTree<IUiCheckoutState, IUiCheckoutState> = {
  isBillingInfoFormVisible: ({
    paymentMethodForm: { enabled },
    billingInfoForm: { visible },
  }): boolean => visible && enabled,
  isPaymentMethodFormEnabled: ({
    paymentMethodForm: { enabled },
    submit: { loading },
  }) => enabled && !loading,
  isSubmitLoading: ({ submit: { loading } }: IUiCheckoutState) => loading,
  billingInfoFormFields: ({
    billingInfoForm: { fields },
  }: IUiCheckoutState): IFormField[] => fields,
  billingInfoFormValues: ({
    billingInfoForm: { values },
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IUiCheckoutState): any => {
    return isEmpty(values) ? {} : JSON.parse(values)
  },

  paymentMethods: ({
    paymentMethods,
  }: IUiCheckoutState): ICheckoutPaymentMethod[] => {
    return paymentMethods.filter(({ disabled }) => !disabled)
  },
  checkoutSections: ({ checkoutSections }) => checkoutSections,
  isValidationCodeStepActive: ({ step }) => step === STEPS.validationCode,
  isInitialStepActive: ({ step }) => step === STEPS.initial,
  isSubmitValidationCodeLoading: ({
    submitVerificationCode: { loading },
  }: IUiCheckoutState): boolean => loading,
  isResendCodeLoading: ({
    resendCode: { loading },
  }: IUiCheckoutState): boolean => loading,
  hasResendCodeSucceeded: ({
    resendCode: { succeeded },
  }: IUiCheckoutState): boolean => succeeded,
  hasResendCodeFailed: ({
    resendCode: { failed },
  }: IUiCheckoutState): boolean => failed,
  breadcrumbs: ({ breadcrumbs }: IUiCheckoutState): IBreadcrumb[] =>
    breadcrumbs,
  hasCertifiedBadge: ({ hasCertifiedBadge }: IUiCheckoutState): boolean =>
    hasCertifiedBadge,
  hasExtraPaymentInformation: ({
    hasExtraPaymentInformation,
  }: IUiCheckoutState): boolean => hasExtraPaymentInformation,
  hasTermsAndConditions: ({
    hasTermsAndConditions,
  }: IUiCheckoutState): boolean => hasTermsAndConditions,
  termsAndConditionsUrl: ({
    termsAndConditionsUrl,
  }: IUiCheckoutState): string => termsAndConditionsUrl,
  shouldShowBreadcrumbs: ({ step }: IUiCheckoutState): boolean =>
    step !== STEPS.validationCode,
}

export default getters
