interface IMutation {
  selectedLanguage?: string
  selectedCountry?: string
  currency?: string
  selectedSlug?: string
}

export const mutatePath = (
  path: string,
  { selectedLanguage, selectedCountry, currency, selectedSlug }: IMutation
): string => {
  const [_, currentLanguage, currentCountry, ...currentSlug] = path.split('/')

  const language = selectedLanguage || currentLanguage
  const country = selectedCountry?.toLowerCase() || currentCountry
  const slug = selectedSlug !== undefined ? selectedSlug : currentSlug.join('/')
  const newPath = ['', language, country, slug].join('/')

  return currency ? `${newPath}?currency=${currency}` : newPath
}
