export default {
  'about-us': 'https://company.recharge.com',
  'amazon-sa': 'amazon',
  'amazon-sa/250-sar': 'amazon',
  'amazon-sa/50-sar': 'amazon',
  'amazon-sa/500-sar': 'amazon',
  'amazon-us': 'amazon',
  'apex-legends': 'gaming-cards',
  bitsa: 'bitsa',
  'call-of-duty': 'gaming-cards',
  careem: 'careem',
  carrefour: 'carrefour',
  cashu: 'prepaid-credit-cards',
  'cashu/10-usd': 'prepaid-credit-cards',
  'cashu/50-usd': 'prepaid-credit-cards',
  'cookie-statement': 'cookie-statement',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  'fifa-points': 'gaming-cards',
  'fortnite-us': 'fortnite',
  'free-fire': 'free-fire',
  'friendi-mobile': 'friendi-mobile',
  'friendi-mobile/100-sar': 'friendi-mobile',
  'friendi-mobile/20-sar': 'friendi-mobile',
  'friendi-mobile/30-sar': 'friendi-mobile',
  'friendi-mobile/50-sar': 'friendi-mobile',
  'gaming-gift-cards': 'gaming-cards',
  'how-it-works': 'how-it-works',
  'karma-koin-us': 'karma-koin',
  'landmark-shukran': 'shukran',
  'league-of-legends': 'league-of-legends',
  lebara: 'lebara',
  'mobile-top-up': 'mobile-top-up',
  mobily: 'mobily',
  'nintendo-eshop-us': 'nintendo-eshop',
  noon: 'noon',
  'openbucks-us': 'openbucks',
  'payment-methods': 'payment-methods',
  'playstation-plus': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store-us': 'playstation-store',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'prepaid-mastercard-us': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  pubg: 'pubg',
  qanz: 'qanz',
  'right-of-withdrawal': 'how-it-works',
  'roblox-us': 'roblox',
  sharafdg: 'sharaf',
  'shopping-gift-cards': 'shopping-gift-cards',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/64-sar': 'spotify-premium',
  starzplay: 'entertainment-gift-cards',
  stc: 'stc',
  steam: 'steam',
  'tamimi-markets': 'tamimi-market',
  twitch: 'twitch',
  'virgin-mobile': 'virgin-mobile',
  'virgin-mobile/100-sar': 'virgin-mobile',
  'virgin-mobile/50-sar': 'virgin-mobile',
  'xbox-game-pass': 'xbox-game-pass',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card-us': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold-us': 'xbox-live-gold',
  'xbox-live-gold/3-months': 'xbox-live-gold',
  zain: 'zain',
  astropay: 'astropay',
  'jeton-cash-eur': 'jeton-cash',
  'visa-prepaid-us': 'prepaid-credit-cards',
  'prepaid-amex-us': 'prepaid-credit-cards',
  'bitsa/100-eur': 'bitsa',
  'bitsa/25-eur': 'bitsa',
  'bitsa/50-eur': 'bitsa',
  'fifa-points/12000-points-playstation': 'gaming-cards',
  'pubg/1800-uc': 'pubg',
  'pubg/3850-uc': 'pubg',
  'pubg/660-uc': 'pubg',
  'stc/115-sar': 'stc',
  'virgin-mobile/30-sar': 'virgin-mobile',
  'zain/115-sar': 'zain',
}
