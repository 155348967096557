export default [
  {
    id: 'Buckaroo_Instant_Ideal',
    name: 'iDEAL',
    issuers: [
      {
        id: 'INGBNL2A',
        label: 'ING Bank',
        __typename: 'PaymentMethodIssuer',
        value: 'ingbnl2a',
      },
      {
        id: 'ABNANL2A',
        label: 'ABN Amro Bank',
        __typename: 'PaymentMethodIssuer',
        value: 'abnanl2a',
      },
      {
        id: 'RABONL2U',
        label: 'Rabobank',
        __typename: 'PaymentMethodIssuer',
        value: 'rabonl2u',
      },
      {
        id: 'SNSBNL2A',
        label: 'SNS Bank',
        __typename: 'PaymentMethodIssuer',
        value: 'snsbnl2a',
      },
      {
        id: 'ASNBNL21',
        label: 'ASN Bank',
        __typename: 'PaymentMethodIssuer',
        value: 'asnbnl21',
      },
      {
        id: 'TRIONL2U',
        label: 'Triodos Bank',
        __typename: 'PaymentMethodIssuer',
        value: 'trionl2u',
      },
      {
        id: 'RBRBNL21',
        label: 'Regio Bank',
        __typename: 'PaymentMethodIssuer',
        value: 'rbrbnl21',
      },
      {
        id: 'KNABNL2H',
        label: 'KNAB Bank',
        __typename: 'PaymentMethodIssuer',
        value: 'knabnl2h',
      },
      {
        id: 'FVLBNL22',
        label: 'van Lanschot Bankiers',
        __typename: 'PaymentMethodIssuer',
        value: 'fvlbnl22',
      },
      {
        id: 'BUNQNL2A',
        label: 'Bunq',
        __typename: 'PaymentMethodIssuer',
        value: 'bunqnl2a',
      },
    ],
    disabled: false,
    transactionCost: {
      value: 0,
      currency: 'EUR',
      __typename: 'Money',
    },
    image: '',
    paymentInformationFields: [],
    totalPrice: undefined,
  },
  {
    id: 'Cg_Test',
    name: 'Test',
    issuers: [],
    disabled: false,
    transactionCost: {
      value: 0,
      currency: 'EUR',
      __typename: 'Money',
    },
    image: '',
    paymentInformationFields: [],
    totalPrice: undefined,
  },
  {
    id: 'Paypal_Expresscheckout',
    name: 'PayPal',
    issuers: [],
    disabled: false,
    transactionCost: {
      value: 0,
      currency: 'EUR',
      __typename: 'Money',
    },
    image: '',
    paymentInformationFields: [],
    totalPrice: undefined,
  },
  {
    id: 'Adyen_Visa',
    name: 'Visa',
    issuers: [],
    disabled: false,
    transactionCost: {
      value: 0,
      currency: 'EUR',
      __typename: 'Money',
    },
    image: '',
    paymentInformationFields: [],
    totalPrice: undefined,
  },
  {
    id: 'Telserv_Ivr',
    name: 'Telefoon/Beltegoed',
    issuers: [],
    disabled: false,
    transactionCost: {
      value: 518,
      currency: 'EUR',
      __typename: 'Money',
    },
    image: '',
    paymentInformationFields: [],
    totalPrice: undefined,
  },
  {
    id: 'Adyen_Mastercard',
    name: 'Mastercard',
    issuers: [],
    disabled: true,
    transactionCost: null,
    image: '',
    paymentInformationFields: [],
    totalPrice: undefined,
  },
  {
    id: 'Buckaroo_Instant_Banktransfer',
    name: 'Bankoverschrijving',
    issuers: [],
    disabled: true,
    transactionCost: null,
    image: '',
    paymentInformationFields: [],
    totalPrice: undefined,
  },
]
