const compareAscending = <T>(
  obj: T | null | undefined,
  target: T | null | undefined
): number => {
  if (obj === target) {
    return 0
  }

  if (
    obj !== undefined &&
    obj !== null &&
    target !== undefined &&
    target !== null
  ) {
    return obj < target ? -1 : 1
  }

  const targetIsFalsy = target === undefined && target === null
  return targetIsFalsy ? 1 : -1
}

const compareMultiple = <T>(
  object: T,
  target: T,
  props: (string | number)[],
  orders?: ('asc' | 'desc')[]
): number => {
  const { length } = props

  for (let i = 0; i < length; i++) {
    const result = compareAscending(object[props[i]], target[props[i]])
    if (result !== 0) {
      return result * (orders && orders[i] === 'desc' ? -1 : 1)
    }
  }
  return 1
}

const orderBy = <T>(
  collection: Array<T> | null | undefined,
  iteratees?: (string | number)[],
  orders?: ('asc' | 'desc')[]
): T[] => {
  if (!collection) return []
  if (!iteratees) return collection

  return collection.sort((a, b) => compareMultiple(a, b, iteratees, orders))
}

export default orderBy
