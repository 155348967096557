import { ActionTree } from 'vuex'
import { TYPES } from './mutation-types'
import { states } from './states'
import { IStateSelectorModel } from '~/models/components/StateSelector'

const { SET_STATES } = TYPES

const actions: ActionTree<IStateSelectorModel, IStateSelectorModel> = {
  async getStates({ commit }, countryCode) {
    if (!countryCode || !states[countryCode]) {
      return commit(SET_STATES, [])
    }

    return commit(SET_STATES, states[countryCode])
  },
}

export default actions
