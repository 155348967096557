
import { mapGetters, mapActions } from 'vuex'
import { PropOptions } from 'vue'
import { Container, Row, Column } from '~/components/grid'
import { UiImage } from '~/components/atoms'
import { QuickBuyCard, ReorderCard } from '~/components/molecules'
import HeroCountrySelector from '~/components/organisms/hero-country-selector/index.vue'
import { fetchAccountOrderHistory, isReorderPossible } from '~/services/orders'
import { UNO_MERCHANT } from '~/utils/constants/orders'
import { OrderType } from '~/apis/clients/graphql/types/topup'
import reorder from '~/mixins/reorder'
import { RecommerceVariant } from '~/apis/clients/rest/recommerce/types'

const NUMBER_OF_ORDERS = 1

export default {
  components: {
    Container,
    UiImage,
    Row,
    Column,
    QuickBuyCard,
    ReorderCard,
    HeroCountrySelector,
  },
  mixins: [reorder],
  props: {
    title: {
      type: String,
      required: true,
    },
    src: {
      type: Object,
      required: true,
    },
    disableQuickBuyProduct: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    isMobile: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      mounted: false,
      countries: [],
      reorderableOrder: null,
      orderLoading: false,
    }
  },
  computed: {
    ...mapGetters('products', [
      'quickBuyProductIsLoading',
      'showQuickBuyProductCard',
      'quickBuyProduct',
    ]),

    ...mapGetters('contentful', ['country']),
    ...mapGetters('context', [
      'comesFromRechargeClassic',
      'currency',
      'language',
    ]),
    ...mapGetters('user', ['isAuthenticated']),
    propositionTitle() {
      return this.showCountrySelector && !this.showQuickBuyProductCard
        ? this.$t('organisms.hero_banner.recharge_classic_title')
        : this.title
    },
    showReorderCard(): boolean {
      const { mounted, reorderableOrder, orderLoading } = this
      return (mounted && !!reorderableOrder) || orderLoading
    },
    showCountrySelector() {
      const {
        mounted,
        quickBuyProductIsLoading,
        showQuickBuyProductCard,
        orderLoading,
      } = this
      return (
        mounted &&
        !quickBuyProductIsLoading &&
        !showQuickBuyProductCard &&
        !orderLoading
      )
    },
    showBackgroundImage() {
      const {
        mounted,
        showQuickBuyProductCard,
        quickBuyProductIsLoading,
        showCountrySelector,
        showReorderCard,
        orderLoading,
      } = this
      return (
        (mounted &&
          !showReorderCard &&
          !orderLoading &&
          !showQuickBuyProductCard &&
          !quickBuyProductIsLoading &&
          !showCountrySelector) ||
        this.disableQuickBuyProduct
      )
    },
    bottomMarginClasses() {
      const { showQuickBuyProductCard, showCountrySelector } = this
      return (showQuickBuyProductCard || showCountrySelector) &&
        !this.disableQuickBuyProduct
        ? 'lg:mb-16'
        : ' mb-8 lg:mb-16'
    },
    headerWidthClass() {
      const {
        showQuickBuyProductCard,
        showCountrySelector,
        showReorderCard,
      } = this
      return showQuickBuyProductCard || showCountrySelector || showReorderCard
        ? 'sm:w-quick-buy'
        : 'sm-w-100'
    },
  },
  watch: {
    isAuthenticated(val) {
      if (!val) this.reorderableOrder = null
    },
  },
  async mounted() {
    this.mounted = true
    if (this.isAuthenticated) {
      await this.fetchLatestReorderableOrder()
    } else {
      this.orderLoading = false
    }
    if (this.disableQuickBuyProduct) return
    this.fetchQuickBuyProductIfIdExistsInLocalstorage()
  },
  methods: {
    ...mapActions('products', ['fetchQuickBuyProductIfIdExistsInLocalstorage']),
    async fetchLatestReorderableOrder() {
      try {
        if (!this.orderLoading) this.orderLoading = true
        const orderData = await fetchAccountOrderHistory(
          this.$topup,
          this.$store,
          UNO_MERCHANT.ID,
          OrderType.Topup,
          NUMBER_OF_ORDERS,
          0,
          this.$sentry
        )
        if (!orderData) return
        const [order] = orderData
        order.productVariant = await this.fetchRecommerceVariant(order)
        this.reorderableOrder = isReorderPossible(order.productVariant, order)
          ? order
          : null
      } catch (e) {
        if (process.env.NODE_ENV === 'development') console.error(e)
        this.$sentry.captureException(e)
      } finally {
        this.orderLoading = false
      }
    },
    async fetchRecommerceVariant(orderData): Promise<RecommerceVariant | null> {
      let variantData = null
      const isGMPOrder = this.checkGMPOrder(orderData)
      const productId = orderData.products[0].products_service_id

      try {
        variantData = await this.$recommerce('variantById')(
          {
            id: productId,
          },
          {
            channel: isGMPOrder ? 'uno_global' : 'uno',
            'Accept-Language': this.language.code,
            currency: orderData.currency.toLowerCase(),
            'Redemption-Country': orderData.country_iso.toLowerCase(),
            'Customer-Country': orderData.country_iso.toLowerCase(),
          }
        )
      } catch (e) {
        if (process.env.NODE_ENV === 'development') console.error(e)
        this.$sentry.captureException(e)
      }
      return variantData
    },
  },
}
