import { Plugin } from '@nuxt/types'
import { Store } from 'vuex/types'
import {
  NotificationType,
  RawNotification,
} from '~/store/ui/notifications/types'
import {
  clearStorageItem,
  getStorageItem,
  setStorageItem,
} from '~/services/localstorage'

const DEFAULT_INFO_CLOSE_AFTER = 4000
const DEFAULT_SUCCESS_CLOSE_AFTER = 4000
const DEFAULT_WARNING_CLOSE_AFTER = 8000
const DEFAULT_ERROR_CLOSE_AFTER = 8000

const STORAGE = 'notifications'
const STORAGE_KEY = 'notifications'

class NotificationsPlugin {
  private store: Store<unknown>

  constructor(store: Store<unknown>) {
    this.store = store
  }

  private push(notification: RawNotification): void {
    if (notification.deferred) {
      if (!process.server) {
        const deferredNotifications = getStorageItem({
          storage: STORAGE,
          key: STORAGE_KEY,
        })

        let notifications: RawNotification[] = []

        if (deferredNotifications) {
          notifications = [...deferredNotifications]
        }

        notifications.push({
          ...notification,
          deferred: false,
        })

        setStorageItem({
          storage: STORAGE,
          key: STORAGE_KEY,
          value: notifications,
        })
      }
    } else {
      this.store.dispatch('ui/notifications/pushNotification', notification)
    }
  }

  public pushInfo(rawNotification: RawNotification): void {
    this.push({
      ...rawNotification,
      type: NotificationType.INFO,
      closeAfter: rawNotification.closeAfter || DEFAULT_INFO_CLOSE_AFTER,
    })
  }

  public pushSuccess(rawNotification: RawNotification): void {
    this.push({
      ...rawNotification,
      type: NotificationType.SUCCESS,
      closeAfter: rawNotification.closeAfter || DEFAULT_SUCCESS_CLOSE_AFTER,
    })
  }

  public pushWarning(rawNotification: RawNotification): void {
    this.push({
      ...rawNotification,
      type: NotificationType.WARNING,
      closeAfter: rawNotification.closeAfter || DEFAULT_WARNING_CLOSE_AFTER,
    })
  }

  public pushError(rawNotification: RawNotification): void {
    this.push({
      ...rawNotification,
      type: NotificationType.ERROR,
      closeAfter: rawNotification.closeAfter || DEFAULT_ERROR_CLOSE_AFTER,
    })
  }

  /**
   * Restores deferred notifications which were pushed to local storage
   * usually before changing window.location, since by default notifications
   * stored in memory and will be lost after changing window.location.
   */
  public restoreDeferredNotifications(): void {
    const deferredNotifications = getStorageItem({
      storage: STORAGE,
      key: STORAGE_KEY,
    })

    if (deferredNotifications) {
      deferredNotifications.forEach(notification => {
        this.push(notification)
      })
    }

    clearStorageItem({
      storage: STORAGE,
      key: STORAGE_KEY,
    })
  }
}

const notifications: Plugin = (ctx, inject) => {
  inject('notifications', new NotificationsPlugin(ctx.store))
}

export { NotificationsPlugin }

export default notifications
