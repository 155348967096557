export default {
  'alisveris-hediye-kartlari': 'shopping-gift-cards',
  'amazon-de': 'amazon',
  'amazon-tr': 'amazon',
  battlenet: 'blizzard',
  'blu-tv': 'entertainment-gift-cards',
  boyner: 'boyner',
  carrefour: 'carrefour',
  cashu: 'prepaid-credit-cards',
  'cayma-hakki': 'how-it-works',
  'cerez-bildirimi': 'cookie-statement',
  dent: 'mobile-top-up',
  ecovoucher: 'ecovoucher',
  'eglence-hediye-kartlari': 'entertainment-gift-cards',
  flexepin: 'flexepin',
  getir: 'getir',
  'gizlilik-bildirimi': 'privacy-statement',
  hakkimizda: 'https://company.recharge.com',
  ikea: 'ikea',
  'islem-rehberi': 'how-it-works',
  koton: 'koton',
  'league-of-legends': 'league-of-legends',
  'mobil-yukleme': 'mobile-top-up',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'nintendo-eshop': 'nintendo-eshop',
  'odeme-secenekleri': 'payment-methods',
  'on-odemeli-kredi-karti': 'prepaid-credit-cards',
  'oyun-hediye-kartlari': 'gaming-cards',
  'playstation-store': 'playstation-store',
  pubg: 'pubg',
  'rakuten-kobo': 'entertainment-gift-cards',
  'razer-gold': 'gaming-cards',
  'spotify-premium': 'spotify-premium',
  steam: 'steam',
  teknosa: 'teknosa',
  'turk-telekom': 'turk-telekom',
  turkcell: 'turkcell',
  'twitch-us': 'twitch',
  vodafone: 'vodafone',
  'vodafone-cyprus': 'vodafone',
  'world-of-warcraft': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  zula: 'gaming-cards',
  'prepaid-mastercard-eur': 'prepaid-credit-cards',
  'jeton-cash-eur': 'jeton-cash',
  astropay: 'astropay',
  'prepaid-visa-us': 'prepaid-credit-cards',
  'prepaid-amex-us': 'prepaid-credit-cards',
}
