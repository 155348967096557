export default {
  computed: {
    customDenominationErrorMessage(): string {
      if (this.openRangeValue) {
        if (
          this.openRangeValue * this.quantity >
          this.product.customDenominationMaxValue
        ) {
          return this.$t('open.range.value.oob')
        }
        if (
          this.openRangeValue * this.quantity <
          this.product.customDenominationMinValue
        ) {
          return this.$t('open.range.value.small')
        }
      }

      return ''
    },
  },
}
