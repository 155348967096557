const { CloudWatch } = require('aws-sdk')
const cw = new CloudWatch()
const { STAGE } = process.env

/**
 * Send custom metric to CloudWatch
 *
 * @TODO check if it's needed and remove if not
 *
 * @param {String} timerName
 * @param {Number} executionTime
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function putCloudwatchMetric(timerName, executionTime) {
  /**
   * @type { CloudWatch.putMetricDataInput }
   */
  const putMetricDataInput = {
    MetricData: [
      {
        Dimensions: [
          {
            Name: 'Timers',
            Value: timerName,
          },
          {
            Name: 'Stage',
            Value: STAGE,
          },
        ],
        Timestamp: (new Date()).toISOString(),
        Value: `${executionTime}`,
        Unit: 'Milliseconds',
        MetricName: 'ExecutionTime',
      },
    ],
    Namespace: 'ShopWeb',
  }
  return cw.putMetricData(putMetricDataInput).promise()
}

const timers = {}

function startMeasurement(name) {
  // eslint-disable-next-line no-console
  if (timers[name]) console.log(`${name} started multiple times without ending`)

  timers[name] = process.hrtime()
}

function endMeasurement(name) {
  if (!timers[name]) return

  const end = process.hrtime(timers[name])

  const executionTime = end[0] * 1000 + end[1] / 1e6
  // eslint-disable-next-line no-console
  console.log(`${name}: ${executionTime}ms`)

  // NOTE: Split on ' -> ' to drop paths from 'server:fetch' metrics
  // putCloudwatchMetric(name.split(' -> ')[0], executionTime).then(
  //   ({ $response: { error } }) => {
  //     // eslint-disable-next-line no-console
  //     if (error !== null) console.error(error)
  //   }
  // )

  timers[name] = undefined
}

module.exports = { startMeasurement, endMeasurement }
