import { PropOptions } from 'vue'
import { IImageModel, IResponsiveImageModel } from '~/models/base/Image'

type TImagePosition = 'left' | 'right' | 'center'

const props = {
  src: {
    type: [Object, String],
    default: '',
  } as PropOptions<IImageModel | IResponsiveImageModel | string>,
  imageDimensions: {
    type: Array,
    default: () => [],
  },
  fit: {
    type: String,
    default: '',
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  alt: {
    type: String,
    default: '',
  },
  responsiveWidth: {
    type: String,
    default: '',
  },
  skeleton: Boolean,
  height: {
    type: String,
    default: 'auto',
  },
  width: {
    type: String,
    default: 'full',
  },
  contain: Boolean,
  position: {
    type: String,
    default: 'center',
  } as PropOptions<TImagePosition>,
}

export default props
