export default {
  BilliTel: 'mobile-top-up',
  billitel: 'mobile-top-up',
  'amazon-de': 'amazon',
  'amazon-de/10-eur': 'amazon',
  'amazon-de/15-eur': 'amazon',
  'amazon-de/25-eur': 'amazon',
  'app-store-itunes': 'app-store-itunes',
  'app-store-itunes/100-eur': 'app-store-itunes',
  'app-store-itunes/15-eur': 'app-store-itunes',
  'app-store-itunes/25-eur': 'app-store-itunes',
  'app-store-itunes/50-eur': 'app-store-itunes',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  'battlenet/50-eur': 'blizzard',
  bfree: 'bfree',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  bob: 'bob',
  callingcard: 'calling-card',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  'cookie-hinweis': 'cookie-statement',
  'crypto-voucher': 'crypto-voucher',
  datenschutzerklaerung: 'privacy-statement',
  dazn: 'dazn',
  deezer: 'deezer',
  dent: 'mobile-top-up',
  drei: 'drei',
  'ea-origin': 'ea-origin',
  ecovoucher: 'ecovoucher',
  eety: 'eety',
  'entertainment-gutscheine': 'entertainment-gift-cards',
  'eve-online': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  'fifa-points/1050-ps4': 'gaming-cards',
  'fifa-points/1600-ps4': 'gaming-cards',
  'fifa-points/2200-ps4': 'gaming-cards',
  'fifa-points/4600-ps4': 'gaming-cards',
  flexepin: 'flexepin',
  'gaming-gutscheine': 'gaming-cards',
  georg: 'georg',
  'google-play': 'google-play',
  'google-play/100-eur': 'google-play',
  'google-play/15-eur': 'google-play',
  'google-play/25-eur': 'google-play',
  'google-play/50-eur': 'google-play',
  'handy-aufladen': 'mobile-top-up',
  kryptowaehrung: 'cryptocurrency',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends',
  'league-of-legends/20-eur': 'league-of-legends',
  lycamobile: 'lycamobile',
  magenta: 'magenta',
  mtel: 'mtel',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'netflix/25-eur': 'netflix',
  'netflix/50-eur': 'netflix',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop',
  'nintendo-eshop/25-eur': 'nintendo-eshop',
  'nintendo-eshop/50-eur': 'nintendo-eshop',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-monate': 'nintendo-switch-online',
  'nintendo-switch-online/3-monate': 'nintendo-switch-online',
  openbucks: 'openbucks',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-now': 'playstation-now',
  'playstation-now/1-monat': 'playstation-now',
  'playstation-now/3-monate': 'playstation-now',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/12-monate': 'playstation-plus',
  'playstation-plus/3-monate': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/10-eur': 'playstation-store',
  'playstation-store/20-eur': 'playstation-store',
  'playstation-store/50-eur': 'playstation-store',
  'playstation-store/75-eur': 'playstation-store',
  'prepaid-kreditkarten': 'prepaid-credit-cards',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'pubg-us': 'pubg',
  'red-dead-online': 'gaming-cards',
  roblox: 'roblox',
  'roblox/10-eur': 'roblox',
  'roblox/20-eur': 'roblox',
  runescape: 'runescape',
  'runescape/30-tage': 'runescape',
  'runescape/90-tage': 'runescape',
  'shopping-gutscheine': 'shopping-gift-cards',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/10-eur': 'spotify-premium',
  'spotify-premium/30-eur': 'spotify-premium',
  'spotify-premium/60-eur': 'spotify-premium',
  steam: 'steam',
  'steam/10-eur': 'steam',
  'steam/100-eur': 'steam',
  'steam/20-eur': 'steam',
  'steam/25-eur': 'steam',
  'steam/5-eur': 'steam',
  'steam/50-eur': 'steam',
  telering: 'mobile-top-up',
  'ticket-premium': 'ticket-premium',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  twitch: 'twitch',
  'ueber-uns': 'https://company.recharge.com',
  'vectone-mobile': 'vectone-mobile',
  widerrufsrecht: 'how-it-works',
  'wie-es-funktioniert': 'how-it-works',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-days': 'world-of-warcraft',
  wowww: 'wowww',
  'xbox-game-pass': 'xbox-game-pass',
  'xbox-game-pass/1-monat': 'xbox-game-pass',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card/25-eur': 'xbox-live-gold',
  'xbox-live-gold': 'xbox-live-gold',
  yesss: 'yesss',
  yooopi: 'yooopi',
  zahlungsmethoden: 'payment-methods',
  zalando: 'zalando',
  'zalando/10-eur': 'zalando',
  'zalando/15-eur': 'zalando',
  'zalando/20-eur': 'zalando',
  'zalando/25-eur': 'zalando',
  'zalando/50-eur': 'zalando',
  'pokemon-sword-shield': 'gaming-cards',
  'free-fire': 'gaming-cards',
  'apex-legends': 'gaming-cards',
  'prepaid-visa-us': 'prepaid-credit-cards',
  'jeton-cash': 'jeton-cash',
  astropay: 'astropay',
  'prepaid-amex-us': 'prepaid-credit-cards',
}
