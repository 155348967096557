export default {
  'amazon-de': 'amazon',
  'amazon-de/10-eur': 'amazon',
  'amazon-de/100-eur': 'amazon',
  'amazon-de/15-eur': 'amazon',
  'amazon-de/25-eur': 'amazon',
  'amazon-de/50-eur': 'amazon',
  'amazon-se': 'amazon',
  'amazon-se/100-sek': 'amazon/100-sek',
  'amazon-se/200-sek': 'amazon/200-sek',
  'amazon-se/500-sek': 'amazon/500-sek',
  angerrat: 'how-it-works',
  'guild-wars': 'gaming-cards',
  'astropay-eur': 'astropay',
  battlenet: 'blizzard',
  betalningsmetoder: 'payment-methods',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  cdon: 'shopping-gift-cards',
  'cdon/100-sek': 'shopping-gift-cards',
  'cdon/500-sek': 'shopping-gift-cards',
  cookieuttalande: 'cookie-statement',
  'crypto-voucher': 'crypto-voucher',
  ecovoucher: 'ecovoucher',
  'eve-online': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  flexepin: 'flexepin',
  'forbetalda-kreditkort': 'prepaid-credit-cards',
  fortnite: 'gaming-cards',
  'free-fire-us': 'free-fire',
  'hur-det-fungerar': 'how-it-works',
  integritetspolicy: 'privacy-statement',
  'jeton-cash-eur': 'jeton-cash',
  'karma-koin': 'karma-koin',
  'karma-koin/10-eur': 'karma-koin/10-eur',
  'karma-koin/50-eur': 'karma-koin/50-eur',
  kryptovaluta: 'cryptocurrency',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends/10-eur',
  'league-of-legends/20-eur': 'league-of-legends/20-eur',
  'mobil-pafyllning': 'mobile-top-up',
  ncoin: 'gaming-cards',
  'ncoin/5-eur': 'gaming-cards',
  'ncoin/50-eur': 'gaming-cards',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'om-oss': 'https://company.recharge.com',
  'openbucks-us': 'openbucks',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/12-manader': 'playstation-plus/12-months-se',
  'playstation-plus/3-manader': 'playstation-plus/3-months-se',
  'playstation-store': 'playstation-store',
  'playstation-store/200-sek': 'playstation-store/200-sek',
  'playstation-store/400-sek': 'playstation-store/400-sek',
  'prepaid-amex-us': 'prepaid-credit-cards',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'red-dead-online': 'gaming-cards',
  roblox: 'gaming-cards',
  'roblox/50-eur-xbox-one': 'gaming-cards',
  'roblox/6-eur-xbox-one': 'gaming-cards',
  runescape: 'runescape',
  'runescape/30-dagar': 'runescape/30-days',
  'runescape/90-dagar': 'runescape/90-days',
  'shopping-presentkoder': 'shopping-gift-cards',
  'sitemap.xml': 'sitemap.xml',
  soshop: 'soshop',
  spelpresentkoder: 'gaming-cards',
  'spotify-premium': 'spotify-premium',
  steam: 'steam',
  'steam/10-eur': 'steam',
  'steam/100-eur': 'steam',
  'steam/20-eur': 'steam',
  'steam/25-eur': 'steam',
  'steam/5-eur': 'steam',
  'steam/50-eur': 'steam',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  underhallningpresentkoder: 'entertainment-gift-cards',
  'visa-prepaid-us': 'prepaid-credit-cards',
  'world-of-warcraft': 'world-of-warcraft',
  'xbox-live-gold': 'xbox-live-gold',
  zalando: 'zalando',
  'zalando/100-sek': 'zalando',
  'zalando/200-sek': 'zalando/200-sek',
  telenor: 'telenor',
  lycamobile: 'lycamobile',
  telia: 'telia',
  halebop: 'halebop',
  dent: 'mobile-top-up',
  comviq: 'comviq',
  'about-us': 'https://company.recharge.com',
  'cookie-statement': 'cookie-statement',
  cryptocurrency: 'cryptocurrency',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  'gaming-gift-cards': 'gaming-cards',
  'how-it-works': 'how-it-works',
  'mobile-top-up': 'mobile-top-up',
  'payment-methods': 'payment-methods',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'pubg-us': 'pubg',
  'right-of-withdrawal': 'how-it-works',
  'shopping-gift-cards': 'shopping-gift-cards',
  'blog/vad-ar-paysafecard':
    'https://www.recharge.com/blog/en-gb/ie/how-does-paysafecard-work',
}
