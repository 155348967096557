import { GetterTree } from 'vuex'
import { isPresent } from 'ts-is-present'
import { IContenfulState } from './interfaces'
import pick from '~/utils/pick'
import { IExternalLinkModel } from '~/models/base/ExternalLink'
import { IHeader } from '~/models/components/Header'
import { firstOfCollection, collectionToArray } from '~/utils/collection'
import { contentfulToImage } from '~/utils/image'
import isEmpty from '~/utils/isEmpty'

const HELP_URL = 'https://help.recharge.com/hc'

const getters: GetterTree<IContenfulState, IContenfulState> = {
  layout: ({ layout }) => layout,
  header: ({ header }) => header,
  footer: ({ footer }) => footer,
  country: ({ country }, { sharedCountry }, _rootState, rootGetters) => {
    if (rootGetters['context/isLocalMarketplace']) return country
    return sharedCountry
  },
  currencies: ({ country }) => country?.currencies,
  countryAbv: ({ country }) => country?.abv,
  structure: ({ structure }) => structure,
  appPageLayout: ({ app }) => {
    return pick(app, ['sectionAppBannerLanding'])
  },
  blogPageLayout({ blogPage }) {
    return pick(blogPage, ['list', 'sectionBlogLead'])
  },
  blogOverviewPageLayout({ blogOverviewPage }) {
    return blogOverviewPage
  },
  countryList: ({ countryList }) => countryList,
  brandNavLinks: ({ brandNavLinks }) => brandNavLinks,
  paymentMethods: ({ paymentMethods }) => paymentMethods,
  products: ({ products }) => products,
  product: ({ product }, _getters, _state, rootGetters) => {
    return rootGetters['context/useRecommerce']
      ? rootGetters['recommerce/variant']
      : product
  },
  metaLinks: ({ metaLinks }) => metaLinks,
  metaTags: ({ metaTags }) => metaTags,
  metaTitle: ({ metaTitle }) => metaTitle,
  structuredData: ({ structuredData }) => structuredData,
  pageTitle: ({ pageTitle }) => pageTitle,
  helpLink: (
    { header },
    { sharedHeader },
    _state,
    rootGetters
  ): IExternalLinkModel => {
    const locale = rootGetters['context/locale']
    const lang = rootGetters['context/lang'].toLowerCase()
    const link = sharedHeader.legalLink || {
      url: `${HELP_URL}/${lang}`,
    }
    return {
      ...(link as IExternalLinkModel),
      url: `${link?.url}?locale=${locale}`,
    }
  },
  parentPageInfo: ({ parentPageInfo }) => parentPageInfo,
  aboutUsUrl: ({ aboutUsUrl }) => aboutUsUrl,
  pathTranslations: ({ pathTranslations }): Record<string, string> =>
    pathTranslations,
  paymentMethodsPage: ({ paymentMethodsPage }) => paymentMethodsPage,
  cachedSharedData: ({ cachedSharedData }) => cachedSharedData,
  sharedHeader: ({ sharedData }) => {
    if (isEmpty(sharedData)) return {}
    const header = firstOfCollection(sharedData.headerCollection)
    const menu = header?.menuCollection
      ? collectionToArray(header.menuCollection)
      : []
    const usps = header?.uspsCollection
      ? collectionToArray(header.uspsCollection)
      : []
    return { menu, usps, legalLink: header?.legalLink }
  },
  sharedCountry: ({ sharedData }, _getters, _state, rootGetters) => {
    if (!sharedData?.countryCollection) return {}
    const country = firstOfCollection(sharedData.countryCollection)
    // LMP components legacy
    const currencies = rootGetters['context/currencies']
    return { ...country, currencies }
  },
  sharedMerchant: ({ sharedData }) => {
    if (!sharedData?.merchantCollection) return {}
    return firstOfCollection(sharedData.merchantCollection)
  },
  sharedFooter: ({ sharedData }) => {
    if (!sharedData) return {}
    return firstOfCollection(sharedData.footerCollection)
  },
  sharedPaymentMethods: (_state, { sharedCountry }) => {
    if (!sharedCountry) return []
    return collectionToArray(sharedCountry.paymentMethodsCollection)
      .filter(isPresent)
      .map(paymentMethod => ({
        icon: contentfulToImage(paymentMethod.icon),
        name: paymentMethod.name,
        topupId: paymentMethod.topupId,
      }))
  },
  sharedGlobalPaymentMethods: ({ sharedData }) => {
    if (!sharedData) return []
    return collectionToArray(sharedData.paymentMethodCollection)
      .filter(isPresent)
      .map(({ icon, name, topupId }) => ({
        icon: icon ? contentfulToImage(icon) : null,
        name,
        topupId,
      }))
  },
  sharedMenu: (_state, { sharedHeader }) => {
    if (!sharedHeader) return []
    return sharedHeader.menu.flatMap(menuItem => {
      const { slug } = menuItem.kindPage || {}
      const { url, newTab } = menuItem.externalLink || {}
      return [
        {
          ...menuItem,
          iconType: menuItem.icon,
          categoryLink: { slug },
          slug,
          newTab,
          url,
        },
      ]
    })
  },
  sharedIndexedLanguages: (_state, { sharedCountry }) =>
    sharedCountry?.seo?.canonicalLanguages || [],
}

export default getters
