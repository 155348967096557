export default {
  'about-us': 'https://company.recharge.com',
  'amazon-de': 'amazon',
  'amazon-de/10-eur': 'amazon',
  'amazon-de/15-eur': 'amazon',
  'amazon-de/25-eur': 'amazon',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  'battlenet/50-eur': 'blizzard',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  'cashu/': 'prepaid-credit-cards',
  'cookie-statement': 'cookie-statement',
  'crypto-voucher': 'crypto-voucher',
  cryptocurrency: 'cryptocurrency',
  'ea-origin': 'ea-origin',
  ecovoucher: 'ecovoucher',
  'ecovoucher/': 'ecovoucher',
  entertainment: 'entertainment-gift-cards',
  'fifa-points': 'gaming-cards',
  'fifa-points/1050-xbox-one': 'gaming-cards',
  'fifa-points/1600-xbox-one': 'gaming-cards',
  'fifa-points/2200-xbox-one': 'gaming-cards',
  flexepin: 'flexepin',
  'free-fire': 'free-fire',
  'gaming-gift-cards': 'gaming-cards',
  'how-it-works': 'how-it-works',
  'karma-koin': 'karma-koin',
  'league-of-legends': 'league-of-legends',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin',
  'ncoin/100-eur': 'ncoin',
  'ncoin/20-eur': 'ncoin',
  'ncoin/5-eur': 'ncoin',
  'ncoin/50-eur': 'ncoin',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/12-months': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop',
  'nintendo-eshop/25-eur': 'nintendo-eshop',
  'nintendo-eshop/3-months': 'nintendo-eshop',
  'nintendo-switch-online': 'nintendo-switch-online',
  'payment-methods': 'payment-methods',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/10-eur': 'playstation-store',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'pubg-us': 'pubg',
  'pubg-us/660-uc': 'pubg',
  'red-dead-online': 'gaming-cards',
  'red-dead-online/150-gold-bars-xbox-one': 'gaming-cards',
  'right-of-withdrawal': 'how-it-works',
  roblox: 'roblox',
  runescape: 'runescape',
  'shopping-gift-cards': 'shopping-gift-cards',
  soshop: 'soshop',
  steam: 'steam',
  'steam/10-eur': 'steam',
  'steam/100-eur': 'steam',
  'steam/20-eur': 'steam',
  'steam/25-eur': 'steam',
  'steam/5-eur': 'steam',
  'steam/50-eur': 'steam',
  'ticket-premium': 'ticket-premium',
  transcash: 'transcash',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'apex-legends': 'gaming-cards',
  'toneo-first': 'toneo-first',
  'openbucks-us': 'openbucks-us',
  'prepaid-visa-us': 'prepaid-credit-cards',
  'jeton-cash': 'jeton-cash',
  astropay: 'astropay',
  'prepaid-amex-us': 'prepaid-credit-cards',
}
