import { Context, Middleware } from '@nuxt/types'
import localeLabelConfig from '../../config/i18n/config/label.json'

const label = process.env.LABEL || 'recharge'
const config = localeLabelConfig[label] || {}

const checkIfRouteLocaleExists = (route): boolean => {
  const { languages: locales } = config
  const routerToLocale = route.params.locale
  const pattern = /^(\w|\.|-)+$/

  return locales.includes(routerToLocale) && pattern.test(routerToLocale)
}

const checkForInvalidLocalesMiddleware: Middleware = (
  context: Context
): void => {
  if (!process.server) return

  const { error, route } = context

  if (!route.name) {
    return
  }

  if (!checkIfRouteLocaleExists(route)) {
    error({
      statusCode: 404,
      message: 'Page not found',
    })
  }
}

export default checkForInvalidLocalesMiddleware
