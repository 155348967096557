import { BLOCKS } from '@contentful/rich-text-types'
import get from '~/utils/get'
export default {
  [BLOCKS.EMBEDDED_ASSET]: (node, key, h, next) => {
    const { contentType, url, details } = get(node, 'data.target.fields.file')
    const { width, height } = details.image
    const acceptedImageMimeTypeReg = /image\/(png|svg\+xml|jpg|jpeg)/g
    const isImage = acceptedImageMimeTypeReg.test(contentType)
    if (!isImage) return undefined
    return h(
      'img',
      {
        key,
        attrs: {
          src: url,
          width,
          height,
        },
      },
      next(node.content)
    )
  },
}
