<template>
  <div>
    <div class="flex flex-row mb-5">
      <h2 class="sm:w-11/12 text-lg leading-5 font-bold text-black">{{
        title
      }}</h2>
      <div class="mt-2 sm:m-0 md:m-10px pl-5">
        <icon
          icon="faq-bubble"
          width="full"
          height="auto"
          :image-dimension="[20, 20]"
        />
      </div>
    </div>
    <div class="sm:block">
      <div v-for="(item, index) in items" :key="item.title">
        <divider />
        <collapsable
          :ref="`faq_item_${index}`"
          :show-initial-content="isShowInitialContent(index)"
          override-classes="px-0"
        >
          <template #header>
            <h3
              class="m-0 font-bold text-primary leading-exceeding lg:leading-relaxed"
            >
              {{ item.title }}
            </h3>
          </template>
          <template #content>
            <rich-text-renderer
              class-names="mb-5 html-injected"
              :document="item.description"
            />
          </template>
        </collapsable>
      </div>
    </div>
  </div>
</template>

<script>
import { Divider, Icon, Collapsable } from '~/components/atoms/'
import RichTextRenderer from '~/components/renderers/rich-text-renderer/index.vue'
export default {
  components: {
    Icon,
    Collapsable,
    RichTextRenderer,
    Divider,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowFirstFaq: false,
    }
  },
  methods: {
    showFirstFaq() {
      this.isShowFirstFaq = true
      const firstFaq = this.$refs?.faq_item_0
      if (firstFaq?.length && firstFaq[0].setShowContent) {
        firstFaq[0].setShowContent(true)
      }
    },
    isShowInitialContent(index) {
      return index === 0 && this.isShowFirstFaq
    },
  },
}
</script>
