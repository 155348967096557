import { TBrandCategory } from '../base/Category'
import { IImageModel } from '~/models/base/Image'
import { IProduct } from '~/models/components/Product'
import { TProduct } from '~/store/products/state'
import { IProductPriceModel } from '~/models/topup/ProductPrice'
import { REDEEM_TYPE } from '~/services/topup/enums'

export interface IQuickBuyProductModel {
  logo?: IImageModel
  title?: string
  productId: string
  price: IProductPriceModel
  parentDenominationType: string
  available: boolean
  productBrand: string
  productCategory: TBrandCategory | ''
  isRTR: boolean
  isCustomDenomination: boolean
  value: number | null
  scamNotificationType?: string
}

export class QuickBuyProductModel {
  protected readonly logo?: IImageModel
  protected readonly title?: string
  protected readonly productId: string
  protected readonly price: IProductPriceModel
  protected readonly parentDenominationType: string
  protected readonly available: boolean
  protected readonly productBrand: string
  protected readonly productCategory: TBrandCategory | ''
  protected readonly isRTR: boolean
  protected readonly value: number | null
  protected readonly isCustomDenomination: boolean
  protected readonly scamNotificationType?: string

  constructor(
    {
      logo,
      title,
      productId,
      productFeedImage,
      brandTitle,
      parentDenominationType,
      brandCategory,
      value,
      isCustomDenomination,
    }: IProduct,
    // eslint-disable-next-line camelcase
    { price, available, redeem_type, scamNotificationType }: TProduct
  ) {
    this.logo = productFeedImage || logo
    this.title = title
    this.productId = `${productId}`
    this.price = price
    this.parentDenominationType = parentDenominationType || ''
    this.available = available
    this.isCustomDenomination = !!isCustomDenomination
    this.productBrand = brandTitle || ''
    this.productCategory = brandCategory || ''
    this.value = value ? parseInt(value, 10) : null
    // eslint-disable-next-line camelcase
    this.isRTR = redeem_type === REDEEM_TYPE.RTR
    this.scamNotificationType = scamNotificationType
  }

  toJSON(): IQuickBuyProductModel {
    return {
      logo: this.logo,
      title: this.title,
      productId: this.productId,
      price: this.price,
      parentDenominationType: this.parentDenominationType,
      available: this.available,
      isCustomDenomination: this.isCustomDenomination,
      productBrand: this.productBrand,
      productCategory: this.productCategory,
      isRTR: this.isRTR,
      value: this.value,
      scamNotificationType: this.scamNotificationType,
    }
  }
}
