import { ORDER_STATUS } from '~/models/components/Order'

export enum ORDER_PAGE {
  SHIPPED = 'Shipped',
  DISTRIBUTION = 'Distribution',
  REFUNDED = 'Refunded',
  INVESTIGATION = 'Investigation',
  INVESTIGATED = 'Investigated',
  PENDING = 'Pending',
}

export const pagesByOrderStatus = {
  [ORDER_STATUS.DISTRIBUTION]: ORDER_PAGE.DISTRIBUTION,
  [ORDER_STATUS.PAID]: ORDER_PAGE.PENDING,
  [ORDER_STATUS.UNPAID]: ORDER_PAGE.PENDING,
  [ORDER_STATUS.INVESTIGATED]: ORDER_PAGE.INVESTIGATED,
  [ORDER_STATUS.INVESTIGATION]: ORDER_PAGE.INVESTIGATION,
  [ORDER_STATUS.REFUNDED]: ORDER_PAGE.REFUNDED,
  [ORDER_STATUS.SHIPPED]: ORDER_PAGE.SHIPPED,
}
