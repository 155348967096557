export const fontIconTypes = [
  'account',
  'arrow',
  'breadcrumb',
  'certified-empty',
  'certified',
  'check-circle',
  'check',
  'close',
  'cryptocurrency',
  'down',
  'edit',
  'entertainment',
  'gamecards-copy',
  'gamecards',
  'giftcards',
  'hamburger',
  'homepage',
  'international',
  'lock',
  'mail',
  'min',
  'mobile-recharge',
  'mobile-recharge',
  'plus',
  'popular',
  'prepaid-cards',
  'privacy',
  'search',
  'warning-stroke',
  'warning',
]

export const svgTypes = [
  'loader',
  'hole',
  'error-payment-general',
  'error-payment-method',
  'error-payment-connection',
  'email',
]

export const sizes = {
  small: 8,
  base: 16,
  medium: 24,
  large: 36,
}

export const colors = {
  link: '#438FDF',
  white: '#FFF',
  black: '#000',
  success: '#00e070',
  primary: '#00994c',
  gray: '#abb9ba',
  error: '#FF5A64',
}

export const sizeTypes = Object.keys(sizes).concat('original')
