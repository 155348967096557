import { Context, Middleware } from '@nuxt/types'
import { Route } from 'vue-router'

const CHECKOUT_ERROR_TYPES = [
  'error_001',
  'error_002',
  'error_003',
  'error_004',
  'error_005',
  'Unknown',
]

const isOrderErrorRoute = (route: Route) => {
  return route.name === 'order-error'
}

const isCheckoutErrorRoute = (route: Route) => {
  return route.name === 'checkout-error-gmp' || route.name === 'checkout-error'
}

const checkoutErrorRedirectionMiddleware: Middleware = ({
  redirect,
  route,
}: Context): void => {
  const locale = route.path?.match(
    /^(\/\w{2}-\w{2}\/\w{2}\/)|(\/\w{2}-\w{2}\/)|(\/\w{2}\/\w{2}\/)/
  )

  if (
    locale?.length &&
    route.params.errorType &&
    !CHECKOUT_ERROR_TYPES.includes(route.params.errorType)
  ) {
    redirect(302, `${locale[0]}checkout/error/page`)
  }

  if (
    locale?.length &&
    isCheckoutErrorRoute(route) &&
    route.params.errorType === undefined
  ) {
    const { orderId, hash } = route.params

    let { paymentMethod } = route.params

    if (paymentMethod === undefined) {
      paymentMethod = 'Unknown'
    }

    const errorType = 'Unknown'

    redirect(
      302,
      `${locale[0]}checkout/error/${orderId}/${hash}/${paymentMethod}/${errorType}`
    )
  }

  if (
    locale?.length &&
    isOrderErrorRoute(route) &&
    CHECKOUT_ERROR_TYPES.includes(route.params.errorType)
  ) {
    const { orderId, hash, errorType, paymentMethod } = route.params
    redirect(
      302,
      `${locale[0]}checkout/error/${orderId}/${hash}/${paymentMethod}/${errorType}`
    )
  }
}

export default checkoutErrorRedirectionMiddleware
