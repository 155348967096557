import { GetterTree } from 'vuex'
import { IProductsStoreState, TProduct } from './state'
import { IQuickBuyProductModel } from '~/models/components/QuickBuyProduct'

const getters: GetterTree<IProductsStoreState, IProductsStoreState> = {
  all: ({ byId }): Record<string, TProduct> => {
    return byId
  },
  byId: ({ byId }) => (id: string | number): TProduct => byId[id],
  someProductsHaveRTRRedeemType: ({ someProductsHaveRTRRedeemType }): boolean =>
    someProductsHaveRTRRedeemType,
  quickBuyProductIsLoading: ({
    asyncQuickBuyProduct,
  }: IProductsStoreState): boolean => asyncQuickBuyProduct.loading,
  quickBuyProductHasFailed: ({ asyncQuickBuyProduct }): boolean =>
    asyncQuickBuyProduct.failed,
  quickBuyProduct: ({
    asyncQuickBuyProduct: { available, quickBuyProduct },
  }: IProductsStoreState): IQuickBuyProductModel | undefined =>
    available ? quickBuyProduct : undefined,
  showQuickBuyProductCard: (
    { asyncQuickBuyProduct: { available } },
    { quickBuyProductHasFailed, quickBuyProductIsLoading },
    _rootState,
    rootGetters
  ) => {
    const lastProductSeenId = rootGetters['context/lastProductSeenId']
    return (
      lastProductSeenId &&
      !quickBuyProductHasFailed &&
      !quickBuyProductIsLoading &&
      available
    )
  },
  pageViewEventLogged: ({ pageViewEventLogged }): boolean =>
    pageViewEventLogged,
}

export default getters
