<script>
import VueTypes from 'vue-types'
import { parse } from 'node-html-parser'
import defaultComponents from './components/default-components'
import defaultMarks from './components/default-marks'
import embeddedComponents from './components/embedded-components'
import nodeRenderer from './node-renderer'
import HtmlRenderer from './components/html-renderer'

export default {
  name: 'RichTextRenderer',
  functional: true,

  props: {
    document: VueTypes.oneOfType([Object, String]).def(''),
    classNames: VueTypes.string.def(''),
    parentComponent: VueTypes.string.def(''),
  },

  render(createElement, { props }) {
    const idPrefix = 'richText-'
    const { document, classNames, parentComponent } = props

    if (typeof document === 'string') {
      const documentRoot = parse(document)
      const documentLinks = documentRoot.getElementsByTagName('a')
      documentLinks.forEach(link => {
        if (
          link.hasAttribute('href') &&
          !link.hasAttribute('target') &&
          !link.getAttribute('href').includes('recharge.com')
        ) {
          link.setAttribute('target', '_blank')
        }
      })
      return createElement(HtmlRenderer, {
        class: `relative w-full ${classNames}`,
        props: {
          content: documentRoot.toString(),
        },
        attrs: {
          'data-parent-component': parentComponent,
        },
      })
    }

    const { json: { content: json } = {}, content: legacy, links } = document
    const content = json || legacy

    const rendererObj = {
      node: {
        ...defaultComponents,
        ...embeddedComponents,
      },
      mark: {
        ...defaultMarks,
      },
      links,
    }
    if (!content) return null
    return createElement(
      'div',
      {
        class: `relative w-full ${classNames}`,
        attrs: {
          'data-parent-component': parentComponent,
        },
      },
      nodeRenderer.renderNodeList(content, idPrefix, {
        ...rendererObj,
        createElement,
      })
    )
  },
}
</script>
<style scoped>
/**
  Dev-Note: For below styles to be applied correctly, any parent component/wrapper
  over this component, should be exported with 'hydrateWhenVisible'. 
  Otherwise you will be banging your head for 2 days why does this not work.
 */
[data-parent-component='freeFormat'] p,
[data-parent-component='info-card'] p {
  @apply mb-4 break-words;
}
[data-parent-component='freeFormat'] h2 {
  @apply mb-4;
}

[data-parent-component='freeFormat'] h3,
[data-parent-component='freeFormat'] h4,
[data-parent-component='freeFormat'] h5 {
  @apply mb-2;
}
div >>> h1 {
  @apply text-xl font-bold leading-snug text-primary;
}

div >>> h2 {
  @apply text-2lg  font-bold  leading-relaxed text-primary;
}

div >>> h3 {
  @apply text-lg font-bold leading-exceeding text-primary;
}

div >>> h4 {
  @apply text-base leading-normal text-primary;
}

div >>> h5 {
  @apply text-xs leading-relaxed text-primary;
}

div >>> ul {
  @apply list-disc ms-5 py-2;
}

div >>> ol {
  @apply list-decimal ms-5 py-2;
}

div >>> blockquote p::before {
  font-size: 1rem;
  content: '\201C';
}

div >>> blockquote p::after {
  font-size: 1rem;
  content: '\201D';
}

div >>> a {
  @apply leading-normal no-underline text-base text-link inline-block;
}

div >>> a:hover {
  @apply underline;
}

div >>> hr {
  @apply h-px w-full bg-gray m-0 border-0 my-4;
}

@screen lg {
  div >>> h1 {
    @apply text-4xl leading-big;
  }

  div >>> h2 {
    @apply text-xl leading-loose;
  }

  div >>> h3 {
    @apply text-2lg leading-relaxed;
  }

  div >>> h4 {
    @apply text-lg;
  }
}

[data-parent-component='TnC'] >>> a {
  @apply inline;
}
</style>
