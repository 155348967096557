import { Api } from '../apis/clients/rest/account/api'
import { ISentry } from '~/types/store'
import { AuthPlugin } from '~/plugins/auth'

export async function fetchReferralCode(
  $auth: AuthPlugin,
  $sentry: ISentry
): Promise<string | null> {
  let referralCode
  try {
    const { data } = await $auth.accountClient.referralCode.get()
    referralCode = data.referral_code
  } catch (e) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'acceptance'
    )
      console.error(e)
    $sentry.captureException(e)
  }
  return referralCode
}

export async function checkRafCode(
  $auth: AuthPlugin,
  $sentry: ISentry,
  code: string
): Promise<boolean> {
  let validCode = false
  try {
    const { data } = await $auth.accountClient.referralCode.getByCode(code)
    validCode = !!data.referral_code
  } catch (e) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'acceptance'
    )
      console.error(e)
    $sentry.captureException(e)
    throw e
  }

  return validCode
}

export async function fetchRafAvailability(
  $accountClient: Api<unknown>,
  $sentry: ISentry,
  countries: string
): Promise<boolean> {
  try {
    const { data } = await $accountClient.wallet.walletAvailability({
      countries,
    })

    return data.enabled
  } catch (e) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'acceptance'
    )
      console.error(e)

    $sentry.captureException(e)

    return false
  }
}
