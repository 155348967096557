import { createClient } from 'contentful'

// use default environment config for convenience
// these will be set via `env` property in nuxt.config.js

const config = {
  space: process.env.CONTENTFUL_SPACE_KEY,
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
}

export default (_, inject) => {
  inject('cmsClient', createClient(config))
}
