export default async function handlePromiseAllErrors(
  promises: Promise<void>[],
  error: (...params) => boolean
): Promise<void> {
  try {
    await Promise.all(promises)
  } catch (e) {
    error(e)
  }
}
