export default {
  paymentMethods: [
    {
      id: 'Buckaroo_Instant_Ideal',
      label: 'iDEAL',
      image: '',
      disabled: false,
      transactionCost: {
        value: 0,
        currency: 'EUR',
        __typename: 'Money',
      },
      issuers: [
        {
          id: 'INGBNL2A',
          label: 'ING Bank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'ABNANL2A',
          label: 'ABN Amro Bank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'RABONL2U',
          label: 'Rabobank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'SNSBNL2A',
          label: 'SNS Bank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'ASNBNL21',
          label: 'ASN Bank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'TRIONL2U',
          label: 'Triodos Bank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'RBRBNL21',
          label: 'Regio Bank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'KNABNL2H',
          label: 'KNAB Bank',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'FVLBNL22',
          label: 'van Lanschot Bankiers',
          __typename: 'PaymentMethodIssuer',
        },
        {
          id: 'BUNQNL2A',
          label: 'Bunq',
          __typename: 'PaymentMethodIssuer',
        },
      ],
      paymentInformationFields: [],
      totalPrice: undefined,
      __typename: 'PaymentMethod',
    },
    {
      id: 'Cg_Test',
      label: 'Test',
      image: '',
      disabled: false,
      transactionCost: {
        value: 0,
        currency: 'EUR',
        __typename: 'Money',
      },
      issuers: [],
      paymentInformationFields: [],
      totalPrice: undefined,
      __typename: 'PaymentMethod',
    },
    {
      id: 'Paypal_Expresscheckout',
      label: 'PayPal',
      image: '',
      disabled: false,
      transactionCost: {
        value: 0,
        currency: 'EUR',
        __typename: 'Money',
      },
      issuers: [],
      paymentInformationFields: [],
      __typename: 'PaymentMethod',
    },
    {
      id: 'Adyen_Visa',
      label: 'Visa',
      image: '',
      disabled: false,
      transactionCost: {
        value: 0,
        currency: 'EUR',
        __typename: 'Money',
      },
      issuers: [],
      paymentInformationFields: [],
      totalPrice: undefined,
      __typename: 'PaymentMethod',
    },
    {
      id: 'Telserv_Ivr',
      label: 'Telefoon/Beltegoed',
      image: '',
      disabled: false,
      transactionCost: {
        value: 518,
        currency: 'EUR',
        __typename: 'Money',
      },
      issuers: [],
      paymentInformationFields: [],
      totalPrice: undefined,
      __typename: 'PaymentMethod',
    },
    {
      id: 'Adyen_Mastercard',
      label: 'Mastercard',
      image: '',
      disabled: true,
      transactionCost: null,
      issuers: null,
      paymentInformationFields: null,
      totalPrice: undefined,
      __typename: 'PaymentMethod',
    },
    {
      id: 'Buckaroo_Instant_Banktransfer',
      label: 'Bankoverschrijving',
      image: '',
      disabled: true,
      transactionCost: null,
      issuers: null,
      paymentInformationFields: null,
      totalPrice: undefined,
      __typename: 'PaymentMethod',
    },
  ],
}
