import { EntryModel } from '../base/Entry'

export type TType = 'info' | 'error' | 'warning' | 'success'
export type TIconType = 'certified' | 'check'

export interface IInfoBadge {
  text?: string
  type: TType
  iconType: TIconType
}

export class InfoBadgeModel extends EntryModel {
  text: string
  iconType: TIconType
  type: TType

  constructor(model) {
    super(model)
    const {
      fields: { text, iconType, type },
    } = model
    this.text = text
    this.iconType = iconType
    this.type = type
  }

  toJSON(): IInfoBadge {
    return {
      text: this.text,
      iconType: this.iconType,
      type: this.type,
    }
  }
}
