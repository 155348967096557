import { Maybe } from '~/apis/clients/graphql/types/contentful'

export type TContentfulCountryLang = {
  abv: Maybe<string>
  name: Maybe<string>
  langs?: Maybe<Maybe<string>[]>
  defaultLang: Maybe<string>
  disableLanguages?: Maybe<Maybe<string>[]>
}

export type TCountryLangItem = {
  lang: string
  displayName: string
  abv: string
  locale: string
  name: string
}

export type TCountryLangList = {
  countries: TCountryLangItem[]
}

export function normalizeCountryList(
  countries: TContentfulCountryLang[]
): TCountryLangList {
  const list = countries.reduce(
    (acc, { abv, name, langs, disableLanguages }) => {
      const formattedLangs = (langs || []).reduce((accumulator, language) => {
        if (disableLanguages && disableLanguages.includes(language))
          return accumulator
        const newCountryLang = {
          lang: language || '',
          name: name || '',
          abv: abv || '',
          displayName: `${name} (${language?.toUpperCase()})`,
          locale: `${language}-${abv}`.toLowerCase(),
        }
        return [...accumulator, newCountryLang]
      }, [])

      return [...acc, ...formattedLangs]
    },
    []
  )

  return {
    countries: list,
  }
}
