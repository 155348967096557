<template>
  <p v-if="price" class="text-xs text-gray-dark">
    {{ formattedPrice }}
  </p>
</template>

<script>
import VueTypes from 'vue-types'

export default {
  props: {
    cost: VueTypes.shape({
      value: VueTypes.number.isRequired,
      currency: VueTypes.string.isRequired,
    }).loose,
  },
  computed: {
    price() {
      const { cost = {} } = this
      // TODO: It should come formatted from the API
      const price = cost.value * 0.01
      return price === 0 ? null : price
    },
    formattedPrice() {
      return this.$n(this.price, 'currency')
    },
  },
}
</script>
