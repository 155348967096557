<template>
  <container class="md:w-3/4 lg:w-1/2 xl:w-5/12">
    <column class="w-full">
      <row class="justify-center my-5">
        <img
          class="w-full h-auto -ms-12 responsive-icon"
          src="@/assets/images/arrow.svg"
        />
        <div
          class="absolute top-0 start-0 h-full w-full flex items-center justify-center"
        >
          <img
            class="full h-16 responsive-icon"
            src="@/assets/images/error-card.png"
          />
        </div>
      </row>
    </column>
    <column class="w-full">
      <row class="justify-center">
        <ui-title class="mb-5 text-center" data-test="order-status-title">
          {{ $t('organisms.order_error_info.title') }}
        </ui-title>
      </row>
      <row>
        <p class="w-full text-center mb-4">
          {{ $t(`organisms.order_error_info.messages.${errorType}`) }}
        </p>
      </row>
    </column>
    <column class="w-full my-4">
      <row wrap>
        <column class="w-full md:w-1/2">
          <ui-button
            class="mb-4 md:me-4"
            :url="checkoutErrorUrl"
            data-test="other-payment-method-button"
          >
            {{ $t('organisms.order_error_info.button.use_different_payment') }}
          </ui-button>
        </column>
        <column class="w-full md:w-1/2">
          <ui-button type="secondary" @click="handleClick">
            {{ $t('general.contact_us') }}
          </ui-button>
        </column>
      </row>
    </column>
  </container>
</template>

<script>
import VueTypes from 'vue-types'
import { CgPayError } from '~/apis/clients/cg-pay/error'
import { Container, Row, Column } from '~/components/grid'
import { UiTitle, UiButton } from '~/components/atoms'

export default {
  components: {
    Container,
    Row,
    Column,
    UiTitle,
    UiButton,
  },
  props: {
    zendeskLink: VueTypes.object.def({}),
  },
  computed: {
    checkoutErrorUrl() {
      const {
        params: { orderId, hash },
        query: { p, productId },
      } = this.$route
      const query = p && productId ? `?productId=${productId}&p=${p}` : ''
      return `/checkout/error/${orderId}/${hash}${query}`
    },
    errorType() {
      const error = new CgPayError(this.$route)
      const { message } = error

      if (message === 'error_not_provided') {
        return 'unknown'
      }

      return message
    },
  },
  methods: {
    handleClick() {
      const { newTab, url } = this.zendeskLink
      const tab = newTab ? '_blank' : '_self'
      return window.open(url, tab)
    },
  },
}
</script>
