import { TYPES } from './mutation-types'
import { IUserState } from './state'
import { Commit, mutationTree } from '~/store/types'

const { SET_ATTRIBUTE, CLEAR_USER, SET_RAF_ELIGIBILITY } = TYPES
const mutations = mutationTree<IUserState>()({
  [SET_ATTRIBUTE](
    state,
    { name, value }: { name: string; value: string }
  ): void {
    if (!state.user) {
      return
    }
    state.user[name] = value
  },
  [CLEAR_USER](state): void {
    // eslint-disable-next-line guard-for-in
    for (const userKey in state.user) {
      state.user[userKey] = ''
    }
  },
  [SET_RAF_ELIGIBILITY](state, payload): void {
    state.rafEligible = payload
  },
})

export type UserCommit = Commit<typeof mutations>

export default mutations
