<template>
  <row class="flex-col mb-4">
    <column class="w-full">
      <main-title class="pb-0">
        {{ $t('organisms.checkout_header.title') }}
      </main-title>
    </column>
  </row>
</template>

<script>
import { MainTitle } from '~/components/molecules'
import { Row, Column } from '~/components/grid'

export default {
  components: {
    MainTitle,
    Row,
    Column,
  },
}
</script>
