var render = function render(_c,_vm){return _c('div',_vm._b({class:[
    _vm.data.staticClass,
    _vm.data.class,
    `flex flex-col first:ps-1 last:pe-1 sm:first:ps-2 sm:last:pe-2 xl:first:ps-3 xl:last:pe-3 relative ${
      _vm.props.withoutPadding ? '' : ' px-1 sm:px-2 xl:px-3'
    }`,
  ]},'div',_vm.data.attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }