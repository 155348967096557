export default {
  'about-us': 'https://company.recharge.com/',
  'africa-call': 'mobile-top-up',
  'africa-call/2-50-cad': 'mobile-top-up',
  'africa-call/5-cad': 'mobile-top-up',
  'africa-sky': 'mobile-top-up',
  'africa-sky/2-50-cad': 'mobile-top-up',
  'africa-sky/5-cad': 'mobile-top-up',
  amazon: 'amazon',
  'amazon-us': 'amazon',
  'amazon-us/100-usd': 'amazon',
  'amazon-us/25-usd': 'amazon',
  'amazon-us/50-usd': 'amazon',
  'amazon/10-cad': 'amazon',
  'amazon/100-cad': 'amazon',
  'amazon/20-cad': 'amazon',
  'amazon/25-cad': 'amazon',
  'amazon/50-cad': 'amazon',
  'arabia-sky': 'mobile-top-up',
  'arabia-sky/2-50-cad': 'mobile-top-up',
  'arabia-sky/5-cad': 'mobile-top-up',
  'asia-sky': 'mobile-top-up',
  'asia-sky/5-cad': 'mobile-top-up',
  'astropay-us': 'astropay',
  'bell-mobile': 'mobile-top-up',
  'chatr-mobile': 'chatr-mobile',
  cici: 'cici',
  'cici/10-cad': 'cici',
  'cici/2-50-cad': 'cici',
  'cici/20-cad': 'cici',
  'cici/5-cad': 'cici',
  'cookie-statement': 'cookie-statement',
  dice: 'mobile-top-up',
  'dice/2-50-cad': 'mobile-top-up',
  'dice/5-cad': 'mobile-top-up',
  ebay: 'ebay',
  'ebay/100-cad': 'ebay',
  'ebay/15-cad': 'ebay',
  'ebay/25-cad': 'ebay',
  'ebay/50-cad': 'ebay',
  'ecovoucher-eur': 'ecovoucher',
  'ecovoucher-eur/10-eur': 'ecovoucher',
  'ecovoucher-eur/15-eur': 'ecovoucher',
  'ecovoucher-eur/25-eur': 'ecovoucher',
  'ecovoucher-eur/50-eur': 'ecovoucher',
  'ecovoucher-eur/75-eur': 'ecovoucher',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  fido: 'mobile-top-up',
  'fido/10-cad': 'mobile-top-up',
  'fido/20-cad': 'mobile-top-up',
  'fido/30-cad': 'mobile-top-up',
  'fido/50-cad': 'mobile-top-up',
  flexepin: 'flexepin',
  'flexepin/100-cad': 'flexepin',
  'fortnite-us': 'fortnite',
  'fortnite-us/100-usd': 'fortnite',
  'fortnite-us/25-usd': 'fortnite',
  'fortnite-us/40-usd': 'fortnite',
  'freedom-mobile': 'freedom-mobile',
  'freedom-mobile/10-cad': 'freedom-mobile',
  'freedom-mobile/3-cad': 'freedom-mobile',
  'freedom-mobile/38-cad': 'freedom-mobile',
  'freedom-mobile/45-cad': 'freedom-mobile',
  'freedom-mobile/48-cad': 'freedom-mobile',
  'freedom-mobile/5-cad': 'freedom-mobile',
  'freedom-mobile/50-cad': 'freedom-mobile',
  'gaming-gift-cards': 'gaming-cards',
  globoflat: 'mobile-top-up',
  'globoflat/10-cad': 'mobile-top-up',
  'globoflat/2-50-cad': 'mobile-top-up',
  'globoflat/20-cad': 'mobile-top-up',
  'good-call': 'mobile-top-up',
  'how-it-works': 'how-it-works',
  'hudsons-bay': 'shopping-gift-cards',
  'hudsons-bay/10-cad': 'shopping-gift-cards',
  'hudsons-bay/20-cad': 'shopping-gift-cards',
  'hudsons-bay/25-cad': 'shopping-gift-cards',
  'hudsons-bay/50-cad': 'shopping-gift-cards',
  'jeton-cash-eur': 'jeton-cash',
  'jeton-cash-eur/10-eur': 'jeton-cash',
  'jeton-cash-eur/25-eur': 'jeton-cash',
  'jeton-cash-eur/5-eur': 'jeton-cash',
  'jeton-cash-eur/50-eur': 'jeton-cash',
  'karma-koin': 'karma-koin',
  'koodo-mobile': 'koodo-mobile',
  'lucky-mobile': 'lucky-mobile',
  'lucky-mobile/15-cad': 'lucky-mobile',
  'lucky-mobile/20-cad': 'lucky-mobile',
  'lucky-mobile/25-cad': 'lucky-mobile',
  'lucky-mobile/50-cad': 'lucky-mobile',
  'mobile-recharge': 'mobile-top-up',
  'mts-wireless': 'mts-wireless',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/20-cad': 'nintendo-eshop',
  'nintendo-eshop/35-cad': 'nintendo-eshop',
  'nintendo-eshop/50-cad': 'nintendo-eshop',
  'payment-methods': 'payment-methods',
  'pcs-eur': 'pcs',
  'pcs-eur/100-eur': 'pcs',
  'pcs-eur/150-eur': 'pcs',
  'pcs-eur/20-eur': 'pcs',
  'pcs-eur/50-eur': 'pcs',
  'phone-visa': 'mobile-top-up',
  'phone-visa/2-50-cad': 'mobile-top-up',
  'phone-visa/5-cad': 'mobile-top-up',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/10-cad': 'playstation-plus',
  'playstation-plus/100-cad': 'playstation-plus',
  'playstation-plus/50-cad': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'public-mobile': 'public-mobile',
  'public-mobile/10-cad': 'public-mobile',
  'public-mobile/19-cad': 'public-mobile',
  'public-mobile/25-cad': 'public-mobile',
  'public-mobile/30-cad': 'public-mobile',
  'public-mobile/5-cad': 'public-mobile',
  'rakuten-kobo': 'kobo-rakuten',
  'rakuten-kobo/10-cad': 'kobo-rakuten',
  'rakuten-kobo/100-cad': 'kobo-rakuten',
  'rakuten-kobo/25-cad': 'kobo-rakuten',
  'right-of-withdrawal': 'how-it-works',
  'roblox-us': 'roblox',
  'roblox-us/10-usd': 'roblox',
  'rogers-wireless': 'rogers-wireless',
  'rogers-wireless/20-cad': 'rogers-wireless',
  'shopping-gift-cards': 'shopping-gift-cards',
  starbucks: 'starbucks',
  'starbucks/25-cad': 'starbucks',
  'telus-mobile': 'telus',
  'telus-mobile/10-cad': 'telus',
  'telus-mobile/25-cad': 'telus',
  'telus-mobile/50-cad': 'telus',
  'transcash-eur': 'transcash',
  'transcash-eur/100-eur': 'transcash',
  'transcash-eur/20-eur': 'transcash',
  uber: 'uber',
  'uber/15-usd': 'uber',
  'uber/50-cad': 'uber',
  'virgin-mobile': 'virgin-mobile',
  'virgin-mobile/15-cad': 'virgin-mobile',
  'virgin-mobile/25-cad': 'virgin-mobile',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  neosurf: 'neosurf',
  'MTS-wireless': 'mts-wireless',
  'mts-wireless/30-cad': 'mts-wireless',
  'mts-wireless/60-cad': 'mts-wireless',
  'public-mobile/20-cad': 'public-mobile',
  'public-mobile/40-cad': 'public-mobile',
  'public-mobile/45-cad': 'public-mobile',
  'public-mobile/60-cad': 'public-mobile',
  'xbox-gift-card/100-cad': 'xbox-gift-card',
  'xbox-gift-card/15-cad': 'xbox-gift-card',
  'xbox-gift-card/25-cad': 'xbox-gift-card',
  'xbox-gift-card/50-cad': 'xbox-gift-card',
  'mts-wireless/15-cad': 'mts-wireless',
}
