import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { IImageModel, ICMSImageModel, ImageModel } from '../base/Image'
import { DocumentModel } from '../base/Document'
import { Document } from '~/normalizers/base/document'
import { Maybe } from '~/apis/clients/graphql/types/contentful'

export interface IUsps {
  text?: Maybe<Document>
  icon?: Maybe<IImageModel>
}

export interface IUspsBarModel {
  usps: IUsps[]
}

export interface ICMSUsps extends Entry {
  fields: {
    text: Document
    icon?: ICMSImageModel
  }
}

export class UspsModel extends EntryModel {
  protected readonly text: Document
  protected readonly icon?: IImageModel

  constructor(uspsModel: ICMSUsps) {
    super(uspsModel)

    const {
      fields: { icon },
    } = uspsModel
    this.text = new DocumentModel(this.requiredField('text')).toJSON()
    this.icon = icon ? new ImageModel(icon).toJSON() : undefined
  }

  toJSON(): IUsps {
    return {
      text: this.text,
      icon: this.icon,
    }
  }
}
