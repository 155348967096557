import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from './Entry'

export interface IExternalLinkModel {
  url: string
  internalName?: string
  title: string
  newTab: boolean
  rel: string
}

export interface ICMSExternalLinkModel extends Entry {
  fields: {
    url: string
    title: string
    newTab: boolean
    noFollow: boolean
  }
}

export class ExternalLinkModel extends EntryModel {
  protected readonly url: string
  protected readonly title: string
  protected readonly newTab: boolean
  protected readonly rel: string

  constructor({ fields }: ICMSExternalLinkModel) {
    super({ fields })
    const { url, title, newTab, noFollow } = fields
    this.url = url
    this.title = title
    this.newTab = newTab
    this.rel = noFollow ? 'noFollow' : ''
  }

  toJSON(): IExternalLinkModel {
    return {
      url: this.url,
      title: this.title,
      newTab: this.newTab,
      rel: this.rel,
    }
  }
}
