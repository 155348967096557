import { hydrateWhenVisible, hydrateSsrOnly } from '~/services/lazy-hydration'

export const ProductBanner = () => import('./product-banner')
export const UiFooter = hydrateWhenVisible(() => import('./footer'))
export const CategoryBanner = () => import('./category-banner')
export const StateSelector = hydrateWhenVisible(() =>
  import('./state-selector')
)
export const MobileMenu = hydrateWhenVisible(() => import('./mobile-menu'))
export const UiHeader = hydrateWhenVisible(() => import('./header'))
export const ProductList = hydrateWhenVisible(() => import('./product-list'))
export const BrandList = hydrateWhenVisible(() => import('./brand-list'))
export const SimilarProducts = hydrateWhenVisible(() =>
  import('./similar-products')
)
export const BrandBanner = () => import('./brand-banner')
export const SectionList = () => import('./section-list')
export const AccordionList = hydrateWhenVisible(() =>
  import('./accordion-list')
)
export const UspsBar = hydrateWhenVisible(() => import('./usps-bar'))
export const ReviewList = hydrateWhenVisible(() => import('./review-list'))
export const ActivationInfo = hydrateWhenVisible(() =>
  import('./activation-info')
)
export const LanguageSelector = () => import('./language-selector')
export { default as ContentModal } from './content-modal'
export { default as HeroBanner } from './hero-banner'
export { default as CheckoutInfoBox } from './checkout-info-box'
export { default as CertifiedBadge } from './new-checkout-info-box/certified-badge'
export { default as CheckoutOrderSummary } from './checkout-order-summary'
export { default as CheckoutPaymentMethods } from './checkout-payment-methods'
export { default as CheckoutVerificationCodeForm } from './checkout-verification-code-form'
export { default as PaymentStepsContainer } from './payment-steps-container'
export { default as CheckoutHeader } from './checkout-header'
export { default as CheckoutPaymentForm } from './checkout-payment-form'
export { default as FeaturedCardList } from './featured-card-list'
export { default as PopularBrandList } from './popular-brand-list'
export { default as CategoryList } from './category-list'
export { default as ConfirmationCardList } from './confirmation-card-list'
export { default as ErrorBox } from './error-box'
export { default as ErrorModal } from './error-modal'
export { default as OrderErrorInfo } from './order-error-info'
export { default as CountrySelector } from './country-selector'
export { default as CountryCodeSelector } from './country-code-selector'
export { default as SearchContactOverlay } from './search-contact-overlay'
export { default as IconTooltipModal } from './icon-tooltip-modal'

export const ConfirmationModal = hydrateWhenVisible(() =>
  import('./confirmation-modal')
)

export const EditMyPhoneNumberForm = hydrateWhenVisible(() =>
  import('./edit-my-phone-number-form')
)

export const HydratedCountrySelector = hydrateWhenVisible(() =>
  import('./country-selector')
)

export const NoContacts = hydrateWhenVisible(() => import('./no-contacts'))

export const ContactForm = hydrateWhenVisible(() => import('./contact-form'))

export const ContactsLetterGroup = hydrateWhenVisible(() =>
  import('./contacts-letter-group')
)

export const ContactsOverview = hydrateWhenVisible(() =>
  import('./contacts-overview')
)

export const PhoneNumberInput = hydrateWhenVisible(() =>
  import('./phone-number-input')
)

export const UspsBlock = hydrateSsrOnly(() => import('./usps-block'))
export const PromotionBanner = hydrateWhenVisible(() =>
  import('./promotion-banner')
)
export const AccountHeader = hydrateWhenVisible(() =>
  import('./account-header')
)
export const RefundForm = hydrateWhenVisible(() => import('./refund-form'))
export const SignInForm = hydrateWhenVisible(() => import('./sign-in-form'))
export const RegisterForm = hydrateWhenVisible(() => import('./register-form'))
export const NotificationBar = hydrateWhenVisible(() =>
  import('./notification-bar')
)
export const OrderDetailList = hydrateWhenVisible(() =>
  import('./order-detail-list')
)
export const FreeForm = hydrateWhenVisible(() => import('./free-form'))
export const BreadcrumbsSection = hydrateWhenVisible(() =>
  import('./breadcrumbs-section')
)
export const InfoBoxList = hydrateWhenVisible(() => import('./info-box-list'))

export const H1Title = hydrateSsrOnly(() => import('./h1-title'))

export { default as PhoneNumberModal } from './phone-number-modal'
export const PaymentMethodsBanner = hydrateSsrOnly(() =>
  import('./payment-methods-banner')
)

export const GeolocationRestrictedBlock = hydrateWhenVisible(() =>
  import('./geolocation-restricted-block')
)

export const AccountBanner = hydrateWhenVisible(() =>
  import('./account-banner/index')
)

export const AccountBannerCarousel = hydrateWhenVisible(() =>
  import('./account-banner-carousel/index')
)

export const AccountBannerReorders = hydrateWhenVisible(() =>
  import('./account-banner-reorders/index')
)

export const AccountBannerSkeleton = hydrateWhenVisible(() =>
  import('./account-banner-skeleton/index')
)

export const AccountMenu = hydrateWhenVisible(() =>
  import('./account-menu/index')
)

export const ProfileCards = hydrateWhenVisible(() =>
  import('./profile-cards/index')
)

export const ProfileCard = hydrateWhenVisible(() =>
  import('./profile-card/index')
)

export const EditEmailForm = hydrateWhenVisible(() =>
  import('./profile-email-form/index')
)

export const EditDetailsForm = hydrateWhenVisible(() =>
  import('./personal-details-form/index')
)

export const EditMobileNumberForm = hydrateWhenVisible(() =>
  import('./edit-mobile-number-form/index')
)

export const EditBillingDetailsForm = hydrateWhenVisible(() =>
  import('./edit-billing-details-form')
)

export const NoOrdersList = hydrateWhenVisible(() => import('./no-orders'))

export const OrdersOverview = hydrateWhenVisible(() =>
  import('./orders-overview')
)

export const UpdatePasswordForm = hydrateWhenVisible(() =>
  import('./update-password-form/index')
)
export const ScamModal = hydrateWhenVisible(() => import('./scam-modal'))

export const ForgotPasswordForm = hydrateWhenVisible(() =>
  import('./forgot-password-form/index')
)

export const ResetPasswordForm = hydrateWhenVisible(() =>
  import('./reset-password-form/index')
)

export const RechargeContactButton = hydrateWhenVisible(() =>
  import('./recharge-contact-button/index')
)

export const OrderDetailSection = hydrateWhenVisible(() =>
  import('./order-detail-section')
)

export const OrderDetailsStatusSection = hydrateWhenVisible(() =>
  import('./order-details-status-section')
)

export const OrderDetailsChangePhoneNumberModal = hydrateWhenVisible(() =>
  import('./order-details-change-phone-number-modal')
)

export const AccountRafCardContent = hydrateWhenVisible(() =>
  import('./account-raf-card-content')
)

export const RafHomeBanner = hydrateWhenVisible(() =>
  import('./raf-home-banner')
)

export const RafHowItWorks = hydrateWhenVisible(() =>
  import('./raf-how-it-works')
)

export const RafFaq = hydrateWhenVisible(() => import('./raf-faq'))

export const UspsCarousel = hydrateWhenVisible(() => import('./usps-carousel'))

export const AuthenticationModal = hydrateWhenVisible(() =>
  import('./authentication-modal')
)

export const SearchCountries = hydrateWhenVisible(() =>
  import('./search-countries')
)

export const SearchCarriers = hydrateWhenVisible(() =>
  import('./search-carriers')
)

export { default as Loading } from './loading'

export const BreadcrumbsTrustpilotBar = () =>
  import('./breadcrumbs-trustpilot-bar')

export { default as Faq } from './faq'

export const AccountHeaderMenu = hydrateWhenVisible(() =>
  import('./account-header-menu')
)

export const RafAccountBanner = hydrateWhenVisible(() =>
  import('./raf-account-banner')
)

export const AccountPage = hydrateWhenVisible(() => import('./account-page'))

export const HeaderCarousel = () => import('./header-carousel')

export const WebToAppBanner = hydrateWhenVisible(() =>
  import('./web-to-app-banner')
)

export const BusinessBanner = hydrateWhenVisible(() =>
  import('./business-banner')
)

export const BusinessCarousel = hydrateWhenVisible(() =>
  import('./business-carousel')
)

export const BusinessForm = () => import('./business-form')
