export default {
  'about-us': 'https://company.recharge.com',
  amazon: 'amazon',
  'app-store-itunes': 'app-store-itunes',
  battlenet: 'blizzard',
  bitnovo: 'bitnovo',
  blizzard: 'blizzard',
  cashu: 'prepaid-credit-cards',
  'cookie-statement': 'cookie-statement',
  'crypto-voucher': 'crypto-voucher',
  cryptocurrency: 'cryptocurrency',
  dent: 'mobile-top-up',
  ecovoucher: 'ecovoucher',
  'entertainment-vouchers': 'entertainment-gift-cards',
  flexepin: 'flexepin',
  'free-fire': 'free-fire',
  'gaming-vouchers': 'gaming-cards',
  'google-play': 'google-play',
  'how-it-works': 'how-it-works',
  'karma-koin': 'karma-koin',
  'league-of-legends': 'league-of-legends',
  'mobile-top-up': 'mobile-top-up',
  ncoin: 'ncoin',
  neosurf: 'neosurf',
  netflix: 'netflix',
  o2: 'o2-mobile',
  ee: 'ee-mobile',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-switch-online': 'nintendo-switch-online',
  openbucks: 'openbucks',
  'payment-methods': 'payment-methods',
  paysafecard: 'paysafecard',
  'playstation-now': 'playstation-now',
  'playstation-store': 'playstation-store',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'prepaid-mastercard-eur': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'pubg-us': 'pubg',
  'right-of-withdrawal': 'how-it-works',
  'roblox-gift-card': 'roblox',
  'shopping-gift-cards': 'shopping-gift-cards',
  'spotify-premium': 'spotify-premium',
  steam: 'steam',
  'ticket-premium': 'prepaid-credit-cards',
  uber: 'uber',
  'uber-eats': 'uber',
  'world-of-warcraft': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  zalando: 'zalando',
  giffgaff: 'giffgaff',
  lebara: 'lebara',
  lycamobile: 'lycamobile',
  'now-mobile': 'now-mobile',
  'talk-home-mobile': 'talk-home-mobile',
  'tesco-mobile': 'tesco-mobile',
  'three-mobile': 'three',
  'virgin-mobile': 'virgin-mobile',
}
