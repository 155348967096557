import { IUiMobileMenuState } from './state'
import { MUTATION_TYPES } from './mutation-types'
import { Commit, mutationTree } from '~/store/types'

const mutations = mutationTree<IUiMobileMenuState>()({
  [MUTATION_TYPES.SET_MOBILE_MENU_VISIBILITY](state, visibilityStatus) {
    state.mobileMenuVisibility = visibilityStatus
  },
})

export type MobileMenuCommit = Commit<typeof mutations>

export default mutations
