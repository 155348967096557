export const REGISTER_CONFIRMATION_RECIPIENT_EMAIL = 'recipient_email'
export const VERIFICATION_CODE_RESEND_AT_KEY = 'verification_code_resend'

export const SIGN_IN_REF = 'sign_in_ref'

export const FEDERATED_SIGN_IN_LOCALE = 'federated_sign_in_locale'

// localstorage storage/keys for account
export const ACCOUNT_STORAGE = 'recharge-store-account'

export const RESET_PASSWORD_RESEND_AT_KEY = 'reset_password_resend_at'

export const REFERRAL_STORAGE = 'referral'
export const REFERRED_BY_CODE_KEY = 'referralCode'

// session storage key
export const RESET_PASSWORD_EMAIL_KEY = 'reset_password_email'
