import { GTMEvents, GTMDefaults, PAGE_VERSIONS } from '../enums'
import {
  IECommerceProductDetail,
  ECommerceProductDetail,
} from './ECommerceProductDetail'
import get from '~/utils/get'
import isEmpty from '~/utils/isEmpty'
import isNull from '~/utils/isNull'
import { IOrderModel } from '~/models/components/Order'
import { IProduct } from '~/models/components/Product'
/* eslint-disable camelcase */
export interface IPageView {
  event: string
  app_platform: string
  website_id: string
  page_type: string
  product_category?: string
  product_theme?: string | null
  product_brand?: string | null
  default_currency: string
  ecommerce?: IECommerceProductDetail
  order_delivery_method?: string | null
  page_version?: PAGE_VERSIONS | null
  // LMP
  store_currency?: string
  store_country?: string
  store_language?: string
  // GMP
  product_country?: string
  app_language?: string
  purchase_currency?: string
}

const mapPageTypeToGAType = (pageType?: string | null): string | undefined => {
  if (!pageType) return undefined
  return {
    home: 'home',
    category: 'category',
    product: 'brand',
    legal: 'legal',
    secondary_page: 'secondary_page',
    variant: 'product',
    account: 'account',
    raf: 'refer-a-friend',
    business: 'business',
  }[pageType]
}
/* eslint-enable camelcase */
export class PageViewModel {
  protected pageType: string
  protected productCategory: string
  protected productTheme: string | null = null
  protected productBrand: string | null = null
  protected storeCurrency: string
  protected storeCountry: string
  protected storeLanguage: string
  protected ecommerce?: IECommerceProductDetail
  protected websiteId: string
  protected customerCountry?: { lowerCode: string }
  protected isGlobalMarketplace: boolean
  protected orderDeliveryMethod: string | null = null
  protected pageVersion: PAGE_VERSIONS | null = null

  constructor({
    url = { name: '', path: '', query: { saved: false } },
    store,
    pageType,
    pageViewAttrs,
  }) {
    const isGlobalMarketplace = store.getters['context/isGlobalMarketplace']
    const currency = store.getters['context/currency']
    let lang
    if (isGlobalMarketplace) {
      ;[lang] = store.getters['context/marketplacePrefix'].split('/')
    } else {
      lang = store.getters['context/lang'].toLowerCase()
    }
    const countryAbv = isGlobalMarketplace
      ? store.getters['context/countryCode']
      : store.getters['contentful/countryAbv']
    const pageTitle = store.getters['contentful/pageTitle']
    const parentPageInfo = store.getters['contentful/parentPageInfo']
    const brandInfo =
      pageViewAttrs?.productInfo || store.getters['contentful/brandInfo']
    const parentBrandCategory = get(brandInfo, 'brandCategory')
    const brandTitle = get(brandInfo, 'brandTitle')
    this.websiteId = isGlobalMarketplace
      ? GTMEvents.GMP_WEBSITE_ID
      : GTMEvents.LMP_WEBSITE_ID
    this.isGlobalMarketplace = isGlobalMarketplace
    this.customerCountry = store.getters['context/customerCountry']
    this.orderDeliveryMethod = pageViewAttrs?.orderDeliveryMethod

    const {
      title: parentPageTitle,
      brandCategory,
      pageType: parentPageType,
    } = parentPageInfo

    this.storeCurrency = (currency && currency.abv) || ''
    this.pageType =
      mapPageTypeToGAType(pageType) ||
      store.getters['contentful/pageType'] ||
      null
    this.setContext(url, countryAbv, lang)
    this.setStandalonePageTypes(url)

    this.getPageCategory({
      brandCategory,
      parentBrandCategory,
      pageTitle,
    })

    this.setEcommercePageData({
      pageType: this.pageType,
      pageTitle,
      parentPageTitle,
      store,
      parentPageType,
      brandTitle,
      product: pageViewAttrs?.product,
    })

    this.determineOrderDeliveryMethodPerPageType({
      store,
      pageType: this.pageType,
    })
  }

  setEcommercePageData({
    pageType,
    parentPageType,
    pageTitle,
    parentPageTitle,
    store,
    brandTitle,
    product,
  }): void {
    switch (pageType) {
      case 'brand':
        this.setProductBrand(store, brandTitle)
        break
      case 'theme':
        this.productTheme = pageTitle
        break
      case 'product':
        this.productPageData(parentPageTitle, parentPageType, store, product)
        break
      case 'account':
        this.customProductPageData(parentPageTitle, parentPageType, product)
        break
      default:
    }
  }

  setProductBrand(store, brandTitle): void {
    this.productBrand = brandTitle

    const cmsProducts = store.getters['contentful/products']
    const getTopupProduct = store.getters['products/byId']

    if (isEmpty(cmsProducts)) return

    const { productId } = cmsProducts[0]
    const { brand_name: brandName } = getTopupProduct(productId)
    this.productBrand = brandName
  }

  determineOrderDeliveryMethodPerPageType({ store, pageType }): void {
    if (this.orderDeliveryMethod) return
    switch (pageType) {
      case 'brand':
        this.setProductOrderDeliveryMethodBrandPage(store)
        break
      case 'product':
        this.setProductOrderDeliveryMethodBrandPage(store)
        break
      case 'order':
        this.setProductOrderDeliveryMethodCheckoutPage(store)
        break
      case 'thank_you':
        this.setVariantThankyouPage(store)
        break
      default:
        this.orderDeliveryMethod = null
        break
    }
  }

  setProductOrderDeliveryMethodBrandPage(store) {
    const products = store.getters['products/all'] || []
    const firstProductOfBrandPageOrNull =
      get(store.getters['contentful/products'][0], 'productId') || null

    if (isEmpty(products) || isNull(firstProductOfBrandPageOrNull)) return

    const product = products[firstProductOfBrandPageOrNull]

    if (isEmpty(product)) return

    this.orderDeliveryMethod = !('redeem_type' in product)
      ? null
      : get(product, 'redeem_type')
  }

  setProductOrderDeliveryMethodCheckoutPage(store) {
    const productId = get(store.getters['order/product'], 'productId')
    const products = store.getters['products/all'] || []

    if (isEmpty(products) || !(productId in products)) return

    const product = products[productId]

    this.orderDeliveryMethod = !('redeem_type' in product)
      ? null
      : get(product, 'redeem_type')
  }

  setVariantThankyouPage(store) {
    const variant = store.getters['recommerce/variant'] || null
    this.productBrand = get(variant, 'brandTitle')
    this.productCategory = get(variant, 'baseCategory')
    this.orderDeliveryMethod = get(variant, 'type')
  }

  productPageData(title, parentPageType, store, product): void {
    if (parentPageType === 'theme') {
      this.productTheme = title
    } else if (parentPageType === 'brand') {
      this.productBrand = title
    }
    this.setProductDetail(store, product)
  }

  customProductPageData(title, parentPageType, product): void {
    if (parentPageType === 'theme') {
      this.productTheme = title
    } else if (parentPageType === 'brand') {
      this.productBrand = title
    }
    if (!product) return
    this.setCustomProductDetail(product)
  }

  getPageCategory({ brandCategory, parentBrandCategory, pageTitle }): void {
    const pageTypesWithCategory = ['theme', 'brand', 'product', 'category']
    if (!pageTypesWithCategory.includes(this.pageType)) return
    this.productCategory = brandCategory || parentBrandCategory || pageTitle
  }

  setContext(url, countryAbv, lang): void {
    if (url) {
      this.storeCountry = countryAbv
      this.storeLanguage = lang
    }
  }

  setCustomProductDetail(product): void {
    const {
      id: productId,
      name: title,
      parentDenominationType,
      brandTitle,
      discontinued,
    } = product.recommerceVariant

    const {
      quantity,
      products,
      currency,
      discount_price: discountPrice,
      total_price: totalPrice,
      price: localPrice,
    } = product.orderData

    const price = {
      currency,
      discount: discountPrice,
      localAmount: localPrice,
      subtotal: localPrice,
      total: totalPrice,
    }

    const {
      available,
      brand_name: brandName,
      product_kind: productKind,
    } = products[0]
    const { productBrand, productCategory, productTheme } = this

    this.ecommerce = new ECommerceProductDetail({
      productId,
      title,
      available,
      price,
      brandName,
      productBrand: productBrand || brandTitle || brandName,
      productTheme,
      productCategory,
      parentDenominationType,
      quantity,
      productKind,
      discontinued,
    }).toJSON()
  }

  setProductDetail(store, product = null): void {
    const cmsProducts = product
      ? { foo: product }
      : store.getters['contentful/products']
    const getTopupProduct = store.getters['products/byId']
    const quantity = store.getters['order/productQuantity']

    if (isEmpty(cmsProducts)) return

    const [cmsProduct] = Object.values(cmsProducts)

    const {
      productId = 0,
      title,
      parentDenominationType,
      brandTitle,
    } = cmsProduct as IProduct
    getTopupProduct(productId)

    const {
      available,
      price,
      brand_name: brandName,
      product_kind: productKind,
    } = getTopupProduct(productId)
    const { productBrand, productCategory, productTheme } = this

    this.ecommerce = new ECommerceProductDetail({
      productId,
      title,
      available,
      price,
      brandName,
      productBrand: productBrand || brandTitle || brandName,
      productTheme,
      productCategory,
      parentDenominationType,
      quantity,
      productKind,
    }).toJSON()
  }

  setStandalonePageTypes({ name = '', query = { saved: false } }): void {
    if (!name) return
    const pageTypes = {
      checkout: 'order',
      finished: 'thank_you',
      paymentprogress: 'payment',
    }

    Object.keys(pageTypes).forEach(key => {
      if (!name.includes(key)) return
      this.pageType = pageTypes[key]

      if (key === 'checkout' && query.saved) {
        this.pageType = 'customer_verification'
      }

      if (key === 'checkout') {
        if (name.includes('primer')) {
          this.pageVersion = PAGE_VERSIONS.VARIATION_PRIMER
        } else {
          this.pageVersion = PAGE_VERSIONS.VARIATION_DEFAULT
        }
      }
    })
  }

  toJSON(): IPageView {
    const { APP_PLATFORM, DEFAULT_CURRENCY } = GTMDefaults
    const orderDeliveryMethodOnlyPages = ['brand', 'product', 'order']
    const data = {
      event: GTMEvents.PAGE,
      app_platform: APP_PLATFORM,
      website_id: this.websiteId,
      default_currency: DEFAULT_CURRENCY,
      page_type: this.pageType,
      ...(this.productCategory && { product_category: this.productCategory }),
      ...((!isNull(this.productBrand) || !isNull(this.productTheme)) && {
        product_brand: this.productBrand,
        product_theme: this.productTheme,
      }),
      ...(this.isGlobalMarketplace
        ? {
            product_country: this.storeCountry,
            app_language: this.storeLanguage,
            purchase_currency: this.storeCurrency,
            customer_country: this.customerCountry,
          }
        : {
            store_country: this.storeCountry,
            store_language: this.storeLanguage,
            store_currency: this.storeCurrency,
          }),
      app_language: this.storeLanguage,
      purchase_currency: this.storeCurrency,
      customer_country: this.customerCountry?.lowerCode,
      ...(this.ecommerce && { ecommerce: this.ecommerce }),
      ...(this.pageVersion && { page_version: this.pageVersion }),
    }

    if (orderDeliveryMethodOnlyPages.includes(this.pageType)) {
      Object.assign(data, { order_delivery_method: this.orderDeliveryMethod })
    }

    if (this.pageType === 'thank_you') {
      Object.assign(data, {
        ...(this.productBrand && { product_brand: this.productBrand }),
        ...(this.productCategory && { product_category: this.productCategory }),
        ...(this.orderDeliveryMethod && {
          order_delivery_method: this.orderDeliveryMethod,
        }),
      })
    }

    return data
  }
}
