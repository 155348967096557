import { GTMEvents } from '../enums'
import { GmpImpression, IGmpImpression } from './GmpImpression'
export interface IGmpImpressionsEcommerce {
  currencyCode: string
  impressions: IGmpImpression[]
}
export interface IGmpProductImpressions {
  event: GTMEvents
  ecommerce: IGmpImpressionsEcommerce
}

export class GmpProductImpressions {
  private readonly impressions: IGmpImpression[]
  private ecommerce: IGmpImpressionsEcommerce

  constructor({ products, currencyCode, listValue }) {
    const impressions = products.map((product, index) =>
      new GmpImpression(product, index, listValue).toJSON()
    )

    this.impressions = impressions
    this.ecommerce = {
      currencyCode,
      impressions,
    }
  }

  toJSON(): IGmpProductImpressions {
    return {
      event: GTMEvents.PRODUCT_IMRESSIONS,
      ecommerce: this.ecommerce,
    }
  }
}
