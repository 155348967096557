<template>
  <div class="mb-4 lg:mb-6">
    <alert type="info" has-transparent-background>
      <div slot="content" class="flex justify-between flex-wrap items-center">
        <div class="w-full md:w-1/2 flex flex-col justify-center pb-2 md:pb-0">
          <ui-title type="h4">
            {{ $t('molecules.checkout_out_of_stock.out_of_stock') }}
          </ui-title>

          <p>
            {{
              $t('molecules.checkout_out_of_stock.see_other', { brandTitle })
            }}
          </p>
        </div>
        <div class="w-full md:w-1/2 flex justify-end">
          <ui-button
            class="w-full md:w-auto"
            has-word-break
            is-slim
            @click.prevent="handleClick"
          >
            {{ $t('molecules.checkout_out_of_stock.see_more') }}
          </ui-button>
        </div>
      </div>
    </alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import { Alert, UiButton, UiTitle } from '~/components/atoms'
import { getUrlWithLocale } from '~/services/url'
export default {
  components: {
    Alert,
    UiButton,
    UiTitle,
  },
  props: {
    brandTitle: VueTypes.string.required,
    brandSlug: VueTypes.string.required,
    locale: VueTypes.string,
  },
  computed: {
    ...mapGetters('context', ['marketplacePrefix']),
    brandUrl() {
      const { marketplacePrefix, brandSlug } = this
      const { origin } = window.location
      const baseUrl = getUrlWithLocale(origin, marketplacePrefix)
      return `${baseUrl}/${brandSlug}`
    },
  },

  mounted() {
    this.$gtmEnhanced.trackOutOfStockProduct()
  },

  methods: {
    handleClick() {
      this.$gtmEnhanced.trackChangeOutOfStockProduct()
      window.location.href = this.brandUrl
    },
  },
}
</script>
