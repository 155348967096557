import { PropOptions } from 'vue'
import { IProduct } from '~/models/components/Product'
import { IProductValuesModel } from '~/models/topup/ProductPrice'

interface ICountryProp {
  abv: string
  name: string
}

export const product = {
  type: Object,
  default() {
    return {}
  },
} as PropOptions<IProduct | Record<string, unknown>>

export const productPrice = {
  type: Object,
  default() {
    return {}
  },
} as PropOptions<IProductValuesModel | Record<string, unknown>>

export const totalPrice = {
  type: Number,
  default: 0,
  required: true,
}

export const transactionCost = {
  type: Number,
  default: 0,
}
export const locale = {
  type: String,
  required: true,
}
export const brandSlug = {
  type: String,
  required: true,
}
export const country = {
  type: Object,
  required: true,
} as PropOptions<ICountryProp>

export const quantity = {
  type: Number,
  required: true,
}

export const hasCertifiedBadge = {
  type: Boolean,
  default: false,
}

export const discountPrice = {
  type: Number,
  default: 0,
}

export const promoCode = {
  type: String,
  default: '',
}

export const promoCodeError = {
  type: String,
  default: '',
}

export const promoCodeLoading = {
  type: Boolean,
  default: false,
}

export const promoCodeHasBeenApplied = {
  type: Boolean,
  default: false,
}

export const isGlobalMarketplace = {
  type: Boolean,
  default: false,
}

export const marketplacePrefix = {
  type: String,
  default: '',
}

export const prependToServiceFee = {
  type: Boolean,
  default: false,
}

export const openRangeValue = {
  type: Number,
  default: 0,
}
