const pick = (
  obj = {},
  paths: string[] = []
): Record<string | number, unknown> =>
  paths.reduce((result, path) => {
    return obj && path in obj
      ? {
          ...result,
          [path]: obj[path],
        }
      : result
  }, {})

export default pick
