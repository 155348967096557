
import { Notification } from '~/store/ui/notifications/types'

export default {
  props: {
    notification: Notification,
  },
  computed: {
    notificationClass() {
      const mapping = {
        warning: 'bg-warning text-white',
        error: 'bg-error text-white',
        success: 'bg-tertiary-pale text-primary',
        info: 'bg-info-medium-dark text-white',
      }

      return mapping[this.notification.type] || 'bg-info-medium-dark'
    },
  },
  mounted() {
    if (!this.notification.closeAfter) {
      return
    }

    setTimeout(() => {
      this.$store.dispatch(
        'ui/notifications/popNotification',
        this.notification.id
      )
      if (this.notification.isServerNotification) {
        this.$auth.accountClient.notification.markNotificationRead(
          this.notification.id
        )
      }
    }, this.notification.closeAfter)
  },
}
