import { Context, Middleware } from '@nuxt/types'

const TWO_POINT_O_PATHS = [
  '/en-gb/ie/paysafecard',
  '/en/ie/paysafecard',
  '/en/gb/paysafecard',
  '/en-gb/gb/paysafecard',
  '/en/gb/steam',
  '/en-gb/gb/steam',
]

const routeToTwoPointO: Middleware = ({ next, route }: Context) => {
  if (process.server) return

  if (TWO_POINT_O_PATHS.includes(route.path)) {
    window.location.href = route.path
    next?.(false)
  }
}

export default routeToTwoPointO
