export const BlogUrlRedirections = {
  '/en-gb/ie/blog/reduce-expenses-with-these-money-saving-tips-and-easy-budgeting':
    '/blog/en-gb/ie/reduce-expenses-with-these-money-saving-tips-and-easy-budgeting',
  '/en/es/blog/prepaid-credit-cards-compared':
    '/blog/es/es/tarjetas-de-credito-prepago-comparadas',
  '/en/ie/blog/reduce-expenses-with-these-money-saving-tips-and-easy-budgeting':
    '/blog/en-gb/ie/reduce-expenses-with-these-money-saving-tips-and-easy-budgeting',
  '/es-es/blog/donde-comprar-tarjetas-de-credito-prepago-sin-costes':
    '/es/es/prepaid-credit-cards',
  '/es-es/blog/guia-para-principiantes-tarjetas-anónimas-prepago':
    '/es/es/prepaid-credit-cards',
  '/es-es/blog/las-mejores-tarjetas-prepago-para-adolescentes':
    '/es/es/prepaid-credit-cards',
  '/es-es/blog/metodos-de-pago-anonimos': '/es/es/prepaid-credit-cards',
  '/es-es/blog/metodos-mas-utilizados-en-los-sitios-web-de-apuestas-deportivas':
    '/blog/es/es',
  '/es-mx/es/blog/historia-del-telefono-celular-y-como-ha-cambiado-nuestras-vidas':
    '/blog/es/es/historia-del-telefono-movil',
  '/es-us/blog/que-esta-cambiando-en-recharge': '/blog/es-mx/mx',
  '/es/es/blog/historia-del-telefono-movil':
    '/blog/es/es/historia-del-telefono-movil',
  '/fr-be/blog/comment-utiliser-votre-carte-cadeau-amazon': '/fr/be/amazon',
  '/fr-be/blog/des-idees-rapides-pour-depenser-intelligemment': '/fr/be',
  '/fr-be/blog/l-application-de-recharge-est-la': '/fr/be',
  '/fr-be/blog/les-changements-paysafecard-vous-impacteront-ils':
    '/fr/be/paysafecard',
  '/fr-be/blog/que-pouvez-vous-acheter-avec-une-carte-cadeau-app-store':
    '/fr/be/app-store-itunes',
  '/it-it/blog/i-metodi-di-pagamento-piu-accettati-sui-siti-di-scommesse':
    '/it/it/prepaid-credit-cards',
  'it-it/blog/le-migliori-carte-prepagate-per-minorenni':
    '/it/it/prepaid-credit-cards',
  '/it-it/blog/pagamenti-sicuri-e-anonimi-una-guida-essenziale':
    '/it/it/prepaid-credit-cards',
  '/nb-no/blog/appen-til-recharge-er-her': '/nb/no',
  '/nb-no/blog/forhandsbetalt-mobilabonnement-eller-vanlig-abonnement':
    '/nb/no',
  '/nb-no/blog/hvordan-vil-endringene-i-paysafecard-pavirke-deg':
    '/nb/no/paysafecard',
  '/pl-pl/blog/co-warto-wiedziec-na-temat-anonimowych-form-platnosci':
    '/pl/pl/prepaid-credit-cards',
  '/pl-pl/blog/gdzie-kupic-karte-przedplacona-bez-dodatkowych-kosztow':
    '/pl/pl/prepaid-credit-cards',
  '/pl-pl/blog/najlepsze-karty-kredytowe-dla-dzieci':
    '/pl/pl/prepaid-credit-cards',
  '/pl-pl/blog/poradnik-poczatkujacego-po-kartach-przedplaconych':
    '/pl/pl/prepaid-credit-cards',
  '/pt-pt/blog/razoes-porque-um-plano-movel-pre-pago-e-melhor-do-que-um-contrato':
    '/blog/pt/pt',
  '/sv-se/blog/hur-kan-jag-kopa-ett-paysafecard-med-paypal':
    '/blog/en-gb/ie/how-to-buy-paysafecard-with-paypal',
  '/sv-se/blog/hur-paverkas-jag-av-forandringarna-hos-paysafecard':
    '/nb/no/paysafecard',
  'sv-se/blog/recharge-appen-ar-har': '/sv/se',
  '/tr-tr/blog/recharge-uygulaması-cıktı': '/blog/en-gb/ie',
  '/pt-pt/blog/o-que-e-o-paysafecard-e-como-se-utiliza':
    '/blog/pt/pt/o-que-e-o-paysafecard',
}
