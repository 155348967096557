import { ActionTree } from 'vuex'
import countries from '../../../config/i18n/config/recommerceCountries.json'
import GLOBAL_COUNTRY from '../../utils/constants/global-country.json'
import { IContextStore } from './interfaces'
import { CONTEXT_STORAGE_KEYS, COOKIE_AGE } from './enums'
import { MUTATION_TYPES } from './mutation-types'
import get from '~/utils/get'
import snakeCase from '~/utils/snakeCase'
import compact from '~/utils/compact'
import {
  QUERY_STRING_KEYS,
  UTM_SOURCE_VALUES,
  UTM_MEDIUM_VALUES,
} from '~/enums/query-strings'
import locales from '~~/config/i18n/config/cmsLocales.json'
import {
  setStorageItem,
  getStorageItem,
  clearStorageItem,
} from '~/services/localstorage'
import { ROUTE_NAME } from '~~/config/router'

const STORAGE_KEY = 'recharge-storage'
const RECHARGE_CLASSIC_LANGUAGE_COOKIE = 'cg_language'

const label = process.env.LABEL

const defaultLocale = 'en-no'

const actions: ActionTree<IContextStore, IContextStore> = {
  initClient({ dispatch }, { query, $cookies }): void {
    dispatch('setAidInStorage', { query })
    dispatch('validateOriginRechargeClassicMerchant', { query, $cookies })
  },
  setConfigFromLocales({ commit, getters, dispatch }, { route, app }): void {
    try {
      const path = route.path.toLowerCase()
      let [pathLocale] = compact(path.split('/')) as string[]

      const availableLocales = app.i18n.locales.flatMap(({ code }) =>
        code.includes('-') ? [code] : []
      )

      if (!availableLocales.includes(pathLocale)) {
        const oAuthRoutes = [
          ROUTE_NAME.OAUTH_SIGN_IN_REDIRECT,
          ROUTE_NAME.OAUTH_SIGN_OUT_REDIRECT,
        ]

        if (oAuthRoutes.includes(route.name)) {
          pathLocale = app.i18n.getLocaleCookie() || defaultLocale
        } else {
          pathLocale = defaultLocale
        }
      }

      const currentLocale = app.i18n.locales.find(
        item => item.code === pathLocale
      )

      if (getters.pathIncludeLocale) {
        const { code, countryCode } = currentLocale
        commit(MUTATION_TYPES.SET_LOCALE, code)
        commit(MUTATION_TYPES.SET_COUNTRY_CODE, countryCode)
      }

      dispatch('setCmsLocale', pathLocale)
      dispatch('setMerchant', currentLocale.code)
      dispatch('setDirection', currentLocale.direction)

      if (currentLocale.code !== app.i18n.locale) {
        app.i18n.setLocale(currentLocale.code)
      }
    } catch (error) {
      console.log(error)
    }
  },
  setMarketplace({ commit }, marketplace) {
    commit('setMarketplace', marketplace)
  },
  setIsContactsFeaturePreview({ commit }, isContactsFeaturePreview) {
    commit('setIsContactsFeaturePreview', isContactsFeaturePreview)
  },
  setIsSignInWithAppleFeaturePreview(
    { commit },
    isSignInWithAppleFeaturePreview
  ) {
    commit(
      MUTATION_TYPES.SET_IS_SIGN_IN_WITH_APPLE_FEATURE_PREVIEW,
      isSignInWithAppleFeaturePreview
    )
  },
  setIsChangeEmailFeaturePreview({ commit }, isChangeEmailFeaturePreview) {
    commit('setIsChangeEmailFeaturePreview', isChangeEmailFeaturePreview)
  },
  disableAccountAnalytics({ commit }, accountAnalyticsDisabled) {
    commit(
      MUTATION_TYPES.SET_DISABLE_ACCOUNT_ANALYTICS,
      accountAnalyticsDisabled
    )
  },
  setMerchant({ commit }, selectedLocale) {
    const snakeCaseLocale = snakeCase(selectedLocale)
    const merchant = `${label}_${snakeCaseLocale}`.toUpperCase()
    commit(MUTATION_TYPES.SET_MERCHANT, merchant)
  },
  setBcSegments({ commit }) {
    if (!get(window, 'blueConicClient')) return
    const segments = window.blueConicClient.getSegments()

    const hasRapidoSegment = segments.some(({ name }) =>
      name.includes('Visited channel: www.rapido.com')
    )
    commit(MUTATION_TYPES.SET_RAPIDO_SEGMENT, hasRapidoSegment)
  },
  setCmsLocale({ commit }, pathLocale) {
    // gets the country from 'lang-country' locale composition
    const localeCountryRegex = /-(.{2})/i
    const formattedLocale = pathLocale.replace(localeCountryRegex, x =>
      x.toUpperCase()
    )
    const cmsLocales = locales.map(({ code }) => code)
    const lang = pathLocale.split('-')[0]
    const cmsLocaleFormatted = cmsLocales.includes(formattedLocale)
      ? formattedLocale
      : undefined

    const cmsLocaleLang = cmsLocales.includes(lang) ? lang : undefined
    const cmsLocale = cmsLocaleFormatted || cmsLocaleLang || cmsLocales[0]
    commit(MUTATION_TYPES.SET_CMS_LOCALE, cmsLocale)
  },

  selectCurrency({ commit, getters }, { currencies = [] }): void {
    if (getters.isGlobalMarketplace) return
    const defaultCurrency = currencies[0]

    commit(MUTATION_TYPES.SET_CURRENCY, defaultCurrency)
  },
  setAidInStorage(_, { query }): void {
    const { aid } = query
    if (!aid) return

    setStorageItem({
      storage: STORAGE_KEY,
      key: CONTEXT_STORAGE_KEYS.AID,
      value: aid,
    })
  },
  removeAidFromStorage(): void {
    const aid = getStorageItem({
      storage: STORAGE_KEY,
      key: CONTEXT_STORAGE_KEYS.AID,
    })
    if (!aid) return

    clearStorageItem({ storage: STORAGE_KEY, key: CONTEXT_STORAGE_KEYS.AID })
  },

  isRedirectedFromRapido({ commit }, queryStrings) {
    const { UTM_SOURCE, UTM_MEDIUM } = QUERY_STRING_KEYS
    const comesFromRapido =
      queryStrings[UTM_SOURCE] === UTM_SOURCE_VALUES.RAPIDO
    const isRedirect = queryStrings[UTM_MEDIUM] === UTM_MEDIUM_VALUES.REDIRECT
    const isRedirectedFromRapido = comesFromRapido && isRedirect
    commit(MUTATION_TYPES.SET_IS_REDIRECTED_FROM_RAPIDO, isRedirectedFromRapido)
  },

  validateOriginRechargeClassicMerchant({ commit }, { $cookies }): void {
    const rechargeMerchantToken = $cookies.get(
      CONTEXT_STORAGE_KEYS.GUEST_TOKEN,
      { parseJSON: false }
    )

    if (rechargeMerchantToken) {
      commit(MUTATION_TYPES.SET_ORIGIN_MERCHANT_RECHARGE_CLASSIC, true)
    }
  },
  setLastProductSeenByCountry({ rootGetters, commit }, value): void {
    const productId = rootGetters['checkout/productId']
    const countryAbv = rootGetters['contentful/countryAbv']

    if (!productId || !countryAbv) return

    const productsByCountry =
      getStorageItem({
        storage: STORAGE_KEY,
        key: CONTEXT_STORAGE_KEYS.LAST_PRODUCT_SEEN_BY_COUNTRY,
      }) || {}

    const modifiedProductsByCountry = {
      ...productsByCountry,
      [countryAbv]: productId,
    }

    setStorageItem({
      storage: STORAGE_KEY,
      key: CONTEXT_STORAGE_KEYS.LAST_PRODUCT_SEEN_BY_COUNTRY,
      value: modifiedProductsByCountry,
    })

    if (value) {
      const productsWithValuesByCountry =
        getStorageItem({
          storage: STORAGE_KEY,
          key: CONTEXT_STORAGE_KEYS.LAST_PRODUCT_VALUE_SEEN_BY_COUNTRY,
        }) || {}

      const modifiedValuesByCountry = {
        ...productsWithValuesByCountry,
        [countryAbv]: { productId, value },
      }

      setStorageItem({
        storage: STORAGE_KEY,
        key: CONTEXT_STORAGE_KEYS.LAST_PRODUCT_VALUE_SEEN_BY_COUNTRY,
        value: modifiedValuesByCountry,
      })
    }

    commit(MUTATION_TYPES.SET_LAST_PRODUCT_SEEN_BY_COUNTRY, {
      productId,
      countryAbv,
    })
  },
  validateLastProductSeen({ commit, rootGetters }): void {
    const countryAbv = rootGetters['context/countryCode']
    const productIdsByCountry =
      getStorageItem({
        storage: STORAGE_KEY,
        key: CONTEXT_STORAGE_KEYS.LAST_PRODUCT_SEEN_BY_COUNTRY,
      }) || {}

    const productId = productIdsByCountry[countryAbv]
    if (!productId) return

    commit(MUTATION_TYPES.SET_LAST_PRODUCT_SEEN_BY_COUNTRY, {
      productId,
      countryAbv,
    })
  },
  showErrorDetails({ commit }, { query }) {
    if (query.show_error) {
      setStorageItem({
        storage: STORAGE_KEY,
        key: CONTEXT_STORAGE_KEYS.SHOW_ERROR_DETAILS,
        value: true,
      })

      commit(MUTATION_TYPES.SET_SHOW_ERROR_DETAILS, true)
      return
    }

    const shouldShowErrorDetails = getStorageItem({
      storage: STORAGE_KEY,
      key: CONTEXT_STORAGE_KEYS.SHOW_ERROR_DETAILS,
    })

    commit(MUTATION_TYPES.SET_SHOW_ERROR_DETAILS, shouldShowErrorDetails)
  },
  redirectToRechargeClassic({ getters }): void {
    const { lang } = getters
    this.$cookies.remove(RECHARGE_CLASSIC_LANGUAGE_COOKIE)
    this.$cookies.set(
      RECHARGE_CLASSIC_LANGUAGE_COOKIE,
      { locale: lang.toLowerCase() },
      COOKIE_AGE
    )
    window.location.href = '/'
  },
  setDirection({ commit }, direction) {
    commit(MUTATION_TYPES.SET_DIRECTION, direction)
  },
  setLocaleProperties({ commit }, i18nLocaleProperties) {
    commit('setLocaleProperties', i18nLocaleProperties)
  },
  setCustomerCountry({ commit }, customerCountryCode) {
    const customerCountry =
      countries.find(
        c => c.code.toLowerCase() === customerCountryCode?.toLowerCase()
      ) || GLOBAL_COUNTRY
    commit('setCustomerCountry', {
      ...customerCountry,
      lowerCode: customerCountry.code.toLowerCase(),
      nonGlobalCode: customerCountryCode,
    })
  },
  translateCountriesAndCurrencies({ getters, commit }) {
    const { countries } = getters
    const { currencies } = getters
    const translateNameFor = type => item => ({
      ...item,
      name: this.$i18n.t(`${type}.${item.code}`),
      nameTranslated: this.$i18n.t(`${type}.${item.code}`), // Supporting legacy Classic naming
    })
    commit('setCountries', countries.map(translateNameFor('countries')))
    commit('setCurrencies', currencies.map(translateNameFor('currencies')))
  },
  setIsRafAvailableCountry({ commit }, isRafAvailableCountry) {
    commit('setIsRafAvailableCountry', isRafAvailableCountry)
  },
}

export default actions
