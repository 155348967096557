import { Context, Middleware } from '@nuxt/types'

// Middleware checks for authenticated state and compares against routes with auth meta.
// If false and route requiresAuth redirects user to login
const authGuardMiddleware: Middleware = (context: Context): void => {
  const {
    route: { path, name },
    redirect,
    store,
  } = context
  if (!name) {
    return
  }

  const isAuthenticated: boolean = store.getters['user/isAuthenticated']

  const { requiresAuth } = context.route.matched[0].meta

  if (requiresAuth && !isAuthenticated) {
    const locale = store.getters['context/marketplacePrefix']
    const ref = path.replace(`/${locale}`, '')

    redirect(302, `/${locale}?auth=login&ref=${ref}`)
  }
}

export default authGuardMiddleware
