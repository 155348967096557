import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../client/store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../client/store/types.ts'), 'types.ts')
  resolveStoreModules(require('../client/store/user/index.ts'), 'user/index.ts')
  resolveStoreModules(require('../client/store/ui/index.js'), 'ui/index.js')
  resolveStoreModules(require('../client/store/stateSelector/index.ts'), 'stateSelector/index.ts')
  resolveStoreModules(require('../client/store/rtrUser/index.ts'), 'rtrUser/index.ts')
  resolveStoreModules(require('../client/store/recommerce/index.ts'), 'recommerce/index.ts')
  resolveStoreModules(require('../client/store/products/index.ts'), 'products/index.ts')
  resolveStoreModules(require('../client/store/order/index.ts'), 'order/index.ts')
  resolveStoreModules(require('../client/store/errors/index.ts'), 'errors/index.ts')
  resolveStoreModules(require('../client/store/context/index.ts'), 'context/index.ts')
  resolveStoreModules(require('../client/store/contentful/index.ts'), 'contentful/index.ts')
  resolveStoreModules(require('../client/store/checkout/index.ts'), 'checkout/index.ts')
  resolveStoreModules(require('../client/store/checkout/actions.ts'), 'checkout/actions.ts')
  resolveStoreModules(require('../client/store/checkout/getters.ts'), 'checkout/getters.ts')
  resolveStoreModules(require('../client/store/checkout/interfaces.ts'), 'checkout/interfaces.ts')
  resolveStoreModules(require('../client/store/checkout/mutation-types.ts'), 'checkout/mutation-types.ts')
  resolveStoreModules(require('../client/store/checkout/mutations.ts'), 'checkout/mutations.ts')
  resolveStoreModules(require('../client/store/checkout/services.ts'), 'checkout/services.ts')
  resolveStoreModules(require('../client/store/checkout/state.ts'), 'checkout/state.ts')
  resolveStoreModules(require('../client/store/contentful/actions.ts'), 'contentful/actions.ts')
  resolveStoreModules(require('../client/store/contentful/getters.ts'), 'contentful/getters.ts')
  resolveStoreModules(require('../client/store/contentful/interfaces.ts'), 'contentful/interfaces.ts')
  resolveStoreModules(require('../client/store/contentful/mutation-types.ts'), 'contentful/mutation-types.ts')
  resolveStoreModules(require('../client/store/contentful/mutations.ts'), 'contentful/mutations.ts')
  resolveStoreModules(require('../client/store/contentful/state.ts'), 'contentful/state.ts')
  resolveStoreModules(require('../client/store/context/actions.ts'), 'context/actions.ts')
  resolveStoreModules(require('../client/store/context/enums.ts'), 'context/enums.ts')
  resolveStoreModules(require('../client/store/context/getters.ts'), 'context/getters.ts')
  resolveStoreModules(require('../client/store/context/interfaces.ts'), 'context/interfaces.ts')
  resolveStoreModules(require('../client/store/context/mutation-types.ts'), 'context/mutation-types.ts')
  resolveStoreModules(require('../client/store/context/mutations.ts'), 'context/mutations.ts')
  resolveStoreModules(require('../client/store/context/state.ts'), 'context/state.ts')
  resolveStoreModules(require('../client/store/order/actions.ts'), 'order/actions.ts')
  resolveStoreModules(require('../client/store/order/enums.ts'), 'order/enums.ts')
  resolveStoreModules(require('../client/store/order/getters.ts'), 'order/getters.ts')
  resolveStoreModules(require('../client/store/order/interfaces.ts'), 'order/interfaces.ts')
  resolveStoreModules(require('../client/store/order/mutations.ts'), 'order/mutations.ts')
  resolveStoreModules(require('../client/store/order/state.ts'), 'order/state.ts')
  resolveStoreModules(require('../client/store/products/actions.ts'), 'products/actions.ts')
  resolveStoreModules(require('../client/store/products/getters.ts'), 'products/getters.ts')
  resolveStoreModules(require('../client/store/products/mutation-types.ts'), 'products/mutation-types.ts')
  resolveStoreModules(require('../client/store/products/mutations.ts'), 'products/mutations.ts')
  resolveStoreModules(require('../client/store/products/services.ts'), 'products/services.ts')
  resolveStoreModules(require('../client/store/products/state.ts'), 'products/state.ts')
  resolveStoreModules(require('../client/store/recommerce/actions.ts'), 'recommerce/actions.ts')
  resolveStoreModules(require('../client/store/recommerce/getters.ts'), 'recommerce/getters.ts')
  resolveStoreModules(require('../client/store/recommerce/mutation-types.ts'), 'recommerce/mutation-types.ts')
  resolveStoreModules(require('../client/store/recommerce/mutations.ts'), 'recommerce/mutations.ts')
  resolveStoreModules(require('../client/store/recommerce/state.ts'), 'recommerce/state.ts')
  resolveStoreModules(require('../client/store/rtrUser/actions.ts'), 'rtrUser/actions.ts')
  resolveStoreModules(require('../client/store/rtrUser/getters.ts'), 'rtrUser/getters.ts')
  resolveStoreModules(require('../client/store/rtrUser/mutation-types.ts'), 'rtrUser/mutation-types.ts')
  resolveStoreModules(require('../client/store/rtrUser/mutations.ts'), 'rtrUser/mutations.ts')
  resolveStoreModules(require('../client/store/rtrUser/state.ts'), 'rtrUser/state.ts')
  resolveStoreModules(require('../client/store/rtrUser/types.ts'), 'rtrUser/types.ts')
  resolveStoreModules(require('../client/store/stateSelector/actions.ts'), 'stateSelector/actions.ts')
  resolveStoreModules(require('../client/store/stateSelector/getters.ts'), 'stateSelector/getters.ts')
  resolveStoreModules(require('../client/store/stateSelector/mutation-types.ts'), 'stateSelector/mutation-types.ts')
  resolveStoreModules(require('../client/store/stateSelector/mutations.ts'), 'stateSelector/mutations.ts')
  resolveStoreModules(require('../client/store/stateSelector/state.ts'), 'stateSelector/state.ts')
  resolveStoreModules(require('../client/store/stateSelector/states.ts'), 'stateSelector/states.ts')
  resolveStoreModules(require('../client/store/user/actions.ts'), 'user/actions.ts')
  resolveStoreModules(require('../client/store/user/getters.ts'), 'user/getters.ts')
  resolveStoreModules(require('../client/store/user/mutation-types.ts'), 'user/mutation-types.ts')
  resolveStoreModules(require('../client/store/user/mutations.ts'), 'user/mutations.ts')
  resolveStoreModules(require('../client/store/user/state.ts'), 'user/state.ts')
  resolveStoreModules(require('../client/store/user/types.ts'), 'user/types.ts')
  resolveStoreModules(require('../client/store/ui/notifications/index.ts'), 'ui/notifications/index.ts')
  resolveStoreModules(require('../client/store/ui/mobile-menu/index.ts'), 'ui/mobile-menu/index.ts')
  resolveStoreModules(require('../client/store/ui/content-modal/index.ts'), 'ui/content-modal/index.ts')
  resolveStoreModules(require('../client/store/ui/checkout/index.ts'), 'ui/checkout/index.ts')
  resolveStoreModules(require('../client/store/contentful/mocks/product.js'), 'contentful/mocks/product.js')
  resolveStoreModules(require('../client/store/products/tests/mocks.js'), 'products/tests/mocks.js')
  resolveStoreModules(require('../client/store/ui/checkout/actions.ts'), 'ui/checkout/actions.ts')
  resolveStoreModules(require('../client/store/ui/checkout/getters.ts'), 'ui/checkout/getters.ts')
  resolveStoreModules(require('../client/store/ui/checkout/mutation-types.ts'), 'ui/checkout/mutation-types.ts')
  resolveStoreModules(require('../client/store/ui/checkout/mutations.ts'), 'ui/checkout/mutations.ts')
  resolveStoreModules(require('../client/store/ui/checkout/state.ts'), 'ui/checkout/state.ts')
  resolveStoreModules(require('../client/store/ui/content-modal/actions.ts'), 'ui/content-modal/actions.ts')
  resolveStoreModules(require('../client/store/ui/content-modal/getters.ts'), 'ui/content-modal/getters.ts')
  resolveStoreModules(require('../client/store/ui/content-modal/mutation-types.ts'), 'ui/content-modal/mutation-types.ts')
  resolveStoreModules(require('../client/store/ui/content-modal/mutations.ts'), 'ui/content-modal/mutations.ts')
  resolveStoreModules(require('../client/store/ui/content-modal/state.ts'), 'ui/content-modal/state.ts')
  resolveStoreModules(require('../client/store/ui/mobile-menu/actions.ts'), 'ui/mobile-menu/actions.ts')
  resolveStoreModules(require('../client/store/ui/mobile-menu/getters.ts'), 'ui/mobile-menu/getters.ts')
  resolveStoreModules(require('../client/store/ui/mobile-menu/mutation-types.ts'), 'ui/mobile-menu/mutation-types.ts')
  resolveStoreModules(require('../client/store/ui/mobile-menu/mutations.ts'), 'ui/mobile-menu/mutations.ts')
  resolveStoreModules(require('../client/store/ui/mobile-menu/state.ts'), 'ui/mobile-menu/state.ts')
  resolveStoreModules(require('../client/store/ui/mobile-menu/types.ts'), 'ui/mobile-menu/types.ts')
  resolveStoreModules(require('../client/store/ui/notifications/actions.ts'), 'ui/notifications/actions.ts')
  resolveStoreModules(require('../client/store/ui/notifications/getters.ts'), 'ui/notifications/getters.ts')
  resolveStoreModules(require('../client/store/ui/notifications/mutation-types.ts'), 'ui/notifications/mutation-types.ts')
  resolveStoreModules(require('../client/store/ui/notifications/mutations.ts'), 'ui/notifications/mutations.ts')
  resolveStoreModules(require('../client/store/ui/notifications/state.ts'), 'ui/notifications/state.ts')
  resolveStoreModules(require('../client/store/ui/notifications/types.ts'), 'ui/notifications/types.ts')
  resolveStoreModules(require('../client/store/checkout/tests/mocks/createPayment401ErrorResponse.js'), 'checkout/tests/mocks/createPayment401ErrorResponse.js')
  resolveStoreModules(require('../client/store/checkout/tests/mocks/createPayment422ErrorResponse.js'), 'checkout/tests/mocks/createPayment422ErrorResponse.js')
  resolveStoreModules(require('../client/store/checkout/tests/mocks/createPaymentPayload.js'), 'checkout/tests/mocks/createPaymentPayload.js')
  resolveStoreModules(require('../client/store/checkout/tests/mocks/createPaymentResponse.js'), 'checkout/tests/mocks/createPaymentResponse.js')
  resolveStoreModules(require('../client/store/checkout/tests/mocks/paymentMethodsMutate.js'), 'checkout/tests/mocks/paymentMethodsMutate.js')
  resolveStoreModules(require('../client/store/checkout/tests/mocks/paymentMethodsResponse.js'), 'checkout/tests/mocks/paymentMethodsResponse.js')
  resolveStoreModules(require('../client/store/ui/checkout/enums/brandConfig.ts'), 'ui/checkout/enums/brandConfig.ts')
  resolveStoreModules(require('../client/store/ui/checkout/enums/paysafe-terms-and-conditions-links.ts'), 'ui/checkout/enums/paysafe-terms-and-conditions-links.ts')
  resolveStoreModules(require('../client/store/ui/checkout/enums/steps.ts'), 'ui/checkout/enums/steps.ts')
  resolveStoreModules(require('../client/store/ui/checkout/__tests__/mocks/paymentMethodsMutate.js'), 'ui/checkout/__tests__/mocks/paymentMethodsMutate.js')
  resolveStoreModules(require('../client/store/ui/checkout/__tests__/mocks/paymentMethodsResponse.js'), 'ui/checkout/__tests__/mocks/paymentMethodsResponse.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
