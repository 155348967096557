
import { Container, Row, Column } from '~/components/grid'
import { ConfirmationCard } from '~/components/molecules'

export default {
  components: {
    Row,
    Column,
    Container,
    ConfirmationCard,
  },
  props: {
    phone: {
      type: String,
      default: '',
    },
    codes: {
      type: Array,
      default: () => [],
    },
  },
}
