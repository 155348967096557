export enum FONT_ICON {
  account = 'account',
  arrow = 'arrow',
  breadcrumb = 'breadcrumb',
  check = 'check',
  'check-circle' = 'check-circle',
  close = 'close',
  down = 'down',
  entertainment = 'entertainment',
  giftcards = 'giftcards',
  gamecards = 'gamecards',
  info = 'info',
  international = 'international',
  'gamecards-copy' = 'gamecards-copy',
  'mobile-recharge' = 'mobile-recharge',
  homepage = 'homepage',
  hamburger = 'hamburger',
  min = 'min',
  plus = 'plus',
  popular = 'popular',
  'prepaid-cards' = 'prepaid-cards',
  privacy = 'privacy',
  warning = 'warning',
  cryptocurrency = 'cryptocurrency',
  lock = 'lock',
  search = 'search',
  certified = 'certified',
  phone = 'phone',
  mail = 'mail',
  edit = 'edit',
  eye = 'eye',
  'eye-slash' = 'eye-slash',
  chevron = 'chevron',
  'chevron-down' = 'chevron-down',
  'chevron-right' = 'chevron-right',
  'warning-stroke' = 'warning-stroke',
}
export enum SVG_ICON {
  loader = 'loader',
  hole = 'hole',
  'error-payment-general' = 'error-payment-general',
  'error-payment-method' = 'error-payment-method',
  'trusted' = 'trusted',
  'trusted-alt' = 'trusted-alt',
  'black-lock' = 'black-lock',
  'black-lock-alt' = 'black-lock-alt',
  'danger' = 'danger',
  'danger-filled' = 'danger-filled',

  'icon' = 'icon',
  'error' = 'error',
  'error-filled' = 'error-filled',
  'pending' = 'pending',
  'success' = 'success',
  'success-filled' = 'success-filled',
  'google' = 'google',
  'facebook' = 'facebook',
  'faq-bubble' = 'faq-bubble',
  'faq-bubble-alt' = 'faq-bubble-alt',
  'fast-delivery-2' = 'fast-delivery-2',
  'warning-filled-yellow' = 'warning-filled-yellow',
  'safety' = 'safety',
  'checkmark' = 'checkmark',
  'edit-pencil' = 'edit-pencil',
  'encrypted' = 'encrypted',
  'terms' = 'terms',

  'email' = ' email',
}

export enum SIZE {
  small = 'small',
  base = 'base',
  large = 'large',
}

export enum COLOR {
  link = '#438FDF',
  white = '#FFF',
  black = '#000',
  success = '#00e070',
  primary = '#00994c',
  gray = '#abb9ba',
  darkgray = '#738A8C',
  error = '#FF5A64',
}
