<template>
  <div>
    <slot name="step-1" />
    <slot v-if="step > 1" name="step-2" />
    <slot v-if="step > 2" name="step-3" />
    <slot v-if="step > 3" name="step-4" />
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
}
</script>
