import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** Banner (promospot) */
export type Banner = {
  __typename?: 'Banner';
  /** Link target of the banner. */
  link: Scalars['String'];
  /** Image of the banner */
  image: BannerImage;
  /** Mobile image of the banner */
  mobileImage: Maybe<BannerImage>;
};

/** Banner image */
export type BannerImage = {
  __typename?: 'BannerImage';
  /** Title of the banner image. */
  title: Scalars['String'];
  /** Alt text of the banner image. */
  alt: Scalars['String'];
  /** URL of the banner image */
  url: Scalars['String'];
};

/** Calculate order resolver response */
export type CalculateOrderType = {
  __typename?: 'CalculateOrderType';
  /** The transaction cost for the payment method. */
  transactionCost: Maybe<Money>;
  /** The price for the order line. */
  orderLinePrice: Maybe<Money>;
  /** The price for the order without fees. */
  orderPrice: Maybe<Money>;
  /** The total price for the order. */
  totalPrice: Maybe<Money>;
  /** The discount price for the order. */
  discountPrice: Maybe<Money>;
  /** Order service fees */
  serviceFee: Maybe<Money>;
  /** User's wallet balance */
  walletBalance: Maybe<Money>;
  /** Extra amount needed for referral reward to be applicable */
  extraAmountForApplicableReward: Maybe<Money>;
  /** Indicates if order price is minimum */
  isMinimumOrderPrice: Maybe<Scalars['Boolean']>;
  /** Indicates if the maximum discount amount for the applied voucher has been reached and applied to the order. */
  maximumDiscountAmountApplied: Maybe<Scalars['Boolean']>;
  /** The applied voucher */
  voucher: Maybe<Scalars['String']>;
  /** The selected payment method. */
  paymentMethodId: Maybe<Scalars['String']>;
};

/** Category from Rapido */
export type Category = {
  __typename?: 'Category';
  /** ID from the category */
  id: Scalars['Int'];
  /** Category's name */
  name: Scalars['String'];
  /** Unique identifier (URI) for category */
  slug: Scalars['String'];
  productImage: Maybe<Scalars['String']>;
  popularProductImage: Maybe<Scalars['String']>;
  headerImage: Maybe<Scalars['String']>;
  topupInstructions: Maybe<Scalars['String']>;
  termsAndConditions: Maybe<Scalars['String']>;
  info: Maybe<CategoryInfo>;
  sections: Array<SectionSimple>;
  productsServiceCategoryId: Maybe<Scalars['Int']>;
  /** The type of category from the CMS (cg_brand or cg_category) */
  cmsCategoryType: Maybe<Scalars['String']>;
};

/** Category with subcategories and products */
export type CategoryExtended = {
  __typename?: 'CategoryExtended';
  /** ID from the category */
  id: Scalars['Int'];
  /** Category's name */
  name: Scalars['String'];
  /** Unique identifier (URI) for category */
  slug: Scalars['String'];
  /** Slugs for every locale */
  localizedUrls: Array<Maybe<CategoryLocalizedUrl>>;
  /** Category's meta title */
  metaTitle: Scalars['String'];
  /** Category's meta description */
  metaDescription: Scalars['String'];
  productImage: Maybe<Scalars['String']>;
  headerImage: Maybe<Scalars['String']>;
  topupInstructions: Maybe<Scalars['String']>;
  info: Maybe<CategoryInfo>;
  subCategories: Array<Subcategory>;
  products: Maybe<Array<Product>>;
  productKinds: Maybe<Array<ProductKinds>>;
  sections: Array<SectionSimple>;
};


/** Category with subcategories and products */
export type CategoryExtendedLocalizedUrlsArgs = {
  productSlug: Maybe<Scalars['String']>;
};


/** Category with subcategories and products */
export type CategoryExtendedProductsArgs = {
  currency: Maybe<Currency>;
  visible: Maybe<Scalars['Boolean']>;
};


/** Category with subcategories and products */
export type CategoryExtendedProductKindsArgs = {
  currency: Maybe<Currency>;
  visible: Maybe<Scalars['Boolean']>;
};

export type CategoryInfo = {
  __typename?: 'CategoryInfo';
  title: Scalars['String'];
  merchant: InfoBlock;
  category: InfoBlock;
  promotion: Maybe<PromotionBlock>;
  questions: Array<InfoBlock>;
};

export type CategoryLocalizedUrl = {
  __typename?: 'CategoryLocalizedUrl';
  locale: Scalars['String'];
  url: Scalars['String'];
};

/** Order from Classic */
export type ClassicOrder = {
  __typename?: 'ClassicOrder';
  /** ID from order */
  id: Maybe<Scalars['Int']>;
  /** Number from order */
  number: Maybe<Scalars['String']>;
  /** Secret token to fetch the order without account */
  token: Maybe<Scalars['String']>;
  /** Order delivery email address */
  email: Maybe<Scalars['String']>;
  /** Order created at timestamp */
  created_at: Maybe<Scalars['String']>;
  /** Country from the order */
  country_name: Maybe<Scalars['String']>;
  /** Country ISO from the order */
  country_iso: Maybe<Scalars['String']>;
  /** Order payment method display name */
  pay_method: Maybe<Scalars['String']>;
  /** Order payment status */
  payment_status: Maybe<Scalars['String']>;
  /** Order status */
  status: Maybe<Scalars['String']>;
  /** User from Omnia */
  user: Maybe<User>;
  /** Product from the order */
  product: Maybe<ClassicProduct>;
  /** Order price (without service fees) */
  price: Maybe<Scalars['String']>;
  /** Order currency */
  currency: Maybe<Scalars['String']>;
  /** Order product quantity  */
  quantity: Maybe<Scalars['String']>;
  /** Order service fees  */
  service_fee: Maybe<Scalars['String']>;
  /** The actually paid transaction fee of this order. */
  transaction_cost: Maybe<Money>;
  /** Order discount total */
  discount_price: Maybe<Scalars['String']>;
  /** Order total price including service fees */
  total_price: Maybe<Scalars['String']>;
  /** RTR from the order */
  rtr: Maybe<Rtr>;
  /** Voucher */
  voucher: Maybe<Scalars['String']>;
};

/** Product from Rapido */
export type ClassicProduct = {
  __typename?: 'ClassicProduct';
  /** Name of the product */
  name: Maybe<Scalars['String']>;
  /** Image of the product */
  image_url: Maybe<Scalars['String']>;
  /** Slug of the product */
  slug: Maybe<Scalars['String']>;
};

/** Top up Code from Rapido */
export type Code = {
  __typename?: 'Code';
  /** Code */
  code: Maybe<Scalars['String']>;
  /** Serial number of the code */
  serial_number: Maybe<Scalars['String']>;
  /** Image url of the product */
  image_url: Maybe<Scalars['String']>;
  /** Redeem url of the product */
  redeem_url: Maybe<Scalars['String']>;
  /** Sicherheits code of the product */
  sicherheits_code: Maybe<Scalars['String']>;
  /** Delivery date of the code */
  delivery_date: Maybe<Scalars['String']>;
  /** Reference of the code */
  reference: Maybe<Scalars['String']>;
  /** Delivery status of the code */
  status: Maybe<Scalars['String']>;
  /** Code is available, but hidden from displaying */
  hidden: Maybe<Scalars['Boolean']>;
};

/** List of countries available in Topup */
export enum Country {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

/** List of currencies available in topup */
export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
  Pln = 'PLN',
  Dkk = 'DKK',
  Nok = 'NOK',
  Sek = 'SEK',
  Chf = 'CHF',
  Try = 'TRY',
  Nzd = 'NZD',
  Aud = 'AUD',
  Czk = 'CZK',
  Huf = 'HUF',
  Mxn = 'MXN',
  Bhd = 'BHD',
  Kwd = 'KWD',
  Omr = 'OMR',
  Qar = 'QAR',
  Sar = 'SAR',
  Aed = 'AED',
  Cad = 'CAD',
  Ars = 'ARS',
  Bgn = 'BGN',
  Brl = 'BRL',
  Bsd = 'BSD',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ghs = 'GHS',
  Gtq = 'GTQ',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Khr = 'KHR',
  Krw = 'KRW',
  Kzt = 'KZT',
  Lkr = 'LKR',
  Mad = 'MAD',
  Myr = 'MYR',
  Ngn = 'NGN',
  Npr = 'NPR',
  Pab = 'PAB',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Ron = 'RON',
  Rub = 'RUB',
  Sgd = 'SGD',
  Srd = 'SRD',
  Thb = 'THB',
  Tnd = 'TND',
  Ttd = 'TTD',
  Twd = 'TWD',
  Uah = 'UAH',
  Vnd = 'VND',
  Xaf = 'XAF',
  Xof = 'XOF',
  Zar = 'ZAR',
  Mkd = 'MKD',
  Bam = 'BAM',
  Uyu = 'UYU'
}

/** Custom Denomination from products */
export type CustomDenomination = {
  __typename?: 'CustomDenomination';
  minValue: Maybe<Scalars['Int']>;
  maxValue: Maybe<Scalars['Int']>;
  predefinedValues: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type FooterLinks = {
  __typename?: 'FooterLinks';
  /** ID of the section */
  name: Maybe<Scalars['String']>;
  /** Sections items */
  items: Maybe<Array<Maybe<SectionItem>>>;
};

/** A giftwrapping option */
export type GiftWrapping = {
  __typename?: 'GiftWrapping';
  /** Name of the giftwrapping option */
  name: Scalars['String'];
  /** Display name of the giftwrapping option */
  displayName: Scalars['String'];
  /** Image of the giftwrapping option */
  image: Maybe<Scalars['String']>;
};

/** Giftwrapping PDF content */
export type GiftWrappingPdf = {
  __typename?: 'GiftWrappingPDF';
  /** content of the giftwrapping PDF */
  content: Scalars['String'];
};

/** The existing giftwrap options */
export enum GiftWrapsEnum {
  None = 'none',
  Default = 'default',
  Christmas = 'christmas',
  Holidays = 'holidays',
  Sint = 'sint',
  Congratulations = 'congratulations',
  Thankyou = 'thankyou'
}

export type InfoBlock = {
  __typename?: 'InfoBlock';
  title: Scalars['String'];
  description: Scalars['String'];
  expanded: Scalars['Boolean'];
};

/** List of languages available in Topup */
export enum Language {
  Ar = 'AR',
  Cs = 'CS',
  Da = 'DA',
  De = 'DE',
  El = 'EL',
  En = 'EN',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  Gr = 'GR',
  Hr = 'HR',
  Hu = 'HU',
  Is = 'IS',
  It = 'IT',
  Lb = 'LB',
  Lt = 'LT',
  Lv = 'LV',
  Mt = 'MT',
  Nb = 'NB',
  Nl = 'NL',
  Pl = 'PL',
  Pt = 'PT',
  Ro = 'RO',
  Si = 'SI',
  Sk = 'SK',
  Sv = 'SV',
  Tr = 'TR'
}

/** Legal page, like privacy statement and cookie statement */
export type LegalPage = {
  __typename?: 'LegalPage';
  /** Title of the page */
  title: Scalars['String'];
  /** Content of the page */
  content: Scalars['String'];
  /** Content of the page but sectioned out */
  sections: Array<InfoBlock>;
};

/** The allowed legal pages to retrieve data from */
export enum LegalPagesEnum {
  Privacy = 'PRIVACY',
  Cookie = 'COOKIE'
}

/** Login on GraphQL endpoint */
export type LoginType = {
  __typename?: 'LoginType';
  /** GraphQL API JWT token */
  token: Maybe<Scalars['String']>;
};

/** The merchant identifies the storefront. Each locale has a unique merchant. */
export enum Merchant {
  Beltegoed = 'BELTEGOED',
  Herladen = 'HERLADEN',
  Guthaben = 'GUTHABEN',
  Rechargefr = 'RECHARGEFR',
  Mobiletopup = 'MOBILETOPUP',
  BeltegoedNlNl = 'BELTEGOED_NL_NL',
  BeltegoedNlBe = 'BELTEGOED_NL_BE',
  HerladenNlBe = 'HERLADEN_NL_BE',
  GuthabenDeDe = 'GUTHABEN_DE_DE',
  GuthabenDeAt = 'GUTHABEN_DE_AT',
  RechargefrFrFr = 'RECHARGEFR_FR_FR',
  MobiletopupEnGb = 'MOBILETOPUP_EN_GB',
  UnoGlobal = 'UNO_GLOBAL',
  RechargeNbNo = 'RECHARGE_NB_NO',
  RechargeEnNo = 'RECHARGE_EN_NO',
  RechargeEnSe = 'RECHARGE_EN_SE',
  RechargeSvSe = 'RECHARGE_SV_SE',
  RechargeFrBe = 'RECHARGE_FR_BE',
  RechargeNlBe = 'RECHARGE_NL_BE',
  RechargeEnBe = 'RECHARGE_EN_BE',
  RechargeEnIe = 'RECHARGE_EN_IE',
  RechargeEnPl = 'RECHARGE_EN_PL',
  RechargePlPl = 'RECHARGE_PL_PL',
  RechargeEnIt = 'RECHARGE_EN_IT',
  RechargeItIt = 'RECHARGE_IT_IT',
  RechargeEnEs = 'RECHARGE_EN_ES',
  RechargeEsEs = 'RECHARGE_ES_ES',
  RechargeDeEs = 'RECHARGE_DE_ES',
  RechargeEnPt = 'RECHARGE_EN_PT',
  RechargePtPt = 'RECHARGE_PT_PT',
  RechargeEnCh = 'RECHARGE_EN_CH',
  RechargeDeCh = 'RECHARGE_DE_CH',
  RechargeFrCh = 'RECHARGE_FR_CH',
  RechargeEnDk = 'RECHARGE_EN_DK',
  RechargeDaDk = 'RECHARGE_DA_DK',
  RechargeEnGb = 'RECHARGE_EN_GB',
  RechargeEnAt = 'RECHARGE_EN_AT',
  RechargeDeAt = 'RECHARGE_DE_AT',
  RechargeEnFi = 'RECHARGE_EN_FI',
  RechargeFiFi = 'RECHARGE_FI_FI',
  RechargeEnSk = 'RECHARGE_EN_SK',
  RechargeSkSk = 'RECHARGE_SK_SK',
  RechargeEnUs = 'RECHARGE_EN_US',
  RechargeEsUs = 'RECHARGE_ES_US',
  RechargeFrUs = 'RECHARGE_FR_US',
  RechargeEnNl = 'RECHARGE_EN_NL',
  RechargeNlNl = 'RECHARGE_NL_NL',
  RechargePlNl = 'RECHARGE_PL_NL',
  RechargeEnDe = 'RECHARGE_EN_DE',
  RechargeDeDe = 'RECHARGE_DE_DE',
  RechargePlDe = 'RECHARGE_PL_DE',
  RechargeEnFr = 'RECHARGE_EN_FR',
  RechargeFrFr = 'RECHARGE_FR_FR',
  RechargeEsFr = 'RECHARGE_ES_FR',
  RechargeEnHr = 'RECHARGE_EN_HR',
  RechargeHrHr = 'RECHARGE_HR_HR',
  RechargeEnGr = 'RECHARGE_EN_GR',
  RechargeElGr = 'RECHARGE_EL_GR',
  RechargeEnIs = 'RECHARGE_EN_IS',
  RechargeIsIs = 'RECHARGE_IS_IS',
  RechargeEnLv = 'RECHARGE_EN_LV',
  RechargeLvLv = 'RECHARGE_LV_LV',
  RechargeEnLt = 'RECHARGE_EN_LT',
  RechargeLtLt = 'RECHARGE_LT_LT',
  RechargePlLt = 'RECHARGE_PL_LT',
  RechargeEnMt = 'RECHARGE_EN_MT',
  RechargeMtMt = 'RECHARGE_MT_MT',
  RechargeEnCy = 'RECHARGE_EN_CY',
  RechargeElCy = 'RECHARGE_EL_CY',
  RechargeTrCy = 'RECHARGE_TR_CY',
  RechargeEnHu = 'RECHARGE_EN_HU',
  RechargeHuHu = 'RECHARGE_HU_HU',
  RechargeEnLu = 'RECHARGE_EN_LU',
  RechargeLbLu = 'RECHARGE_LB_LU',
  RechargeFrLu = 'RECHARGE_FR_LU',
  RechargeDeLu = 'RECHARGE_DE_LU',
  RechargeEnRo = 'RECHARGE_EN_RO',
  RechargeRoRo = 'RECHARGE_RO_RO',
  RechargeEnCz = 'RECHARGE_EN_CZ',
  RechargeCsCz = 'RECHARGE_CS_CZ',
  RechargeEnAu = 'RECHARGE_EN_AU',
  RechargeEnNz = 'RECHARGE_EN_NZ',
  RechargeEnTr = 'RECHARGE_EN_TR',
  RechargeTrTr = 'RECHARGE_TR_TR',
  RechargeEnSi = 'RECHARGE_EN_SI',
  RechargeSiSi = 'RECHARGE_SI_SI',
  RechargeFrAt = 'RECHARGE_FR_AT',
  RechargeGrGr = 'RECHARGE_GR_GR',
  RechargeEsMx = 'RECHARGE_ES_MX',
  RechargeEnMx = 'RECHARGE_EN_MX',
  RechargeEnAe = 'RECHARGE_EN_AE',
  RechargeArAe = 'RECHARGE_AR_AE',
  RechargeEnSa = 'RECHARGE_EN_SA',
  RechargeArSa = 'RECHARGE_AR_SA',
  RechargeEnKw = 'RECHARGE_EN_KW',
  RechargeArKw = 'RECHARGE_AR_KW',
  RechargeEnQa = 'RECHARGE_EN_QA',
  RechargeArQa = 'RECHARGE_AR_QA',
  RechargeEnOm = 'RECHARGE_EN_OM',
  RechargeArOm = 'RECHARGE_AR_OM',
  RechargeEnBh = 'RECHARGE_EN_BH',
  RechargeArBh = 'RECHARGE_AR_BH',
  RechargeEnCa = 'RECHARGE_EN_CA',
  RechargeFrCa = 'RECHARGE_FR_CA'
}

/** A monetary value. */
export type Money = {
  __typename?: 'Money';
  /** The value in cents. */
  value: Scalars['Float'];
  /** The currency. */
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** JWT login */
  login: Maybe<LoginType>;
  /** Payment checkout */
  createPayment: Maybe<Payment>;
  /** Create a phone challenge (send an sms with a code) */
  createPhoneChallenge: Maybe<PhoneChallenge>;
  /** Validates a phone challenge */
  validatePhoneChallenge: Maybe<ValidatePhoneChallengeType>;
  /** Updates phone number and refresh order */
  changePhoneNumber: Maybe<OrderId>;
  /** Request an order refund */
  requestRefund: Maybe<RefundRequest>;
  /** Send order codes via sms */
  sendSms: Maybe<SendSmsType>;
  /** Set order is_ga_tracked true */
  orderIsTracked: Maybe<OrderIsTracked>;
};


export type MutationLoginArgs = {
  username: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
};


export type MutationCreatePaymentArgs = {
  merchant: Merchant;
  affiliateId: Maybe<Scalars['ID']>;
  currency: Currency;
  productId: Scalars['ID'];
  redeemPhoneNumber: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  email: Scalars['String'];
  newsletter: Scalars['Boolean'];
  issuer: Maybe<Scalars['String']>;
  gift: Scalars['Boolean'];
  paymentMethodId: Scalars['String'];
  redirectUrls: PaymentRedirectUrlsType;
  paymentInformationData: Maybe<PaymentInformationData>;
  voucher: Maybe<Scalars['String']>;
  orderId: Maybe<Scalars['ID']>;
  orderHash: Maybe<Scalars['String']>;
  locale: Maybe<Scalars['String']>;
  country: Maybe<Country>;
  accountId: Maybe<Scalars['ID']>;
  value: Maybe<Scalars['Int']>;
  recaptchaToken: Maybe<Scalars['String']>;
  beaconId: Maybe<Scalars['String']>;
  ravelinDeviceId: Maybe<Scalars['String']>;
};


export type MutationCreatePhoneChallengeArgs = {
  merchant: Merchant;
  phoneNumber: Scalars['String'];
  locale: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['ID']>;
  currency: Maybe<Currency>;
  localeOptions: Maybe<Scalars['String']>;
};


export type MutationValidatePhoneChallengeArgs = {
  phoneChallengeId: Scalars['String'];
  phoneChallengeNumber: Scalars['String'];
  phoneChallengeCode: Scalars['String'];
};


export type MutationChangePhoneNumberArgs = {
  merchant: Merchant;
  phoneNumber: Scalars['String'];
  id: Scalars['ID'];
  hash: Scalars['String'];
};


export type MutationRequestRefundArgs = {
  merchant: Merchant;
  id: Scalars['ID'];
  hash: Scalars['String'];
  reason: Maybe<Scalars['String']>;
  reasonMessage: Maybe<Scalars['String']>;
};


export type MutationSendSmsArgs = {
  merchant: Merchant;
  phoneNumber: Scalars['String'];
  countryAbv: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  hash: Scalars['String'];
};


export type MutationOrderIsTrackedArgs = {
  merchant: Merchant;
  id: Scalars['ID'];
  hash: Scalars['String'];
};

export type MyOrder = TopupOrder | ClassicOrder;

export type NewBillingFields = {
  __typename?: 'NewBillingFields';
  /** Field name */
  fieldName: Maybe<Scalars['String']>;
  /** If the field is required */
  required: Maybe<Scalars['Boolean']>;
  /** Step */
  step: Maybe<Scalars['String']>;
};

/** News (blog post) */
export type News = {
  __typename?: 'News';
  /** Title of the news item. */
  title: Scalars['String'];
  /** Description of the news item. */
  description: Scalars['String'];
  /** URL of the news item. */
  url: Scalars['String'];
  /** Date of the news item. Format: Y-m-d */
  date: Scalars['String'];
};

/** Order from Rapido */
export type Order = {
  __typename?: 'Order';
  /** ID from order */
  id: Maybe<Scalars['Int']>;
  /** Order created at timestamp */
  created_at: Maybe<Scalars['String']>;
  /** Country from the order */
  country_name: Maybe<Scalars['String']>;
  /** Country ISO from the order */
  country_iso: Maybe<Scalars['String']>;
  /** Order finish page url */
  url: Maybe<Scalars['String']>;
  /** Order payment method display name */
  pay_method: Maybe<Scalars['String']>;
  /** Order payment status */
  payment_status: Maybe<Scalars['String']>;
  /** Order currency */
  order_currency: Maybe<Scalars['String']>;
  /** Order status */
  status: Maybe<Scalars['String']>;
  /** IP address */
  ip: Maybe<Scalars['String']>;
  /** Order status */
  comments: Maybe<Scalars['String']>;
  /** The payment orchestrator */
  payment_orchestrator: Maybe<PaymentOrchestrator>;
  /** User from Rapido */
  user: Maybe<User>;
  /** Product from the order */
  products: Maybe<Array<Maybe<Product>>>;
  /** Order price (without service fees) */
  price: Maybe<Scalars['String']>;
  /** Order currency */
  currency: Maybe<Scalars['String']>;
  /** Order product quantity  */
  quantity: Maybe<Scalars['String']>;
  /** Order service fees  */
  service_fee: Maybe<Scalars['String']>;
  /** The actually paid transaction fee of this order. */
  transactionCost: Maybe<Money>;
  /** Order discount total */
  discount_price: Maybe<Scalars['String']>;
  /** Order total price including service fees */
  total_price: Maybe<Scalars['String']>;
  /** RTR from the order */
  rtr: Maybe<Rtr>;
  /** Voucher */
  voucher: Maybe<Scalars['String']>;
  /** Hash */
  hash: Maybe<Scalars['String']>;
  /** Open range value from the order */
  open_range_value: Maybe<Scalars['Int']>;
  primerPaymentStatus: Maybe<PrimerPaymentStatusEnum>;
  /** Order is a gift */
  is_gift: Maybe<Scalars['Boolean']>;
  /** Sms is sent */
  sms_sent: Maybe<Scalars['Boolean']>;
  /** Is tracked by GA */
  is_ga_tracked: Maybe<Scalars['Boolean']>;
};


/** Order from Rapido */
export type OrderUserArgs = {
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
};


/** Order from Rapido */
export type OrderProductsArgs = {
  code: Maybe<Scalars['String']>;
  serial_number: Maybe<Scalars['String']>;
};

/** Phone number update status */
export type OrderId = {
  __typename?: 'OrderID';
  /** The ID from the order */
  orderId: Scalars['ID'];
};

/** Order is tracked by GA */
export type OrderIsTracked = {
  __typename?: 'OrderIsTracked';
  /** Order is tracked */
  orderIsTracked: Maybe<Scalars['Boolean']>;
};

/** Topup or Classic */
export enum OrderType {
  Topup = 'TOPUP',
  Classic = 'CLASSIC'
}

export type PageType = {
  __typename?: 'PageType';
  /** Page Type */
  Type: Maybe<PageTypeEnum>;
};

/** Brand, Category or General */
export enum PageTypeEnum {
  Brand = 'BRAND',
  Category = 'CATEGORY',
  General = 'GENERAL'
}

/** Payment */
export type Payment = {
  __typename?: 'Payment';
  /** checkoutUrl */
  checkoutUrl: Scalars['String'];
};

/** Payment details */
export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  /** ID from order */
  id: Maybe<Scalars['Int']>;
  /** IP address */
  ip: Maybe<Scalars['String']>;
  /** Order status */
  status: Maybe<Scalars['String']>;
  /** Payment status */
  payment_status: Maybe<Scalars['String']>;
  /** Order payment method display name */
  pay_method: Maybe<Scalars['String']>;
  /** GMP Locale */
  locale: Maybe<Scalars['String']>;
  /** Order created at timestamp */
  created_at: Maybe<Scalars['String']>;
  /** Order updated at timestamp */
  updated_at: Maybe<Scalars['String']>;
  /** Order delivery email address */
  email: Maybe<Scalars['String']>;
  /** Order price */
  amount: Maybe<Scalars['String']>;
  payment_orchestrator: Maybe<Scalars['String']>;
  currency_code: Maybe<Scalars['String']>;
  country_code: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  house_number: Maybe<Scalars['String']>;
  postal_code: Maybe<Scalars['String']>;
  place: Maybe<Scalars['String']>;
  birthdate: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  account_id: Maybe<Scalars['String']>;
  primer_payment_method: Maybe<PrimerPaymentMethod>;
};

/** Payment information data */
export type PaymentInformationData = {
  /** Salutation of the billing address */
  billingSalutation?: Maybe<Scalars['String']>;
  /** Firstname of the billing address */
  billingFirstname?: Maybe<Scalars['String']>;
  /** Lastname of the billing address */
  billingLastname?: Maybe<Scalars['String']>;
  /** Street of the billing address */
  billingStreet?: Maybe<Scalars['String']>;
  /** City of the billing address */
  billingCity?: Maybe<Scalars['String']>;
  /** Postal code of the billing address */
  billingPostalcode?: Maybe<Scalars['String']>;
  /** House number of the billing address */
  billingHousenumber?: Maybe<Scalars['String']>;
  /** Phone number of the billing address */
  billingPhonenumber?: Maybe<Scalars['String']>;
  /** Phone number prefix of the billing address */
  billingPhonenumberPrefix?: Maybe<Scalars['String']>;
  /** Phone number country code of the billing address */
  billingPhonenumberCountry?: Maybe<Scalars['String']>;
  /** Email address for the billing address */
  billingEmailaddress?: Maybe<Scalars['String']>;
  /** Birth date for the billing address */
  billingBirthdate?: Maybe<Scalars['String']>;
  /** Country for the billing address */
  billingCountry?: Maybe<Scalars['String']>;
  /** State for the billing address */
  billingState?: Maybe<Scalars['String']>;
  /** Customer billing address. */
  billingAddressLine?: Maybe<Scalars['String']>;
  /** Customer codice fiscale number. */
  billingCodiceFiscale?: Maybe<Scalars['String']>;
  /** Phone number used for the phone challenge */
  phoneChallengeNumber?: Maybe<Scalars['String']>;
  /** Identifier for the phone challenge */
  phoneChallengeId?: Maybe<Scalars['String']>;
  /** Code for the phone challenge */
  phoneChallengeCode?: Maybe<Scalars['String']>;
};

/** Some payment methods require additional information. It can be any of these fields. */
export enum PaymentInformationFields {
  BillingSalutation = 'BILLING_SALUTATION',
  BillingFirstname = 'BILLING_FIRSTNAME',
  BillingLastname = 'BILLING_LASTNAME',
  BillingStreet = 'BILLING_STREET',
  BillingCity = 'BILLING_CITY',
  BillingPostalcode = 'BILLING_POSTALCODE',
  BillingHousenumber = 'BILLING_HOUSENUMBER',
  BillingPhonenumber = 'BILLING_PHONENUMBER',
  BillingPhonenumberPrefix = 'BILLING_PHONENUMBER_PREFIX',
  BillingPhonenumberCountry = 'BILLING_PHONENUMBER_COUNTRY',
  BillingEmailaddress = 'BILLING_EMAILADDRESS',
  BillingBirthdate = 'BILLING_BIRTHDATE',
  BillingCountry = 'BILLING_COUNTRY',
  BillingState = 'BILLING_STATE',
  BillingCodiceFiscale = 'BILLING_CODICE_FISCALE',
  PhoneChallengeNumber = 'PHONE_CHALLENGE_NUMBER',
  PhoneChallengeId = 'PHONE_CHALLENGE_ID',
  PhoneChallengeCode = 'PHONE_CHALLENGE_CODE',
  Remember = 'REMEMBER'
}

/** A payment method. For example Paypal or iDeal. */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** The ID of the payment method. */
  id: Maybe<Scalars['ID']>;
  /** The human readable name for the payment method. */
  label: Scalars['String'];
  /** The human readable tooltip for the payment method. */
  tooltip: Maybe<Scalars['String']>;
  /** The logo image URL for the payment method */
  image: Scalars['String'];
  /** If a payment method is disabled it should be shown to the user, but not be available to select. */
  disabled: Scalars['Boolean'];
  /** The transaction cost for the payment method. */
  transactionCost: Maybe<Money>;
  /** The price for the order without fees. */
  orderPrice: Maybe<Money>;
  /** The total price for the order. */
  totalPrice: Maybe<Money>;
  /** The discount price for the order. */
  discountPrice: Maybe<Money>;
  /** Order service fees */
  serviceFee: Maybe<Money>;
  /** The list of issuers for the payment method. */
  issuers: Maybe<Array<Maybe<PaymentMethodIssuer>>>;
  /** Payment Information Fields */
  paymentInformationFields: Maybe<Array<Maybe<PaymentInformationFields>>>;
  /** If payment information fields are required */
  paymentInformationFieldsRequired: Maybe<Scalars['String']>;
  /** Country ID based on geolocation */
  geolocationCountryID: Maybe<Scalars['Int']>;
  /** Country based on geolocation */
  geoIpCountry: Maybe<Scalars['String']>;
  /** Indicates if the inputs "Street" and "House number" are separated */
  shouldSeparateStreetHouseInputs: Maybe<Scalars['Boolean']>;
  /** Indicates if the maximum discount amount for the applied voucher has been reached and applied to the order. */
  maximumDiscountAmountApplied: Maybe<Scalars['Boolean']>;
};

/** A payment method can have one or more issuers. An issuer is a provider of a certain payment method. An example of a payment method issuer is a bank for iDeal payments. */
export type PaymentMethodIssuer = {
  __typename?: 'PaymentMethodIssuer';
  /** The ID of the payment method issuer. */
  id: Scalars['ID'];
  /** The human readable name for the payment method issuer. */
  label: Scalars['String'];
};

/** A list of redirect urls to redirect to after completing of cancelling a payment. */
export type PaymentRedirectUrlsType = {
  /** The url to redirect to after the payment succeeded. */
  success: Scalars['String'];
  /** The url to redirect to if the payment is still pending. */
  pending: Scalars['String'];
  /** The url to redirect to when the payment is cancelled. */
  cancel: Scalars['String'];
  /** The url to redirect to when an error occurs. */
  error: Scalars['String'];
};

/** Phone challenge */
export type PhoneChallenge = {
  __typename?: 'PhoneChallenge';
  /** The id of the phone challenge */
  id: Maybe<Scalars['ID']>;
  /** The reference of the sms status */
  reference: Maybe<Scalars['String']>;
};

/** Pre Payment Data */
export type PrePaymentData = {
  __typename?: 'PrePaymentData';
  /** country based on ip address */
  geoIpCountry: Maybe<Scalars['String']>;
  /** currency scale for decimal point calculations */
  currencyScale: Maybe<Scalars['Int']>;
  /** temporary flag for showing Paysafe form form or not */
  showPaysafeForm: Scalars['Boolean'];
  /** Needed billing fields according to the product */
  billingFields: Maybe<Array<Maybe<NewBillingFields>>>;
};

/** Product Price from Rapido */
export type Price = {
  __typename?: 'Price';
  /** Local amount of the price */
  local_amount: Maybe<Scalars['String']>;
  localAmount: Maybe<Scalars['String']>;
  /** Local discount amount of the price */
  local_discount_amount: Maybe<Scalars['String']>;
  localDiscountAmount: Maybe<Scalars['String']>;
  /** Local currency of the price */
  local_currency: Maybe<Scalars['String']>;
  localCurrency: Maybe<Scalars['String']>;
  displayValueAddAmount: Maybe<Scalars['Float']>;
  displayLocalAmount: Maybe<Scalars['String']>;
  fxMarkupPercentage: Maybe<Scalars['Float']>;
  baseCurrency: Maybe<Scalars['String']>;
};

/** Primer Payment Method */
export type PrimerPaymentMethod = {
  __typename?: 'PrimerPaymentMethod';
  /** Payment method name */
  name: Maybe<Scalars['String']>;
  /** Payment processor */
  processor_name: Maybe<Scalars['String']>;
  last_4_digits: Maybe<Scalars['String']>;
  expiration_month: Maybe<Scalars['String']>;
  expiration_year: Maybe<Scalars['String']>;
  cardholder_name: Maybe<Scalars['String']>;
  network: Maybe<Scalars['String']>;
  is_network_tokenized: Maybe<Scalars['String']>;
  bin_data: Maybe<BinData>;
};

/** The primer payment statuses */
export enum PrimerPaymentStatusEnum {
  Pending = 'PENDING',
  Authorized = 'AUTHORIZED',
  Declined = 'DECLINED',
  Failed = 'FAILED',
  Settling = 'SETTLING',
  PartiallySettled = 'PARTIALLY_SETTLED',
  Settled = 'SETTLED',
  Cancelled = 'CANCELLED'
}

/** Product from Rapido */
export type Product = {
  __typename?: 'Product';
  /** Product id */
  id: Scalars['ID'];
  /** Product name */
  name: Scalars['String'];
  /** Product class. Examples: `"DataCredit"`, `"DataCreditCode"`, `"RTR"` and many others. */
  class: Scalars['String'];
  /** Product display name */
  display_name: Maybe<Scalars['String']>;
  /** Product display name */
  displayName: Maybe<Scalars['String']>;
  /** Short product description */
  shortDescription: Maybe<Scalars['String']>;
  /** Long product description */
  longDescription: Maybe<Scalars['String']>;
  /** Product slug */
  slug: Maybe<Scalars['String']>;
  /** Product type */
  product_type: Maybe<Scalars['String']>;
  productType: Maybe<Scalars['String']>;
  /** Product kind */
  product_kind: Maybe<Scalars['String']>;
  productKind: Maybe<Scalars['String']>;
  /** Redeem type (pin or rtr) */
  redeem_type: Maybe<Scalars['String']>;
  redeemType: Maybe<Scalars['String']>;
  /** Brand name of the product */
  brand_name: Maybe<Scalars['String']>;
  brandName: Maybe<Scalars['String']>;
  /** Available */
  available: Scalars['Boolean'];
  visible: Scalars['Boolean'];
  /** Topup codes from the order */
  codes: Maybe<Array<Maybe<Code>>>;
  /** Product's prices */
  prices: Maybe<Array<Maybe<Price>>>;
  /** Product's service fees */
  service_fees: Maybe<Array<Maybe<Service_Fee>>>;
  /** Product's categories */
  categories: Array<Category>;
  /** Product's categories, independent of country */
  linkedCategories: Array<Category>;
  /** Product image url */
  product_image: Maybe<Scalars['String']>;
  /** Redeem instructions */
  redeem_instructions: Maybe<Scalars['String']>;
  /** Products Service Id */
  products_service_id: Maybe<Scalars['ID']>;
  productsServiceId: Maybe<Scalars['ID']>;
  /** Type of scam notification */
  scam_notification_type: Maybe<Scalars['String']>;
  scamNotificationType: Maybe<Scalars['String']>;
  /** Terms and conditions */
  terms_and_conditions: Maybe<Scalars['String']>;
  /** Terms and conditions */
  termsAndConditions: Maybe<Scalars['String']>;
  /** Can send code via sms */
  sendSms: Maybe<Scalars['Boolean']>;
  maxQuantity: Maybe<Scalars['Int']>;
  /** The custom denomination details of the product. */
  customDenomination: Maybe<CustomDenomination>;
  /** Product updated date */
  updatedAt: Maybe<Scalars['String']>;
  /** Customer has correct country for product */
  isCorrectCountry: Scalars['Boolean'];
  /** First check to determine if Paysafecard form should be shown on page load */
  showPaysafeForm: Scalars['Boolean'];
  /** Returns the specified open range value, or the min open range value for OpenRange products. */
  open_range_value: Maybe<Scalars['Int']>;
  /** Returns if the products requires confirmation to waive rights upon purchase. */
  requestConfirmationToWaiveRights: Scalars['Boolean'];
  /** Returns if we are a certified reseller of the product */
  certifiedReseller: Scalars['Boolean'];
};


/** Product from Rapido */
export type ProductCodesArgs = {
  code: Maybe<Scalars['String']>;
  serial_number: Maybe<Scalars['String']>;
};


/** Product from Rapido */
export type ProductPricesArgs = {
  currency: Maybe<Scalars['String']>;
  currencyEnum: Maybe<Currency>;
};

/** Kinds of Products */
export type ProductKinds = {
  __typename?: 'ProductKinds';
  kind: Scalars['String'];
  /** Product from the order */
  products: Array<Product>;
};

export type PromotionBlock = {
  __typename?: 'PromotionBlock';
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** List of orders */
  orders: Maybe<Array<Maybe<Order>>>;
  /** Products from Rapido */
  products: Maybe<Array<Maybe<Product>>>;
  /** Kinds */
  productKinds: Maybe<Array<Maybe<ProductKinds>>>;
  /** Page Type */
  pageType: Maybe<PageType>;
  /** User quickbuy product */
  userQuickbuy: Maybe<Product>;
  /** Default quickbuy product for Local Labels. */
  quickbuy: Maybe<Product>;
  /** News items */
  news: Array<News>;
  /** List the available payment methods for a product. */
  paymentMethods: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Get the giftwrapping options */
  giftWrappings: Maybe<Array<GiftWrapping>>;
  /** Get the giftwrapping PDF content */
  giftWrappingPDF: Maybe<GiftWrappingPdf>;
  /** Get the details of a single order */
  order: Maybe<Order>;
  /** Get the status of a single order */
  orderStatus: Maybe<Status>;
  /** Translated list of countries for Recharge Classic */
  rechargeClassicCountries: Maybe<Array<Maybe<RechargeClassicCountry>>>;
  /** Validates Phone Number */
  validatePhoneNumber: Maybe<ValidatePhone>;
  /** List of customer orders */
  myOrders: Maybe<Array<Maybe<MyOrder>>>;
  /** Get the details of a single order */
  myOrder: Maybe<MyOrder>;
  /** Active sections for a merchant */
  sections: Maybe<Array<Maybe<Section>>>;
  section: Maybe<Section>;
  /** Categories matching the search input */
  searchCategories: Maybe<Array<Maybe<CategoryExtended>>>;
  /** Category by id or slug */
  category: Maybe<CategoryExtended>;
  /** A legal page */
  legalPage: Maybe<LegalPage>;
  /** Creates a temporary order and returns full order summary */
  calculateOrder: Maybe<CalculateOrderType>;
  /** Get footer links */
  footerLinks: Maybe<Array<Maybe<FooterLinks>>>;
  /** Payment Details Information by customer account id */
  paymentDetails: Maybe<Array<Maybe<PaymentDetails>>>;
  /** Pre payment data required in the checkout page */
  prePaymentData: Maybe<PrePaymentData>;
  /** get visitor details */
  getVisitorDetails: Maybe<VisitorDetails>;
  /** Fetches banners for a merchant used for the homepage. */
  banners: Maybe<Array<Maybe<Banner>>>;
};


export type QueryOrdersArgs = {
  id: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['String']>;
  ip: Maybe<Scalars['String']>;
  payment_id: Maybe<Scalars['Int']>;
  merchant_id: Maybe<Scalars['Int']>;
  users_service_id: Maybe<Scalars['Int']>;
  code: Maybe<Scalars['String']>;
  serial_number: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  created_after: Maybe<Scalars['String']>;
  created_before: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryProductsArgs = {
  products_service_id: Maybe<Scalars['Int']>;
  quantity: Maybe<Scalars['Int']>;
  products_service_ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  merchant: Maybe<Merchant>;
  country: Maybe<Country>;
  merchants_service_id: Maybe<Scalars['Int']>;
  currency: Maybe<Currency>;
  visible: Maybe<Scalars['Boolean']>;
  limit: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
};


export type QueryProductKindsArgs = {
  products_service_id: Maybe<Scalars['Int']>;
  quantity: Maybe<Scalars['Int']>;
  products_service_ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  merchant: Maybe<Merchant>;
  country: Maybe<Country>;
  merchants_service_id: Maybe<Scalars['Int']>;
  currency: Maybe<Currency>;
  visible: Maybe<Scalars['Boolean']>;
  limit: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
};


export type QueryPageTypeArgs = {
  merchant: Merchant;
  slug: Scalars['String'];
};


export type QueryUserQuickbuyArgs = {
  email: Scalars['String'];
  merchant_id: Scalars['Int'];
};


export type QueryQuickbuyArgs = {
  merchant: Merchant;
  country: Country;
  currency: Currency;
  productId: Maybe<Scalars['ID']>;
  value: Maybe<Scalars['Int']>;
};


export type QueryNewsArgs = {
  merchant: Merchant;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPaymentMethodsArgs = {
  merchant: Merchant;
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  currency: Currency;
  email: Maybe<Scalars['String']>;
  voucher: Maybe<Scalars['String']>;
  locale: Maybe<Scalars['String']>;
  country: Maybe<Country>;
  value: Maybe<Scalars['Int']>;
};


export type QueryGiftWrappingsArgs = {
  merchant: Merchant;
};


export type QueryGiftWrappingPdfArgs = {
  merchant: Merchant;
  orderID: Scalars['ID'];
  orderHash: Scalars['String'];
  giftwrap: GiftWrapsEnum;
  message: Maybe<Scalars['String']>;
};


export type QueryOrderArgs = {
  merchant: Merchant;
  id: Scalars['ID'];
  hash: Scalars['String'];
  locale: Maybe<Scalars['String']>;
  country: Maybe<Country>;
};


export type QueryOrderStatusArgs = {
  merchant: Merchant;
  id: Scalars['ID'];
  hash: Scalars['String'];
  locale: Maybe<Scalars['String']>;
  country: Maybe<Country>;
};


export type QueryRechargeClassicCountriesArgs = {
  language: Language;
};


export type QueryValidatePhoneNumberArgs = {
  countryAbv: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};


export type QueryMyOrdersArgs = {
  merchant_id: Scalars['Int'];
  order_type: Maybe<Array<Maybe<OrderType>>>;
  limit: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
};


export type QueryMyOrderArgs = {
  id: Scalars['ID'];
  order_type: Maybe<OrderType>;
};


export type QuerySectionsArgs = {
  merchant: Merchant;
};


export type QuerySectionArgs = {
  merchant: Merchant;
  identifier: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  sortSection: Maybe<Scalars['Boolean']>;
};


export type QuerySearchCategoriesArgs = {
  merchant: Maybe<Merchant>;
  searchInput: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryCategoryArgs = {
  merchant: Merchant;
  id: Maybe<Scalars['ID']>;
  slug: Maybe<Scalars['String']>;
  productSlug: Maybe<Scalars['String']>;
};


export type QueryLegalPageArgs = {
  merchant: Merchant;
  name: LegalPagesEnum;
};


export type QueryCalculateOrderArgs = {
  merchant: Merchant;
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  currency: Currency;
  country: Country;
  shopCountry: Maybe<Country>;
  paymentMethodId: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  voucher: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Int']>;
};


export type QueryFooterLinksArgs = {
  merchant: Merchant;
};


export type QueryPaymentDetailsArgs = {
  accountId: Maybe<Scalars['String']>;
};


export type QueryPrePaymentDataArgs = {
  merchant: Merchant;
  productId: Scalars['ID'];
  currencyCode: Scalars['String'];
  country: Scalars['String'];
  email: Maybe<Scalars['String']>;
  paymentMethod: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Int']>;
};


export type QueryBannersArgs = {
  merchant: Merchant;
};

/** Rtr information */
export type Rtr = {
  __typename?: 'RTR';
  /** Phone number that has been topped */
  phone: Maybe<Scalars['String']>;
  /** Status of the RTR */
  status: Maybe<Scalars['String']>;
};

/** Country information */
export type RechargeClassicCountry = {
  __typename?: 'RechargeClassicCountry';
  /** English country name */
  name: Maybe<Scalars['String']>;
  /** Translated country name */
  nameTranslated: Maybe<Scalars['String']>;
  /** ISO code of the country */
  iso: Maybe<Scalars['String']>;
  /** Country code of the country */
  countryCode: Maybe<Scalars['String']>;
  /** Slug of the country */
  slug: Maybe<Scalars['String']>;
};

/** Refund Request */
export type RefundRequest = {
  __typename?: 'RefundRequest';
  /** The status of the return request */
  status: Maybe<Scalars['String']>;
};

export type Section = {
  __typename?: 'Section';
  /** ID of the section */
  id: Scalars['Int'];
  /** Translated name */
  name: Scalars['String'];
  /** Alternative translated name (Phrase) */
  displayName: Maybe<Scalars['String']>;
  /** Unique section identifier */
  identifier: Maybe<Scalars['String']>;
  /** Sort order value */
  index: Maybe<Scalars['Int']>;
  /** URI part of the section page */
  slug: Scalars['String'];
  /** Page title of the section page */
  pageTitle: Scalars['String'];
  /** Meta description of the section page */
  pageDescription: Scalars['String'];
  /** Nested (brand) categories within the section */
  categories: Array<Category>;
  /** Info blocks of the section item */
  info: Array<SectionInfo>;
};

/** Info which is displayed on the category page. */
export type SectionInfo = {
  __typename?: 'SectionInfo';
  /** Title of the section */
  title: Maybe<Scalars['String']>;
  /** Content of the section */
  content: Maybe<Scalars['String']>;
};

export type SectionItem = {
  __typename?: 'SectionItem';
  /** Item title */
  title: Maybe<Scalars['String']>;
  /** URI part of the section page */
  slug: Maybe<Scalars['String']>;
};

export type SectionSimple = {
  __typename?: 'SectionSimple';
  /** ID of the section */
  id: Scalars['Int'];
  /** Translated name */
  name: Scalars['String'];
  /** Alternative translated name (Phrase) */
  displayName: Maybe<Scalars['String']>;
  /** Unique section identifier */
  identifier: Maybe<Scalars['String']>;
  /** Sort order value */
  index: Maybe<Scalars['Int']>;
  /** URI part of the section page */
  slug: Maybe<Scalars['String']>;
};

/** sendSms status */
export type SendSmsType = {
  __typename?: 'SendSmsType';
  /** True if the SMS was succesfully sent */
  smsSent: Maybe<Scalars['Boolean']>;
};

/** Product Service Fee from Rapido */
export type Service_Fee = {
  __typename?: 'Service_Fee';
  /** Local amount of the service fee */
  local_service_fee: Maybe<Scalars['String']>;
  localServiceFee: Maybe<Scalars['String']>;
  localServiceFeeCurrency: Maybe<Scalars['String']>;
};

/** Order Status */
export type Status = {
  __typename?: 'Status';
  /** The status of the order */
  status: Maybe<Scalars['String']>;
  paymentStatus: Maybe<PrimerPaymentStatusEnum>;
};

export type Subcategory = {
  __typename?: 'Subcategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** Unique identifier (URI) for category */
  slug: Scalars['String'];
  products: Array<Product>;
};


export type SubcategoryProductsArgs = {
  currency: Maybe<Currency>;
};

/** Order from Topup */
export type TopupOrder = {
  __typename?: 'TopupOrder';
  /** ID from order */
  id: Maybe<Scalars['Int']>;
  /** Order delivery email address */
  email: Maybe<Scalars['String']>;
  /** Order created at timestamp */
  created_at: Maybe<Scalars['String']>;
  /** Country from the order */
  country_name: Maybe<Scalars['String']>;
  /** Country ISO from the order */
  country_iso: Maybe<Scalars['String']>;
  /** Order finish page url */
  url: Maybe<Scalars['String']>;
  /** Order payment method display name */
  pay_method: Maybe<Scalars['String']>;
  /** Order payment status */
  payment_status: Maybe<Scalars['String']>;
  /** Order status */
  status: Maybe<Scalars['String']>;
  /** IP address */
  ip: Maybe<Scalars['String']>;
  /** Order status */
  comments: Maybe<Scalars['String']>;
  /** User from Rapido */
  user: Maybe<User>;
  /** Product from the order */
  products: Maybe<Array<Maybe<Product>>>;
  /** Order price (without service fees) */
  price: Maybe<Scalars['String']>;
  /** Order currency */
  currency: Maybe<Scalars['String']>;
  /** Order product quantity  */
  quantity: Maybe<Scalars['String']>;
  /** Order service fees  */
  service_fee: Maybe<Scalars['String']>;
  /** The actually paid transaction fee of this order. */
  transaction_cost: Maybe<Money>;
  /** Order discount total */
  discount_price: Maybe<Scalars['String']>;
  /** Reward value used in order */
  wallet_amount: Maybe<Scalars['String']>;
  /** Order total price including service fees */
  total_price: Maybe<Scalars['String']>;
  /** RTR from the order */
  rtr: Maybe<Rtr>;
  /** Voucher */
  voucher: Maybe<Scalars['String']>;
  /** Hash */
  hash: Maybe<Scalars['String']>;
  /** Open range value from the order */
  open_range_value: Maybe<Scalars['Int']>;
  primerPaymentStatus: Maybe<PrimerPaymentStatusEnum>;
};


/** Order from Topup */
export type TopupOrderUserArgs = {
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
};


/** Order from Topup */
export type TopupOrderProductsArgs = {
  code: Maybe<Scalars['String']>;
  serial_number: Maybe<Scalars['String']>;
};

/** User from Rapido */
export type User = {
  __typename?: 'User';
  /** Email from a Rapido user */
  email: Maybe<Scalars['String']>;
  /** First name from a Rapido user */
  first_name: Maybe<Scalars['String']>;
  /** Last name from a Rapido user */
  last_name: Maybe<Scalars['String']>;
  /** GSM number from a Rapido user */
  gsm_number: Maybe<Scalars['String']>;
  /** Address from user */
  address: Maybe<Scalars['String']>;
  /** Address line 2 from user */
  address2: Maybe<Scalars['String']>;
  /** House number from user */
  house_number: Maybe<Scalars['String']>;
  /** Postal code from user */
  postal_code: Maybe<Scalars['String']>;
  /** Place from user */
  place: Maybe<Scalars['String']>;
  /** State from user */
  state: Maybe<Scalars['String']>;
  /** Country id from user */
  country_id: Maybe<Scalars['String']>;
  /** Country alpha2 from user */
  country_alpha2: Maybe<Scalars['String']>;
  /** Birth date from user */
  birth_date: Maybe<Scalars['String']>;
};

/** Validates Phone */
export type ValidatePhone = {
  __typename?: 'ValidatePhone';
  /** formatted phone number */
  formattedPhoneNumber: Maybe<Scalars['String']>;
};

/** phone number challenge result */
export type ValidatePhoneChallengeType = {
  __typename?: 'ValidatePhoneChallengeType';
  /** the status of the challenge */
  status: Maybe<Scalars['String']>;
};

/** Visitor details data */
export type VisitorDetails = {
  __typename?: 'VisitorDetails';
  /** country based on ip address */
  geoIpCountry: Maybe<Scalars['String']>;
};

export type BinData = {
  __typename?: 'binData';
  network: Maybe<Scalars['String']>;
  issuer_country_code: Maybe<Scalars['String']>;
  issuer_name: Maybe<Scalars['String']>;
  regional_restriction: Maybe<Scalars['String']>;
  account_number_type: Maybe<Scalars['String']>;
  account_funding_type: Maybe<Scalars['String']>;
  prepaid_reloadable_indicator: Maybe<Scalars['String']>;
  product_usage_type: Maybe<Scalars['String']>;
  product_code: Maybe<Scalars['String']>;
  product_name: Maybe<Scalars['String']>;
};

/** List of orchastrators */
export enum PaymentOrchestrator {
  Cgpay = 'CGPAY',
  Primer = 'PRIMER'
}

export type ProductPricesFieldsFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'products_service_id' | 'available' | 'brand_name'>
  & { prices: Maybe<Array<Maybe<(
    { __typename?: 'Price' }
    & Pick<Price, 'local_amount' | 'local_discount_amount' | 'local_currency'>
  )>>> }
);

export type ChangePhoneNumberMutationVariables = Exact<{
  merchant: Merchant;
  phoneNumber: Scalars['String'];
  id: Scalars['ID'];
  hash: Scalars['String'];
}>;


export type ChangePhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { changePhoneNumber: Maybe<(
    { __typename?: 'OrderID' }
    & Pick<OrderId, 'orderId'>
  )> }
);

export type CreatePaymentMutationVariables = Exact<{
  merchant: Merchant;
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  email: Scalars['String'];
  newsletter: Scalars['Boolean'];
  currency: Currency;
  gift: Scalars['Boolean'];
  paymentMethodId: Scalars['String'];
  redirectUrls: PaymentRedirectUrlsType;
  paymentInformationData: Maybe<PaymentInformationData>;
  issuer: Maybe<Scalars['String']>;
  affiliateId: Maybe<Scalars['ID']>;
  redeemPhoneNumber: Maybe<Scalars['String']>;
  voucher: Maybe<Scalars['String']>;
  orderId: Maybe<Scalars['ID']>;
  orderHash: Maybe<Scalars['String']>;
  country: Maybe<Country>;
  locale: Maybe<Scalars['String']>;
  accountId: Maybe<Scalars['ID']>;
  value: Maybe<Scalars['Int']>;
  recaptchaToken: Maybe<Scalars['String']>;
  beaconId: Maybe<Scalars['String']>;
  ravelinDeviceId: Maybe<Scalars['String']>;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { payment: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'checkoutUrl'>
  )> }
);

export type CreatePhoneChallengeMutationVariables = Exact<{
  merchant: Merchant;
  phoneNumber: Scalars['String'];
  locale: Maybe<Scalars['String']>;
  localeOptions: Maybe<Scalars['String']>;
}>;


export type CreatePhoneChallengeMutation = (
  { __typename?: 'Mutation' }
  & { phoneChallenge: Maybe<(
    { __typename?: 'PhoneChallenge' }
    & Pick<PhoneChallenge, 'id'>
  )> }
);

export type RefundMutationVariables = Exact<{
  merchant: Merchant;
  reason: Maybe<Scalars['String']>;
  reasonMessage: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  hash: Scalars['String'];
}>;


export type RefundMutation = (
  { __typename?: 'Mutation' }
  & { refund: Maybe<(
    { __typename?: 'RefundRequest' }
    & Pick<RefundRequest, 'status'>
  )> }
);

export type CalculateOrderQueryVariables = Exact<{
  merchant: Merchant;
  currency: Currency;
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  country: Country;
  shopCountry: Maybe<Country>;
  paymentMethodId: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  voucher: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Int']>;
}>;


export type CalculateOrderQuery = (
  { __typename?: 'Query' }
  & { calculateOrder: Maybe<(
    { __typename?: 'CalculateOrderType' }
    & Pick<CalculateOrderType, 'isMinimumOrderPrice'>
    & { transactionCost: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )>, orderPrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value'>
    )>, totalPrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value'>
    )>, discountPrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value'>
    )>, serviceFee: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value'>
    )>, walletBalance: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value'>
    )>, extraAmountForApplicableReward: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value'>
    )> }
  )> }
);

export type MyOrderQueryVariables = Exact<{
  id: Scalars['ID'];
  order_type: Maybe<OrderType>;
}>;


export type MyOrderQuery = (
  { __typename?: 'Query' }
  & { myOrder: Maybe<(
    { __typename: 'TopupOrder' }
    & Pick<TopupOrder, 'id' | 'email' | 'status' | 'created_at' | 'country_name' | 'country_iso' | 'url' | 'pay_method' | 'payment_status' | 'ip' | 'comments' | 'price' | 'currency' | 'quantity' | 'service_fee' | 'discount_price' | 'total_price' | 'wallet_amount' | 'voucher' | 'hash' | 'open_range_value'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'first_name' | 'last_name'>
    )>, products: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'available' | 'visible' | 'shortDescription' | 'name' | 'display_name' | 'products_service_id' | 'product_kind' | 'brand_name' | 'redeem_type' | 'scam_notification_type'>
      & { codes: Maybe<Array<Maybe<(
        { __typename?: 'Code' }
        & Pick<Code, 'status' | 'code' | 'serial_number' | 'sicherheits_code'>
      )>>> }
    )>>>, rtr: Maybe<(
      { __typename?: 'RTR' }
      & Pick<Rtr, 'phone'>
    )>, transaction_cost: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )> }
  ) | (
    { __typename: 'ClassicOrder' }
    & Pick<ClassicOrder, 'id' | 'email' | 'created_at' | 'country_name' | 'country_iso' | 'pay_method' | 'payment_status' | 'status' | 'price' | 'currency' | 'quantity' | 'service_fee' | 'discount_price' | 'total_price' | 'voucher'>
    & { product: Maybe<(
      { __typename?: 'ClassicProduct' }
      & Pick<ClassicProduct, 'name' | 'image_url' | 'slug'>
    )>, user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'first_name' | 'last_name'>
    )>, rtr: Maybe<(
      { __typename?: 'RTR' }
      & Pick<Rtr, 'phone'>
    )>, transaction_cost: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )> }
  )> }
);

export type MyOrdersQueryVariables = Exact<{
  merchant_id: Scalars['Int'];
  order_type: Maybe<Array<OrderType> | OrderType>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type MyOrdersQuery = (
  { __typename?: 'Query' }
  & { myOrders: Maybe<Array<Maybe<(
    { __typename: 'TopupOrder' }
    & Pick<TopupOrder, 'id' | 'email' | 'created_at' | 'country_name' | 'country_iso' | 'url' | 'pay_method' | 'payment_status' | 'status' | 'ip' | 'comments' | 'price' | 'currency' | 'quantity' | 'service_fee' | 'discount_price' | 'total_price' | 'wallet_amount' | 'voucher' | 'hash' | 'open_range_value'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'first_name' | 'last_name'>
    )>, products: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'available' | 'visible' | 'shortDescription' | 'name' | 'display_name' | 'products_service_id' | 'product_kind' | 'brand_name' | 'redeem_type' | 'scam_notification_type'>
      & { codes: Maybe<Array<Maybe<(
        { __typename?: 'Code' }
        & Pick<Code, 'status' | 'code' | 'serial_number' | 'sicherheits_code'>
      )>>> }
    )>>>, transaction_cost: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )>, rtr: Maybe<(
      { __typename?: 'RTR' }
      & Pick<Rtr, 'phone'>
    )> }
  ) | (
    { __typename: 'ClassicOrder' }
    & Pick<ClassicOrder, 'id' | 'email' | 'created_at' | 'country_name' | 'country_iso' | 'pay_method' | 'payment_status' | 'status' | 'price' | 'currency' | 'quantity' | 'service_fee' | 'discount_price' | 'total_price' | 'voucher'>
    & { product: Maybe<(
      { __typename?: 'ClassicProduct' }
      & Pick<ClassicProduct, 'name' | 'image_url' | 'slug'>
    )>, user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'first_name' | 'last_name'>
    )>, transaction_cost: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )>, rtr: Maybe<(
      { __typename?: 'RTR' }
      & Pick<Rtr, 'phone'>
    )> }
  )>>> }
);

export type OrderQueryVariables = Exact<{
  merchant: Merchant;
  id: Scalars['ID'];
  hash: Scalars['String'];
  country: Maybe<Country>;
  locale: Maybe<Scalars['String']>;
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'hash' | 'status' | 'url' | 'payment_status' | 'order_currency' | 'comments' | 'price' | 'service_fee' | 'total_price' | 'pay_method' | 'voucher' | 'quantity' | 'open_range_value'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )>, products: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'products_service_id' | 'name'>
      & { codes: Maybe<Array<Maybe<(
        { __typename?: 'Code' }
        & Pick<Code, 'code' | 'serial_number' | 'sicherheits_code' | 'hidden' | 'redeem_url'>
      )>>> }
    )>>>, rtr: Maybe<(
      { __typename?: 'RTR' }
      & Pick<Rtr, 'phone'>
    )> }
  )> }
);

export type OrderStatusQueryVariables = Exact<{
  merchant: Merchant;
  id: Scalars['ID'];
  hash: Scalars['String'];
  country: Maybe<Country>;
  locale: Maybe<Scalars['String']>;
}>;


export type OrderStatusQuery = (
  { __typename?: 'Query' }
  & { orderStatus: Maybe<(
    { __typename?: 'Status' }
    & Pick<Status, 'status' | 'paymentStatus'>
  )> }
);

export type PaymentMethodsQueryVariables = Exact<{
  merchant: Merchant;
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  currency: Currency;
  email: Maybe<Scalars['String']>;
  voucher: Maybe<Scalars['String']>;
  locale: Maybe<Scalars['String']>;
  country: Maybe<Country>;
  value: Maybe<Scalars['Int']>;
}>;


export type PaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Maybe<Array<Maybe<(
    { __typename?: 'PaymentMethod' }
    & Pick<PaymentMethod, 'id' | 'label' | 'image' | 'disabled' | 'paymentInformationFields' | 'paymentInformationFieldsRequired' | 'geolocationCountryID' | 'shouldSeparateStreetHouseInputs'>
    & { transactionCost: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )>, issuers: Maybe<Array<Maybe<(
      { __typename?: 'PaymentMethodIssuer' }
      & Pick<PaymentMethodIssuer, 'id' | 'label'>
    )>>>, totalPrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )>, orderPrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )>, serviceFee: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )>, discountPrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'value' | 'currency'>
    )> }
  )>>> }
);

export type PrePaymentDataQueryVariables = Exact<{
  currencyCode: Scalars['String'];
  productId: Scalars['ID'];
  email: Maybe<Scalars['String']>;
  country: Scalars['String'];
  merchant: Merchant;
  value: Maybe<Scalars['Int']>;
}>;


export type PrePaymentDataQuery = (
  { __typename?: 'Query' }
  & { prePaymentData: Maybe<(
    { __typename?: 'PrePaymentData' }
    & Pick<PrePaymentData, 'geoIpCountry' | 'showPaysafeForm' | 'currencyScale'>
    & { billingFields: Maybe<Array<Maybe<(
      { __typename?: 'NewBillingFields' }
      & Pick<NewBillingFields, 'fieldName' | 'required' | 'step'>
    )>>> }
  )> }
);

export type ProductsQueryVariables = Exact<{
  productsServiceIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  merchant: Merchant;
  currency: Maybe<Currency>;
  quantity: Maybe<Scalars['Int']>;
  country: Maybe<Country>;
}>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { products: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'redeem_type' | 'product_type' | 'product_kind' | 'scam_notification_type' | 'scamNotificationType'>
    & { service_fees: Maybe<Array<Maybe<(
      { __typename?: 'Service_Fee' }
      & Pick<Service_Fee, 'local_service_fee'>
    )>>> }
    & ProductPricesFieldsFragment
  )>>> }
);

export type RechargeClassicCountriesQueryVariables = Exact<{
  language: Language;
}>;


export type RechargeClassicCountriesQuery = (
  { __typename?: 'Query' }
  & { rechargeClassicCountries: Maybe<Array<Maybe<(
    { __typename?: 'RechargeClassicCountry' }
    & Pick<RechargeClassicCountry, 'name' | 'nameTranslated' | 'countryCode' | 'iso' | 'slug'>
  )>>> }
);

export type ValidatePhoneNumberQueryVariables = Exact<{
  countryAbv: Scalars['String'];
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;


export type ValidatePhoneNumberQuery = (
  { __typename?: 'Query' }
  & { validatePhoneNumber: Maybe<(
    { __typename?: 'ValidatePhone' }
    & Pick<ValidatePhone, 'formattedPhoneNumber'>
  )> }
);

export const ProductPricesFieldsFragmentDoc = gql`
    fragment productPricesFields on Product {
  id
  products_service_id
  available
  brand_name
  prices(currencyEnum: $currency) {
    local_amount
    local_discount_amount
    local_currency
  }
}
    `;
export const ChangePhoneNumberDocument = gql`
    mutation changePhoneNumber($merchant: Merchant!, $phoneNumber: String!, $id: ID!, $hash: String!) {
  changePhoneNumber(
    merchant: $merchant
    phoneNumber: $phoneNumber
    id: $id
    hash: $hash
  ) {
    orderId
  }
}
    `;
export const CreatePaymentDocument = gql`
    mutation createPayment($merchant: Merchant!, $productId: ID!, $quantity: Int!, $email: String!, $newsletter: Boolean!, $currency: Currency!, $gift: Boolean!, $paymentMethodId: String!, $redirectUrls: PaymentRedirectUrlsType!, $paymentInformationData: PaymentInformationData, $issuer: String, $affiliateId: ID, $redeemPhoneNumber: String, $voucher: String, $orderId: ID, $orderHash: String, $country: Country, $locale: String, $accountId: ID, $value: Int, $recaptchaToken: String, $beaconId: String, $ravelinDeviceId: String) {
  payment: createPayment(
    currency: $currency
    merchant: $merchant
    productId: $productId
    quantity: $quantity
    paymentMethodId: $paymentMethodId
    email: $email
    newsletter: $newsletter
    gift: $gift
    redirectUrls: $redirectUrls
    paymentInformationData: $paymentInformationData
    issuer: $issuer
    affiliateId: $affiliateId
    redeemPhoneNumber: $redeemPhoneNumber
    voucher: $voucher
    orderId: $orderId
    orderHash: $orderHash
    country: $country
    locale: $locale
    accountId: $accountId
    value: $value
    recaptchaToken: $recaptchaToken
    beaconId: $beaconId
    ravelinDeviceId: $ravelinDeviceId
  ) {
    checkoutUrl
  }
}
    `;
export const CreatePhoneChallengeDocument = gql`
    mutation createPhoneChallenge($merchant: Merchant!, $phoneNumber: String!, $locale: String, $localeOptions: String) {
  phoneChallenge: createPhoneChallenge(
    merchant: $merchant
    phoneNumber: $phoneNumber
    locale: $locale
    localeOptions: $localeOptions
  ) {
    id
  }
}
    `;
export const RefundDocument = gql`
    mutation refund($merchant: Merchant!, $reason: String, $reasonMessage: String, $id: ID!, $hash: String!) {
  refund: requestRefund(
    merchant: $merchant
    id: $id
    reason: $reason
    reasonMessage: $reasonMessage
    hash: $hash
  ) {
    status
  }
}
    `;
export const CalculateOrderDocument = gql`
    query calculateOrder($merchant: Merchant!, $currency: Currency!, $productId: ID!, $quantity: Int!, $country: Country!, $shopCountry: Country, $paymentMethodId: String, $email: String, $voucher: String, $value: Int) {
  calculateOrder(
    merchant: $merchant
    currency: $currency
    productId: $productId
    quantity: $quantity
    country: $country
    shopCountry: $shopCountry
    paymentMethodId: $paymentMethodId
    email: $email
    voucher: $voucher
    value: $value
  ) {
    transactionCost {
      value
      currency
    }
    orderPrice {
      value
    }
    totalPrice {
      value
    }
    discountPrice {
      value
    }
    serviceFee {
      value
    }
    walletBalance {
      value
    }
    extraAmountForApplicableReward {
      value
    }
    isMinimumOrderPrice
  }
}
    `;
export const MyOrderDocument = gql`
    query myOrder($id: ID!, $order_type: OrderType) {
  myOrder(id: $id, order_type: $order_type) {
    __typename
    ... on TopupOrder {
      id
      email
      status
      created_at
      country_name
      country_iso
      url
      pay_method
      payment_status
      status
      ip
      comments
      user {
        email
        first_name
        last_name
      }
      products {
        id
        codes {
          status
          code
          serial_number
          sicherheits_code
        }
        available
        visible
        shortDescription
        name
        display_name
        products_service_id
        product_kind
        brand_name
        redeem_type
        scam_notification_type
      }
      price
      currency
      quantity
      service_fee
      discount_price
      total_price
      wallet_amount
      rtr {
        phone
      }
      transaction_cost {
        value
        currency
      }
      voucher
      hash
      open_range_value
    }
    ... on ClassicOrder {
      id
      email
      created_at
      country_name
      country_iso
      pay_method
      payment_status
      status
      product {
        name
        image_url
        slug
      }
      user {
        email
        first_name
        last_name
      }
      price
      currency
      quantity
      service_fee
      discount_price
      total_price
      rtr {
        phone
      }
      transaction_cost {
        value
        currency
      }
      voucher
    }
  }
}
    `;
export const MyOrdersDocument = gql`
    query myOrders($merchant_id: Int!, $order_type: [OrderType!], $limit: Int!, $page: Int!) {
  myOrders(
    merchant_id: $merchant_id
    order_type: $order_type
    limit: $limit
    page: $page
  ) {
    __typename
    ... on ClassicOrder {
      id
      email
      created_at
      country_name
      country_iso
      pay_method
      payment_status
      status
      product {
        name
        image_url
        slug
      }
      user {
        email
        first_name
        last_name
      }
      price
      currency
      quantity
      service_fee
      discount_price
      total_price
      transaction_cost {
        value
        currency
      }
      rtr {
        phone
      }
      voucher
    }
    ... on TopupOrder {
      id
      email
      created_at
      country_name
      country_iso
      url
      pay_method
      payment_status
      status
      ip
      comments
      user {
        email
        first_name
        last_name
      }
      products {
        id
        codes {
          status
          code
          serial_number
          sicherheits_code
        }
        available
        visible
        shortDescription
        name
        display_name
        products_service_id
        product_kind
        brand_name
        redeem_type
        scam_notification_type
      }
      price
      currency
      quantity
      service_fee
      discount_price
      total_price
      wallet_amount
      transaction_cost {
        value
        currency
      }
      rtr {
        phone
      }
      voucher
      hash
      open_range_value
    }
  }
}
    `;
export const OrderDocument = gql`
    query order($merchant: Merchant!, $id: ID!, $hash: String!, $country: Country, $locale: String) {
  order(
    merchant: $merchant
    id: $id
    hash: $hash
    country: $country
    locale: $locale
  ) {
    id
    hash
    status
    url
    payment_status
    order_currency
    comments
    price
    service_fee
    total_price
    pay_method
    voucher
    user {
      email
    }
    quantity
    products {
      id
      products_service_id
      name
      codes {
        code
        serial_number
        sicherheits_code
        hidden
        redeem_url
      }
    }
    rtr {
      phone
    }
    open_range_value
  }
}
    `;
export const OrderStatusDocument = gql`
    query orderStatus($merchant: Merchant!, $id: ID!, $hash: String!, $country: Country, $locale: String) {
  orderStatus(
    merchant: $merchant
    id: $id
    hash: $hash
    country: $country
    locale: $locale
  ) {
    status
    paymentStatus
  }
}
    `;
export const PaymentMethodsDocument = gql`
    query paymentMethods($merchant: Merchant!, $productId: ID!, $quantity: Int!, $currency: Currency!, $email: String, $voucher: String, $locale: String, $country: Country, $value: Int) {
  paymentMethods(
    merchant: $merchant
    productId: $productId
    quantity: $quantity
    currency: $currency
    email: $email
    voucher: $voucher
    country: $country
    locale: $locale
    value: $value
  ) {
    id
    label
    image
    disabled
    transactionCost {
      value
      currency
    }
    issuers {
      id
      label
    }
    paymentInformationFields
    paymentInformationFieldsRequired
    totalPrice {
      value
      currency
    }
    orderPrice {
      value
      currency
    }
    serviceFee {
      value
      currency
    }
    discountPrice {
      value
      currency
    }
    geolocationCountryID
    shouldSeparateStreetHouseInputs
  }
}
    `;
export const PrePaymentDataDocument = gql`
    query prePaymentData($currencyCode: String!, $productId: ID!, $email: String, $country: String!, $merchant: Merchant!, $value: Int) {
  prePaymentData(
    currencyCode: $currencyCode
    productId: $productId
    email: $email
    country: $country
    merchant: $merchant
    value: $value
  ) {
    geoIpCountry
    showPaysafeForm
    currencyScale
    billingFields {
      fieldName
      required
      step
    }
  }
}
    `;
export const ProductsDocument = gql`
    query products($productsServiceIds: [Int]!, $merchant: Merchant!, $currency: Currency, $quantity: Int, $country: Country) {
  products(
    products_service_ids: $productsServiceIds
    merchant: $merchant
    currency: $currency
    quantity: $quantity
    country: $country
  ) {
    ...productPricesFields
    redeem_type
    product_type
    product_kind
    scam_notification_type
    scamNotificationType
    service_fees {
      local_service_fee
    }
  }
}
    ${ProductPricesFieldsFragmentDoc}`;
export const RechargeClassicCountriesDocument = gql`
    query rechargeClassicCountries($language: Language!) {
  rechargeClassicCountries(language: $language) {
    name
    nameTranslated
    countryCode
    iso
    slug
  }
}
    `;
export const ValidatePhoneNumberDocument = gql`
    query validatePhoneNumber($countryAbv: String!, $countryCode: String!, $phoneNumber: String!) {
  validatePhoneNumber(
    countryAbv: $countryAbv
    countryCode: $countryCode
    phoneNumber: $phoneNumber
  ) {
    formattedPhoneNumber
  }
}
    `;
export type Requester<C= {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    changePhoneNumber(variables: ChangePhoneNumberMutationVariables, options?: C): Promise<ChangePhoneNumberMutation> {
      return requester<ChangePhoneNumberMutation, ChangePhoneNumberMutationVariables>(ChangePhoneNumberDocument, variables, options);
    },
    createPayment(variables: CreatePaymentMutationVariables, options?: C): Promise<CreatePaymentMutation> {
      return requester<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, variables, options);
    },
    createPhoneChallenge(variables: CreatePhoneChallengeMutationVariables, options?: C): Promise<CreatePhoneChallengeMutation> {
      return requester<CreatePhoneChallengeMutation, CreatePhoneChallengeMutationVariables>(CreatePhoneChallengeDocument, variables, options);
    },
    refund(variables: RefundMutationVariables, options?: C): Promise<RefundMutation> {
      return requester<RefundMutation, RefundMutationVariables>(RefundDocument, variables, options);
    },
    calculateOrder(variables: CalculateOrderQueryVariables, options?: C): Promise<CalculateOrderQuery> {
      return requester<CalculateOrderQuery, CalculateOrderQueryVariables>(CalculateOrderDocument, variables, options);
    },
    myOrder(variables: MyOrderQueryVariables, options?: C): Promise<MyOrderQuery> {
      return requester<MyOrderQuery, MyOrderQueryVariables>(MyOrderDocument, variables, options);
    },
    myOrders(variables: MyOrdersQueryVariables, options?: C): Promise<MyOrdersQuery> {
      return requester<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument, variables, options);
    },
    order(variables: OrderQueryVariables, options?: C): Promise<OrderQuery> {
      return requester<OrderQuery, OrderQueryVariables>(OrderDocument, variables, options);
    },
    orderStatus(variables: OrderStatusQueryVariables, options?: C): Promise<OrderStatusQuery> {
      return requester<OrderStatusQuery, OrderStatusQueryVariables>(OrderStatusDocument, variables, options);
    },
    paymentMethods(variables: PaymentMethodsQueryVariables, options?: C): Promise<PaymentMethodsQuery> {
      return requester<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, variables, options);
    },
    prePaymentData(variables: PrePaymentDataQueryVariables, options?: C): Promise<PrePaymentDataQuery> {
      return requester<PrePaymentDataQuery, PrePaymentDataQueryVariables>(PrePaymentDataDocument, variables, options);
    },
    products(variables: ProductsQueryVariables, options?: C): Promise<ProductsQuery> {
      return requester<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables, options);
    },
    rechargeClassicCountries(variables: RechargeClassicCountriesQueryVariables, options?: C): Promise<RechargeClassicCountriesQuery> {
      return requester<RechargeClassicCountriesQuery, RechargeClassicCountriesQueryVariables>(RechargeClassicCountriesDocument, variables, options);
    },
    validatePhoneNumber(variables: ValidatePhoneNumberQueryVariables, options?: C): Promise<ValidatePhoneNumberQuery> {
      return requester<ValidatePhoneNumberQuery, ValidatePhoneNumberQueryVariables>(ValidatePhoneNumberDocument, variables, options);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;