import { Context, Middleware } from '@nuxt/types'

// redirect signed in user to homepage when trying to access authentication routes
const redirectLoggedInUserMiddleware: Middleware = (context: Context): void => {
  const {
    route: { name },
    redirect,
    store,
  } = context
  if (!name) {
    return
  }

  const isAuthenticated: boolean = store.getters['user/isAuthenticated']
  const { redirectIfLoggedIn } = context.route.matched[0].meta

  if (redirectIfLoggedIn && isAuthenticated) {
    redirect(302, context.$contextPath('/'))
  }
}

export default redirectLoggedInUserMiddleware
