import { Context, Middleware } from '@nuxt/types'
import {
  getConsolidatedCookie,
  setConsolidatedCookie,
} from '~/services/ConsolidatedCookie'

const disableAccountAnalyticsFeatureGuard: Middleware = (
  context: Context
): void => {
  if (!process.server) return

  const {
    app,
    route: { query, name },
    store,
  } = context
  if (!name) {
    return
  }

  const accountAnalyticsDisabled =
    query.disable_account_analytics === 'true' ||
    getConsolidatedCookie(app, 'feature_flags', 'disable_account_analytics')

  if (accountAnalyticsDisabled) {
    setConsolidatedCookie(
      app,
      'feature_flags',
      'disable_account_analytics',
      accountAnalyticsDisabled
    )
  }

  store.dispatch('context/disableAccountAnalytics', accountAnalyticsDisabled)
}

export default disableAccountAnalyticsFeatureGuard
