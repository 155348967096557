import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { ICMSIconModel, IconModel } from '../base/Icon'
import { LinkModel, ICMSLink, ILink, isCMSLink } from '../base/Link'
import { IImageModel } from '../base/Image'
import {
  IExternalLinkModel,
  ICMSExternalLinkModel,
  ExternalLinkModel,
} from '../base/ExternalLink'

export interface IPaymentMethod {
  link?: ILink | IExternalLinkModel
  name: string
  icon: IImageModel
  topupId: string
}

export interface ICMSPaymentMethod extends Entry {
  fields: {
    link?: ICMSLink | ICMSExternalLinkModel
    name: string
    icon: ICMSIconModel
    topupId: string
  }
}

export class PaymentMethodModel extends EntryModel {
  protected readonly link?: ILink | IExternalLinkModel
  protected readonly name: string
  protected readonly icon: IImageModel
  protected readonly topupId: string

  constructor(paymentMethodModel: ICMSPaymentMethod) {
    super(paymentMethodModel)
    const {
      fields: { link },
    } = paymentMethodModel
    this.icon = new IconModel(this.requiredField('icon')).toJSON()
    this.name = this.requiredField('name')
    this.topupId = this.requiredField('topupId')

    if (link) {
      this.link = !isCMSLink(link)
        ? new ExternalLinkModel(link).toJSON()
        : new LinkModel(link).toJSON()
    }
  }

  toJSON(): IPaymentMethod {
    return {
      link: this.link,
      name: this.name,
      icon: this.icon,
      topupId: this.topupId,
    }
  }
}
