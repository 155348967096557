/* eslint-disable camelcase */
import { addTopLevelCategory } from '../utils'

export type TStockStatus =
  | 'on_stock'
  | 'out_of_stock'
  | 'discontinued'
  | 'invalid_product'
export interface IECommerceProduct {
  id: string
  stock_status: TStockStatus
  name: string
  product_brand: string | null
  product_theme: string | null
  product_price_stored?: string
  price?: string
  category?: string
  brand?: string
  quantity: number
  discontinued?: boolean | null
  invalidProduct?: boolean | null
}
export interface IECommerceProducts {
  products: IECommerceProduct[]
}

export interface IECommerceActionField {
  list: string
}
export class ECommerceProducts {
  private products: IECommerceProduct[] = []
  private actionField: IECommerceActionField | null

  constructor(
    {
      productId,
      title,
      available,
      price,
      brandName,
      productBrand: parentDenominationTitle,
      productCategory,
      parentDenominationType,
      quantity,
      productKind,
      discontinued,
      invalidProduct,
    },
    addList = false,
    listValue: string | null = null
  ) {
    let stockStatus = available ? 'on_stock' : 'out_of_stock'
    if (discontinued) stockStatus = 'discontinued'
    if (invalidProduct) stockStatus = 'invalid_product'
    const { localAmount } = price
    let parentDenomination

    if (parentDenominationType === 'brand') {
      parentDenomination = {
        product_brand: brandName,
        product_theme: null,
      }
    } else if (parentDenominationType === 'theme') {
      parentDenomination = {
        product_brand: null,
        product_theme: parentDenominationTitle,
      }
    }

    const formattedPrice = localAmount ? localAmount.toString() : undefined

    if (addList) {
      this.actionField = listValue
        ? { list: listValue }
        : { list: `${productCategory}+${parentDenominationTitle}` }
    } else {
      this.actionField = null
    }

    const category = addTopLevelCategory(productKind, productCategory)

    this.products.push({
      id: productId.toString(),
      name: title,
      stock_status: stockStatus,
      product_price_stored: formattedPrice,
      price: formattedPrice,
      brand: parentDenominationTitle,
      ...parentDenomination,
      category,
      quantity: quantity.toString(),
    })
  }

  toJSON(): IECommerceProducts {
    return {
      ...(this.actionField ? { actionField: this.actionField } : {}),
      products: this.products,
    }
  }
}
/* eslint-enable camelcase */
