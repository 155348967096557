export default {
  'amazon-de': 'amazon',
  'amazon-de/10-eur': 'amazon',
  'amazon-de/100-eur': 'amazon',
  'amazon-de/15-eur': 'amazon',
  'amazon-de/25-eur': 'amazon',
  'amazon-fr': 'amazon',
  'amazon-fr/': 'amazon',
  'amazon-fr/10-eur': 'amazon/10-eur-fr',
  'amazon-fr/100-eur': 'amazon/100-eur-fr',
  'amazon-fr/15-eur': 'amazon/15-eur-fr',
  'amazon-fr/25-eur': 'amazon/25-eur-fr',
  'amazon-fr/50-eur': 'amazon/50-eur-fr',
  'app-store-itunes': 'app-store-itunes',
  'app-store-itunes/': 'app-store-itunes',
  'app-store-itunes/100-eur': 'app-store-itunes/100-eur-be',
  'app-store-itunes/15-eur': 'app-store-itunes/15-eur-be',
  'app-store-itunes/25-eur': 'app-store-itunes/25-eur-be',
  'app-store-itunes/50-eur': 'app-store-itunes/50-eur-be',
  base: 'base',
  'base/': 'base',
  'base/10-eur': 'base/10-eur',
  'base/15-eur': 'base/15-eur',
  'base/20-eur': 'base/20-eur',
  'base/30-eur': 'base/30-eur',
  'base/5-eur': 'base/5-eur',
  battlenet: 'blizzard',
  'battlenet/100-eur': 'blizzard/100-eur-be',
  'battlenet/20-eur': 'blizzard/20-eur-be',
  'battlenet/50-eur': 'blizzard/50-eur-be',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  'bol-com': 'bol-com',
  'bol-com/10-eur': 'bol-com/10-eur-be',
  'bol-com/25-eur': 'bol-com/25-eur-be',
  'bol-com/50-eur': 'bol-com/50-eur-be',
  'cartes-cadeaux-de-divertissement': 'entertainment-gift-cards',
  'cartes-cadeaux-shopping': 'shopping-gift-cards',
  'cartes-de-paiement-prepayees': 'prepaid-credit-cards',
  'cartes-prepayees-jeux-video': 'gaming-cards',
  cashlib: 'cashlib',
  'cashlib/10-eur': 'cashlib/10-eur-be',
  'cashlib/100-eur': 'cashlib/100-eur-be',
  'cashlib/20-eur': 'cashlib/20-eur-be',
  'cashlib/50-eur': 'cashlib/50-eur-be',
  cashu: 'prepaid-credit-cards',
  'comment-cela-fonctionne': 'how-it-works',
  'credit-d-appel': 'mobile-top-up',
  'crypto-monnaie': 'cryptocurrency',
  'crypto-voucher': 'crypto-voucher',
  'crypto-voucher/100-eur': 'crypto-voucher/100-eur',
  'declaration-relative-aux-cookies': 'cookie-statement',
  'delight-mobile': 'mobile-top-up',
  dent: 'mobile-top-up',
  'droit-de-retractation': 'how-it-works',
  ecovoucher: 'ecovoucher',
  'eve-online': 'gaming-cards',
  'eve-online-': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  'fifa-points/': 'gaming-cards',
  'fifa-points/1050-xbox-one': 'gaming-cards',
  'fifa-points/1600-xbox-one': 'gaming-cards',
  'fifa-points/2200-xbox-one': 'gaming-cards',
  'fifa-points/4600-xbox-one': 'gaming-cards',
  flexepin: 'flexepin',
  'flexepin/10-eur': 'flexepin/10-eur',
  fortnite: 'fortnite',
  'free-fire': 'free-fire',
  'google-play': 'google-play',
  'google-play/': 'google-play',
  'google-play/100-eur': 'google-play/100-eur-be',
  'google-play/15-eur': 'google-play/15-eur-be',
  'google-play/25-eur': 'google-play/25-eur-be',
  'google-play/50-eur': 'google-play/50-eur-be',
  'guild-wars': 'gaming-cards',
  'jim-mobile': 'jim-mobile',
  'jim-mobile/10-eur': 'jim-mobile/10-eur',
  'jim-mobile/15-eur': 'jim-mobile/15-eur',
  'jim-mobile/20-eur': 'jim-mobile/20-eur',
  'jim-mobile/5-eur': 'jim-mobile/5-eur',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends/10-eur',
  'league-of-legends/20-eur': 'league-of-legends/20-eur',
  lycamobile: 'lycamobile',
  'lycamobile/': 'lycamobile',
  'lycamobile/10-eur': 'lycamobile/10-eur-be',
  'lycamobile/20-eur': 'lycamobile/20-eur-be',
  'lycamobile/30-eur': 'lycamobile/30-eur-be',
  'lycamobile/5-eur': 'lycamobile/5-eur-be',
  'modes-de-paiement': 'payment-methods',
  ncoin: 'ncoin',
  'ncoin-': 'ncoin',
  'ncoin/5-eur': 'ncoin',
  'ncoin/50-eur': 'ncoin',
  neosurf: 'neosurf',
  'neosurf/': 'neosurf',
  'neosurf/10-eur': 'neosurf/10-eur-be',
  'neosurf/100-eur': 'neosurf/100-eur-be',
  'neosurf/15-eur': 'neosurf/15-eur-be',
  'neosurf/30-eur': 'neosurf/30-eur-be',
  'neosurf/50-eur': 'neosurf/50-eur-be',
  netflix: 'netflix',
  'netflix/': 'netflix',
  'netflix/15-eur': 'netflix/15-eur',
  'netflix/25-eur': 'netflix/25-eur-be',
  'netflix/50-eur': 'netflix/50-eur-be',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop/15-eur-be',
  'nintendo-eshop/25-eur': 'nintendo-eshop/25-eur-be',
  'nintendo-eshop/50-eur': 'nintendo-eshop/50-eur-be',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-mois': 'nintendo-switch-online/12-months-be',
  'nintendo-switch-online/3-mois': 'nintendo-switch-online/3-months-be',
  'openbucks-us': 'openbucks',
  orange: 'orange',
  'orange/15-eur': 'orange/15-eur-be',
  'orange/5-eur': 'orange/5-eur-be',
  'orange/50-eur': 'orange/50-eur-be',
  paysafecard: 'paysafecard',
  'paysafecard/': 'paysafecard',
  'paysafecard/10-eur': 'paysafecard',
  'paysafecard/100-eur': 'paysafecard',
  'paysafecard/25-eur': 'paysafecard',
  'paysafecard/50-eur': 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/': 'playstation-plus',
  'playstation-plus/12-mois': 'playstation-plus',
  'playstation-plus/3-mois': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/': 'playstation-store',
  'playstation-store/10-eur': 'playstation-store/10-eur-be',
  'playstation-store/20-eur': 'playstation-store/20-eur-be',
  'playstation-store/50-eur': 'playstation-store/50-eur-be',
  'politique-de-confidentialite': 'privacy-statement',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'prepaid-mastercard/10-eur': 'prepaid-credit-cards',
  proximus: 'proximus',
  'proximus/': 'proximus',
  'proximus/15-eur': 'proximus',
  'proximus/25-eur': 'proximus/25-eur',
  'proximus/5-eur': 'proximus/5-eur',
  'proximus/50-eur': 'proximus/50-eur',
  'pubg-us': 'pubg',
  'qui-sommes-nous': 'https://company.recharge.com',
  'rakuten-kobo': 'kobo-rakuten',
  'rakuten-kobo/': 'kobo-rakuten',
  'rakuten-kobo/10-eur': 'kobo-rakuten',
  'rakuten-kobo/50-eur': 'kobo-rakuten',
  'red-dead-online': 'gaming-cards',
  roblox: 'roblox',
  'roblox/10-eur': 'roblox',
  'roblox/11-eur-xbox-one': 'roblox',
  'roblox/20-eur': 'roblox',
  'roblox/20-eur-xbox-one': 'roblox',
  'roblox/50-eur-xbox-one': 'roblox',
  'roblox/6-eur-xbox-one': 'roblox',
  runescape: 'runescape',
  'runescape/30-jours': 'runescape/30-days',
  'runescape/90-jours': 'runescape/90-days',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/10-eur': 'spotify-premium/10-eur-be',
  'spotify-premium/30-eur': 'spotify-premium/30-eur-be',
  'spotify-premium/60-eur': 'spotify-premium/60-eur-be',
  steam: 'steam',
  'steam/10-eur': 'steam/10-eur',
  'steam/20-eur': 'steam/20-eur',
  'steam/25-eur': 'steam/25-eur',
  'steam/5-eur': 'steam/5-eur',
  'steam/50-eur': 'steam/50-eur',
  'ticket-premium': 'ticket-premium',
  'ticket-premium/10-eur': 'ticket-premium',
  'ticket-premium/100-eur': 'ticket-premium',
  'ticket-premium/25-eur': 'ticket-premium',
  'ticket-premium/50-eur': 'ticket-premium',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'transcash/100-eur': 'transcash/100-eur',
  'transcash/20-eur': 'transcash/20-eur',
  'transcash/50-eur': 'transcash/50-eur',
  'vectone-mobile': 'vectone-mobile',
  'vectone-mobile/10-eur': 'vectone-mobile/10-eur-be',
  'vectone-mobile/20-eur': 'vectone-mobile/20-eur-be',
  'vectone-mobile/5-eur': 'vectone-mobile/5-eur-be',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-days': 'world-of-warcraft',
  'world-of-warcraftx': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card/10-eur': 'xbox-gift-card/10-eur-be',
  'xbox-gift-card/15-eur': 'xbox-gift-card/15-eur-be',
  'xbox-gift-card/20-eur': 'xbox-gift-card/20-eur-be',
  'xbox-gift-card/25-eur': 'xbox-gift-card/25-eur-be',
  'xbox-gift-card/5-eur': 'xbox-gift-card/5-eur-be',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/': 'xbox-live-gold',
  'xbox-live-gold/12-mois': 'xbox-live-gold/12-months',
  'xbox-live-gold/150-gold-bars-xbox-one': 'xbox-live-gold',
  'xbox-live-gold/3-mois': 'xbox-live-gold/3-months',
  zalando: 'zalando',
  'zalando/': 'zalando',
  'zalando/10-eur': 'zalando/10-eur-be',
  'zalando/15-eur': 'zalando/15-eur-be',
  'zalando/20-eur': 'zalando/20-eur-be',
  'zalando/25-eur': 'zalando',
  'zalando/50-eur': 'zalando/50-eur-be',
  belwaarde: 'mobile-top-up',
  betaalmethoden: 'payment-methods',
  'cookie-verklaring': 'cookie-statement',
  cryptocurrency: 'cryptocurrency',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  'gaming-gift-cards': 'gaming-cards',
  herroepingsrecht: 'how-it-works',
  'hoe-het-werkt': 'how-it-works',
  'over-ons': 'https://company.recharge.com',
  'payment-methods': 'payment-methods',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'privacy-beleid': 'privacy-statement',
  'runescape-': 'runescape',
  'shopping-gift-cards': 'shopping-gift-cards',
}
