import { EntryModel } from '../base/Entry'
import { IInfoBox, InfoBoxModel } from './InfoBox'

export interface IInfoBoxList {
  items: IInfoBox[]
}
export class InfoBoxListModel extends EntryModel {
  protected readonly items: IInfoBox[]
  constructor(infoBoxModel) {
    super(infoBoxModel)
    const {
      fields: { items },
    } = infoBoxModel
    this.items = items.map(item => new InfoBoxModel(item).toJSON())
  }

  toJSON(): IInfoBoxList {
    return {
      items: this.items,
    }
  }
}
