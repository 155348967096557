export const castBoolStringToBool = boolString => {
  if (typeof boolString !== 'string') return boolString

  const normalizedString = boolString.toLowerCase()

  if (normalizedString === 'true') return true
  if (normalizedString === 'false') return false

  return boolString
}
