
import { SpaceConfig } from './enums'
import {
  highlighted,
  space,
  disable,
  margin,
  borderRadius,
  overflowHidden,
  removeBorder,
  borderColor,
  backgroundColor,
  staticCard,
} from './props'

export default {
  props: {
    highlighted,
    space,
    disable,
    margin,
    borderRadius,
    overflowHidden,
    removeBorder,
    borderColor,
    backgroundColor,
    staticCard,
    extendedClass: {
      default: '',
      type: String,
    },
  },
  computed: {
    classes() {
      const base = `${this.margin} ${this.backgroundColor}`
      const flexClass = this.staticCard ? 'flex-shrink-0' : 'flex-1'
      let borderClasses = `border ${this.borderColor} border-solid`
      if (this.removeBorder) {
        borderClasses = ''
      }
      const highlight = this.highlighted
        ? 'border-t-4 rounded-t-none border-t-highlight'
        : ''
      const padding = `p-${SpaceConfig[this.space]}`
      const disabledClass = this.disable ? 'opacity-25' : ''
      const borderRadiusClass =
        this.borderRadius === 'default'
          ? 'rounded'
          : `rounded-${this.borderRadius}`

      const removeBorderRadius =
        this.borderRadius === 'none' ? 'rounded-none' : ''
      return [
        flexClass,
        base,
        highlight,
        padding,
        disabledClass,
        borderRadiusClass,
        removeBorderRadius,
        borderClasses,
        { 'overflow-hidden': this.overflowHidden },
      ]
    },
  },
}
