import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { TPageType } from '../base/Page'
import { BrandInfoModel, ICMSBrandInfo } from './BrandInfo'
import { CategoryTypes } from '~/enums/categories'

export interface IParentPageInfo {
  brandCategory?: CategoryTypes
  title: string
  pageType: TPageType
}

export interface ICMSParentPageInfo extends Entry {
  fields: {
    brandInfo: ICMSBrandInfo
    title: string
    pageType: TPageType
  }
}

export class ParentPageInfoModel extends EntryModel {
  protected readonly brandCategory?: CategoryTypes
  protected readonly title: string
  protected readonly pageType: TPageType
  constructor(model: ICMSParentPageInfo) {
    super(model)
    const {
      fields: { title },
    } = model
    this.title = title
    this.pageType = this.requiredField('pageType')
    const brandInfo = new BrandInfoModel(
      this.requiredField('brandInfo')
    ).toJSON()

    this.brandCategory = brandInfo.brandCategory
  }

  toJSON(): IParentPageInfo {
    return {
      title: this.title,
      pageType: this.pageType,
      brandCategory: this.brandCategory,
    }
  }
}
