import { MutationTree } from 'vuex'

import { IContextStore } from './interfaces'
import { MUTATION_TYPES } from './mutation-types'

const mutations: MutationTree<IContextStore> = {
  [MUTATION_TYPES.SET_LOCALE](state, locale: string): void {
    state.locale = locale
    this.$sentry.configureScope(scope => {
      scope.setTag('locale', locale)
    })
  },
  setMarketplace(state, marketplace: IContextStore['marketplace']) {
    state.marketplace = marketplace
  },
  [MUTATION_TYPES.SET_CMS_LOCALE](state, cmsLocale: string): void {
    state.cmsLocale = cmsLocale
  },
  [MUTATION_TYPES.SET_COUNTRY_CODE](state, countryCode: string): void {
    state.countryCode = countryCode
  },
  [MUTATION_TYPES.SET_CURRENCY](state, currency: string): void {
    state.currency = currency
    this.$sentry.configureScope(scope => {
      scope.setTag('currency', currency)
    })
  },
  [MUTATION_TYPES.SET_MERCHANT](state, merchant: string): void {
    state.merchant = merchant
    this.$sentry.configureScope(scope => {
      scope.setTag('merchant', merchant)
    })
  },
  [MUTATION_TYPES.SET_ORIGIN_MERCHANT_RECHARGE_CLASSIC](
    state,
    isOriginRechargeClassic: boolean
  ): void {
    state.comesFromRechargeClassic = isOriginRechargeClassic
  },
  [MUTATION_TYPES.SET_IS_REDIRECTED_FROM_RAPIDO](
    state,
    isRedirectedFromRapido: boolean
  ): void {
    state.isRedirectedFromRapido = isRedirectedFromRapido
  },
  [MUTATION_TYPES.SET_LAST_PRODUCT_SEEN_BY_COUNTRY](
    state: IContextStore,
    { productId, countryAbv }: { productId: number; countryAbv: string }
  ): void {
    state.lastProductSeenByCountry = {
      ...state.lastProductSeenByCountry,
      [countryAbv]: productId,
    }
  },
  [MUTATION_TYPES.SET_RAPIDO_SEGMENT](state, hasRapidoSegment: boolean): void {
    state.hasRapidoSegment = hasRapidoSegment
  },
  [MUTATION_TYPES.SET_SHOW_ERROR_DETAILS](
    state,
    showErrorDetails: boolean
  ): void {
    state.showErrorDetails = showErrorDetails
  },
  [MUTATION_TYPES.SET_DIRECTION](state, direction: string): void {
    state.direction = direction
    this.$sentry.configureScope(scope => {
      scope.setTag('direction', direction)
    })
  },
  [MUTATION_TYPES.SET_IS_CONTACTS_FEATURE_PREVIEW](
    state,
    isContactsFeaturePreview
  ): void {
    state.isContactsFeaturePreview = isContactsFeaturePreview
  },
  [MUTATION_TYPES.SET_IS_SIGN_IN_WITH_APPLE_FEATURE_PREVIEW](
    state,
    setIsSignInWithAppleFeaturePreview
  ): void {
    state.isSignInWithAppleFeaturePreview = setIsSignInWithAppleFeaturePreview
  },
  [MUTATION_TYPES.SET_DISABLE_ACCOUNT_ANALYTICS](
    state,
    accountAnalyticsDisabled
  ): void {
    state.accountAnalyticsDisabled = accountAnalyticsDisabled
  },
  [MUTATION_TYPES.SET_IS_CHANGE_EMAIL_PREVIEW](
    state,
    isChangeEmailFeaturePreview
  ): void {
    state.isChangeEmailFeaturePreview = isChangeEmailFeaturePreview
  },
  [MUTATION_TYPES.SET_IS_RAF_AVAILABLE_COUNTRY](
    state,
    isRafAvailableCountry
  ): void {
    state.isRafAvailableCountry = isRafAvailableCountry
  },
  setLocaleProperties(state, i18nLocaleProperties) {
    state.localeProperties = i18nLocaleProperties
  },
  setCustomerCountry(state, customerCountry) {
    state.customerCountry = customerCountry
  },
  setCountries(state, countries) {
    state.countries = countries
  },
  setCurrencies(state, currencies) {
    state.currencies = currencies
  },
}

export default mutations
