export enum MUTATION_TYPES {
  SET_LOCALE = 'setLocale',
  SET_CURRENCY = 'setCurrency',
  SET_MERCHANT = 'setMerchant',
  SET_IS_CONTACTS_FEATURE_PREVIEW = 'setIsContactsFeaturePreview',
  SET_IS_SIGN_IN_WITH_APPLE_FEATURE_PREVIEW = 'setIsSignInWithAppleFeaturePreview',
  SET_IS_CHANGE_EMAIL_PREVIEW = 'setIsChangeEmailFeaturePreview',
  SET_DISABLE_ACCOUNT_ANALYTICS = 'disableAccountAnalytics',
  SET_ORIGIN_MERCHANT_RECHARGE_CLASSIC = 'setOriginMerchantRechargeClassic',
  SET_ORIGIN_MERCHANT_RAPIDO = 'setOriginMerchantRapido',
  SET_LAST_PRODUCT_SEEN_BY_COUNTRY = 'setLastProductSeenByCountry',
  SET_CMS_LOCALE = 'setCMSLocale',
  SET_COUNTRY_CODE = 'setCountryCode',
  SET_RAPIDO_SEGMENT = 'setRapidoSegment',
  SET_SHOW_ERROR_DETAILS = 'setShowErrorDetails',
  SET_IS_REDIRECTED_FROM_RAPIDO = 'setIsRedirectedFromRapido',
  SET_DIRECTION = 'setDirection',
  SET_IS_RAF_AVAILABLE_COUNTRY = 'setIsRafAvailableCountry',
}
