
import scrollToSelected from './mixins/scroll-to-selected'
import { SearchList } from '@/components/molecules'
import { ButtonBox } from '@/components/atoms'

export default {
  name: 'Desktop',
  components: {
    SearchList,
    ButtonBox,
  },
  mixins: [scrollToSelected(false)],
  props: {
    selectedCountry: {
      type: Object,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: 'ltr',
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: null,
      dropdown: false,
    }
  },
  computed: {
    searchPlaceholder(): string {
      return this.$t(
        'account.edit_profile.mobile_number.country_code.search_country'
      )
    },
  },
  methods: {
    changeItem(item): void {
      this[`${this.selectorShown}Selected`] = item
      this.$emit('change-item', item)
      this.$emit('close-dropdown')
    },
    closeDropdown(): void {
      this.$emit('close-dropdown')
    },
  },
}
