import { CMS402Error } from './CMS402Error'

export class RequiredFieldError extends CMS402Error {
  constructor({
    fieldName,
    modelName,
    name = 'unknown',
    locale = 'unknown',
    fieldIdentifier = 'unknown',
    sys,
  }) {
    const message = `Required Field Error: The field '${fieldName}' is empty for ${modelName.replace(
      'Model',
      ''
    )} where ${fieldIdentifier}:'${name}' in locale '${locale}'`
    super({ message, sys })
  }
}
