import { REQUEST_ERROR } from '../enums'
import { RequestError } from './RequestError'
import get from '~/utils/get'

export abstract class CMS402Error extends RequestError {
  constructor({ message, sys }) {
    super({ statusCode: REQUEST_ERROR.UNPROCESSABLE_ENTITY, message })
    this.details = this.getRequiredFieldErrorDetails(sys)
  }

  static buildUrlIfSys(sys): string {
    if (!sys) return ''

    const spaceId = get(sys, 'space.sys.id', process.env.CONTENTFUL_SPACE_KEY)
    const id = get(sys, 'id')
    const env = get(
      sys,
      'environment.sys.id',
      process.env.CONTENTFUL_ENVIRONMENT
    )

    return `The entry URL is: https://app.contentful.com/spaces/${spaceId}/environments/${env}/entries/${id}`
  }

  getRequiredFieldErrorDetails(sys = undefined): string {
    return `
      <br />
      ${this.message} <br />
      * status code: ${this.statusCode}  <br />
      * environment: ${process.env.CONTENTFUL_ENVIRONMENT} <br />
      <br /> <br /> <br />
      <b>${CMS402Error.buildUrlIfSys(sys)}</b>
    `
  }
}
