export default {
  gb: {
    'apple-gift-card': 'apple',
    'google-play-gift-card': 'google-play',
    'app-store-itunes': 'apple',
  },
  it: {
    'apple-gift-card': 'apple',
    'google-play-gift-card': 'google-play',
    'app-store-itunes': 'apple',
  },
  at: {
    'apple-gift-card': 'apple',
    'google-play-gift-card': 'google-play',
    'app-store-itunes': 'apple',
  },
  es: {
    'apple-gift-card': 'apple',
    'google-play-gift-card': 'google-play',
    'app-store-itunes': 'apple',
  },
  dk: {
    'apple-gift-card': 'apple',
    'app-store-itunes': 'apple',
  },
  nl: {
    'google-play-gift-card': 'google-play',
  },
  de: {
    'google-play-gift-card': 'google-play',
  },
  fr: {
    'google-play-gift-card': 'google-play',
  },
  be: {
    'google-play-gift-card': 'google-play',
  },
  pl: {
    'google-play-gift-card': 'google-play',
    'app-store-itunes': 'apple',
  },
  ch: {
    'google-play-gift-card': 'google-play',
  },
}
