<template>
  <ui-image
    :class="[
      'flag block border border-gray-light',
      width ? '' : ' w-auto',
      addedClasses,
    ]"
    :src="require(`~/assets/images/flags/${country.toLowerCase()}.svg`)"
    :style="{
      height: height + 'px',
      ...(width ? { width: width + 'px' } : {}),
    }"
  />
</template>

<script>
import { UiImage } from '~/components/atoms'
export default {
  components: {
    UiImage,
  },
  props: {
    addedClasses: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 18,
    },
    width: {
      type: Number,
      default: 32,
    },
  },
}
</script>
