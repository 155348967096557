export interface IAsyncStoreState {
  loading: boolean
  succeeded: boolean
  failed: boolean
}

export function AsyncStoreState(): IAsyncStoreState {
  return {
    loading: false,
    succeeded: false,
    failed: false,
  }
}

export default AsyncStoreState
