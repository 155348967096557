import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from './Entry'
import { IImageModel, ICMSImageModel, ImageModel } from './Image'
import { ICMSPageModel, IPage, PageModel } from './Page'

export type TBrandCategory =
  | 'entertainment'
  | 'game'
  | 'e_voucher'
  | 'crypto'
  | 'prepaid_credit_card'
  | 'shopping_giftcards'
  | 'unlimited'
  | 'international'
  | 'call_credit'
  | 'bundle'
  | 'chat'
  | 'data'

export interface ICategory {
  logo: IImageModel
  title: string
  slug: string
  brandFeedImage?: IImageModel
  parentPage: IPage
  brandCategory: TBrandCategory
  maximumProductQuantity: number
  isCertifiedReseller?: boolean
}

export interface ICMSCategory extends Entry {
  fields: {
    logo: ICMSImageModel
    title: string
    brandFeedImage?: ICMSImageModel
    parentPage: ICMSPageModel
    brandCategory: TBrandCategory
    maximumProductQuantity: number
    isCertifiedReseller?: boolean
  }
}
export class CategoryModel extends EntryModel {
  protected readonly logo: IImageModel
  protected readonly title: string
  protected readonly slug: string
  protected readonly brandFeedImage?: IImageModel
  protected readonly parentPage: IPage
  protected readonly brandCategory: TBrandCategory
  protected readonly maximumProductQuantity: number
  protected readonly isCertifiedReseller?: boolean

  constructor(categoryModel: ICMSCategory, slug?: string) {
    super(categoryModel)
    const {
      fields: {
        logo,
        title,
        brandFeedImage,
        brandCategory,
        maximumProductQuantity,
        isCertifiedReseller,
      },
    } = categoryModel

    if (logo) {
      this.logo = new ImageModel(logo).toJSON()
    }

    this.brandFeedImage = brandFeedImage
      ? new ImageModel(brandFeedImage).toJSON()
      : undefined

    this.title = title
    this.parentPage = new PageModel(this.requiredField('parentPage')).toJSON()
    this.slug = slug || this.parentPage.slug || '/'
    this.brandCategory = brandCategory
    this.maximumProductQuantity = maximumProductQuantity
    this.isCertifiedReseller = isCertifiedReseller
  }

  toJSON(): ICategory {
    return {
      logo: this.logo,
      title: this.title,
      slug: this.slug,
      brandFeedImage: this.brandFeedImage,
      parentPage: this.parentPage,
      brandCategory: this.brandCategory,
      maximumProductQuantity: this.maximumProductQuantity,
      isCertifiedReseller: this.isCertifiedReseller,
    }
  }
}
