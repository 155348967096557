import { TYPES } from './mutation-types'
import { IRtrUserState } from './state'
import { Commit, mutationTree } from '~/store/types'

const { SET_PHONE_NUMBER } = TYPES
const mutations = mutationTree<IRtrUserState>()({
  [SET_PHONE_NUMBER](state, phoneNumber: string): void {
    state.rtrUser.phoneNumber = phoneNumber
  },
})

export type RtrUserCommit = Commit<typeof mutations>

export default mutations
