import { getMaximumDate } from './helpers'
import { INPUT_TYPE, COMPONENT, DIMENSIONS } from './enums'
import { PAYMENT_METHOD_FIELDS } from '~/enums'

export interface IFormFieldValidations {
  required?: boolean
  email?: boolean
  numeric?: boolean
  maxLength?: number
  minLength?: number
  minDate?: Date
  maxDate?: Date
  validCodiceFiscale?: boolean
}

export interface IFormField {
  key: PAYMENT_METHOD_FIELDS
  inputType: INPUT_TYPE
  component?: COMPONENT
  /**
   * Placeholder key on translations
   */
  placeholder: string
  dimensions: {
    mobile: DIMENSIONS
    desktop: DIMENSIONS
  }
  validations?: IFormFieldValidations
  props?: Record<string, any>
}

type IPaymentMethodsFormFields = {
  [key in PAYMENT_METHOD_FIELDS]?: IFormField
}

const config = (
  shouldSeparateStreetHouseInputs: boolean
): IPaymentMethodsFormFields => ({
  BILLING_FIRSTNAME: {
    key: PAYMENT_METHOD_FIELDS.BILLING_FIRSTNAME,
    inputType: INPUT_TYPE.text,
    placeholder: 'first_name',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
    },
  },
  BILLING_LASTNAME: {
    key: PAYMENT_METHOD_FIELDS.BILLING_LASTNAME,
    inputType: INPUT_TYPE.text,
    placeholder: 'last_name',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
    },
  },
  BILLING_STREET: {
    inputType: INPUT_TYPE.text,
    key: PAYMENT_METHOD_FIELDS.BILLING_STREET,
    placeholder: shouldSeparateStreetHouseInputs
      ? 'street'
      : 'street_house_number',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
    },
  },
  BILLING_POSTALCODE: {
    inputType: INPUT_TYPE.text,
    key: PAYMENT_METHOD_FIELDS.BILLING_POSTALCODE,
    placeholder: 'postal_code',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
    },
  },
  BILLING_CITY: {
    inputType: INPUT_TYPE.text,
    key: PAYMENT_METHOD_FIELDS.BILLING_CITY,
    placeholder: 'city',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
    },
  },
  BILLING_COUNTRY: {
    inputType: INPUT_TYPE.countrySelect,
    key: PAYMENT_METHOD_FIELDS.BILLING_COUNTRY,
    component: COMPONENT['country-selector'],
    placeholder: 'country',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
  },
  BILLING_STATE: {
    inputType: INPUT_TYPE.stateSelect,
    key: PAYMENT_METHOD_FIELDS.BILLING_STATE,
    component: COMPONENT['state-selector'],
    placeholder: 'state_or_province',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
  },
  BILLING_CODICE_FISCALE: {
    inputType: INPUT_TYPE.text,
    key: PAYMENT_METHOD_FIELDS.BILLING_CODICE_FISCALE,
    placeholder: 'codice_fiscale',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
      minLength: 16,
      maxLength: 16,
      validCodiceFiscale: true,
    },
    props: {
      maxlength: 16,
    },
  },
  BILLING_BIRTHDATE: {
    inputType: INPUT_TYPE.date,
    key: PAYMENT_METHOD_FIELDS.BILLING_BIRTHDATE,
    placeholder: 'birth_date',
    dimensions: {
      desktop: DIMENSIONS.half,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
      minDate: new Date('1900-01-01'),
      maxDate: new Date(getMaximumDate()),
    },
    props: {
      hideSufix: true,
      min: '1900-01-01',
      max: getMaximumDate(),
      value: getMaximumDate(),
      hasSubscript: true,
      subscriptKey: 'birth_date.age_verification',
    },
  },
  BILLING_HOUSENUMBER: {
    inputType: INPUT_TYPE.text,
    key: PAYMENT_METHOD_FIELDS.BILLING_HOUSENUMBER,
    placeholder: 'house_number',
    dimensions: {
      desktop: DIMENSIONS.full,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
    },
  },
  PHONE_CHALLENGE_NUMBER: {
    inputType: INPUT_TYPE.number,
    key: PAYMENT_METHOD_FIELDS.PHONE_CHALLENGE_NUMBER,
    component: COMPONENT['phone-input'],
    placeholder: 'phone_number',
    dimensions: {
      desktop: DIMENSIONS.half,
      mobile: DIMENSIONS.full,
    },
    validations: {
      required: true,
    },
    props: {
      hasSubscript: true,
      subscriptKey: 'phone_number.verification',
      hasExtraInfo: true,
      extraInfo: [
        'phone_number.sms.safe_verification',
        'phone_number.sms.security_guarantee',
      ],
    },
  },
})

export default class FormField {
  static create(
    field: PAYMENT_METHOD_FIELDS,
    shouldSeparateStreetHouseInputs: boolean
  ): IFormField | undefined {
    const fieldConfig: IFormField | undefined = config(
      shouldSeparateStreetHouseInputs
    )[field]
    return fieldConfig
  }
}
