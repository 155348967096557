var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('row',[_c('column',{staticClass:"w-full"},[_c('card',{attrs:{"id":"checkout-payment-form","disable":_vm.loading,"data-test":"checkout-payment-form","margin":"mb-4 lg:mb-6","overflow-hidden":false}},[_c('form-description',{attrs:{"brand-name":_vm.brandName}}),_vm._v(" "),_c('form',{staticClass:"flex flex-wrap",on:{"change":_vm.savePaymentFormValues}},_vm._l((_vm.fields),function({
              key,
              inputType,
              component,
              placeholder,
              dimensions,
              props,
            }){return _c('column',{key:key,class:`w-${dimensions.mobile} lg:w-${dimensions.desktop}`},[_c(component || 'ui-input',_vm._b(_vm._b({tag:"component",attrs:{"data-test":`input-${key}`,"type":inputType,"has-error":_vm.$v.form[key].$error,"is-dirty":_vm.$v.form[key].$dirty,"is-succeeded":!_vm.$v.form[key].$invalid && _vm.$v.form[key].$model !== '',"label":`${_vm.$t(
                  `organisms.checkout_payment_form.placeholders.${placeholder}`
                )}*`},on:{"input":function($event){return _vm.onInput(key, $event)},"country:select":_vm.onCountrySelect},model:{value:(_vm.$v.form[key].$model),callback:function ($$v) {_vm.$set(_vm.$v.form[key], "$model", $$v)},expression:"$v.form[key].$model"}},"component",_vm._d({},[_vm.selectedCountryAttribute(key),_vm.selectedCountry])),'component',props,false))],1)}),1)],1)],1)],1),_vm._v(" "),_c('row',[_vm._t("default")],2),_vm._v(" "),_c('row',[_c('column',{staticClass:"w-full"},[_c('right-of-withdrawal',{attrs:{"right-of-withdrawal":_vm.rightOfWithdrawal}})],1)],1),_vm._v(" "),(_vm.hasTermsAndConditions)?_c('row',[_c('column',{staticClass:"w-full"},[_c('terms-and-conditions',{attrs:{"is-preselected":""},on:{"terms-contidions:change":_vm.handleTermsAndConditionChange}},[_c('i18n',{attrs:{"path":"organisms.checkout_payment_form.terms_conditions.text"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('a',{staticClass:"text-secondary",attrs:{"href":_vm.termsAndConditionsUrl,"target":"_blank"}},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'organisms.checkout_payment_form.terms_conditions.link_text'
                ))+"\n            ")])]},proxy:true},{key:"brand",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.brandTitle)+"\n          ")]},proxy:true}],null,false,429431237)})],1)],1)],1):_vm._e(),_vm._v(" "),_c('row',{staticClass:"mb-6",attrs:{"wrap":""}},[_c('column',{staticClass:"w-full md:w-2/3"},[_c('ui-button',{attrs:{"data-test":"btn-complete-payment","loading":_vm.loading,"disable":!_vm.buttonEnabled},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submitPayment.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('general.complete_payment')))])],1),_vm._v(" "),_c('column',{staticClass:"justify-center w-full mb-4 md:w-1/2 md:mb-0 md:mt-2"},[_c('usps',{staticClass:"pt-2",attrs:{"plain-text":_vm.$t('organisms.checkout_payment_form.usps'),"icon-type":"lock"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }