import { IOrderProduct, IOrderState } from './interfaces'
import { ORDER_PAGE, pagesByOrderStatus } from './enums'
import {
  ITopupMyOrderModel,
  OrderModel,
  IOrderModel,
  IOrderCodeModel,
  ORDER_STATUS,
} from '~/models/components/Order'
import get from '~/utils/get'

import { IPageStructureModel } from '~/models/layout/PageStructure'

export default {
  product: ({ product }): IOrderProduct => product,
  productId: ({ product }: IOrderState): string => product.productId,
  productQuantity: ({ product }: IOrderState): number => product.quantity || 1,
  orderDetails: ({ details }): IOrderModel => details,
  orderProduct: ({ details }): IOrderProduct => get(details, 'products[0]', {}),
  codes: ({ details }): IOrderCodeModel[] => OrderModel.formattedCodes(details),
  structure: ({
    productOrderPageStructure,
  }: IOrderState): IPageStructureModel => productOrderPageStructure,
  orderStatus: ({ orderStatus }): boolean => orderStatus,
  isOrderPaid: ({ orderStatus }): boolean => orderStatus === ORDER_STATUS.PAID,
  isOrderUnderDistribution: ({ orderStatus }): boolean =>
    orderStatus === ORDER_STATUS.DISTRIBUTION,
  isOrderInProgress: ({ orderStatus }): boolean =>
    orderStatus === ORDER_STATUS.UNPAID ||
    orderStatus === ORDER_STATUS.DISTRIBUTION ||
    orderStatus === ORDER_STATUS.PAID,
  wasOrderShipped: ({ orderStatus }): boolean =>
    orderStatus === ORDER_STATUS.SHIPPED,
  orderWasInvestigated: ({ orderStatus }): boolean =>
    orderStatus === ORDER_STATUS.INVESTIGATED,
  isPaymentUnderInvestigation: ({ orderStatus }): boolean =>
    orderStatus === ORDER_STATUS.INVESTIGATION,
  paymentHasFailed: ({ orderStatus }): boolean =>
    orderStatus === ORDER_STATUS.ERROR || status === ORDER_STATUS.ABORTED,
  orderHasBeenRefunded: ({ orderStatus }): boolean =>
    orderStatus === ORDER_STATUS.REFUNDED,
  orderPageToShow: (_, { orderStatus }): ORDER_PAGE =>
    pagesByOrderStatus[orderStatus] || ORDER_PAGE.SHIPPED,
  isRTR: ({ details }: IOrderState): boolean => (details as IOrderModel).isRTR,
  featureReorderEnabled: (): boolean => true,
}
