export default {
  merchant: 'BELTEGOED',
  productId: 66822,
  quantity: 1,
  paymentMethodId: 'Adyen_Visa',
  email: 'bauke@10kb.nl',
  newsletter: false,
  gift: false,
  redirectUrls: {
    success: 'https://rapido/com/it-it/order/success',
    pending: 'https://rapido/com/it-it/order/pending',
    cancelled: 'https://rapido/com/it-it/order/cancelled',
    error: 'https://rapido/com/it-it/order/error',
    resume: 'https://rapido/com/it-it/order/resume',
  },
  paymentInformationData: {
    billingFirstname: 'CG',
    billingLastname: 'Test',
    billingStreet: 'Kerkenbos 1301',
    billingHousenumber: '0',
    billingPostalcode: '6546BG',
    billingCity: 'Nijmegen',
  },
}
