import { Middleware, Context } from '@nuxt/types'
import { LocaleObject } from 'nuxt-i18n/types'
import {
  localeDidNotChange,
  getLocaleFromPath,
  setLocaleCookieCreator,
} from './utils'
import { ILocaleChangeMethodParams } from './interfaces'

// NOTE: It has all this functions in order to be easy to read/debug case by case
// every function takes a possible scenario based on the path, if is valid, it has locale, etc

function toDoIfPathLocaleIsValid(
  setLocaleCookie: (label: string) => void,
  localeFromPath: string
): void {
  setLocaleCookie(localeFromPath)
}

function toDoIfLocaleChange(params: ILocaleChangeMethodParams): void {
  const { app, localeFromPath, localeFromCookie } = params
  const { locales = [], locale: localeFromApp } = app.i18n
  const availableLocales: string[] = (locales as LocaleObject[]).map(
    (locale: { code: string }) => locale.code
  )
  const setLocaleCookie = setLocaleCookieCreator(app.$cookies, availableLocales)

  if (!availableLocales.includes(localeFromPath)) {
    const locale: string = localeFromCookie || localeFromApp
    setLocaleCookie(locale)
    return
  }

  toDoIfPathLocaleIsValid(setLocaleCookie, localeFromPath)
}

const checkLocaleMiddleware: Middleware = (context: Context): void => {
  if (!process.server) return

  const { store } = context

  if (store.getters['context/isGlobalMarketplace']) return

  const pathIncludeLocale = store.getters['context/pathIncludeLocale']
  // if label has locale on path
  if (!pathIncludeLocale) return

  const { app, route, redirect } = context

  if (route.matched?.length && route.matched[0].meta.withoutLocale) return

  const path = route.path.toLowerCase()

  const localeFromPath: string = getLocaleFromPath(path)
  const { locale: localeFromApp } = app.i18n
  const localeFromCookie: string = app.$cookies.get('locale') || ''

  if (localeDidNotChange(localeFromPath, localeFromApp, localeFromCookie))
    return

  toDoIfLocaleChange({ app, localeFromPath, redirect, path, localeFromCookie })
}

export default checkLocaleMiddleware
