import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { LinkModel, ILink, ICMSLink } from '../base/Link'

import { ExternalLinkModel, ICMSExternalLinkModel } from '../base/ExternalLink'
import { ICMSStructure } from '../layout/CMSStructure'
import isEmpty from '~/utils/isEmpty'
import mapWithFilter from '~/services/map-with-filter'
import { Maybe } from '~/apis/clients/graphql/types/contentful'
export interface IHeaderLink {
  title: string
  slug?: string
  icon?: string
  url?: string
  newTab?: Maybe<boolean>
  brandMenu?: ILink[]
}

export interface ICMSHeaderLink extends Entry {
  fields: {
    title: string
    icon?: string
    kindPage?: Record<string, ICMSStructure>
    brandMenu?: ICMSLink[]
    externalLink?: ICMSExternalLinkModel
  }
}

export class HeaderLinkModel extends EntryModel {
  protected title: string
  protected readonly brandMenu?: ILink[]
  protected readonly slug?: string
  protected url?: string
  protected newTab?: boolean
  protected readonly icon?: string

  constructor(headerLinkModel: ICMSHeaderLink) {
    super(headerLinkModel)
    const {
      fields: { title, brandMenu = [], icon = '', kindPage = {}, externalLink },
    } = headerLinkModel

    this.title = title
    this.icon = icon

    if (externalLink && !isEmpty(externalLink)) {
      this.formatExternalLink(externalLink)
    }

    this.brandMenu = !isEmpty(brandMenu)
      ? mapWithFilter(brandMenu, link => new LinkModel(link).toJSON(), 'fields')
      : undefined

    if (!isEmpty(kindPage)) {
      this.slug = kindPage.fields.slug
    }
  }

  private formatExternalLink(externalLink: ICMSExternalLinkModel): void {
    const { url, title: externalLinkTitle, newTab } = new ExternalLinkModel(
      externalLink
    ).toJSON()

    this.title = externalLinkTitle
    this.url = url
    this.newTab = newTab
  }

  toJSON(): IHeaderLink {
    return {
      title: this.title,
      slug: this.slug,
      icon: this.icon,
      url: this.url,
      newTab: this.newTab,
      brandMenu: this.brandMenu,
    }
  }
}
