import { Plugin, NuxtAppOptions } from '@nuxt/types'
import { Store } from 'vuex/types'
import { ContextPath } from './types'
import { isExternalLink } from '~/services/url'

declare module 'vue/types/vue' {
  interface Vue {
    /**
     * Method to insert `context/locale` on `path`
     */
    $contextPath(path: string): string
    /**
     * Method to navigate to `path` inserting `context/locale` if it is needed<br />
     * example: `/my-path/` -> `/en-us/my-path/`
     */
    $navigate(path: string): void
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $contextPath(path: string): string
    $navigate(path: string): void
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createContextPath(store: Store<any>): ContextPath {
  return function(page: string): string {
    if (isExternalLink(page)) return page

    const locale: string = store.getters['context/marketplacePrefix']
    if (!page) return `/${locale}`

    const shouldIncludesLocale: boolean =
      store.getters['context/pathIncludeLocale']
    const hasLeadingSlash = page && page.charAt(0) === '/'
    const slug = hasLeadingSlash ? page.substr(1) : page
    if (!shouldIncludesLocale || slug.includes(locale)) return `/${slug}`

    return slug.length === 0 ? `/${locale}` : `/${locale}/${slug}`
  }
}

function createNavigateWithContextPath(
  app: NuxtAppOptions,
  contextPath: ContextPath
) {
  return function(page: string): void {
    if (app.router) {
      app.router.push(contextPath(page))
    }
  }
}

const contextPathPlugin: Plugin = ({ store, app }, inject) => {
  const contextPath: ContextPath = createContextPath(store)
  const navigateWithContext = createNavigateWithContextPath(app, contextPath)
  inject('contextPath', contextPath)
  inject('navigate', navigateWithContext)
}

export default contextPathPlugin
