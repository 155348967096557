import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { IImageModel, ICMSImageModel, ImageModel } from '../base/Image'
import { ICMSCategory, CategoryModel, TBrandCategory } from '../base/Category'
// TODO: remove this property once RTR is merged from code and CMS
import { UspsModel, IUsps, ICMSUsps } from './Usps'

export interface IProduct {
  logo?: IImageModel
  title?: string
  productId?: number
  slug?: string
  buttonText?: string
  isPopular?: boolean
  value?: string
  description?: Record<string, unknown>
  // TODO: remove this property once RTR is merged
  usps?: IUsps
  banner?: IImageModel
  showCountryRestricted?: boolean
  brandCategory?: TBrandCategory
  brandTitle?: string
  brandSlug?: string
  productFeedImage?: IImageModel
  parentDenominationType?: string
  maximumQuantity?: number
  isCertifiedReseller?: boolean
  isCustomDenomination?: boolean
  redirectToCustomerCountry?: boolean
  showOnlyInCustomerCountry?: boolean
  scamNotificationType?: string
}

export interface ICMSProductModel extends Entry {
  fields: {
    logo?: ICMSImageModel
    title: string
    productId: number
    slug: string
    buttonText?: string
    isPopular?: boolean
    value?: string
    description?: Record<string, unknown>
    // TODO: remove this property once RTR is merged
    usps?: ICMSUsps
    banner: ICMSImageModel
    showCountryRestricted?: boolean
    parentBrand: ICMSCategory
    productFeedImage?: ICMSImageModel
  }
}

export class ProductModel extends EntryModel {
  protected readonly logo?: IImageModel
  protected readonly banner?: IImageModel
  protected readonly title: string
  protected readonly productId: number
  protected readonly slug: string
  protected readonly buttonText?: string
  protected readonly isPopular?: boolean
  // TODO: remove this property once RTR is merged
  protected readonly usps?: IUsps
  protected readonly description?: Record<string, unknown>
  protected readonly showCountryRestricted?: boolean
  protected readonly brandTitle?: string
  protected readonly brandSlug?: string
  protected readonly brandCategory?: TBrandCategory
  protected readonly productFeedImage?: IImageModel
  protected readonly parentDenominationType?: string
  protected readonly maximumQuantity: number
  protected readonly isCertifiedReseller?: boolean
  protected readonly redirectToCustomerCountry?: boolean
  protected readonly showOnlyInCustomerCountry?: boolean
  constructor(productModel: ICMSProductModel) {
    super(productModel)
    const {
      fields: {
        slug,
        buttonText,
        isPopular,
        banner,
        description,
        parentBrand,
        showCountryRestricted,
        productFeedImage,
        logo,
        usps,
      },
    } = productModel
    this.cmsIdentifier = 'title'

    if (banner) {
      this.banner = new ImageModel(banner).toJSON()
    }

    if (productFeedImage) {
      this.productFeedImage = new ImageModel(productFeedImage).toJSON()
    }

    // TODO: this conditional is necessary because there is a problem with product sync updating
    // the brand pages, it add some draft elements, so the "fields" param is coming empty
    const {
      title: brandTitle,
      parentPage,
      brandCategory,
      maximumProductQuantity,
      slug: brandSlug,
      isCertifiedReseller,
      logo: categoryLogo,
    } = new CategoryModel(parentBrand).toJSON()

    this.logo = logo ? new ImageModel(logo).toJSON() : categoryLogo

    this.isCertifiedReseller = isCertifiedReseller
    this.brandTitle = brandTitle
    this.parentDenominationType = parentPage.pageType
    this.brandCategory = brandCategory
    this.brandSlug = brandSlug
    this.maximumQuantity = maximumProductQuantity || 9

    this.title = this.requiredField('title')
    this.productId = this.requiredField('productId')
    this.buttonText = buttonText
    this.slug = slug
    this.isPopular = isPopular
    this.description = description
    this.showCountryRestricted = showCountryRestricted
    // TODO: remove this property once RTR is merged
    if (usps) {
      this.usps = new UspsModel(usps).toJSON()
    }
  }

  toJSON(): IProduct {
    return {
      logo: this.logo,
      title: this.title,
      banner: this.banner,
      productId: this.productId,
      slug: this.slug,
      buttonText: this.buttonText,
      isPopular: this.isPopular,
      description: this.description,
      showCountryRestricted: this.showCountryRestricted,
      brandTitle: this.brandTitle,
      brandSlug: this.brandSlug,
      brandCategory: this.brandCategory,
      productFeedImage: this.productFeedImage,
      parentDenominationType: this.parentDenominationType,
      maximumQuantity: this.maximumQuantity,
      isCertifiedReseller: this.isCertifiedReseller,
      redirectToCustomerCountry: this.redirectToCustomerCountry,
      showOnlyInCustomerCountry: this.showOnlyInCustomerCountry,
    }
  }
}
