
import { Flag } from '~/components/atoms'

export default {
  name: 'CountrySelectorDropdownMobilePrefix',
  components: {
    Flag,
  },
  props: {
    selectedOption: {
      type: Object,
      default: null,
    },
  },
}
