
import { UiButton, UiSelect } from '~/components/atoms'

export default {
  components: {
    UiSelect,
    UiButton,
  },
  props: {
    issuers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    savedIssuerId: {
      type: String,
      default: '',
    },
    hasFields: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.savedIssuer,
    }
  },
  computed: {
    savedIssuerPosition() {
      const { savedIssuerId, issuers } = this
      return issuers.findIndex(item => item.id === savedIssuerId) + 1 || null
    },
    savedIssuer() {
      const { savedIssuerId, issuers } = this
      return issuers.find(item => item.id === savedIssuerId) || null
    },
  },
  mounted() {
    const { savedIssuerId, issuers } = this
    this.onSelect(issuers.find(({ id }) => id === savedIssuerId) || {})
  },
  methods: {
    onSelect(value) {
      this.selected = value
      this.$emit('issuer-list:select', value)
    },
    onClickAfterIssuerSelected() {
      this.$emit('issuer-list-button:click')
    },
    onClickContinueAfterIssuerSelected() {
      this.$emit('issuer-list-continue-button:click')
    },
  },
}
