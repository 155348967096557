import { GTMEvents, GTMDefaults } from '../enums'
import { IECommerceProduct, ECommerceProducts } from './ECommerceProducts'

export interface ICheckoutEcommerce {
  checkout: {
    actionField: {
      step: number
    }
    products: IECommerceProduct[]
  }
}

export interface ICheckoutLoad {
  event: GTMEvents
  // eslint-disable-next-line camelcase
  checkout_stage_total_steps: string
  ecommerce: ICheckoutEcommerce
}

export class CheckoutLoadModel {
  private ecommerce: ICheckoutEcommerce

  constructor(product, step) {
    const { products } = new ECommerceProducts(product).toJSON()

    this.ecommerce = {
      checkout: {
        actionField: {
          step,
        },
        products,
      },
    }
  }

  toJSON(): ICheckoutLoad {
    return {
      event: GTMEvents.CHECKOUT,
      checkout_stage_total_steps: GTMDefaults.CHECKOUT_STEPS,
      ecommerce: this.ecommerce,
    }
  }
}
