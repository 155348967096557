import isEmpty from '~/utils/isEmpty'
import handlePromiseAllErrors from '~/services/handle-promise-all'
import { decodeBase64 } from '~/services/encoder'
import { MAX_ORDER_QUANTITY } from '~/utils/constants/api'

const commitErrors = (commit, apiErrors = {}): boolean => {
  let errors = [] as Record<string, unknown>[]

  if (!isEmpty(apiErrors)) {
    errors = [apiErrors]
  }

  if (errors.length === 0) return false

  return commit('errors/setErrors', errors, {
    root: true,
  })
}

export async function fetchLayoutData({
  dispatch,
  errorPage,
  redirect,
  $sentry,
}): Promise<void> {
  try {
    await dispatch(
      'contentful/fetchLayoutData',
      {
        redirect,
      },
      { root: true }
    )
  } catch (error) {
    if (process.env.NODE_ENV === 'development') throw error

    $sentry.captureException(error)

    errorPage(error)
  }
}

async function fetchProduct(
  { errorPage, dispatch, redirect, $sentry, getters },
  { productId, countryAbv }
): Promise<void> {
  try {
    const useRecommerce = getters['context/useRecommerce']

    if (useRecommerce) {
      await dispatch('recommerce/fetchVariantById', productId, { root: true })
    } else {
      await dispatch(
        'contentful/fetchCMSProduct',
        { productId, redirect, errorPage, countryAbv, returnProduct: false },
        { root: true }
      )
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') throw error
    $sentry.captureException(error)
    errorPage(error)
  }
}

function validateOrderQuantity(
  quantity: number,
  { getters, redirect, $contextPath }
): void {
  const { maximumQuantity, productId } =
    getters['contentful/product'] || getters['recommerce/variant'] || {}

  if (!quantity || quantity < 1) {
    redirect($contextPath(`checkout?productId=${productId}&quantity=1`))
  } else if (quantity > (maximumQuantity || MAX_ORDER_QUANTITY)) {
    redirect(
      $contextPath(
        `checkout?productId=${productId}&quantity=${maximumQuantity || 1}`
      )
    )
  }
}

function validateOpenRangeValue(
  quantity: number,
  openRangeValue,
  { getters, redirect, $contextPath }
): void {
  const {
    brandSlug,
    productId,
    customDenominationMinValue,
    customDenominationMaxValue,
  } = getters['contentful/product'] || getters['recommerce/variant'] || {}

  if (openRangeValue) {
    let setOpenRangeValue
    if (openRangeValue * quantity > customDenominationMaxValue) {
      setOpenRangeValue = customDenominationMaxValue
    }
    if (openRangeValue * quantity < customDenominationMinValue) {
      setOpenRangeValue = customDenominationMinValue
    }
    if (setOpenRangeValue) {
      redirect(
        $contextPath(
          `checkout?productId=${productId}&quantity=${quantity}&value=${setOpenRangeValue}`
        )
      )
    }
  }
}

async function fetchOrderInfo(params): Promise<void> {
  const {
    dispatch,
    hash,
    orderId,
    errorPage,
    merchant,
    redirect,
    currency,
  } = params
  try {
    await dispatch(
      'order/fetchOrderInfo',
      {
        route: { params: { hash, id: orderId } },
        options: { fetchProductInfo: true },
        errorPage,
        merchant,
        redirect,
        currency,
      },
      { root: true }
    )
  } catch (error) {
    if (process.env.NODE_ENV === 'development') throw error

    errorPage(error)
  }
}

async function deepLinkAccessFlow(
  { dispatch, errorPage, redirect, getters, $sentry, $contextPath },
  { hash, orderId, merchant, currency, countryAbv }
): Promise<void> {
  await fetchOrderInfo({
    dispatch,
    hash,
    orderId,
    errorPage,
    merchant,
    redirect,
    currency,
  })

  const orderedProductId = getters['order/productId']
  const orderedProductQuantity = getters['order/productQuantity']

  if (!orderedProductId || !orderedProductQuantity)
    throw new Error(
      'Required parameters to execute deep link access flow are missing'
    )

  const fetchCMSProductPromise = fetchProduct(
    { errorPage, dispatch, $sentry, redirect, getters },
    { productId: orderedProductId, countryAbv }
  )
  const fetchPaymentMethodsDispatcher = dispatch(
    'checkout/fetchPaymentMethods',
    {
      errorPage,
      redirect,
      currency,
      productId: orderedProductId,
      merchant,
      quantity: orderedProductQuantity,
    },
    { root: true }
  )
  const productPriceDispatcher = dispatch(
    'products/fetchProductPrice',
    {
      productId: orderedProductId,
      errorPage,
      currency,
      merchant,
      quantity: orderedProductQuantity,
      country: countryAbv,
    },
    { root: true }
  )

  return handlePromiseAllErrors(
    [
      fetchPaymentMethodsDispatcher,
      productPriceDispatcher,
      fetchCMSProductPromise,
    ],
    commitErrors
  )
}

async function primerDeepLinkAccessFlow(
  { dispatch, errorPage, redirect, getters, $sentry },
  { redirectHash, redirectOrderId, merchant, currency, countryAbv, clientToken }
): Promise<void> {
  await fetchOrderInfo({
    dispatch,
    hash: redirectHash,
    orderId: redirectOrderId,
    errorPage,
    merchant,
    redirect,
    currency,
  })

  const orderedProductId = getters['order/productId']
  const orderedProductQuantity = getters['order/productQuantity']

  if (!orderedProductId || !orderedProductQuantity)
    throw new Error(
      'Required parameters to execute deep link access flow are missing'
    )

  const navigateDeepLink = dispatch('checkout/setRedirectInfo', {
    clientToken,
  })

  const fetchPaymentMethodsDispatcher = dispatch(
    'checkout/fetchPaymentMethods',
    {
      errorPage,
      redirect,
      currency,
      productId: orderedProductId,
      merchant,
      quantity: orderedProductQuantity,
    },
    { root: true }
  )

  const productPriceDispatcher = await dispatch(
    'products/fetchProductPrice',
    {
      productId: orderedProductId,
      errorPage,
      currency,
      merchant,
      quantity: orderedProductQuantity,
      country: countryAbv,
    },
    { root: true }
  )

  const fetchCMSProductPromise = fetchProduct(
    { errorPage, dispatch, $sentry, redirect, getters },
    { productId: orderedProductId, countryAbv }
  )

  return handlePromiseAllErrors(
    [
      navigateDeepLink,
      fetchPaymentMethodsDispatcher,
      productPriceDispatcher,
      fetchCMSProductPromise,
    ],
    commitErrors
  )
}

async function executeInAppNavigationFlow(
  { errorPage, dispatch, getters, redirect, $sentry, $contextPath },
  { productId, currency, merchant, quantity, countryAbv, openRangeValue }
): Promise<void> {
  await fetchProduct(
    { errorPage, dispatch, redirect, $sentry, getters },
    { productId, countryAbv }
  )

  validateOrderQuantity(quantity, { getters, redirect, $contextPath })

  validateOpenRangeValue(quantity, openRangeValue, {
    getters,
    redirect,
    $contextPath,
  })

  const fetchPaymentMethodsDispatcher = dispatch(
    'checkout/fetchPaymentMethods',
    {
      errorPage,
      redirect,
      currency,
      productId,
      merchant,
      quantity,
      value: openRangeValue,
    },
    { root: true }
  )

  const productPriceDispatcher = dispatch(
    'products/fetchProductPrice',
    { productId, errorPage, currency, merchant, quantity, country: countryAbv },
    { root: true }
  )

  return handlePromiseAllErrors(
    [productPriceDispatcher, fetchPaymentMethodsDispatcher],
    commitErrors
  )
}

export const checkoutSagaLoading = async (
  { dispatch, getters, errorPage, redirect, $sentry, $contextPath },
  { urlParams, appUrl }
): Promise<void> => {
  await fetchLayoutData({ dispatch, errorPage, redirect, $sentry })
  try {
    const {
      orderId,
      hash,
      productId,
      quantity = '1',
      phone: encodedPhone,
      openRangeValue,
      clientToken,
      redirectOrderId,
      redirectHash,
    } = urlParams
    const merchant = getters['context/merchant']
    const isGlobalMarketplace = getters['context/isGlobalMarketplace']
    const country = isGlobalMarketplace
      ? getters['context/country']
      : getters['contentful/country']
    const marketplacePrefix = getters['context/marketplacePrefix']
    const countryAbv = isGlobalMarketplace
      ? country.code.toLowerCase()
      : getters['contentful/countryAbv']
    const phone = encodedPhone ? decodeBase64(encodedPhone) : false
    dispatch('context/selectCurrency', country, { root: true })

    const { abv: currency } = getters['context/currency']

    dispatch(
      'checkout/generateUrls',
      {
        appUrl,
        locale: marketplacePrefix,
        encodedPhone,
        productId,
      },
      { root: true }
    )

    const isDeepLinkAccess = hash && orderId
    const isInAppNavigation = Boolean(productId)
    const isRTR = Boolean(productId) && Boolean(phone)
    const isPrimerDeepLinkAccess =
      clientToken && redirectOrderId && redirectHash

    if (!isDeepLinkAccess && !isInAppNavigation && !isRTR)
      throw new Error(
        'Required parameters to execute in app navigation flow or deep link access flow are missing'
      )

    if (isPrimerDeepLinkAccess) {
      await primerDeepLinkAccessFlow(
        {
          errorPage,
          dispatch,
          redirect,
          getters,
          $sentry,
        },
        {
          redirectOrderId,
          redirectHash,
          currency,
          merchant,
          countryAbv,
          clientToken,
        }
      )
      return
    }

    if (isInAppNavigation) {
      const parsedQuantity = parseInt(quantity, 10)
      const parsedProductId = parseInt(productId, 10)
      const parsedOpenRangeValue = parseInt(openRangeValue, 10)

      dispatch(
        'order/addProductToOrder',
        { productId: parsedProductId, quantity: parsedQuantity },
        { root: true }
      )

      if (isRTR) {
        dispatch('rtrUser/setPhoneNumber', phone, { root: true })
        dispatch('checkout/saveRedeemPhoneNumber', phone)
      }

      await executeInAppNavigationFlow(
        {
          errorPage,
          dispatch,
          getters,
          redirect,
          $sentry,
          $contextPath,
        },
        {
          productId,
          currency,
          merchant,
          quantity: parsedQuantity,
          countryAbv,
          openRangeValue: parsedOpenRangeValue,
        }
      )

      return
    }

    // deep link flow for CgPay checkout
    await deepLinkAccessFlow(
      {
        errorPage,
        dispatch,
        redirect,
        getters,
        $sentry,
        $contextPath,
      },
      { orderId, hash, currency, merchant, countryAbv }
    )
  } catch (error) {
    if (process.env.NODE_ENV === 'development') throw error
    $sentry.captureException(error)

    const homePageUrl = $contextPath('/')

    redirect(homePageUrl)
  }
}
