import get from '~/utils/get'

export default function mapWithFilter<T>(
  collection,
  callback,
  filterBy
): Array<T> {
  if (!collection) return []
  return collection
    .filter(item => get(item, filterBy))
    .map(item => callback(item))
}

export function mapWithPropFilter<T>(
  collection,
  callback: (item: unknown) => void,
  filterBy: string
): Array<T> {
  return collection.reduce((accumulator, item) => {
    const hasProp = get(item, filterBy)

    if (!hasProp) return accumulator

    accumulator.push(callback(item))

    return accumulator
  }, [])
}
