import no from './no'
import fi from './fi'
import dk from './dk'
import se from './se'
import mx from './mx'
import gb from './gb'
import us from './us'
import ie from './ie'
import pt from './pt'
import ch from './ch'
import pl from './pl'
import be from './be'
import it from './it'
import tr from './tr'
import gr from './gr'
import sk from './sk'
import ca from './ca'
import ae from './ae'
import sa from './sa'
import es from './es'
import at from './at'

const dictionary = {
  no,
  fi,
  dk,
  se,
  mx,
  gb,
  us,
  ie,
  pt,
  ch,
  pl,
  be,
  it,
  tr,
  gr,
  sk,
  ca,
  ae,
  sa,
  es,
  at,
}

export default dictionary
