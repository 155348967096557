import { BLOCKS } from '@contentful/rich-text-types'
const { UL_LIST, OL_LIST, LIST_ITEM } = BLOCKS
export default {
  [UL_LIST]: ({ content }, key, h, next) =>
    h('ul', { key }, next(content, key, h, next)),
  [OL_LIST]: ({ content }, key, h, next) =>
    h('ol', { key }, next(content, key, h, next)),
  [LIST_ITEM]: ({ content }, key, h, next) =>
    h('li', { key }, next(content, key, h, next)),
}
