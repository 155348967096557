<template>
  <section class="min-h-screen bg-gray-light overflow-x-hidden">
    <nuxt />
  </section>
</template>

<script>
export default {
  watch: {
    $route() {
      this.$store.dispatch('ui/closeOverlay')
    },
  },
}
</script>
