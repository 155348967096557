import { META_TAGS } from '~/enums/meta-tags'

export type TMetaTag = {
  name: META_TAGS
  hid: META_TAGS
  content: string
}

export class MetaTagsModel {
  protected readonly meta: TMetaTag[]

  constructor(
    metaDescription: string | undefined,
    metaRobots: string | undefined,
    noRobots: boolean
  ) {
    const descriptionMeta = MetaTagsModel.normalizeMetaTag(
      'DESCRIPTION',
      metaDescription
    )

    this.meta = noRobots
      ? [descriptionMeta]
      : [descriptionMeta, MetaTagsModel.buildRobotsMeta(metaRobots)]
  }

  private static normalizeMetaTag(
    type: string,
    content: string | undefined
  ): TMetaTag {
    const name = META_TAGS[type]
    const metaTag = {
      name,
      hid: name,
      content,
    }

    return metaTag as TMetaTag
  }

  private static buildRobotsMeta(metaRobots: string | undefined): TMetaTag {
    return MetaTagsModel.normalizeMetaTag(
      'ROBOTS',
      metaRobots || 'noindex, nofollow'
    )
  }

  toJSON(): TMetaTag[] {
    return this.meta
  }
}
