export default {
  'about-us': 'https://company.recharge.com',
  'amazon-de': 'amazon',
  'amazon-de/10-eur': 'amazon',
  'amazon-de/100-eur': 'amazon',
  'amazon-de/50-eur': 'amazon',
  astropay: 'astropay',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  'battlenet/50-eur': 'blizzard',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  cdon: 'shopping-gift-cards',
  'cdon/20-eur': 'shopping-gift-cards',
  'cdon/50-eur': 'shopping-gift-cards',
  'crypto-voucher': 'crypto-voucher',
  cryptocurrency: 'cryptocurrency',
  dent: 'mobile-top-up',
  'ea-origin': 'ea-origin',
  ecovoucher: 'ecovoucher',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  flexepin: 'flexepin',
  'free-fire': 'free-fire',
  'gaming-gift-cards': 'gaming-cards',
  'jeton-cash': 'jeton-cash',
  'mobile-top-up': 'mobile-top-up',
  ncoin: 'ncoin',
  'ncoin/100-eur': 'ncoin',
  'ncoin/50-eur': 'ncoin',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'netflix/25-eur': 'netflix',
  'netflix/50-eur': 'netflix',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop/15-eur',
  'nintendo-eshop/25-eur': 'nintendo-eshop/25-eur',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-months': 'nintendo-switch-online/12-months',
  openbucks: 'openbucks',
  'payment-methods': 'payment-methods',
  paysafecard: 'paysafecard',
  'paysafecard/10-eur': 'paysafecard',
  'paysafecard/25-eur': 'paysafecard',
  'paysafecard/50-eur': 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/12-months': 'playstation-plus',
  'playstation-plus/3-months': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'playstation-store/50-eur': 'playstation-store',
  'prepaid-amex-us': 'prepaid-credit-cards',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'prepaid-visa-us': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'pubg-us': 'pubg',
  'pubg-us/3850-uc': 'pubg',
  'right-of-withdrawal': 'how-it-works',
  'cookie-statement': 'cookie-statement',
  'how-it-works': 'how-it-works',
  roblox: 'roblox',
  runescape: 'runescape',
  'runescape/30-days': 'runescape/30-days',
  'runescape/90-days': 'runescape/90-days',
  'shopping-gift-cards': 'shopping-gift-cards',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  steam: 'steam',
  'steam/10-eur': 'steam',
  'steam/100-eur': 'steam',
  'steam/20-eur': 'steam',
  'steam/25-eur': 'steam',
  'steam/5-eur': 'steam',
  'steam/50-eur': 'steam',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  twitch: 'twitch',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/60-days': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/11-eur-xbox-one': 'xbox-live-gold',
  'xbox-live-gold/12-months': 'xbox-live-gold',
  'xbox-live-gold/20-eur-xbox-one': 'xbox-live-gold',
  'xbox-live-gold/3-months': 'xbox-live-gold',
  'xbox-live-gold/50-eur-xbox-one': 'xbox-live-gold',
  'xbox-live-gold/6-eur-xbox-one': 'xbox-live-gold',
  zalando: 'zalando',
  'zalando/50-eur': 'zalando',
}
