import checkout from './checkout'

export default {
  modules: {
    checkout,
  },

  state() {
    return {
      overlay: {
        visibility: 'hidden',
        name: '',
        type: undefined,
      },
      nestingOverlays: [],
      menu: {
        items: [],
      },
      overflowHidden: false,
    }
  },

  getters: {
    overlayVisibility: state => state.overlay.visibility,
    overlayName: state => state.overlay.name,
    overlayType: state => state.overlay.type,

    nestingOverlays: state => state.nestingOverlays,
    mobileMenuItems: state => state.menu.items,
  },

  actions: {
    toggleOverlay({ commit }, { visibility, name, type }) {
      commit('setOverlayVisibility', visibility)
      commit('setOverlayName', name)
      commit('setOverlayType', type)
    },
    closeOverlay({ commit }) {
      commit('setOverlayName', undefined)
      commit('setOverlayType', undefined)
      commit('setOverlayVisibility', 'hidden')
    },
    showNestingOverlay({ commit }, { name }) {
      commit('addNestingOverlay', name)
    },
    hideNestingOverlay({ commit }) {
      commit('removeNestingOverlay')
    },
    setMobileMenuItems({ commit }, { items }) {
      commit('setMobileMenuItems', items)
    },
  },

  mutations: {
    setOverlayVisibility(state, visibility) {
      state.overlay.visibility = visibility
    },
    setOverlayName(state, name) {
      state.overlay.name = name
    },
    setOverlayType(state, type) {
      state.overlay.type = type
    },
    removeNestingOverlay(state) {
      state.nestingOverlays.pop()
      if (state.nestingOverlays.length === 0 && !state.overflowHidden) {
        const bodyHTMLElement = document.querySelector('body')
        bodyHTMLElement.classList.remove('overflow-hidden')
      }
    },
    addNestingOverlay(state, name) {
      const bodyHTMLElement = document.querySelector('body')
      if (state.nestingOverlays.length === 0) {
        state.overflowHidden = bodyHTMLElement.classList.contains(
          'overflow-hidden'
        )
      }
      if (!state.overflowHidden) {
        bodyHTMLElement.classList.add('overflow-hidden')
      }
      state.nestingOverlays.push({
        name,
        level: state.nestingOverlays.length + 1,
      })
    },
    setMobileMenuItems(state, items) {
      if (undefined === state.menu) {
        state.menu = {}
      }
      state.menu.items = items
    },
  },
}
