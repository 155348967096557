export const filterArrayBySearchTerm = (
  items,
  searchFields: string[],
  searchTerm: string
): [] => {
  const formattedTerm = searchTerm.toLowerCase()
  return items.filter(item =>
    searchFields.some(field =>
      item[field].toLowerCase().includes(formattedTerm)
    )
  )
}
