import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import { IImageModel, ICMSImageModel, ImageModel } from '../base/Image'
import { PageModel, ICMSPageModel, IPage } from '../base/Page'

export interface IFeaturedCard {
  title: string
  brandImg?: IImageModel
  image: IImageModel
  buttonText: string
  brand: IPage
}

export interface ICMSFeaturedCardModel extends Entry {
  fields: {
    title: string
    brandImg?: ICMSImageModel
    image: ICMSImageModel
    buttonText: string
    brand: ICMSPageModel
  }
}

export class FeaturedCardModel extends EntryModel {
  protected readonly title: string
  protected readonly brandImg?: IImageModel
  protected readonly image: IImageModel
  protected readonly buttonText: string
  protected readonly brand: IPage

  constructor(featuredCardModel: ICMSFeaturedCardModel) {
    super(featuredCardModel)
    const {
      fields: { brandImg, buttonText },
    } = featuredCardModel
    this.title = this.requiredField('title')
    this.brandImg = brandImg ? new ImageModel(brandImg).toJSON() : undefined
    this.buttonText = buttonText
    this.brand = new PageModel(this.requiredField('brand')).toJSON()
    this.image = new ImageModel(this.requiredField('image')).toJSON()
  }

  toJSON(): IFeaturedCard {
    return {
      title: this.title,
      buttonText: this.buttonText,
      brandImg: this.brandImg,
      image: this.image,
      brand: this.brand,
    }
  }
}

export default FeaturedCardModel
