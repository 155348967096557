import { ActionTree } from '../types'
import { TYPES } from './mutation-types'
import { IRecommerceState } from './state'
import { RecommerceCommit } from './mutations'
import { normalizeProduct } from '~/normalizers/recommerce/product'
import { normalizeVariant } from '~/normalizers/recommerce/variant'
import { ContentError } from '~/apis/error/ContentError'
const { SET_PRODUCT, SET_VARIANT } = TYPES

interface RecommerceActions {
  fetchProductBySlug(slug: string): Promise<void>
  fetchVariantById(id: number | string): Promise<void>
  fetchVariantBySlug({
    productSlug,
    variantSlug,
  }: {
    productSlug: string
    variantSlug: string
  }): Promise<void>
}

const actions: ActionTree<
  IRecommerceState,
  RecommerceCommit,
  RecommerceActions
> = {
  async fetchProductBySlug({ commit }, slug) {
    const product = await this.$recommerce('productBySlug')({
      productSlug: slug,
    })

    if (!product)
      throw new ContentError(`Product not found, productSlug: '${slug}'`)

    commit(SET_PRODUCT, normalizeProduct(product))
  },

  async fetchVariantById({ commit }, id) {
    const variant = await this.$recommerce('variantById')({ id })
    if (!variant) throw new ContentError(`Variant not found, id: '${id}'`)

    commit(SET_VARIANT, normalizeVariant(variant))
  },

  async fetchVariantBySlug({ commit }, { productSlug, variantSlug }) {
    const variant = await this.$recommerce('variantBySlug')({
      productSlug,
      variantSlug,
    })
    if (!variant)
      throw new ContentError(
        `Variant not found by slug, product: '${productSlug}', variant: '${variantSlug}'`
      )

    commit(SET_VARIANT, normalizeVariant(variant))
  },
}

export default actions
