export enum GTMEvents {
  ADD_TO_CART = 'add_to_cart',
  APPLY_COUPON = 'apply_coupon',
  BACK_TO_TOP = 'back_to_top',
  CATEGORY_COMING_SOON = 'category_coming_soon',
  CHANGE_NUMBER = 'change_number',
  CHANGE_NUMBER_RESPONSE = 'change_number_response',
  CHANGE_OUT_OF_STOCK_PRODUCT = 'change_out_of_stock_product',
  CHECKOUT = 'checkout',
  CHECKOUT_CREATE_CLIENT_SESSION = 'checkout_create_client_session',
  CHECKOUT_EMAIL = 'checkout_email',
  CHECKOUT_EMAIL_CHANGE = 'checkout_email_change',
  CHECKOUT_FPC = 'checkout_fpc',
  CHECKOUT_PAYMENT_CHANGE = 'checkout_payment_change',
  CHECKOUT_SELECTPAYMENT = 'checkout_selectpayment',
  CHECKOUT_STARTPAYMENT = 'checkout_startpayment',
  CHECKOUT_STARTPAYMENT_TWO = 'checkout_startpayment2',
  CHECKOUT_WIDGET_COMPLETEPAYMENT = 'checkout_widget_completepayment',
  CHECKOUT_WIDGET_LOADED = 'checkout_widget_loaded',
  CHECKOUT_WIDGET_STARTPAYMENT = 'checkout_widget_startpayment',
  CHECKOUT_WIDGET_SELECTPAYMENT = 'checkout_widget_selectpayment',
  CHECKOUT_WIDGET_PAYMENT_ERROR = 'checkout_widget_payment_error',
  CLICK_BRAND = 'click_brand',
  CLICK_COUPON = 'click_coupon',
  CLICK_PREFERENCES = 'click_preferences',
  CONFIRM_COUNTRY_SELECT = 'confirm_country_select',
  CONFIRM_PREFERENCES = 'confirm_preferences',
  COUNTRY_SELECTION = 'country_selection',
  EXIT_CHANGE_NUMBER = 'exit_change_number',
  FORM_SUBMIT = 'form_submit',
  GEOLOCK_PRODUCT = 'geolock_product',
  LMP_WEBSITE_ID = '001',
  GMP_WEBSITE_ID = '002',
  CHANGE_GEOLOCATION_PRODUCT = 'change_geolocation_product',
  NUMBER_VALIDATION_EXIT = 'NV_exit_modal',
  NUMBER_VALIDATION_INSERT = 'insert_number',
  NUMBER_VALIDATION_RESPONSE = 'number_validation_response',
  ORDER_ID = 'order_id',
  ORDER_IDS = 'order_ids',
  OUT_OF_STOCK_PRODUCT = 'out_of_stock_product',
  PAGE = 'page',
  PAYMENT_ERROR = 'payment_error',
  PRODUCT_IMRESSIONS = 'product_impressions',
  PURCHASE = 'purchase',
  QUANTITY_SELECTION = 'quantity_selection',
  REMOVE_COUPON = 'remove_coupon',
  SWITCH_SUBCATEGORY = 'switch_subcategory',
  WHY_SERVICE_FEE = 'why_service_fee',
  PAYMENT_ERROR_INFORMATION = 'payment_error_information',
  PAYMENT_ERROR_RETRY_PAYMENT = 'payment_error_retrypayment',
  PAYMENT_ERROR_SELECTPAYMENT = 'payment_error_selectpayment',
  PAYMENT_ERROR_HELP = 'payment_error_help',
  PAYMENT_ERROR_CLOSE = 'exit_payment_error_overlay',
  RESEND_VERIFICATION_CODE = 'resend_verification_code',
  SEARCH = 'search',
  SHOW_MORE_PAYMENT_METHODS = 'show_more_payment_methods',
  SHOW_LESS_PAYMENT_METHODS = 'show_less_payment_methods',
  VIEW_SEARCH_RESULTS = 'view_search_results',
  LOGIN = 'login',
  SIGN_OUT = 'sign_out',
  SIGN_UP = 'sign_up',
  VERIFIED_EMAIL = 'verified_email',
  UPDATE_PASSWORD = 'update_password',
  PASSWORD_RESET = 'password_reset',
  AUTHENTICATION_SUCCESS = 'authentication_success',
  ERROR_EVENT = 'error_event',
  REFUND = 'refund',
  SELECT_PRODUCT_CURRENCY = 'select_product_currency',
  CONFIRM_SELECT_PRODUCT_CURRENCY = 'confirm_select_product_currency',
  CHECKOUT_AVS_DETAILS_LOADED = 'checkout_avs_details_loaded',
  CHECKOUT_MOBILEVERIFICATION_INPUT_ERROR = 'checkout_mobileverification_input_error',
  CHECKOUT_MOBILEVERIFICATION_RESEND_CODE = 'checkout_mobileverification_resend_code',
  CHECKOUT_MOBILEVERIFICATION_VERIFY = 'checkout_mobileverification_verify',
  CHECKOUT_AVS_DETAILS_STARTPAYMENT = 'checkout_avs_details_startpayment',
  CHECKOUT_CREDITCARD_DETAILS_LOADED = 'checkout_creditcard_details_loaded',
  CHECKOUT_MOBILEVERIFICATION_LOADED = 'checkout_mobileverification_loaded',
  CHECKOUT_PSC_DETAILS_CHANGE = 'checkout_psc_details_change',
  CHECKOUT_PSC_DETAILS_CONTINUE = 'checkout_psc_details_continue',
  CHECKOUT_PSC_DETAILS_LOADED = 'checkout_psc_details_loaded',
  CHECKOUT_CREDITCARD_STARTPAYMENT = 'checkout_creditcard_startpayment',
  RECHARGE_CONTACT_BUTTON = 'recharge_contact_button',
  SMS_ID = 'sms_id',
  SHOW_REORDER_BANNER = 'show_reorder_banner',
  SHOW_REORDER_BUTTON = 'show_reorder_button',
  SHOW_ALL_ORDERS = 'show_all_orders',
  REORDER = 'reorder',
  COPY_RAF_LINK = 'copy_raf_link',
  CLICK_RAF_PROMOTIONAL_TERMS = 'raf_promotional_terms',
  CLICK_JOIN_BY_RAF = 'join_by_raf',
  REORDER_CHANGE_AMOUNT = 'reorder_change_amount',
  SHOW_WEB_TO_APP_BANNER = 'show_web-to-app_banner',
  WEB_TO_APP_DOWNLOAD_APP = 'web-to-app_download_app',
  WEB_TO_APP_CONTINUE_NAVIGATING = 'web-to-app_continue_navigating',
}

export enum AUTH_EVENT_METHODS {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

export enum AUTH_EVENT_ERROR_TYPE {
  FORM_VALIDATION = 'form_validation',
  FORM_SUBMISSION = 'form_submission',
}

export enum GTMDefaults {
  APP_PLATFORM = 'web',
  CHECKOUT_STEPS = '5',
  DEFAULT_CURRENCY = 'EUR',
  WEBSITE_ID = '1',
}

export enum COUPON_STATUS {
  COUPON_SUCCESS = 'coupon_success',
  COUPON_NOT_IN_PRICE_RANGE = 'coupon_not_in_price_range',
  COUPON_CONSUMED = 'coupon_consumed',
  COUPON_EXPIRED = 'coupon_expired',
  COUPON_NOT_RECOGNISED = 'coupon_not_recognised',
  COUPON_NOT_VALID = 'coupon_not_valid',
  COUPON_NOT_ENTERED = 'coupon_not_entered',
}

export enum CHECKOUT_ERROR_SCENARIOS {
  ERROR_001 = 'bank_refusal',
  ERROR_002 = 'insufficient_funds',
  ERROR_003 = 'input_error',
  ERROR_004 = 'blocked_fpc',
  ERROR_005 = 'payment_outage',
  UNKNOWN = 'generic_error',
}

export enum PAGE_VERSIONS {
  VARIATION_PRIMER = 'variation_primer',
  VARIATION_DEFAULT = 'variation_default',
}

export enum EVENT_LOCATIONS {
  IN_PAGE = 'in-page',
}

export enum ACTION_EVENTS {
  PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED',
  PAYMENT_METHOD_UNSELECTED = 'PAYMENT_METHOD_UNSELECTED',
}

export enum RAF_STATUS {
  AVAILABLE = 'available',
  NOT_ELIGIBLE = 'not_eligible',
  COUNTRY_RESTRICTED = 'country_restricted',
  INVALID_REFERRAL_CODE = 'invalid_referral_code',
}
