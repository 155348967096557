import { EntryModel } from './Entry'
import isEmpty from '~/utils/isEmpty'

export type TFile = {
  url?: string
  contentType?: string | null
  details?: {
    image: {
      width?: number | null
      height?: number | null
      ratio?: number | null
    }
  }
}
export interface IImageModel {
  file: TFile
  title?: string | null
}
export interface IRecommerceImageModel {
  title?: string | null
  path: string
}
export interface ICMSImageModel {
  fields: IImageModel
}

export interface IResponsiveImageModel {
  mobile?: IImageModel
  desktop?: IImageModel
}

export class ImageModel extends EntryModel {
  protected readonly file: TFile
  protected readonly title: string | null
  protected readonly cdnAssetsDomain: string =
    process.env.CMS_ASSETS_DOMAIN || ''

  protected formattedUrl: string
  protected isBig: boolean

  constructor(imageModel: ICMSImageModel) {
    super(imageModel)

    const {
      fields: { file, title = '' },
    } = imageModel

    if (isEmpty(file)) return
    this.getFormattedUrl(file)

    this.getCDNUrl()

    this.isBig = false

    this.file = { ...file, url: this.formattedUrl }

    this.title = title
  }

  getFormattedUrl(file: TFile): void {
    const { url, contentType, details } = file
    if (details?.image.width && details?.image.height) {
      this.isBig = details.image.width >= 100 && details.image.height >= 100
    }

    switch (contentType) {
      case 'image/jpg':
      case 'image/jpeg':
        this.formattedUrl = `${url}?fm=jpg&fl=progressive`
        break
      case 'image/png':
        this.formattedUrl = `${url}?fm=png&fl=png8`
        break
      case 'image/svg+xml':
        this.formattedUrl = `${url}?q=100`
        break
      default:
        this.formattedUrl = url || ''
    }
  }

  getCDNUrl(): void {
    const arrayOfPaths = this.formattedUrl.split('/')

    if (arrayOfPaths.length <= 1) return

    this.formattedUrl = arrayOfPaths
      .filter(item => item && !item.includes('http'))
      .map((item, index) => (index === 0 ? `//${this.cdnAssetsDomain}` : item))
      .join('/')
  }

  toJSON(): IImageModel {
    return {
      file: this.file,
      title: this.title,
    }
  }
}
