export enum sections {
  sectionBrandList = 'brand-list',
  promoComponent = 'promotion-banner',
  productListComponent = 'product-list',
  sectionUspsBar = 'usps-bar',
  sectionInfoBlocks = 'activation-info',
  sectionCategoryList = 'category-list',
  sectionCategoryBanner = 'category-banner',
  sectionAccordion = 'accordion-list',
  sectionHeroBanner = 'hero-banner',
  sectionBrandBanner = 'brand-banner',
  sectionProductBanner = 'product-banner',
  sectionBrandPageNavigation = 'brand-quick-links',
  sectionFreeForm = 'free-form',
  sectionBreadcrumbs = 'breadcrumbs-section',
  sectionInfoBoxList = 'info-box-list',
  sectionFeaturedCardList = 'featured-card-list',
  sectionH1Title = 'h1-title',
  sectionPopularBrandList = 'popular-brand-list',
  sectionUspsBlock = 'usps-block',
  sectionInfoBadge = 'info-badge',
  sectionPaymentMethodsBanner = 'payment-methods-banner',
}

export enum orderSections {
  sectionInfoBlocks = 'activation-info',
  sectionAccordion = 'accordion-list',
  sectionFreeForm = 'free-form',
}

export enum orderInvestigatedSections {
  sectionFreeForm = 'free-form',
}

export default { sections }
