<template functional>
  <div
    v-bind="data.attrs"
    :class="[
      data.class,
      data.staticClass,
      props.wrap ? 'flex-wrap' : 'flex-no-wrap',
    ]"
    class="flex -mx-1 sm:-mx-2 xl:-mx-3"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    wrap: Boolean,
  },
}
</script>
