import { hydrateWhenVisible, hydrateSsrOnly } from '~/services/lazy-hydration'

export const UiTitle = hydrateSsrOnly(() => import('./title'))
export const Divider = hydrateSsrOnly(() => import('./divider'))
export { default as Icon } from './icon'
export const UiLink = hydrateWhenVisible(() => import('./link'))
export const NoticeBox = hydrateWhenVisible(() => import('./notice-box'))
export const TabLink = hydrateWhenVisible(() => import('./tab-link'))
export { default as UiImage } from './image'
export { default as Usps } from './usps'
export { default as Card } from './card'
export const UiButton = hydrateWhenVisible(() => import('./button'))
export const RadioSelect = hydrateWhenVisible(() => import('./radio-select'))
export const FederatedAuthButton = hydrateWhenVisible(() =>
  import('./federated-auth-button')
)
export const FederatedSignIn = hydrateWhenVisible(() =>
  import('./federated-sign-in')
)
export const PasswordEyeIcon = hydrateWhenVisible(() =>
  import('./password-eye-icon')
)
export const ButtonBox = hydrateWhenVisible(() => import('./button-box'))
export const Flag = hydrateSsrOnly(() => import('./flag'))
export const DynamicScript = hydrateSsrOnly(() => import('./dynamic-script'))
export const Tag = hydrateSsrOnly(() => import('./tag'))
export const Collapsable = hydrateWhenVisible(() => import('./collapsable'))
export const ButtonIcon = hydrateSsrOnly(() => import('./button-icon'))
export const UiInput = hydrateWhenVisible(() => import('./input'))
export const UiTextarea = hydrateWhenVisible(() => import('./textarea'))
export const UiSelect = hydrateWhenVisible(() => import('./select'))
export const Checkbox = hydrateWhenVisible(() => import('./checkbox'))
export const Alert = hydrateWhenVisible(() => import('./alert'))
export const CopyToClipboard = hydrateWhenVisible(() =>
  import('./copy-to-clipboard')
)
export const Loader = hydrateWhenVisible(() => import('./loader'))
export { default as Ribbon } from './ribbon'

export const NoSearchResults = hydrateWhenVisible(() =>
  import('./no-search-results')
)

export const AuthPageContentLayout = hydrateWhenVisible(() =>
  import('./auth-page-content-layout')
)
export const LoaderDots = hydrateWhenVisible(() => import('./loader-dots'))

export { default as TildeDivider } from './tilde-divider'

export const PromotionTag = hydrateWhenVisible(() => import('./promotion-tag'))

export const RibbonHorizontal = hydrateSsrOnly(() =>
  import('./ribbon-horizontal')
)
