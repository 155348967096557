
import ServiceCostsUsps from './service-costs-usps.vue'
import {
  discountPrice,
  product,
  productPrice,
  promoCode,
  totalPrice,
  transactionCost,
  quantity,
  prependToServiceFee,
} from './props'
import IconTooltipModal from '@/components/organisms/icon-tooltip-modal/index.vue'

export default {
  components: {
    IconTooltipModal,
    ServiceCostsUsps,
  },
  props: {
    discountPrice,
    product,
    productPrice,
    promoCode,
    totalPrice,
    transactionCost,
    quantity,
    prependToServiceFee,
  },
  computed: {
    localAmount(): number {
      const {
        price: { localAmount = 0 },
      } = this.productPrice

      return localAmount
    },

    serviceFee(): number {
      const { serviceFee = 0 } = this.productPrice
      return serviceFee
    },
    discount(): number {
      const {
        price: { discount = 0 },
      } = this.productPrice
      return discount
    },
    serviceFeeText(): string {
      return this.prependToServiceFee
        ? `Recharge.com ${this.$t('general.service_costs')}`
        : this.$t('general.service_costs')
    },
  },
}
