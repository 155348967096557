import { Plugin } from '@nuxt/types'
import isEmpty from '~/utils/isEmpty'
import { LOCALSTORAGE_REHYDRATION_ACTIONS } from '~/enums/localstorage-rehydration-actions'
import { getStorageItem } from '~/services/localstorage'
import { LOCALSTORAGE_CUSTOM_ROUTES_PAGE } from '~/enums/localstorage-custom-routes-page'

function localStorageHydration({ route, store }): void {
  const [fullPage]: [string] = route.name.split('___')
  // TODO: Temporary solution so that the primer-checkout uses the localStorage of checkout
  // When the new checkout is the only one that we use it will be removed
  const page = fullPage.replace('primer-', '')
  const actions: [string] = LOCALSTORAGE_REHYDRATION_ACTIONS[page] || []
  if (!actions) return
  const customRoutePage: string = LOCALSTORAGE_CUSTOM_ROUTES_PAGE[page]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let parsedLocalStorageStore: any

  try {
    const appLocalStorageStore = getStorageItem({ storage: 'recharge-store' })
    const pageLocalStorageStore = getStorageItem({
      storage: `recharge-store-${page}`,
    })
    const customRoutePageLocalStorageStore = customRoutePage
      ? getStorageItem({
          storage: `recharge-store-${customRoutePage}`,
        })
      : {}

    parsedLocalStorageStore = {
      ...appLocalStorageStore,
      ...pageLocalStorageStore,
      ...customRoutePageLocalStorageStore,
    }
  } catch (error) {
    parsedLocalStorageStore = {}
  }

  if (isEmpty(parsedLocalStorageStore)) return
  actions.forEach(action => store.dispatch(action, parsedLocalStorageStore))
}

const localStorageRehydration: Plugin = (_, inject): void => {
  inject('localStorageHydration', localStorageHydration)
}

export default localStorageRehydration
