import { stringValue } from 'aws-sdk/clients/iot'
import {
  ITopupProduct,
  ITopupMyOrders,
  ITopupMyOrder,
  ITopupFetchCalculateOrderRequestParams,
  ITopupFetchCalculateOrderResponse,
  IPrePaymentParams,
  PrePaymentDataQueryWithError,
} from './interfaces'
import { PRODUCT_KIND, REDEEM_TYPE } from './enums'
import isEmpty from '~/utils/isEmpty'
import { ISentry } from '~/types/store'
import {
  ITopupOrderModel,
  ORDER_PAYMENT_STATUS,
  ORDER_STATUS,
  ORDER_TYPE,
} from '~/models/components/Order'
import Debug, { DebugKeys } from '~/dev-tools/debug'
import { TopupSdk, LazyLoaded } from '~/apis/clients/graphql/create-sdk'
import {
  Currency,
  Maybe,
  Merchant,
  Country,
  OrderType,
} from '~/apis/clients/graphql/types/topup'

import { GLOBAL_MERCHANT } from '~/utils/constants/api'

const debug = new Debug(DebugKeys.services)

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type DeepMaybe<T> = T extends (infer I)[]
  ? Maybe<DeepMaybe<I>[]>
  : T extends Record<string, any>
  ? Maybe<
      {
        [P in keyof T]: DeepMaybe<T[P]>
      }
    >
  : Maybe<T>

function isObject(maybeObj: unknown): maybeObj is Record<string, any> {
  return typeof maybeObj === 'object' && maybeObj !== null
}

function withDefaults<T, U extends DeepPartial<T>>(
  data: DeepMaybe<T>,
  defaults: U
): DeepMaybe<T> & U
function withDefaults<T, U extends DeepPartial<T>>(
  data: DeepMaybe<T[]>,
  defaults: U[]
): (DeepMaybe<T> & U)[]
function withDefaults<T, U extends DeepPartial<T>>(
  data: DeepMaybe<T> | DeepMaybe<T[]>,
  defaults: U
) {
  if (Array.isArray(data)) {
    return data.map(record => withDefaults(record, defaults[0]))
  }
  if (!data && Array.isArray(defaults)) return []
  if (!data) return defaults
  return Object.keys(defaults).reduce((record, key) => {
    if (Array.isArray(defaults[key]) || isObject(defaults[key])) {
      return { ...record, [key]: withDefaults(record[key], defaults[key]) }
    }
    return { ...record, [key]: record[key] || defaults[key] }
  }, data)
}

export const defaultTopupProductFields = {
  id: '',
  products_service_id: '',
  available: false,

  brand_name: '',
  prices: [],
}

export async function fetchProducts({
  $topup,
  productsServiceIds,
  currency,
  merchant,
  quantity,
  country,
}: {
  $topup: LazyLoaded<TopupSdk>
  productsServiceIds: number[]
  currency: string
  merchant: string
  country?: Country
  quantity?: number
}): Promise<ITopupProduct[]> {
  const {
    data: { products },
  } = await $topup('products')({
    productsServiceIds,
    currency: currency as Currency,
    merchant: merchant as Merchant,
    quantity: quantity || null,
    country:
      merchant === GLOBAL_MERCHANT ? (country?.toUpperCase() as Country) : null,
  })
  return withDefaults(products, [
    {
      id: '',
      products_service_id: '',
      available: false,
      prices: [
        {
          local_amount: '',
          local_discount_amount: '',
          local_currency: '',
        },
      ],
      brand_name: '',
      redeem_type: '' as REDEEM_TYPE,
      product_kind: '' as PRODUCT_KIND,
      scam_notification_type: '',
      scamNotificationType: '',
      service_fees: [
        {
          local_service_fee: '',
        },
      ],
    },
  ])
}

export async function fetchOrderSummary(
  $topup: LazyLoaded<TopupSdk>,
  $sentry: ISentry,
  params: ITopupFetchCalculateOrderRequestParams
): Promise<ITopupFetchCalculateOrderResponse> {
  const defaults = {
    transactionCost: {
      value: undefined,
      currency: undefined,
    },
    discountPrice: {
      value: undefined,
    },
    orderPrice: {
      value: undefined,
    },
    serviceFee: {
      value: undefined,
    },
    totalPrice: {
      value: undefined,
    },
    walletBalance: {
      value: undefined,
    },
    extraAmountForApplicableReward: {
      value: undefined,
    },
    isMinimumOrderPrice: false,
  }

  try {
    const { data } = await $topup('calculateOrder')(params)
    return withDefaults(data, {
      calculateOrder: defaults,
    })
  } catch (error) {
    debug.log(error.message)
    $sentry.captureException(error)
    throw error
  }
}

export async function fetchMyOrders(
  $topup: LazyLoaded<TopupSdk>,
  {
    merchantId,
    orderType,
    limit,
    page,
  }: {
    merchantId: number
    orderType: Maybe<OrderType>
    limit: number
    page: number
  },
  $sentry: ISentry
): Promise<ITopupMyOrders> {
  try {
    const { data } = await $topup('myOrders')({
      merchant_id: merchantId,
      order_type: orderType,
      limit,
      page,
    })

    return withDefaults(data, {
      myOrders: [
        {
          __typename: '' as ORDER_TYPE.TOPUP,
          id: 0,
          email: '',
          status: '' as ORDER_STATUS,
          payment_status: '' as ORDER_PAYMENT_STATUS,
          price: '',
          currency: '',
          total_price: '',
          pay_method: '',
          url: '',
          quantity: '',
          created_at: '',
          products: [
            {
              id: '',
              products_service_id: '',
              name: '',
              available: false,
              visible: false,
              codes: [
                {
                  code: '',
                },
              ],
              product_kind: '',
              brand_name: '',
              redeem_type: '',
              scam_notification_type: null,
            },
          ],
          user: {
            email: '',
          },
          rtr: {
            phone: '',
          },
          transaction_cost: {
            currency: '',
            value: 0,
          },
          voucher: '',
          hash: '',
          open_range_value: null,
        },
      ],
    })
  } catch (error) {
    debug.log(error.message)
    $sentry.captureException(error)
  }

  return { myOrders: [] }
}

export async function fetchMyOrder(
  $topup: LazyLoaded<TopupSdk>,
  {
    orderId,
    orderType,
  }: {
    orderId: string
    orderType: Maybe<OrderType>
  },
  $sentry: ISentry
): Promise<ITopupMyOrder> {
  try {
    const { data } = await $topup('myOrder')({
      id: orderId,
      order_type: orderType,
    })

    return withDefaults(data, {
      myOrder: {
        __typename: '' as ORDER_TYPE.TOPUP,
        id: 0,
        status: '' as ORDER_STATUS,
        payment_status: '' as ORDER_PAYMENT_STATUS,
        email: '',
        price: '',
        currency: '',
        total_price: '',
        pay_method: '',
        url: '',
        quantity: '',
        created_at: '',
        products: [
          {
            id: '',
            products_service_id: '',
            name: '',
            codes: [
              {
                code: '',
                serial_number: '',
                status: '',
              },
            ],
          },
        ],
        user: {
          email: '',
          first_name: '',
          last_name: '',
        },
        transaction_cost: {
          currency: '',
          value: 0,
        },
        rtr: {
          phone: '',
        },
        voucher: '',
        hash: '',
        open_range_value: null,
      },
    })
  } catch (error) {
    debug.log(error.message)
    $sentry.captureException(error)
  }

  return { myOrder: null }
}

export async function fetchOrder(
  $topup: LazyLoaded<TopupSdk>,
  {
    id,
    hash,
    merchant,
    country,
    locale,
  }: {
    merchant: string
    id: string
    hash: string
    country: Maybe<Country>
    locale: Maybe<string>
  },
  $sentry: ISentry
): Promise<{ order: Maybe<ITopupOrderModel> }> {
  try {
    const {
      data: { order },
    } = await $topup('order')({
      merchant: merchant as Merchant,
      id,
      hash,
      country,
      locale,
    })

    if (!order) return { order }

    return {
      order: withDefaults(order, {
        hash: '',
        status: '' as ORDER_STATUS,
        payment_status: '' as ORDER_PAYMENT_STATUS,
        order_currency: '',
        price: '',
        service_fee: '',
        total_price: '',
        pay_method: '',
        products: [
          {
            id: '',
            products_service_id: '',
            name: '',
            available: false,
            visible: false,
            codes: [
              {
                code: '',
              },
            ],
          },
        ],
        quantity: '',
        user: {
          email: '',
        },
        rtr: {},
      }),
    }
  } catch (error) {
    debug.log(error.message)
    $sentry.captureException(error)
  }

  return { order: null }
}

export async function fetchOrderStatus(
  $topup: LazyLoaded<TopupSdk>,
  {
    id,
    hash,
    merchant,
    country,
    locale,
  }: {
    merchant: string
    id: stringValue
    hash: string
    country: Maybe<Country>
    locale: Maybe<string>
  },
  $sentry: ISentry
): Promise<{ status: Maybe<ORDER_STATUS>; paymentStatus: Maybe<string> }> {
  try {
    const { data } = await $topup('orderStatus')({
      merchant: merchant as Merchant,
      id,
      hash,
      country,
      locale,
    })

    return data
      ? (data.orderStatus as {
          status: Maybe<ORDER_STATUS>
          paymentStatus: Maybe<string>
        })
      : { status: null, paymentStatus: null }
  } catch (error) {
    debug.log(error.message)
    $sentry.captureException(error)
  }

  return { status: null, paymentStatus: null }
}

export async function validatePhoneNumber(
  $topup: LazyLoaded<TopupSdk>,
  {
    countryAbv,
    countryCode,
    phoneNumber,
  }: {
    countryAbv: string
    countryCode: string
    phoneNumber: string
  }
): Promise<string> {
  const { data, errors } = await $topup('validatePhoneNumber')({
    countryAbv,
    countryCode,
    phoneNumber,
  })

  if (errors && !isEmpty(errors)) {
    const [error] = errors
    throw new Error(`errors.${error.message}`)
  }

  return data.validatePhoneNumber?.formattedPhoneNumber || ''
}

export async function getPrePaymentData(
  $topup: LazyLoaded<TopupSdk>,
  $sentry: ISentry,
  params: IPrePaymentParams
): Promise<PrePaymentDataQueryWithError> {
  const defaults = {
    geoIpCountry: '',
    showPaysafeForm: true,
    currencyScale: 100,
    billingFields: null,
    value: null,
  }
  try {
    const response = await $topup('prePaymentData')(params)
    return { ...response.data, error: null }
  } catch (error) {
    debug.log(error.message)
    $sentry?.captureException(error)
    return { prePaymentData: defaults, error: error.message }
  }
}
