<template>
  <section>
    <account-header />
    <container class="flex-1 my-12">
      <row wrap>
        <column class="w-full md:w-4/12 lg:w-3/12 mb-6">
          <account-option-list />
        </column>
        <column class="w-full md:w-8/12 lg:w-9/12">
          <nuxt />
        </column>
      </row>
    </container>
  </section>
</template>

<script>
import { AccountOptionList } from '~/components/molecules'
import { Row, Column, Container } from '~/components/grid'
import { AccountHeader } from '~/components/organisms'

export default {
  components: {
    AccountOptionList,
    Row,
    Column,
    Container,
    AccountHeader,
  },
}
</script>
