import { PropOptions } from 'vue'
import { FONT_ICON, SVG_ICON, COLOR, SIZE } from './enums'

const props = {
  icon: {
    type: String,
    required: true,
  } as PropOptions<SVG_ICON | FONT_ICON>,
  color: {
    type: String,
    default: COLOR.link,
  } as PropOptions<COLOR>,
  fontSize: {
    type: String,
    default: SIZE.base,
  } as PropOptions<SIZE | ''>,
  size: {
    type: String,
    default: SIZE.base,
  } as PropOptions<SIZE | ''>,
  width: {
    type: String,
    default: '',
  },
  height: {
    type: String,
    default: '',
  },
  reverse: Boolean,
}

export default props
