const observers = new Map()

export function createObserver(options) {
  if (typeof IntersectionObserver === `undefined`) return null

  const optionKey = JSON.stringify(options)
  if (observers.has(optionKey)) return observers.get(optionKey)

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      // Use `intersectionRatio` because of Edge 15's
      // lack of support for `isIntersecting`.
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      const isIntersecting = entry.isIntersecting || entry.intersectionRatio > 0
      if (!isIntersecting || !entry.target.hydrate) return
      entry.target.hydrate()
    })
  }, options)
  observers.set(optionKey, observer)

  return observer
}

export function loadingComponentFactory(resolvableComponent, options) {
  return {
    render(h) {
      const tag = this.$el ? this.$el.tagName : `div`
      const classList = this.$el ? this.$el.classList.value : ''

      // eslint-disable-next-line no-underscore-dangle
      if (!this.$el) resolvableComponent._resolve()

      return h(
        tag,
        {
          class: classList,
        },
        this.$scopedSlots.default
      )
    },
    ...options,
  }
}

async function resolveAsyncComponents(components) {
  if (!components) return components
  const resolvedComponents = await Promise.all(
    Object.entries(components).map(async ([name, component]) => {
      if (typeof component !== `function`) return { [name]: component }

      const loadedComponent = component()

      if (loadedComponent.component && loadedComponent.component._resolve) {
        await loadedComponent.component._resolve()
      }

      return { [name]: await loadedComponent.component }
    })
  )

  return Object.assign({}, ...resolvedComponents)
}

export function resolvableComponentFactory(component) {
  let r
  const promise = new Promise(resolve => {
    r = resolve
  })

  // eslint-disable-next-line no-underscore-dangle
  promise._resolve = async () => {
    if (typeof component === `function`) {
      const { default: loadedComponent } = await component()

      loadedComponent.components = await resolveAsyncComponents(
        loadedComponent.components
      )
      r(loadedComponent)
    } else {
      r(component)
    }
  }

  return promise
}
