
import Mobile from './mobile.vue'
import Desktop from './desktop.vue'

export default {
  components: {
    Mobile,
    Desktop,
  },
  props: {
    selectedCountry: {
      type: Object,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: 'ltr',
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  computed: {
    componentName(): string {
      return this.$mq === 'sm' ? 'mobile' : 'desktop'
    },
  },
}
