import { ITopupProduct } from '~/services/topup/interfaces'

export interface IProductPriceModel {
  localAmount?: number
  discount?: number
  currency?: string
  subtotal?: number
  discountPercent?: number
  total?: number
}

export interface IProductValuesModel {
  price: IProductPriceModel
  serviceFee: number
}

export class ProductValuesModel {
  protected readonly price: IProductPriceModel
  protected readonly serviceFee: number
  protected readonly locale: string

  constructor({ prices, service_fees: serviceFees }: ITopupProduct) {
    const hasPrice = prices.length > 0

    const hasServiceFee = serviceFees && serviceFees.length > 0
    this.serviceFee = hasServiceFee
      ? ProductValuesModel.normalizeServiceFee(serviceFees)
      : 0
    this.price = hasPrice
      ? ProductValuesModel.normalizePrice(prices, this.serviceFee)
      : {}
  }

  private static normalizePrice(prices, serviceFee): IProductPriceModel {
    const [price] = prices

    if (!price) return {}

    const localAmount = parseFloat(price.local_amount)
    const discount = price.local_discount_amount
      ? Number(price.local_discount_amount)
      : 0
    const currency = price.local_currency

    // TODO: this value should come from the API
    const subtotal = localAmount - discount
    const total = (subtotal + serviceFee).toFixed(2)
    const discountPercent = discount
      ? Math.round(100 / (localAmount * discount))
      : 0

    return {
      localAmount,
      discount,
      currency,
      subtotal,
      discountPercent,
      total,
    }
  }

  private static normalizeServiceFee(serviceFees): number {
    const [serviceFee] = serviceFees

    return parseFloat(serviceFee.local_service_fee || 0)
  }

  toJSON(): IProductValuesModel {
    return {
      price: this.price,
      serviceFee: this.serviceFee,
    }
  }
}
