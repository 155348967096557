<template functional>
  <span
    :class="[
      data.class,
      data.staticClass,
      `skeleton h-${props.height} w-${props.width} ${props.radius} bg-gray inline-block align-middle relative overflow-hidden`,
    ]"
  />
</template>

<script>
import VueTypes from 'vue-types'
export default {
  props: {
    height: VueTypes.string.def('full'),
    width: VueTypes.string.def('full'),
    radius: VueTypes.string.def('rounded'),
  },
}
</script>

<style scoped>
.skeleton {
  &::after {
    @apply absolute inset-0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0.2) 80%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2.6s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
