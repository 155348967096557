import { GetterTree } from 'vuex'
import {
  ICheckoutState,
  ICreatePaymentPayload,
  IPaymentRedirectUrls,
} from './interfaces'

const getters: GetterTree<ICheckoutState, ICheckoutState> = {
  redirectUrls: ({
    createPaymentPayload,
  }: ICheckoutState): IPaymentRedirectUrls => createPaymentPayload.redirectUrls,
  redirectUrl: ({ createPaymentPayload }: ICheckoutState): string =>
    createPaymentPayload.redirectUrl,
  paymentEmail: ({ createPaymentPayload }: ICheckoutState): string =>
    createPaymentPayload.email,
  paymentMethodId: ({ createPaymentPayload }: ICheckoutState): string =>
    createPaymentPayload.paymentMethodId,
  issuerId: ({ createPaymentPayload }: ICheckoutState): string | null =>
    createPaymentPayload.issuer,
  productId: ({ createPaymentPayload }: ICheckoutState): string =>
    createPaymentPayload.productId,
  acceptedTermsAndConditions: ({
    createPaymentPayload,
  }: ICheckoutState): boolean =>
    createPaymentPayload.acceptedTermsAndConditions,
  promoCode: ({ createPaymentPayload }) => createPaymentPayload.voucher,

  discountPrice: ({ createPaymentPayload }) =>
    createPaymentPayload.discountPrice,
  serviceFee: ({ createPaymentPayload }) => createPaymentPayload.serviceFee,
  totalPrice: ({ createPaymentPayload }: ICheckoutState): number =>
    createPaymentPayload.totalPrice,
  createPaymentPayload: ({
    createPaymentPayload,
  }: ICheckoutState): ICreatePaymentPayload => createPaymentPayload,
  isRetryOrder: ({ isRetryOrder }: ICheckoutState): boolean => isRetryOrder,
  primerPhoneChallenge: ({ primerPhoneChallenge }) => primerPhoneChallenge,
  isRedirectDeepLink: ({ isRedirectDeepLink }): boolean => isRedirectDeepLink,
  redirectClientToken: ({ redirectClientToken }): string => redirectClientToken,
}

export default getters
