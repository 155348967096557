import { mapGetters } from 'vuex'

export default isMobile => {
  return {
    data() {
      return {
        resultsOpen: false,
      }
    },
    computed: {
      ...mapGetters('context', { countries: 'countriesWithoutGlobal' }),
      sortedCountries() {
        const countries = [...this.countries]
        return countries.sort((a, b) =>
          a.nameTranslated > b.nameTranslated ? 1 : -1
        )
      },
    },
    watch: {
      resultsOpen(val: boolean): void {
        if (val) {
          const selectedOption = document.getElementsByClassName(
            'selected-country'
            // eslint-disable-next-line no-undef
          ) as HTMLCollectionOf<HTMLElement> | null
          const dropdownWrapper = document.getElementById('results')
          if (selectedOption && dropdownWrapper) {
            setTimeout(() => {
              const selectedOffset = isMobile
                ? selectedOption[0].offsetTop - 150
                : selectedOption[0].offsetTop
              dropdownWrapper.scrollTo(0, selectedOffset)
            }, 50)
          }
        }
      },
    },
  }
}
