import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
import {
  IFeaturedCard,
  ICMSFeaturedCardModel,
  FeaturedCardModel,
} from './FeaturedCard'

import mapWithFilter from '~/services/map-with-filter'

export interface IFeaturedCardList {
  title: string
  cards: IFeaturedCard[]
}

export interface ICMSFeaturedCardList extends Entry {
  title: string
  cards: ICMSFeaturedCardModel[]
}

export class FeaturedCardListModel extends EntryModel {
  protected readonly title: string
  protected readonly cards: IFeaturedCard[]

  constructor(model: ICMSFeaturedCardList) {
    super(model)
    const {
      fields: { cards = [], title },
    } = model
    this.title = title
    this.cards = mapWithFilter(
      cards,
      card => new FeaturedCardModel(card).toJSON(),
      'fields'
    )
  }

  toJSON(): IFeaturedCardList {
    return {
      title: this.title,
      cards: this.cards,
    }
  }
}

export default FeaturedCardListModel
