import { Context, Middleware } from '@nuxt/types'
import { fetchRafAvailability } from '../services/refer-a-friend'
import GLOBAL_COUNTRY from '~/utils/constants/global-country.json'

/**
 * This middleware checks for and sets the availability of the refer a friend
 * feature based on the users geolocation and selected shop country
 */
const referAFriendCountryAvailabilityGuardMiddleware: Middleware = async (
  context: Context
): Promise<void> => {
  if (!process.server) return

  const {
    route: { name, params },
    store,
    req,
    $sentry,
    $auth,
  } = context
  if (!name) {
    return
  }

  // wallet availability to launch in specific countries
  const countriesArray: string[] = []

  const geolocCountry =
    process.env.NODE_ENV === 'development'
      ? 'GR'
      : (req.headers['cloudfront-viewer-country'] as string | undefined)
  if (geolocCountry) countriesArray.push(geolocCountry)

  if (params.country && params.country !== GLOBAL_COUNTRY.code.toLowerCase())
    countriesArray.push(params.country.toUpperCase())

  if (countriesArray.length) {
    const isRafAvailableCountry = await fetchRafAvailability(
      $auth.accountClient,
      $sentry,
      countriesArray.join(',')
    )

    store.dispatch('context/setIsRafAvailableCountry', isRafAvailableCountry)
  }
}

export default referAFriendCountryAvailabilityGuardMiddleware
