import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { IImageModel, ICMSImageModel, ImageModel } from '../base/Image'
import { EntryModel } from '../base/Entry'
import {
  IBrandFallbackInfoModel,
  BrandFallbackInfoModel,
  ICMSBrandFallbackInfoModel,
} from './BrandInfoFallbackModel'
import isEmpty from '~/utils/isEmpty'

export interface IBrandBanner {
  title?: string
  description?: string
  logo?: IImageModel
  showTrustpilot?: boolean
  image?: IImageModel
  imageMobile?: IImageModel
  hasActivePromotion?: boolean
}

export interface ICMSBrandBannerModel extends Entry {
  fields: {
    banner?: ICMSImageModel
    bannerMobile?: ICMSImageModel
    title?: string
    description?: string
    logo?: ICMSImageModel
    showTrustpilot?: boolean
    hasActivePromotion?: boolean
  }
}

export class BrandBannerModel extends EntryModel {
  protected readonly title?: string
  protected readonly logo?: IImageModel
  protected readonly showTrustpilot?: boolean
  protected readonly description?: string
  protected readonly image: IImageModel
  protected readonly imageMobile?: IImageModel
  protected readonly hasLogo: boolean
  protected readonly hasActivePromotion?: boolean

  constructor(
    brandBannerModel: ICMSBrandBannerModel,
    brandInfo: ICMSBrandFallbackInfoModel
  ) {
    super(brandBannerModel)
    const {
      fields: {
        title: bannerTitle,
        logo,
        banner,
        showTrustpilot,
        description,
        bannerMobile,
        hasActivePromotion,
      },
    } = brandBannerModel

    const fallbackInfo: IBrandFallbackInfoModel = new BrandFallbackInfoModel(
      brandInfo
    )
    this.image = BrandBannerModel.getBannerImageProps(fallbackInfo, banner)
    this.description = description
    this.title = bannerTitle || fallbackInfo.title
    this.hasLogo = BrandBannerModel.computeHasLogo(fallbackInfo, logo)
    this.showTrustpilot = showTrustpilot
    this.logo = this.getLogo(fallbackInfo, logo)
    this.hasActivePromotion = hasActivePromotion

    if (bannerMobile) {
      this.imageMobile = new ImageModel(bannerMobile).toJSON()
    }
  }

  private static computeHasLogo(
    fallbackInfo: IBrandFallbackInfoModel,
    logo?: ICMSImageModel
  ): boolean {
    const hasLogo = !isEmpty(logo)
    const hasFallbackLogo = !isEmpty(fallbackInfo.logo)

    return hasLogo || hasFallbackLogo
  }

  private getLogo(
    { logo: fallbackLogo }: IBrandFallbackInfoModel,
    bannerLogo?: ICMSImageModel
  ): IImageModel | undefined {
    if (!this.hasLogo) return undefined

    return new ImageModel(bannerLogo || fallbackLogo).toJSON()
  }

  private static getBannerImageProps(
    { banner: fallbackInfoBanner }: IBrandFallbackInfoModel,
    banner
  ): IImageModel {
    let validBanner = banner
    if (!banner) validBanner = fallbackInfoBanner

    return new ImageModel(validBanner).toJSON()
  }

  toJSON(): IBrandBanner {
    return {
      title: this.title,
      logo: this.logo,
      description: this.description,
      image: this.image,
      imageMobile: this.imageMobile,
      hasActivePromotion: this.hasActivePromotion,
      showTrustpilot: this.showTrustpilot,
    }
  }
}
