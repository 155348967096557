/* eslint-disable camelcase */
import { addTopLevelCategory } from '../utils'
import { TStockStatus } from './ECommerceProducts'

export interface IGmpImpression {
  id: string
  stock_status: TStockStatus
  name: string
  category: string
  brand: string
  list: string
  position: string
  price: string
  product_price_stored: string
  quantity: string
  product_brand: string | null
  product_theme?: string | null
}

export class GmpImpression {
  private readonly id: string
  private readonly stockStatus: TStockStatus
  private readonly name: string
  private readonly category: string
  private readonly brand: string
  private readonly parentDenomination: {
    product_brand: string | null
    product_theme: string | null
  }

  private readonly list: string
  private position: string
  private readonly price: string
  private readonly priceStored: string
  private readonly quantity: string = '1'

  constructor(
    {
      productId,
      available,
      discontinued,
      invalidProduct,
      title,
      brandCategory,
      brandTitle: parentDenominationTitle,
      parentDenominationType,
      price: { localAmount = 0 } = {},
      position,
      product_kind,
    },
    index: number,
    listValue: string | null = null
  ) {
    const stringifiedLocalAmount = localAmount ? localAmount.toString() : ''
    const hasThemeParentDenomination = parentDenominationType === 'theme'
    const productBrand = hasThemeParentDenomination
      ? null
      : parentDenominationTitle
    const productTheme = hasThemeParentDenomination
      ? parentDenominationTitle
      : null
    this.id = productId.toString()

    if (available) {
      this.stockStatus = 'on_stock'
    } else {
      this.stockStatus = 'out_of_stock'
    }

    if (discontinued) {
      this.stockStatus = 'discontinued'
    }

    if (invalidProduct) {
      this.stockStatus = 'invalid_product'
    }

    this.name = title
    this.category = addTopLevelCategory(product_kind, brandCategory)
    this.brand = parentDenominationTitle
    this.list = listValue || `${brandCategory}+${parentDenominationTitle}`
    this.position = (position || index + 1).toString()
    this.price = stringifiedLocalAmount
    this.priceStored = stringifiedLocalAmount
    this.parentDenomination = {
      product_brand: productBrand,
      product_theme: productTheme,
    }
  }

  toJSON(): IGmpImpression {
    const {
      parentDenomination,
      id,
      name,
      category,
      brand,
      list,
      position,
      price,
      stockStatus: stock_status,
      priceStored: product_price_stored,
      quantity,
    } = this
    const { product_brand, product_theme } = parentDenomination

    return {
      id,
      stock_status,
      name,
      category,
      brand,
      product_brand,
      product_theme,
      list,
      position,
      product_price_stored,
      price,
      quantity,
    }
  }
}
/* eslint-enable camelcase */
