export default {
  'amazon-es': 'amazon',
  'amazon-es/100-eur': 'amazon',
  'amazon-es/15-eur': 'amazon',
  'amazon-es/25-eur': 'amazon',
  'amazon-es/50-eur': 'amazon',
  battlenet: 'blizzard',
  'battlenet/20-eur': 'blizzard',
  'battlenet/50-eur': 'blizzard',
  bitnovo: 'bitnovo',
  bitsa: 'bitsa',
  blizzard: 'blizzard',
  'cartoes-de-credito-pre-pagos': 'prepaid-credit-cards',
  'cartoes-de-presente': 'shopping-gift-cards',
  cashlib: 'cashlib',
  cashu: 'prepaid-credit-cards',
  'codigos-de-entretenimento': 'entertainment-gift-cards',
  'codigos-de-jogo': 'gaming-cards',
  'como-funciona': 'how-it-works',
  criptomoeda: 'cryptocurrency',
  'crypto-voucher': 'crypto-voucher',
  'declarao-de-cookie': 'cookie-statement',
  dent: 'mobile-top-up',
  'direito-de-retirada': 'how-it-works',
  'ea-origin': 'ea-origin',
  'ea-origin/15-eur': 'ea-origin/15-eur',
  'ea-origin/30-eur': 'ea-origin/30-eur',
  ecovoucher: 'ecovoucher',
  'eve-online': 'gaming-cards',
  'fifa-points': 'gaming-cards',
  flexepin: 'flexepin',
  fortnite: 'gaming-cards',
  'guild-wars': 'gaming-cards',
  'karma-koin': 'karma-koin',
  'league-of-legends': 'league-of-legends',
  'league-of-legends/10-eur': 'league-of-legends/10-eur',
  'league-of-legends/20-eur': 'league-of-legends/20-eur',
  lycamobile: 'lycamobile',
  meo: 'meo',
  'metodos-de-pagamento': 'payment-methods',
  moche: 'moche',
  moneyclic: 'prepaid-credit-cards',
  ncoin: 'ncoin',
  'ncoin/10-eur': 'ncoin/10-eur',
  'ncoin/100-eur': 'ncoin/100-eur',
  'ncoin/20-eur': 'ncoin/20-eur',
  'ncoin/5-eur': 'ncoin/5-eur',
  'ncoin/50-eur': 'ncoin/50-eur',
  neosurf: 'neosurf',
  netflix: 'netflix',
  'netflix/15-eur': 'netflix/15-eur',
  'netflix/25-eur': 'netflix/25-eur',
  'netflix/50-eur': 'netflix/50-eur',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-eshop/15-eur': 'nintendo-eshop/15-eur',
  'nintendo-eshop/25-eur': 'nintendo-eshop/25-eur',
  'nintendo-switch-online': 'nintendo-switch-online',
  'nintendo-switch-online/12-meses': 'nintendo-switch-online/12-months',
  'nintendo-switch-online/3-meses': 'nintendo-switch-online/3-months',
  nos: 'nos',
  openbucks: 'openbucks',
  paysafecard: 'paysafecard',
  pcs: 'pcs',
  'playstation-plus': 'playstation-plus',
  'playstation-plus/12-meses': 'playstation-plus/12-months-pt',
  'playstation-plus/3-meses': 'playstation-plus/3-months-pt',
  'playstation-store': 'playstation-store',
  'playstation-store/50-eur': 'playstation-store',
  'politica-de-privacidade': 'privacy-statement',
  'prepaid-mastercard': 'prepaid-credit-cards',
  'recarga-celular': 'mobile-top-up',
  'red-dead-online': 'gaming-cards',
  'red-dead-online/150-barras-de-oro-xbox-one': 'gaming-cards',
  roblox: 'roblox',
  'roblox/50-eur-xbox-one': 'roblox',
  'roblox/6-eur-xbox-one': 'roblox',
  runescape: 'runescape',
  'runescape/30-days': 'runescape/30-days',
  'sobre-nos': 'https://company.recharge.com',
  soshop: 'soshop',
  'spotify-premium': 'spotify-premium',
  'spotify-premium/21-eur': 'spotify-premium',
  'spotify-premium/42-eur': 'spotify-premium',
  steam: 'steam',
  'steam/100-eur': 'steam/100-eur',
  'steam/20-eur': 'steam/20-eur',
  'steam/25-eur': 'steam/25-eur',
  'steam/5-eur': 'steam/5-eur',
  'steam/50-eur': 'steam/50-eur',
  'ticket-premium': 'prepaid-credit-cards',
  'toneo-first': 'toneo-first',
  transcash: 'transcash',
  'transcash/100-eur': 'transcash/100-eur',
  'transcash/20-eur': 'transcash/20-eur',
  uzo: 'uzo',
  vodafone: 'vodafone',
  'world-of-warcraft': 'world-of-warcraft',
  'world-of-warcraft/20-eur': 'world-of-warcraft',
  'world-of-warcraft/50-eur': 'world-of-warcraft',
  'world-of-warcraft/60-dias': 'world-of-warcraft',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-gift-card/10-eur': 'xbox-gift-card/10-eur',
  'xbox-gift-card/15-eur': 'xbox-gift-card/15-eur',
  'xbox-gift-card/20-eur': 'xbox-gift-card/20-eur',
  'xbox-gift-card/25-eur': 'xbox-gift-card/25-eur',
  'xbox-gift-card/30-eur': 'xbox-gift-card/30-eur',
  'xbox-gift-card/5-eur': 'xbox-gift-card/5-eur',
  'xbox-gift-card/50-eur': 'xbox-gift-card/50-eur',
  'xbox-live-gold': 'xbox-live-gold',
  'xbox-live-gold/12-meses': 'xbox-live-gold/12-months',
  'xbox-live-gold/3-meses': 'xbox-live-gold/3-months',
}
