import localeConfig from './i18n/config/trustpilotLocales.json'

const trustpilotSharedConfig = {
  businessunitId: '5060842700006400051bf54f',
  reviewUrl: 'https://nl.trustpilot.com/review/beltegoed.nl',
  locale: 'en-GB',
  reviewnumber: '5',
  theme: 'light',
  schemaType: 'Organization',
}

const templates = {
  horizontal: '5406e65db0d04a09e042d5fc',
  microComboDesktop: '5419b6ffb0d04a076446a9af',
  microCombo: '5419b732fbfb950b10de65e5',
  mini: '53aa8807dec7e10d38f59f32',
  micro: '5419b637fa0340045cd0c936',
}

const trustpilot = {
  ...localeConfig,
  widgets: {
    'footer-ltr': {
      templateId: templates.microComboDesktop,
      styleHeight: '45px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
      theme: 'dark',
    },
    'footer-rtl': {
      templateId: templates.microComboDesktop,
      styleHeight: '45px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
      theme: 'dark',
    },
    'header-desktop-ltr': {
      templateId: templates.microComboDesktop,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
    'header-mobile-ltr': {
      templateId: templates.microCombo,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
    'header-desktop-rtl': {
      templateId: templates.microComboDesktop,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
    'header-mobile-rtl': {
      templateId: templates.microCombo,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
    categoryHeader: {
      templateId: templates.microCombo,
      ...trustpilotSharedConfig,
    },
    reviewList: {
      templateId: '5717796816f630043868e2e8',
      ...trustpilotSharedConfig,
    },
    'business-desktop-rtl': {
      templateId: templates.micro,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
    'business-desktop-ltr': {
      templateId: templates.micro,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
    'business-mobile-rtl': {
      templateId: templates.micro,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
    'business-mobile-ltr': {
      templateId: templates.micro,
      styleHeight: '24px',
      styleWidth: '100%',
      ...trustpilotSharedConfig,
    },
  },
}

const config = {
  recharge: trustpilot,
  default: trustpilot,
}

const label = process.env.LABEL || 'recharge'

export default config[label] || config.default
