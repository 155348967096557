<template>
  <div v-if="usps.length" class="flex flex-col md:flex-row relative w-full">
    <usps
      v-for="(usp, index) in usps"
      :key="index"
      :class="[
        'text-center md:text-length md:pe-4 lg:pe-6',
        index === defaultUsps ? '' : 'hidden md:flex',
      ]"
      :text="usp.text"
      tabindex="0"
    />
  </div>
</template>

<script>
import { Usps } from '~/components/atoms'

export default {
  components: {
    Usps,
  },

  props: {
    usps: {
      type: Array,
      default: () => [],
    },
    defaultUsps: {
      type: Number,
      default: 0,
    },
  },
}
</script>
