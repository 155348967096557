import { GetterTree } from 'vuex'
import { IUiMobileMenuState } from './state'
import { MobileMenuVisibility } from './types'

const getters: GetterTree<IUiMobileMenuState, IUiMobileMenuState> = {
  mobileMenuVisibility: ({ mobileMenuVisibility }): MobileMenuVisibility =>
    mobileMenuVisibility,
}

export default getters
