
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import SearchList from '../search-list/index.vue'
import PaymentMethodIssuers from './payment-method-issuers.vue'
import TransactionCost from './transaction-cost.vue'
import ContinueButton from './continue-method-selected.vue'
import isEmpty from '~/utils/isEmpty'
import SmoothWrapper from '~/components/utils/smooth-wrapper.vue'
import { UiImage, Alert, UiButton, Icon } from '~/components/atoms'
import { ICheckoutPaymentMethod } from '~/models/components/CheckoutPaymentMethod'

// List-length to be shown by search-list component
const INFINITE = undefined
const MAX = 5

export default {
  components: {
    UiImage,
    PaymentMethodIssuers,
    SmoothWrapper,
    TransactionCost,
    ContinueButton,
    Alert,
    UiButton,
    Icon,
    SearchList,
  },
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
    } as PropOptions<ICheckoutPaymentMethod[]>,
    checkoutPaymentMethodsState: {
      type: Object,
      default: () => ({}),
    },
    savedPaymentMethodId: {
      type: String,
      default: '',
    },
    savedIssuerId: {
      type: String,
      default: '',
    },
    rightOfWithdrawal: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
    disable: Boolean,
  },
  data() {
    return {
      showAllPayments: false,
      initiallySelectedPaymentMethodId: this.$props.savedPaymentMethodId,
      selectedIssuer: undefined,
    }
  },
  computed: {
    ...mapGetters('context', ['isGlobalMarketplace', 'isLocalMarketplace']),
    ...mapGetters('contentful', ['sharedPaymentMethods']),
    showMoreLessButtons() {
      return (
        this.isGlobalMarketplace && this.preferredPaymentMethods.length > MAX
      )
    },
    preferredPaymentMethods() {
      return this.isGlobalMarketplace
        ? this.gmpPaymentMethods
        : this.paymentMethods
    },
    gmpPaymentMethods() {
      const preferredPaymentMethodsIds = this.sharedPaymentMethods.map(
        method => method.topupId
      )
      const { initiallySelectedPaymentMethodId } = this

      return this.paymentMethods
        .map(paymentMethod => ({
          ...paymentMethod,
          position: preferredPaymentMethodsIds.includes(
            paymentMethod.id.toLowerCase()
          )
            ? preferredPaymentMethodsIds.indexOf(paymentMethod.id.toLowerCase())
            : initiallySelectedPaymentMethodId === paymentMethod.id
            ? -1
            : paymentMethod?.issuers?.length
            ? 1
            : 9999,
        }))
        .sort((a, b) => {
          if (a.position < b.position) return -1
          if (a.position > b.position) return 1
          return 0
        })
    },
    maxNumberOfPaymentMethods() {
      if (this.isLocalMarketplace) return INFINITE
      return this.showAllPayments ? INFINITE : MAX
    },
    hasExtraForm() {
      const { isPreselected, selectedPaymentMethod } = this
      const { paymentInformationFields, issuers } = selectedPaymentMethod

      return (
        (paymentInformationFields.length && !issuers.length) || isPreselected
      )
    },
    selectedPaymentMethod() {
      const { savedPaymentMethodId } = this
      return (
        this.paymentMethods.find(({ id }) => id === savedPaymentMethodId) || {}
      )
    },
    initiallySelectedPaymentMethod() {
      const { initiallySelectedPaymentMethodId } = this
      return (
        this.paymentMethods.find(
          ({ id }) => id === initiallySelectedPaymentMethodId
        ) || {}
      )
    },
    showSelectedPaymentMethod() {
      const {
        loading,
        disable,
        savedPaymentMethodId,
        initiallySelectedPaymentMethod,
        checkoutPaymentMethodsState: { isFilled },
      } = this

      return (
        (savedPaymentMethodId && loading) ||
        (savedPaymentMethodId && disable) ||
        (isFilled && !initiallySelectedPaymentMethod?.issuers?.length)
      )
    },
    mustSelectIssuer() {
      if (!this.selectedPaymentMethod) return false
      return (
        this.selectedPaymentMethod?.issuers.length && !this.selectedIssuer?.id
      )
    },
  },
  created() {
    if (!isEmpty(this.paymentMethods)) {
      this.onPaymentMethodSelect(this.selectedPaymentMethod, false)
    }

    this.$watch(
      'paymentMethods',
      newVal => {
        if (!newVal) return
        const hasPresetId = Boolean(this.savedPaymentMethodId)
        this.onPaymentMethodSelect(this.selectedPaymentMethod, hasPresetId)
      },
      { deep: true }
    )
  },
  methods: {
    onIssuerSelect(issuer) {
      this.selectedIssuer = issuer
      this.$emit('issuer:select', issuer, this.selectedPaymentMethod.id)
    },
    trackSearchInput() {
      this.$gtmEnhanced.searchInput('payment_method')
    },
    blurSearchInput(searchTerm) {
      this.$gtmEnhanced.viewSearchResults(searchTerm, 'payment_method')
    },
    onPaymentMethodSelect(selectedPaymentMethod, isPreselected = false) {
      this.$emit('payment-method:select', selectedPaymentMethod, isPreselected)
    },
    onContinueClick(selectedPaymentMethod) {
      if (this.mustSelectIssuer) {
        return
      }
      this.$emit('payment-method:continue', selectedPaymentMethod)
    },
    submitCreatePayment() {
      if (this.mustSelectIssuer) {
        return
      }
      this.$emit('payment-method:submit')
    },
    toggleShowPaymentMethods() {
      this.showAllPayments = !this.showAllPayments
      this.$gtmEnhanced.trackShowPaymentMethods(this.showAllPayments)
    },
  },
}
