
import { PropOptions } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import omit from '~/utils/omit'
import Layout from '~/components/layout/default.vue'
import { ErrorBox } from '~/components/organisms'
import { RequestError } from '~/apis'

export default {
  components: {
    Layout,
    ErrorBox,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    } as PropOptions<RequestError | Record<string, unknown>>,
  },
  head() {
    return {
      title: this.getPageTitle(),
      meta: [
        {
          name: 'viewport',
          content:
            'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('contentful', ['header', 'footer']),

    pageFooter() {
      const {
        error: { statusCode },
        footer,
      } = this
      const hasUnavailableServices = [502, 503].includes(statusCode)

      if (!hasUnavailableServices) return footer

      const propsToRemove = ['footerLinksGroup', 'legalLinks', 'paymentMethods']

      return omit(footer, propsToRemove)
    },
  },
  mounted() {
    const { query } = this.$route
    this.showErrorDetails({ query })
  },
  methods: {
    ...mapActions('context', ['showErrorDetails']),
    getPageTitle() {
      const {
        error: { statusCode: error = 'non_existent_error' },
      } = this

      const hasErrorBoxTranslation = this.$te(
        `organisms.error_box.${error}.title`
      )
      if (hasErrorBoxTranslation)
        return this.$t(`organisms.error_box.${error}.title`)

      const hasDefaultErrorTranslation = this.$te(`errors.${error}`)
      if (hasDefaultErrorTranslation) return this.$t(`errors.${error}`)
      return error
    },
  },
}
