
import { mapActions } from 'vuex'
import { PropOptions } from 'vue'
import SmoothWrapper from '~/components/utils/smooth-wrapper.vue'
import ModalBottom from '~/components/molecules/modal-bottom/index.vue'
import { Icon } from '~/components/atoms'

const OVERLAY_NAME = 'tooltipOverlay'
const OVERLAY_VISIBILITY = 'visible'

export default {
  name: 'IconTooltip',
  components: {
    SmoothWrapper,
    ModalBottom,
    Icon,
  },
  props: {
    icon: {
      type: String,
      default: 'down',
    } as PropOptions<string>,
    iconWidth: {
      type: String,
      default: '15',
    } as PropOptions<string>,
    overlayName: {
      type: String,
      default: OVERLAY_NAME,
    } as PropOptions<string>,
  },
  data() {
    return {
      showOverlay: false,
    }
  },
  methods: {
    ...mapActions('ui', ['toggleOverlay']),
    openOverlay() {
      this.showOverlay = !this.showOverlay
      this.toggleOverlay({
        name: this.overlayName,
        visibility: OVERLAY_VISIBILITY,
      })
    },
  },
}
