import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { EntryModel } from '../base/Entry'
export interface ICurrencyModel {
  abv: string
  name: string
  symbol: string
}

export interface ICMSCurrencyModel extends Entry {
  fields: ICurrencyModel
}

export class CurrencyModel extends EntryModel {
  protected readonly abv: string
  protected readonly name: string
  protected readonly symbol: string

  constructor(currencyModel: ICMSCurrencyModel) {
    super(currencyModel)
    this.abv = this.requiredField('abv')
    this.name = this.requiredField('name')
    this.symbol = this.requiredField('symbol')
  }

  toJSON(): ICurrencyModel {
    return {
      abv: this.abv,
      name: this.name,
      symbol: this.symbol,
    }
  }
}
