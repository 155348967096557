import fetch from 'cross-fetch'
import { Store } from 'vuex/types'
import {
  RECOMMERCE_APIS,
  RecommerceApiKeys,
  RecommerceRequestConfig,
  UNO_GMP_CHANNEL,
} from './enums'
import { RecommerceResponses } from './types'
import { getCache, setCache, createCacheKey } from './cache'
import { executeOnServer } from '~/services/nuxt'
import {
  endMeasurement,
  startMeasurement,
} from '~~/server/services/performance'

import { RequestError } from '~/apis/error/RequestError'
import UnionToIntersection from '~/utils/types/unionToIntersection'

type CustomHeaders = {
  channel?: string
  'Accept-Language'?: string
  currency?: string
  'Redemption-Country'?: string
  'Customer-Country'?: string
}

type RecommerceRequestFn<K, P> = (
  params: P,
  customHeaders?: CustomHeaders
) => K extends keyof RecommerceResponses
  ? Promise<RecommerceResponses[K]>
  : never

type RecommerceApiEndpointFunctionsAbstract<M> = UnionToIntersection<
  {
    [K in keyof M]: M[K] extends (params: infer P) => RecommerceRequestConfig
      ? (endpoint: K) => RecommerceRequestFn<K, P>
      : never
  }[keyof M]
>

export type RecommerceApiEndpointFunctions = RecommerceApiEndpointFunctionsAbstract<
  typeof RECOMMERCE_APIS
>

type RecommerceApiSlugFunctions<M> = UnionToIntersection<
  {
    [K in keyof M]: M[K] extends (params) => RecommerceRequestConfig
      ? (store: Store<any>) => (endpoint) => any
      : never
  }[keyof M]
>

type RecommerceApiInjectFn = RecommerceApiSlugFunctions<typeof RECOMMERCE_APIS>

export type RecommerceApiEndpoints<M> = UnionToIntersection<
  {
    [K in keyof M]: M[K] extends (params: infer P) => RecommerceRequestConfig
      ? (endpoint: K, store: Store<any>) => RecommerceRequestFn<K, P>
      : never
  }[keyof M]
>

const fetchFromRecommerce = async (
  url: string,
  fetchOptions: RequestInit,
  times = 0
): Promise<Response | null> => {
  if (times >= 2) return null
  try {
    const response = await fetch(url, fetchOptions)
    if ((response.status === 408 || response.status > 501) && times < 2)
      return fetchFromRecommerce(url, fetchOptions, times + 1)
    return response
  } catch (e) {
    return fetchFromRecommerce(url, fetchOptions, times + 1)
  }
}

export const endpointRequestFn: RecommerceApiEndpoints<typeof RECOMMERCE_APIS> = (
  endpoint: RecommerceApiKeys,
  store
) => {
  return async (params, customHeaders: CustomHeaders = {}) => {
    const channel = customHeaders.channel || UNO_GMP_CHANNEL
    let { code: currency } = store.getters['context/currency']
    if (customHeaders.currency) {
      currency = customHeaders.currency
    }
    const redemptionCountry =
      customHeaders['Redemption-Country'] ||
      store.getters['context/countryCode']
    const recommerceLocale =
      customHeaders['Accept-Language'] ||
      store.getters['context/recommerceLocale']

    const { url, body, cache, method = 'GET' } = RECOMMERCE_APIS[endpoint](
      params
    )
    const cacheKey = createCacheKey({
      endpoint,
      params,
      recommerceLocale,
      redemptionCountry,
      currency,
    })

    const cachedResponse = getCache(cacheKey)
    if (cachedResponse) return cachedResponse

    const requestHeaders = {
      channel,
      'Accept-Language': recommerceLocale,
      currency,
      'Redemption-Country': redemptionCountry,
      'Content-Type': 'application/json',
    }

    try {
      executeOnServer(() => startMeasurement(`server:recommerce:${endpoint}`))
      const response = await fetchFromRecommerce(url, {
        method,
        headers: requestHeaders,
        body,
      })

      if (!response)
        throw new RequestError({
          statusCode: 418,
          message: `Request failed with no status code for ${url}`,
        })

      if (response.status === 200 || response.status === 400) {
        const data = await response.json()
        if (cache) setCache(cacheKey, data)
        return data
      }
      if (response.status === 404) {
        return null
      }
      const error = await response.json()
      throw new RequestError({
        statusCode: response.status,
        message: `Request failed with ${response.status} for ${url}. Message: ${error.message}`,
      })
    } catch ({ message, statusCode }) {
      throw new RequestError({ message, statusCode })
    } finally {
      executeOnServer(() => endMeasurement(`server:recommerce:${endpoint}`))
    }
  }
}

const recommerce: RecommerceApiInjectFn = store => endpoint =>
  endpointRequestFn(endpoint, store)

export default recommerce
