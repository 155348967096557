
import Tooltip from './index.vue'
import { Icon } from '~/components/atoms'

enum TYPE_CHAR {
  info = 'i',
  question = '?',
}

export default {
  components: {
    Tooltip,
    Icon,
  },
  props: {
    type: {
      type: String,
      default: 'info',
      validator: value => ['info', 'question'].includes(value),
    },
    info: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      typeChar: TYPE_CHAR[this.type] || TYPE_CHAR.question,
    }
  },
}
