import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  throttleWait: 0,
  observer: true,
  filter: {
    progressive(listener): void {
      const { src } = listener
      if (!src) return

      const cdn = process.env.CMS_ASSETS_DOMAIN || ''
      if (cdn && src.includes(cdn)) {
        listener.el.setAttribute('lazy-progressive', 'true')
      }
    },
    webp(listener, options): void {
      if (!options.supportWebp) return

      const { src } = listener
      if (!src) return

      const cdn = process.env.CMS_ASSETS_DOMAIN || ''
      if (!cdn || !src.includes(cdn)) return

      if (src.includes('fm=jpg')) {
        // eslint-disable-next-line no-param-reassign
        listener.src = src.replace('fm=jpg&fl=progressive', 'fm=webp')
        return
      }

      if (src.includes('fm=png') && !src.includes('fl=png')) {
        // eslint-disable-next-line no-param-reassign
        listener.src = src.replace('fm=png&fl=png8', 'fm=webp')
      }
    },
  },
})
