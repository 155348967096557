var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideTooltip),expression:"hideTooltip"}],staticClass:"relative inline-block"},[_c('p',{staticClass:"cursor-pointer",on:{"click":_vm.switchTooltip,"mouseover":_vm.showTooltip}},[_vm._t("title")],2),_vm._v(" "),_c('div',{staticClass:"tooltip-content",class:[
      `direction--${_vm.direction}`,
      {
        'opacity-0 invisible': !_vm.show,
        'opacity-100 visible': _vm.show,
      },
    ]},[_vm._t("content")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }