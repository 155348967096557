
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import {
  isDisabled,
  orderEmail,
  privacyPolicy,
  emailIsValidated,
  checkoutEmailState,
  checkoutPhoneState,
  isCustomFieldError,
} from './props'
import isEmpty from '~/utils/isEmpty'
import { UiTitle, UiInput, UiButton, Icon, Divider } from '~/components/atoms'
import EditableCard from '~/components/molecules/editable-card/index.vue'
import HlrInput from '~/components/molecules/hlr-input/index.vue'
import RichTextRenderer from '~/components/renderers/rich-text-renderer/index.vue'

export default {
  components: {
    UiInput,
    UiTitle,
    UiButton,
    Icon,
    EditableCard,
    Divider,
    HlrInput,
    RichTextRenderer,
  },
  props: {
    isDisabled,
    orderEmail,
    privacyPolicy,
    emailIsValidated,
    checkoutEmailState,
    isCustomFieldError,
    stepTitle: {
      default: 'molecules.checkout_email_box.title',
      type: String,
    },
    dataOrderedHeader: {
      default: true,
      type: Boolean,
    },
    buttonContinueLabel: {
      default: 'molecules.checkout_email_box.button_text',
      type: String,
    },
    withPhoneNumber: {
      default: false,
      type: Boolean,
    },
    checkoutPhoneState,
    isNewDesign: {
      default: false,
      type: Boolean,
    },
    phoneNumber: {
      default: null,
      type: String,
    },
    errorMessage: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      email: '',
      fieldError: false,
      isFocused: false,
    }
  },
  computed: {
    message(): string {
      if (this.isFocused) return ''
      if (this.errorMessage) {
        return this.errorMessage
      }
      if (!this.$v.email.email || this.isCustomFieldError)
        return this.$t('form.validations.email')
      if (!this.$v.email.required) return this.$t('form.validations.required')
      return ''
    },
    dataTestMessage(): string {
      if (this.isFocused || !this.$v.email.$dirty) return ''
      if (!this.$v.email.email || this.isCustomFieldError)
        return 'invalid-checkout-email-address'
      if (!this.$v.email.required) return 'checkout-email-address-required'
      return ''
    },
    ...mapGetters('rtrUser', ['phoneNumber']),
  },
  mounted(): void {
    if (isEmpty(this.orderEmail)) return

    this.email = this.orderEmail

    this.$v.$touch()
  },
  methods: {
    onContinueClick(): void {
      this.fieldError = false
      if (this.$v.$invalid) this.fieldError = true
      if (this.$v.$invalid) return
      this.formSubmitted = true
      this.changeRequested = false
      this.$emit('checkout-email-box:submission', {
        isValid: !this.$v.$invalid,
        email: this.email,
      })
      if (this.withPhoneNumber) {
        this.$emit('checkout-phone-box:submission', {
          phone: this.phoneNumber,
        })
        if (this.$options.hlrInterface) {
          this.$options.hlrInterface.storeAndEmit()
        }
      }
    },
    onInputBlur(): void {
      this.fieldError = false
      this.isFocused = false
      this.$emit('checkout-email-box:onBlur', {
        resetCustomInvalid: true,
      })
    },
    onInputFocus(): void {
      this.isFocused = true
    },
    getHlrInterface(hlrInterface): void {
      this.$options.hlrInterface = hlrInterface
    },
    updatePhoneNumberInStore(phoneNumberInfo): void {
      const { phoneNumber = '', dialCode = '' } = phoneNumberInfo

      const formattedPhoneNumber = `+${dialCode} ${phoneNumber}`.replace(
        /(\+\d+) \1/,
        '$1 '
      )
      this.$store.dispatch('rtrUser/setPhoneNumber', formattedPhoneNumber)
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
}
