export default {
  'about-us': 'https://company.recharge.com',
  'access-wireless': 'access-wireless',
  adidas: 'adidas',
  'airvoice-wireless': 'airvoice-wireless',
  amazon: 'amazon',
  'at-t-prepaid': 'at-t-prepaid',
  'boost-mobile': 'boost-mobile',
  'boost-mobile/10-usd': 'boost-mobile',
  'boost-mobile/25-usd': 'boost-mobile',
  'boost-mobile/30-usd': 'boost-mobile',
  'boost-mobile/40-usd': 'boost-mobile',
  'boost-mobile/50-usd': 'boost-mobile',
  'cookie-statement': 'cookie-statement',
  cvs: 'cvs',
  dent: 'mobile-top-up',
  'easy-go': 'mobile-top-up',
  ebay: 'ebay',
  'eco-mobile': 'mobile-top-up',
  'entertainment-gift-cards': 'entertainment-gift-cards',
  'gaming-gift-cards': 'gaming-cards',
  good2go: 'mobile-top-up',
  'h2o-wireless': 'h2o-wireless',
  'home-depot': 'home-depot',
  'how-it-works': 'how-it-works',
  'karma-koin': 'karma-koin',
  'league-of-legends': 'league-of-legends',
  'liberty-mobile': 'liberty-mobile',
  'life-wireless': 'life-wireless',
  lycamobile: 'lycamobile',
  macys: 'macys',
  'macys/': 'macys',
  'mobile-recharge': 'mobile-top-up',
  'net10-wireless': 'net10-wireless',
  netflix: 'netflix',
  nike: 'nike',
  'nintendo-eshop': 'nintendo-eshop',
  'nintendo-switch-online': 'nintendo-switch-online',
  nordstrom: 'nordstrom',
  openbucks: 'openbucks',
  'page-plus': 'page-plus',
  'payment-methods': 'payment-methods',
  'playstation-plus': 'playstation-plus',
  'playstation-store': 'playstation-store',
  'prepaid-credit-cards': 'prepaid-credit-cards',
  'privacy-statement': 'privacy-statement',
  'reachout-wireless': 'mobile-top-up',
  'real-mobile': 'mobile-top-up',
  'red-pocket-mobile': 'mobile-top-up',
  'right-of-withdrawal': 'how-it-works',
  roblox: 'roblox',
  'safelink-wireless': 'safelink-wireless',
  sephora: 'sephora',
  'shopping-gift-cards': 'shopping-gift-cards',
  'simple-mobile': 'simple-mobile',
  'spotify-premium': 'spotify-premium',
  'spotify-premium?aid': 'spotify-premium',
  starbucks: 'starbucks',
  steam: 'steam',
  't-mobile': 't-mobile',
  target: 'target',
  'telcel-america': 'mobile-top-up',
  'total-wireless': 'mobile-top-up',
  tracfone: 'tracfone',
  twitch: 'twitch',
  uber: 'uber',
  'uber/': 'uber',
  'ultra-mobile': 'ultra-mobile',
  verizon: 'verizon',
  'virgin-mobile': 'virgin-mobile',
  walmart: 'walmart',
  'xbox-gift-card': 'xbox-gift-card',
  'xbox-live-gold': 'xbox-live-gold',
  yourtel: 'mobile-top-up',
  fortnite: 'fortnite',
  'at-t-prepago': 'at-t-prepaid',
  'tarjetas-de-pago': 'prepaid-credit-cards',
  'tarjetas-de-regalo-para-compras': 'shopping-gift-cards',
  'tarjetas-de-regalo-de-entretenimiento': 'entertainment-gift-cards',
  'tarjetas-de-regalo-para-juegos': 'gaming-cards',
  'sobre-nosotros': 'https://company.recharge.com',
  'claro-puerto-rico': 'claro-puerto-rico',
  'cricket-wireless': 'cricket-wireless',
  ecomobile: 'eco-mobile',
  'good2go-mobile': 'good2go',
  'gosmart-mobile': 'go-smart-mobile',
  metropcs: 'metropcs',
  'red-pocket': 'red-pocket',
  'yourtel-wireless': 'yourtel',
  claro: 'claro-puerto-rico',
  easygo: 'easy-go',
  'freeup-mobile': 'freeup-mobile',
  'declaracion-sobre-cookies': 'cookie-statement',
  'derecho-a-retirada': 'how-it-works',
  'recarga-movil': 'mobile-top-up',
  'formas-de-pago': 'payment-methods',
  'declaracion-de-privacidad': 'privacy-statement',
  'blog/fourth-of-july-gift-ideas':
    'https://www.recharge.com/blog/en/us/fourth-of-july-gift-ideas',
}
