
import { mapGetters } from 'vuex'
import { ButtonIcon } from '~/components/atoms'

export default {
  components: { ButtonIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    name: {
      // A unique name across all the overlays
      type: String,
      required: true,
    },
    addedModalStyling: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('ui', ['nestingOverlays']),
    isMobile() {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
    overlayData() {
      return this.nestingOverlays.find(v => v.name === this.name)
    },
    show() {
      return this.overlayData !== undefined
    },
    level() {
      return this.overlayData?.level || 1
    },
    zIndexClass() {
      return `z-${50 + (this.level - 1) * 10}`
    },
    paddingTopClass() {
      if (!this.isMobile) {
        return ''
      }
      return `pt-${this.level * 10}`
    },
    transitionName() {
      if (this.isMobile) {
        return 'down-fade'
      }
      return 'fade'
    },
  },
  watch: {
    show(n, o) {
      // cannot make unique listeners for every overlay, hence add & remove listeners only on the level 1
      if (this.level === 1) {
        if (n) {
          window.addEventListener('keydown', this.onKeyPress)
        } else if (o) {
          window.removeEventListener('keydown', this.onKeyPress)
        }
      }
    },
  },
  methods: {
    onOverlayClose(): void {
      this.$store.dispatch('ui/hideNestingOverlay')
      this.$emit('overlay:close')
    },
    onOverlayClick($event) {
      // Sometimes `this.$refs.section` is undefined.
      if (this.$refs.section && !this.$refs.section.contains($event.target)) {
        this.onOverlayClose()
      }
    },
    onKeyPress(e) {
      if (e.key === 'Escape') {
        this.onOverlayClose()
      }
    },
  },
}
