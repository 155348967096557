import { groupBy } from '~/utils/groupBy'
import { Maybe } from '~/apis/clients/graphql/types/contentful'
import {
  RecommerceProduct,
  RedeemType,
} from '~/apis/clients/rest/recommerce/types'
import { RecommerceContentError } from '~/apis/error/RecommerceContentError'
import { TFile } from '~/models/base/Image'

export enum GeoLock {
  NO_GEOLOCK = 'none',
  DISABLED = 'disabled',
  DISABLED_PURCHASE = 'disabledPurchase',
}

type ProductContentBlock = {
  title: Maybe<string>
  description: Maybe<string>
}

type ProductListItem = {
  brandCategory: string
  brandTitle: string
  productId: number
  title: string
  logo: Maybe<string>
  maximumQuantity?: Maybe<number>
}

type ProductTab = {
  id: number
  type: string
}

export type ProductContentImage = {
  title: string
  url: Maybe<string>
  alt: Maybe<string>
}

// This type is used for using <responsive-image> component as it is.
// file attribute is necessary for that
// see <ui-image> & <responsive-image> components
export type ProductContentImageResponsive = {
  title: string
  alt: Maybe<string>
  file: TFile
}

type ProductFreeForm = {
  title: Maybe<string>
  contentBlock: Maybe<string>
}

export type ProductData = Pick<RecommerceProduct, 'code' | 'locales'> & {
  name: string
  title: string
  metaTitle: Maybe<string>
  metaDescription: Maybe<string>
  metaCanonicalLink: Maybe<string>
  metaRobotsConfiguration: Maybe<string>
  slug: string
  sectionBrandBanner: {
    title: string
    brandTitle: string
    logo: ProductContentImage
    image: ProductContentImageResponsive
    imageMobile: ProductContentImageResponsive
    hasActivePromotion: boolean
  }
  sectionInfoBlocks: {
    title: string
    infoBlocks: ProductContentBlock[]
  }
  sectionAccordion: {
    accordionItems: ProductContentBlock[]
  }
  sectionFreeForm: ProductFreeForm[]
  geoLock: GeoLock
  productListComponent: {
    brandInfo: {
      logoSrc: Maybe<string>
      name: string
    }
    productList: ProductListItem[]
    productTabs: ProductTab[]
  }
  sectionBrandList: []
  logo: ProductContentImage
  banner: ProductContentImage
  type: RedeemType
  productCardImage: ProductContentImage
  isCertifiedReseller: boolean
  maximumQuantity?: Maybe<number>
  hreflang: string[]
  isCustomDenomination: boolean
  isRTR: boolean
  customDenominationMinValue: Maybe<number>
  customDenominationMaxValue: Maybe<number>
}

export const normalizeProduct = ({
  code,
  images,
  locales,
  name,
  nameInAboutSection,
  slug,
  metaTitle,
  metaDescription,
  metaRobotsConfiguration,
  metaCanonicalLink,
  maxQuantity,
  isCertifiedReseller,
  productContents,
  mainCategory,
  isRTR,
  id,
  variants,
  hreflang,
  productTabs,
}: RecommerceProduct): ProductData => {
  if (!images)
    throw new RecommerceContentError(`Product images missing for ${id}`)
  if (!productContents)
    throw new RecommerceContentError(`Product content missing for ${id}`)
  if (!name) throw new RecommerceContentError(`Product name missing for ${id}`)

  const {
    logo: logoGroup,
    product_card: productCardImageGroup,
    desktop_banner: desktopBannerGroup,
    mobile_banner: mobileBannerGroup,
  } = groupBy(images, 'type')

  const [logo] = logoGroup || []
  if (!logo) throw new RecommerceContentError(`Product logo missing for ${id}`)

  const [productCardImage] = productCardImageGroup || logoGroup || []

  const [desktopBanner] = desktopBannerGroup || []
  const [mobileBanner] = mobileBannerGroup || []

  const {
    about,
    faq,
    terms: termGroup,
    redeem_instructions: redeemGroup,
  } = productContents
  const [termAndCondition] = termGroup || []
  const [redeemInstructions] = redeemGroup || []
  if (!about)
    throw new RecommerceContentError(`Product about missing for ${id}`)
  if (!faq) throw new RecommerceContentError(`Product faq missing for ${id}`)
  if (!termAndCondition)
    throw new RecommerceContentError(`Product terms missing for ${id}`)
  if (!redeemInstructions)
    throw new RecommerceContentError(
      `Product redeem instructions missing for ${id}`
    )

  const variantList = Object.values(variants || {})

  const variantCustomDenomination = variantList.reduce(
    (variantCustomDenomination, variant) => {
      if (variant.isCustomDenomination) return variant.customDenomination
      return variantCustomDenomination
    },
    null
  )

  const maximumQuantity = maxQuantity || undefined
  return {
    code,
    title: name,
    name,
    locales,
    slug,
    metaTitle,
    metaDescription,
    metaCanonicalLink,
    metaRobotsConfiguration,
    sectionBrandBanner: {
      title: name,
      brandTitle: name,
      logo: {
        title: name,
        url: logo.path,
        alt: logo.alt,
      },
      image: {
        title: name,
        alt: desktopBanner?.alt,
        file: {
          url: desktopBanner?.path || undefined,
        },
      },
      imageMobile: {
        title: name,
        alt: mobileBanner?.alt,
        file: {
          url: mobileBanner?.path || undefined,
        },
      },
      hasActivePromotion: !!desktopBanner,
    },
    sectionInfoBlocks: {
      title: nameInAboutSection || name,
      infoBlocks: about.map(({ title, content }) => ({
        title,
        description: content,
      })),
    },
    sectionAccordion: {
      accordionItems: [
        {
          title: redeemInstructions.title,
          description: redeemInstructions.content,
        },
        ...faq.map(({ title, content }) => ({
          title,
          description: content,
        })),
      ],
    },
    sectionFreeForm: [
      {
        title: termAndCondition.title,
        contentBlock: termAndCondition.content,
      },
    ],
    sectionBrandList: [],
    productListComponent: {
      productList: variantList.map(variant => ({
        brandCategory: mainCategory.name,
        brandTitle: name,
        title: variant.name,
        productId: variant.id,
        logo: logo?.path,
        productCardImage: {
          // this property is used in <product-list> from <brand> component
          title: name,
          url: variant?.productCardImage?.path,
          alt: variant?.productCardImage?.alt,
        },
        countries: variant.countries,
        description: variant.description,
        maximumQuantity: variant.maxQuantity || maximumQuantity,
        redirectToCustomerCountry: variant.redirectToCustomerCountry,
        slug: variant.variantSlug,
        showOnlyInCustomerCountry: variant.showOnlyInCustomerCountry,
        priceCurrency: variant.priceCurrency,
        customdenomination: variant.customDenomination,
        isMostPopular: !!variant.isMostPopular,
      })),
      brandInfo: {
        logoSrc: logo?.path,
        name,
      },
      productTabs,
    },
    logo: {
      title: name,
      url: logo?.path,
      alt: logo?.alt,
    },
    geoLock: variantList.reduce((geolock, variant) => {
      if (geolock !== GeoLock.NO_GEOLOCK) return geolock
      if (variant.showOnlyInCustomerCountry) return GeoLock.DISABLED_PURCHASE
      if (variant.redirectToCustomerCountry) return GeoLock.DISABLED
      return GeoLock.NO_GEOLOCK
    }, GeoLock.NO_GEOLOCK),
    type: variantList.reduce(
      (type, variant) => (variant.type === 'rtr' ? 'rtr' : type),
      'pin'
    ),
    productCardImage: {
      title: name,
      url: productCardImage?.path,
      alt: productCardImage?.alt,
    },
    // TODO is this banner used?
    banner: {
      url: desktopBanner?.path,
      title: name,
      alt: desktopBanner?.alt,
    },
    isCertifiedReseller: isCertifiedReseller || false,
    isRTR,
    maximumQuantity,
    hreflang,
    isCustomDenomination: !!variantList.find(
      variant => variant.isCustomDenomination
    ),
    customDenominationMinValue: variantCustomDenomination?.minValue || null,
    customDenominationMaxValue: variantCustomDenomination?.maxValue || null,
  }
}
