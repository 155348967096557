
import { PropOptions } from 'vue'
import { Row, Column } from '~/components/grid'
import { CategoryCard } from '~/components/molecules'
import { ICategoryList } from '~/models/components/CategoryList'
import { CdpContainer } from '~/components/renderers'

export default {
  components: {
    CdpContainer,
    CategoryCard,
    Row,
    Column,
  },
  props: {
    categoryCards: {
      type: Array,
      default() {
        return []
      },
    } as PropOptions<ICategoryList[] | []>,
  },
}
