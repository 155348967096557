import { PropOptions } from 'vue'

type TDirection = 'ltr' | 'rtl'
type TJustifyContent =
  | 'justify-start'
  | 'justify-end'
  | 'justify-center'
  | 'justify-between'
  | 'justify-around'
  | 'justify-evenly'

const props = {
  hideSearch: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  clearable: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  searchHalfWidth: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  wrap: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  searchFields: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },
  searchPlaceholder: {
    type: String,
    default: 'Search',
  } as PropOptions<string>,
  widthClasses: {
    type: String,
    default: '',
  } as PropOptions<string>,
  deactivateSearch: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  isBig: {
    type: Boolean,
    default: true,
  } as PropOptions<boolean>,
  max: {
    type: Number,
    default: undefined,
  } as PropOptions<number>,
  selectedItem: {
    type: Object,
    default: () => ({}),
  },
  selectPropName: {
    type: String,
    default: undefined,
  } as PropOptions<string>,
  dropdownResults: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  highlightSelected: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  enableDividers: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  enableAutoFocus: {
    type: Boolean,
    default: true,
  } as PropOptions<boolean>,
  focusSearchAgain: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  inputPrefix: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  inputBackButton: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  inputPrefixIcon: {
    type: String,
    default: '',
  } as PropOptions<string>,
  inputPrefixClasses: {
    type: String,
    default: '',
  } as PropOptions<string>,
  orderingOptions: {
    type: Array,
    default: () => [],
  },
  dataTest: {
    type: String,
    default: null,
  } as PropOptions<string>,
  direction: {
    type: String,
    default: 'ltr',
  } as PropOptions<TDirection>,
  lockInputDirection: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
  justifyContent: {
    type: String,
    default: 'justify-between',
  } as PropOptions<TJustifyContent>,
  boxClasses: {
    type: String,
    default: '',
  } as PropOptions<string>,
  scrollableResults: {
    type: Boolean,
    default: false,
  } as PropOptions<boolean>,
}

export default props
