export enum INPUT_TYPE {
  text = 'text',
  countrySelect = 'country-selector',
  stateSelect = 'state-selector',
  email = 'email',
  number = 'number',
  date = 'date',
  multiline = 'textarea',
  tel = 'tel',
}

export enum COMPONENT {
  'ui-input' = 'ui-input',
  'country-selector' = 'country-selector',
  'state-selector' = 'state-selector',
  'ui-textarea' = 'ui-textarea',
  'phone-input' = 'phone-input',
}

export enum DIMENSIONS {
  full = 'full',
  half = '1/2',
  third = '1/3',
  quarter = '1/4',
  threeQuarters = '3/4',
}
