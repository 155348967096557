export enum QUERY_STRING_KEYS {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
}

export enum UTM_SOURCE_VALUES {
  RAPIDO = 'rapido',
}
export enum UTM_MEDIUM_VALUES {
  REDIRECT = 'redirect',
}
