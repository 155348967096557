import localeLabelConfig from '../../../config/i18n/config/label.json'
import countries from '../../../config/i18n/config/recommerceCountries.json'
import currencies from '../../../config/i18n/config/recommerceCurrencies.json'
import languages from '../../../config/i18n/config/recommerceLanguages.json'
import { IContextStore } from './interfaces'
import trustpilotConfig from '~~/config/trustpilot'

const label = process.env.LABEL || 'recharge'

const config = localeLabelConfig[label] || {}

export default function state(): IContextStore {
  const { domain, languages: locales, default: locale, prefixStrategy } = config

  return {
    labelName: label,
    domain,
    merchant: '',
    lang: '',
    locale,
    direction: '',
    currency: {},
    locales,
    trustpilot: trustpilotConfig,
    rechargeClassicDomain: 'https://www.recharge.com',
    prefixStrategy,
    isRedirectedFromRapido: false,
    comesFromRechargeClassic: false,
    lastProductSeenByCountry: {},
    cmsLocale: '',
    hasRapidoSegment: false,
    showErrorDetails: false,
    countryCode: '',
    useRecommerce: false,
    marketplace: 'local',
    localeProperties: { code: '' },
    isContactsFeaturePreview: false,
    isSignInWithAppleFeaturePreview: false,
    isChangeEmailFeaturePreview: false,
    accountAnalyticsDisabled: false,
    countries,
    currencies,
    languages,
    customerCountry: null,
    isRafAvailableCountry: false,
  }
}
