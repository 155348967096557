<template>
  <div class="mt-2 mb-4 text-gray-dark text-xs border-gray">
    <div>
      <rich-text-renderer :document="rightOfWithdrawal" />
    </div>
  </div>
</template>
<script>
import VueTypes from 'vue-types'
import RichTextRenderer from '~/components/renderers/rich-text-renderer'

export default {
  components: {
    RichTextRenderer,
  },
  props: {
    rightOfWithdrawal: VueTypes.object,
  },
}
</script>
<style scoped>
.text-xs a {
  @apply text-xs;
}
</style>
