export default {
  errors: [
    {
      message:
        '[GraphQL]: Response not successful: Received status code 422\nIncorrect data sent',
      statusCode: 422,
    },
  ],
  data: {},
}
