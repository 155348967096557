import { IOrderState } from './interfaces'
import {
  OrderModel,
  ORDER_STATUS,
  ITopupMyOrderModel,
} from '~/models/components/Order'

export default {
  setProduct(state: IOrderState, { productId, quantity = 1 }): void {
    state.product.quantity = quantity
    state.product.productId = productId
  },
  setOrderDetails(
    state: IOrderState,
    { order, product, isGlobalMarketplace }
  ): void {
    const formattedOrder = new OrderModel(order, product).toJSON()
    if (isGlobalMarketplace)
      formattedOrder.products = [
        {
          ...formattedOrder.products[0],
          brandTitle: product.brandTitle,
          brandCategory: product.brandCategory,
          nominationType: 'brand',
        },
      ]
    state.details = formattedOrder
    state.orderStatus = formattedOrder.status
  },
  setOrderStatus(state: IOrderState, status: ORDER_STATUS): void {
    state.orderStatus = status
  },
  setProductId(state: IOrderState, productId: string): void {
    state.product.productId = productId
  },
  setProductOrderPageStructure(state: IOrderState, sections): void {
    state.productOrderPageStructure = sections
  },
}
