import { Context, Plugin } from '@nuxt/types'
import { Store } from 'vuex'
import isEmpty from '~/utils/isEmpty'
import { REQUEST_ERROR } from '~/apis/enums'

type Error = {
  message: string
  statusCode?: REQUEST_ERROR
}

interface IErrorHandler {
  get(): Error[]
  set(error: Error): void
  isEmpty(): boolean
  reset(): void
  verbose(): string
}

declare module 'vue/types/vue' {
  interface Vue {
    $errors: IErrorHandler
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $errors: IErrorHandler
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $errors: IErrorHandler
  }
}

class ErrorHandler implements IErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private store: Store<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private i18n: any

  constructor({ app, store }: Context) {
    this.store = store
    this.i18n = app.i18n
  }

  get(): Error[] {
    return this.store.getters['errors/errors'] || []
  }

  private translate(message: string): string {
    const errorMessageAsKey = `errors.${message}`
    if (!this.i18n.te(errorMessageAsKey)) return '' // te = translate exists

    return this.i18n.t(errorMessageAsKey)
  }

  verbose = (): string =>
    this.get()
      .map(({ message, statusCode }) => {
        const translatedError = this.translate(message)
        const translatedStatusError = statusCode
          ? this.translate(statusCode.toString())
          : ''

        return translatedError || message || translatedStatusError
      })
      .join('\n')

  isEmpty = (): boolean => {
    const errors: Error[] = this.get()
    return isEmpty(errors)
  }

  reset = (): void => {
    if (!this.isEmpty()) {
      this.store.commit('errors/resetErrors')
    }
  }

  set = (error: Error): void => {
    this.store.commit('errors/setErrors', error)
  }
}

const errorHandlerPlugin: Plugin = (context: Context, inject): void => {
  const errorHandler: IErrorHandler = new ErrorHandler(context)
  inject('errors', errorHandler)
}

export default errorHandlerPlugin
