import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { RequestError } from '~/apis'

const GEOLOCATION_ERROR_KEY = 'product.country_restricted'
interface IErrorsState {
  errors: Array<Error | RequestError>
}

const getters: GetterTree<IErrorsState, IErrorsState> = {
  errors: state => state.errors,
  isGeolocationError: state => {
    if (!state.errors.length) return false

    const [error] = state.errors
    return error.message.includes(GEOLOCATION_ERROR_KEY)
  },
}

const mutations: MutationTree<IErrorsState> = {
  setErrors(state, errors) {
    state.errors = errors
  },
  pushErrors(state, errors) {
    state.errors = state.errors.concat(errors)
  },
  resetErrors(state) {
    state.errors = []
  },
}

const actions: ActionTree<IErrorsState, IErrorsState> = {
  setErrors({ commit }, errors) {
    commit('setErrors', errors)
  },
}

export default {
  state(): IErrorsState {
    return {
      errors: [],
    }
  },
  getters,
  mutations,
  actions,
}
