<template>
  <div>
    <a
      v-if="canNativeShare"
      class="text-link hover:underline cursor-pointer"
      @click="nativeShareInfo"
    >
      {{ buttonText }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      canNativeShare: false,
    }
  },
  mounted() {
    this.canNativeShare =
      typeof window.navigator !== 'undefined' &&
      typeof window.navigator.share !== 'undefined'
  },
  methods: {
    nativeShareInfo() {
      const shareData = {
        title: this.title,
        text: this.text,
      }

      if (this.url !== null) shareData.url = this.url

      if (this.canNativeShare) {
        window.navigator.share(shareData)
      }
    },
  },
}
</script>
