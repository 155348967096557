import { IContenfulState } from './interfaces'

export default (): IContenfulState => ({
  pdp: {},
  brandPage: {},
  home: {},
  app: {},
  categoryPage: {},
  layout: {},
  header: {},
  footer: {},
  structure: [],
  countryList: {},
  brandNavLinks: [],
  paymentMethods: {},
  products: [],
  product: {},
  country: null,
  metaLinks: [],
  metaTags: [],
  metaTitle: '',
  pageTitle: '',
  pageType: '',
  parentPageInfo: {},
  aboutUsUrl: '',
  pathTranslations: {},
  paymentMethodsPage: {},
  secondaryPage: {},
  blogPage: {},
  blogOverviewPage: {},
  sharedData: {},
  cachedSharedData: {},
})
