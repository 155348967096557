import { Plugin, Context } from '@nuxt/types'

const onLanguageSwitched: Plugin = (context: Context) => {
  const { app, store } = context
  app.i18n.onLanguageSwitched = (_, newLocale): void => {
    store.commit('context/setLocale', newLocale)
  }
}

export default onLanguageSwitched
