import { CookiesClient } from './types'
import compact from '~/utils/compact'
import isEmpty from '~/utils/isEmpty'

export const cookieAge = {
  path: '/',
  maxAge: 60 * 60 * 24 * 3,
}

const LOCALE_REGEX_VALIDATION = /([\w]{2})-([\w]{2})/

export function hasLocaleFormat(locale: string): boolean {
  return LOCALE_REGEX_VALIDATION.test(locale)
}

export function getLocaleFromPath(path: string): string {
  if (isEmpty(path)) return ''
  const [locale] = compact(path.split('/'))
  if (!locale) return ''

  return locale
}

export function setLocaleCookieCreator(
  $cookies: CookiesClient,
  availableLocales: string[]
): (locale: string) => void {
  function setLocale(locale: string): void {
    if (!availableLocales.includes(locale)) return

    const localeFromCookie: string | undefined = $cookies.get('locale')
    if (localeFromCookie && localeFromCookie === locale) return

    $cookies.set('locale', locale, cookieAge)
  }

  return setLocale
}

export function localeDidNotChange(
  localeFromPath: string,
  localeFromApp: string,
  localeFromCookie: string
): boolean {
  return localeFromPath === localeFromApp && localeFromPath === localeFromCookie
}
