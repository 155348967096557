<template functional>
  <div
    v-bind="data.attrs"
    :class="[
      data.class,
      data.staticClass,
      props.withoutInsets ? '' : 'px-6 md:px-10',
      props.overrideClass ? props.overrideClass : 'container mx-auto w-full',
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    withoutInsets: Boolean,
    overrideClass: {
      type: String,
      default: '',
    },
  },
}
</script>
