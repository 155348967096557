import { IECommerceProducts, ECommerceProducts } from './ECommerceProducts'

export interface IECommerceProductDetail {
  detail: IECommerceProducts
}

export class ECommerceProductDetail {
  public detail: IECommerceProducts
  constructor({
    productId,
    title,
    available,
    price,
    productBrand,
    productTheme,
    productCategory,
    brandName,
    parentDenominationType,
    quantity,
    productKind,
    discontinued = null,
    invalidProduct = null,
  }) {
    this.detail = new ECommerceProducts({
      productId,
      title,
      available,
      price,
      brandName,
      productBrand: productBrand || productTheme,
      productCategory,
      parentDenominationType,
      quantity,
      productKind,
      discontinued,
      invalidProduct,
    }).toJSON()
  }

  toJSON(): IECommerceProductDetail {
    return {
      detail: this.detail,
    }
  }
}
