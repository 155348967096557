
import { PropOptions } from 'vue'
import { Card } from '~/components/atoms'
import { CategoryListItem } from '~/components/molecules'
import { CdpContainer } from '~/components/renderers'
import { ICategoryList } from '~/models/components/CategoryList'

export default {
  components: {
    Card,
    CategoryListItem,
    CdpContainer,
  },
  props: {
    categoryCards: {
      type: Array,
      default() {
        return []
      },
    } as PropOptions<ICategoryList[] | []>,
  },
}
