import { Entry } from 'contentful-management/dist/typings/entities/entry'
import { ExternalLinkModel } from '../base/ExternalLink'
import { ICMSUsps, IUsps, UspsModel } from '~/models/components/Usps'
import { LinkModel, TCMSLink, TLink, isCMSLink } from '~/models/base/Link'
import { EntryModel } from '~/models/base/Entry'

export interface ICMSUspsBlock extends Entry {
  fields: {
    usps: ICMSUsps[]
    text: string
    buttonLink?: TCMSLink
    buttonText?: string
  }
}

export interface IUspsBlock {
  usps: IUsps[]
  text: string
  buttonLink?: TLink | null
  buttonText?: string
}

export class UspsBlockModel extends EntryModel {
  protected readonly usps: IUsps[]
  protected readonly text: string
  protected readonly buttonLink: TLink
  protected readonly buttonText: string

  constructor(uspsModel: ICMSUspsBlock) {
    super(uspsModel)
    const {
      fields: { buttonLink, buttonText },
    } = uspsModel
    this.usps = this.requiredField('usps').map(usp =>
      new UspsModel(usp).toJSON()
    )

    this.text = this.requiredField('text')
    if (buttonText) {
      this.buttonText = buttonText
    }

    if (buttonLink) {
      this.buttonLink = isCMSLink(buttonLink)
        ? new LinkModel(buttonLink).toJSON()
        : new ExternalLinkModel(buttonLink).toJSON()
    }
  }

  toJSON(): IUspsBlock {
    return {
      text: this.text,
      buttonLink: this.buttonLink,
      usps: this.usps,
      buttonText: this.buttonText,
    }
  }
}
